import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import filterFactory from 'react-bootstrap-table2-filter';
import ReactTooltip from "react-tooltip";
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
// import ToolkitProvider, { ColumnToggle } from 'react-bootstrap-table2-toolkit';

import ValColumnSelector from "../validators/ValColumnSelector";

import "../validators/Validators.css";
import '../styles/section.css';
import '../styles/tables.css';
import '../styles/validator.css';
import Utilities from "../util/Utilities";
import RespUtils from "../util/RespUtils";

import MainUtils from "../main/MainUtils";
import CoinUtils from '../util/CoinUtils';
import SignFmtUtils from '../validator/SignFmtUtils';
import currentNetwork from '../currentNetwork';
import ValidatorUtils from '../validators/ValidatorUtils';
import StakeUtils from '../util/StakeUtils';
import ChartTitle from '../components/ChartTitle';
import tooltips from '../tooltips';
import RestUtils from '../util/RestUtils';
import SPCalc from '../util/SPCalc';
import ColumnUtils from '../util/ColumnUtils';

const interchainKey = "interchainKey_v4";

class InterchainValPerf extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      apps: null,
    };
    this.repaintData = this.repaintData.bind(this);
    this.getInitColumns = this.getInitColumns.bind(this);
  }

  async componentDidMount() {
    RestUtils.populateDashboards(this, true);
  }
  
  render() {
    if (!this.props.interchainPerf || this.props.interchainPerf.length === 0 || this.state.apps === null) {
      return "";
    }

    const headerSortingClasses = (column, sortOrder, isLastSorting, colIndex) => (
      sortOrder === 'asc' ? 'sorting-asc' : 'sorting-desc'
    );
    
    let token = currentNetwork.details.app.token;
    // console.log(this.state.apps);

    var columns = [
      {text: "#", desc: "VP Rank", hidden: this.isHidden("vpRank"), dataField: "vpRank", sort: true, align: "left", headerStyle: Utilities.respWidth(5, 5, 4), headerSortingClasses},
      {text: "Validator Name", desc: "Validator Name", hidden: this.isHidden("name"), dataField: "name", sort: true, headerStyle: Utilities.respWidth(25, 15, 10), headerSortingClasses},
      {text: "Network", desc: "Network", hidden: this.isHidden("app"), dataField: "app", formatter: MainUtils.networkNameFormatterTableWithLogo, formatExtraData: this.state.apps, sort: true, headerStyle: Utilities.respWidth(35, 25, 14), headerSortingClasses},
      {text: "Description", desc: "Description", hidden: this.isHidden("description"), dataField: "description", sort: true, headerStyle: Utilities.respWidth(45, 25, 15), headerSortingClasses},
      {text: "Website", desc: "Website", hidden: this.isHidden("website"), dataField: "website", sort: true, headerStyle: Utilities.respWidth(25, 15, 10), headerSortingClasses},
      {text: "Contact Info", desc: "Contact Info", hidden: this.isHidden("securityContact"), dataField: "securityContact", sort: true, headerStyle: Utilities.respWidth(25, 15, 10), headerSortingClasses},
      {text: "Operator Address", desc: "Operator Address", hidden: this.isHidden("operatorAddress"), dataField: "operatorAddress", sort: true, headerStyle: Utilities.respWidth(25, 15, 10), headerSortingClasses},
      {text: "Status",  desc: "Validator Status", hidden: this.isHidden("status"), dataField: "status", formatter: MainUtils.statusFormatter, sort: true, headerStyle: Utilities.width(9), headerSortingClasses},
      {text: "Unbonding Time", desc: "Waiting time for undelegation", hidden: this.isHidden("unbondingTime"), dataField: "unbondingTime", formatter: SPCalc.formatDaysLeft, sort: true, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
      {text: "Voting Power (VP)",  desc: ("Total " + token + " Staked with validator"), hidden: this.isHidden("votingPower"), dataField: "votingPower", formatter: CoinUtils.stakeFormatterRounded, sort: true, headerStyle: Utilities.width(9), headerSortingClasses},
      {text: "% Voting Power",  desc: "% of total voting power that is with the validator", hidden: this.isHidden("votingPowerPercentage"), dataField: "votingPowerPercentage", formatter: MainUtils.percentFormatter, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 12, 9), headerSortingClasses},
      {text: Utilities.respLabel("Comm", "Comm", "Commission"), desc: "Current commission charged by validator", hidden: this.isHidden("commission"), dataField: "commission", formatter: MainUtils.getCommission, sort: true, headerStyle: Utilities.width(9), headerSortingClasses},
      {text: "Max Comm.",  desc: "Maximum commission ever charged by validator", hidden: this.isHidden("commissionMaxRate"), dataField: "commissionMaxRate", formatter: MainUtils.percentFormatter, sort: true, headerStyle: Utilities.width(8), headerSortingClasses},
      {text: "Comm. Max Change Rate",  desc: "Daily max change rate for commission", hidden: this.isHidden("commissionMaxChangeRate"), dataField: "commissionMaxChangeRate", formatter: MainUtils.percentFormatter, sort: true, headerStyle: Utilities.width(8), headerSortingClasses},
      {text: "Unique Delegates",  desc: "Total number of unique delegates", hidden: this.isHidden("uniqueDelegates"), dataField: "uniqueDelegates", sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(9), headerSortingClasses},
      {text: "VP Change (1d)",  desc: "Change in voting power in 1 day", hidden: this.isHidden("votingPowerChange1d"), dataField: "votingPowerChange1d", formatter: StakeUtils.formatVPChangeLink, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(9), headerSortingClasses},
      {text: "VP Change (7d)",  desc: "Change in voting power in 7 days", hidden: this.isHidden("votingPowerChange7d"), dataField: "votingPowerChange7d", formatter: StakeUtils.formatVPChangeLink, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(9), headerSortingClasses},
      {text: "VP Change (30d)",  desc: "Change in voting power in 30 days", hidden: this.isHidden("votingPowerChange30d"), dataField: "votingPowerChange30d", formatter: StakeUtils.formatVPChangeLink, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(9), headerSortingClasses},
      {text: "Delegate Change (1d)",  desc: "Change in number of unique delegates in 1d", hidden: this.isHidden("uniqueDelegatesChange1d"), dataField: "uniqueDelegatesChange1d", formatter: StakeUtils.growthStyleFromList, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(9), headerSortingClasses},
      {text: "Delegate Change (7d)",  desc: "Change in number of unique delegates in 7d", hidden: this.isHidden("uniqueDelegatesChange7d"), dataField: "uniqueDelegatesChange7d", formatter: StakeUtils.growthStyleFromList, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(9), headerSortingClasses},
      {text: "Delegate Change (30d)",  desc: "Change in number of unique delegates in 30d", hidden: this.isHidden("uniqueDelegatesChange30d"), dataField: "uniqueDelegatesChange30d", formatter: StakeUtils.growthStyleFromList, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(9), headerSortingClasses},
      {text: "Current Sign %",  desc: "Block signature % in last 8 hours", hidden: this.isHidden("currentPerSigned"), dataField: "currentPerSigned", formatter: SignFmtUtils.currentPerSignedFmt, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(15, 15, 9), headerSortingClasses},
      {text: "Current Signed",  desc: "Blocks signed in last 8 hours", hidden: this.isHidden("currentSigned"), dataField: "currentSigned", formatter: SignFmtUtils.currentSignedFmt, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(15, 15, 9), headerSortingClasses},
      {text: "Current Missed",  desc: "Blocks missed in last 8 hours", hidden: this.isHidden("currentMissed"), dataField: "currentMissed", formatter: MainUtils.currentMissedFmt, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(15, 15, 9), headerSortingClasses},
      {text: "Avg Sign %",  desc: "Block signature % in last 30 days", hidden: this.isHidden("longPerSigned"), dataField: "longPerSigned", formatter: SignFmtUtils.longPerSignedFmt, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
      {text: "Avg Signed", desc: "Blocks signed in the last 30 days", hidden: this.isHidden("longSigned"), dataField: "longSigned", formatter: SignFmtUtils.longSignedFmt, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
      {text: "Avg Missed", desc: "Blocks missed in the last 30 days", hidden: this.isHidden("longMissed"), dataField: "longMissed", formatter: SignFmtUtils.longMissedFmt, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
      {text: "Historical Sign %", desc: "Block signature % in the last 90 days", hidden: this.isHidden("historicalPerSigned"), dataField: "historicalPerSigned", formatter: SignFmtUtils.longPerSignedFmt, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
      {text: "Historical Signed", desc: "Blocks signed in the last 90 days", hidden: this.isHidden("historicalSigned"), dataField: "historicalSigned", formatter: SignFmtUtils.longSignedFmt, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
      {text: "Historical Missed", desc: "Blocks missed in the last 90 days", hidden: this.isHidden("historicalMissed"), dataField: "historicalMissed", formatter: SignFmtUtils.longSignedFmt, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
      {text: "Net APR",  desc: "Net APR over last 30 days", hidden: this.isHidden("longNetApr"), dataField: "longNetApr", formatter: ValidatorUtils.aprFormatter, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(15, 15, 9), headerSortingClasses},
      {text: "Slashing Window Missed Blocks",  desc: "Slashing Window Missed Blocks", hidden: this.isHidden("slashingWindowMissedBlocks"), dataField: "slashingWindowMissedBlocks", formatter: this.formatSlashingWindowMissedBlocks, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 10), headerSortingClasses},
      {text: "Slashing Window % Uptime",  desc: "Slashing Window Uptime in %age", hidden: this.isHidden("slashingWindowUptime"), dataField: "slashingWindowUptime", formatter: this.formatSlashingWindowUptime, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(35, 20, 15), headerSortingClasses},
    ];

    // FIXME were these needed? columns = ValidatorUtils.removeListColumns(columns);
    // console.log(columns);

    const options = {
      onlyOneExpanding: true
    };

    // const expandRow = {
    //   renderer: row => (
    //     <div>
    //       {/* <ValInline val={row} coinStat={this.state.coinStat}/> */}
    //       <hr/>
    //     </div>
    //   ), showExpandColumn: false
    // };expandRow={ expandRow } expandableRow={ () => { return true; } } 

    return (
      <div id="interchainPerf">
        <ChartTitle title={this.props.title} shortTitle={this.props.shortTitle} tip={tooltips.valDetails.interchainPerf} />
        <p/>
        <ReactTooltip id="main" place="top" type="dark" effect="float" multiline={true} />
        <ToolkitProvider keyField="app" data={this.props.interchainPerf} columns={columns} columnToggle>
          {
            props => (
              <div id="mainTable">
                <ValColumnSelector settingKey={interchainKey} getInitColumns={this.getInitColumns} 
                  { ...props.columnToggleProps } refreshParent={this.repaintData} 
                  fsElementId="mainTable" hideFullScreenLink={true} />
                <BootstrapTable options={options} { ...props.baseProps } 
                    filter={ filterFactory() }
                    condensed noDataIndication="No Data" />
              </div>
            )
          }
        </ToolkitProvider>
        <p/>
      </div>
    );
  }

  getInitColumns() {
    let columnsConfig = {};
    if (RespUtils.isTabletView()) {
      columnsConfig = {
        vpRank: false, app: false, name: true, operatorAddress: true, votingPower: true, 
        votingPowerPercentage: false, commission: true, status: true, name: true, 
        currentPerSigned: true, currentMissed: true, currentSigned: true, 
        longPerSigned: true, longNetApr: true, description: true, 
        votingPowerChange1d: true, votingPowerChange7d: true, votingPowerChange30d: true, 
        uniqueDelegates: true, uniqueDelegatesChange1d: true, uniqueDelegatesChange7d: true,
        uniqueDelegatesChange30d: true, commissionMaxChangeRate: true, commissionMaxRate: true,
        slashingWindowMissedBlocks: true, slashingWindowUptime: false, unbondingTime: true,
        longSigned: true, longMissed: true, historicalPerSigned: true, 
        historicalSigned: true, historicalMissed: true, 
        securityContact: true, website: true, description: true, operatorAddress: true,
      };
    } else {
      columnsConfig = {
        vpRank: false, app: false, name: true, operatorAddress: true, votingPower: false, 
        votingPowerPercentage: false, commission: this.props.ics, status: false, 
        currentPerSigned: true, currentMissed: true, currentSigned: true, 
        longPerSigned: false, longNetApr: true, name: true, description: true, 
        votingPowerChange1d: this.props.ics, votingPowerChange7d: true, votingPowerChange30d: true, 
        uniqueDelegates: this.props.ics, uniqueDelegatesChange1d: true, uniqueDelegatesChange7d: true,
        uniqueDelegatesChange30d: true, commissionMaxChangeRate: true, commissionMaxRate: true,
        slashingWindowMissedBlocks: true, slashingWindowUptime: false, unbondingTime: true,
        longSigned: true, longMissed: true, historicalPerSigned: true, 
        historicalSigned: true, historicalMissed: true, 
        securityContact: true, website: true, description: true, operatorAddress: true,
      };
    }

    // console.log("interchain val perf - getInit - columns config is: ", columnsConfig);
    return columnsConfig;
  }

  isHidden(dataField) {
    return ColumnUtils.isHidden(interchainKey, dataField, this.getInitColumns);
  }

  repaintData() {
    // console.log("in repaint settings");
    let apps = this.state.apps;
    apps["JUNK_APP"] = new Date();
    this.setState({apps: apps});
  }

  formatSlashingWindowUptime(cell, row) {
    let suffix = "";
    if (currentNetwork.details.ui.ics && row.cumulativeVPWeight > 95) {
      suffix = " - Low VP"
    }
    if(row.slashingMinPerUptime) {
      if (cell) {
        return cell.toFixed(1) + "% (>" + row.slashingMinPerUptime + "%)" + suffix;
      }
    } else if (cell) {
      return cell.toFixed(1) + "%" + suffix;
    }

    return "";
  }

  formatSlashingWindowMissedBlocks(cell, row) {
    if(row.signedBlocksWindow && cell != null) {
      return cell + " (" + CoinUtils.stakeFormatterRounded(row.signedBlocksWindow) + ")";
    }

    if (cell) {
      return cell;
    }

    return "";
  }
}

export default InterchainValPerf;
