import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
// import paginationFactory from 'react-bootstrap-table2-paginator';
// import TablePagination from '@material-ui/core/TablePagination';
import Pagination from "react-pagination-js";
import "react-pagination-js/dist/styles.css"; // import css

import UITableUtils from "../util/UITableUtils";
import "./Richlist.css";
import AddressUtils from '../util/AddressUtils';

import currentNetwork from '../currentNetwork';
import SPCalc from '../util/SPCalc';
import ApiUtils from '../util/ApiUtils';
import Utilities from "../util/Utilities";
import UIUtils from "../util/UIUtils";
import SPUtilities from "../util/SPUtilities";
// import NetworkNav from '../network/NetworkNav';
import RespUtils from '../util/RespUtils';
import SessionUtils from '../util/SessionUtils';
import common from '../common';
import LogoUtils from '../util/LogoUtils';
import PageHeader from '../components/PageHeader';
import MainUtils from '../main/MainUtils';
import UrlUtils from '../util/UrlUtils';

class Delegations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      width: window.innerWidth,
      size: 10,
      minBalance: "N/A",
      coinStat: {},
      relatedTokens: [],
      isLoading: true,
      currentPage: 1,
      rowsPerPage: 500,
    }
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  updateDimensions() {
    this.setState({width: window.innerWidth});
  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  changeCurrentPage = numPage => {
    // console.log("setting numPage to: ", numPage);
    this.setState({currentPage: numPage});

    this.loadData(numPage);
  }

  async componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    SessionUtils.setNetwork(this);
    
    UrlUtils.setUrlFragment("delegations");
    this.loadData(this.state.currentPage);
  }

  async loadData(currentPage) {
    this.setState({isLoading: true});
    
    let url = "richlist?type=delegation&pageSize=" + this.state.rowsPerPage + "&page=" + currentPage;

    let allData = await ApiUtils.get(url);
    // console.log("allData is:", allData);

    if (allData) {
      let data = allData["data"];
      let newData = Utilities.addIndexByPage(data, this.state.rowsPerPage, currentPage);

      let coinStat = allData["coinStat"];

      let totalRecords = allData["recordCount"];
      // let totalRecords = coinStat.activeAccounts;
      
      let totalPages = Math.ceil(totalRecords/this.state.rowsPerPage);
      // console.log("totalRecords: ", totalRecords);
      // console.log("totalPages: ", totalPages);
      this.setState({"data": newData, isLoading: false, "coinStat": coinStat, 
        minBalance: allData["minBalance"], "totalPages": totalPages, 
        "totalRecords": totalRecords});
    }
  }

  render() {
    if (this.state.isLoading) {
      return <div>Loading</div>;
    }

    var columns = [
      {text: "#", dataField: "index", sort: true, headerStyle: Utilities.respWidth(10, 7, 5)},
      {text: "Address", dataField: "address", formatter: AddressUtils.addressFormatterByLabel, sort: true, headerStyle: Utilities.width(25)},
      {text: "Validator", dataField: "name", formatter: MainUtils.nameFormatterTableWithLogo, sort: true, headerStyle: Utilities.respWidth(25, 25, 20)},
      {text: ("Staked $" + currentNetwork.details.app.token), dataField: "stake", formatter: SPUtilities.stakeFormatterRounded, sort: true,  sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(15)},
      {text: "Percent of Total Staked", hidden: RespUtils.isMobileView(), dataField: "percentOfTotal", formatter: SPUtilities.percentFormatter, sort: true,  sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 12)},
      {text: "USD Value", hidden: (RespUtils.isMobileView() || !this.state.coinStat.usdPrice), dataField: "stake", formatter: SPCalc.calcTotalUSD, formatExtraData: this, sort: true,  sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(10)},
    ];

    const options = UIUtils.getPageOptionsLarge(this);
    let wrapperClasses = UITableUtils.isDefaultView() ? "table":"table-responsive";
    return (
      <div>
        <PageHeader title="Delegations" thisObj={this} hideSpecial={true} disableStakeFormatter={true} logo={LogoUtils.networkLogo}/>
        <p>Total Delegations: {this.state.totalRecords} (&gt;&#61; {this.state.minBalance} ${currentNetwork.details.app.token}). Data shown may be outdated by few hours. Want alias (known exchanges/ team/ foundation) for any of the addresses? Submit <a href={common.urls.aliasUrl}>here</a></p>
        
        <div className="richlistPagination"><Pagination currentPage={this.state.currentPage} theme="dark" totalSize={this.state.totalRecords}
          sizePerPage={this.state.rowsPerPage} changeCurrentPage={this.changeCurrentPage} numberOfPagesNextToActivePage={Utilities.respLabel(3, 5, 7)} /></div>
        <p/>
        <BootstrapTable keyField='index' data={ this.state.data }
          columns={ columns } striped expandableRow={ () => { return true; } } options={options}
          condensed noDataIndication="No data found"
          wrapperClasses={wrapperClasses}/>
        <div className="richlistPagination"><Pagination currentPage={this.state.currentPage} theme="dark" totalSize={this.state.totalRecords}
          sizePerPage={this.state.rowsPerPage} changeCurrentPage={this.changeCurrentPage} numberOfPagesNextToActivePage={Utilities.respLabel(3, 5, 7)} /></div>
      </div>
    );
  }
}

export default Delegations;
