import React from "react";
import {ResponsiveContainer, XAxis, YAxis, CartesianGrid, Tooltip, Label, Legend, LabelList } from 'recharts';
import {BarChart, Bar } from 'recharts';

import UIUtils from '../util/UIUtils';
import Utilities from '../util/Utilities';
import ChartUtils from '../util/ChartUtils';
import ChartTitle from "../components/ChartTitle";
import DownloadUtils from "../util/DownloadUtils";
import common from "../common";
import currentNetwork from "../currentNetwork";
import ChartData from "./ChartData";

class StackedBarChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      responsive: true,
      mode: common.charts.mode.default
    }

    this.updateDimensions = this.updateDimensions.bind(this);
    this.flipResponsive = this.flipResponsive.bind(this);
    this.switchMode = this.switchMode.bind(this);
  }

  updateDimensions() {
    this.setState({width: window.innerWidth});
  }

  flipResponsive() {
    ChartUtils.flipResponsive(this);
  }

  renderBar() {
    let textColor = common.colors.text;
    let lineColor = "#999999";//4d4d4d 737373 999999 a6a6a6 common.colors.chartColor1;
    let lineColor2 = common.colors.chartColorBright;

    let width =  UIUtils.getResponsiveWidth(this);
    let height = UIUtils.getFSChartHeight(this);
    let showDataLabel = UIUtils.getShowDataLabel(this, this.props.data, 600);

    let range = this.props.range;
    if (this.props.range === null) {
      range = Utilities.getRangeWithBase0(true, this.props.data, this.props.valueAttr);
    }
    // <YAxis domain={Utilities.getAddedRange(true, this.props.data, this.props.valueAttr, this.props.valueAttr2)}>

    const CustomTooltip = ({ active, payload, label }) => {
      if (active) {
        if (payload === null || payload.length === 0) {
          return null;
        }

        let data = payload[0].payload;
        // const valueAttrColor = data[this.props.valueAttr] > 0 ? "green" : "red";

        return (<div style={{"margin": "0px", "padding": "0px"}}>
            <table><tbody>
              <tr>
                <td>{this.props.xAxis}:</td>
                <td>{data["title"]}</td>
              </tr>
              <tr>
                  <td><font color={lineColor}>{this.props.valueAttr}:</font></td>
                  <td><font color={lineColor}>{data[this.props.valueAttr]}</font></td>
              </tr>
              <tr>
                  <td><font color={lineColor2}>{this.props.valueAttr2}:</font></td>
                  <td><font color={lineColor2}>{data[this.props.valueAttr2]}</font></td>
              </tr>
            </tbody></table>
          </div>);
      }

      return null;
    };

      // <div style={{ width: '100%', height: '100%', position: 'relative' }}>
      //   <div style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0, }} >
    return (
      <div>
        <ResponsiveContainer width={width} height={height}>
          <BarChart data={this.props.data} barCategoryGap={2} width="0"
            margin={{top: 10, right: 0, left: 5, bottom: 0}}>
            <CartesianGrid vertical={false} horizontal={false} strokeDasharray="3 3"/>
            <XAxis dataKey="title" stroke={textColor} padding={{ top: 10 }} angle={-10}>
            </XAxis>
            <YAxis stroke={textColor} domain={range}>
            </YAxis>
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
            {showDataLabel && <Bar maxBarSize={50} dataKey={this.props.valueAttr} strokeWidth={3} dot={false} stroke={lineColor} stackId="a" fill={lineColor}>
                <LabelList dataKey={this.props.valueAttr} position="center" angle={-90} />
              </Bar>}
            {!showDataLabel && <Bar maxBarSize={50} dataKey={this.props.valueAttr} strokeWidth={3} dot={false} stroke={lineColor} stackId="a" fill={lineColor} />}
            {showDataLabel && <Bar maxBarSize={50} dataKey={this.props.valueAttr2} strokeWidth={3} dot={false} stroke={lineColor2} stackId="a" fill={lineColor2}>
                <LabelList dataKey={this.props.valueAttr2} position="top" />
              </Bar>}
            {!showDataLabel && <Bar maxBarSize={50} dataKey={this.props.valueAttr2} stroke={lineColor2} stackId="a" fill={lineColor2} /> }
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }

  render () {
    let tableInd = this.state.mode === common.charts.mode.table;
    let chartVisualId = currentNetwork.details.networkName + "-" + (tableInd ? "" : "data") + this.props.chartVisualId;
    const downloadChart = () => {
      ChartUtils.downloadChart(document, chartVisualId, currentNetwork.details.app.title);
    };
  	return (
      <div id={"div" + chartVisualId}>
        <ChartTitle title={this.props.title} tip={this.props.tip} handleChart={this.props.handleChart} 
          showMoreValue={this.props.showMoreValue} handleMore={this.props.handleMore} fsElementId={this.props.fsElementId} 
          frequency={this.props.frequency} showHelp={this.showHelp} frequencies={this.props.frequencies} 
          downloadData={DownloadUtils.getDownloadDataForChart(this.props.data, this.props.xAxisValueAttr, this.props.valueAttr)} 
          saveChart={downloadChart} enableSave={true} 
          mode={this.state.mode} switchMode={this.switchMode} columns={this.props.columns} />
        <p/>
        <div align="center">
          {this.props.showTotalLabel ? Utilities.getTotalWithLabel(this.props.data, this.props.valueAttr, this.props.totalLabel) : ""}
          {this.props.subtitle && <p className="compSubTitle">{this.props.subtitle}</p> }
          {tableInd ? <ChartData data={this.props.data} columns={this.props.columns} dataKey={this.props.dataKey} /> : this.renderBar()}
        </div>
        {ChartUtils.getLandscapeMsg()}
      </div>
    );
  }

  switchMode() {
    let currentMode = this.state.mode;
    if (currentMode === common.charts.mode.chart) {
      this.setState({mode: common.charts.mode.table});
    } else {
      this.setState({mode: common.charts.mode.chart});
    }
  }
}

export default StackedBarChart;
