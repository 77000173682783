import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';

import ReactTooltip from 'react-tooltip';
import LogoUtils from '../util/LogoUtils';
import "../airdrops/Airdrops.css";
import currentNetwork from '../currentNetwork';
import CoinUtils from '../util/CoinUtils';
import LSUtils from './LSUtils';
import RespUtils from '../util/RespUtils';
import SPUtilities from '../util/SPUtilities';


class LSProviders extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <ReactTooltip id="main" place="top" type="dark" effect="float" multiline={true} />
        {this.renderProviders()}
      </div>
    );
  }

  renderProviders() {
    let switchProtocol = this.props.switchProtocol;
    let token4Amounts = RespUtils.isMobileView() ? "" : (" $" + currentNetwork.details.app.token);
    let selectedProtocol = this.props.selectedProtocol;
    let appStyle = (selectedProtocol == currentNetwork.details.app.token) ? "frequencyLink animLink selectedLsp" : "frequencyLink animLink";
    let freq = RespUtils.isMobileView() ? "" : " (1 day)";

    return (
      <React.Fragment>
        <br/>
        <Container fluid>
          <Row>
            <Col key="overall" className="blockBg" xs={6} sm={6} md={6} lg={3} xl={3}>
              <div className="stat">
                  <div className="airdropTitle">
                      <div className="airdropLabel">
                          <span className="airdropLogo">{LogoUtils.projectLogo(this.props.summary.logo, this.props.summary)}</span>
                          <span className="textLabel">
                              {RespUtils.isMobileView() ? "Total LS " : "Total Liquid Staked "}{freq}
                          </span>
                      </div>
                  </div>
                  <div className="statValue">
                    <button key={"frequency" + currentNetwork.details.app.token} onClick={switchProtocol} value={currentNetwork.details.app.token} 
                        className={appStyle}>{CoinUtils.stakeFormatterRoundIfNeeded(this.props.summary.totalDelegation) + token4Amounts}
                    </button>{LSUtils.formatDelta(this.props.summary.deltaDelegation)}
                    <p className="smallFootnote">%age of Total Stake: {this.props.coinStat.liquidStakePercent}%</p>
                  </div>
              </div>
            </Col>
            {this.props.providers.map(function (provider) {
              return <Col key={provider.name} className="blockBg" xs={6} sm={6} md={6} lg={3} xl={3}>
                <div className="stat">
                    <div className="airdropTitle">
                        <div className="airdropLabel">
                            <span className="airdropLogo">{LogoUtils.projectLogo(provider.logo, provider)}</span>
                            <span className="textLabel">{provider.name}{freq}
                              {<a href={SPUtilities.getUrl(provider.website)} target="_blank" title={provider.name + " website"} 
                                className="providerApp mediaCustom fas fa-external-link-alt fa-md"/>}
                            </span>
                        </div>
                    </div>
                    {/* {LSProviders.lsProjectFormatter(provider.name, provider)} */}
                    <div className="statValue">
                      <button key={"frequency" + provider.lsp} onClick={switchProtocol} value={provider.lsp} 
                        className={(selectedProtocol == provider.lsp) ? "frequencyLink animLink selectedLsp" : "frequencyLink animLink"}
                        >{CoinUtils.stakeFormatterRoundIfNeeded(provider.totalDelegation) + token4Amounts}
                        </button>{LSUtils.formatDelta(provider.deltaDelegation)}
                    </div>
                </div>
              </Col>
            })}
          </Row>
        </Container>
      </React.Fragment>
    );

  }

  static lsProjectFormatter(cell, row) {
    return (<a target="_blank" href={SPUtilities.getUrl(row.liquidStakeLink)} className="animLinkVisual">{cell}</a>);
  }

}

export default LSProviders;
