import React from 'react';
import {Container} from 'react-bootstrap';

import ApiUtils from '../util/ApiUtils';
import TxsInline from './TxsInline';

import Utilities from "../util/Utilities";
import SessionUtils from '../util/SessionUtils';
import ValHeader from '../validator/ValHeader';
import ReactTooltip from 'react-tooltip';
import TxUtils from './TxUtils';
import ValNav from '../validator/ValNav';
import currentNetwork from '../currentNetwork';

class ValTxs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      largeSize: null,
      address: null,
      category: null,
      width: window.innerWidth,
      size: 10,
      isLoading: true,
    }
    this.updateDimensions = this.updateDimensions.bind(this);
    this.selectCategory = this.selectCategory.bind(this);    
  }

  updateDimensions() {
    this.setState({width: window.innerWidth});
  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  async componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    SessionUtils.setNetwork(this);

    let address = this.state.address;
    let category = this.state.category;
    if (this.props.match && this.props.match.params.address) {
        address = this.props.match.params.address;
    }
    if (this.props.match && this.props.match.params.category) {
      category = this.props.match.params.category;
    }

    await this.prepareData(address, category);
  }

  async prepareData(address, category) {
    var catCriteria = category != null ? "&category=" + category : "";
    var url = "listData?type=valtxs&size=50&address=" + address + catCriteria;
    // console.log(url);
    const allData = await ApiUtils.get(url);
    // console.log(allData);
    if (allData) {
      let data = Utilities.addIndex(allData["data"]);
      this.setState({"data": data, "coinStat": allData["coinStat"], "address": address, "category": category,
        "notification": allData["notification"], "val": allData["val"], "largeSize": allData["largeSize"],
        isLoading: false});
    }
  }

  render() {
    if (this.state.isLoading) {
      return <div>Loading</div>;
    }

    return (
      <div>
        <p/>
        <ValHeader val={this.state.val} notification={this.state.notification} 
          title={TxUtils.getTitle(this, "Recent Staking Transactions")} thisObj={this}/>
        <ReactTooltip id="main" place="top" type="dark" effect="float" multiline={true} />

        <Container fluid className="containerLayout container-fluid chartContainer">
          <div>
            <div class="chartBg">
              {TxUtils.renderTxFilters(this, "large")}
              <TxsInline pageSize="50" data={this.state.data} address={this.state.address} isWidget={false} 
                showValName={true} context="validator" selectCategory={this.selectCategory} 
                category={this.state.category} />
            </div>
          </div>
        </Container>
        <ValNav address={this.state.val[currentNetwork.details.ui.identifierAttribute]} val={this.state.val} />
      </div>
    );
  }

  selectCategory(e) {
    let category = e.target.value;
    window.location = SessionUtils.getUrl("/val/" + this.state.address + "/txs/" + category);
  }
}

export default ValTxs;
