import React from 'react';

import FavIcon from '../components/FavIcon';
import NetworkSwitch from '../components/NetworkSwitch';

class GenFavUtils extends React.Component {
  static getIdField(thisObj) {
    return thisObj.getFavIdField();
  }

  static getFavStorageKey(thisObj) {
    return thisObj.getFavStorageKey();
  }

  static getDefaultViewKey(thisObj) {
    return GenFavUtils.getIdField(thisObj) + "-ViewKey";
  }


  static getRedirectLocation() {
    return window.location;
  }

  static isFavourite(key, thisObj) {
    let favs = window.localStorage.getItem(GenFavUtils.getFavStorageKey(thisObj));
    //console.log("in isFavourite: ", key, "; favs: ", favs);
    if (favs != null) {
      let favArr = favs.split(",");
      for (let i = 0; i < favArr.length; i++) {
        if (favArr[i] === "" + key) {
          return true;
        }
      }
    }

    return false;
  }

  static favoriteFormatter(cell, row, rowIndex, thisObj) {
    //console.log("in favoriteFormatter for: ", cell);
    if (cell && GenFavUtils.isFavourite(cell, thisObj)) {
      return <span className="tourFavorite"><FavIcon isFav={true} thisObj={thisObj} id={row[GenFavUtils.getIdField(thisObj)]}/></span>
    } else {
      return <span className="tourFavorite"><FavIcon isFav={false} thisObj={thisObj} id={row[GenFavUtils.getIdField(thisObj)]}/></span>
    }
  }

  static showFavSwitch(thisObj) {
    let defaultView = window.localStorage.getItem(GenFavUtils.getDefaultViewKey(thisObj));
    // console.log("in showFavSwitch for: " + defaultView)
    // if (defaultView !== null && defaultView === 'Favs') {//favourite
    //   return (<img src={"/images/heart.png"} title="Showing favorites. Click to show all"
    //     className="imgicon" width="32" height="32" onClick={FavUtils.switchToAll}/>);
    // } else {
    //   return (<img src={"/images/heart-outline.png"} title="Showing all. Click to show favorites"
    //     className="imgicon" width="32" height="32" onClick={FavUtils.switchToFav}/>);
    // }
    return <NetworkSwitch label1="Show All" value1={"all"} label2="Show Fav" value2="Favs" 
        selectedValue={defaultView} handleNetworkChange={thisObj.handleFavChange} />
  }

  static switchToFav(thisObj) {
    window.localStorage.setItem(GenFavUtils.getDefaultViewKey(thisObj), "Favs");
    // window.location.reload();
  }

  static switchToAll(thisObj) {
    // alert(e.target.value);
    // let defaultViewKey = e.target.value;
    window.localStorage.removeItem(GenFavUtils.getDefaultViewKey(thisObj));
    // window.location.reload();
  }

  static unfavourite(e, thisObj) {
    e.preventDefault();
    GenFavUtils.unfavouriteById(e.target.id, thisObj);
  }

  static unfavouriteById(key, thisObj) {
    // console.log("in unfavourite: " + key);
    let favs = window.localStorage.getItem(GenFavUtils.getFavStorageKey(thisObj));
    // console.log("unfavourite - favs: " + favs);
    if (favs != null) {
      let favArr = favs.split(",");
      let newFavs = "";
      let found = false;
      for (let i = 0; i < favArr.length; i++) {
        if (favArr[i] == key) {
          // console.log("removing pool");
          continue;
        }

        if (found) {
          newFavs += ",";
        }
        newFavs += favArr[i];
        found = true;
      }
      window.localStorage.setItem(GenFavUtils.getFavStorageKey(thisObj), newFavs);
      // console.log("unfavourite - favs: " + newFavs);
    }
    window.location.reload();
  }

  static favourite(e, thisObj) {
    e.preventDefault();
    GenFavUtils.favouriteById(e.target.id, thisObj);
  }
  
  static favouriteById(key, thisObj) {
    let storageKey = GenFavUtils.getFavStorageKey(thisObj);
    // window.localStorage.removeItem(GenFavUtils.getFavStorageKey());
    // console.log("in favourite: " + key);
    let favs = window.localStorage.getItem(storageKey);
    // console.log("favourite - favs: " + favs);
    if (favs !== null) {
      favs += "," + key;
      window.localStorage.setItem(storageKey, favs);
      // console.log(favs);
    } else {
      favs = key;
      window.localStorage.setItem(storageKey, favs);
    }
    // console.log("favourite - favs: " + window.localStorage.getItem(GenFavUtils.getFavStorageKey(thisObj)));
    window.location.reload();
  }

  static filterData(thisObj, allData) {
    // window.localStorage.removeItem(GenFavUtils.getFavStorageKey());
    // return;
    let defaultView = window.localStorage.getItem(GenFavUtils.getDefaultViewKey(thisObj));
    let idField = GenFavUtils.getIdField(thisObj);
    let newData = [];

    // let favInd = window.localStorage.getItem(GenFavUtils.getFavStorageKey());
    // console.log("favInd: '", favInd);
    // let showFavsOnly = (favInd !== null && favInd.trim().replace(",","").length > 0);
    // console.log("showFavsOnly: ", showFavsOnly);
    // && favInd

    if (defaultView === 'Favs') {
      for(let i=0; i<allData.length; i++) {
        if (GenFavUtils.isFavourite(allData[i][idField], thisObj)) {
          newData[newData.length]=allData[i];
        }
      }

      if (newData.length === 0) {
        newData = allData;
      }
      // console.log(newData);
      // thisObj.setState({"pools": newData});
    } else {
      // let newData = [];
      let regularPoolData = [];
      for(let i=0; i<allData.length; i++) {
        if (GenFavUtils.isFavourite(allData[i][idField], thisObj)) {
          newData[newData.length]=allData[i];
        } else {
          regularPoolData[regularPoolData.length]=allData[i];
        }
      }

      newData.push.apply(newData, regularPoolData)
      // console.log(newData);
      // thisObj.setState({"pools": newData});
    }

    return newData;
  }
}

export default GenFavUtils;
