import React from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import {Container} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ReactTooltip from "react-tooltip";
import paginationFactory from 'react-bootstrap-table2-paginator';

import ApiUtils from '../util/ApiUtils';
import tooltips from "../tooltips";
import '../styles/section.css';
import MainUtils from '../main/MainUtils';
import Utilities from '../util/Utilities';
import ChartTitle from '../components/ChartTitle';
import UIUtils from '../util/UIUtils';
import './VotingPower.css';
import RespUtils from '../util/RespUtils';
import MultiLineChart from '../charts/MultiLineChart';
import ChainUtils from '../util/ChainUtils';
import common from '../common';
import SPUtilities from '../util/SPUtilities';
import SessionUtils from '../util/SessionUtils';
import PageHeader from '../components/PageHeader';
import currentNetwork from '../currentNetwork';
import BaseMultiBarChart from '../charts/BaseMultiBarChart';
import UrlUtils from '../util/UrlUtils';

class Performance extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      notification: {},
      width: window.innerWidth,
      responsive: true,
      isLoading: true,
      frequencyNetwork: "M",
      frequencyBlock: "H",
      signHistory: [],
      mostMissedBlocks: [],
      networkPerfByProposer: [],
      blockRateHistory: [],
      valPerfByBucket: [],
    }

    // this.showVPCurveChart = this.showVPCurveChart(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.handleChartUpdateNetwork = this.handleChartUpdateNetwork.bind(this);   
    this.handleChartUpdateBlock = this.handleChartUpdateBlock.bind(this);
  }


  updateDimensions() {
    this.setState({width: window.innerWidth});
  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  async componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);

    if (!this.props.mode || this.props.mode != "inline") {
      SessionUtils.setNetwork(this);
    }

    await this.prepareData(this.state.frequencyNetwork, this.state.frequencyBlock);
    UrlUtils.setUrlFragment("performance");
  }

  async prepareData(frequencyNetwork, frequencyBlock) {
    let url = "networkStats?type=performance&frequencyNetwork=" + frequencyNetwork + "&frequencyBlock=" + frequencyBlock;

    const allData = await ApiUtils.get(url);

    if (allData) {
      let blockRateHistory = Utilities.removeEmptyRecords(allData["networkHistory"], "blockRate");
      let mostMissedBlocks = this.getMostMissedBlocks(allData["signHistory"]);
      this.setState({"frequencyNetwork": frequencyNetwork, "frequencyBlock": frequencyBlock,
          "notification": allData["notification"], "networkPerfByProposer": allData["networkPerfByProposer"],
          isLoading: false, "mostMissedBlocks": mostMissedBlocks, 
          "signHistory": allData["signHistory"], "extraData": allData["extraData"],
          "blockRateHistory": blockRateHistory, "valPerfByBucket": allData["valPerfByBucket"]});
    }
  }

  render() {
    if (this.state.isLoading) {
      return <div>Loading</div>;
    }

    return (
      <div>
        {this.props.mode != "inline" && <PageHeader title={currentNetwork.details.app.appName + " Network Performance"} thisObj={this} />}
        <ReactTooltip id="main" place="top" type="dark" effect="float" multiline={true} />
        <hr/>
        <Container fluid className="containerLayout container-fluid chartContainer">
          <div className="chartGridContainer">
            <div className="chartBg" id="networkSignHistory" style={{"visibility": "visible"}}>
              <MultiLineChart title="Network Sign History" xAxis="Block" yAxis="Signatures"
                  chartVisualId="network-sign-history" enableSave={true} 
                  showVerticalLabel={false} valueAttr={["signed", "missed", "networkSignRate"]} 
                  showTotalLabel={false} xAxisValueAttr="title" rangeAttrs={["signed", "networkSignRate"]} 
                  data={this.state.signHistory} tip={tooltips.networkCharts.signHistory} formatValues={true} 
                  handleChart={this.handleChartUpdateBlock} fsElementId="networkSignHistory"
                  frequencies={["H", "2H", "4H"]} frequency={this.state.frequencyBlock} 
                  dataKey="title" columns={[
                    {text: "Date", dataField: "title", sort: true, },
                    {text: "Signed", dataField: "signed", sort: true, },
                    {text: "Missed", dataField: "missed", sort: true, },
                    {text: "Network Sign %", dataField: "networkSignRate", formatter: MainUtils.percentFormatterText, sort: true, },
                  ]} />
            </div>
            {!ChainUtils.isMatic() && <div className="chartBg">
              {this.renderNetworkPerfByProposer()}
            </div>}
            {ChainUtils.isMatic() && <div className="chartBg" id="checkpointHistory">
              <MultiLineChart title="Checkpoint History" xAxis="Checkpoint" yAxis="Checkpoints"
                  chartVisualId="checkpoint-history" enableSave={true} 
                  showVerticalLabel={false} valueAttr={["signed", "missed"]} fsElementId="checkpointHistory"
                  showTotalLabel={false} xAxisValueAttr="title" rangeAttr="signed" adjustRange={true} 
                  data={this.state.extraData.checkpoints} tip={tooltips.matic.networkCheckpointHistory} 
                  handleChart={this.handleChartUpdateNetwork} formatValues={true} 
                  frequencies={["M", "Y", "All"]} frequency={this.state.frequencyNetwork} />
            </div>}
            <div className="chartBg">
              {this.renderMissedBlocks()}
            </div>
            <div className="chartBg" id="blockRate">
              <MultiLineChart title="Block Rate (seconds) vs Network Sign Rate" shortTitle="Block vs Network Sign Rate" 
                chartVisualId="block-rate-vs-network-sign-rate" enableSave={true} 
                xAxis="Date" yAxis="Block Rate" 
                showVerticalLabel={false} valueAttr={["blockRate"]} showTotalLabel={false} xAxisValueAttr="title"
                yAxisColor={common.colors.chartColor4} showLegend={true} compareAttr="signRate" rangeAttrs={["blockRate", "blockRate"]} 
                data={this.state.blockRateHistory} tip={tooltips.networkCharts.blockRateHistory} 
                handleChart={this.handleChartUpdateNetwork} formatValues={true} fsElementId="blockRate" 
                frequencies={["M", "Y", "All"]} frequency={this.state.frequencyNetwork}
                dataKey="title" columns={[
                  {text: "Date", dataField: "title", sort: true, },
                  {text: "Block Rate", dataField: "blockRate", sort: true, },
                  {text: "Sign Rate", dataField: "signRate", formatter: MainUtils.percentFormatterText, sort: true, },
                ]} />
            </div>
            {!ChainUtils.isMatic() && <div className="chartBg">
              <BaseMultiBarChart title="Uptime by VP Rank Buckets" shortTitle="Uptime by VP Rank" 
                chartVisualId="uptime-by-vp-rank-buckets" enableSave={true} 
                xAxis="Bucket" yAxis="Average Sign Rate" 
                showVerticalLabel={false} value1Attr="perSigned8HourAvg" value2Attr="perSigned30DayAvg" value3Attr="perSigned90DayAvg"
                showTotalLabel={false} xAxisValueAttr="title" data={this.state.valPerfByBucket} 
                tip={tooltips.networkCharts.valPerfByBucket} 
                dataKey="title" columns={[
                  {text: "Bucket", dataField: "title", sort: true, },
                  {text: "Avg Sign Rate - 8 hour", dataField: "perSigned8HourAvg", formatter: MainUtils.percentFormatterText, sort: true, },
                  {text: "Avg Sign Rate - 30 day", dataField: "perSigned30DayAvg", formatter: MainUtils.percentFormatterText, sort: true, },
                  {text: "Avg Sign Rate - 90 day", dataField: "perSigned90DayAvg", formatter: MainUtils.percentFormatterText, sort: true, },
                ]} />
            </div>}
          </div>
        </Container>
      </div>
    );
  }
  
  handleChartUpdateNetwork(frequencyNetwork) {
    this.prepareData(frequencyNetwork, this.state.frequencyBlock);
  }

  handleChartUpdateBlock(frequencyBlock) {
    this.prepareData(this.state.frequencyNetwork, frequencyBlock);
  }

  getMostMissedBlocks(signHistory) {
    let allSigns = JSON.parse(JSON.stringify(signHistory));
    allSigns.sort((a, b) => (a.missed > b.missed) ? -1 : 1);

    if (allSigns.length > 100) {
      return allSigns.slice(0, 100);
    }

    return allSigns;
  }

  renderMissedBlocks() {
    const headerSortingClasses = (column, sortOrder, isLastSorting, colIndex) => (
      sortOrder === 'asc' ? 'sorting-asc' : 'sorting-desc'
    );
    var columns = [
      {text: "Block", dataField: "title", formatter: this.blockLink, sort: true, headerStyle: Utilities.respWidth(18, 20, 15), headerSortingClasses},
      {text: "Signed", hidden: RespUtils.isMobileView(), dataField: "signed", sort: true, headerStyle: Utilities.width(15), headerSortingClasses},
      {text: "Missed", dataField: "missed", sort: true, headerStyle: Utilities.respWidth(18, 20, 15), headerSortingClasses},
      {text: "Sign Per", dataField: "networkSignRate", sort: true, formatter: SPUtilities.percentFormatter, headerStyle: Utilities.respWidth(18, 20, 15), headerSortingClasses},
      {text: "Proposer", hidden: ChainUtils.isMatic(), dataField: "name", formatter: MainUtils.nameFormatterTableWithLogo, sort: true, headerStyle: Utilities.respWidth(40, 30, 35), headerSortingClasses},
    ];

    const options = UIUtils.getPageOptionsSmall(this, 5);
    return (
      <div>
        <ChartTitle title="Most Missed Blocks" tip={tooltips.networkCharts.mostMissedBlocks} 
            handleChart={this.handleChartUpdateBlock} 
            frequencies={["H", "2H", "4H"]} frequency={this.state.frequencyBlock} />
        <p/>
        <ReactTooltip id="main" place="top" type="dark" effect="float" multiline={true} />
        <BootstrapTable keyField='title' data={ this.state.mostMissedBlocks }
          columns={columns} striped options={options} 
          condensed noDataIndication="No data" pagination={ paginationFactory(options) } />
      </div>
    );
  }

  renderNetworkPerfByProposer() {
    const headerSortingClasses = (column, sortOrder, isLastSorting, colIndex) => (
      sortOrder === 'asc' ? 'sorting-asc' : 'sorting-desc'
    );
    var columns = [
      {text: "Validator", hidden: ChainUtils.isMatic(), dataField: "name", formatter: MainUtils.nameFormatterTableWithLogo, sort: true, headerStyle: Utilities.respWidth(40, 30, 35), headerSortingClasses},
      {text: "Voting Power %", hidden: RespUtils.isMobileView(), formatter: SPUtilities.percentFormatter, dataField: "votingPowerPercentage", sort: true, headerStyle: Utilities.respWidth(18, 20, 15), headerSortingClasses},
      {text: "Blocks Proposed", dataField: "proposedCount", sort: true, headerStyle: Utilities.width(15), headerSortingClasses},
      {text: "Avg Network Sign Rate", dataField: "averageSignRate", formatter: SPUtilities.percentFormatter, sort: true, headerStyle: Utilities.respWidth(18, 20, 15), headerSortingClasses},
    ];

    const options = UIUtils.getPageOptionsSmall(this, 5);

    return (
      <div>
        <ChartTitle title="Network Average Sign Rate by Proposer" shortTitle="Network Sign by Proposer" 
          tip={tooltips.networkCharts.signRateByProposer} 
          handleChart={this.handleChartUpdateBlock} 
          frequencies={["H", "2H", "4H"]} frequency={this.state.frequencyBlock} />
        <p/>
        <ReactTooltip id="main" place="top" type="dark" effect="float" multiline={true} />
        <BootstrapTable keyField='operatorAddress' data={ this.state.networkPerfByProposer }
          columns={columns} striped options={options} 
          condensed noDataIndication="No data" pagination={ paginationFactory(options) } />
      </div>
    );
  }

  blockLink(cell, row) {
    /*
    TODO fix me and ENABLE ME
    if (currentNetwork.details.app.REST_ENDPOINT != null) {
      return (<a href={SessionUtils.getUrl("/block/" + cell)} className="animLinkVisual">{cell}</a>);
    }
    */

    return cell;
  }

}

export default Performance;
