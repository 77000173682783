import React from "react";
import {ResponsiveContainer, ReferenceLine, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Label } from 'recharts';
import {BarChart, Bar } from 'recharts';

import '../styles/section.css';
import "../styles/charts.css";
import UIUtils from '../util/UIUtils';
import Utilities from '../util/Utilities';
import ChartUtils from '../util/ChartUtils';
import ChartTitle from "../components/ChartTitle";
import currentNetwork from "../currentNetwork";
import ChartData from './ChartData';
import common from "../common";
import DownloadUtils from "../util/DownloadUtils";

class BidirectionalBarChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      responsive: true,
      mode: common.charts.mode.default
    }

    this.flipResponsive = this.flipResponsive.bind(this);
    this.switchMode = this.switchMode.bind(this);
  }

  flipResponsive() {
    ChartUtils.flipResponsive(this);
  }

  renderBar() {
    const CustomTooltip = ({ active, payload, label }) => {
      // console.log(active, "-", payload, "-", label);
      if (active) {
        if (payload === null || payload.length === 0) {
          return null;
        }

        let data = payload[0].payload;

        // return (
        //   <div className="custom-tooltip">
        //     <p className="label">{`${label} : ${payload[0].value}`}</p>
        //     <p className="desc">Anything you want can be displayed here.</p>
        //   </div>
        // );
        if (this.props.customTooltip) {
          return this.props.customTooltip(data);
        }
    
        return (<div style={{"margin": "0px", "padding": "0px"}}>
            <table><tbody>
              <tr>
                <td>{this.props.xAxis}:</td>
                <td>{data["title"]}</td>
              </tr>
              <tr>
                  <td><font color={lineColor}>{this.props.valueAttr}:</font></td>
                  <td><font color={lineColor}>{data[this.props.valueAttr]}</font></td>
              </tr>
            </tbody></table>
          </div>);
      }

      return null;
    };

    let width = UIUtils.getResponsiveWidth(this);
    // <Label value={this.props.xAxis} offset={-8} position="insideBottom"/>
    let height = UIUtils.getFSChartHeight(this);
    let showDataLabel = UIUtils.getShowDataLabel(this, this.props.data, 600);
    let barSize = 50;
    if (this.props.data.length > 25) {
      barSize = barSize * 25/this.props.data.length;
      if (barSize < 5) {
        barSize = 5;
      }
    }

    // {/* <Label value={this.props.xAxis} offset={-3} position="insideBottom" /> */}
    // {/* <Label value={this.props.yAxis} offset={8} position="insideBottomLeft" angle={-90} /> */}
    let textColor = "white";
    let lineColor = "#faa84b";
    return (
      <div>
        <ResponsiveContainer width={width} height={height}>
          <BarChart data={this.props.data} barCategoryGap={2} stackOffset="sign"
                margin={{top: 10, right: 0, left: 5, bottom: 0}}>
            <CartesianGrid vertical={false} horizontal={false} strokeDasharray="3 3"/>
            <XAxis dataKey={this.props.xAxisValueAttr} stroke={textColor} padding={{ top: 10 }}>
            </XAxis>
            <YAxis domain={this.props.range} stroke={textColor}>
            </YAxis>
            {this.props.customTooltip !== null ? <Tooltip content={<CustomTooltip />} /> : <Tooltip labelStyle={ {color: 'black'}} />}
            <ReferenceLine y={0} stroke={lineColor} strokeWidth="1" label={{ value: '0', angle: 0, position: 'center' }} />
            {showDataLabel && <Bar maxBarSize={50} strokeWidth={2} dataKey={this.props.valueAttr} stroke={lineColor} onClick={this.props.onClick} label={{ angle: -90, position: 'center' }}>
              {
                this.props.data.map((entry, index) => {
                	const color = entry[this.props.valueAttr] > 0 ? "green" : "red";
                	return <Cell fill={color} />;
                })
              }
            </Bar>}
            {!showDataLabel && <Bar maxBarSize={50} barSize={barSize} dataKey={this.props.valueAttr} onClick={this.props.onClick}>
              {
                this.props.data.map((entry, index) => {
                  const color = entry[this.props.valueAttr] > 0 ? "green" : "red";
                  return <Cell fill={color} />;
                })
              }
            </Bar>}
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }

  render () {
    let tableInd = this.state.mode === common.charts.mode.table;
    let chartVisualId = currentNetwork.details.networkName + "-" + (tableInd ? "" : "data") + this.props.chartVisualId;
    const downloadChart = () => {
      ChartUtils.downloadChart(document, chartVisualId, currentNetwork.details.app.title);
    };

    return (
      <div id={"div" + chartVisualId}>
        <ChartTitle title={this.props.title} tip={this.props.tip} 
          handleChart={this.props.handleChart} disableDay={this.props.disableDay} disableWeek={this.props.disableWeek} 
          addDays={this.props.addDays} displayHelpIcon={this.props.displayHelpIcon} fsElementId={this.props.fsElementId}
          frequency={this.props.frequency} showHelp={this.showHelp} frequencies={this.props.frequencies} shortTitle={this.props.shortTitle}
          downloadData={DownloadUtils.getDownloadDataForChart(this.props.data, this.props.xAxisValueAttr, this.props.valueAttr)} 
          saveChart={downloadChart} enableSave={this.props.enableSave} 
          mode={this.state.mode} switchMode={this.switchMode} columns={this.props.columns}
        />
        <p/>
        <div align="center">
          {this.props.showTotalLabel ? Utilities.getTotalWithLabel(this.props.data, this.props.valueAttr, this.props.totalLabel) : ""}
          {this.props.subtitle && <p className="compSubTitle">{this.props.subtitle}</p>}
          {tableInd ? <ChartData data={this.props.data} columns={this.props.columns} dataKey={this.props.dataKey} sortBy={this.props.sortBy} /> : this.renderBar()}
        </div>
        {ChartUtils.getLandscapeMsg()}
      </div>
    );
  }
  // <p>Data for the latest window (hour or date) is partial. All times are in GMT.</p>

  truncDate(val) {
    return ChartUtils.truncDate(val, this.props.chartType, this.props.filter)
  }

  switchMode() {
    let currentMode = this.state.mode;
    if (currentMode === common.charts.mode.chart) {
      this.setState({mode: common.charts.mode.table});
    } else {
      this.setState({mode: common.charts.mode.chart});
    }
  }
}

export default BidirectionalBarChart;
