import numeral from 'numeral';
import React from 'react';

import common from '../common';
import currentNetwork from '../currentNetwork';
import ChainUtils from '../util/ChainUtils';
import RespUtils from '../util/RespUtils';
import MainUtils from '../main/MainUtils';
import SessionUtils from '../util/SessionUtils';
import Utilities from '../util/Utilities';
import SPUtilities from '../util/SPUtilities';
import AddressUtils from '../util/AddressUtils';

class ValidatorUtils extends React.Component {

  static removeListColumns(columns) {
    let selectedColumns = ValidatorUtils.getCustomColumnsList(columns);
    if (selectedColumns.length > 0) return selectedColumns;

    // FIXME remove below code
    let removedColumns;

    if (currentNetwork.details.ui.validators.supportOracle === true) {
      removedColumns = currentNetwork.details.ui.validators.removedColumns;
    } else {
      removedColumns = [...currentNetwork.details.ui.validators.removedColumns, ...common.removeOracleColumns];
    }

    let removedColumnsResp = currentNetwork.details.ui.validators.removedColumnsResp;
    for(let i=0; i < columns.length; i++) {
      let column = columns[i];

      if (!removedColumns.includes(column.dataField)) {
        if (RespUtils.isTabletView()) {
          if (!removedColumnsResp.includes(column.dataField)) {
            selectedColumns.push(column);
          }
        } else {
          selectedColumns.push(column);
        }
      }
    }

    // console.log(columns.length, " to ", selectedColumns.length);
    return selectedColumns;
  }

  static isShow(attrName) {
    let customConfig = ValidatorUtils.getCustomConfig();
    // console.log(customConfig);

    return attrName in customConfig;
    // let removedColumns = currentNetwork.details.ui.validators.removedColumns;
    // return !removedColumns.includes(attrName);
  }

  static aprFormatter(cell, row) {
    return ValidatorUtils.aprApyFormatter(cell, row.longNetApy);
  }

  static aprApyFormatter(inputApr, inputApy) {
    let apr = inputApr ? inputApr : 0;
    let apy = inputApy ? inputApy : 0;

    if (apr > 100) {
      apr = numeral(apr).format('0')
    }
    if (apy > 100) {
      apy = numeral(apy).format('0')
    }

    return apr + "% / " + apy + "%";
  }
  
  static formatVoting(cell, row) {
    if (!cell && cell !== 0) {
      return cell;
    }

    return cell + "/" + row.maxVotes;
  }

  static formatVotingOverall(cell, row) {
    if (!cell && cell !== 0) {
      return cell;
    }

    return cell + "/" + row.maxVotesOverall;
  }

  static getIdParam(thisObj) {
    if (thisObj.props.match && thisObj.props.match.params.address) {
      if (ChainUtils.isMatic()) {
          return "&polygonId=" + thisObj.props.match.params.address;
      } else {
          return "&address=" + thisObj.props.match.params.address;
      }
    }
    
    return "";
  }

  static getCustomConfig() {
    // console.log("in getCustomConfig:", currentNetwork.details.ui.validators.columnsConfig);
    // if (!currentNetwork.details.ui.validators.columnsConfig) return ValidatorUtils.getDefaultInitConfig();

    let customConfig = {};
    let allColumns = currentNetwork.details.ui.validators.columnsConfig;
    let mobileView = RespUtils.isTabletView();
    for (let column in allColumns) {
      let details = allColumns[column];
      if (mobileView) {
        if (details.addToMobile) {
          customConfig[column] = details["hideMobile"];
        }
      } else if (currentNetwork.details.ui.validators.columnsConfig) {
        customConfig[column] = details["hide"];
      }
    }
    // if (RespUtils.isTabletView() && currentNetwork.details.ui.validators.columnsConfigMobile) {
    //   console.log("using configured mobile config");
    //   return currentNetwork.details.ui.validators.columnsConfigMobile;
    // } else if (currentNetwork.details.ui.validators.columnsConfig) {
    //   console.log("using configured config");
    //   return currentNetwork.details.ui.validators.columnsConfig;
    // }
    
    // console.log(customConfig);
    return customConfig;
  }

  static getCustomColumnsList(columns) {
    let customConfig = ValidatorUtils.getCustomConfig();
    // console.log("customConfig is: ");
    // console.log(customConfig);

    let selectedColumns = [];
    for(let i=0; i < columns.length; i++) {
      let column = columns[i];
      // console.log(column);
      // console.log(column.dataField in (customConfig));
      if (column.dataField in customConfig) {
        selectedColumns.push(column);
      }
    }

    return selectedColumns;
  }

  /**
   * this method is likely of no value add as networks have column config
   */
  /*static getDefaultInitConfig() {
    // console.log("in getDefaultInitConfig");
    let columnsConfig = {};
    if (RespUtils.isTabletView()) {
      columnsConfig = {
        averageDelegation: true, commission: true, commissionMaxChangeRate: true, commissionMaxRate: true, commissionPlus: false, 
        consensusNodeAddress: true, currentMissed: true, 
        currentOracleMissed: true, currentOraclePerSigned: true, currentPerSigned: true, currentSigned: true, 
        dailyMissed: true, dailyOracleMissed: true, dailyOraclePerSigned: true, dailyPerSigned: true, dailySigned: true, 
        description: true, extraData1: true, 
        extraData3: true, fav: false, hexAddress: true, ibcRelayerInd: true, icsCurrentPerSigned: true, icsPerf: true, 
        historicalPerSigned: true, historicalSigned: true, historicalMissed: true, index:true, 
        longMissed: true, longNetApr: false, longOracleMissed: true, longOraclePerSigned: true, 
        longPerSigned: currentNetwork.details.ui.validators.hideLongPerSignedMobile, longSigned: true, midMissed: true, midOracleMissed: true, 
        midOraclePerSigned: true, midPerSigned: true, midSigned: true, name: false, operatorAddress: true, oracleMissedVotes: true, publicKey: true, 
        securityContact: true, selfBond: true, selfBondPercentage: true, slashingWindowMissedBlocks: true, slashingWindowUptime: true, 
        status: true, timesSlashed: true, tombstoned: true, totalUndelegationAmount: true, 
        uniqueDelegates: true, uniqueDelegatesChange1d: true, uniqueDelegatesChange30d: true, uniqueDelegatesChange7d: true, 
        uniqueUndelegations: true, voteParticipation: true, voteParticipationOverall: true, 
        votingPower: true, votingPowerChange1d: true, votingPowerChange30d: true, votingPowerChange7d: true, votingPowerPercentage: true, 
        website: true,
      };
    } else {
      columnsConfig = {
        averageDelegation: true, commission: true, commissionMaxChangeRate: true, commissionMaxRate: true, commissionPlus: false, 
        consensusNodeAddress: true, currentMissed: true, 
        currentOracleMissed: true, currentOraclePerSigned: false, currentPerSigned: currentNetwork.details.ui.validators.slashingWindowUptime, 
        currentSigned: true, dailyMissed: true, dailyOracleMissed: true, dailyOraclePerSigned: true, 
        dailyPerSigned: true, dailySigned: true, description: true, extraData1: currentNetwork.details.ui.validators.hideExtraData1, 
        extraData3: currentNetwork.details.ui.validators.hideExtraData3, fav: false, hexAddress: true, 
        historicalPerSigned: true, historicalSigned: true, historicalMissed: true, 
        ibcRelayerInd: false, 
        icsCurrentPerSigned: !currentNetwork.details.ui.validators.icsCurrentPerSigned, icsPerf: !currentNetwork.details.ui.validators.icsPerf, 
        index:false, longMissed: true, longNetApr: false, longOracleMissed: true, longOraclePerSigned: false, 
        longPerSigned: currentNetwork.details.ui.validators.hideLongPerSignedMobile, longSigned: true, midMissed: true, midOracleMissed: true, 
        midOraclePerSigned: true, midPerSigned: true, midSigned: true, name: false, operatorAddress: true, oracleMissedVotes: true, publicKey: true, 
        securityContact: true, selfBond: true, selfBondPercentage: true, slashingWindowMissedBlocks: true, 
        slashingWindowUptime: !currentNetwork.details.ui.validators.slashingWindowUptime, status: false, timesSlashed: true, 
        tombstoned: true, totalUndelegationAmount: true, uniqueDelegates: false, uniqueDelegatesChange1d: true, uniqueDelegatesChange30d: true, 
        uniqueDelegatesChange7d: true, uniqueUndelegations: true, voteParticipation: false, voteParticipationOverall: true, 
        votingPower: false, votingPowerChange1d: true, votingPowerChange30d: true, votingPowerChange7d: true, votingPowerPercentage: false, 
        website: true,
      };
    } 

    // console.log("columns config is: ", columnsConfig);
    return columnsConfig;
  }*/

  static renderBadge(condition, target, title, imgSrc) {
    if (condition !== true) return "";

    return <td className="view-tag"><a className="animLink" href={SessionUtils.getUrl(target)} 
      title={title}> {MainUtils.formatViewIcon(imgSrc)}</a></td>
  }

  static prepValidators(validators) {
    let newValidators = Utilities.addIndex(validators, "poolId");
    let cumulativeWeight = 0
    for(let i=0;i < newValidators.length; i++) {
      let val = newValidators[i];
      cumulativeWeight += val.votingPowerPercentage;
      val.cumulativeWeight = cumulativeWeight;
      val.commissionPlus = val.commission;
    }

    return newValidators;
  }

  static opAddressFormatter(cell, row) {
    let label = SPUtilities.addressLabelShortFormatter(cell, null, true);
    return (<span><a className="tourValName animLinkTable"
      href={SessionUtils.getUrl("val/" + row[currentNetwork.details.ui.identifierAttribute])}>
        {label}
      </a>{AddressUtils.copyLink(cell)}</span>);
  }

}

export default ValidatorUtils;
