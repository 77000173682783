import React from 'react';
// import { set } from 'react-ga';
import { MultiSelect } from "react-multi-select-component";
// import MultiSelect from "react-multi-select-component";

import "./AppList.css";

class MultiList extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
      };
      this.setSelected = this.setSelected.bind(this);
  }

  async componentDidMount() {
  }
  
  setSelected(e) {
    this.props.setSelected(e);
  }

  render() {
    // if (!this.props.allItems || this.props.allItems.length === 0) return "";
    // console.log("all items: ", this.props.allItems);
    let selectedValues = this.props.selected;
    // console.log("selected items: ", selectedValues);

    const valueRenderer = () => {
      if (!selectedValues || selectedValues.length === 0) return "Select Token(s)";

      if (selectedValues.length === 1) return `${selectedValues[0].label}`;

      return selectedValues.slice(0, 3).map(({ label }) => label + ", ");
    };

    return (
      <MultiSelect options={this.props.allItems} value={this.props.selected} className="multiSelectLimitWidth multi-select"
        onChange={this.setSelected} labelledBy="Compare with" hasSelectAll={false} valueRenderer={valueRenderer} />
    );
  }

}

export default MultiList;
