import React from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import {Container} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ReactTooltip from "react-tooltip";
import paginationFactory from 'react-bootstrap-table2-paginator';

import ApiUtils from '../util/ApiUtils';
import ValNav from './ValNav';

import ValHeader from '../validator/ValHeader';
import "./Delegations.css";
// import '../styles/section.css';
// import '../styles/tables.css';
import Utilities from "../util/Utilities";
import UIUtils from "../util/UIUtils";
import SPUtilities from "../util/SPUtilities";
import MainUtils from '../main/MainUtils';
import tooltips from '../tooltips';
import ChartTitle from '../components/ChartTitle';
import BidirectionChangeAndCumulativeChart from '../charts/BidirectionChangeAndCumulativeChart';
import DataUtils from '../util/DataUtils';
import currentNetwork from '../currentNetwork';
import ValidatorUtils from '../validators/ValidatorUtils';
import ChainUtils from '../util/ChainUtils';
import BaseBarChart from '../charts/BaseBarChart';
import SessionUtils from '../util/SessionUtils';
import RespUtils from '../util/RespUtils';
import DownloadUtils from '../util/DownloadUtils';
import CoinUtils from '../util/CoinUtils';

class Delegations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      delegations: [],
      undelegationSummary: [],
      undelegations: [],
      val: {},
      delegationDistributionToken: [],
      delegationDistributionUsd: [],
      width: window.innerWidth,
      size: 10,
      isLoading: true,
    }
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  updateDimensions() {
    this.setState({width: window.innerWidth});
  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  async componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    SessionUtils.setNetwork(this);

    let url = "listData?type=delegations&undel=" + currentNetwork.details.ui.delegations.includeUndelInSameCall;
    url += ValidatorUtils.getIdParam(this);

    // console.log(url);
    const allData = await ApiUtils.get(url);
    // console.log("allData is:", allData);

    if (!allData) {
      this.setState({error: true, isLoading: false});
      return;
    }

    let undelListObj;
    let undelSummaryObj;
    if (currentNetwork.details.ui.delegations.includeUndelInSameCall === true) {
      undelListObj = allData["undelegations"];
      undelSummaryObj = allData["undelegationSummary"]
    } else {
      let undelUrl = "listData?type=undelegations&address=" + this.props.match.params.address;
      // console.log(undelUrl);
      const undelegationsData = await ApiUtils.get(undelUrl);
      undelListObj = undelegationsData["undelegations"]
      undelSummaryObj = undelegationsData["undelegationSummary"]
    }

    let val = allData["val"];
    let undelegations = Utilities.addIndex(undelListObj);
    let undelegationSummary = DataUtils.addCumulativeData(undelSummaryObj, "undelegationAmount", 
      "cumulativeUndelegationAmount");
    // console.log(undelegationSummary);

    let delegations = Utilities.addIndex(allData["delegations"]);
    
    this.setState({"val": val, "delegations": delegations, 
      "undelegationSummary": undelegationSummary, "notification": allData["notification"], 
      "undelegations": undelegations, isLoading: false,
      "delegationDistributionToken": allData["delegationDistributionToken"], 
      "delegationDistributionUsd": allData["delegationDistributionUsd"], 
    });
  }

  render() {
    let loadMsg = UIUtils.getLoading(this);
    if (loadMsg) return loadMsg;

    return (
      <div>
        <ValHeader val={this.state.val} notification={this.state.notification} title="Delegates" thisObj={this}/>
        <ReactTooltip id="main" place="top" type="dark" effect="float" multiline={true} />
        <Container fluid className="containerLayout container-fluid chartContainer">
          <div className="chartGridContainer">
            <div className="chartBg">{this.renderDelegations()}</div>
            {ChainUtils.isNotMatic() && <div className="chartBg">{this.renderUndelegations()}</div>}
            {ChainUtils.isNotMatic() && <div className="chartBg">
              <BidirectionChangeAndCumulativeChart title={"Pending Undelegations ($" + currentNetwork.details.app.token + ")"}
                  chartVisualId="pending-undelegations" enableSave={true} 
                  xAxis="Date" yAxis="Pending Undelegations" xAxisValueAttr="title" cumulativeAttr="cumulativeUndelegationAmount"
                  valueAttr="undelegationAmount" showVerticalLabel={false} 
                  showLegend={true} adjustRange={true}
                  range={[0,3]} data={this.state.undelegationSummary} tip={tooltips.networkCharts.undelegationSummary} 
                  dataKey="title" columns={[
                    {text: "Date", dataField: "title", sort: true, },
                    {text: "Undelegation Amount", dataField: "undelegationAmount", sort: true, },
                    {text: "Cumulative Undelegation Amount", dataField: "cumulativeUndelegationAmount", formatter: CoinUtils.formatCoins, sort: true, },
                  ]} />
            </div>}
            <div className="chartBg" id="delegationDistributionToken">
              <BaseBarChart title={"Delegation Distributions in % (by " + currentNetwork.details.app.token + " amount)"} 
                chartVisualId="delegation-distributions-token" enableSave={true} 
                shortTitle={"Del. Distribution in % (by " + currentNetwork.details.app.token + ")"} 
                tip={tooltips.valCharts.delegationDistribution}
                xAxis={"Amount in " + currentNetwork.details.app.token} yAxis="Percentage Delegations"
                showVerticalLabel={true} valueAttr="percentDelegations" showTotalLabel={false} xAxisValueAttr="title"
                data={this.state.delegationDistributionToken}
                dataKey="title" columns={[
                  {text: "Date", dataField: "title", sort: true, },
                  {text: ("% Delegations by " + currentNetwork.details.app.token), dataField: "percentDelegations", sort: true, },
                ]} />
            </div>
            {this.state.delegationDistributionUsd != null && (<div className="chartBg" id="delegationDistributionUsd">
              <BaseBarChart title="Delegation Distributions in % (by $ value)" shortTitle="Del. Distribution in % (by $)" 
                chartVisualId="delegation-distributions-usd" enableSave={true} 
                tip={tooltips.valCharts.delegationDistributionUsd}
                xAxis={"Amount in $"} yAxis="Percentage Delegations"
                showVerticalLabel={true} valueAttr="percentDelegations" showTotalLabel={false} xAxisValueAttr="title"
                data={this.state.delegationDistributionUsd} 
                dataKey="title" columns={[
                  {text: "Date", dataField: "title", sort: true, },
                  {text: "% Delegations by $ Value", dataField: "percentDelegations", sort: true, },
                ]} />
            </div>)}
          </div>
        </Container>
        <ValNav address={this.state.val[currentNetwork.details.ui.identifierAttribute]} val={this.state.val} />
      </div>
    );
  }

  static dateFormatter(value, row) {
      return SPUtilities.getDateTimeAsText(value);
  }

  renderDelegations() {
    var columns = [
      {text: "#", dataField: "index", sort: true, headerStyle: Utilities.width(5)},
      {text: "Address", dataField: "address", formatter: MainUtils.addressFormatterDel, sort: true, headerStyle: Utilities.width(40)},
      {text: "Stake", dataField: "stake", formatter: SPUtilities.formatCoins, sort: true,  sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(25)},
    ];

    const options = UIUtils.getPageOptionsSmall(this, 5);

    return (
      <React.Fragment>
        <ChartTitle title="Delegations" tip={tooltips.valDetails.delegations} 
          downloadData={DownloadUtils.getDownloadDataForChart(this.state.delegations, "address", "stake")} />
        <br/>
        <div>Delegation count: {this.state.delegations.length}</div>
        <BootstrapTable keyField='address' data={ this.state.delegations }
          columns={ columns } striped options={options}
          condensed noDataIndication="No data" pagination={ paginationFactory(options) }/>
      </React.Fragment>
    )
  }

  renderUndelegations() {
    var columns = [
      {text: "#", dataField: "index", sort: true, headerStyle: Utilities.width(5)},
      {text: "Address", dataField: "address", formatter: MainUtils.addressFormatterDel, sort: true, headerStyle: Utilities.width(45)},
      {text: "Amount", dataField: "amount", formatter: SPUtilities.formatCoins, sort: true,  sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(15)},
      {text: "Release Time", dataField: "releaseTimeEpoch", formatter: SPUtilities.epochFormatter, sort: true,  sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(35)},
    ];

    const options = UIUtils.getPageOptionsSmall(this, 5);

    return (
      <React.Fragment>
        <ChartTitle title="Pending Undelegations" tip={tooltips.valDetails.undelegations} 
          downloadData={DownloadUtils.getDownloadData(this.state.undelegations, ["address", "amount", "releaseTimeEpoch"])} />
        <br/>
        <div>Undelegation count: {this.state.val.uniqueUndelegations ? this.state.val.uniqueUndelegations : 0}. 
            &nbsp;{RespUtils.getRespLabel("Amount", "Total undelegation amount")}: 
            {SPUtilities.formatCoinsWithDefault(this.state.val.totalUndelegationAmount, 0)} ${currentNetwork.details.app.token}</div>
        <BootstrapTable keyField='address' data={ this.state.undelegations }
          columns={ columns } striped options={options}
          condensed noDataIndication="No data" pagination={ paginationFactory(options) }/>
      </React.Fragment>
    )
  }
}

export default Delegations;
