import React from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
// import Watermark from '@uiw/react-watermark';

import MainUtils from '../main/MainUtils';
import '../styles/section.css';
import AddressUtils from '../util/AddressUtils';
import CoinUtils from '../util/CoinUtils';
import RespUtils from '../util/RespUtils';
import SPCalc from '../util/SPCalc';
import TinyPieChart from '../charts/TinyPieChart';
import tooltips from '../tooltips';
import SessionUtils from '../util/SessionUtils';
import common from '../common';

class ProposalUtils extends React.Component {
  static statusFormatter(status, statusMap) {
    return statusMap[status];
  }

  static statusFormatterTable(status, row, index, thisObj) {
    return thisObj.state.statusMap[status];
  }

  static typeFormatter(proposalType, proposalTypeMap) {
    // console.log(thisObj.state.proposalType);
    if (proposalTypeMap && proposalType in proposalTypeMap) {
      return proposalTypeMap[proposalType];
    }

    return proposalType;
    // return proposalType;
  }

  static typeFormatterTable(proposalType, row, index, thisObj) {
    // console.log(thisObj.state.proposalTypeMap);
    let proposalTypeMap = thisObj.state.proposalTypeMap;
    if (proposalTypeMap && proposalType in proposalTypeMap) {
      return proposalTypeMap[proposalType];
    }

    return proposalType;
    // return proposalType;
  }

  static percentVotesFormatter(percentVotes) {
    if (!percentVotes) {
      return "";
    }

    return CoinUtils.percentFormatter(percentVotes);
  }

  static timeLeft(inputValue, proposal) {
    if (!inputValue) {
      return "";
    }
    
    if (proposal["status"] !== "PROPOSAL_STATUS_VOTING_PERIOD") {
      return "";
    }

    return SPCalc.formatTimeLeft(inputValue);
  }

  static tallyFormatter(inputValue, proposal) {
    // console.log(cell, " - total votes: ", row.totalVotes);
    if (!inputValue || !proposal.totalVotes) {
      return "0%";
    }

    return CoinUtils.percentFormatter(inputValue * 100/proposal.totalVotes);
  }

  static tallyContribution(inputValue, proposal) {
    return inputValue * 100/proposal.totalVotes;
  }

  static renderProposal(cell, row) {
    return ProposalUtils.renderProposalBySize(cell, row, false);
  }

  static renderProposalShort(cell, row) {
    return ProposalUtils.renderProposalBySize(cell, row, true);
  }
  
  static renderProposalBySize(cell, row, shortInd, prefixInd) {
    if (!cell) {
      return "";
    }

    let textSize = [30, 80];
    if (shortInd === true) {
      textSize = [30, 70];
    }

    let prefix = prefixInd ? "Proposal - " : "";
    let length = RespUtils.isMobileView() ? textSize[0] : textSize[1];
    let label = prefix + MainUtils.getShortText(cell, length);
    const onClick = e => e.stopPropagation();
    return (<span onClick={ onClick }><a className="animLinkTable" href={SessionUtils.getUrl("/proposal/" + row.proposalId)}>
        {ProposalUtils.renderSpamInd(row)}
        {label}</a></span>);
  }

  static renderSpamInd(row) {
    if (ProposalUtils.isSpam(row)) {
      return (<span data-for="main" data-tip={"Likely Spam Proposal"} data-iscapture="true">
                <i aria-label="likely spam proposal" className="mediaCustom fas fa-solid fa-ban" 
                  style={{"color": "#ff0000"}} size="xs" />&nbsp;</span>)
    }

    return "";
  }

  static getTextOrAnchor(part) {
    // use whatever you want here
    const URL_REGEX = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

    return URL_REGEX.test(part) ? <a href={part}>{part} </a> : part + " ";
  }

  static formatAddress(cell, row) {
    if (!cell) {
      return cell;
    }

    if (!row.name) {
      return <a href={SessionUtils.getUrl("/address/" + cell)} className="animLinkVisual">{AddressUtils.addressFormatterSimpleNoCopy(cell)}</a>;
    }

    return MainUtils.nameFormatterTable(row.name, row);
  }

  static formatVote(cell, row) {
    // console.log(cell);
    return ProposalUtils.formatVoteByProp(cell, row, null, row.status);
  }

  static formatVoteByProp(cell, row, index, proposal) {
    if (!cell || cell === "") {
      if (proposal && proposal.votesAvailable === 'true') {
        if (common.proposal.finalStatuses.includes(proposal.status)) {
          return "Did not vote";
        } else if (common.proposal.votingStatus === proposal.status) {
          return "Not voted yet";
        }
      }
      return cell;
    }

    return common.proposal.vote[cell];
  }

  static formatVoteProgress(cell, row) {
    return ProposalUtils.renderVotes(row, true);
  }

  static renderVotes(row, tiny) {
    if (row.status === "PROPOSAL_STATUS_VOTING_PERIOD") {
      const chartTooltip = (data) => {
        return (<div className="pieChartTooltip">
            {data.tallyType}: {MainUtils.percentFormatter(data.tally, data)}%
          </div>);
      }

      return <TinyPieChart customTooltip={chartTooltip} desc={tooltips.stats.recentBlock} countAttr="tally" labelAttr="tallyType" tiny={tiny}
                data={ProposalUtils.getTallyData(row)} tip="Chart showing the current TVL breakdown" />
    } else if (common.proposal.finalStatuses.includes(row.status)) {
      const chartTooltip = (data) => {
        return (<div className="pieChartTooltip">
            {data.tallyType}: {MainUtils.percentFormatter(data.tally, data)}%
          </div>);
      }

      return <TinyPieChart customTooltip={chartTooltip} desc={tooltips.stats.recentBlock} countAttr="tally" labelAttr="tallyType" tiny={tiny}
                data={ProposalUtils.getTallyData(row)} tip="Chart showing the current TVL breakdown" />
    } else {
      return "";      
    }
  }

  static getTallyData(row) {
    let data = [{"tally": ProposalUtils.tallyContribution(row.tallyYes, row), "tallyType": "Yes"},
      {"tally": ProposalUtils.tallyContribution(row.tallyNo, row), "tallyType": "No"},
      {"tally": ProposalUtils.tallyContribution(row.tallyNoWithVeto, row), "tallyType": "Veto"},
      {"tally": ProposalUtils.tallyContribution(row.tallyAbstain, row), "tallyType": "Abstain"},
    ];

    return data;
  }

  static getVoteProgressValue(vote, totalPossibleVotes) {
    return vote * 100 / totalPossibleVotes;
  }


  static renderIndex(cell, row) {
    return <a className="animLinkTable" href={SessionUtils.getUrl("/proposal/" + row.proposalId)}>{cell}</a>;
  }

  static isSpam(proposal) {
    return (proposal.spamProposalInd === 1);
  }
}

export default ProposalUtils;
