import React from 'react';

import currentNetwork from '../currentNetwork';
import StakeFormatter from './StakeFormatter';

import "./StakeFormatter.css";
import SessionUtils from '../util/SessionUtils';
import UIUtils from '../util/UIUtils';

class DelegateWithSmartStake extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    }
  }

  async componentDidMount() {
    SessionUtils.activateOnLoad(this);
  }

  render() {
    let loadMsg = UIUtils.getLoading(this);
    if (loadMsg) return loadMsg;

    if (currentNetwork.details.ui.delegateToSmartStake === false) {
      return "";
    }

    return (
      <span className="delegateContainer">
        <div className="miniStat">
          <div className="miniStatTitle">
            <StakeFormatter name="Smart Stake" address={currentNetwork.details.app.smartStakeAddress} 
                label="Delegate to Smart Stake" isSS="true" />
          </div>
        </div>
      </span>
    );
  }
}

export default DelegateWithSmartStake;
