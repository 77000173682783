import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import tooltips from '../tooltips';
import "./Airdrop.css";
import Tooltip from '../components/Tooltip';
import RespUtils from '../util/RespUtils';
import LogoUtils from '../util/LogoUtils';
import UIUtils from '../util/UIUtils';
import SPUtilities from '../util/SPUtilities';
import ProjectUIUtils from '../projects/ProjectUIUtils';
import Special from '../components/Special';
import MainUtils from '../main/MainUtils';
import AirdropUtils from './AirdropUtils';

class Airdrop extends Component {
    render() {
        // console.log("in airdrop");
        // console.log(this.props.airdrop);
        return (
            <Col className="blockBg" xs={12} sm={12} md={6} lg={4} xl={4}>
                <div className="airdrop">
                    <div className="airdropTitle">
                        <div className="airdropLabel">
                            <span className="airdropLogo">{LogoUtils.projectLogo(this.props.airdrop.logo, this.props.airdrop)}</span>
                            <span className="textLabel">{Special.projectFormatter(this.props.airdrop.name, this.props.airdrop)} {AirdropUtils.renderStatus(this.props.airdrop.airdropStatus)}</span>
                            <div className="airdropMedia">{ProjectUIUtils.formatMediaSmall(this.props.airdrop.twitter, this.props.airdrop)}</div>
                        </div>
                    </div>
                    <div className="airdropContent">
                        <p className="statDesc">{this.props.airdrop.desc}</p>
                        <div className="statDesc" dangerouslySetInnerHTML={{ __html: this.props.airdrop.qualificationCriteria }} />
                    </div>
                    <p className="airdropNav">
                        <a className="animLink airdropLink linkBorder" href={this.getDetailsLink()} target="_blank">Details</a>
                        {this.props.airdrop.eligibilityCheck !== null && <a className="animLink airdropLink linkBorder" href={this.props.airdrop.eligibilityCheck} target="_blank">Eligibility Check</a> }
                        {this.props.airdrop.airdropStatus === 'Active' && <a className="animLink airdropLink linkBorder" href={this.props.airdrop.airdropWebsite} target="_blank">Claim</a> }
                    </p>
                </div>
            </Col>
        );
    }

    getDetailsLink() {
        if (this.props.airdrop.detailsLink !== null) {
            return this.props.airdrop.detailsLink;
        }
        
        return SPUtilities.getUrl(this.props.airdrop.website);
    }
}

export default Airdrop;
