import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import Amplify from 'aws-amplify';
import config from './config';

// Amplify.configure({
// 	API: {
// 		endpoints: [
// 			{name: 'mainnet', endpoint: config.apiGatewayMainnet.URL, region: config.apiGatewayMainnet.REGION},
// 			{name: 'crossfire', endpoint: config.apiGatewayCrossfire.URL, region: config.apiGatewayCrossfire.REGION},
// 			{name: 'testnet', endpoint: config.apiGatewayTestnet.URL, region: config.apiGatewayTestnet.REGION},
// 			{name: 'devnet', endpoint: config.apiGatewayDevnet.URL, region: config.apiGatewayDevnet.REGION},
// 		]
// 	}
// });

Amplify.configure({
	API: {
		endpoints: [
			{
				name: 'stakingService',
				endpoint: config.apiGateway.URL,
				region: config.apiGateway.REGION
			},
			{
				name: 'special',
				endpoint: config.apiGateway.URL_SPECIAL,
				region: config.apiGateway.REGION
			},
			{
				name: 'common',
				endpoint: config.apiGateway.URL_COMMON,
				region: config.apiGateway.REGION
			}
		]
	}
});

ReactDOM.render(<App />, document.getElementById('root'));
registerServiceWorker();
