import React from 'react';
import {Row, Col} from 'react-bootstrap';
import ReplayIcon from '@material-ui/icons/Replay';
import SearchIcon from '@material-ui/icons/Search';
import {Form} from 'react-bootstrap';

import ApiUtils from '../util/ApiUtils';
import Loading from "../components/Loading";

import ChartTitle from "../components/ChartTitle";
import common from "../common";
import tooltips from '../tooltips';
import "./NetworkDistributionComparison.css";
import "../base/AppList.css";
import BaseBarChart from '../charts/BaseBarChart';
import RespUtils from '../util/RespUtils';
import DownloadUtils from '../util/DownloadUtils';
import ChartUtils from '../util/ChartUtils';
import currentNetwork from '../currentNetwork';


class StakerDistributionRange extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      width: window.innerWidth,
      distribution: [],
      minAmount: null,
      maxAmount: null,
      lineProps: [],
      lineHover: null,
      responsive: true,
      totalStakers: 0
    }

    this.updateDimensions = this.updateDimensions.bind(this);
    this.updateDistribution = this.updateDistribution.bind(this);
    this.renderAdditionalLink = this.renderAdditionalLink.bind(this);
    this.handleMinAmountChange = this.handleMinAmountChange.bind(this);
    this.handleMaxAmountChange = this.handleMaxAmountChange.bind(this);
    this.resetCriteria = this.resetCriteria.bind(this);
    this.search = this.search.bind(this);
  }

  async updateDistribution(minAmount, maxAmount) {
    let url = "listData?type=stakerDistributionRange";

    if (minAmount != null) {
        url += "&minAmount=" + minAmount;
    }
    if (maxAmount != null) {
        url += "&maxAmount=" + maxAmount;
    }
    if (this.props.token != null) {
      url += "&tokenCode=" + encodeURIComponent(this.props.token);
    }

    // console.log("url is: ", url);
    const allData = await ApiUtils.get(url);
    // console.log("allData is:", allData);

    this.setState({"distribution": ChartUtils.normalizeFloatTitleLength(allData["stakerDistribution"]), 
      "totalStakers": allData["totalStakers"], "isLoading": false, });
  }

  updateDimensions() {
    this.setState({width: window.innerWidth});
  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  async componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);

    this.updateDistribution(null, null);
  }

  render() {
    if (this.state.isLoading) {
      return <Loading />;
    }

    let assetToken = this.props.token != null ? this.props.token : this.props.network;
    let chartVisualId = currentNetwork.details.networkName + "-staker-dist-range";
    const downloadChart = () => {
      // console.log(document);
      // console.log(chartVisualId);
      // console.log(currentNetwork.details.app.title);
      ChartUtils.downloadChart(document, chartVisualId, currentNetwork.details.app.title);
    };

    return (
      <div className="chartBg" id={"div" + chartVisualId}>
        <ChartTitle title={"Staker Distribution $" + assetToken + " (" + this.state.totalStakers + ")"} shortTitle={"Stakers (" + this.state.totalStakers + ")"} 
          tip={tooltips.networkCharts.stakerDistributionRange} 
          showAdditionalLink={true} renderAdditionalLink={this.renderAdditionalLink} 
          downloadData={DownloadUtils.getDownloadDataForChart(this.state.distribution, "title", "percentStakers")} 
          saveChart={downloadChart} enableSave={this.props.enableSave} />
        <BaseBarChart xAxis={"Amount in " + assetToken} yAxis="Percentage Stakers" fsElementId="stakerDistributionTokenRange" 
          showVerticalLabel={true} valueAttr="percentStakers" showTotalLabel={false} xAxisValueAttr="title"
          data={this.state.distribution} hideTitle={true} />
      </div>
    );
  }

  renderAdditionalLink() {
    if (RespUtils.isMobileView()) {
      return (<Form ref={form => this.rangeForm = form} onSubmit={this.search.bind(this)}>
        <Form.Group as={Row} controlId="stakedAmount" className="amountStyle">
          <Form.Control type="text" placeholder="min" className="w-25"
              onChange={this.handleMinAmountChange} defaultValue={this.state.minAmount}/>
          <Form.Control type="text" placeholder="max" className="w-25"
              onChange={this.handleMaxAmountChange} defaultValue={this.state.maxAmount}/>
          <SearchIcon htmlColor={common.colors.text} onClick={this.search} className="animLink" />
          <ReplayIcon htmlColor={common.colors.text} onClick={this.resetCriteria} className="animLink" />
        </Form.Group>
      </Form>);
    }

    return (<Form ref={form => this.rangeForm = form} onSubmit={this.search.bind(this)} className="stakerRangeForm">
              <Form.Group as={Row} controlId="stakedAmount">
                <Col sm={3}><Form.Control type="text" placeholder="min"
                    onChange={this.handleMinAmountChange} defaultValue={this.state.minAmount}/></Col>
                <Col sm={3}><Form.Control type="text" placeholder="max"
                    onChange={this.handleMaxAmountChange} defaultValue={this.state.maxAmount}/></Col>
                <SearchIcon htmlColor={common.colors.text} onClick={this.search} className="animLink" />
                <ReplayIcon htmlColor={common.colors.text} onClick={this.resetCriteria} className="animLink" />
              </Form.Group>
            </Form>);
  }

  resetCriteria() {
    this.setState({"minAmount": null, "maxAmount": null});
    this.updateDistribution(null, null);
    this.rangeForm.reset();
  }

  handleMinAmountChange(e) {
    let minAmount = e.target.value;
    this.setState({"minAmount": minAmount});
  } 

  handleMaxAmountChange(e) {
    let maxAmount = e.target.value;
    this.setState({"maxAmount": maxAmount});
  } 

  search() {
    let minAmount = this.state.minAmount;
    let maxAmount = this.state.maxAmount;
    // console.log("in search: ", minAmount, " - ", maxAmount);

    let validMin = false;
    let validMax = false;
    if (minAmount && minAmount != "" && minAmount.trim() != "") {
      // this.updateDistribution(minAmount, this.state.maxAmount);
      if (isNaN(minAmount) || minAmount < 0 || minAmount > 100000000000) {
        alert("Amount must be a number between 0 and 100 billion");
        return;
      }
      minAmount = parseInt(minAmount);
      validMin = true;
    }

    if (maxAmount && maxAmount != "" && maxAmount.trim() != "") {
      // this.updateDistribution(maxAmount, this.state.maxAmount);
      if (isNaN(maxAmount) || maxAmount < 0 || maxAmount > 100000000000) {
        alert("Amount must be a number between 0 and 100 billion");
        return;
      }

      maxAmount = parseInt(maxAmount);
      validMax = true;
    }

    if (validMin && validMax) {
      if (maxAmount < minAmount) {
        alert("Minimum amount must be smaller than maximum amount");
      } else {
        this.updateDistribution(minAmount, maxAmount);
      }
    } else if (validMin) {
      this.updateDistribution(minAmount, null);
    } else if (validMax) {
      this.updateDistribution(null, maxAmount);
    } else if ((!minAmount || minAmount === "") && (!maxAmount || maxAmount === "")) {
      this.updateDistribution(null, null);
    }
  } 
}

export default StakerDistributionRange;
