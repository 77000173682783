import React from 'react';
import CoinUtils from './CoinUtils';
import NetworkUtils from '../network/NetworkUtils';
import Utilities from './Utilities';
import MainUtils from '../main/MainUtils';
import StakeUtils from './StakeUtils';
import common from '../common';

class MetricsUtils extends React.Component {
    static getAvailableMetrics(coinstat, disabledMetrics) {
        let availableMetrics = common.allCompareMetrics.filter(item => {
            if (disabledMetrics.includes(item.code)) {
                return false;
            }

            const value = coinstat[item.coinStatAttr];
            // return value !== null && value !== undefined && typeof value === "number" && value !== 0;
            const numericValue = Number(value); // Convert value to a number
            return value !== null && value !== undefined && !isNaN(numericValue) && numericValue !== 0;
        });

        return availableMetrics;
    }

    static getMetricsColumns(selectedMetric1, selectedMetric2) {
        const matchFields = ["title", selectedMetric1, selectedMetric2];

        const headerSortingClasses = (column, sortOrder, isLastSorting, colIndex) => (
            sortOrder === 'asc' ? 'sorting-asc' : 'sorting-desc'
        );

        var columns = [
            {text: "Date", dataField: "title", sort: true, headerStyle: Utilities.respWidth(20, 15, 9), },
            {text: "Average Delegation", dataField: "averageDelegation", formatter: CoinUtils.moneyFormatter, headerStyle: Utilities.respWidth(20, 15, 9), sort: true, },
            {text: "Average Delegation USD", dataField: "averageDelegationUsd", formatter: CoinUtils.moneyFormatter, headerStyle: Utilities.respWidth(20, 15, 9), sort: true, },
            {text: "Market Cap", dataField: "usdMcap", formatter: NetworkUtils.mcapFormatter, sort: true, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
            {text: "FDV", dataField: "fdvMcap", formatter: NetworkUtils.mcapFormatter, sort: true, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
            {text: "Circulating Supply", dataField: "circulatingSupply", formatter: CoinUtils.stakeFormatterRounded, sort: true, sortFunc: Utilities.sortNumeric, headerStyle:  Utilities.respWidth(20, 15, 9), headerSortingClasses},
            
            {text: "Total Supply", dataField: "totalSupply", formatter: CoinUtils.stakeFormatterRounded, sort: true, sortFunc: Utilities.sortNumeric, headerStyle:  Utilities.respWidth(20, 15, 9), headerSortingClasses},
            {text: "Total Stake", dataField: "totalStake", formatter: CoinUtils.stakeFormatterRounded, sort: true, sortFunc: Utilities.sortNumeric, headerStyle:  Utilities.respWidth(20, 15, 9), headerSortingClasses},
            {text: "Total Stake USD*", dataField: "totalStakeUsd", formatter: NetworkUtils.mcapFormatter, sort: true, sortFunc: Utilities.sortNumeric, headerStyle:  Utilities.respWidth(20, 15, 9), headerSortingClasses},
            {text: "Percent Staked*", dataField: "percentStaked", formatter: NetworkUtils.percentStakeFormatter, sort: true, sortFunc: Utilities.sortNumeric, headerStyle:  Utilities.respWidth(20, 15, 9), headerSortingClasses},
            {text: "Staking APR*", dataField: "apr", formatter: NetworkUtils.aprFormatter, sort: true, sortFunc: Utilities.sortNumeric, headerStyle:  Utilities.respWidth(20, 15, 9), headerSortingClasses},
            {text: "Stake % Change (7d)", dataField: "totalStakePC7d", formatter: NetworkUtils.formatGrowth, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
            {text: "Stake % Change (30d)", dataField: "totalStakePC30d", formatter: NetworkUtils.formatGrowth, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},

            {text: "Block Rate", dataField: "blockRate", headerStyle: Utilities.respWidth(20, 15, 9), sort: true, },
            {text: "Block Sign Rate", dataField: "signRate", headerStyle: Utilities.respWidth(20, 15, 9), sort: true, },
            {text: "Median Commission", dataField: "medianCommission", headerStyle: Utilities.respWidth(20, 15, 9), sort: true, },

            {text: "Inactive Val Stake USD", dataField: "unbondedStakeUsd", formatter: NetworkUtils.mcapFormatter, sort: true, sortFunc: Utilities.sortNumeric, headerStyle:  Utilities.respWidth(20, 15, 9), headerSortingClasses},
            {text: "Inactive Val Stake %*", dataField: "unbondedStakePercent", formatter: NetworkUtils.percentStakeFormatter, sort: true, sortFunc: Utilities.sortNumeric, headerStyle:  Utilities.respWidth(20, 15, 9), headerSortingClasses},
            {text: "Median Commission", dataField: "medianFee", formatter: MainUtils.percentFormatter, sort: true, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
            {text: "Delta Active Accounts", dataField: "deltaActiveAccounts", formatter: NetworkUtils.activeAccountFormatter, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
            {text: "Addresses (>0 balance)", dataField: "activeAccounts", formatter: NetworkUtils.activeAccountFormatter, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
            {text: "Recent Avg Votes", dataField: "recentAvgVotes", formatter: NetworkUtils.formatPropData, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
            {text: "Recent Max Votes", dataField: "recentMaxVotes", formatter: NetworkUtils.formatPropData, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
            {text: "Unique Delegates", dataField: "uniqueDelegates", formatter: NetworkUtils.uniqueDelegatesFormatter, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
            {text: "Unique Delegations", dataField: "uniqueDelegations", sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
            {text: "# of Validators", dataField: "consensusValidators", sort: true, sortFunc: Utilities.sortNumeric, headerStyle:  Utilities.respWidth(20, 15, 9), headerSortingClasses},
            {text: "Average Voting Power", dataField: "avgVotingPower", formatter: CoinUtils.usdFormatter, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
            {text: "Val Below Avg Voting Power", dataField: "valBelowAvgPower", sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
            {text: "Nakamoto Coefficient", dataField: "nakamotoIndex", formatter: NetworkUtils.formatNakamoto, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
            
            {text: "Gini Coefficient", dataField: "gini", formatter: NetworkUtils.formatGini, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
            {text: "Top 1 equals bottom N Val %", dataField: "oneEqualsNBottomValPercent", sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
                    
            {text: "Transaction Count", dataField: "txCount", sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
            {text: "Top 100 Delegate Stake Share", dataField: "top100StakeShare", formatter: CoinUtils.percentFormatter, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
            {text: "Undelegations Count", dataField: "uniqueUndelegations", formatter: NetworkUtils.countFormatter, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
            {text: "Undelegation Amount", dataField: "undelegationAmount", formatter: CoinUtils.stakeFormatterRounded, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
            {text: "Undel Amt % of Total Supply", dataField: "undelPercentOfTotalSupply", formatter: NetworkUtils.percentFormatter, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
            {text: "Undel Amt % of Not Staked Supply", dataField: "undelPercentOfNotStakedSupply", formatter: NetworkUtils.percentFormatter, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
            {text: "USD Price", dataField: "usdPrice", formatter: CoinUtils.moneyFormatter, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
            {text: "24h Transactions", dataField: "txCount24h", formatter: NetworkUtils.format24hTxs, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
            {text: "24h Active Addresses", dataField: "activeAddresses", formatter: NetworkUtils.format24hAddresses, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},

            {text: "Successful Transactions", dataField: "successfulTxs", formatter: NetworkUtils.format24hTxs, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
            {text: "Failed Transactions", dataField: "failedTxs", formatter: NetworkUtils.format24hTxs, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
            {text: "Last Validator Stake", dataField: "lastValStake", formatter: CoinUtils.stakeFormatterRounded, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
            {text: "Last Validator Stake USD", dataField: "lastValStakeUsd", formatter: CoinUtils.moneyFormatterRoundDollar, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
        ];

        const filteredColumns = columns.filter(item => matchFields.includes(item.dataField));
        return filteredColumns;
    }
}

export default MetricsUtils;
