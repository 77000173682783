import React from 'react';
import MainUtils from '../main/MainUtils';
import LogoUtils from '../util/LogoUtils';
import SessionUtils from '../util/SessionUtils';
import RespUtils from '../util/RespUtils';
import common from '../common';
import currentNetwork from '../currentNetwork';
import SPCalc from '../util/SPCalc';
import IntegrationUtils from '../util/IntegrationUtils';
import AddressUtils from '../util/AddressUtils';


class TxUtils extends React.Component {
  static formatAddress(cell, row) {
    if (!cell) return cell;

    if (!cell.startsWith(currentNetwork.details.app.addressPrefix)) {
      return AddressUtils.addressFormatter(cell, row);
    }

    return MainUtils.addressFormatterByType(cell, true, false);
  }

  static formatMsgTypeAndLink(cell, row) {
    if (!cell) return cell;

    return cell;
    // return <a target="_blank" className="animLink" href={"https://www.mintscan.io/cosmos/tx/" + row.txHash}>{cell}
    //   &nbsp;<i className="mediaCustom fas fa-external-link-alt" size="xs" /></a>;
  }

  // static formatMsgTypeAndLink(cell, row, index, msgTypes) {
  //   if (!cell || !msgTypes) return cell;

  //   return <a target="_blank" className="animLink" href={"https://www.mintscan.io/cosmos/tx/" + row.txHash}>{msgTypes[cell]}
  //     &nbsp;<i className="mediaCustom fas fa-external-link-alt" size="xs" /></a>;
  // }

  // static formatMsgType(cell, row, index, msgTypes) {
  //   if (!cell || !msgTypes) return cell;

  //   return msgTypes[cell];
  // }

  static formatMsgDesc(cell, row, index, extraData) {
    if (RespUtils.isTabletView()) {
      if (row.txType === "/cosmos.bank.v1beta1.MsgSend") {
        return TxUtils.formatDescSend(row);
      } else if (row.txType === "/cosmos.staking.v1beta1.MsgDelegate") {
        return TxUtils.formatDescDelegate(row);
      } else if (row.txType === "/cosmos.staking.v1beta1.MsgBeginRedelegate") {
        return TxUtils.formatDescRedelegate(row);
      } else if (row.txType === "/cosmos.staking.v1beta1.MsgUndelegate") {
        return TxUtils.formatDescUndelegate(row);
      } else if (row.txType === "/cosmos.staking.v1beta1.MsgCancelUnbondingDelegation") {
        return TxUtils.formatDescCancelUnbond(row);
      } else if (row.txType === "/cosmos.distribution.v1beta1.MsgFundCommunityPool") {
        return TxUtils.formatDescFundCommunityPool(row);
      } else if (row.txType === "/ibc.applications.transfer.v1.MsgTransfer") {
        return TxUtils.formatDescIbcTransfer(row);
      } else if (row.txType === "/cosmos.distribution.v1beta1.MsgWithdrawDelegatorReward") {
        return TxUtils.formatDescWithdrawReward(row);
      }
    } else if (extraData.props.context === "validator") {
      if (row.txType === "/cosmos.staking.v1beta1.MsgBeginRedelegate") {
        return TxUtils.formatDescRedelegate(row);
      } else {
        return "";
      }
    }

    return "";
  }

  static formatMsgDescSort(cell, row, index, extraData) {
    let value = "";
    if (row.txType === "/cosmos.bank.v1beta1.MsgSend") {
      value = "Sent";
    } else if (row.txType === "/cosmos.staking.v1beta1.MsgDelegate") {
      value = "Delegate";
    } else if (row.txType === "/cosmos.staking.v1beta1.MsgBeginRedelegate") {
      value = "Redelegated";
    } else if (row.txType === "/cosmos.staking.v1beta1.MsgUndelegate") {
      value = "Undelegated";
    } else if (row.txType === "/cosmos.staking.v1beta1.MsgCancelUnbondingDelegation") {
      value = "Cancelled";
    } else if (row.txType === "/cosmos.distribution.v1beta1.MsgFundCommunityPool") {
      value = "fund cp";
    } else if (row.txType === "/ibc.applications.transfer.v1.MsgTransfer") {
      value = "ibc";
      return TxUtils.formatDescIbcTransfer(row);
    } else if (row.txType === "/cosmos.distribution.v1beta1.MsgWithdrawDelegatorReward") {
      value = "Rewards";
    }

    return value;
  }

  static formatDescSend(row) {
    return <span>Sent to: {MainUtils.addressFormatterByType(row.toAddress, true, false)}</span>;
  }

  static formatDescDelegate(row) {
    return <span>Delegated to: {TxUtils.nameFormatterWithLogo(row.operatorAddress, row.validatorName, row.logoPath)}</span>;
  }

  static formatDescRedelegate(row) {
    return <span>Redelegated from: {TxUtils.nameFormatterWithLogo(row.previousValOperatorAddress, row.previousValName, row.previousValLogoPath)}
      &nbsp;to {TxUtils.nameFormatterWithLogo(row.operatorAddress, row.validatorName, row.logoPath)}</span>;
  }

  static formatDescUndelegate(row) {
    return <span>Undelegated from: {TxUtils.nameFormatterWithLogo(row.operatorAddress, row.validatorName, row.logoPath)}</span>;
  }

  static formatDescCancelUnbond(row) {
    return <span>Cancelled undelegation from: {TxUtils.nameFormatterWithLogo(row.operatorAddress, row.validatorName, row.logoPath)}</span>;
  }

  static formatDescWithdrawReward(row) {
    return <span>Rewards withdrawn from: {TxUtils.nameFormatterWithLogo(row.operatorAddress, row.validatorName, row.logoPath)}</span>;
  }

  static formatDescFundCommunityPool(row) {
    return "fund cp";
  }

  static formatDescIbcTransfer(row) {
    return "ibctransfer";
  }

  static nameFormatterWithLogo(operatorAddress, name, logo) {
    if (!name) {
      return "";
    }

    let label = name;
    const onClick = e => e.stopPropagation();

    return (<span onClick={ onClick }><a className="animLinkTable" href={SessionUtils.getUrl("val/" + operatorAddress)}
        >{LogoUtils.formatLogoByPathAndSizeAndStyle(logo, null, "logoStyleWithName")}&nbsp;&nbsp;{label}</a></span>);
  }

  static renderTxFilters(thisObj) {
    let selectedCategory = thisObj.state.category;

    return (
      <div>
        <table>
          <tbody>
            <tr>
              {common.txs.categories.map(function (category) {
                if (category.networks && !category.networks.includes(currentNetwork.details.app.token)) {
                  return "";
                }

                let className = "animLinkVisual";
                let key = category.category;
                if (key === selectedCategory) {
                  className="animLinkSelected animLinkVisual";
                }
                return (<td className="view-tag-vote view-tag">
                          <button key={"category" + key} onClick={thisObj.selectCategory} value={key} 
                            className={className}>{RespUtils.isMobileView() ? category.respLabel : category.label}</button>
                        </td>);
              })}
            </tr>
          </tbody>
        </table>
      </div>);
  }

  static getTitle(thisObj, defaultTitle) {
    let title = defaultTitle;
    if (thisObj.state.category) {
      title = common.txs.categoryTitles[thisObj.state.category];
      if (thisObj.state.category === "large") {
        title += " (>" + thisObj.state.largeSize + " $" +  currentNetwork.details.app.token + ")";
      }
    }

    return title;
  }

  static formatValidator(cell, row) {
    if (!cell) return "";

    return TxUtils.nameFormatterWithLogo(row.operatorAddress, row.validatorName, row.logoPath);
  }

  static formatPreviousValidator(cell, row) {
    if (!cell) return "";

    return TxUtils.nameFormatterWithLogo(row.previousValOperatorAddress, row.previousValName, row.previousValLogoPath);
  }

  static formatElapsedTimeAndLink(cell, row) {
    if (!cell) return cell;
    
    if (RespUtils.isMobileView()) {
      return <a target="_blank" className="animLink" href={IntegrationUtils.getTxLink(row.txHash)}>{SPCalc.formatElapsedTime(cell, row)}</a>;
    }

    return <a target="_blank" className="animLink" href={IntegrationUtils.getTxLink(row.txHash)}>{SPCalc.formatElapsedTime(cell, row)}
      &nbsp;<i className="mediaCustom fas fa-external-link-alt" size="xs" /></a>;

  }
}

export default TxUtils;
