import UIUtils from "./util/UIUtils";

export default {
  validators: {
    validatorsList: "List of validators",
  },
  stats: {
    stakingPools: "# of validators",
    recentBlock: "Latest block # when dashboard was updated"
  },
  netstatsummary: {
    networkStats: UIUtils.getTip("Overall network stats related to supply and market cap."),
    stakingStats: UIUtils.getTip("Staking stats including APR and staking participation."),
    d14nStats: UIUtils.getTip("Decentralization metrics for the network."),
    growthStats: UIUtils.getTip("Stats reflecting how the network is growing over time."),
    undelegationStats: UIUtils.getTip("Undelegation stats related to the network."),
    otherUsageStats: UIUtils.getTip("Miscellaneous usage stats related to the network."),
  },
  netstats: {
    totalStake: UIUtils.getTip("Total Staked tokens"),
    apr: UIUtils.getTip("Current expected APR - based on annual inflation, current total stake, & zero commission"),
    percentStaked: UIUtils.getTip("Percentage of tokens staked out of total supply."),
    activeAddresses: UIUtils.getTip("Total number of addresses with non-zero balance."),
    uniqueDelegates: UIUtils.getTip("Total number of unique delegates/stakers."),
    totalValidators: UIUtils.getTip("Total number of validators (including jailed/unbonded)."),
    delegations: UIUtils.getTip("Total number of active delegations."),
    gini: UIUtils.getTip("Gini coefficient is a metric used to describe the level of inequality of stake between all validators"),
    usdPrice: UIUtils.getTip("USD price of token"),
    valForNetworkHalt: UIUtils.getTip("The smallest number of validators that together control more than 33% of the voting power."),
    medianCommission: UIUtils.getTip("Median and weighted median of the commission charged by all validators."),
    valBelowAvg: UIUtils.getTip("# of validators below average voting power ($value1%)."),
    stakingApr: UIUtils.getTip("Current Staking APR ignoring validator commissions. Staking APY assumes weekly compounding (manual or automatic using providers like restake.app"),
    blockRate: UIUtils.getTip("The pace at which blocks are being produced in the network (based on last 10,000 blocks)."),
    overallApr: UIUtils.getTip("Sum total of current airdrops APR and Staking APR (ignoring validator commissions)."),
    nakamotoIndex: UIUtils.getTip("Nakamoto index is the number of top validators, holding together more than 51% of staked coins. Measures the prevalence of top validators comparing to the whole."),
    pendingUndelegations: UIUtils.getTip("Amount of token pending undelegation completion in the next 24 hours, 7 days, and overall."),
    uniqueUndelegations: UIUtils.getTip("Number of unique pending undelegation transactions."),
    totalSupply: UIUtils.getTip("Total Supply of the coin."),
    circulatingSupply: UIUtils.getTip("Circulating Supply of the coin."),
    oneEqualsNBottomVal: UIUtils.getTip("How many bottom validators collectively equal Top 1 Validator VP."),
    averageDelegation: UIUtils.getTip("Average amount of tokens/USD value in each delegation."),
    unbondingTime: UIUtils.getTip("The waiting time for undelegation/unbonding to complete."),
    txCount24h: UIUtils.getTip("Number of transactions in last 24 hours/7 days."),
    activeAddress24h: UIUtils.getTip("Number of wallets that transacted in last 24 hours."),
    successfulFailedTransactions: UIUtils.getTip("Number of successful and failed transactions in last 24 hours."),
    unbondedStake: UIUtils.getTip("Tokens staked with validators that are not bonded."),
    growthStats: UIUtils.getTip("Network growth stats."),
  },
  networkCharts: {
    signHistory: UIUtils.getTip("Chart showing the signing history for each block produced. Data elements shown are: signed/missed - how many validators signed/missed a block. networkSignRate - %age of validators that signed a particular block."),
    aprHistory: UIUtils.getTip("Chart showing the staking apr history."),
    stakeDistribution: UIUtils.getTip("Chart showing the distribution of staking/voting power between active validator."),
    stakeAndDelegateDistribution: UIUtils.getTip("Breakdown of total voting power and delegates between all validators"),
    burnHistory: UIUtils.getTip("Breakdown of historical token burn (since tracking started)"),
    totalStake: UIUtils.getTip("Chart showing how Total Stake changes over time"),
    percentStaked: UIUtils.getTip("Chart showing how Percent Staked changes over time"),
    activeAccounts: UIUtils.getTip("Chart showing how non-zero balance addresses count changes over time. Please note that the data is scraped through scripts and there might be days with inaccuracies due to script issues."),
    uniqueDelegates: UIUtils.getTip("Chart showing how Unique Delegate count changes over time"),
    gini: UIUtils.getTip("Chart showing how Gini Coefficient changes over time"),
    nakamotoIndex: UIUtils.getTip("Chart showing how Nakamoto Index changes over time"),
    valForNetworkHalt: UIUtils.getTip("Chart showing how 'Validators required for Network Halt' changes over time"),
    weightedMedianCommission: UIUtils.getTip("Chart showing how 'Weighted Median Commission' changes over time"),
    totalSupply: UIUtils.getTip("Chart showing how Total Supply changes over time"),
    circulatingSupply: UIUtils.getTip("Chart showing how Circulating Supply changes over time"),
    undelegationSummary: UIUtils.getTip("Chart showing undelegation summary over time"),
    mostMissedBlocks: UIUtils.getTip("List of blocks that were missed the most. Choose time frame using the sign history chart."),
    signRateByProposer: UIUtils.getTip("Network sign rate by individual proposers over a selected timeframe."),
    allUndelegations: UIUtils.getTip("All pending undelegations."),
    vpCurve: UIUtils.getTip("Voting power curve for network based on bonded validators."),
    vpCurveTimeline: UIUtils.getTip("Voting power curve comparison for network based on bonded validators at this time and a selected time in the past."),
    vpCurveComparison: UIUtils.getTip("Voting power curve comparison with other networks based on bonded validators."),
    topDelegates: UIUtils.getTip("Top delegates in the network (based on their total staked amount across all validators)."),
    averageDelegation: UIUtils.getTip("Average amount of tokens/USD value in each delegation."),
    holderDistributionRange: UIUtils.getTip("Percentage of holders in each bucket of amounts. In case of range search (e.g. 0 - 1000), each data point (e.g. 100) is showing holders between previous data point till current value (0 - 100)"),
    stakerDistribution: UIUtils.getTip("Percentage of stakers in each bucket of delegated amounts (Token)."),
    stakerDistributionUsd: UIUtils.getTip("Percentage of stakers in each bucket of delegated amounts ($ value)."),
    blockRateHistory: UIUtils.getTip("Chart showing the network block rate history."),
    newWallets: UIUtils.getTip("Chart showing the history of net new wallets with >0 balance. Please note that the data is scraped through scripts and there might be days with inaccuracies due to script issues."),
    newDelegates: UIUtils.getTip("Chart showing the history of net new delegates with >0 balance. Please note that the data is scraped through scripts and there might be days with inaccuracies due to script issues."),
    inProgressBurn: UIUtils.getTip("History of pending/in-progress burn. The amount will reset/become 0/drop to a low number on the day a burn is carried out."),
    liquidStake: UIUtils.getTip("Liquid Staking history for the validator."),
    protocolLiquidStake: UIUtils.getTip("Protocol level liquid staking history for the validator."),
    txCount24h: UIUtils.getTip("Daily number of transactions for the network."),
    activeAddress24h: UIUtils.getTip("Number of wallets that interact with the network on a daily basis"),
    failedTxs: UIUtils.getTip("Comparison of successful vs failed transactions."),
    lastValStakes: UIUtils.getTip("Last active validator's VP change over time."),
    supplyDistribution: UIUtils.getTip("Token supply distribution chart showing how much supply is held by the top addresses (excludes named addresses by default). Allows comparison of supply distribution with other tokens."),
    networkSupplyDistribution: UIUtils.getTip("Supply distribution chart showing how much supply is held by the top addresses. Allows comparison of supply distribution with other networks. Feature restricts to top 5000 addresses for the selected criteria."),
    valStakeDistribution: UIUtils.getTip("Chart showing stake distribution among active validators."),
    stakerMilestones: UIUtils.getTip("Ranks of stakers at milestone points based on staked amount"),
    stakerMilestones: UIUtils.getTip("Ranks of holders at milestone points based on total balance amount in each address(staked + non-staked)"),
    valPerfByBucket: UIUtils.getTip("Comparison of aggregated average performance between validators in different buckets based on voting power. The bottom validator set is volatile. Data belonging to current validators in the active validator set is used for the chart."),
    compareMetrics: UIUtils.getTip("Chart allows comparing any two metrics over time"),
  },
  valDetails: {
    basic: UIUtils.getTip("Basic details of the validator"),
    valAssess: UIUtils.getTip("Various non-performance aspects for assessing validators"),
    address: UIUtils.getTip("Address details"),
    perfSummary: UIUtils.getTip("Validator performance summary in different timeframe. This data does not use completed days and presents validator performance counted backwards from this moment"),
    maxCommission: UIUtils.getTip("Max Commission - represents the maximum commission that validator can ever charge on rewards earned from staking."),
    maxCommissionChangeRate: UIUtils.getTip("Max Commission Change Rate - The max daily change in commission that validator can do."),
    events: UIUtils.getTip("Key events related to validator."),
    votes: UIUtils.getTip("Governance participation history of the validator."),
    undelegations: UIUtils.getTip("Ongoing undelegations for the validator"),
    delegations: UIUtils.getTip("Current delegations for the validator"),
    interchainPerf: UIUtils.getTip("Validator performance across all supported networks. Requires use of same Keybase Identity."),
  },
  valCharts: {
    votingPower: UIUtils.getTip("History of how voting power is changing for the validator."),
    votingPowerPercentage: UIUtils.getTip("History of how voting power percentage (relative to overall stake) is changing for the validator."),
    uniqueDelegates: UIUtils.getTip("History of how unique delegate count is changing for the validator."),
    uniqueUndelegations: UIUtils.getTip("History of number of undelegations for the validator."),
    commission: UIUtils.getTip("Commission history of the validator (as captured at the end of each day)."),
    votingPowerRank: UIUtils.getTip("History of how validator's voting power rank is changing."),
    averageDelegation: UIUtils.getTip("History of how average delegation is changing."),
    delegationDistribution: UIUtils.getTip("Percentage of delegations in each bucket of delegated amounts (Token)."),
    delegationDistributionUsd: UIUtils.getTip("Percentage of delegations in each bucket of delegated amounts (USD)."),
  },
  address: {
    undelegations: UIUtils.getTip("Ongoing undelegations for the address"),
    delegations: UIUtils.getTip("Current delegations for the address"),
  },
  proposal: {
    basic: UIUtils.getTip("Basic details of the proposal."),
    timing: UIUtils.getTip("Key timing aspects of the proposal."),
    voteSummary: UIUtils.getTip("Summary of governance voting on the proposal"),
    votes: UIUtils.getTip("List of all votes received on the proposal"),
    proposalChanges: UIUtils.getTip("Changes submitted by this proposal"),
    voterDistribution: UIUtils.getTip("Number of voters in each bucket of delegated amounts."),
    voterDistributionPercent: UIUtils.getTip("Percentage of delegations in each bucket of delegated amounts."),
    compVoterDistribution: UIUtils.getTip("Number of voters in each bucket of delegated amounts between selected proposal and latest finished proposal."),
    compVoterDistributionPercent: UIUtils.getTip("Percentage of delegations in each bucket of delegated amounts between selected proposal and latest finished proposal."),
  },
  valDetailsCharts: {
    hourlySignPer: UIUtils.getTip("Chart showing hourly block sign percentages for the validator."),
    hourlySigns: UIUtils.getTip("Chart showing hourly block signatures for the validator."),
    dailySignPer: UIUtils.getTip("Chart showing daily block sign percentages for the validator."),
    dailySigns: UIUtils.getTip("Chart showing daily block signatures for the validator."),
    dailyOracleMissedVotes: UIUtils.getTip("Chart showing daily oracle missed votes for the validator. Data is scraped and may be inaccurate."),
    hourlyOracleMissedVotes: UIUtils.getTip("Chart showing daily oracle missed votes for the validator. Data is scraped and may be inaccurate."),
  },
  airdrop: {
    airdrops: UIUtils.getTip("Currently active airdrops for stakers."),
  },
  blocksign: {
    height: UIUtils.getTip("Block height for which signature information is show."),
    blockTime: UIUtils.getTip("Time of the block creation in UTC."),
    proposer: UIUtils.getTip("The validator that proposed currently shown block."),
  },
  matic: {
    maticBenchmarkPerfMedian: UIUtils.getTip("Running benchmark performance and median performance based on the last 700 checkpoints. Calculations use a buffer of BUFFER% from the median to arrive at benchmark performance."),
    validatorsBelowBenchmark: UIUtils.getTip("# of validators with lower performance than benchmark performance in the last 700 checkpoints."),
    lastCheckpointNumberAndTime: UIUtils.getTip("Latest checkpoint number and the time passed since then."),
    hourlyCheckpoints: UIUtils.getTip("Chart/table showing hourly checkpoint signatures for the validator."),
    dailyCheckpoints: UIUtils.getTip("Chart/table showing daily checkpoint signatures for the validator."),
    checkpointSigns: UIUtils.getTip("Checkpoint performance of the validator in last 700 checkpoints."),
    networkCheckpointHistory: UIUtils.getTip("Checkpoint performance history at network level."),
  },
  fdp: {
    assessmentWindows: UIUtils.getTip("List of foundation delegation assessment windows"),
    recipientValidators: UIUtils.getTip("Performance assessment of validators receiving foundation delegations in the current assessment window"),
    basic: UIUtils.getTip("Basic details of the current assessment window"),
    assessmentDetails: UIUtils.getTip("Performance details of the current assessment window"),
    fdpval: {
      commission: UIUtils.getTip("Commission changes of the validator in current assessment window"),
      uptime: UIUtils.getTip("Block uptime of the validator in current assessment window"),
      peggoUptime: UIUtils.getTip("Peggo performance of the validator in current assessment window"),
      upgrades: UIUtils.getTip("Upgrade participation of the validator in current assessment window"),
      proposals: UIUtils.getTip("Governance participation of the validator in current assessment window"),
      slashing: UIUtils.getTip("Slashing history of the validator in current assessment window"),
      basic: UIUtils.getTip("Basic details of validator in the current assessment window"),
      summary: UIUtils.getTip("Validator performance summary in assessment window"),
    }
  },
  lsm: {
    validators: UIUtils.getTip("Liquid staking delegations for validators"),
    delegations: UIUtils.getTip("Liquid staking delegations for the validator"),
    liquidStakingHistory: UIUtils.getTip("Liquid staking history for the selected provider or for the overall network"),
    liquidStakingDeltaHistory: UIUtils.getTip("Daily changes in liquid staking for the selected provider or for the overall network"),
  }
};
// Click more/less to change the timeframe for chart.
