import React from 'react';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';

import currentNetwork from '../currentNetwork';
import UIUtils from '../util/UIUtils';


class ChartData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      responsive: true,
      lineProps: [],
      lineHover: null,
    }
    this.myRef = React.createRef();
  }

  render() {
    let tableVisualId = currentNetwork.details.networkName + "-data-" + this.props.chartVisualId;
    const options = UIUtils.getPageOptionsSmall(this, 5);

    console.log(this.props.sortBy);
    const defaultSorted = [{
        dataField: this.props.sortBy ? this.props.sortBy : this.props.columns[0].dataField,
        order: 'desc'
    }];

    console.log(defaultSorted);
    return (
      <div id={"div" + tableVisualId}>
        <BootstrapTable keyField={this.props.dataKey} data={this.props.data}
            columns={this.props.columns} striped options={options} defaultSorted={defaultSorted}
            condensed noDataIndication="No data" pagination={paginationFactory(options)} />
      </div>
    );
  }
}

export default ChartData;
