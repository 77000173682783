import React from 'react';
import currentNetwork from '../currentNetwork';
import constants from '../constants';

class SessionUtils extends React.Component {
  static keys = {network: "network",};

  static switchNetwork(e) {
    e.preventDefault();
    // window.localStorage.removeItem(FavUtils.getFavPoolsKey());
    // console.log("in favourite: " + e.target.id);
    let newNetwork = e.target.value;
    if (newNetwork) {
      window.sessionStorage.setItem(SessionUtils.keys.network, newNetwork);
      currentNetwork.setNetwork(newNetwork);
    }
    // console.log("switching to: ", newNetwork);

    // let networkObject = window.localStorage.getItem(NetworkUtils.keys.network);
    // console.log(window.localStorage.getItem(NetworkUtils.keys.network))
    // console.log("favourite - favs: " + window.localStorage.getItem(FavUtils.getFavPoolsKey()));
    // window.location.reload();
    // window.location = "/";
  }

  static isMainnet() {
    return true;
  }

  static getNetwork() {
    return SessionUtils.obtainNetwork(false);
  }

  static obtainNetwork(audit) {
    let network = window.sessionStorage.getItem(SessionUtils.keys.network);
    if (audit) console.log("in obtainNetwork network: ", network, ", storage: ", window.sessionStorage);

    // if (!network) {
    //   alert("The website encountered an issue. Please reload. If the issue happens again, please contact support at https://t.me/smartstake");
    // }

    return network;
  }

  static setNetwork(thisObj) {
    SessionUtils.initNetwork(thisObj, false);
  }

  static initNetwork(thisObj, audit) {
    if (audit) console.log("in init network: props: ", thisObj.props, ", match: ", thisObj.props.match);
    if (!thisObj.props.match) {
      if (audit) console.log("not setting network as params not present");
      return;
    }
    
    let newNetwork = thisObj.props.match.params.network;
    if (newNetwork === "index.html") {
      window.location = "/";
    }

    let oldNetwork = SessionUtils.getNetwork()
    if (audit) console.log("before any processing: newNetwork: ", newNetwork, ", current network: ", oldNetwork, ", currentNetwork.notSet: ", currentNetwork.details.notSet);

    let updateValues = false;
    if (newNetwork != null) {
      window.sessionStorage.setItem(SessionUtils.keys.network, newNetwork);
      updateValues = true;
    } else if (!oldNetwork) {
      newNetwork = constants.defaultNetworkName;
      updateValues = true;
    } else if (oldNetwork && currentNetwork.details.notSet === true) {
      newNetwork = oldNetwork;
      updateValues = true;
    }

    if (audit) console.log("before setting: updateValues: ", updateValues, "newNetwork: ", newNetwork, ", current network: ", oldNetwork);
    if (updateValues) {
      currentNetwork.setNetwork(newNetwork);

      if (audit) {
        console.log("expected - network should be set to " + newNetwork + "at this time");
        console.log("actual - network currently set is " + SessionUtils.obtainNetwork(true));
      }

      if (constants[newNetwork]) {
        document.title = constants[newNetwork].app.appName + " - " + constants.title;
      } else {
        // alert(`Network "${newNetwork}" not supported. Please contact smart stake support if you think this is a bug.`);
        console.log(newNetwork);
        console.log(constants);
      }
    }
    if (audit) console.log("after processing: newNetwork: ", newNetwork, ", current network: ", oldNetwork, ", currentNetwork.notSet: ", currentNetwork.details.notSet);

  }

  static getUrl(inputUrl) {
    return SessionUtils.obtainUrl(inputUrl, false)
  }

  static getUrlForAudit(inputUrl) {
    let url = SessionUtils.obtainUrl(inputUrl, true)
    
    console.log("url obtained is: ", url);
    return url;
  }

  static getUrlWithAccessKey(inputUrl) {
    let url = SessionUtils.obtainUrl(inputUrl, true);
    return url;
  }

  static obtainUrl(inputUrl, audit) {
    if (!inputUrl.startsWith("/")) {
      inputUrl = "/" + inputUrl;
    }

    let network = SessionUtils.obtainNetwork(audit);
    let relativeUrl = network + inputUrl;
    if (!network && inputUrl === "/") {
      relativeUrl = "";
    }

    let finalUrl = "/" + relativeUrl;
    return finalUrl;
  }

  static activateOnLoad(thisObj) {
    setTimeout(() => {
      // console.log("navbar - 100: SessionUtils.getNetwork(): ", SessionUtils.getNetwork());
      if (SessionUtils.getNetwork()) {
        thisObj.setState({isLoading: false});
      }
    }, 100);
    setTimeout(() => {
      // console.log("navbar - 1000: SessionUtils.getNetwork(): ", SessionUtils.getNetwork());
      if (SessionUtils.getNetwork()) {
        thisObj.setState({isLoading: false});
      }
    }, 1000);
    setTimeout(() => {
      // console.log("navbar - 1000: SessionUtils.getNetwork(): ", SessionUtils.getNetwork());
      if (SessionUtils.getNetwork()) {
        thisObj.setState({isLoading: false});
      }
    }, 3000);
    setTimeout(() => {
      // console.log("navbar - 1000: SessionUtils.getNetwork(): ", SessionUtils.getNetwork());
      if (SessionUtils.getNetwork()) {
        thisObj.setState({isLoading: false});
      }
    }, 4000);
    setTimeout(() => {
      // console.log("navbar - 1000: SessionUtils.getNetwork(): ", SessionUtils.getNetwork());
      thisObj.setState({isLoading: false});
    }, 5000);
  }

}

export default SessionUtils;
