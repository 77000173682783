import React from 'react';
import CoinUtils from '../util/CoinUtils';

class LSUtils extends React.Component {
  static formatHasSufficientValBondInd(cell, row) {
    let style = "positiveGrowth";
    let label = "Yes";
    if (cell === "False") {
      style = "negativeGrowth";
      label = "No";
    }

    return (<span className={style}>{label}</span>);
  }

  static formatValBondRemCapacity(cell, row) {
    // console.log(row);
    if (!row.valBond || row.valBond == 0) {
      let desc = "The validator has not validator bonded any tokens";
      return (<span data-for="main" data-tip={desc} data-iscapture="true">0</span>);
    }

    if (cell && cell < 0) {
      let desc = "The validator doesn't have enough validator bond to receive new delegations";
      return (<span data-for="main" data-tip={desc} data-iscapture="true">&lt; 0</span>);
    }

    let valBondBasedCapacity = cell;
    // let vp = row.votingPower;
    // let lsmDelegations = row.lsmDelegations;
    // let lsmDelBasedCapacity = parseInt(vp * 0.25) - parseInt(lsmDelegations);
    let finalCapacity = valBondBasedCapacity;

    let style = "";
    let desc = "The validator bond allows validator to <br/> get proportional LSM delegations.";

    return (<span className={style} data-for="main" data-tip={desc} data-iscapture="true"
      >{CoinUtils.stakeFormatterRoundIfNeeded(finalCapacity, row)}</span>);
  }

  /**
   *   static formatHasSufficientValBondInd(cell, row) {
    let style = "positiveGrowth";
    let label = "Yes";
    if (cell === "False") {
      style = "negativeGrowth";
      label = "No";
    }

    return (<span className={style}>{label}</span>);
  }

  static formatValBondRemCapacity(cell, row) {
    if (cell && cell < 0) {
      let desc = "The validator has not validator bonded any tokens";
      return (<span data-for="main" data-tip={desc} data-iscapture="true">&lt; 0</span>);
    }

    let valBondBasedCapacity = cell;
    let vp = row.votingPower;
    let lsmDelegations = row.lsmDelegations;
    let lsmDelBasedCapacity = parseInt(vp * 0.25) - parseInt(lsmDelegations);
    let finalCapacity = valBondBasedCapacity;

    let style = "";
    let desc = "The validator bond allows validator to <br/> get proportional LSM delegations.";
    if (row.valBond )

    if (lsmDelBasedCapacity < valBondBasedCapacity) {
      style = "negativeGrowth";
      if (lsmDelBasedCapacity < 0) {
        desc = "The current LSM delegations (" + lsmDelegations + ") <br/> exceed the 25% limit of total voting power (" 
          + vp + "). <br/>Validator cannot receive new LSM delegations at this time."
        finalCapacity = 0;
      } else {
        desc = "The current LSM delegations (" + lsmDelegations + ") <br/> have reduced the maximum delegations that the<br/> validator can receive. "
          + " Validator will stop receiving<br/> new LSM delegations when it's LSM delegations<br/> "
          + "hit the 25% of it's total voting power (" + vp + ").";
        finalCapacity = lsmDelBasedCapacity;
      }
    }

    return (<span className={style} data-for="main" data-tip={desc} data-iscapture="true"
      >{CoinUtils.stakeFormatterRoundIfNeeded(finalCapacity, row)}</span>);
  }


  */
  
  static formatDelta(cell, row) {
    if (!cell) return "";

    let style = "positiveGrowth animLink";
    let label = CoinUtils.stakeFormatterRoundIfNeeded(cell);
    let labelPrefix = "+";
    if (cell < 0) {
      style = "negativeGrowth animLink";
      labelPrefix = "";
    } else if (label == 0) {
      style = "";
      labelPrefix = "";
    }

    // console.log(cell);

    return (<span className={style}>&nbsp;({labelPrefix}{label})</span>);
  }

}

export default LSUtils;
