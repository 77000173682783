import React from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import queryString from 'query-string';

import SessionUtils from '../util/SessionUtils';
import MainUtils from '../main/MainUtils';
import LogoUtils from '../util/LogoUtils';
import ChartTitle from '../components/ChartTitle';
import UIUtils from '../util/UIUtils';
import currentNetwork from '../currentNetwork';
    

class FdpUtils extends React.Component {
  static fdpFormatter(cell, row, index, extraData) {
    return (<a className="animLinkVisual" href={SessionUtils.getUrl("/fdp/" + row.fdpNumber)}>{cell}</a>);
  }

  static yesNoFormatter(cell, row) {
    if (cell === 0) {
        return <font color="red">No</font>;
    } else if (cell === 1) {
        return "Yes";
    }

    return "";
  }

  static peggoFormatter(cell, row) {
    if (cell === 0) {
      return "Yes";
    } else if (cell === 1) {
      return "No";
    }

    return "";
  }

  static objectiveFormatter(cell, row) {
    if (cell === 0) {
      return <CancelRoundedIcon style={{color: "#ce4536",}} />;
    } else if (cell === 1) {
      return <CheckCircleRoundedIcon style={{color: "#419541",}} />;
    }

    return "";
  }

  static objectiveFormatterBoolean(cell, row) {
    if (cell === "true") {
      return <CheckCircleRoundedIcon style={{color: "#419541",}} />;
    } else if (cell === "false") {
      return <CancelRoundedIcon style={{color: "#ce4536",}} />;
    }

    return "";
  }

  static nameFormatterTableWithLogo(cell, row, index, extra) {
    if (!cell) {
      return "";
    }

    let label = MainUtils.getNameLabelShorter(cell);

    const onClick = e => e.stopPropagation();
    return (<span onClick={ onClick }><a className="tourValName animLinkTable"
      href={SessionUtils.getUrl("fdp/" + extra.state.fdpNumber + "/val/" + row.operatorAddress)}>{LogoUtils.formatLogoByPathAndSizeAndStyle(
      row.logoPath, row, "logoStyleWithName")}&nbsp;&nbsp;{label}</a></span>);
  }

  static renderTable(objective, title, data, columns, tooltip, objectivesResult) {
    const options = UIUtils.getPageOptionsSmall(this, 5);

    return (
      <React.Fragment>
        <ChartTitle title={title} tip={tooltip} />
        <br/>
        <p>{objective}</p>
        <table><tbody><tr>
          {objectivesResult.map(function (objectiveResult) {
              if (objectiveResult !== "") {
                return <React.Fragment>
                          <td>{objectiveResult.name} {FdpUtils.objectiveFormatter(objectiveResult.result)}</td>
                       </React.Fragment>
              }
          })}
        </tr></tbody></table>
        
        <BootstrapTable keyField={"index"} data={data} columns={ columns } striped 
          condensed noDataIndication="No data" pagination={paginationFactory(options)} />
      </React.Fragment>
    );
  }

  static getFdpBreadCrumb(fdpListInd, fdp) {
    let url = fdpListInd ? "/fdp" : "/fdp/" + fdp.fdpNumber;
    let label = currentNetwork.details.app.appName;
    label += (fdpListInd ?  " - FDP List" : " - FDP #" + fdp.fdpNumber);

    return (
      <Breadcrumb>
        <Breadcrumb.Item href={SessionUtils.getUrl(url)}>&lt;&lt; {label}</Breadcrumb.Item>
      </Breadcrumb>
    );
  }

  static isExtraEnabled(fdp) {
    // let fdpExtraDisabled = !currentNetwork.details.fdp || !currentNetwork.details.fdp.extra;
    // return fdpExtraDisabled;
    return (fdp.extraUptimeEnabled === 1);
  }

  static isLowVPEnabled(fdp) {
    // let fdpLowVPDisabled = !currentNetwork.details.fdp || !currentNetwork.details.fdp.maxVP;
    // return fdpLowVPDisabled;
    return (fdp.maxVP !== null);
  }

  static setAccessKey(accessKey) {
    window.localStorage.setItem(currentNetwork.details.fdp.fdpAccessKey, accessKey);
  }

  static setAccessKeyAndGetParam(thisObj) {
    let params = queryString.parse(thisObj.props.location.search);
    if (params && params.accessKey) {
      let accessKey = params.accessKey;
      FdpUtils.setAccessKey(accessKey);
    }

    return FdpUtils.getAccessKeyParam();
  }

  static getAccessKeyParam() {
    let accessKey = window.localStorage.getItem(currentNetwork.details.fdp.fdpAccessKey);

    if (accessKey) {
      return "&accessKey=" + accessKey;
    }
    
    return "";
  }

  static excludeSpamLabel(fdp) {
    if (fdp.excludeSpamProposalsInd && fdp.excludeSpamProposalsInd === 1) {
      return "(Excluding Spam Proposals)";
    }

    return "";
  }

  static uptimeRollingDaysLabel(fdp) {
    if (fdp.uptimeRollingDays) {
      return "(based on last " + fdp.uptimeRollingDays + " days)";
    }

    return "";
  }

  static getFdpColumns(fdp, showDelegation) {
    let fdpColumns = {foundationDelegation: showDelegation, extraMaxDowntime: (fdp.extraMaxDowntime != null), 
      metAllObj: (fdp.minUptime != null), metCommObj: (fdp.maxCommission != null), 
      metUptimeObj: (fdp.minUptime != null), metExtraUptimeObj: (fdp.extraMinUptime != null), 
      metUpgradeObj: (fdp.upgradeMaxDuration != null), metExtraUpgradeObj: (fdp.extraMinUptime != null),
      metSlashingFreqObj: (fdp.maxSlashingFreq != null), metSlashingMaxDurObj: (fdp.maxSlashingDuration != null), 
      metGovernanceObj: (fdp.minGovParticipation != null), metVPObj: (fdp.maxVP != null), };
    
    return fdpColumns;
  }

  static removeUnsupportedColumns(columns, fdp, showDelegation) {
    let fdpColumns = FdpUtils.getFdpColumns(fdp, showDelegation);
    // console.log(fdpColumns);
    
    let supportedColumns = []
    for(let i=0; i < columns.length; i++) {
      let column = columns[i];

      if (fdpColumns[column.dataField] != null) {
        // console.log("processing: ", column.dataField, " from supported columns");
        let supported = fdpColumns[column.dataField];
        if (supported) {
          supportedColumns.push(column);
        }
      } else {
        // console.log("found regular column: ", column.dataField);
        supportedColumns.push(column);
      }
    }

    // console.log(supportedColumns);
    return supportedColumns;
  }

  static isToBeShown(fdpColumns, columnName) {
    if (fdpColumns[columnName] != null) {
      let supported = fdpColumns[columnName];
      if (!supported) {
        return false;
      }
    }

    return true;
  }

}

export default FdpUtils;
