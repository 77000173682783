import React from 'react';


class TextUtils extends React.Component {
  static isEmpty(text) {
    return text === null || text === "";
  }

  static isNotEmpty(text) {
    return text !== null && text !== "";
  }

  static shortenLongWords(text) {
      if (!text) return text;

      return text.split(' ').map(word => {
        if (word.length > 20) {
          return word.slice(0, 18) + '..';
        }
        return word;
      }).join(' ');
  }
}

export default TextUtils;
