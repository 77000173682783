import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { textFilter } from 'react-bootstrap-table2-filter';
import ReactTooltip from "react-tooltip";
import {Collapse} from 'react-collapse';
import paginationFactory from 'react-bootstrap-table2-paginator';

import FavUtils from "../util/FavUtils";
import tooltips from "../tooltips";
import ChartTitle from '../components/ChartTitle';
import StakeUtils from '../util/StakeUtils';
import SignFmtUtils from '../validator/SignFmtUtils';
import StackedBarChart from '../charts/StackedBarChart';
import Utilities from '../util/Utilities';
import MainUtils from '../main/MainUtils';
import UIUtils from '../util/UIUtils';
import CoinUtils from '../util/CoinUtils';
import ValidatorUtils from '../validators/ValidatorUtils';
import UITableUtils from "../util/UITableUtils";
import AddressUtils from '../util/AddressUtils';
import MediaUtils from '../util/MediaUtils';
import ChartUtils from '../util/ChartUtils';
import currentNetwork from '../currentNetwork';
import RespUtils from '../util/RespUtils';

class MaticValidatorUtils extends React.Component {
  static renderValidatorRunningSummary(val, coinStat) {
    return (
      <React.Fragment>
        <ChartTitle title="Validator Performance - Running Summary" tip={tooltips.valDetails.perfSummary} />
        <table >
          <thead>
            <tr>
              <th>Window</th>
              <th>Checkpoint Signed</th>
              <th>Checkpoint Missed</th>
              <th>Checkpoint Sign %</th>
              <th>Block Sign %</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>8 Hours</td>
              <td> {val.currentCheckpointSign}</td>
              <td> {val.currentCheckpointMissed}</td>
              <td> {MaticValidatorUtils.checkpointSignFmt(val.currentPerCheckpointSign, val, 0, coinStat)}</td>
              <td> {SignFmtUtils.longPerSignedFmt(val.currentPerSigned)}</td>
            </tr>
            <tr>
              <td>1 Day</td>
              <td> {val.dailyCheckpointSign}</td>
              <td> {val.dailyCheckpointMissed}</td>
              <td> {MaticValidatorUtils.checkpointSignFmt(val.dailyPerCheckpointSign, val, 0, coinStat)}</td>
              <td> {SignFmtUtils.longPerSignedFmt(val.dailyPerSigned)}</td>
            </tr>
            <tr>
              <td>10 Days</td>
              <td> {val.midCheckpointSign}</td>
              <td> {val.midCheckpointMissed}</td>
              <td> {MaticValidatorUtils.checkpointSignFmt(val.midPerCheckpointSign, val, 0, coinStat)}</td>
              <td> {SignFmtUtils.longPerSignedFmt(val.midPerSigned)}</td>
            </tr>
            <tr>
              <td>700 Checkpoints / 30 Days (Blocks)</td>
              <td> {val.longCheckpointSign}</td>
              <td> {val.longCheckpointMissed}</td>
              <td> {MaticValidatorUtils.checkpointSignFmt(val.longPerCheckpointSign, val, 0, coinStat)}</td>
              <td> {SignFmtUtils.longPerSignedFmt(val.longPerSigned)}</td>
            </tr>
            <tr>
              <td>90 Days</td>
              <td> {val.historicalCheckpointSign}</td>
              <td> {val.historicalCheckpointMissed}</td>
              <td> {MaticValidatorUtils.checkpointSignFmt(val.historicalPerCheckpointSign, val, 0, coinStat)}</td>
              <td> {SignFmtUtils.longPerSignedFmt(val.historicalPerSigned)}</td>
            </tr>
          </tbody>
        </table>
      </React.Fragment>
    );
  }

  static renderPoolCheckpointPerf(thisObj) {
    let hourlyCheckpointData = thisObj.state.extraData["hourlyCheckpointData"];
    let dailyCheckpointData = thisObj.state.extraData["dailyCheckpointData"];

    if (!hourlyCheckpointData) hourlyCheckpointData = [];
    if (!dailyCheckpointData) dailyCheckpointData = [];

    var perfData = [
      {text: "Date", dataField: "title", sort: true, headerStyle: Utilities.width(35)},
      {text: "Signed", dataField: "signed", sort: true},
      {text: "Missed", dataField: "missed", sort: true},
    ];

    return (
        <React.Fragment>
            <div className="chartBg">
                <StackedBarChart title="Hourly Checkpoint Signatures" tip={tooltips.matic.hourlyCheckpoints}
                  chartVisualId={"hourly-checkpoint-signatures"}
                  showVerticalLabel={true} valueAttr="signed" valueAttr2="missed" showTotalLabel={false}
                  data={hourlyCheckpointData} 
                  handleMore={thisObj.handleMore} addMoreLink={false} 
                  frequencies={["8H", "D", "W"]} frequency={thisObj.state.frequencyHour} 
                  handleChart={thisObj.handleChartUpdateHourlyFrequency} xAxis="Date/Time"
                  dataKey="title" columns={perfData} />
            </div>
            <div className="chartBg">
                <StackedBarChart title="Daily Checkpoint Signatures"  tip={tooltips.matic.dailyCheckpoints}
                  chartVisualId={"daily-checkpoint-signatures"}
                  showVerticalLabel={true} valueAttr="signed" valueAttr2="missed" showTotalLabel={false}
                  data={dailyCheckpointData}
                  handleMore={thisObj.handleMore} addMoreLink={false} 
                  frequencies={["10D", "M", "3M", "6M", "Y"]} frequency={thisObj.state.frequencyDay} 
                  handleChart={thisObj.handleChartUpdateDailyFrequency} xAxis="Date"
                  dataKey="title" columns={perfData} />
            </div>
        </React.Fragment>
    );    
  }

  static renderValidatorPerfTables(thisObj) {
    var columns = [
        {text: "Date",dataField: "title", sort: true, headerStyle: Utilities.width(35)},
        {text: "Checkpoint Signed", dataField: "signed", sort: true,  sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(19)},
        {text: "Checkpoint Missed", dataField: "missed", sort: true,  sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(19)},
        {text: "Checkpoint Sign %", dataField: "signedPer", formatter: MainUtils.signPerFormatterTitle, formatExtraData: thisObj.state.val.poolId, sort: true,  sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(20)},
    ];

    const options = UIUtils.getPageOptionsSmall(thisObj, 5);
    const defaultSorted = [{
        dataField: 'title',
        order: 'desc' // desc or asc
    }];

    let hourlyCheckpointData = thisObj.state.extraData["hourlyCheckpointData"];
    let dailyCheckpointData = thisObj.state.extraData["dailyCheckpointData"];

    if (!hourlyCheckpointData) hourlyCheckpointData = [];
    if (!dailyCheckpointData) dailyCheckpointData = [];

    return (
        <React.Fragment>
            <div className="chartBg">
            <ChartTitle title="Hourly Checkpoint Stats" tip={tooltips.matic.hourlyCheckpoints} />
            <BootstrapTable keyField='title' data={hourlyCheckpointData}
                columns={ columns } options={options} defaultSorted={defaultSorted}
                condensed pagination={ paginationFactory(options) } />
            </div>
            <div className="chartBg">
            <ChartTitle title="Daily Checkpoint Stats" tip={tooltips.matic.dailyCheckpoints} />
            <BootstrapTable keyField='title' data={dailyCheckpointData}
                columns={ columns } options={options} defaultSorted={defaultSorted}
                condensed pagination={ paginationFactory(options) } />
            </div>
        </React.Fragment>
    );
  }

  static renderAddressDetails(validator) {
    return (
        <React.Fragment>
          <tr>
              <td>Validator ID: </td>
              <td> {validator.polygonId}</td>
          </tr>
          <tr>
              <td>Eth Balance: </td>
              <td> {validator.ethBalance}</td>
          </tr>
        </React.Fragment>
    );
  }

  static renderPerformanceDetails(validator) {
    return (
        <React.Fragment>
          <tr>
              <td>Current State: </td>
              <td> {validator.currentState}</td>
          </tr>
          <tr>
              <td>Is in auction?: </td>
              <td> {validator.isInAuction}</td>
          </tr>
          <tr>
              <td>Missed Latest Checkpoint Count: </td>
              <td> {validator.missedLatestCheckpointCount}</td>
          </tr>
          <tr>
              <td>Delta Performance Benchmark: </td>
              <td> {validator.deltaPerformanceBenchmark}%</td>
          </tr>
          <tr>
              <td>Performance Index: </td>
              <td> {validator.performanceIndex}</td>
          </tr>
        </React.Fragment>
    );
  }

  static renderValidatorBasicDetails(validator) {
    return (
        <React.Fragment>
          <tr>
              <td>Activation Epoch: </td>
              <td> {validator.activationEpoch}</td>
          </tr>
        </React.Fragment>
    );
  }

  static renderValidatorDetails(validator) {
    return (
        <React.Fragment>
            <ChartTitle title="Validator Details" tip={tooltips.valDetails.basic} />
            <table>
                <tbody>
                    <tr>
                        <td>Overall Health: </td>
                        <td> {MaticValidatorUtils.currentStateFormatter(validator.currentState, validator)}</td>
                    </tr>
                    <tr>
                        <td>Is in auction?: </td>
                        <td> {validator.isInAuction}</td>
                    </tr>
                    <tr>
                        <td>Missed Latest Checkpoint Count: </td>
                        <td> {validator.missedLatestCheckpointCount}</td>
                    </tr>
                    <tr>
                        <td>Delta Performance Benchmark: </td>
                        <td> {validator.deltaPerformanceBenchmark}</td>
                    </tr>
                    <tr>
                        <td>Eth Balance: </td>
                        <td> {validator.ethBalance}</td>
                    </tr>
                    <tr>
                        <td>Delegation Enabled: </td>
                        <td> {validator.delegationEnabled}</td>
                    </tr>
                    <tr>
                        <td>Activation Epoch: </td>
                        <td> {validator.activationEpoch}</td>
                    </tr>
                    <tr>
                        <td>Performance Index: </td>
                        <td> {validator.performanceIndex}</td>
                    </tr>
                </tbody>
            </table>
        </React.Fragment>
    );
  }

  static renderCheckpointSigns(thisObj) {
    let checkpoints = thisObj.state.extraData["checkpoints"];
    if (!checkpoints) checkpoints = {};

    let chartVisualId = currentNetwork.details.networkName + "-checkpoints";
    const downloadChart = () => {
      ChartUtils.downloadChart(document, chartVisualId, currentNetwork.details.app.title);
    };
    return (
      <div id={"div" + chartVisualId}>
        <ChartTitle title="Latest Checkpoints" tip={tooltips.matic.checkpointSigns} 
          saveChart={downloadChart} enableSave={true} />
        <ReactTooltip id="checkpointTip" place="top" type="dark" effect="float" multiline={true} />            
        <div>
            {checkpoints.map(function (checkpoint) {
                let perfStyle = "signed";
                if (checkpoint.signed === "false") {
                    perfStyle = "missed";
                }
                return (<div className={perfStyle} data-for="checkpointTip" data-tip={checkpoint.checkpointNum} data-iscapture="true" />);
                
            })}
        </div>
      </div>
    );
  }

  static getColsForValidators(thisObj, headerSortingClasses) {
    var maticColumns = [
      {text: "Fav", desc: "Favorite indicator", hidden: thisObj.isHidden("fav"), dataField: "fav", sort: true, formatter: FavUtils.favoriteFormatter, align: "left", formatExtraData: thisObj, headerStyle: Utilities.respWidth(5, 5, 2), headerSortingClasses},
      {text: "#", desc: "Index", hidden: thisObj.isHidden("vpRank"), dataField: "vpRank", sort: true, align: "left", headerStyle: Utilities.respWidth(5, 5, 2), headerSortingClasses},
      {text: "", filter: textFilter({placeholder: "Name"}), desc: "Validator Name", hidden: thisObj.isHidden("name"), dataField: "name", formatter: MainUtils.nameFormatterTableWithLogo, sort: true, headerStyle: Utilities.respWidth(45, 25, 15), headerSortingClasses},
      {text: "Status",  desc: "Validator Status", hidden: thisObj.isHidden("status"), dataField: "status", formatter: MainUtils.statusFormatter, sort: true, headerStyle: Utilities.width(6), headerSortingClasses},
      {text: "Self Delegation",  desc: ("Total tokens staked by the validator"), hidden: thisObj.isHidden("selfBond"), dataField: "selfBond", formatter: CoinUtils.stakeFormatterRounded, sort: true, headerStyle: Utilities.width(10), headerSortingClasses},
      {text: "Self Delegation %",  desc: "% of total voting power that is self staked by the validator", hidden: thisObj.isHidden("selfBondPercentage"), dataField: "selfBondPercentage", formatter: MainUtils.percentFormatter, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(10), headerSortingClasses},
      {text: "Voting Power (VP)",  desc: ("Total tokens staked with validator"), hidden: thisObj.isHidden("votingPower"), dataField: "votingPower", formatter: CoinUtils.stakeFormatterRounded, sort: true, headerStyle: Utilities.width(7), headerSortingClasses},
      {text: "% Voting Power",  desc: "% of total voting power that is with the validator", hidden: thisObj.isHidden("votingPowerPercentage"), dataField: "votingPowerPercentage", formatter: UITableUtils.vpProgressFormatter, formatExtraData: thisObj, style: UITableUtils.vpProgressStyle, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(10), headerSortingClasses},
      {text: Utilities.respLabel("Comm", "Comm", "Commission"), desc: "Current commission charged by validator", hidden: thisObj.isHidden("commission"), dataField: "commission", formatter: MainUtils.getCommission, sort: true, headerStyle: Utilities.respWidth(20, 12, 7), headerSortingClasses},
      {text: "Unique Delegates",  desc: "Total number of unique delegates", hidden: thisObj.isHidden("uniqueDelegates"), dataField: "uniqueDelegates", formatter: StakeUtils.formatDelegateLinkForCount, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(6), headerSortingClasses},
      {text: "Average Delegation",  desc: "Average Delegation of all delegates for the validator", hidden: thisObj.isHidden("averageDelegation"), dataField: "averageDelegation", formatter: StakeUtils.formatAverageDelegation, formatExtraData: true, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(12), headerSortingClasses},
      {text: "VP Change (1d)",  desc: "Change in voting power in 1 day", hidden: thisObj.isHidden("votingPowerChange1d"), dataField: "votingPowerChange1d", formatter: StakeUtils.formatVPChangeLink, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(9), headerSortingClasses},
      {text: "VP Change (7d)",  desc: "Change in voting power in 7 days", hidden: thisObj.isHidden("votingPowerChange7d"), dataField: "votingPowerChange7d", formatter: StakeUtils.formatVPChangeLink, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(9), headerSortingClasses},
      {text: "VP Change (30d)",  desc: "Change in voting power in 30 days", hidden: thisObj.isHidden("votingPowerChange30d"), dataField: "votingPowerChange30d", formatter: StakeUtils.formatVPChangeLink, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(9), headerSortingClasses},
      {text: "Delegate Change (1d)",  desc: "Change in number of unique delegates in 1d", hidden: thisObj.isHidden("uniqueDelegatesChange1d"), dataField: "uniqueDelegatesChange1d", formatter: StakeUtils.formatDelegateChangeLink, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(10), headerSortingClasses},
      {text: "Delegate Change (7d)",  desc: "Change in number of unique delegates in 7d", hidden: thisObj.isHidden("uniqueDelegatesChange7d"), dataField: "uniqueDelegatesChange7d", formatter: StakeUtils.formatDelegateChangeLink, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(10), headerSortingClasses},
      {text: "Delegate Change (30d)",  desc: "Change in number of unique delegates in 30d", hidden: thisObj.isHidden("uniqueDelegatesChange30d"), dataField: "uniqueDelegatesChange30d", formatter: StakeUtils.formatDelegateChangeLink, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(10), headerSortingClasses},
      {text: "Current Sign %",  desc: "Block signature % in last 8 hours", hidden: thisObj.isHidden("currentPerSigned"), dataField: "currentPerSigned", formatter: SignFmtUtils.currentPerSignedFmt, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(15, 15, 11), headerSortingClasses},
      {text: "Daily Sign %",  desc: "Block signature % in last 24 hours", hidden: thisObj.isHidden("dailyPerSigned"), dataField: "dailyPerSigned", formatter: SignFmtUtils.currentPerSignedFmt, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(11), headerSortingClasses},
      {text: "10D Sign %",  desc: "Block signature % in last 10 days", hidden: thisObj.isHidden("midPerSigned"), dataField: "midPerSigned", formatter: SignFmtUtils.currentPerSignedFmt, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(11), headerSortingClasses},
      {text: "Avg Sign %",  desc: "Block signature % in last 30 days", hidden: thisObj.isHidden("longPerSigned"), dataField: "longPerSigned", formatter: SignFmtUtils.longPerSignedFmt, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 7), headerSortingClasses},
      {text: "Net APR / APY (%)",  desc: "Net APR over last 30 days", hidden: thisObj.isHidden("longNetApr"), dataField: "longNetApr", formatter: ValidatorUtils.aprFormatter, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(25, 15, 7), headerSortingClasses},

      {text: "In Auction?",  desc: "In Auction?", hidden: thisObj.isHidden("isInAuction"), dataField: "isInAuction", sort: true, headerStyle: Utilities.width(8), headerSortingClasses},
      {text: "Overall Health",  desc: "Overall Health", hidden: thisObj.isHidden("currentState"), dataField: "currentState", formatter: MaticValidatorUtils.currentStateFormatter, sort: true, headerStyle: Utilities.width(8), headerSortingClasses},
      {text: "Missed Latest Checkpoint Count",  desc: "Missed Latest Checkpoint Count", hidden: thisObj.isHidden("missedLatestCheckpointCount"), dataField: "missedLatestCheckpointCount", sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(12), headerSortingClasses},
      {text: "Delta Performance Benchmark",  desc: "Delta Performance Benchmark", hidden: thisObj.isHidden("deltaPerformanceBenchmark"), dataField: "deltaPerformanceBenchmark", formatter: MainUtils.percentFormatter, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(10), headerSortingClasses},
      {text: "ETH Balance",  desc: "ETH balance remaining with the validator", hidden: thisObj.isHidden("ethBalance"), dataField: "ethBalance", formatter: CoinUtils.btcValueFormatter, sort: true, headerStyle: Utilities.width(10), headerSortingClasses},
      {text: "Polygon's Uptime",  desc: "Uptime percentage shown by Polygon Staking dashboard", hidden: thisObj.isHidden("uptimePercent"), dataField: "uptimePercent", formatter: MainUtils.percentFormatter, sort: true, headerStyle: Utilities.width(10), headerSortingClasses},
      {text: "Latest Signed Checkpoint",  desc: "Latest Signed Checkpoint", hidden: thisObj.isHidden("latestSignedCheckpoint"), dataField: "latestSignedCheckpoint", sort: true, headerStyle: Utilities.width(8), headerSortingClasses},

      {text: "Current Checkpoint Signed",  desc: "Checkpoint signed in last 8 hours", hidden: thisObj.isHidden("currentCheckpointSign"), dataField: "currentCheckpointSign", sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(15, 15, 11), headerSortingClasses},
      {text: "Current Checkpoint Missed",  desc: "Checkpoint missed in last 8 hours", hidden: thisObj.isHidden("currentCheckpointMissed"), dataField: "currentCheckpointMissed", sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(15, 15, 9), headerSortingClasses},
      {text: "Current Checkpoint Sign %",  desc: "Checkpoint signs % in last 8 hours", hidden: thisObj.isHidden("currentPerCheckpointSign"), dataField: "currentPerCheckpointSign", formatter: MaticValidatorUtils.checkpointSignFmt, formatExtraData: thisObj.state.coinStat, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(15, 15, 12), headerSortingClasses},

      {text: "Daily Checkpoint Signed",  desc: "Checkpoint signed in last 1 day", hidden: thisObj.isHidden("dailyCheckpointSign"), dataField: "dailyCheckpointSign", sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(15, 15, 11), headerSortingClasses},
      {text: "Daily Checkpoint Missed",  desc: "Checkpoint missed in last 1 day", hidden: thisObj.isHidden("dailyCheckpointMissed"), dataField: "dailyCheckpointMissed", sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(15, 15, 9), headerSortingClasses},
      {text: "Daily Checkpoint Sign %",  desc: "Checkpoint sign % in last 1 day", hidden: thisObj.isHidden("dailyPerCheckpointSign"), dataField: "dailyPerCheckpointSign", formatter: MaticValidatorUtils.checkpointSignFmt, formatExtraData: thisObj.state.coinStat, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(15, 15, 12), headerSortingClasses},

      {text: "10D Checkpoint Signed",  desc: "Checkpoint signed in last 10 days", hidden: thisObj.isHidden("midCheckpointSign"), dataField: "midCheckpointSign", sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(15, 15, 11), headerSortingClasses},
      {text: "10D Checkpoint Missed",  desc: "Checkpoint missed in last 10 days", hidden: thisObj.isHidden("midCheckpointMissed"), dataField: "midCheckpointMissed", sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(15, 15, 9), headerSortingClasses},
      {text: "10D Checkpoint Sign %",  desc: "Checkpoint sign % in last 10 days", hidden: thisObj.isHidden("midPerCheckpointSign"), dataField: "midPerCheckpointSign", formatter: MaticValidatorUtils.checkpointSignFmt, formatExtraData: thisObj.state.coinStat, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(15, 15, 12), headerSortingClasses},

      {text: "700 Checkpoint Signed",  desc: "Checkpoint signed in last 700 checkpoints", hidden: thisObj.isHidden("longCheckpointSign"), dataField: "longCheckpointSign", sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(15, 15, 11), headerSortingClasses},
      {text: "700 Checkpoint Missed",  desc: "Checkpoint missed in last 700 checkpoints", hidden: thisObj.isHidden("longCheckpointMissed"), dataField: "longCheckpointMissed", sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(15, 15, 9), headerSortingClasses},
      {text: "700 Checkpoint Sign %",  desc: "Checkpoint sign % in last 700 checkpoints", hidden: thisObj.isHidden("longPerCheckpointSign"), dataField: "longPerCheckpointSign", formatter: MaticValidatorUtils.checkpointSignFmt, formatExtraData: thisObj.state.coinStat, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(15, 15, 12), headerSortingClasses},

      {text: "Historical Checkpoint Signed",  desc: "Checkpoint signed in last 90 days", hidden: thisObj.isHidden("historicalCheckpointSign"), dataField: "historicalCheckpointSign", sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(15, 15, 11), headerSortingClasses},
      {text: "Historical Checkpoint Missed",  desc: "Checkpoint missed in last 90 days", hidden: thisObj.isHidden("historicalCheckpointMissed"), dataField: "historicalCheckpointMissed", sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(15, 15, 9), headerSortingClasses},
      {text: "Historical Checkpoint Sign %",  desc: "Checkpoint sign % in last 90 days", hidden: thisObj.isHidden("historicalPerCheckpointSign"), dataField: "historicalPerCheckpointSign", formatter: MaticValidatorUtils.checkpointSignFmt, formatExtraData: thisObj.state.coinStat, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(15, 15, 12), headerSortingClasses},

      {text: "Historical Sign %", desc: "Block signature % in the last 90 days", hidden: thisObj.isHidden("historicalPerSigned"), dataField: "historicalPerSigned", formatter: SignFmtUtils.longPerSignedFmt, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
      {text: "Historical Signed", desc: "Blocks signed in the last 90 days", hidden: thisObj.isHidden("historicalSigned"), dataField: "historicalSigned", formatter: SignFmtUtils.longSignedFmt, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
      {text: "Historical Missed", desc: "Blocks missed in the last 90 days", hidden: thisObj.isHidden("historicalMissed"), dataField: "historicalMissed", formatter: SignFmtUtils.longSignedFmt, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
  
      {text: "Operator Address", desc: "Operator Address", hidden: thisObj.isHidden("operatorAddress"), dataField: "operatorAddress", formatter: ValidatorUtils.opAddressFormatter, sort: true, headerStyle: Utilities.respWidth(25, 15, 10), headerSortingClasses},
      {text: "Signer Address", desc: "Signer Address", hidden: thisObj.isHidden("consensusNodeAddress"), dataField: "consensusNodeAddress", formatter: AddressUtils.addressFormatterShortWithCopy, sort: true, headerStyle: Utilities.respWidth(25, 15, 10), headerSortingClasses},
      {text: "Staking Contract", desc: "Staking Contract", hidden: thisObj.isHidden("hexAddress"), dataField: "hexAddress", formatter: AddressUtils.addressFormatterShortWithCopy, sort: true, headerStyle: Utilities.respWidth(25, 15, 10), headerSortingClasses},
      {text: "Signer Public Key", desc: "Signer Public Key", hidden: thisObj.isHidden("publicKey"), dataField: "publicKey", formatter: AddressUtils.addressFormatterShortWithCopy, sort: true, headerStyle: Utilities.respWidth(25, 15, 10), headerSortingClasses},
      {text: "Media", desc: "Contact Info for validator", hidden: thisObj.isHidden("website"), dataField: "website", formatter: MediaUtils.valMediaFormatter, sort: true, headerStyle: Utilities.respWidth(10, 8, 5), headerSortingClasses},
      {text: "Description", desc: "Description", hidden: thisObj.isHidden("description"), dataField: "description", sort: true, headerStyle: Utilities.respWidth(45, 45, 30), headerSortingClasses},

      {text: "Delegate",  desc: "Delegate button", hidden: thisObj.isHidden("delegate"), dataField: "delegate", sort: false, formatter: MainUtils.stakeFormatter, formatExtraData: thisObj, headerStyle: Utilities.width(11)},
    ];

    // return columns.concat(maticColumns);
    return maticColumns;
  }

  static checkpointSignFmt(cell, row, index, coinStat) {
    let value = cell
    if (!value) {
      value = 0;
    }

    let color = "green";

    value = value.toFixed(1);

    if (!value || value < parseFloat(coinStat.extraData4)) {
      color = "red";
    }

    value = parseFloat(value) + "%";
    let desc = "% of checkpoints signed: " + value + ".<br/>";
    return (<span data-for="main" data-tip={desc} data-iscapture="true">{MainUtils.colorFormatter(value, color)}</span>);
  }

  static currentStateFormatter(state, row) {
    let CURRENT_STATE_MAP = {"HEALTHY": "Healthy",
      "GRACE_PERIOD_1": "Grace Period 1",
      "GRACE_PERIOD_2": "Grace Period 2",
      "UNDER_MEASUREMENT_PERIOD": "Under Measurement Period",
      "NOTICE_OF_DEFICIENCY": "Notice of Deficiency",
      "FINAL_NOTICE": "Final Notice"
    };

    let value = CURRENT_STATE_MAP[state];

    if (!value) {
      return state;
    }

    return value;
  }

  static getPoolNote() {
    return (<Collapse isOpened={true}>
        <ul>
          <li>Checkpoint windows: Current - last 8 hours, Daily - last 1 day, 10D - last 10 days, Average - last 30 days, Historical - last 90 days</li>
          <li>Checkpoint performance: Validator performance for checkpoints in a given window as compared to the running benchmark performance over last 700 checkpoints.</li>
          <li>Block Signing windows: Current - last 8 hours, Daily - last 1 day, 10D - last 10 days, Average - last 30 days, Historical - last 90 days</li>
          <li>Delta Performance Benchmark: Indicates the %age delta in validator's checkpoint signing performance and benchmark performance in the last 700 checkpoints.</li>
        </ul>
      </Collapse>);
  }
}

export default MaticValidatorUtils;
