import React from 'react';
// import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import {Container} from 'react-bootstrap';
import ReactTooltip from "react-tooltip";

import MainUtils from "../main/MainUtils";
import '../styles/section.css';
import '../styles/tables.css';
import '../styles/validator.css';
import tooltips from '../tooltips';
import ChartTitle from '../components/ChartTitle';
import SPUtilities from '../util/SPUtilities';
import ValidatorUtils from '../validators/ValidatorUtils';
import StakeUtils from '../util/StakeUtils';
import currentNetwork from '../currentNetwork';
import CoinUtils from '../util/CoinUtils';
import AddressUtils from '../util/AddressUtils';

class ValInline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
    }
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  updateDimensions() {
    this.setState({width: window.innerWidth});
  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  async componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }

  render() {
    return (
      <React.Fragment>
        <p>{this.props.val.description}</p>
        <ReactTooltip id="main" place="top" type="dark" effect="float" multiline={true} />
        <Container fluid className="containerLayout container-fluid chartContainer">
          <div className="chartGridContainer">
            <div className="chartBg">{this.renderBasicDetails()}</div>
            <div className="chartBg">{this.renderPerformanceDetails()}</div>
          </div>
        </Container>
      </React.Fragment>
    );
  }
  // <div className="chartBg">{this.renderAddressDetails()}</div>

  // renderStat(key, label, value) {
  //   if (ValidatorUtils.isShow(key)) {
  //     return (
  //       <tr>
  //         <td>{label}: </td>
  //         <td> </td>
  //       </tr>

  //     );
  //   }

  // }
  

  renderBasicDetails() {
    return (
      <React.Fragment>
        <ChartTitle title="Validator Details" tip={tooltips.valDetails.basic} />
        <table>
          <tbody>
            {ValidatorUtils.isShow("name") && <tr>
              <td>Validator Name: </td>
              <td> {MainUtils.nameFormatterLong(this.props.val.name, this.props.val)}{MainUtils.renderNewValidatorBadge(this.props.val)}</td>
            </tr>}
            {ValidatorUtils.isShow("status") && <tr>
              <td>Status: </td>
              <td> {this.props.val.status}</td>
            </tr>}
            {ValidatorUtils.isShow("votingPower") && <tr>
              <td>Voting Power: </td>
              <td> {SPUtilities.formatCoins(this.props.val.votingPower, this.props.val)}</td>
            </tr>}
            {ValidatorUtils.isShow("vpRank") && <tr>
              <td>Voting Power Rank: </td>
              <td> {this.props.val.vpRank}</td>
            </tr>}
            {ValidatorUtils.isShow("votingPowerChange1d") && <tr>
              <td>Voting Power Change 1 day: </td>
              <td> {StakeUtils.formatVPChangeLink(this.props.val.votingPowerChange1d, this.props.val)}</td>
            </tr>}
            {ValidatorUtils.isShow("votingPowerPercentage") && <tr>
              <td>Voting Power Percentage: </td>
              <td> {MainUtils.percentFormatter(this.props.val.votingPowerPercentage)}</td>
            </tr>}
            {ValidatorUtils.isShow("averageDelegation") && <tr>
              <td>Average Delegation: </td>
              <td> {StakeUtils.formatAverageDelegation(this.props.val.averageDelegation, this.props.val)}</td>
            </tr>}
            {ValidatorUtils.isShow("uniqueDelegates") && <tr>
              <td>Unique Delegates: </td>
              <td> {StakeUtils.formatDelegateLinkForCount(this.props.val.uniqueDelegates, this.props.val)}</td>
            </tr>}
            {ValidatorUtils.isShow("uniqueDelegatesChange1d") && <tr>
              <td>Unique Delegates Change 1 day: </td>
              <td> {StakeUtils.formatDelegateChangeLink(this.props.val.uniqueDelegatesChange1d, this.props.val)}</td>
            </tr>}
            {currentNetwork.details.app.tendermint === true && ValidatorUtils.isShow("uniqueUndelegations") && <tr>
              <td>Unique Pending Undelegations Count: </td>
              <td> {StakeUtils.formatDelegateLinkForCount(this.props.val.uniqueUndelegations, this.props.val)}</td>
            </tr>}
            {currentNetwork.details.app.tendermint === true && ValidatorUtils.isShow("totalUndelegationAmount") && <tr>
              <td>Total Pending Undelegation Amount: </td>
              <td> {StakeUtils.formatDelegateLinkForAmount(this.props.val.totalUndelegationAmount, this.props.val)}</td>
            </tr>}
            {currentNetwork.details.ui.badges.showBadges === true && ValidatorUtils.isShow("ibcRelayerInd") && <tr>
              <td>Badges: </td>
              <td> {MainUtils.formatBadges(this.props.val.ibcRelayerInd, this.props.val)}</td>
            </tr>}
            {ValidatorUtils.isShow("oracleMissedVotes") && <tr>
              <td>Missed Oracle Votes: </td>
              <td> {this.props.val.oracleMissedVotes}</td>
            </tr>}
            {ValidatorUtils.isShow("extraData1") && <tr>
              <td>{currentNetwork.details.ui.validator.extra1Label}: </td>
              <td> {this.props.val.extraData1}</td>
            </tr>}
            {ValidatorUtils.isShow("orchAddress") && (<tr>
              <td>Orchestrator Address: </td>
              <td> {AddressUtils.addressFormatterSimpleWithLink(this.props.val.orchAddress, this.props.val)}</td>
            </tr>)}
            {ValidatorUtils.isShow("orchAddressBalance") && (<tr>
              <td>Orchestrator Address Balance (${currentNetwork.details.app.token}): </td>
              <td> {AddressUtils.orchAddressBalanceFormatter(this.props.val.orchAddressBalance, this.props.val)}</td>
            </tr>)}
            {ValidatorUtils.isShow("ethAddress") && (<tr>
              <td>ETH Address: </td>
              <td> {AddressUtils.ethAddressFormatter(this.props.val.ethAddress, this.props.val, 0, false)}</td>
            </tr>)}
            {ValidatorUtils.isShow("ethAddressBalance") && (<tr>
              <td>ETH Address Balance: </td>
              <td> {AddressUtils.ethAddressBalanceFormatter(this.props.val.ethAddressBalance, this.props.val)}</td>
            </tr>)}
            {/* {ChainUtils.isMatic() && MaticValidatorUtils.renderValidatorBasicDetails(this.props.val)} */}
          </tbody>
        </table>
      </React.Fragment>
    );
  }
//   <tr>
//   <td>Unique Delegates: </td>
//   <td> {this.props.val.uniqueDelegates}</td>
// </tr>
//   <tr>
//   <td>Total Shares: </td>
//   <td> {MainUtils.coinCountCellFormatter(this.props.val.totalShares, this.props.val)}</td>
// </tr>

  renderPerformanceDetails() {
    return (
      <React.Fragment>
        <ChartTitle title="Validator Assessment Details" tip={tooltips.valDetails.valAssess} />
        <table>
          <tbody>
            {ValidatorUtils.isShow("commission") && <tr>
              <td>Commission: </td>
              <td> {MainUtils.getCommissionPlus(this.props.val.commission, this.props.val, 0, false)} {MainUtils.getCommissionNote(this.props.val.commission, this.props.val, 0)}</td>
            </tr>}
            {ValidatorUtils.isShow("commissionMaxRate") && <tr>
              <td>Max Commission: </td>
              <td> {this.props.val.commissionMaxRate}%</td>
            </tr>}
            {ValidatorUtils.isShow("commissionMaxChangeRate") && <tr>
              <td>Max Commission Change Rate: </td>
              <td> {this.props.val.commissionMaxChangeRate}%</td>
            </tr>}
            <tr>
              <td>Website: </td>
              <td> {MainUtils.websiteLink(this.props.val.website, this.props.val.website)}</td>
            </tr>
            {ValidatorUtils.isShow("selfBond") && <tr>
              <td>Self Delegation: </td>
              <td> {CoinUtils.stakeFormatterRounded(this.props.val.selfBond, this.props.val)} / {MainUtils.percentFormatter(this.props.val.selfBondPercentage)}</td>
            </tr>}
            {ValidatorUtils.isShow("securityContact") && <tr>
              <td>Security Contact: </td>
              <td> {MainUtils.websiteLink(this.props.val.securityContact, this.props.val.securityContact)}</td>
            </tr>}

            {ValidatorUtils.isShow("voteParticipation") && <tr>
              <td>Governance Voting Participation: </td>
              <td> {ValidatorUtils.formatVoting(this.props.val.voteParticipation, this.props.val)}</td>
            </tr>}
            {ValidatorUtils.isShow("voteParticipationOverall") && <tr>
              <td>Governance Voting Participation Overall: </td>
              <td> {ValidatorUtils.formatVotingOverall(this.props.val.voteParticipationOverall, this.props.val)}</td>
            </tr>}
            {ValidatorUtils.isShow("longNetApr") && <tr>
              <td>Net APR: </td>
              <td> {ValidatorUtils.aprFormatter(this.props.val.longNetApr, this.props.val)}</td>
            </tr>}
            {ValidatorUtils.isShow("jailed") && <tr>
              <td>Jailed?</td>
              <td> {this.props.val.jailed}</td>
            </tr>}
            {ValidatorUtils.isShow("timesSlashed") && <tr>
              <td>Number of Times Jailed (after tracking begun) </td>
              <td> {(this.props.val.timesSlashed !== null ? this.props.val.timesSlashed : 0)}</td>
            </tr>}
            {ValidatorUtils.isShow("tombstoned") && <tr>
              <td>Tombstoned (permanently jailed)? </td>
              <td> {(this.props.val.tombstoned !== null ? this.props.val.tombstoned : "False")}</td>
            </tr>}
            {ValidatorUtils.isShow("extraData3") && (<tr>
              <td>{currentNetwork.details.ui.validators.extraData3Label}</td>
              <td> {this.props.val.extraData3}</td>
            </tr>)}
            {/* {ChainUtils.isMatic() && MaticValidatorUtils.renderPerformanceDetails(this.props.val)} */}
          </tbody>
        </table>
      </React.Fragment>
    );
  }

}


export default ValInline;
