import React from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import {Container} from 'react-bootstrap';
import ReactTooltip from "react-tooltip";

import tooltips from "../tooltips";
import MultiLineChart from '../charts/MultiLineChart';
import '../styles/section.css';
import currentNetwork from '../currentNetwork';
import ApiUtils from '../util/ApiUtils';
import Utilities from '../util/Utilities';
import CoinUtils from '../util/CoinUtils';
import UIUtils from '../util/UIUtils';
import ChartTitle from '../components/ChartTitle';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import MainUtils from '../main/MainUtils';
import SPCalc from '../util/SPCalc';
import RespUtils from '../util/RespUtils';
import UrlUtils from '../util/UrlUtils';

class Misc extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      coinStat: {},
      networkHistory: [],
      circulatingSupply: [],
      totalSupply: [],
      holderMilestones: [],
      stakerMilestones: [],
      notification: {},
      width: window.innerWidth,
      size: 10,
      responsive: true,
      isLoading: true,
      frequencyNetwork: "M",
      frequencyBlock: "H",
    }

    this.updateDimensions = this.updateDimensions.bind(this);
    this.handleChartUpdateNetwork = this.handleChartUpdateNetwork.bind(this);   
    this.handleChartUpdateBlock = this.handleChartUpdateBlock.bind(this);
  }


  updateDimensions() {
    this.setState({width: window.innerWidth});
  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  async componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);

    UrlUtils.setUrlFragment("misc");
    await this.prepareData(this.state.frequencyNetwork, this.state.frequencyBlock);
  }

  async prepareData(frequencyNetwork, frequencyBlock) {
    let url = "networkStats?type=misc&frequencyNetwork=" + frequencyNetwork + "&frequencyBlock=" + frequencyBlock;
    const allData = await ApiUtils.get(url);
    // "holderDistributionToken": allData["holderDistributionToken"], 

    let totalSupply = Utilities.removeEmptyRecords(allData["networkHistory"], "totalSupply");
    let circulatingSupply = Utilities.removeEmptyRecords(allData["networkHistory"], "circulatingSupply");
    this.setState({"frequencyNetwork": frequencyNetwork, "frequencyBlock": frequencyBlock,
        "notification": allData["notification"], "networkHistory": allData["networkHistory"], 
        "holderMilestones": allData["holderMilestones"], "stakerMilestones": allData["stakerMilestones"], 
        "totalSupply": totalSupply, "circulatingSupply": circulatingSupply, coinStat: allData["coinStat"],
        isLoading: false});
  }

  render() {
    if (this.state.isLoading) {
      return <div>Loading</div>;
    }

    let supplyColumns = [
      {text: "Churn Height", dataField: "title", sort: true, },
      {text: "Circulating Supply", hidden: (currentNetwork.details.ui.network.showCirculatingSupply !== true), dataField: "circulatingSupply", formatter: CoinUtils.stakeFormatterRoundIfNeeded, sort: true, },
      {text: "Total Supply", hidden: (currentNetwork.details.ui.network.showTotalSupply !== true), dataField: "totalSupply", formatter: CoinUtils.stakeFormatterRoundIfNeeded, sort: true, },
    ];

    return (
      <div>
        <ReactTooltip id="main" place="top" type="dark" effect="float" multiline={true} />
        <hr/>
        <Container fluid className="containerLayout container-fluid chartContainer">
          <div className="chartGridContainer">
            {currentNetwork.details.ui.network.showCirculatingSupply === true && <div className="chartBg" id="circulatingSupply">
              <MultiLineChart title="Circulating Supply" xAxis="Date" yAxis="Circulating Supply" adjustRange={true} 
                  chartVisualId="circulating-supply" enableSave={true} 
                  showVerticalLabel={false} valueAttr={["circulatingSupply"]} rangeAttr="circulatingSupply" showTotalLabel={false} xAxisValueAttr="title"
                  data={this.state.circulatingSupply} tip={tooltips.networkCharts.totalSupply} fsElementId="circulatingSupply" 
                  handleChart={this.handleChartUpdateNetwork} formatValues={true} bigMargin={true}
                  frequencies={["M", "Y", "All"]} frequency={this.state.frequencyNetwork}
                  dataKey="title" columns={supplyColumns} />
            </div>}
            {currentNetwork.details.ui.network.showTotalSupply === true && <div className="chartBg" id="totalSupply">
                <MultiLineChart title="Total Supply" xAxis="Date" yAxis="Total Supply" adjustRange={true} 
                  chartVisualId="total-supply" enableSave={true} 
                  showVerticalLabel={false} valueAttr={["totalSupply"]} rangeAttr="totalSupply" showTotalLabel={false} xAxisValueAttr="title"
                  data={this.state.totalSupply} tip={tooltips.networkCharts.totalSupply} fsElementId="totalSupply" 
                  handleChart={this.handleChartUpdateNetwork} formatValues={true} bigMargin={true}
                frequencies={["M", "Y", "All"]} frequency={this.state.frequencyNetwork}
                dataKey="title" columns={supplyColumns} />
            </div>}
            <div className="chartBg" id="holderMilestons">
              {this.getMilestone("Holder Milestones", this.state.holderMilestones, tooltips.networkCharts.holderMilestones)}
            </div>
            <div className="chartBg" id="stakerMilestons">
              {this.getMilestone("Staker Milestones", this.state.stakerMilestones, tooltips.networkCharts.stakerMilestones)}
            </div>
          </div>
        </Container>
      </div>
    );
  }

  getMilestone(milestoneTitle, milestoneData, milestoneTooltip) {
    const headerSortingClasses = (column, sortOrder, isLastSorting, colIndex) => (
      sortOrder === 'asc' ? 'sorting-asc' : 'sorting-desc'
    );
    
    var columns = [
      {text: "Rank", dataField: "addressRank", sort: true, headerStyle: Utilities.respWidth(25, 15, 15), headerSortingClasses},
      {text: "Amount", dataField: "amount", formatter: CoinUtils.stakeFormatterRounded, sort: true, headerStyle: Utilities.respWidth(20, 20, 20), headerSortingClasses},
      {text: "USD Value", hidden: (RespUtils.isMobileView() || !this.state.coinStat.usdPrice), dataField: "amount", formatter: SPCalc.calcTotalUSD, formatExtraData: this, sort: true,  sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(15)},
      {text: "Top Percent Rank", dataField: "percentRank", formatter: MainUtils.percentText, sort: true, headerStyle: Utilities.respWidth(25, 15, 15), headerSortingClasses},
    ];

    // {text: Utilities.respLabel("% VP", "% VP", "Voting Power Percentage"), dataField: "votingPowerPercentage", formatter: CoinUtils.percentFormatter, sort: true, headerStyle: Utilities.respWidth(25, 20, 15), headerSortingClasses},
    // {text: "Unique Delegates", dataField: "uniqueDelegates", sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(35, 20, 20), headerSortingClasses},

    const options = UIUtils.getPageOptionsSmall(this, 5);
    const defaultSorted = [{dataField: 'addressRank', order: 'asc'}];
    return (
      <div>
        <ChartTitle title={milestoneTitle + " (>=1 $" + currentNetwork.details.app.token + ")"} tip={milestoneTooltip} />
        <p/>
        <ReactTooltip id="main" place="top" type="dark" effect="float" multiline={true} />
        <BootstrapTable keyField={currentNetwork.details.ui.identifierAttribute} data={milestoneData}
          columns={columns} striped options={options} defaultSorted={defaultSorted}
          condensed noDataIndication="No data" pagination={ paginationFactory(options) } />
      </div>
    );
  }
  
  handleChartUpdateNetwork(frequencyNetwork) {
    this.prepareData(frequencyNetwork, this.state.frequencyBlock);
  }

  handleChartUpdateBlock(frequencyBlock) {
    this.prepareData(this.state.frequencyNetwork, frequencyBlock);
  }

}

export default Misc;
