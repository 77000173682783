export default {
    networks: {
        minCommonColumns: ["fav", "name",],
        // commonColumnsEnd: [],
        extraCommonColumns: ["index", "twitter", "symbol", "analyticsWebsite",],
        maxColumnsShownResp: 2,
        maxColumnsShown: 7,
        viewColumns: {
            "addresses": ["activeAccounts", "activeAddress24h", "netNewWallets7d", "netNewWallets30d", "activeAccountPC7d", "activeAccountPC30d",],
            "decentralization": ["nakamotoIndex", "gini", "avgVotingPower", "valBelowAvgPower", "top100StakeShare", "oneEqualsNBottomVal", "oneEqualsNBottomValPercent",],
            "delegation": ["uniqueDelegates", "netNewDelegates30d", "averageDelegationUsd", "uniqueDelegations", "uniqueDelegatePC30d", "netNewDelegates7d", "uniqueDelegatePC7d",],
            "market": ["fdvMcap", "usdMcap", "totalSupply", "totalStake", "totalStakeUsd",],
            "misc": ["weightedMedianCommission", "medianFee", "lastValStake", "lastValStakeUsd", "recentAvgVotes", "recentMaxVotes",],
            "params": ["unbondingTime", "slashFractionDowntime", "consensusValidators", "signedBlocksWindow", "slashingMinPerUptime",],
            "staking": ["totalStakeUsd", "percentStaked", "currentRewardRate", "totalStake", "totalStakePC7d", "totalStakePC30d", "unbondedStakeUsd", "unbondedStake",],
            "undelegation": ["totalUndelegationAmount", "uniqueUndelegations", "undelPercentOfTotalSupply", "undelPercentOfNotStakedSupply", "unbondedStakePercent",],
            "usage": ["txCount24h", "activeAddress24h", "successfulTxs", "failedTxs", "txCount7d",],
        },
        views: [
            { "code": "", "description": "Default View" },
            { "code": "addresses", "description": "Addresses" },
            { "code": "decentralization", "description": "Decentralization" },
            { "code": "delegation", "description": "Delegation" },
            { "code": "market", "description": "Market" },
            { "code": "misc", "description": "Misc" },
            { "code": "params", "description": "Params" },
            { "code": "staking", "description": "Staking" },
            { "code": "undelegation", "description": "Undelegation" },
            { "code": "usage", "description": "Usage" }]
    },
    validators: {
        minCommonColumns: ["fav", "name",],
        // commonColumnsEnd: ["delegate"],
        extraCommonColumns: ["vpRank", "ibcRelayerInd", "delegate",],
        maxColumnsShownResp: 2,
        maxColumnsShown: 7,
        viewColumns: {
            "addresses": ["operatorAddress", "consensusNodeAddress", "address", "publicKey", "hexAddress"],
            "average": ["longPerSigned", "longOraclePerSigned", "timesSlashed", "status", "extraData1", "extraData3",
                "hasToValidate", "ethAddressBalance", "longPerCheckpointSign", "historicalPerCheckpointSign",
                "historicalPerSigned", "longCheckpointSign", "longCheckpointMissed",
                "historicalCheckpointSign", "historicalCheckpointMissed",
                "longMissed", "longOracleMissed", "latestSlashedTime", 
                "historicalMissed", "longSigned", "historicalSigned",
                "hasToValidate", 
            ],
            "basic": ["website", "commissionPlus", "longNetApr", "longPerSigned", "selfBond", "status",
                "voteParticipation", "selfBondPercentage", "securityContact", "description", "commission",
                "commissionMaxChangeRate", "commissionMaxRate"],
            "commission": ["commissionPlus", "commission", "commissionMaxChangeRate", "commissionMaxRate", "status",],
            "current": ["currentPerSigned", "icsCurrentPerSigned", "icsPerf", "slashingWindowUptime", "status", "hasToValidate", 
                "orchAddressBalance", "oracleMissedVotes", "extraData3", "ethAddressBalance", "currentMissed",
                "currentOracleMissed", "currentOraclePerSigned", "slashingWindowMissedBlocks", "currentPerCheckpointSign", "latestSlashedTime", 
                "dailyPerCheckpointSign", "currentSigned", "dailyMissed", "dailyOracleMissed", "dailyOraclePerSigned", "dailyPerSigned",
                "dailySigned", "currentCheckpointSign", "currentCheckpointMissed", "dailyCheckpointSign", "dailyCheckpointMissed",
            ],
            "decentralization": ["votingPowerPercentage", "votingPower", "averageDelegation", "uniqueDelegates",
                "status", "commissionPlus"],
            "growth": ["uniqueDelegatesChange30d", "votingPowerChange30d", "uniqueDelegatesChange1d", "uniqueDelegatesChange7d", "status",
                "votingPowerChange1d", "votingPowerChange7d", "commissionPlus"],
            "intro": ["website", "description", "commissionPlus", "securityContact", "status", ],
            "misc": ["extraData1", "voteParticipation", "voteParticipationOverall", "orchAddress",
                "orchAddressBalance", "oracleMissedVotes", "extraData3", "ethAddressBalance", "ethAddress",
                "commissionPlus", "votingPower", "status",],
            "perf": [
                "longPerSigned", "icsCurrentPerSigned", "icsPerf", "voteParticipation", "longOraclePerSigned", "hasToValidate",
                "orchAddressBalance", "oracleMissedVotes", "currentOracleMissed", "currentOraclePerSigned", "status",
                "currentPerSigned", "slashingWindowUptime", "slashingWindowMissedBlocks", "extraData3", "ethAddressBalance",
                "latestSlashedTime", "timesSlashed", "longPerCheckpointSign", "historicalPerCheckpointSign", 
                "currentPerCheckpointSign", "historicalPerSigned", 
                "dailyPerCheckpointSign", 
                "currentMissed", "midPerSigned", 
                "longCheckpointSign", "longCheckpointMissed", "historicalCheckpointSign", "historicalCheckpointMissed",
                "currentSigned", "orchAddress", "dailyMissed", "dailyOracleMissed", "dailyOraclePerSigned",
                "dailyPerSigned", "dailySigned", "currentCheckpointSign", "currentCheckpointMissed", "dailyCheckpointSign", "dailyCheckpointMissed",
                "longMissed", "longOracleMissed", "longSigned", "historicalSigned",
                "historicalMissed", "midMissed", "midOracleMissed", "midOraclePerSigned", "midSigned",],
            "staking": ["commissionPlus", "votingPower", "votingPowerPercentage", "totalUndelegationAmount", "uniqueDelegates", "uniqueUndelegations",
                "uniqueDelegatesChange30d", "votingPowerChange30d", "uniqueDelegatesChange1d", "uniqueDelegatesChange7d",
                "votingPowerChange1d", "votingPowerChange7d", "status",],
        },
        views: [
            { "code": "", "description": "Default View" },
            { "code": "addresses", "description": "Addresses" },
            { "code": "average", "description": "Average Performance" },
            { "code": "basic", "description": "Basic" },
            { "code": "current", "description": "Current Performance" },
            { "code": "decentralization", "description": "Decentralization", "disable4ConsumerChain": true },
            { "code": "growth", "description": "Growth", "disable4ConsumerChain": true },
            { "code": "intro", "description": "Introduction" },
            { "code": "misc", "description": "Misc", "disable4ConsumerChain": true, "disable4NonTendermint": true },
            { "code": "perf", "description": "Performance" },
            { "code": "staking", "description": "Staking", "disable4ConsumerChain": true },
        ]
    }
};
