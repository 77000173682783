import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';

import Stat from '../components/Stat';
import tooltips from '../tooltips';
import CoinUtils from '../util/CoinUtils';
import SessionUtils from '../util/SessionUtils';
import TxUtils from '../events/TxUtils';

class TokenSummaryStat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      size: 10,
      isLoading: true,
    }
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  updateDimensions() {
    this.setState({width: window.innerWidth});
  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  render() {
    return (
      <Container className="containerLayout" fluid>
        <Row className="statRowLayout">
          <Col className="statColLayout" xs={6} sm={4} md={3} lg={3}>
            <Stat label={"Active Addresses (>" + this.props.minBalance + " " + this.props.tokenDetails.token + ")"} labelShort="Active Addresses" 
              value={this.props.tokenDetails.activeAccounts} info={tooltips.netstats.activeAddresses} 
              link={SessionUtils.getUrl("/tokens/" + encodeURIComponent(this.props.tokenDetails.token))} linkStyle="animLinkVisual"/>
          </Col>
          {this.props.tokenDetails.stakingContract != null && <Col className="statColLayout" xs={6} sm={4} md={3} lg={3}>
            <Stat label="Unique Stakers" value={this.props.tokenDetails.uniqueDelegates} 
                info={tooltips.netstats.uniqueDelegates} 
                link={SessionUtils.getUrl("/tokens/" + encodeURIComponent(this.props.tokenDetails.token))}/>
          </Col>}
          {this.props.tokenDetails.totalSupply != null && this.props.tokenDetails.totalSupply > 0 && 
            (<Col className="statColLayout" xs={6} sm={4} md={3} lg={3}>
              <Stat label={"Total Supply"} value={CoinUtils.stakeFormatterRoundIfNeeded(this.props.tokenDetails.totalSupply)} 
                info={tooltips.netstats.totalSupply} 
                link={SessionUtils.getUrl("/tokens/" + encodeURIComponent(this.props.tokenDetails.token) + "/history")} linkStyle="animLinkVisual"/>
            </Col>)}
          <Col className="statColLayout" xs={6} sm={4} md={3} lg={3}>
              <Stat label="Denom/Contract" value={TxUtils.formatAddress(this.props.tokenDetails.denom, this.props.tokenDetails)} />
          </Col>
          <Col className="statColLayout" xs={6} sm={4} md={3} lg={3}>
              <Stat label="Exponent" value={this.props.tokenDetails.exponent} />
          </Col>
        </Row>
      </Container>  
    );
  }
  
}

export default TokenSummaryStat;
