import React from "react";
import {ResponsiveContainer, LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, Label, Legend, ReferenceLine } from 'recharts';

import UIUtils from '../util/UIUtils';
import Utilities from '../util/Utilities';
import ChartUtils from '../util/ChartUtils';

import "../styles/charts.css";
import ChartTitle from "../components/ChartTitle";
import SavedSettings from "../util/SavedSettings";
import AppList from "../base/AppList";
import common from "../common";
import currentNetwork from "../currentNetwork";


class VPCurveChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      responsive: true,
      lineColor: common.colors.chartColorMain,
      lineProps: [],
      lineHover: null,
    }

    this.flipResponsive = this.flipResponsive.bind(this);
    this.renderAdditionalLink = this.renderAdditionalLink.bind(this);
  }

  flipResponsive() {
    ChartUtils.flipResponsive(this);
  }

  async componentDidMount() {
    let lineProps = {};
    let numberOfLines = 0;
    for(let i=0; i < this.props.chains.length; i++) {
      let value = this.props.chains[i]["value"];
      lineProps[value] = true;
      numberOfLines++;
    }

    this.setState({lineProps: lineProps, numberOfLines: numberOfLines});
  }

  renderLines() {
    let textColor = common.colors.text;
    let width = UIUtils.getResponsiveWidth(this);
    let height = UIUtils.getFSChartHeight(this);
    let colors = common.colors.chartColorsArray2;
    let index = -1;

    const handleLegendMouseEnter = (e) => {
      this.setState({lineHover: e.dataKey});
    };
    const handleLegendMouseLeave = (e) => {
      this.setState({lineHover: null});
    };
    const selectLine = (e) => {
      let lineProps = this.state.lineProps;
    //   console.log(lineProps);
      let selectedItem = e.dataKey;
    //   console.log(selectedItem);

      let itemVisibility = lineProps[selectedItem];
    //   console.log(itemVisibility);

      if (typeof itemVisibility === 'undefined') {
        lineProps[selectedItem] = false;
      } else {
        itemVisibility = !itemVisibility;
        lineProps[selectedItem] = itemVisibility;
      }

      this.setState({lineProps: lineProps});
    };

    if (!this.props.data) {
        return "";
    }

    return (
      <div>
        <ResponsiveContainer width={width} height={height}>
          <LineChart data={this.props.curveIndexes} 
            margin={UIUtils.getChartMargin(this)}>

            <XAxis xAxisId="0" dataKey={"index"} stroke={textColor} padding={{ top: 10 }} angle={-10}/>
            <YAxis stroke={textColor} />
            <Tooltip labelStyle={ {color: 'black'}} />
            <ReferenceLine y={33.34} stroke="lightgray" label="33.34%" />
            <ReferenceLine y={66.67} stroke="lightgray" label="66.67%" />
            {
              this.props.chains.map((id) => {
                index++;
                let key = id["value"];
                let data = this.props.data[key];
                // console.log(index + " - " + key);
                // console.log(data);
                return (
                        <Line data={data} type="monotone" key={colors[index]} dataKey={key} strokeWidth={3} connectNulls={false} 
                            hide={this.state.lineProps[key] === false} opacity={Number(this.state.lineHover === id || !this.state.lineHover ? 1 : 0.3)}
                            label={false} dot={false} stroke={colors[index]} />
                )
              })
            }
            {this.props.showLegend && (<Legend onClick={selectLine} onMouseOver={handleLegendMouseEnter} onMouseOut={handleLegendMouseLeave} />)}
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }
  // this.props.data !== {} && 

  render () {
    let chartVisualId = this.props.chartVisualId;
    const downloadChart = () => {
      ChartUtils.downloadChart(document, chartVisualId, currentNetwork.details.app.title);
    };

    return (
      <div id={"div" + chartVisualId}>
        <ChartTitle title={this.props.title} tip={this.props.tip} handleChart={this.props.handleChart} disableDay={this.props.disableDay} 
          statType={this.props.statType} isOverallOnly={this.props.isOverallOnly} disableWeek={this.props.disableWeek} 
          addDays={this.props.addDays} displayHelpIcon={this.props.displayHelpIcon} fsElementId={this.props.fsElementId}
          frequency={this.props.frequency} showHelp={this.showHelp} frequencies={this.props.frequencies}
          showAdditionalLink={true} renderAdditionalLink={this.renderAdditionalLink} shortTitle={this.props.shortTitle}
          saveChart={downloadChart} enableSave={true} />
        <div align="center">
          {this.props.subtitle && <div className="compSubTitle">{this.props.subtitle}</div> }
          {this.props.showLatestLabel ? this.props.latestLabelPrefix + ": " + Utilities.getLastRecordAttributeRound(this.props.data, this.props.valueAttr[0], this.props.roundLatestValue) + "." : ""}
          {this.props.showTwoLatestLabel ? this.props.latestLabelPrefix + ": " + Utilities.getLastRecordAttributeRound(this.props.data, this.props.valueAttr[0], this.props.roundLatestValue) + "/" + Utilities.getLastRecordAttribute(this.props.data, this.props.valueAttr[1], this.props.roundLatestValue) : ""}
          {this.renderLines()}
        </div>
        {ChartUtils.getLandscapeMsg()}
      </div>
    );
  }

  closeHelp() {
    SavedSettings.setHelpNotNeeded(this, this.props.chartName);
  }

  showHelp() {
    SavedSettings.setHelpNeeded(this, this.props.chartName);
  }

  renderAdditionalLink() {
    if (typeof this.props.additionalLink != 'undefined') {
      return this.props.additionalLink();
    }

    if (this.props.selectedAppsForVPCurve) {
      return <AppList selectedApps={this.props.selectedAppsForVPCurve} setApplist={this.props.setSelectedAppsForVPCurve}/>;
    }

    return "";
  }
}

export default VPCurveChart;
