import React from 'react';

import RespUtils from './RespUtils';
import SPCalc from './SPCalc';

class DateUtils extends React.Component {
  static formatTimeLeft(seconds) {
    if (!seconds) {
      return seconds;
    }

    if (seconds < 60) {
      return "Soon";
    } else if (seconds < 3600) {
      let unit = RespUtils.isMobileView() ? "min":"minute";
      return SPCalc.getDesc(seconds, unit, 60);
    }

    let days = parseInt(seconds / 86400);
    let dayLabel = "d ";
    // let dayLabel = days > 1 ? " days " : " day ";

    let remTime = seconds % 86400;
    let hours = parseInt(remTime / 3600);
    let hourLabel = "h ";
    // let hourLabel = hours > 1 ? " hours " : " hour ";

    remTime = remTime % 3600;
    let minutes = parseInt(remTime / 60);
    let minuteLabel = "m ";
    // let minuteLabel = minutes > 1 ? " minutes " : " minute ";

    let overallLabel = "";
    if (days > 0) {
      overallLabel += days + dayLabel;
    }
    if (hours > 0) {
      if(overallLabel != "") overallLabel += " ";
      overallLabel += hours + hourLabel;
    }
    if (minutes > 0) {
      if(overallLabel != "") overallLabel += " ";
      overallLabel += minutes + minuteLabel;
    }

    return overallLabel;
  }

  static formatDaysLeft(seconds) {
    if (!seconds) {
      return "";
    }

    let days = parseInt(seconds / 86400);
    if (days === 0) {
      days = parseFloat(seconds / 86400)
    }
    let dayLabel = " days";

    return days + dayLabel;
  }

  static formatHoursLeft(seconds) {
    if (!seconds) {
      return "";
    }

    let days = parseInt(seconds / 3600);
    if (days === 0) {
      days = parseFloat(seconds / 3600)
    }
    let dayLabel = " hours";

    return days + dayLabel;
  }
}

export default DateUtils;
