import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ReactTooltip from "react-tooltip";

import Stat from '../components/Stat';
import tooltips from "../tooltips";
import MultiLineChart from '../charts/MultiLineChart';
import '../styles/section.css';
import Utilities from '../util/Utilities';
import currentNetwork from '../currentNetwork';
import ApiUtils from '../util/ApiUtils';
import BaseBarChart from '../charts/BaseBarChart';
import BidirectionalBarChart from '../charts/BidirectionalBarChart';
import UrlUtils from '../util/UrlUtils';

class Usage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        notification: {},
        width: window.innerWidth,
        size: 10,
        responsive: true,
        isLoading: true,
        frequencyNetwork: "M",
        frequencyBlock: "H",
        addressHistory: [],
        deltaActiveAccounts: [],
        txs: [],
        txSummary: [],
        coinStat: {}
    }

    this.updateDimensions = this.updateDimensions.bind(this);
    this.handleChartUpdateNetwork = this.handleChartUpdateNetwork.bind(this);   
    this.handleChartUpdateBlock = this.handleChartUpdateBlock.bind(this);
  }


  updateDimensions() {
    this.setState({width: window.innerWidth});
  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  async componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);

    UrlUtils.setUrlFragment("usage");
    await this.prepareData(this.state.frequencyNetwork, this.state.frequencyBlock);
  }

  async prepareData(frequencyNetwork, frequencyBlock) {
    let url = "networkStats?type=usage&frequencyNetwork=" + frequencyNetwork + "&frequencyBlock=" + frequencyBlock;
    const allData = await ApiUtils.get(url);

    let addressHistory = Utilities.removeEmptyRecords(allData["networkHistory"], "activeAccounts");
    let txs = Utilities.removeEmptyRecords(allData["networkHistory"], "txCount");
    let txSummary = allData["txSummary"];
    let deltaActiveAccounts = Utilities.removeEmptyRecords(allData["networkHistory"], "deltaActiveAccounts");
    this.setState({"frequencyNetwork": frequencyNetwork, "frequencyBlock": frequencyBlock,
        "notification": allData["notification"], "addressHistory": addressHistory,
        "deltaActiveAccounts": deltaActiveAccounts, "txs": txs, "coinStat": allData["coinStat"],
        "txSummary": txSummary, isLoading: false});
  }

  render() {
    if (this.state.isLoading) {
      return <div>Loading</div>;
    }

    if (currentNetwork.details.app.tendermint !== true) {
      return (<h3>This page is not supported for this network</h3>);
    }

    let txColumns = [
      {text: "Date", dataField: "title", sort: true, },
      {text: "Successful Tx", dataField: "successfulTxs", sort: true, },
      {text: "Failed Tx", dataField: "failedTxs", sort: true, },
    ];

    let addrColumns = [
      {text: "Date", dataField: "title", sort: true, },
      {text: "Addresses (>0 balance)", dataField: "activeAccounts", sort: true, },
      {text: "Daily Active Addresses", dataField: "activeAddresses", sort: true, },
      {text: "Net New Wallets (>0 balance)", dataField: "deltaActiveAccounts", sort: true, },
    ];

    return (
      <div>
        <ReactTooltip id="main" place="top" type="dark" effect="float" multiline={true} />
        <hr/>
        {this.getStats()}
        <Container fluid className="containerLayout container-fluid chartContainer">
          <div className="chartGridContainer">
                {this.state.txs.length > 0 && (<div className="chartBg" id="txs">
                    <BaseBarChart title={"Daily Transactions"} shortTitle="Daily Txs" 
                      chartVisualId="daily-txs" enableSave={true} 
                      tip={tooltips.networkCharts.txCount24h}
                      xAxis="Date" yAxis="Transactions" hideAxisTitle={true} fsElementId="txs" 
                      showVerticalLabel={false} valueAttr="txCount" showTotalLabel={false} xAxisValueAttr="title"
                      handleChart={this.handleChartUpdateNetwork} formatValues={true} frequencies={["M", "Y", "All"]} frequency={this.state.frequencyNetwork} 
                      data={this.state.txs} 
                      dataKey="title" columns={[
                        {text: "Date", dataField: "title", sort: true, },
                        {text: "Tx Count", dataField: "txCount", sort: true, },
                      ]} /> 
                </div>)}
                {this.state.txSummary.length > 0 && (<div className="chartBg" id="failedTxs">
                    <MultiLineChart title="Successful vs Failed Transactions" shortTitle="Failed Txs" 
                      chartVisualId="successful-vs-failed-txs" enableSave={true} 
                      xAxis="Date" yAxis="Count" adjustRange={false} 
                      showVerticalLabel={false} valueAttr={["failedTxs", "successfulTxs"]} 
                      rangeAttrs={["failedTxs", "successfulTxs"]} showTotalLabel={false} xAxisValueAttr="title"
                      data={this.state.txSummary} tip={tooltips.networkCharts.failedTxs} 
                      handleChart={this.handleChartUpdateNetwork} formatValues={true} fsElementId="failedTxs" 
                      frequencies={["W", "M", "Y", "All"]} frequency={this.state.frequencyNetwork}
                      dataKey="title" columns={txColumns} />
                </div>)}
                {this.state.txs.length > 0 && (<div className="chartBg" id="dau">
                    <BaseBarChart title={"Daily Active Addresses"} 
                      chartVisualId="daily-active-addresses" enableSave={true} 
                      tip={tooltips.networkCharts.activeAddress24h}
                      xAxis="Date" yAxis="Wallets" hideAxisTitle={true} fsElementId="dau" 
                      showVerticalLabel={false} valueAttr="activeAddresses" showTotalLabel={false} xAxisValueAttr="title"
                      handleChart={this.handleChartUpdateNetwork} formatValues={true} 
                      frequencies={["M", "Y", "All"]} frequency={this.state.frequencyNetwork} 
                      data={this.state.txs} 
                      dataKey="title" columns={addrColumns} />
                </div>)}
                {<div className="chartBg" id="activeAddresses">
                    <MultiLineChart title="Addresses (>0 balance)" shortTitle="Addresses" 
                      chartVisualId="addresses" enableSave={true} 
                      xAxis="Date" yAxis="Addresses" adjustRange={true} 
                      showVerticalLabel={false} valueAttr={["activeAccounts"]} rangeAttr="activeAccounts" showTotalLabel={false} xAxisValueAttr="title"
                      data={this.state.addressHistory} tip={tooltips.networkCharts.activeAccounts} 
                      handleChart={this.handleChartUpdateNetwork} formatValues={true} fsElementId="activeAddresses" 
                      frequencies={["M", "Y", "All"]} frequency={this.state.frequencyNetwork} 
                      dataKey="title" columns={addrColumns} />
                </div>}
                {<div className="chartBg" id="newWallets">
                  <BidirectionalBarChart title={"Net New Wallets (>0 balance)"} shortTitle="Net New Wallets" 
                      chartVisualId="net-new-wallets" enableSave={true} 
                      tip={tooltips.networkCharts.newWallets}
                      xAxis="Date" yAxis="Addresses" hideAxisTitle={true}
                      showVerticalLabel={false} valueAttr="deltaActiveAccounts" showTotalLabel={false} xAxisValueAttr="title"
                      handleChart={this.handleChartUpdateNetwork} formatValues={true} 
                      frequencies={["M", "Y", "All"]} frequency={this.state.frequencyNetwork} 
                      data={this.state.deltaActiveAccounts} 
                      dataKey="title" columns={addrColumns} />
                </div>}
            </div>
        </Container>
      </div>
    );
  }

  getStats() {
    return (
      <React.Fragment>
        <Container className="containerLayout" fluid>
          <Row className="statRowLayout">
            {this.state.coinStat.txCount24h != null && (<Col className="statColLayout" xs={6} sm={4} md={3} lg={3}>
              <Stat label="24h Active Addresses" value={this.state.coinStat.activeAddress24h} 
                info={tooltips.netstats.activeAddress24h} />
            </Col>)}
            {this.state.coinStat.txCount24h != null && (<Col className="statColLayout" xs={6} sm={4} md={3} lg={3}>
              <Stat label="24h Transactions" labelShort="Txs - 24h" value={this.state.coinStat.txCount24h} 
                info={tooltips.netstats.txCount24h} />
            </Col>)}
            {this.state.coinStat.successfulTxs != null && (<Col className="statColLayout" xs={6} sm={4} md={3} lg={3}>
              <Stat label="Successful/Failed Transactions" labelShort="Successful/Failed Txs" 
                value={this.state.coinStat.successfulTxs + "/" + this.state.coinStat.failedTxs} 
                info={tooltips.netstats.successfulFailedTransactions} />
            </Col>)}
          </Row>
        </Container>  
      </React.Fragment>
    );
  }

  handleChartUpdateNetwork(frequencyNetwork) {
    this.prepareData(frequencyNetwork, this.state.frequencyBlock);
  }

  handleChartUpdateBlock(frequencyBlock) {
    this.prepareData(this.state.frequencyNetwork, frequencyBlock);
  }

}

export default Usage;
