import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';

import ValColumnSelector from "../validators/ValColumnSelector";
import "../validators/Validators.css";
import '../styles/section.css';
import '../styles/tables.css';
import '../styles/validator.css';
import Utilities from "../util/Utilities";
import RespUtils from "../util/RespUtils";

import MainUtils from "../main/MainUtils";
import CoinUtils from '../util/CoinUtils';
import currentNetwork from '../currentNetwork';
import LSUtils from './LSUtils';
import ValidatorUtils from '../validators/ValidatorUtils';
import ColumnUtils from '../util/ColumnUtils';

const key = "lsv_4";

class LSMValidators extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.getInitColumns = this.getInitColumns.bind(this);
    this.renderFilters = this.renderFilters.bind(this);
  }

  render() {
    const headerSortingClasses = (column, sortOrder, isLastSorting, colIndex) => (
      sortOrder === 'asc' ? 'sorting-asc' : 'sorting-desc'
    );
    
    let token = currentNetwork.details.app.token;

    var columns = [
      {text: "#", desc: "Index", hidden: this.isHidden("index"), dataField: "index", sort: true, align: "left", headerStyle: Utilities.respWidth(7, 5, 4), headerSortingClasses},
      {text: "VP Rank", desc: "VP Rank", hidden: this.isHidden("vpRank"), dataField: "vpRank", sort: true, align: "left", headerStyle: Utilities.respWidth(5, 5, 4), headerSortingClasses},
      {text: "", filter: textFilter({placeholder: "Name"}), desc: "Validator Name", hidden: this.isHidden("name"), dataField: "name", formatter: MainUtils.nameFormatterTableWithLogo, sort: true, headerStyle: Utilities.respWidth(50, 25, 15), headerSortingClasses},
      {text: "Badges",  desc: "Badges", hidden: this.isHidden("badge1Ind"), dataField: "badge1Ind", formatter: MainUtils.formatLSMBadges, sort: true, headerStyle: Utilities.respWidth(15, 15, 6), headerSortingClasses},
      {text: "Status",  desc: "Validator Status", hidden: this.isHidden("status"), dataField: "status", formatter: MainUtils.statusFormatter, sort: true, headerStyle: Utilities.width(9), headerSortingClasses},
      {text: "Voting Power (VP)",  desc: ("Total " + token + " Staked with validator"), hidden: this.isHidden("votingPower"), dataField: "votingPower", formatter: CoinUtils.stakeFormatterRoundIfNeeded, sort: true, headerStyle: Utilities.width(10), headerSortingClasses},
      {text: "% Voting Power",  desc: "% of total voting power that is with the validator", hidden: this.isHidden("votingPowerPercentage"), dataField: "votingPowerPercentage", formatter: MainUtils.percentFormatter, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 12, 10), headerSortingClasses},
      {text: "Self Delegation",  desc: ("Total " + token + " Staked by the validator"), hidden: this.isHidden("selfBond"), dataField: "selfBond", formatter: CoinUtils.stakeFormatterRoundIfNeeded, sort: true, headerStyle: Utilities.width(10), headerSortingClasses},
      {text: "Self Delegation %",  desc: "% of total voting power that is self staked by the validator", hidden: this.isHidden("selfBondPercentage"), dataField: "selfBondPercentage", formatter: MainUtils.percentFormatter, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(10), headerSortingClasses},
      {text: Utilities.respLabel("Comm", "Comm", "Commission"), desc: "Current commission charged by validator", hidden: this.isHidden("commission"), dataField: "commission", formatter: MainUtils.getCommission, style: MainUtils.commissionStyleFormatter, sort: true, headerStyle: Utilities.respWidth(20, 12, 7), headerSortingClasses},
      {text: Utilities.respLabel("Val Bond", "Current Validator Bond", "Current Validator Bond"),  desc: ("Current validator bond for the validator"), hidden: this.isHidden("valBond"), dataField: "valBond", formatter: CoinUtils.stakeFormatterRoundIfNeeded, sort: true, headerStyle: Utilities.respWidth(15, 15, 7), headerSortingClasses},
      {text: "Minimum Validator Bond",  desc: ("Min validator bond to maintain LS delegations"), hidden: this.isHidden("minValBond"), dataField: "minValBond", formatter: CoinUtils.stakeFormatterRoundIfNeeded, sort: true, headerStyle: Utilities.width(7), headerSortingClasses},
      {text: "LSM Delegations",  desc: ("Total LSM Delegations"), hidden: this.isHidden("lsmDelegations"), dataField: "lsmDelegations", formatter: CoinUtils.stakeFormatterRoundIfNeeded, sort: true, headerStyle: Utilities.width(7), headerSortingClasses},
      {text: "Remaining LSM Capacity",  desc: ("Remaining LSM Capacity"), hidden: this.isHidden("valBondRemCapacity"), dataField: "valBondRemCapacity", formatter: LSUtils.formatValBondRemCapacity, sort: true, headerStyle: Utilities.width(7), headerSortingClasses},
      {text: Utilities.respLabel("Enough Bond?", "Does Val Bond Cover Delegation?", "Does Val Bond Cover Delegation?"),  desc: ("Does validator bond cover LSM delegations?"), hidden: this.isHidden("hasEnoughValBond"), dataField: "hasEnoughValBond", formatter: LSUtils.formatHasSufficientValBondInd, sort: true, headerStyle: Utilities.respWidth(15, 15, 7), headerSortingClasses},
      {text: "Stride Delegation",  desc: ("Stride Delegation"), hidden: this.isHidden("strdDelegation"), dataField: "strdDelegation", formatter: CoinUtils.stakeFormatterRoundIfNeeded, sort: true, headerStyle: Utilities.width(7), headerSortingClasses},
      {text: "pSTAKE Delegation",  desc: ("pSTAKE Delegation"), hidden: this.isHidden("pstakeDelegation"), dataField: "pstakeDelegation", formatter: CoinUtils.stakeFormatterRoundIfNeeded, sort: true, headerStyle: Utilities.width(7), headerSortingClasses},
      {text: "Quicksilver Delegation",  desc: ("Quicksilver Delegation"), hidden: this.isHidden("qckDelegation"), dataField: "qckDelegation", formatter: CoinUtils.stakeFormatterRoundIfNeeded, sort: true, headerStyle: Utilities.width(7), headerSortingClasses},
      {text: "Pryzm Delegation",  desc: ("Pryzm Delegation"), hidden: this.isHidden("pryzmDelegation"), dataField: "pryzmDelegation", formatter: CoinUtils.stakeFormatterRoundIfNeeded, sort: true, headerStyle: Utilities.width(7), headerSortingClasses},
      {text: "Drop Delegation",  desc: ("Drop Delegation"), hidden: this.isHidden("dropDelegation"), dataField: "dropDelegation", formatter: CoinUtils.stakeFormatterRoundIfNeeded, sort: true, headerStyle: Utilities.width(7), headerSortingClasses},
    ];

    return (
        <ToolkitProvider keyField="poolId" data={this.props.validators} columns={columns} columnToggle>
          {
            props => (
              <div id="mainTable">
                <div className="pageTitle">Liquid Staking Validators</div>
                <ValColumnSelector settingKey={key} getInitColumns={this.getInitColumns}
                  { ...props.columnToggleProps } refreshParent={this.props.repaintData} 
                  fsElementId="mainTable" hideFullScreenLink={true} renderFilters={this.renderFilters} />
                <BootstrapTable { ...props.baseProps } 
                    filter={ filterFactory() }
                    condensed noDataIndication="No Data" />
              </div>
            )
          }
        </ToolkitProvider>
    );
  }

  getInitColumns() {
    let columnsConfig = {};
    if (RespUtils.isTabletView()) {
      columnsConfig = {
            index: false, vpRank: true, name: false, badge1Ind: true, status: true, votingPower: true, 
            votingPowerPercentage: true, commission: true, valBond: true, 
            selfBond: true, selfBondPercentage: true, lsmDelegations: false, 
            strdDelegation: true, qckDelegation: true, pstakeDelegation: true, 
            pryzmDelegation: true, dropDelegation: true,
            valBondRemCapacity: true, hasEnoughValBond: false, minValBond: true};
    } else {
      columnsConfig = {
            index: false, vpRank: false, name: false, badge1Ind: false, status: true, votingPower: true, 
            votingPowerPercentage: true, commission: false, valBond: true, 
            selfBond: true, selfBondPercentage: true, lsmDelegations: false, 
            strdDelegation: false, qckDelegation: false, pstakeDelegation: false, 
            pryzmDelegation: false, dropDelegation: false,
            valBondRemCapacity: false, hasEnoughValBond: false, minValBond: false};
    }

    // console.log("interchain val perf - getInit - columns config is: ", columnsConfig);
    return columnsConfig;
  }

  isHidden(dataField) {
    return ColumnUtils.isHidden(key, dataField, this.getInitColumns);
  }

  renderFilters() {
    return (<div><table><tbody><tr>
        {ValidatorUtils.renderBadge(currentNetwork.details.ui.badges.badge1Ind, "/lsm/" + currentNetwork.details.ui.badges.badge1Key, 
          "Click to filter validator list for this provider", currentNetwork.details.ui.badges.badge1IndImg)}
        {ValidatorUtils.renderBadge(currentNetwork.details.ui.badges.badge2Ind, "/lsm/" + currentNetwork.details.ui.badges.badge2Key, 
          "Click to filter validator list for this provider", currentNetwork.details.ui.badges.badge2IndImg)}
        {ValidatorUtils.renderBadge(currentNetwork.details.ui.badges.badge3Ind, "/lsm/" + currentNetwork.details.ui.badges.badge3Key, 
          "Click to filter validator list for this provider", currentNetwork.details.ui.badges.badge3IndImg)}
        {ValidatorUtils.renderBadge(currentNetwork.details.ui.badges.badge4Ind, "/lsm/" + currentNetwork.details.ui.badges.badge4Key, 
          "Click to filter validator list for this provider", currentNetwork.details.ui.badges.badge4IndImg)}
        {ValidatorUtils.renderBadge(currentNetwork.details.ui.badges.badge5Ind, "/lsm/" + currentNetwork.details.ui.badges.badge5Key, 
          "Click to filter validator list for this provider", currentNetwork.details.ui.badges.badge5IndImg)}
      </tr></tbody></table></div>);
  }
}

export default LSMValidators;
