import React from 'react';
import numeral from 'numeral';

import CoinUtils from './CoinUtils';
import currentNetwork from '../currentNetwork';
import SPCalc from './SPCalc';
import SessionUtils from './SessionUtils';


class StakeUtils extends React.Component {

  static formatDelegateLinkForCount(cell, row) {
    if (!cell) {
        return 0;
    }

    return (<a className="animLink" href={SessionUtils.getUrl("/val/" + row[currentNetwork.details.ui.identifierAttribute])}
      >{cell}</a>);
  }

  static formatDelegateLinkForAmount(cell, row) {
    if (!cell) {
        return 0;
    }

    return (<a className="animLink" 
      href={SessionUtils.getUrl("/val/" + row[currentNetwork.details.ui.identifierAttribute])}
      >{CoinUtils.stakeFormatterRounded(cell)}</a>);
  }

  static formatDelegateChangeLink(cell, row) {
    if (!cell) {
        return cell;
    }

    return (<a className="animLink" href={SessionUtils.getUrl("/valstats/" + row[currentNetwork.details.ui.identifierAttribute])}
      >{StakeUtils.growthStyle(cell, false)}</a>);
  }

  static formatVPChangeLink(cell, row) {
    if (!cell) {
        return cell;
    }

    return (<a className="animLink" href={SessionUtils.getUrl("/valstats/" + row[currentNetwork.details.ui.identifierAttribute])}
      >{StakeUtils.roundedGrowthStyle(cell, false)}</a>);
  }

  static roundedGrowthStyle(inputValue, addBrackets) {
    if (!inputValue) {
      return inputValue;
    }

    let style = "positiveGrowth";
    let sign = "+";
    if (inputValue < 0) {
      style = "negativeGrowth";
      sign = "";
    }

    let displayValue;
    if (addBrackets === true) {
      displayValue = "(" + sign + numeral(inputValue).format('0.0a') + ")";
    } else {
      displayValue = sign + numeral(inputValue).format('0.0a');
    }

    // return SPUtilities.moneyFormatterPrecision(inputValue, 3);
    return (<span className={style}>{displayValue}</span>);
  }

  static growthStyleFromList(cell, row) {
    return StakeUtils.growthStyle(cell, false);
  }

  static growthStyle(inputValue, addBrackets) {
    if (!inputValue) {
      return inputValue;
    }

    let style = "positiveGrowth";
    let sign = "+";
    if (inputValue < 0) {
      style = "negativeGrowth";
      sign = "";
    }

    let displayValue;
    if (addBrackets === true) {
      displayValue = "(" + sign + inputValue + ")";
    } else {
      displayValue = sign + inputValue;
    }

    // return SPUtilities.moneyFormatterPrecision(inputValue, 3);
    return (<span className={style}>{displayValue}</span>);
  }

  static formatAverageDelegation(cell, row, index, hideToken) {
    if (!cell) return "";

    let averageDelegation = SPCalc.roundPrecision(cell, 0);
    let averageDelegationUsd = SPCalc.roundPrecision(row.averageDelegationUsd, 0);
    let tokenDesc = " $" + currentNetwork.details.app.token;

    if (hideToken === true) {
      tokenDesc = "";
    }

    if (!averageDelegationUsd) {
      // averageDelegationUsd = "N/A";
      return averageDelegation + tokenDesc;
    } else {
      averageDelegationUsd = "$" + averageDelegationUsd;
      return averageDelegation + tokenDesc + " / " + averageDelegationUsd;
    }

  }

  static formatInactiveValStake(cell, row) {
    if (!cell || cell == 0) return "";

    let unbondedStake = CoinUtils.stakeFormatterRounded(parseFloat(row.unbondedStake), 0);
    let unbondedStakeUsd = row.unbondedStakeUsd != null ? " / $" + numeral(row.unbondedStakeUsd).format('0.0a') : "";
    let unbondedStakePercent = row.unbondedStakePercent != null ? (" / " + row.unbondedStakePercent + "%") : "";
    let tokenDesc = " $" + currentNetwork.details.app.token;

    return unbondedStake + tokenDesc + unbondedStakeUsd + unbondedStakePercent;
  }

}

export default StakeUtils;
