import React, { Component } from 'react';

import SPUtilities from "../util/SPUtilities";

class LastUpdated extends Component {
  render() {
    let timezone = this.props.timezone;
    if (!timezone) {
      timezone = false;
    }

    let recentBlock = this.props.recentBlock;
    if (!recentBlock) {
      recentBlock = false;
    }

    return (
        <div>
            {SPUtilities.getLastUpdated(this.props.thisObj, false, timezone, recentBlock)}
        </div>
    );
  }
}

export default LastUpdated;
