import React from 'react';

// import MainUtils from './MainUtils';
// import UIUtils from '../util/UIUtils';
// import SPUtilities from '../util/SPUtilities';
import PageHeader from '../components/PageHeader';
import LogoUtils from '../util/LogoUtils';
import IntegrationUtils from '../util/IntegrationUtils';
import currentNetwork from '../currentNetwork';

class ValHeader extends React.Component {
  constructor(props) {
    super(props);
    this.getLogo = this.getLogo.bind(this);
    this.renderNetworkLinks = this.renderNetworkLinks.bind(this);
  }

  // <ValMenu />
  render() {
    return (
      <div>
        <PageHeader logo={this.getLogo} title={this.props.val.name + " - " + this.props.title} record={this.props.val} 
          thisObj={this.props.thisObj} notification={this.props.notification} additionalForTitle={this.renderNetworkLinks} />
      </div>
    );
  }

  getLogo() {
    return LogoUtils.formatLogoByPath(this.props.val.logoPath, this.props.val);
  }

  reload() {
    window.location.reload();
  }

  renderNetworkLinks() {
    let id = this.props.val[currentNetwork.details.ui.identifierAttribute];
    return (<div className="displayNetworkMedia"
      >{IntegrationUtils.renderIntegrations(this.props.coinStat, id)}</div>);
  }

}

export default ValHeader;
