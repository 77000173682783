import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import HelpIcon from '@material-ui/icons/Help';

import Tooltip from './Tooltip';
import DownloadUtils from '../util/DownloadUtils';
import RespUtils from '../util/RespUtils';
import common from '../common';

class ChartTitle extends Component {
  constructor(props) {
    super(props);
    this.handleChart = this.handleChart.bind(this);
    this.getDays = this.getDays.bind(this);
    this.handleFullScreen = this.handleFullScreen.bind(this);
    this.state = {
      fullScreenMode: false,
    }
  }

  render() {
    return (
        <div className="chartHeader">
          <div className="chartTitle">{this.getTitle()}
            &nbsp;
            <ReactTooltip id="main" place="top" type="dark" effect="float" multiline={true} />
            {this.props.tip && (<div className="chartTooltip"><Tooltip tip={this.props.tip} /></div>)}
          </div>

            {this.props.showAdditionalLink === true && this.props.renderAdditionalLink()}
            {this.getDays(this.handleChart)} 
            {/* {this.props.addDays && this.getDays()}  */}
            {this.props.enableSave === true && DownloadUtils.getSaveChart(this.props.saveChart)}
            {this.props.displayHelpIcon === true && (<button onClick={this.props.showHelp} className="tourHelp animLink"><HelpIcon /></button>)}
            {this.props.downloadData && DownloadUtils.getDownloadIconWithData(this.props.downloadData, this.props.title)} 
            {this.renderMode()} 
            {this.renderFullScreenLink()}
        </div>
    );
  }

  getDays(handleChart) {
    // console.log(this.props.frequencies);
    if (!this.props.frequencies) {
      // return this.renderFullScreenLink();
      return "";
    }
    
    let selectedFrequency = this.props.frequency;

    return (
      <React.Fragment>
        {this.props.frequencies.map(function (frequency) {
          let className = "frequencyLink animLink";
          if (frequency === selectedFrequency) {
            className="frequencyLink animLinkSelected animLink";
          }
          return (<button key={"frequency" + frequency} onClick={handleChart} value={frequency} 
            className={className}>{frequency}</button>);
        })}
      </React.Fragment>
    );
  }

  addDay() {
    // console.log(this.props.disableDay);
    if (this.props.disableDay === 'false' || this.props.disableDay === false) {
      return this.getDurationButton("D");
    }
    
    return "";
  }

  addWeek() {
    // console.log(this.props.disableDay);
    if (this.props.disableWeek === 'false' || this.props.disableWeek === false) {
      return this.getDurationButton("W");
    }
    
    return "";
  }

  handleChart(e) {
    // alert(e.target.value);
    this.props.handleChart(e.target.value);
  }

  getDurationButton(duration) {
    // console.log(duration, " - ", this.props.frequency);
    let className = "animLink";
    if (duration === this.props.frequency) {
      className="animLinkSelected animLink";
    }
    return (<button onClick={this.handleChart} value={duration} className={className}>{duration}</button>);
  }

  handleFullScreen() {
    RespUtils.handleFullScreen(this);
  }

  renderFullScreenLink() {
    // return RespUtils.renderFullScreenLink(this);
    return "";
  }

  getTitle() {
    if(this.props.shortTitle && RespUtils.isMobileView()) {
      return this.props.shortTitle;
    }

    return this.props.title;
  }

  renderMode() {
    if (!this.props.columns) return "";

    let tableInd = this.props.mode == common.charts.mode.table;
    let className = tableInd ? "mediaCustom fas fa-chart-bar fa-lg" : "mediaCustom fas fa-table fa-lg";
    return (
      <button onClick={this.props.switchMode} className="showItemAtTop animLink">
        <i className={className} />
      </button>
    )
  }

}

export default ChartTitle;
