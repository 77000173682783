import React from 'react';
import {Container} from 'react-bootstrap';
import ReactTooltip from "react-tooltip";

import '../styles/section.css';
import '../styles/tables.css';
import '../styles/validator.css';
import tooltips from '../tooltips';
import ChartTitle from '../components/ChartTitle';
import FdpUtils from './FdpUtils';
import SPCalc from '../util/SPCalc';
import SPUtilities from '../util/SPUtilities';

class FdpInline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
    }
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  updateDimensions() {
    this.setState({width: window.innerWidth});
  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  async componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }

  render() {
    return (
      <React.Fragment>
        <ReactTooltip id="main" place="top" type="dark" effect="float" multiline={true} />
        <Container fluid className="containerLayout container-fluid chartContainer">
          <div className="chartGridContainer">
            <div className="chartBg">{this.renderBasicDetails()}</div>
            <div className="chartBg">{this.renderAssessmentDetails()}</div>
          </div>
        </Container>
      </React.Fragment>
    );
  }
  

  renderBasicDetails() {
    return (
      <React.Fragment>
        <ChartTitle title="FDP Details" tip={tooltips.fdp.basic} />
        <table>
          <tbody>
            <tr>
              <td>FDP Source</td><td>{this.props.fdp.source}</td>
            </tr>
            <tr>
              <td>FDP Number</td><td>{FdpUtils.fdpFormatter(this.props.fdp.fdpNumber, this.props.fdp, null, this)}</td>
            </tr>
            <tr>
              <td>Assessment Title</td><td>{FdpUtils.fdpFormatter(this.props.fdp.periodLabel, this.props.fdp, null, this)}</td>
            </tr>
            <tr>
              <td>Start Date</td><td>{SPUtilities.formatDate(this.props.fdp.periodStart)}</td>
            </tr>
            <tr>
              <td>End Date</td><td>{SPUtilities.formatDate(this.props.fdp.periodEnd)}</td>
            </tr>
            <tr>
              <td>Validators Meeting Requirements</td><td>{this.props.fdp.valMetObjective}</td>
            </tr>
            <tr>
              <td>Validators Not Meeting Requirements</td><td>{this.props.fdp.valFailedObjective}</td>
            </tr>
            <tr>
              <td>Status</td><td>{this.props.fdp.status}</td>
            </tr>
            <tr>
              <td>Last Updated</td><td>{this.props.fdp.lastUpdated}</td>
            </tr>
          </tbody>
        </table>
      </React.Fragment>
    );
  }

  renderAssessmentDetails() {
    let fdpColumns = FdpUtils.getFdpColumns(this.props.fdp, false);
    return (
      <React.Fragment>
        <ChartTitle title="Assessment Details" tip={tooltips.fdp.assessmentDetails} />
        <table>
          <tbody>
            {FdpUtils.isToBeShown(fdpColumns, "metUptimeObj") && (
            <tr>
              <td>Minimum Uptime</td><td>{this.props.fdp.minUptime}% {FdpUtils.uptimeRollingDaysLabel(this.props.fdp)}</td>
            </tr>
            )}
            {FdpUtils.isExtraEnabled(this.props.fdp) && (
              <tr>
                <td>Peggo Max Downtime (# of days)</td><td>{this.props.fdp.extraMaxDowntime}</td>
              </tr>
            )}
            <tr>
              <td>Minimum Commission</td><td>{this.props.fdp.minCommission}%</td>
            </tr>
            <tr>
              <td>Maximum Commission</td><td>{this.props.fdp.maxCommission}%</td>
            </tr>
            {FdpUtils.isToBeShown(fdpColumns, "metSlashingFreqObj") && (
            <tr>
              <td>Maximum Slashing Frequency</td><td>{this.props.fdp.maxSlashingFreq}</td>
            </tr>
            )}
            {FdpUtils.isToBeShown(fdpColumns, "metSlashingMaxDurObj") && (
            <tr>
              <td>Maximum Slashing Duration (per occurrence)</td><td>{SPCalc.formatTimeLeft(this.props.fdp.maxSlashingDuration)}</td>
            </tr>
            )}
            {FdpUtils.isToBeShown(fdpColumns, "metUpgradeObj") && (
              <tr>
              <td>Upgrade Max Duration</td><td>{SPCalc.formatTimeLeft(this.props.fdp.upgradeMaxDuration)}</td>
            </tr>
            )}
            {FdpUtils.isToBeShown(fdpColumns, "metGovernanceObj") && (
              <tr>
              <td>Minimum Governance Participation</td><td>{this.props.fdp.minGovParticipation}% {FdpUtils.excludeSpamLabel(this.props.fdp)}</td>
            </tr>
            )}
            {FdpUtils.isToBeShown(fdpColumns, "metVPObj") && (
            // {FdpUtils.isLowVPEnabled(this.props.fdp) && (
              <tr>
                <td>Max Voting Power</td><td>{this.props.fdp.maxVP}%</td>
              </tr>
            )}
          </tbody>
        </table>
      </React.Fragment>
    );
  }
}

export default FdpInline;
