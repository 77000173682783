import React from 'react';
import currentNetwork from '../currentNetwork';

import LogoUtils from '../util/LogoUtils';

class KeybaseLogo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logoPath: currentNetwork.details.app.logoPath,
      isLoading: true,
    }
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  updateDimensions() {
    this.setState({width: window.innerWidth});
  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  async componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);

    let url = "https://keybase.io/_/api/1.0/user/lookup.json?fields=pictures&key_suffix=" + this.props.identity;

    fetch(url)
      .then(res => res.json())
      .then((data) => {
        let them = data.them;
        if (them && them.length > 0) {
            if (them[0].pictures && them[0].pictures.primary) {
                let picUrl = them[0].pictures.primary.url;
                this.setState({"logoPath": picUrl, isLoading: false});
            }
        }
    }).catch(console.log);

    this.setState({isLoading: false});
  }

  render() {
    let size = LogoUtils.getImageSize(true);
    return <img onError={LogoUtils.addDefaultSrc} className="logoStyle"
      width={size} height={size} src={this.state.logoPath} />;
  }

}

export default KeybaseLogo;
