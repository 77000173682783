import React from 'react';
import numeral from 'numeral';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';

import SPUtilities from '../util/SPUtilities';
import RespUtils from '../util/RespUtils';

import SPCalc from '../util/SPCalc';
import currentNetwork from "../currentNetwork";
import StakeFormatter from '../components/StakeFormatter';
import AddressUtils from '../util/AddressUtils';
import LogoUtils from '../util/LogoUtils';
import SessionUtils from '../util/SessionUtils';
import CommissionTip from '../guides/CommissionTip';
import constants from '../constants';

class MainUtils extends React.Component {

  static calcPercent(input1, input2) {
    // console.info("getStakeWeight - ", poolStake, totalStake);
    if (input2 === undefined || input2 === 0) {
      return "";
    }

    if (input1 === undefined || input1 === 0) {
      return 0;
    }

    return MainUtils.getPercentValue(input1, input2) + "%";
  }

  static getPercentValue(input1, input2) {
    // console.info("getStakeWeight - ", poolStake, totalStake);
    if (input2 === undefined || input2 === 0) {
      return "";
    }

    if (input1 === undefined || input1 === 0) {
      return 0;
    }

    return (input1 * 100 /input2).toFixed(2);
  }


  static websiteLink(label, link) {
    if (!label) {
      return label;
    }

    if (!link) {
      return label;
    }

    return (<a className="animLink" target="_blank"
      href={SPUtilities.getUrl(link)}>{label}</a>);
  }

  static websiteFormatterTable(cell, row) {
    if (!cell) {
      return cell;
    }

    return (<a className="animLinkTable" target="_blank"
      href={SPUtilities.getUrl(cell)}>{cell}</a>);
  }

  static websiteFormatter(cell, row) {
    if (!cell) {
      return cell;
    }

    return (<a className="animLink" target="_blank"
      href={SPUtilities.getUrl(cell)}>{cell}</a>);
  }

  static addressFormatter(cell, row, index, formatExtraData) {
    return MainUtils.addressFormatterByType(cell, true, true);
  }

  static addressFormatterDel(cell, row, index, formatExtraData) {
    return MainUtils.addressFormatterByType(cell, true, false);
  }

  static addressFormatterUndel(cell, row, index, formatExtraData) {
    return MainUtils.addressFormatterByType(cell, true, false);
  }

  static valAddressFormatterLong(address) {
    return MainUtils.addressFormatterByType(address, false, true);
  }

  static addressFormatterByType(address, short, validator) {
    if (!address) {
      return "";
    }

    let label = SPUtilities.addressLabelShortFormatter(address, null, short);
    let link = "/address/";
    let target = "";
    if(validator && validator === true) {
      target = "_blank";
      link = currentNetwork.details.integrations.stakingHomeForValidator;
    }

    
    return (<span><a className="animLink" target={target}
      href={SessionUtils.getUrl(link + address)}>{label}</a>{AddressUtils.copyLink(address)}</span>);
  }

  static addressFormatterByLabel(cell, row) {
    return MainUtils.addressFormatterLabelShortInd(cell, row, false);
  }

  static addressFormatterByLabelShort(cell, row) {
    return MainUtils.addressFormatterLabelShortInd(cell, row, true);
  }

  static addressFormatterLabelShortInd(cell, row, short) {
    if (!cell) {
      return "";
    }

    let shortInd = short;
    if (!shortInd) {
      shortInd = (window.innerWidth > 1000 ? false : true);
    }

    let label = row.alias;
    if (!row.alias) {
      label = SPUtilities.addressLabelShortFormatter(cell, null, shortInd);
    }

    let link = "/address/";

    return (<span><a className="black-a"
      href={SessionUtils.getUrl(link + row.address)}>{label}</a>
      &nbsp;{MainUtils.copyLink(row.address)}</span>);
  }

  static getNameLabel(cell) {
    let length = RespUtils.isMobileView() ? 12 : 40;

    return MainUtils.getShortText(cell, length);
  }

  static getNameLabelShorter(cell) {
    let length = RespUtils.isMobileView() ? 12 : 30;

    return MainUtils.getShortText(cell, length);
  }

  static nameFormatterNoLink(cell) {
    if (!cell) {
      return "";
    }

    let label = cell.split("?").join("");
    return label;
    // return MainUtils.getNameLabel(cell);
  }

  static nameFormatterNoLinkLimitSize(cell) {
    if (!cell) {
      return "";
    }

    let label = cell.split("?").join("");
    if (RespUtils.isTabletView() && label !== null && label.length > 15) {
      label = label.substring(0, 15) + "...";
    }

    return label;
    // return MainUtils.getNameLabel(cell);
  }

  static nameFormatterTableWithLogo(cell, row) {
    return MainUtils.nameFormatterWithLogoAndSize(cell, row, true);
  }

  static nameFormatterWithLogoAndSize(cell, row, short) {
    if (!cell) {
      return "";
    }

    let label = cell;
    if (short) {
      label = MainUtils.getNameLabelShorter(cell);
    }

    const onClick = e => e.stopPropagation();
    return (<span onClick={ onClick }><a className="tourValName animLinkTable"
      href={SessionUtils.getUrl("val/" + row[currentNetwork.details.ui.identifierAttribute])}>{LogoUtils.formatLogoByPathAndSizeAndStyle(
      row.logoPath, row, "logoStyleWithName")}&nbsp;&nbsp;{label}</a></span>);
  }

  static nameFormatterTable(cell, row) {
    if (!cell) {
      return "";
    }

    let label = MainUtils.getNameLabel(cell);

    const onClick = e => e.stopPropagation();
    return (<span onClick={ onClick }><a className="tourValName animLinkTable"
      href={SessionUtils.getUrl("/val/" + row[currentNetwork.details.ui.identifierAttribute])}>{label}</a>{MainUtils.renderNewValidatorBadge(row)}</span>);
  }

  static nameFormatter(cell, row) {
    if (!cell) {
      return "";
    }

    let label = MainUtils.getNameLabel(cell);

    const onClick = e => e.stopPropagation();
    return (<span onClick={ onClick }><a className="animLinkTable"
      href={SessionUtils.getUrl("/val/" + row[currentNetwork.details.ui.identifierAttribute])}>{label}</a></span>);
  }

  static nameFormatterShort(cell, row) {
    if (!cell) {
      return "";
    }

    let label = cell.split("?").join("");

    if (window.innerWidth < 1000 && label.length > 8) {
      label = label.substring(0, 6) + ".";
    }

    const onClick = e => e.stopPropagation();
    return (<span onClick={ onClick }><a className="black-a" href={SessionUtils.getUrl("/val/" + row.poolId)}
      >{label}</a></span>);
  }

  static nameFormatterLong(cell, row) {
    if (!cell) {
      return "";
    }

    let label = cell.split("?").join("");

    return (<span><a className="animLinkTable" href={SessionUtils.getUrl("/val/" + row[currentNetwork.details.ui.identifierAttribute])}
      >{label}</a></span>);
  }

  static replaceAll(value, search, replace) {
    return value.split(search).join(replace);
  }

  static copyLink(address) {
    let imgSrc = "/images/copy.png";
    let title = "Copy address to clipboard"
    return (<CopyToClipboard text={address}
        onCopy={() => {
          // formatExtraData.setState({copied: row.address});
          // console.log(row.address);
          // return false;
        }}>
        <img src={imgSrc} title={title}
          className="imgicon" width={16} height={16} />
      </CopyToClipboard>)

    // return (<CopyAddress address={address} />)
  }

  static addressLabelShortFormatter(value, row, short) {
    if (!value) {
      return value;
    }

    let label = value;
    if (short || (window.innerWidth < 1400 && value.length > 10)) {
      if (window.innerWidth < 600) {
        label = value.substring(0,6) + "..";
      } else {
        label = value.substring(0,10) + "..";
      }
    }

    return label;
  }

  static signPerFormatterTitle(cell, row, index, extra) {
    return cell;
  }

  static keySignPerFormatter(cell, row, index, extra) {
    let color = "green";

    if (!cell || cell < 0.97) {
      color = "red";
    } else if (cell > 0.98) {
      color = "green";
    } else {
      color = "orange";
    }

    return MainUtils.colorFormatter(cell, color);
  }

  static statusFormatter(cell, row, index, extra) {
    // let color = "green";

    // if (!cell || cell !== 'Bonded') {
    //   color = "red";
    // }

    let value = cell;
    if (RespUtils.isMobileView()) {
      value = value.substring(0, 4);
       // + "."
    }

    // return MainUtils.colorFormatter(value, color);
    return value;
  }

  static colorFormatter(value, color) {
    let size = "8";
    if (window.innerWidth > 1000) {
      size = 16;
    }

    let imgSrc = "/images/" + color + "-" + size + ".png"
    return (<span><img src={imgSrc} /> {value}</span>)
  }

  static coinCountCellFormatter(cell, row) {
    // console.log(typeof cell);
    if (!cell) {
      return cell;
    }

    return SPCalc.formatCoinCount(cell.toFixed(0));
  }

  static convertPercentFormatter(cell, row) {
    if (!cell) {
      if (cell === 0) {
        return (window.innerWidth > currentNetwork.details.MEDIUM) ? "0%" : "0";
      }
      return cell;
    }

    let value = cell
    if (cell > 0) {
      var formattedNumber = (cell * 100).toFixed(2).replace(/[.,]00$/, "");
      value = formattedNumber;
      // let percentVal = cell * 100;
      // return (percentVal.toFixed(2) + "%");
    }

    if (window.innerWidth > currentNetwork.details.MEDIUM) {
      return value + "%";
    }

    return value
  }

  static percentFormatter(cell, row) {
    return MainUtils.percentFormatterDecimal(cell, 2);
  }

  static percentFormatterSingle(cell, row) {
    return MainUtils.percentFormatterDecimal(cell, 1);
  }

  static percentFormatterDecimal(cell, decimalPlaces) {
    if (!cell) {
      if (cell === 0) {
        return <span align="right">0%</span>;
      }
      return cell;
    }

    let value = cell;
    if (cell > 0) {
      // console.log((typeof cell), " - in percentFormatter: ", cell);
      var formattedNumber = (cell).toFixed(decimalPlaces).replace(/[.,]00$/, "");
      value = formattedNumber;
    }

    return value + "%";
  }

  static percentValue(cell, decimalPlaces) {
    if (!cell) {
      if (cell === 0) {
        return "0%";
      }
      return "N/A";
    }

    let value = cell;
    if (cell > 0) {
      // console.log((typeof cell), " - in percentFormatter: ", cell);
      var formattedNumber = (cell).toFixed(decimalPlaces).replace(/[.,]00$/, "");
      value = formattedNumber;
    }

    return value + "%";
  }

  static percentText(cell) {
    if (!cell) {
      return cell;
    }

    return cell + "%";
  }

  static formatBigCoinCount(count) {
    if (window.innerWidth < currentNetwork.details.MEDIUM) {
      return SPUtilities.stakeFormatterRounded(count);
    } else {
      return SPCalc.formatCoinCount(count);
    }
  }

  static logoFormatter(cell, row) {
    let url = "images/logo/" + row.address + ".jpg";
    return MainUtils.logoFormatterFlex(url, false);
  }

  static logoFormatterFlex(logoPath, large) {
    // console.log(logoPath)
    if (!logoPath) {
      return logoPath;
    }

    let size = 16;

    if (large && window.innerWidth < 1000) {
      size = 32;
    } else if (large) {
      size = 32;
    } else if (window.innerWidth > 1000) {
      size=20;
    }

    return "";
  }

  static getSupportUs() {
    return "";
  }

  static getCommissionPlus(cell, row, index, extraData) {
    // console.log(row);
    // if (row.commIncreaseTime !== null) {
    //   // return (<span style={{"backgroundColor": "orange", "padding": "0.2rem"}}>{row.commIncreaseDesc}</span>);
    //   return row.commIncreaseDesc;
    // }

    // let value = row.commission + "% / " + row.commissionMaxRate + "% / " + row.commissionMaxChangeRate + "%";
    // let desc = "Current Commission: " + row.commission + "%. <br/>";
    // desc += "Max Commission: " + row.commissionMaxRate + "%. <br/>";
    // desc += "Max daily commission change rate: " + row.commissionMaxChangeRate + "%.<br/>"
    // desc += "Click to learn more";
    // return (<span data-for="main" data-tip={desc} data-iscapture="true"><a>{value}</a></span>);
    // return (<span data-for="main" data-tip={desc} data-iscapture="true"><CommissionTip validator={row}/></span>);
    return (<CommissionTip validator={row} commissionPlusList={extraData} />);
  }

  static getCommission(cell, row, index) {
    // console.log(row);
    if (row.commIncreaseTime !== null) {
      // return (<span style={{"backgroundColor": "orange", "padding": "0.2rem"}}>{row.commIncreaseDesc}</span>);
      return row.commIncreaseDesc;
    }

    return cell + "%";
  }

  static getCommissionNote(cell, row, index) {
    // console.log(row);
    if (row.commIncreaseTime !== null) {
      // return (<span style={{"backgroundColor": "orange", "padding": "0.2rem"}}>{row.commIncreaseDesc}</span>);
      return " ("+ row.commIncreaseDesc + ")";
    }

    return "";
  }
  
  static commissionStyleFormatter(cell, row, index, extra) {
    if (!cell) return "";

    if (row.commIncreaseTime !== null) {
      return {"backgroundColor": "orange"};
      // return (<span style={{"backgroundColor": "orange", "padding": "0.2rem"}}>{row.commIncreaseDesc}</span>);
    }

    // if (row.commissionMaxRate > common.commission.maxCommission || 
    //   row.commissionMaxChangeRate > common.commission.maxChangeRate ) {
    //   // return {"background-image": "linear-gradient(to right, rgb(245, 167, 30), #ffffff)"};
    //   // new return {"background-image": "linear-gradient(90deg, rgba(242,236,33,1) 0%, rgba(2,0,36,1) 65%)"};
    //   return {"background-image": "linear-gradient(90deg, rgba(242,236,33,1) 0%, #0f1011 65%)"};
    // }
  }

  static getSmartStakeMsg(smartStakeInd) {
    // console.log("this.props.smartStake : ", this.props.smartStake);
    if (smartStakeInd !== null && smartStakeInd === false) {
      return (MainUtils.getSupportUs())
    }

    return "";
  }

  static convert2DigitsDecimal(cell, row) {
    if (!cell) {
      return cell;
    }

    let value = cell
    if (cell > 0) {
      value = (cell).toFixed(2).replace(/[.,]00$/, "");
    }

    return value
  }

  static stakeFormatter(cell, row, index, extra) {
    return <StakeFormatter name={row.name} address={row[currentNetwork.details.ui.identifierAttribute]}
      label="Delegate" isSS="false" />
  }

  static stakeFormatterLarge(address) {
    return "";
  }

  static consensusStyleFormatter(cell, row, index, extra) {
    if (!cell) return "";
    if (row.status === 'NotEligible') {
      return {"background-image": "linear-gradient(to right, rgb(245, 167, 30), #ffffff)"};
    } else if (row.status === 'Elected') {
      return {"background-image": "linear-gradient(to right, #00b4a0, #ffffff)"};
    } else {
      return {"background-image": "linear-gradient(to right, #00b4a0, #ffffff)"};
    }
  }

  static slotFormatter(cell, row, index, extra) {
    if (!cell) return "";

    return row.slot;
  }

  static intFormatter(cell, row) {
    if (!cell) return "";

    return parseInt(cell);
  }

  static calcRewardRatio(cell, row, index, extra) {
    if (!cell) return "";

    // console.log("getRewardRatio(row.stake: ", row.stake, ", row.reward: ", row.reward, ", extra: ", extra);
    return MainUtils.getRewardRatio(row.stake, row.reward, extra);
  }

  static getRewardRatio(stake, reward, totals) {
    if (stake === undefined || stake === 0) {
      // console.log("1");
      return "";
    } else if (reward === undefined || reward === 0) {
      // console.log("2");
      return 0;
    }

    if (totals.stake === 0 || totals.reward === 0) {
        // console.log("3");
        return "";
    }

    let percentStake = stake * 100 /totals.stake;
    let percentReward = reward * 100 /totals.reward;
    // console.log("percentStake: ", percentStake, ", percentReward: ", percentReward);

    if (percentReward === "" || percentReward === 0) {
      // console.log("4");
      return 0;
    }

    return numeral(percentReward/percentStake).format('0.00a');
  }

  static getBreadCrumb() {
    if (RespUtils.isTabletView()) {
      return (
        <Breadcrumb>
          <Breadcrumb.Item href="/">Validators</Breadcrumb.Item>
        </Breadcrumb>
      );
    } else {
      return "";
    }
  }

  static getShortText(text, length, alwaysShort) {
    if (RespUtils.isNotTabletView()) {
      if (!alwaysShort && alwaysShort === false) {
        return text;
      }
    }

    if (!text) {
      return "";
    }

    let label = text.split("?").join("");

    if (label.length > length) {
      label = label.substring(0, length - 2) + "..";
    }

    return label;
  }

  static getAddress(cell) {
    return (
      <React.Fragment>
        {MainUtils.getShortText(cell, 20)}
        {AddressUtils.copyLink(cell)}
      </React.Fragment>
      );
  }

  static getAddressLong(cell) {
    let length = RespUtils.isMobileView() ? 20 : 50;
    return (
      <React.Fragment>
        {MainUtils.getShortText(cell, length)}
        {AddressUtils.copyLink(cell)}
      </React.Fragment>
      );
  }

  static getBadges(cell, row) {
    // console.log(row)

    return <React.Fragment>
      {row.ibcRelayerInd === 'true' && MainUtils.getIBCRelayerLogo("medium")}
    </React.Fragment>;
  }

  static getIBCRelayerLogo(size) {
    let logoSize = size ? size : "large";
    return (<span title="Validator runs IBC Relayer" className="valBadge"> 
        <CompareArrowsIcon className="mInfoIcon" style={{fill: "white"}} fontSize={logoSize} />
      </span>)
  }

  static renderNewValidatorBadge(validator) {
    if (currentNetwork.details.app.enableIsNew === true && validator.isNew === "true") {
      return (<span className="cloud">New</span>);
    }

    return "";
  }

  static convertListToMap(listObj, attributeName) {
    var result = listObj.reduce(function(map, obj) {
        let attr = obj[attributeName];
        map[attr] = obj;
        return map;
    }, {});
    
    return result;
  }

  static formatBadges(cell, row) { 
    return MainUtils.formatBadgesLarge(row, false);
  }

  static formatBadgesLarge(row, largeInd) {
    // console.log(row.name, " - ", row.restake);
    let relayer = (currentNetwork.details.ui.badges.relayer === true && row.ibcRelayerInd === "true");
    let restakeInd = (currentNetwork.details.ui.badges.restake === true && row.restake === 'true');
    let restakeLink = "https://restake.app/" + currentNetwork.details.integrations.restake + "/" + row[currentNetwork.details.ui.identifierAttribute];
    let foundationNodeInd = (currentNetwork.details.ui.badges.foundationNodeInd === true && row.foundationNodeInd === "true");

    let yieldmosInd = (currentNetwork.details.ui.badges.yieldmosInd === true);
    let fdpInd = (currentNetwork.details.ui.badges.fdpInd === true && row.fdpInd === 1);

    let size = largeInd ? "large" : "small";
    // console.log(currentNetwork.details.ui.badges.badge2IndImg);
    return (
        <div>
            {MainUtils.renderImage(restakeInd, "/images/restake.png", restakeLink, "Validator supports restake.app for auto compounding. Click to use restake.app", largeInd, "valBadgeBorder", "_blank")}
            {relayer && <a href={SessionUtils.getUrl("/validators/ibc")}><span title="Validator runs IBC Relayer service" className="valBadge"> 
              <CompareArrowsIcon className="mInfoIcon" style={{fill: "white"}} fontSize={size}/>
            </span></a>}
            {MainUtils.renderImage(foundationNodeInd, currentNetwork.details.app.defaultLogo, null, currentNetwork.details.ui.badges.foundationNodeIndLabel, largeInd, "valBadgeBorderRound", null)}
            {MainUtils.formatLSMBadges(row.badge1Ind, row)}
            {MainUtils.renderImage(fdpInd, currentNetwork.details.app.defaultProjectLogo, SessionUtils.getUrl("/validators/fdp"), 
              "Validator receives foundation delegation", largeInd, "valBadgeBorderRound", "_self")}
        </div>
    );
  }

  static formatLSMBadges(cell, row) {
    let badge1Ind = (currentNetwork.details.ui.badges.badge1Ind === true && row.badge1Ind === "true");
    let badge2Ind = (currentNetwork.details.ui.badges.badge2Ind === true && row.badge2Ind === "true");
    let badge3Ind = (currentNetwork.details.ui.badges.badge3Ind === true && row.badge3Ind === "true");
    let badge4Ind = (currentNetwork.details.ui.badges.badge4Ind === true && row.badge4Ind === "true");
    let badge5Ind = (currentNetwork.details.ui.badges.badge5Ind === true && row.badge5Ind === "true");
    let largeInd = false;

    return (
      <React.Fragment>
          {MainUtils.renderImage(badge1Ind, currentNetwork.details.ui.badges.badge1IndImg, currentNetwork.details.ui.badges.badge1IndLink, 
            currentNetwork.details.ui.badges.badge1IndLabel, largeInd, "valBadgeBorderRound", "_blank")}
          {MainUtils.renderImage(badge2Ind, currentNetwork.details.ui.badges.badge2IndImg, currentNetwork.details.ui.badges.badge2IndLink, 
            currentNetwork.details.ui.badges.badge2IndLabel, largeInd, "valBadgeBorderRound", "_blank")}
          {MainUtils.renderImage(badge3Ind, currentNetwork.details.ui.badges.badge3IndImg, currentNetwork.details.ui.badges.badge3IndLink, 
            currentNetwork.details.ui.badges.badge3IndLabel, largeInd, "valBadgeBorderRound", "_blank")}
          {MainUtils.renderImage(badge4Ind, currentNetwork.details.ui.badges.badge4IndImg, currentNetwork.details.ui.badges.badge4IndLink, 
            currentNetwork.details.ui.badges.badge4IndLabel, largeInd, "valBadgeBorderRound", "_blank")}
          {MainUtils.renderImage(badge5Ind, currentNetwork.details.ui.badges.badge5IndImg, currentNetwork.details.ui.badges.badge5IndLink, 
            currentNetwork.details.ui.badges.badge5IndLabel, largeInd, "valBadgeBorderRound", "_blank")}
      </React.Fragment>
  );
}


  static renderImage(condition, imgSrc, imgLink, imgTitle, large, styleName, target) {
    let size = large ? "32px" : "24px";
    // valBadge mediaCustomImg
    if (condition) {
      if (imgLink) {
        return (<a href={imgLink} target={target} title={imgTitle} className="mediaCustom">
                <img className={styleName} width={size} height={size} src={imgSrc} />&nbsp;
               </a>);
      } else {
        return (<span title={imgTitle} className="mediaCustom">
                <img className={styleName} width={size} height={size} src={imgSrc} />&nbsp;
               </span>);
      }
    }

    return "";
  }

  static formatViewIcon(imgSrc, style) {
    if (!style) {
      style = "valBadgeBorderRound";
    }
    //  valBadge mediaCustomImg
    return <span><img className={style} width="32px" height="32px" src={imgSrc} /></span>
  }


  static networkNameFormatterTableWithLogo(cell, row, index, apps) {
    if (!cell) {
      return cell;
    }

    let app = apps[cell];
    if (!app) {
      return cell;
    }

    let label = MainUtils.getNameLabelShorter(app.title);

    const onClick = e => e.stopPropagation();
    return (<span onClick={ onClick }><a className="tourValName animLinkTable"
      href={app.website + "/valByIdentity/" + row.identity}>{LogoUtils.chainLogo(app.logoFileName, "logoStyleWithName")
      }&nbsp;&nbsp;{label}</a></span>);
  }

  static shareLink(webLink) {
    return (<CopyToClipboard text={constants.baseUrl + SessionUtils.getUrl(webLink)}
        onCopy={() => {}}>
        <p><a href="javascript:return false;" className="animLink">
            <i className="mediaCustom fas fa-solid fa-share" size="xs" /> Page shortcut</a></p>
      </CopyToClipboard>)
  }

}

export default MainUtils;
