import React from 'react';
import ReactTooltip from "react-tooltip";
import {Container} from 'react-bootstrap';

import ApiUtils from '../util/ApiUtils';

import SPUtilities from "../util/SPUtilities";
import ChartTitle from '../components/ChartTitle';
import PageHeader from '../components/PageHeader';
import TokenUtils from './TokenUtils';
import MultiLineChart from '../charts/MultiLineChart';
import SessionUtils from '../util/SessionUtils';

class TokenAddressHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "",
      token: "",
      addressHistory: [],
      width: window.innerWidth,
      size: 10,
      isLoading: true,
    }
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  updateDimensions() {
    this.setState({width: window.innerWidth});
  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  async componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    SessionUtils.setNetwork(this);

    var address = SPUtilities.getAddress(this);
    var token = TokenUtils.getToken(this);
    // , currentNetwork.details.ui.richlist.defaultToken
    let url = "listData?type=tokenAddressHistory&useNewToken=true&address=" + address + "&tokenCode=" + encodeURIComponent(token);
    // console.log(url);
    const allData = await ApiUtils.get(url);
    // console.log("allData is:", allData);

    if (allData) {
      let addressDetails = allData["address"];

      this.setState({"addressDetails": addressDetails, "addressHistory": allData["addressHistory"], 
        "address": address, "token": token, isLoading: false});
    }
  }

  render() {
    if (this.state.isLoading) {
      return <div>Loading</div>;
    }

    return (
      <div>
        <PageHeader title={this.state.token + " - Address History - " + this.state.address} thisObj={this} />
        <ReactTooltip id="main" place="top" type="dark" effect="float" multiline={true} />
        <p/>
        <Container fluid className="containerLayout container-fluid chartContainer">
          <div className="chartGridContainer">
            <div className="chartBg">
                {this.renderAddressSummary()}
            </div>
            <div className="chartBg" id="totalBalance">
              <MultiLineChart title="Address Balance" xAxis="Date" yAxis="Address Balance" adjustRange={true} 
                  showVerticalLabel={false} valueAttr={["totalBalance"]} rangeAttr="totalBalance" showTotalLabel={false} 
                  xAxisValueAttr="statDate" data={this.state.addressHistory} 
                  formatValues={true} fsElementId="totalBalance" />
            </div>
          </div>
        </Container>
      </div>
    );
  }

  renderAddressSummary() {
    return (
        <React.Fragment>
          <ChartTitle title="Address Summary" />
          <table>
            <tbody>
                <tr>
                    <td>Alias: </td>
                    <td> {this.state.addressDetails.alias}</td>
                </tr>
                <tr>
                    <td>Total Balance (${this.state.token}): </td>
                    <td> {SPUtilities.coinCountCellFormatter(this.state.addressDetails.totalBalance)}</td>
                </tr>
                <tr>
                    <td>Liquid Balance (${this.state.token}): </td>
                    <td> {SPUtilities.coinCountCellFormatter(this.state.addressDetails.addressBalance)}</td>
                </tr>
                <tr>
                    <td>Total Staked (${this.state.token}): </td>
                    <td> {SPUtilities.coinCountCellFormatter(this.state.addressDetails.totalStake)}</td>
                </tr>
            </tbody>
            </table>
        </React.Fragment>
      );
  }

}

export default TokenAddressHistory;
