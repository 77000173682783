import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';

import Stat from '../components/Stat';
import PageHeader from '../components/PageHeader';
import tooltips from '../tooltips';
import CoinUtils from '../util/CoinUtils';
import currentNetwork from '../currentNetwork';
import common from '../common';
import RespUtils from '../util/RespUtils';
import ValidatorUtils from '../validators/ValidatorUtils';
import MaticNetworkHeader from '../matic/MaticNetworkHeader';
import ChainUtils from '../util/ChainUtils';
import ProjectUIUtils from '../projects/ProjectUIUtils';
import StakeUtils from '../util/StakeUtils';
import ReactTooltip from 'react-tooltip';
import SPCalc from '../util/SPCalc';
import SessionUtils from '../util/SessionUtils';
import LogoUtils from '../util/LogoUtils';
import StatsUtils from '../stats/StatsUtils';

class NetworkHeader extends React.Component {
  constructor(props) {
    super(props);
    this.renderNetworkLinks = this.renderNetworkLinks.bind(this);
  }

  render() {
    let title = this.props.title;
    if (this.props.shortTitle && RespUtils.isMobileView()) {
      title = this.props.shortTitle;
    }

    if (this.props.titleOnly || this.props.skipStats) {
      return (<PageHeader title={currentNetwork.details.app.appName + " " + title} fullTitle={this.props.fullTitle} 
        thisObj={this.props.thisObj} customLogo={LogoUtils.getChainLogo} additionalLink={this.props.additionalLink} 
        showFavIcon={true} showRespIcon={true} beta={this.props.beta} additionalForTitle={this.renderNetworkLinks} 
        disableStakeFormatter={this.props.disableStakeFormatter} />);
    // } else if(!this.props.walletsOnly && !this.props.skipStats) {
    //   return this.renderFullStats(title);
    }
    
    return (
      <div>
        <ReactTooltip id="main" place="top" type="dark" effect="float" multiline={true} />
        {!this.props.skipHeader && (<PageHeader title={currentNetwork.details.app.appName + " " + title} fullTitle={this.props.fullTitle} 
          thisObj={this.props.thisObj} customLogo={LogoUtils.getChainLogo} 
          showFavIcon={true} showRespIcon={true} beta={this.props.beta} additionalForTitle={this.renderNetworkLinks} />)}
        {this.props.walletsOnly && this.props.walletsOnly === true && this.getWalletStats()}
        {this.getStats()}
      </div>
    );
  }

  renderNetworkLinks() {
    return (<div className="displayNetworkMedia">{ProjectUIUtils.formatNetworkMediaSmall(this.props.coinStat.twitter, this.props.coinStat)}</div>);
  }

  getStats() {
    return (
      <React.Fragment>
        <Container className="containerLayout" fluid>
          <Row className="statRowLayout">
            <Col className="statColLayout" xs={6} sm={4} md={3} lg={3}>
                <Stat label={StatsUtils.getTotalStakeLabel()} labelShort="Total Stake" value={CoinUtils.stakeFormatterRoundIfNeeded(this.props.coinStat.totalStake)} 
                    info={tooltips.netstats.totalStake} />
            </Col>
            {currentNetwork.details.ui.showApr === true && <Col className="statColLayout" xs={6} sm={4} md={3} lg={3}>
              <Stat label="Staking APR / APY (%)" value={ValidatorUtils.aprApyFormatter(this.props.coinStat.currentRewardRate, this.props.coinStat.weeklyCompoundingApy)} 
                      info={tooltips.netstats.stakingApr} />
            </Col>}
            {RespUtils.isNotMobileView() && currentNetwork.details.app.consumerChain != true && <Col className="statColLayout" xs={6} sm={4} md={3} lg={3}>
              <Stat label="Percent Staked" labelShort="" value={CoinUtils.percentFormatter(this.props.coinStat.percentStaked)} 
                info={tooltips.netstats.percentStaked} />
            </Col>}
            {StatsUtils.showUndelegationStat(true) && <Col className="statColLayout" xs={6} sm={4} md={3} lg={3}>
              <Stat label="Pending Undelegations (1/7 days/ all)" labelShort="" value={
                CoinUtils.stakeFormatterRounded(this.props.coinStat.undelAmt24Hrs) + "/" + CoinUtils.stakeFormatterRounded(this.props.coinStat.undelAmt7Days) + "/" + CoinUtils.stakeFormatterRounded(this.props.coinStat.totalUndelegationAmount)} 
                info={tooltips.netstats.pendingUndelegations} />
            </Col>}
            {ChainUtils.isMatic() && <MaticNetworkHeader coinStat={this.props.coinStat} />}
            {this.props.full === true && this.renderNetworkExtra()}
          </Row>
        </Container>
      </React.Fragment>
    );
  }

  renderNetworkExtra() {
    return (
      <React.Fragment>
        {StatsUtils.showDelegationStat() && <Col className="statColLayout" xs={6} sm={4} md={3} lg={3}>
          <Stat label="Unbonding Time" value={SPCalc.formatDaysLeft(this.props.params.unbondingTime)} 
                  info={tooltips.netstats.unbondingTime} />
        </Col>}
        <Col className="statColLayout" xs={6} sm={4} md={3} lg={3}>
          <Stat label="Block Rate" value={this.props.coinStat.blockRate + " seconds"} 
                  info={tooltips.netstats.blockRate} />
        </Col>
        <Col className="statColLayout" xs={6} sm={4} md={3} lg={3}>
          <Stat label="Total Supply" value={CoinUtils.stakeFormatterRoundIfNeeded(this.props.coinStat.totalSupply)}  
                  info={tooltips.netstats.totalSupply} />
        </Col>
        <Col className="statColLayout" xs={6} sm={4} md={3} lg={3}>
          <Stat label="Gini Coefficient" labelShort="" value={this.props.coinStat.gini} link={common.urls.giniExplanation}
                target="_blank" info={tooltips.netstats.gini} />
        </Col>
        {currentNetwork.details.app.tendermint === true && <Col className="statColLayout" xs={6} sm={4} md={3} lg={3}>
          <Stat label="Active Addresses (>0 balance)" labelShort="Active Addresses" value={this.props.coinStat.activeAccounts + " (Richlist)"} 
                  link={SessionUtils.getUrl("/stats/addresses")} info={tooltips.netstats.activeAddresses} />
        </Col>}
        {StatsUtils.showDelegationStat() && <Col className="statColLayout" xs={6} sm={4} md={3} lg={3}>
          <Stat label="Unique Delegates" labelShort="" value={this.props.coinStat.uniqueDelegates + " (Richlist)"} 
                  link={SessionUtils.getUrl("/stats/delegates")} info={tooltips.netstats.uniqueDelegates} />
        </Col>}
        <Col className="statColLayout" xs={6} sm={4} md={3} lg={3}>
          <Stat label="Val below Avg Voting Power" labelShort="" value={this.props.coinStat.valBelowAvgPower} 
                  info={tooltips.netstats.valBelowAvg.replace("$value1", this.props.coinStat.avgVotingPower)} />
        </Col>
        <Col className="statColLayout" xs={6} sm={4} md={3} lg={3}>
          <Stat label="Last Active Validator Stake" labelShort="Last Val Stake" 
            value={this.props.coinStat.lastValStake + " $" + currentNetwork.details.app.token + 
              (this.props.coinStat.lastValStakeUsd != null ? ("/ $" + this.props.coinStat.lastValStakeUsd) : "")} 
            info={tooltips.netstats.medianCommission} />
        </Col>
        <Col className="statColLayout" xs={6} sm={4} md={3} lg={3}>
          <Stat label="Median/Weighted Median Commission" labelShort="" value={this.props.coinStat.medianFee + "/" + this.props.coinStat.weightedMedianCommission} 
            info={tooltips.netstats.medianCommission} />
        </Col>
        {this.props.coinStat.circulatingSupply != null && this.props.coinStat.circulatingSupply != 0 && <Col className="statColLayout" xs={6} sm={4} md={3} lg={3}>
          <Stat label="Circulating Supply" value={CoinUtils.stakeFormatterRoundIfNeeded(this.props.coinStat.circulatingSupply)}  
                  info={tooltips.netstats.circulatingSupply} />
        </Col>}
        <Col className="statColLayout" xs={6} sm={4} md={3} lg={3}>
          <Stat label="Nakamoto Index" value={this.props.coinStat.nakamotoIndex}  link={common.urls.nakamotoIndex}
            info={tooltips.netstats.nakamotoIndex} />
        </Col>
        <Col className="statColLayout" xs={6} sm={4} md={3} lg={3}>
          <Stat label="#1 VP Equals N Bottom" labelShort="#1 Equals N Bottom" value={this.props.coinStat.oneEqualsNBottomVal} 
            info={tooltips.netstats.oneEqualsNBottomVal} />
        </Col>
        {StatsUtils.showUndelegationStat(false) && <Col className="statColLayout" xs={6} sm={4} md={3} lg={3}>
          <Stat label="Pending Undelegations Count" labelShort="" value={this.props.coinStat.uniqueUndelegations} 
            info={tooltips.netstats.uniqueUndelegations} />
        </Col>}
        {StatsUtils.showDelegationStat() && <Col className="statColLayout" xs={6} sm={4} md={3} lg={3}>
          <Stat label="Average Delegation" value={StakeUtils.formatAverageDelegation(this.props.coinStat.averageDelegation, this.props.coinStat)} 
            info={tooltips.netstats.averageDelegation} />
        </Col>}
        {this.props.coinStat.activeAddress24h != null && (<Col className="statColLayout" xs={6} sm={4} md={3} lg={3}>
          <Stat label="24h Active Addresses" value={this.props.coinStat.activeAddress24h} 
            info={tooltips.netstats.activeAddress24h} />
        </Col>)}
        {/* {this.props.coinStat.txCount24h != null && (<Col className="statColLayout" xs={6} sm={4} md={3} lg={3}>
          <Stat label="24h/7d Transactions" labelShort="Txs - 24h/7d" 
            value={this.props.coinStat.txCount24h + "/" + this.props.coinStat.txCount7d} 
            info={tooltips.netstats.txCount24h} />
        </Col>)} */}
        {this.props.coinStat.successfulTxs != null && (<Col className="statColLayout" xs={6} sm={4} md={3} lg={3}>
          <Stat label="Successful/Failed Transactions" labelShort="Successful/Failed Txs" 
            value={this.props.coinStat.successfulTxs + "/" + this.props.coinStat.failedTxs} 
            info={tooltips.netstats.successfulFailedTransactions} />
        </Col>)}
        {this.props.coinStat.unbondedStake != null && this.props.coinStat.unbondedStake != 0 && (
          <Col className="statColLayout" xs={6} sm={4} md={3} lg={3}>
            <Stat label="Inactive Validator Stake" 
              value={StakeUtils.formatInactiveValStake(this.props.coinStat.unbondedStake, this.props.coinStat)} 
              info={tooltips.netstats.unbondedStake} />
          </Col>)}
      </React.Fragment>
    );
  }

  getWalletStats() {
    return (
      <Container className="containerLayout" fluid>
        <Row className="statRowLayout">
          {currentNetwork.details.app.tendermint === true && <Col className="statColLayout" xs={6} sm={4} md={3} lg={3}>
            <Stat label="Active Addresses (>0 balance)" labelShort="Active Addresses" value={this.state.coinStat.activeAccounts + " (Richlist)"} 
                    link={SessionUtils.getUrl("/stats/addresses")} info={tooltips.netstats.activeAddresses} />
          </Col>}
          {this.state.coinStat.uniqueDelegates != null && this.state.coinStat.uniqueDelegates > 0 && <Col className="statColLayout" xs={6} sm={4} md={3} lg={3}>
            <Stat label="Unique Delegates" labelShort="" value={this.state.coinStat.uniqueDelegates + " (Richlist)"} 
                    link={SessionUtils.getUrl("/stats/delegates")} info={tooltips.netstats.uniqueDelegates} />
          </Col>}
          <Col className="statColLayout" xs={6} sm={4} md={3} lg={3}>
            <Stat label="Average Delegation" value={StakeUtils.formatAverageDelegation(this.state.coinStat.averageDelegation, this.state.coinStat)} 
              info={tooltips.netstats.oneEqualsNBottomVal} />
          </Col>
        </Row>
      </Container>  
    );
  }

}

export default NetworkHeader;
