import React from 'react';
import currentNetwork from '../currentNetwork';
import {Container, Row, Col} from 'react-bootstrap';
import common from '../common';
import RespUtils from './RespUtils';


class IntegrationUtils extends React.Component {
    
    static renderExtraLink1(smallInd, showLabel) {
        if (!currentNetwork.details.integrations.extraLink1) return "";
        
        let url = currentNetwork.details.integrations.extraLink1;
        return IntegrationUtils.renderTool(currentNetwork.details.integrations.extraLink1Label, url, 
            currentNetwork.details.integrations.extraLink1Logo, smallInd, showLabel);
    }

    static renderMainStakingLink(address, smallInd, showLabel) {
        if (!currentNetwork.details.integrations.mainStaking) return "";
        
        let url = currentNetwork.details.integrations.mainStaking;
        if (address) {
            let suffix = currentNetwork.details.integrations.mainStakingSuffix;
            if (suffix) {
                if (suffix != "ignoreAddress") {
                    url += suffix + address;
                }
            } else {
                url += "/validator/" + address;
            }
        }
        return IntegrationUtils.renderTool(currentNetwork.details.integrations.mainStakingLabel, url, 
            currentNetwork.details.app.defaultProjectLogo, smallInd, showLabel);
    }


    static renderExplorerMintscan(address, smallInd, showLabel) {
        if (!currentNetwork.details.integrations.mintscan) return "";

        let url = IntegrationUtils.getMintscanHome();
        if (address) {
            url += "/validators/" + address;
        }
        // return (<a className="animLinkVisual" target="_blank" href={url}
        //     >{LogoUtils.logoByUrl(common.integrations.logo.mintscan, true)}</a>);
        return IntegrationUtils.renderTool("Mintscan", url, common.integrations.logo.mintscan, smallInd, showLabel);
    }

    static renderStakeKeplr(address, smallInd, showLabel) {
        if (!currentNetwork.details.integrations.keplr) return "";
        
        let url = IntegrationUtils.getKeplrHome();
        if (address) {
            url += "?modal=validator&chain=" + currentNetwork.details.integrations.chainId + 
                    "&validator_address=" + address;
        }
        return IntegrationUtils.renderTool("Keplr", url, common.integrations.logo.keplr, smallInd, showLabel);
        // return (<a className="animLinkVisual" target="_blank" href={url}
        //     >{LogoUtils.logoByUrl(common.integrations.logo.keplr, true)}</a>);
    }

    static renderStakeStation(address, smallInd, showLabel) {
        if (!currentNetwork.details.integrations.station) return "";
        
        let url = IntegrationUtils.getStationHome();
        if (address) {
            url += "validator/" + address;
        }
        return IntegrationUtils.renderTool("Station", url, common.integrations.logo.station, smallInd, showLabel);
    }

    static renderStakeLeap(address, smallInd, showLabel) {
        if (!currentNetwork.details.integrations.leap) return "";
        
        let url = IntegrationUtils.getLeapHome();
        if (address) {
            url += "&validator=" + address;
        }
        return IntegrationUtils.renderTool("Leap", url, common.integrations.logo.leap, smallInd, showLabel);
    }

    static renderUptimePingPub(smallInd, showLabel) {
        if (!currentNetwork.details.integrations.pingpub) return "";

        let url = IntegrationUtils.getPingpubHome() + "/uptime";
        return IntegrationUtils.renderTool("Pingpub", url, common.integrations.logo.pingpub, smallInd, showLabel);
    }

    static renderPolkachu(smallInd, showLabel) {
        if (!currentNetwork.details.integrations.polkachu) return "";

        let url = IntegrationUtils.getPolkachuHome();
        return IntegrationUtils.renderTool("Polkachu", url, common.integrations.logo.polkachu, smallInd, showLabel);
    }

    static renderUptimeKJNodes(smallInd, showLabel) {
        if (!currentNetwork.details.integrations.kjnodes) return "";

        let url = IntegrationUtils.getKjnodesHome() + "/uptime";
        return IntegrationUtils.renderTool("Kjnodes", url, common.integrations.logo.kjnodes, smallInd, showLabel);
    }

    static renderUptimeNodeStake(smallInd, showLabel) {
        if (!currentNetwork.details.integrations.nodestake) return "";

        let url = IntegrationUtils.getNodestakeHome() + "/uptime";
        return IntegrationUtils.renderTool("Nodestake", url, common.integrations.logo.nodestake, smallInd, showLabel);
    }

    static renderGecko(coinStat, smallInd, showLabel) {
        if (!coinStat || !coinStat.geckoId) return "";

        let url = "https://www.coingecko.com/en/coins/" + coinStat.geckoId;
        return IntegrationUtils.renderTool("CoinGecko", url, common.integrations.logo.coingecko, smallInd, showLabel);
    }

    static renderGecko4Label(label, geckoId, smallInd) {
        if (!label || !geckoId) return "";

        let url = "https://www.coingecko.com/en/coins/" + geckoId;
        return <a className="mediaCustomImg animLinkVisual" target="_blank" href={url}>{label}</a>
    }

    static renderGecko4Token(coinStat, smallInd) {
        if (!coinStat || !coinStat.geckoId) return "";

        let url = "https://www.coingecko.com/en/coins/" + coinStat.geckoId;
        return <a className="mediaCustomImg animLinkVisual" target="_blank" href={url} title="CoinGecko"
                >{IntegrationUtils.logoBySizeStyle(common.integrations.logo.coingecko, smallInd ? 16 : 32, "logoStyleCenter")}</a>
    }

    static renderStakeRestake(address, smallInd, showLabel) {
        if (!currentNetwork.details.integrations.restake) return "";

        let url = IntegrationUtils.getRestakeHome();
        if (address) {
            url += "/" + address;
        }

        if (!smallInd && !RespUtils.isMobileView()) {
            smallInd = true;
        }

        let label = "";
        if (showLabel) {
            label = "Restake";
        }

        return IntegrationUtils.renderToolByStyle(label, url, common.integrations.logo.restake, smallInd, showLabel, "valBadgeBorder");
        // return (<a className="mediaCustom animLinkVisual" target="_blank" href={url} title="Restake"
        //     >{IntegrationUtils.logoByUrl(common.integrations.logo.restake, largeInd, "valBadgeBorder")}{label}</a>);
        // return IntegrationUtils.renderTool(url, common.integrations.logo.restake);
    }

    static getStakingHome(address) {
        if (currentNetwork.details.integrations.keplr) {
            let url = IntegrationUtils.getKeplrHome() + "?modal=validator&chain=" + 
                currentNetwork.details.integrations.chainId + "&validator_address=" + address;
            return url;
        } else if (currentNetwork.details.integrations.pingpub) {
            return IntegrationUtils.getPingpubHome() + "/staking" + address;
        } else if (currentNetwork.details.integrations.polkachu) {
            return IntegrationUtils.getPolkachuHome() + "/staking" + address;
        } else if (currentNetwork.details.integrations.kjnodes) {
            return IntegrationUtils.getKjnodesHome() + "/staking" + address;
        } else if (currentNetwork.details.integrations.nodestake) {
            return IntegrationUtils.getNodestakeHome() + "/staking" + address;
        } 

        return currentNetwork.details.integrations.stakingHomeForValidator + address;
    }

    static renderTool(title, url, logoPath, smallInd, showLabel) {
        return IntegrationUtils.renderToolByStyle(title, url, logoPath, smallInd, showLabel)
    }

    static renderToolByStyle(title, url, logoPath, smallInd, showLabel, styleName) {
        if (!smallInd && !RespUtils.isMobileView()) {
            smallInd = true;
        }

        let label = "";
        if (showLabel) {
            label = title;
            return (<Col className="statColLayout" xs={4} sm={4} md={2} lg={2}>
                    <a className="mediaCustomImg animLinkVisual" target="_blank" href={url} title={title}
                        >{IntegrationUtils.logoByUrl(logoPath, smallInd, styleName)}</a>
                    <a className="mediaCustomImg animLinkVisual" target="_blank" href={url} title={title}
                        >{label}</a>
                </Col>);
        } else {
            return (<a className="mediaCustomImg animLinkVisual" target="_blank" href={url} title={title}
                >{IntegrationUtils.logoByUrl(logoPath, smallInd, styleName)}{label}</a>);
        }
    }

    static logoByUrl(logoUrl, smallInd, styleName) {
        let size = smallInd ? 24 : 32;
        let imagePath = logoUrl;
    
        if (!styleName) styleName = "logoStyle"
    
        return (<img onError={this.addDefaultSrc} className={styleName}
          width={size} height={size} src={imagePath}/>);
    }
    
    static logoBySizeStyle(logoUrl, size, styleName) {
        let imagePath = logoUrl;
    
        if (!styleName) styleName = "logoStyle"
    
        return (<img onError={this.addDefaultSrc} className={styleName}
          width={size} height={size} src={imagePath}/>);
    }
    
    static getProposalData() {
        let label = currentNetwork.details.integrations.proposalHomeLabel;
        let proposalHome = currentNetwork.details.integrations.proposalHome;
        let homeDetails = IntegrationUtils.getHomeLinkByName(proposalHome, label);
        // console.log(homeDetails);
    
        if (!homeDetails) {
            return null;
        }

        if (homeDetails.useAsIs) {
            return {"link": homeDetails.link, "label": homeDetails.label};
        }

        let govLink = homeDetails.link + "/" + homeDetails.gov;

        return {"link": govLink, "label": homeDetails.label};
    }

    static getHomeLinkByName(key, label) {
        // console.log("in getHomeLinkByName: key: ", key, ", label: ", label);
        let home = {link: key, label: label, gov: "", useAsIs: true};

        if (key === "keplr") {
            //no support for address
            home = {link: IntegrationUtils.getKeplrHome(), label: "Keplr", gov: "proposals/", address: "", useAsIs: false}
        } else if (key === "leap") {
            // leap resource name conventions are wrong
            home = null;
        } else if (key === "pingpub") {
            home = {link: IntegrationUtils.getPingpubHome(), label: "Pingpub", gov: "gov/", address: "account/", useAsIs: false}
        } else if (key === "polkachu") {
            home = {link: IntegrationUtils.getPolkachubHome(), label: "Polkachu", gov: "gov/", address: "account/", useAsIs: false}
        } else if (key === "kjnodes") {
            home = {link: IntegrationUtils.getKjnodesHome(), label: "Kjnodes", gov: "gov/", address: "account/", useAsIs: false}
        } else if (key === "nodestake") {
            home = {link: IntegrationUtils.getNodestakeHome(), label: "Nodestake", gov: "gov/", address: "account/", useAsIs: false}
        } else if (key === "mintscan") {
            home = {link: IntegrationUtils.getMintscanHome(), label: "Mintscan", gov: "proposals/", address: "address/", useAsIs: false}
        } else if (!key) {
            home = null;
        }

        return home;
    }

    static getStationHome() {
        return common.integrations.station;
    }

    static getKeplrHome() {
        return common.integrations.keplr + currentNetwork.details.integrations.keplr;
    }

    static getLeapHome() {
        return common.integrations.leap + currentNetwork.details.integrations.leap;
    }

    static getMintscanHome() {
        return common.integrations.mintscan + currentNetwork.details.integrations.mintscan;
    }

    static getPingpubHome() {
        return common.integrations.pingpub + currentNetwork.details.integrations.pingpub;
    }

    static getPolkachuHome() {
        return common.integrations.polkachu + currentNetwork.details.integrations.polkachu;
    }

    static getKjnodesHome() {
        return common.integrations.kjnodes + currentNetwork.details.integrations.kjnodes;
    }

    static getNodestakeHome() {
        return common.integrations.nodestake + currentNetwork.details.integrations.nodestake;
    }

    static getRestakeHome() {
        return common.integrations.restake + currentNetwork.details.integrations.restake;
    }

    static getAddressLink() {
        let addressKey = currentNetwork.details.integrations.addressLink;
        let homeDetails = IntegrationUtils.getHomeLinkByName(addressKey, null);
        // console.log(addressKey);
        // console.log(homeDetails.link + "/" + homeDetails.address);

        if (!homeDetails) {
            return null;
        }

        if (homeDetails.useAsIs) {
            return homeDetails.link;
        }

        return homeDetails.link + "/" + homeDetails.address;
    }

    static renderIntegrations(coinStat, operatorAddress) {
        return <React.Fragment>&nbsp;
            {IntegrationUtils.renderMainStakingLink(operatorAddress, true)} 
            {IntegrationUtils.renderStakeKeplr(operatorAddress, true)} 
            {IntegrationUtils.renderStakeLeap(operatorAddress, true)} 
            {IntegrationUtils.renderStakeStation(operatorAddress, true)} 
            {IntegrationUtils.renderStakeRestake(operatorAddress, true)} 
            {IntegrationUtils.renderUptimePingPub(true)} 
            {IntegrationUtils.renderPolkachu(true)} 
            {IntegrationUtils.renderUptimeKJNodes(true)}
            {IntegrationUtils.renderUptimeNodeStake(true)} 
            {IntegrationUtils.renderExplorerMintscan(operatorAddress, true)} 
            {IntegrationUtils.renderExtraLink1(true)}
            {IntegrationUtils.renderGecko(coinStat, true)}
          </React.Fragment>        
    }

    static renderStakeIntegrations(cell, row) {
        let restakeInd = (currentNetwork.details.ui.badges.restake === true && row.restake === 'true');

        return <React.Fragment>
          {IntegrationUtils.renderMainStakingLink(row.operatorAddress, true)} 
          {IntegrationUtils.renderStakeKeplr(row.operatorAddress, true)} 
          {IntegrationUtils.renderStakeLeap(row.operatorAddress, true)} 
          {IntegrationUtils.renderStakeStation(row.operatorAddress, true)} 
          {restakeInd && IntegrationUtils.renderStakeRestake(row.operatorAddress, true)} 
        </React.Fragment>
    }

    static renderAutoCompoundOptions(operatorAddress) {
        return <React.Fragment>
          {IntegrationUtils.renderStakeRestake(operatorAddress, true)} 
        </React.Fragment>        
    }

    static renderDetailedIntegrations(operatorAddress) {
        return <React.Fragment>
            {IntegrationUtils.renderMainStakingLink(operatorAddress, true, true)} 
            {IntegrationUtils.renderStakeKeplr(operatorAddress, true, true)}
            {IntegrationUtils.renderStakeLeap(operatorAddress, true, true)}
            {IntegrationUtils.renderStakeStation(operatorAddress, true, true)}
            {IntegrationUtils.renderStakeRestake(operatorAddress, true, true)}
            {IntegrationUtils.renderUptimePingPub(true, true)}
            {IntegrationUtils.renderPolkachu(true, true)}
            {IntegrationUtils.renderUptimeKJNodes(true, true)}
            {IntegrationUtils.renderUptimeNodeStake(true, true)} 
            {IntegrationUtils.renderExplorerMintscan(operatorAddress, true, true)} 
            {IntegrationUtils.renderExtraLink1(true, true)}             
        </React.Fragment>
    }

    static renderDetailedIntegrations4Address(address) {
        return <Container className="containerLayout">
                    <Row className="statRowLayout">
                        {IntegrationUtils.renderPingPubAddress(address)} 
                        {IntegrationUtils.renderKJNodesAddress(address)}
                        {IntegrationUtils.renderNodeStakeAddress(address)}
                        {IntegrationUtils.renderMintscanAddress(address)} 
                    </Row>
                </Container>
    }

    static renderPingPubAddress(address) {
        if (!currentNetwork.details.integrations.pingpub) return "";

        let url = IntegrationUtils.getPingpubHome() + "/account/" + address;
        return IntegrationUtils.renderTool("Pingpub", url, common.integrations.logo.pingpub, false, true);
    }

    static renderKJNodesAddress(address) {
        if (!currentNetwork.details.integrations.kjnodes) return "";

        let url = IntegrationUtils.getKjnodesHome() + "/account/" + address;
        return IntegrationUtils.renderTool("Kjnodes", url, common.integrations.logo.kjnodes, false, true);
    }

    static renderNodeStakeAddress(address) {
        if (!currentNetwork.details.integrations.nodestake) return "";

        let url = IntegrationUtils.getNodestakeHome() + "/account/" + address;
        return IntegrationUtils.renderTool("Nodestake", url, common.integrations.logo.nodestake, false, true);
    }

    static renderMintscanAddress(address) {
        if (!currentNetwork.details.integrations.mintscan) return "";

        let url = IntegrationUtils.getMintscanHome() + "/address/" + address;
        return IntegrationUtils.renderTool("Mintscan", url, common.integrations.logo.mintscan, false, true);
    }

    static getTxLink(txHash) {
        if (currentNetwork.details.integrations.mintscan) {
            return IntegrationUtils.getMintscanHome() + "/tx/" + txHash;
        } else if (currentNetwork.details.integrations.pingpub) {
            return IntegrationUtils.getPingpubHome() + "/tx/" + txHash;
        } else if (currentNetwork.details.integrations.polkachu) {
            return IntegrationUtils.getPolkachuHome() + "/tx/" + txHash;
        } else if (currentNetwork.details.integrations.kjnodes) {
            return IntegrationUtils.getKjnodesHome() + "/tx/" + txHash;
        } else if (currentNetwork.details.integrations.nodestake) {
            return IntegrationUtils.getNodestakeHome() + "/tx/" + txHash;
        } 

        return "#";
    }
}

export default IntegrationUtils;
