import React from "react";
import { Treemap, ResponsiveContainer, Tooltip } from 'recharts';

import UIUtils from '../util/UIUtils';
import Utilities from '../util/Utilities';
import ChartUtils from '../util/ChartUtils';
import ChartTitle from "../components/ChartTitle";
import "./BaseTreeMap.css";
import common from "../common";
import currentNetwork from "../currentNetwork";
import ChartData from './ChartData';

class BaseTreeMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      responsive: true,
      mode: common.charts.mode.default
    }

    this.flipResponsive = this.flipResponsive.bind(this);
    this.switchMode = this.switchMode.bind(this);
  }

  flipResponsive() {
    ChartUtils.flipResponsive(this);
  }

  renderTreemap() {
    // let textColor = common.colors.text;//"white";
    let lineColor = common.colors.chartColor1;
    if (this.props.color && this.props.color !== null) {
      lineColor = this.props.color;
    }

    let width = UIUtils.getResponsiveWidth(this);
    // <Label value={this.props.xAxis} offset={-8} position="insideBottom"/>
    let height = UIUtils.getFSChartHeight(this);
    // let showDataLabel = UIUtils.getShowDataLabel(this, this.props.data, 600);

    // console.log("height is: " + height);
    // domain={['auto', 'auto']}7773ba

    let allData = [];
    for (let i = 0; i < this.props.data.length; i++) {
      let record = this.props.data[i];
      allData.push({name: record.name, size: record.votingPower, uniqueDelegates: record.uniqueDelegates});
    }

    // let colors = ['#3B93A5', '#F7B844', '#ADD8C7', '#EC3C65', '#CDD7B6', '#C1F666', '#D43F97', '#1E5D8C', '#421243', '#7F94B0', '#EF6537', '#C0ADDB'];

    const CustomTooltip = ({ active, payload, label }) => {
        // console.log(active, "-", payload, "-", label);
        if (active) {
            if (payload === null || payload.length === 0) {
            return null;
            }

            let data = payload[0].payload;

            return (<div>
                <table><tbody>
                        <tr key="customTooltipHeader">
                            <th colSpan={2}>{data.name}</th>
                        </tr>
                        <tr key="customTooltipVP">
                            <th>Voting Power</th>
                            <th>{data.size}</th>
                        </tr>
                        <tr key="customTooltipUD">
                            <th>Unique Delegates</th>
                            <th>{data.uniqueDelegates}</th>
                        </tr>
                </tbody></table>
            </div>);
        }

        return null;
    };
  
    let widthInt = parseInt(width);
    return (
      <div className="treemapParent">
        <ResponsiveContainer width={width} height={height}>
            <Treemap width={widthInt} height={height} data={allData} dataKey="size" ratio={16 / 9} stroke="#fff" fill="#856a00">
                <Tooltip content={<CustomTooltip />} />
            </Treemap>
        </ResponsiveContainer>
      </div>
    );
  }

  render () {
    let tableInd = this.state.mode === common.charts.mode.table;
    let chartVisualId = currentNetwork.details.networkName + "-" + (tableInd ? "" : "data") + this.props.chartVisualId;
    const downloadChart = () => {
      ChartUtils.downloadChart(document, chartVisualId, currentNetwork.details.app.title);
    };

    return (
      <div id={"div" + chartVisualId}>
        <ChartTitle title={this.props.title} tip={this.props.tip} fsElementId={this.props.fsElementId} 
          saveChart={downloadChart} enableSave={this.props.enableSave} 
          mode={this.state.mode} switchMode={this.switchMode} columns={this.props.columns} 
          />
        <div align="center">
          {this.props.showTotalLabel ? Utilities.getTotalWithLabel(this.props.data, this.props.valueAttr, this.props.totalLabel) : ""}
          {tableInd ? <ChartData data={this.props.data} columns={this.props.columns} dataKey={this.props.dataKey} sortBy={this.props.sortBy} /> : this.renderTreemap()}
        </div>
      </div>
    );
  }

  switchMode() {
    let currentMode = this.state.mode;
    if (currentMode === common.charts.mode.chart) {
      this.setState({mode: common.charts.mode.table});
    } else {
      this.setState({mode: common.charts.mode.chart});
    }
  }
}

export default BaseTreeMap;
