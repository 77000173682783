import React from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ReactTooltip from "react-tooltip";
import {Container} from 'react-bootstrap';
import ReactJson from 'react-json-view';
// import Watermark from '@uiw/react-watermark';

import BaseBarChart from '../charts/BaseBarChart';
import ApiUtils from '../util/ApiUtils';
import '../styles/section.css';
import './Proposal.css';
import UIUtils from '../util/UIUtils';
import PageHeader from '../components/PageHeader';
import ProposalUtils from './ProposalUtils';
import ChartTitle from '../components/ChartTitle';
import tooltips from '../tooltips';
import Loading from '../components/Loading';
import SPUtilities from '../util/SPUtilities';
import currentNetwork from '../currentNetwork';
import Utilities from '../util/Utilities';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BreadcrumbUtils from '../util/BreadcrumbUtils';
import RespUtils from '../util/RespUtils';
import CoinUtils from '../util/CoinUtils';
import SessionUtils from '../util/SessionUtils';
import common from '../common';
import IntegrationUtils from '../util/IntegrationUtils';
import BaseMultiBarChart from '../charts/BaseMultiBarChart';
import ChartUtils from '../util/ChartUtils';

const Veto = "Veto";
const Yes = "Yes";
const No = "No";
const ALL = "All";
const Abstain = "Abstain";
const Validators = "Validators";
const MissingVotes = "MissingVotes";

class Proposal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        proposal: {},
        votes: [],
        statusMap: {},
        proposalTypeMap: {},
        voteOptionMap: {},
        voteStatus: ALL,
        notification: {},
        voterDistribution: [],
        compVoterDistribution: [],
        compProposalId: "",
        minStake: 0,
        minLabel: "",
        width: window.innerWidth,
        size: 10,
        responsive: true,
        isLoading: true,
    }
    this.updateDimensions = this.updateDimensions.bind(this);
    this.filterVotes = this.filterVotes.bind(this);

    this.showAllVotes = this.showAllVotes.bind(this);
    this.showValidatorVotes = this.showValidatorVotes.bind(this);
    this.showYesVotes = this.showYesVotes.bind(this);
    this.showAbstainVotes = this.showAbstainVotes.bind(this);
    this.showNoVotes = this.showNoVotes.bind(this);
    this.showVetoVotes = this.showVetoVotes.bind(this);
    this.showMissingVotes = this.showMissingVotes.bind(this);
  }

  updateDimensions() {
    this.setState({width: window.innerWidth});
  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  async componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    SessionUtils.setNetwork(this);

    let url = "proposals"
    if (this.props.match && this.props.match.params.proposalId) {
        url += "?proposalId=" + this.props.match.params.proposalId;
    }

    let minStake = this.state.minStake;
    if (this.props.match && this.props.match.params.minStake) {
      minStake = this.props.match.params.minStake;
    }

    if (minStake) {
      url += "&minStake=" + minStake;
      
      let minLabel = " (min stake " + CoinUtils.roundMultipleOf10(minStake) + " $" + currentNetwork.details.app.token + ")";
      this.setState({"minStake": minStake, "minLabel": minLabel});
    }

    const allData = await ApiUtils.get(url);
    if (!allData) {
      this.setState({error: true, isLoading: false});
      return;
    }

    let proposal = allData["proposal"];

    let votes = allData["votes"];
    let orgVotes = JSON.parse(JSON.stringify(votes));
    // let voteOptionMap = {All: 0, Yes: 0, No: 0, Abstain: 0, Veto: 0};

    // for(let i=0; i < votes.length; i++) {
    //   let vote = votes[i];
    //   let voteOption = vote["voteOption"];

    //   if (voteOption !== null) {
    //     let key = common.proposal.vote[voteOption];
    //     voteOptionMap[key] = voteOptionMap[key] + 1;
    //     voteOptionMap[ALL] = voteOptionMap[ALL] + 1;
    //   }
    // }
    
    this.setState({isLoading: false, "proposal": proposal, "statusMap": allData["statusMap"], 
      "proposalTypeMap": allData["proposalType"], 
      "notification": allData["notification"], "votes": allData["votes"], 
      "orgVotes": orgVotes, "voteOptionMap": allData["voteOptionMap"], "compProposalId": allData["compProposalId"],
      "voterDistribution": allData["voterDistribution"], "compVoterDistribution": allData["compVoterDistribution"]});
  }

  render() {
    if (this.state.isLoading) {
      return <Loading />;
    }

    let descParts = this.state.proposal.description.replaceAll("\\n", " ");
    // console.log(descParts);
    descParts = descParts.split(" "); // re is a matching regular expression

    let voterDistColumns = [
      {text: "Amount", dataField: "title", sort: true, },
      {text: "# of Voters", dataField: "voterCount", sort: true, },
      {text: "Percentage Voters", dataField: "voterPercent", sort: true, },
    ];

    let voterCompColumns = [
      {text: "Amount", dataField: "title", sort: true, },
      {text: ("# of Voters in " + this.state.proposal.proposalId), dataField: "voterCount", sort: true, },
      {text: ("# of Voters in " + this.state.compProposalId), dataField: "comparisonVoterCount", sort: true, },
      {text: ("% of Voters in " + this.state.proposal.proposalId), dataField: "voterPercent", sort: true, },
      {text: ("% of Voters in " + this.state.compProposalId), dataField: "comparisonVoterPercent", sort: true, },
    ]

    // console.log(this.state.proposal);
    return (
      <div>
        {BreadcrumbUtils.getProposalBreadCrumb()}
        <PageHeader title={ProposalUtils.renderProposalBySize(this.state.proposal.title, this.state.proposal, false, true)} 
          thisObj={this} showFavIcon={false} beta={false}/>
        <ReactTooltip id="main" place="top" type="dark" effect="float" multiline={true} />
        {descParts.map(function (part) {
          if (part !== "") {
            return (ProposalUtils.getTextOrAnchor(part))
          }
        })}

        <p/>
        {this.renderFilters()}
        <p/>
        <Container fluid className="containerLayout container-fluid chartContainer">
          <div class="chartGridContainer">
            <div class="chartBg">
              {this.renderBasicDetails()}
              {/* {ProposalUtils.isSpam(this.state.proposal) && this.renderBasicDetails()}
              {!ProposalUtils.isSpam(this.state.proposal) && this.renderBasicDetails4Spam()} */}
            </div>
            <div className="chartBg">
              {this.renderVoteBreakdown()}
            </div>
            <div className="chartBg">{this.renderVoteDetails()}</div>
            <div className="chartBg">{this.renderTimingDetails()}</div>
            <div className="chartBg">{this.renderVotes()}</div>
            <div className="chartBg">{this.renderChanges()}</div>
            <div className="chartBg" id="voterDistribution">
              <BaseBarChart title={"Voter Distributions" + this.state.minLabel} 
                chartVisualId="voter-dist" enableSave={true}
                tip={tooltips.proposal.voterDistribution}
                xAxis={"Amount in " + currentNetwork.details.app.token} yAxis="# of Voters"
                showVerticalLabel={true} valueAttr="voterCount" showTotalLabel={false} xAxisValueAttr="title"
                data={this.state.voterDistribution} 
                dataKey="title" columns={voterDistColumns} />
          </div>
            <div className="chartBg" id="voterDistributionPercent">
              <BaseBarChart title={"Voter Distributions by %" + this.state.minLabel} 
                chartVisualId="voter-dist-percent" enableSave={true}
                shortTitle={"Voter Dist. in %"} 
                tip={tooltips.proposal.voterDistributionPercent}
                xAxis={"Amount in " + currentNetwork.details.app.token} yAxis="Percentage Voters"
                showVerticalLabel={true} valueAttr="voterPercent" showTotalLabel={false} xAxisValueAttr="title"
                data={this.state.voterDistribution} 
                dataKey="title" columns={voterDistColumns} />
            </div>
            {this.state.compProposalId && (<div className="chartBg" id="compVoterDistribution">
              <BaseMultiBarChart title={"Compare Voter Distributions with Proposal " + this.state.compProposalId + this.state.minLabel} 
                chartVisualId="compare-voter-dist-with-prop" enableSave={true}
                tip={tooltips.proposal.compVoterDistribution}
                xAxis={"Amount in " + currentNetwork.details.app.token} yAxis="# of Voters"
                showVerticalLabel={false} value1Attr="voterCount" value2Attr="comparisonVoterCount" 
                showTotalLabel={false} xAxisValueAttr="title"
                data={this.state.compVoterDistribution} 
                dataKey="title" columns={voterCompColumns} />
            </div>)}
            {this.state.compProposalId && (<div className="chartBg" id="compVoterDistributionPercent">
              <BaseMultiBarChart title={"Compare Voter Distribution Percent with Proposal " + this.state.compProposalId + this.state.minLabel} 
                chartVisualId="compare-voter-dist-percent-with-prop" enableSave={true}
                tip={tooltips.proposal.compVoterDistributionPercent} 
                xAxis={"Amount in " + currentNetwork.details.app.token} yAxis="Percentage Voters"
                showVerticalLabel={false} value1Attr="voterPercent" value2Attr="comparisonVoterPercent" 
                showTotalLabel={false} xAxisValueAttr="title"
                data={this.state.compVoterDistribution}
                dataKey="title" columns={voterCompColumns} />
            </div>)}
          </div>
        </Container>
        {this.renderProposalHome()}
      </div>
    );
  }

  renderVoteBreakdown() {
    let chartVisualId = currentNetwork.details.networkName + "-vote-breakdown";
    const downloadChart = () => {
      ChartUtils.downloadChart(document, chartVisualId, currentNetwork.details.app.title);
    };

    return (<div id={"div" + chartVisualId}>
        <ChartTitle title="Vote Breakdown" tip={tooltips.proposal.voteSummary} 
          saveChart={downloadChart} enableSave={true} />
        {ProposalUtils.renderVotes(this.state.proposal, false)}
    </div>);

  }

  renderProposalHome() {
    let linkData = IntegrationUtils.getProposalData();

    if (!linkData) {
      return "";
    }

    return (<div>
      <p/>
      <span className="delegateContainer centerAlign">
        <div className="miniStat">
          <div className="miniStatTitle">
            <span><a href={linkData.link + this.state.proposal.proposalId}
              target="_blank"><button className="animLink animLink linkBorder" id={"ProposalHome" + this.state.proposal.proposalId}
              >View proposal on {linkData.label}</button></a></span>
          </div>
        </div>
      </span>
    </div>);
  }

  renderBasicDetails() {
    return (
      <React.Fragment>
        <ChartTitle title="Basic Details" tip={tooltips.proposal.basic} />
        <table>
          <tbody>
            <tr>
              <td>ID: </td>
              <td> {this.state.proposal.proposalId}</td>
            </tr>
            <tr>
              <td>Proposal Type: </td>
              <td> {ProposalUtils.typeFormatter(this.state.proposal.proposalType, this.state.proposalTypeMap)}</td>
            </tr>
            <tr>
              <td>Status: </td>
              <td> {ProposalUtils.statusFormatter(this.state.proposal.status, this.state.statusMap)}</td>
            </tr>
            <tr>
              <td>Total Deposit Amount: </td>
              <td> {this.state.proposal.totalDeposit} {currentNetwork.details.app.proposalToken}</td>
            </tr>
            <tr>
              <td>Total Staked Amount: </td>
              <td> {this.state.proposal.totalStaked} {currentNetwork.details.app.proposalToken}</td>
            </tr>
            <tr>
              <td>Validators Voted: </td>
              <td> {this.state.proposal.validatorsVoted}</td>
            </tr>
            <tr>
              <td>Total Vote Count: </td>
              <td> {this.state.proposal.totalVoteCount}</td>
            </tr>
          </tbody>
        </table>
      </React.Fragment>
    );
  }

  renderTimingDetails() {
    return (
      <React.Fragment>
        <ChartTitle title="Timing" tip={tooltips.proposal.timing} />
        <table>
          <tbody>
            <tr>
              <td>Submit Time: </td>
              <td> {SPUtilities.epochFormatter(this.state.proposal.submitTimeEpoch)}</td>
            </tr>
            <tr>
              <td>Deposit End Time: </td>
              <td> {SPUtilities.epochFormatter(this.state.proposal.depositEndTimeEpoch)}</td>
            </tr>
            <tr>
              <td>Voting Start Time: </td>
              <td> {SPUtilities.epochFormatter(this.state.proposal.votingStartTimeEpoch)}</td>
            </tr>
            <tr>
              <td>Voting End Time: </td>
              <td> {SPUtilities.epochFormatter(this.state.proposal.votingEndTimeEpoch)}</td>
            </tr>
            <tr>
              <td>Time Left: </td>
              <td> {ProposalUtils.timeLeft(this.state.proposal.votingEndTimeLeft, this.state.proposal)}</td>
            </tr>
          </tbody>
        </table>
      </React.Fragment>
    );
  }

  renderVoteDetails() {
    let quorumReached = this.state.proposal.percentVotes >= (this.state.proposal.paramQuorum * 100);
    let quorumStatus = quorumReached ? "Quorum Reached" : "Quorum Not Reached";
    let voteTitle = "Vote Summary (" + quorumStatus + ")";
    
    return (
      <React.Fragment>
        <ChartTitle title={voteTitle} tip={tooltips.proposal.voteSummary} />
        <table>
          <tbody>
            <tr>
              <th></th>
              <th>Total Votes</th>
              <th>Votes %</th>
            </tr>
            <tr>
              <td>Quorum: </td>
              <td> {SPUtilities.formatCoins(this.state.proposal.paramQuorum * this.state.proposal.totalStaked)}</td>
              <td> {SPUtilities.percentFormatter(this.state.proposal.paramQuorum * 100)}</td>
            </tr>
            <tr>
              <td>Overall: </td>
              <td> {SPUtilities.formatCoins(this.state.proposal.totalVotes)}</td>
              <td> {ProposalUtils.percentVotesFormatter(this.state.proposal.percentVotes, this.state.proposal)}</td>
            </tr>
            <tr>
              <td>Yes: </td>
              <td> {SPUtilities.formatCoins(this.state.proposal.tallyYes)}</td>
              <td> {ProposalUtils.tallyFormatter(this.state.proposal.tallyYes, this.state.proposal)}</td>
            </tr>
            <tr>
              <td>Abstain: </td>
              <td> {SPUtilities.formatCoins(this.state.proposal.tallyAbstain)}</td>
              <td> {ProposalUtils.tallyFormatter(this.state.proposal.tallyAbstain, this.state.proposal)}</td>
            </tr>
            <tr>
              <td>No: </td>
              <td> {SPUtilities.formatCoins(this.state.proposal.tallyNo)}</td>
              <td> {ProposalUtils.tallyFormatter(this.state.proposal.tallyNo, this.state.proposal)}</td>
            </tr>
            <tr>
              <td>No with Veto: </td>
              <td> {SPUtilities.formatCoins(this.state.proposal.tallyNoWithVeto)}</td>
              <td> {ProposalUtils.tallyFormatter(this.state.proposal.tallyNoWithVeto, this.state.proposal)}</td>
            </tr>
          </tbody>
        </table>
      </React.Fragment>
    );
  }

  renderChanges() {
    let changes = {};

    if (this.state.proposal.changes && this.state.proposal.changes !== null) {
      try {
        changes = JSON.parse(this.state.proposal.changes);
      }
      catch (err) {
        return "";
      }
    }

    return (
      <React.Fragment>
        <ChartTitle title="Proposal Changes" tip={tooltips.proposal.voteSummary} />
        <ReactJson src={changes} theme="summerfruit" collapsed="1" />
      </React.Fragment>
    );
  }

  renderVotes() {
    const headerSortingClasses = (column, sortOrder, isLastSorting, colIndex) => (
      sortOrder === 'asc' ? 'sorting-asc' : 'sorting-desc'
    );
    
    var columns = [
      {text: "Address", dataField: "voter", formatter: ProposalUtils.formatAddress, sort: true, headerStyle: Utilities.width(50), headerSortingClasses},
      {text: "Vote", dataField: "voteOption", formatter: ProposalUtils.formatVoteByProp, formatExtraData: this.state.proposal, sort: true, headerStyle: Utilities.width(25), headerSortingClasses},
      {text: Utilities.respLabel("Stake", "Stake", "Stake"), dataField: "totalStaked", formatter: CoinUtils.stakeFormatterRounded, sort: true, headerStyle: Utilities.width(25), headerSortingClasses},
    ];

    const options = UIUtils.getPageOptionsSmall(this, 5);
    const defaultSorted = [{
      dataField: 'votingPower',
      order: 'desc' // desc or asc
    }];

    return (
      <div>
        <ChartTitle title={"Votes" + this.state.minLabel} tip={tooltips.proposal.votes} />
        <div className="tablesBg">
          <span>{this.renderVoteOptionTags()} </span>
        </div>
        <p/>
        <ReactTooltip id="main" place="top" type="dark" effect="float" multiline={true} />
        <BootstrapTable keyField='voter' data={this.state.votes}
          columns={columns} striped options={options} defaultSorted={defaultSorted}
          condensed noDataIndication="No data" pagination={ paginationFactory(options) } />
      </div>
    );
  }

  renderVoteOptionTags() {
    if (!this.state.voteOptionMap) {
      return "";
    }
    if (RespUtils.isMobileView()) {
      return (<div><table><tbody><tr>
          <td className="view-tag-vote view-tag"><a className="animLink" onClick={this.showAllVotes}>{this.formatView(ALL, (this.state.voteOptionMap.All), ALL)}</a> </td>
          <td className="view-tag-vote view-tag"><a className="animLink" onClick={this.showYesVotes}>{this.formatView(Yes, (this.state.voteOptionMap.Yes), Yes)}</a> </td>
          <td className="view-tag-vote view-tag"><a className="animLink" onClick={this.showAbstainVotes}>{this.formatView(Abstain, (this.state.voteOptionMap.Abstain), Abstain)}</a> </td>
          <td className="view-tag-vote view-tag"><a className="animLink" onClick={this.showNoVotes}>{this.formatView(No, (this.state.voteOptionMap.No), No)}</a> </td>
          <td className="view-tag-vote view-tag"><a className="animLink" onClick={this.showVetoVotes}>{this.formatView(Veto, (this.state.voteOptionMap[Veto]), Veto)}</a> </td>
          <td className="view-tag-vote view-tag"><a className="animLink" onClick={this.showValidatorVotes}>{this.formatView("Val", (this.state.voteOptionMap.Validators), Validators)}</a> </td>
          <td className="view-tag-vote view-tag"><a className="animLink" onClick={this.showMissingVotes}>{this.formatView("Didn't Vote", (this.state.voteOptionMap.MissingVotes), MissingVotes)}</a> </td>
        </tr></tbody></table></div>);
    } else {
      return (<div><table><tbody><tr>
          <td className="view-tag-vote view-tag"><a className="animLink" onClick={this.showAllVotes}>{this.formatView(ALL, (this.state.voteOptionMap.All), ALL)}</a> </td>
          <td className="view-tag-vote view-tag"><a className="animLink" onClick={this.showYesVotes}>{this.formatView(Yes, (this.state.voteOptionMap.Yes), Yes)}</a> </td>
          <td className="view-tag-vote view-tag"><a className="animLink" onClick={this.showAbstainVotes}>{this.formatView(Abstain, (this.state.voteOptionMap.Abstain), Abstain)}</a> </td>
          <td className="view-tag-vote view-tag"><a className="animLink" onClick={this.showNoVotes}>{this.formatView(No, (this.state.voteOptionMap.No), No)}</a> </td>
          <td className="view-tag-vote view-tag"><a className="animLink" onClick={this.showVetoVotes}>{this.formatView(Veto, (this.state.voteOptionMap[Veto]), Veto)}</a> </td>
          <td className="view-tag-vote view-tag"><a className="animLink" onClick={this.showValidatorVotes}>{this.formatView(Validators, (this.state.voteOptionMap.Validators), Validators)}</a> </td>
          <td className="view-tag-vote view-tag"><a className="animLink" onClick={this.showMissingVotes}>{this.formatView("Didn't Vote", (this.state.voteOptionMap.MissingVotes), MissingVotes)}</a> </td>
        </tr></tbody></table></div>);
    }
  }

  renderFilters() {
    return (<div>
        <table>
          <tbody>
            <tr>
              <td className="view-tag">Filter by minimum ${currentNetwork.details.app.token} stake:</td>
              <td className="view-tag"><a className={this.state.minStake == 0 ? "animLinkSelected": "animLink"} href={this.getFilterUrl()}>Show All</a> </td>
              <td className="view-tag"><a className={this.state.minStake == 0.1 ? "animLinkSelected": "animLink"} href={this.getFilterUrl("0.1")}>{CoinUtils.roundMultipleOf10(0.1)}</a> </td>
              <td className="view-tag"><a className={this.state.minStake == 1 ? "animLinkSelected": "animLink"}  href={this.getFilterUrl("1")}>{CoinUtils.roundMultipleOf10(1)}</a> </td>
              <td className="view-tag"><a className={this.state.minStake == 10 ? "animLinkSelected": "animLink"}  href={this.getFilterUrl("10")}>{CoinUtils.roundMultipleOf10(10)}</a> </td>
              <td className="view-tag"><a className={this.state.minStake == 100 ? "animLinkSelected": "animLink"}  href={this.getFilterUrl("100")}>{CoinUtils.roundMultipleOf10(100)}</a> </td>
              <td className="view-tag"><a className={this.state.minStake == 1000 ? "animLinkSelected": "animLink"}  href={this.getFilterUrl("1000")}>{CoinUtils.roundMultipleOf10(1000)}</a> </td>
              <td className="view-tag"><a className={this.state.minStake == 10000 ? "animLinkSelected": "animLink"}  href={this.getFilterUrl("10000")}>{CoinUtils.roundMultipleOf10(10000)}</a> </td>
              <td className="view-tag"><a className={this.state.minStake == 100000 ? "animLinkSelected": "animLink"}  href={this.getFilterUrl("100000")}>{CoinUtils.roundMultipleOf10(100000)}</a> </td>
            </tr>
          </tbody>
        </table>
      </div>);
  }


  getFilterUrl(filter) {
    if (filter) {
      return SessionUtils.getUrl("/proposal/" + this.props.match.params.proposalId + "/" + filter);
    }

    return SessionUtils.getUrl("/proposal/" + this.props.match.params.proposalId);
  }

  formatView(label, count, status) {
    if (count !== null) {
      label = label + " " + count;
    } else {
      label = label + " 0";
    }

    if (this.state.voteStatus === status) {
      return (<span className="view-tag-selected">{label}</span>);
      // return <b><u>{label}</u></b>
    }

    return label;
  }

  showAllVotes() {
    this.filterVotes(ALL);
  }

  showYesVotes() {
    this.filterVotes(Yes);
  }

  showValidatorVotes() {
    let votes = this.state.orgVotes;
    let filteredVotes = []
    for (let i = 0; i < votes.length; i++) {
      let vote = votes[i];
      let name = vote["name"];
      if (name !== null) {
        filteredVotes.push(vote);
      }
    }

    this.setState({"votes": filteredVotes, "voteStatus": Validators});
  }

  showMissingVotes() {
    let votes = this.state.orgVotes;
    let filteredVotes = []
    for (let i = 0; i < votes.length; i++) {
      let vote = votes[i];
      let name = vote["name"];
      if (name !== null && vote["voteOption"] == null) {
        filteredVotes.push(vote);
      }
    }

    this.setState({"votes": filteredVotes, "voteStatus": MissingVotes});
  }

  showAbstainVotes() {
    this.filterVotes(Abstain);
  }

  showNoVotes() {
    this.filterVotes(No);  
  }

  showVetoVotes() {
    this.filterVotes(Veto);  
  }

  filterVotes(key) {
    let votes = this.state.orgVotes;
    let filteredVotes = []
    for (let i = 0; i < votes.length; i++) {
      let vote = votes[i];
      let voteOption = vote["voteOption"];
      if (key === ALL) {
        filteredVotes.push(vote);
      } else if (voteOption !== null && common.proposal.vote[voteOption] === key) {
        filteredVotes.push(vote);
      }
    }

    this.setState({"votes": filteredVotes, "voteStatus": key});
  }

}

export default Proposal;
