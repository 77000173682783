import React from 'react';

import currentNetwork from '../currentNetwork';

class ChainUtils extends React.Component {
  
    static isMatic() {
        if (currentNetwork.details.app.token === "MATIC") {
            return true;
        }

        return false;
    }

    static isNotMatic() {
        return !ChainUtils.isMatic();
    }

}

export default ChainUtils;
