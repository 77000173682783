import React from 'react';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';

import RespUtils from '../util/RespUtils';
import Dropdown from '../base/Dropdown';
import LogoUtils from '../util/LogoUtils';
import SessionUtils from '../util/SessionUtils';
import ProjectUIUtils from '../projects/ProjectUIUtils';
import SearchableDropdown from '../base/SearchableDropdown';
import common from '../common';
import MainUtils from '../main/MainUtils';

class TokenUtils extends React.Component {
    static renderTokenSwitchNew(thisObj, token) {
        if (RespUtils.isTabletView()) {
            // return (<React.Fragment>
            //             <br/><div className="tokenList"><Dropdown onSelect={thisObj.onTokenSelect} 
            //                         values={thisObj.state.tokens} defaultValue={token} size="sm" /></div>
            //             <br/>
            //     </React.Fragment>);
            return <div className="tokenList">
                        {TokenUtils.renderRelatedTokens(thisObj)}
                        &nbsp;
                        <SearchableDropdown onSelect={thisObj.onTokenSelect} values={thisObj.state.tokens} 
                            defaultValue={token} size="lg" placeholder="Other Tokens" />
                    </div>
        } else {
            return <div className="tokenList">
                        {TokenUtils.renderRelatedTokens(thisObj)}
                        &nbsp;
                        <SearchableDropdown onSelect={thisObj.onTokenSelect} values={thisObj.state.tokens} 
                            defaultValue={token} size="lg" placeholder="Other Tokens" />
                    </div>
        }
    }


    static renderTokenSwitch(thisObj, token) {
        if (RespUtils.isTabletView()) {
            return <div className="tokenList">
                        {TokenUtils.renderRelatedTokens(thisObj, token)}
                        &nbsp;
                        <Dropdown onSelect={thisObj.onTokenSelect} values={thisObj.state.tokens} 
                            defaultValue={token} size="lg" className="moreNetworks" />
                    </div>
        } else {
            return <div className="tokenList">
                        {TokenUtils.renderRelatedTokens(thisObj, token)}
                        &nbsp;
                        <Dropdown onSelect={thisObj.onTokenSelect} values={thisObj.state.tokens} 
                            defaultValue={token} size="lg" className="moreNetworks" />
                    </div>
        }
    }

    static renderRelatedTokens(thisObj, token) {
        if (!thisObj.state.relatedTokens || thisObj.state.relatedTokens.length <= 1) return "";

        return (<Dropdown onSelect={thisObj.onOtherTokenSelect} values={thisObj.state.relatedTokens} 
            customValue={"Interchain"} size="lg" className="moreNetworks" />);
    }

    static getToken(thisObj) {
        let token = thisObj.state.token;

        //, defaultToken
        //  if (!token) {
        //     token = defaultToken;
        // }

        if (thisObj.props.match && thisObj.props.match.params.token) {
          token = thisObj.props.match.params.token;
        }

        token = decodeURIComponent(token);
        
        return token;
    }

    /**
     * get token id from this object
     * if not, get denom from url param
     * if not, get token from url param
     * 
     */

//     static renderTokenSwitch(thisObj, token) {
//     if (RespUtils.isNotTabletView()) {
//         return <div className="tokenList"><Dropdown onSelect={thisObj.onTokenSelect} values={thisObj.state.tokens} 
//             defaultValue={token} size="lg" /></div>
//     }
//   }

//   static renderTokenSwitchResp(thisObj, token) {
//         return <React.Fragment><br/><div className="tokenList"><Dropdown onSelect={thisObj.onTokenSelect} values={thisObj.state.tokens} 
//             defaultValue={token} size="sm" /></div>
//             <br/>
//         </React.Fragment>
//     }
//   }

    static tokenNameFormatter(cell, row) {
        if (!cell) {
            return cell;
        }

        const onClick = e => e.stopPropagation();
        let length = RespUtils.isMobileView() ? 15 : 30;
        let name = MainUtils.getShortText(cell, length);

        return (<span onClick={ onClick }><a className="tourValName animLinkTable" 
                    href={SessionUtils.getUrl("/tokens/" + row.token + "/history")}
                    >{LogoUtils.tokenLogoWithCustomStyle(row.logo, row, "logoStyleWithName")
                }&nbsp;&nbsp;{name}</a>&nbsp;{TokenUtils.getIbcBadge(row)}{ProjectUIUtils.formatTokenMediaSmall(row.twitter, row)}</span>);
    }

    static getIbcBadge(row) {
        if (row && row.ibcInd === "true") {
            return (<span title="This is an IBC asset">
                        <CompareArrowsIcon className="mInfoIcon" style={{fill: "white"}} fontSize="medium"/>
                    </span>);
        }

        return "";
    }

    static tokenFormatter(cell) {
        if (!cell) {
        return cell;
        }

        return "$" + cell;
    }

    static navToOtherTokenPage(e) {
        // console.log(e);
        // console.log(e.target.value);
        let value = e.target.value;
        // console.log(value);
        let details = value.split(",");
        // console.log(details);
        if (details.length === 2) {
            let context = common.allNetworks[details[0]];
            if (context) {
                if (details[0] === details[1]) {
                    window.location = "/" + context + "/stats/addresses";
                } else {
                    window.location = "/" + context + "/tokens/" + details[1];
                }
            }
        }
    }
}

export default TokenUtils;
