import React from 'react';
import SPUtilities from './SPUtilities';

class MediaUtils extends React.Component {

  static websiteFormatter(cell, row) {
    if (!cell) {
      return cell;
    }

    return (<a href={SPUtilities.getUrl(cell)} target="_blank" title="website" className="mediaCustom fa fa-globe fa-md"/>);
  }

  static valMediaFormatter(cell, row) {
    if (!cell) {
      return cell;
    }

    return (<React.Fragment>
        <a href={SPUtilities.getUrl(cell)} target="_blank" title="website" className="mediaCustom fa fa-globe fa-md"/>
        {row.securityContact && row.securityContact != null && (
            <a href={"mailto:" + row.securityContact} title="email" className="mediaCustom fas fa-envelope fa-md" aria-label="Send email to validator"/>
        )}
    </React.Fragment>);
  }
}

export default MediaUtils;
