import React from 'react';
import {Container} from 'react-bootstrap';
import ReactTooltip from "react-tooltip";

import BidirectionChangeAndCumulativeChart from '../charts/BidirectionChangeAndCumulativeChart';
import DataUtils from '../util/DataUtils';
import MultiLineChart from '../charts/MultiLineChart';
import tooltips from "../tooltips";

import "../validators/Validators.css";
import '../styles/section.css';
import Utilities from "../util/Utilities";
import RespUtils from "../util/RespUtils";

import currentNetwork from '../currentNetwork';
import SessionUtils from '../util/SessionUtils';
import ApiUtils from '../util/ApiUtils';
import Loading from '../components/Loading';
import NetworkHeader from '../network/NetworkHeader';
import LSProviders from './LSProviders';
import ValidatorUtils from '../validators/ValidatorUtils';
import LSMValidators from './LSMValidators';
import LiquidStakingValidators from './LiquidStakingValidators';

const key = "lsv_2";

class LSM extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validators: [],
      lsHistory: [],
      notification: {"message": null},
      providers: [],
      frequencyNetwork: "M",
      lspSummary: null,
      lsp: null,
      lspTitle: "",
      lspTitleMobile: "",
      frequency: "M",
      // showAll: false,
      lspDetails: null,
      lastUpdated: "",
      isLoading: true,
      width: window.innerWidth,
    };
    this.updateDimensions = this.updateDimensions.bind(this);
    this.handleChartUpdateNetwork = this.handleChartUpdateNetwork.bind(this);   
    this.repaintData = this.repaintData.bind(this);
    this.switchProtocol = this.switchProtocol.bind(this);
  }

  updateDimensions() {
    this.setState({width: window.innerWidth});
  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  async componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    SessionUtils.initNetwork(this, false);
    let lsp = currentNetwork.details.app.token;
    if (this.props.match && this.props.match.params.lsp) {
      lsp = this.props.match.params.lsp;
    }
    // let showAll = this.state.showAll;
    // await this.prepareData(this.state.frequencyNetwork, showAll, lsp, true);
    await this.prepareData(this.state.frequencyNetwork, lsp);
  }

  // async prepareData(frequencyNetwork, showAll, lsp) {
  async prepareData(frequencyNetwork, lsp) {
    // this.setState({isLoading: true});

    let url = "listData?type=lsValidators&frequencyNetwork=" + frequencyNetwork;

    let lspTitle = " Overall for $" + currentNetwork.details.app.token;
    let lspTitleMobile = "Overall";
    url += "&lsp=" + lsp;
    // console.log(url);

    const allData = await ApiUtils.get(url);
    // console.log(allData);
    if (!allData) {
      this.setState({error: true, isLoading: false});
      return;
    }

    let validators = ValidatorUtils.prepValidators(allData["data"]);
    let notification = allData["notification"];
    let coinStat = allData["coinStat"];
  
    let lspDetails = allData["lspDetails"];
    let lsHistory = DataUtils.addCumulativeData(allData["lsHistory"], "deltaDelegation", "cumulativeDelegationChange");
    
    if (lsp != currentNetwork.details.app.token) {
      lspTitle = "via " + lspDetails["name"];
      lspTitleMobile = lspTitle;
    }

    this.setState({"validators": validators, "notification": notification, "coinStat": coinStat, 
      "lsp": lsp, "lspTitle": lspTitle, "lspTitleMobile": lspTitleMobile,
      "frequencyNetwork": frequencyNetwork, "lspSummary": allData["lspSummary"],
      "providers": allData["lsps"], "lsHistory": lsHistory, "lspDetails": lspDetails,
      "isLoading": false});
  }
  
  render() {
    if (this.state.isLoading) {
      return <Loading />;
    }

    let token = currentNetwork.details.app.token;

    let title4TotalLSChart = "Liquid Stake History - " + this.state.lspTitle;
    let title4DeltaLSChart = "Liquid Staking Daily Changes - " + this.state.lspTitle;
    if (RespUtils.isMobileView()) {
      title4TotalLSChart = "LS History - " + this.state.lspTitleMobile;
      title4DeltaLSChart = "LS Delta - " + this.state.lspTitleMobile;
    }

    return (
      <div>
        <NetworkHeader skipStats={true} coinStat={this.state.coinStat} 
          title={"Liquid Staking Delegations ($" + currentNetwork.details.app.token + ")"} 
          shortTitle={"Liquid Staking"} 
          thisObj={this} beta={false} />
        <p/>
        <LSProviders providers={this.state.providers} summary={this.state.lspSummary} coinStat={this.state.coinStat}
          switchProtocol={this.switchProtocol} selectedProtocol={this.state.lsp}/>
        <ReactTooltip id="main" place="top" type="dark" effect="float" multiline={true} />
        <Container fluid className="containerLayout container-fluid chartContainer">
          <div className="chartGridContainer">
            <div className="chartBg" id="networkSignHistory">
              <MultiLineChart title={title4TotalLSChart} xAxis="Date" yAxis="Total Liquid Stake" adjustRange={true} 
                  showVerticalLabel={false} valueAttr={["totalDelegation"]} rangeAttr="totalDelegation" showTotalLabel={false} xAxisValueAttr="title"
                  data={this.state.lsHistory} tip={tooltips.networkCharts.liquidStakingHistory} hideFullScreenLink={true}
                  handleChart={this.handleChartUpdateNetwork} formatValues={true} fsElementId="liquidStakingHistory" 
                  frequencies={["M", "Y", "All"]} frequency={this.state.frequencyNetwork} />
            </div>
            <div className="chartBg">
              <BidirectionChangeAndCumulativeChart title={title4DeltaLSChart}
                  xAxis="Date" yAxis="Daily LS Changes" xAxisValueAttr="title" cumulativeAttr="cumulativeDelegationChange"
                  valueAttr="deltaDelegation" showVerticalLabel={false} hideFullScreenLink={true}
                  showLegend={true} adjustRange={true}
                  range={[0,3]} data={this.state.lsHistory} tip={tooltips.networkCharts.liquidStakingDeltaHistory} 
                  handleChart={this.handleChartUpdateNetwork} formatValues={true} frequencies={["M", "Y", "All"]} frequency={this.state.frequencyNetwork} 
                  />
            </div>
          </div>
        </Container>
        {this.state.lspSummary.lsmType != "LIQUID_STAKING" && <LSMValidators validators={this.state.validators} repaintData={this.repaintData} />}
        {this.state.lspSummary.lsmType == "LIQUID_STAKING" && <LiquidStakingValidators validators={this.state.validators} repaintData={this.repaintData} />}
      </div>
    );
  }

  repaintData() {
    let validators = this.state.validators;
    let newValidators = Utilities.addIndex(validators, "poolId");
    this.setState({validators: newValidators});
  }

  handleChartUpdateNetwork(frequencyNetwork) {
    let lsp = this.state.lsp;
    this.prepareData(frequencyNetwork, lsp);
  }

  switchProtocol(e) {
    let lsp = e.target.value;
    this.prepareData(this.state.frequencyNetwork, lsp);
  }
}

export default LSM;
