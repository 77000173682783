import React, { Component } from 'react';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import common from '../common';



class FavIcon extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
    this.favourite = this.favourite.bind(this);
    this.unfavourite = this.unfavourite.bind(this);
  }

  unfavourite(e) {
    // console.log("in unfavourite: ");
    // console.log(e);
    // console.log(this.props.id);
    this.props.thisObj.unfavourite(this.props.id);
  }

  favourite(e) {
    // console.log("in favourite: ");
    // console.log(e);
    // console.log(this.props.id);
    this.props.thisObj.favourite(this.props.id);
  }

  render() {
    if(this.props.isFav === true) {
      return (<FavoriteIcon htmlColor={common.colors.text} onClick={this.unfavourite} className="animLink"
        id={this.props.id} />);
    }

    return (<FavoriteBorderIcon htmlColor={common.colors.text} onClick={this.favourite} className="animLink"
      id={this.props.id} />);
  }
}


export default FavIcon;
