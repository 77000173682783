import React from 'react';

import OpenWithIcon from '@material-ui/icons/OpenWith';
import CancelIcon from '@material-ui/icons/Cancel';
import UITableUtils from './UITableUtils';

class RespUtils extends React.Component {
  static isWidthLessThan(width) {
    // console.log("UITableUtils.isDefaultView(): ", UITableUtils.isDefaultView(), 
    //   "window.innerWidth: ", window.innerWidth, ", width:", width);
    // console.log(UITableUtils.isDefaultView())
    // console.log((window.innerWidth < width))
    return UITableUtils.isDefaultView() && window.innerWidth < width;
  }

  static isMobileView() {
    return RespUtils.isWidthLessThan(600);
  }

  static isTabletView() {
    return RespUtils.isWidthLessThan(1000);
  }

  static isOldPCView() {
    return RespUtils.isWidthLessThan(1400);
  }

  static isWidthMoreThan(width) {
    return !UITableUtils.isDefaultView() || window.innerWidth > width;
  }

  static isNotMobileView() {
    return RespUtils.isWidthMoreThan(600);
  }

  static isNotTabletView() {
    return RespUtils.isWidthMoreThan(1000);
  }

  static isNotOldPCView() {
    return RespUtils.isWidthMoreThan(1400);
  }

  static getChartHeight(thisObj) {
    let height = 300;
    if (thisObj.props.chartType === 'pool') {
      if (window.innerHeight < 600) {
        height = 250;
      } else if (window.innerHeight < 1000) {
        height = 300;
      }
    }
    return height;
  }

  static getResponsiveWidth(thisObj) {
    let width = "90%"
    if (!thisObj.state.responsive) {
      width =  window.innerWidth * 1.5;
    }

    return width;
  }

  static getSpecialMessage() {
    let specialMessage = "";
    if (RespUtils.isMobileView()) {
      specialMessage = "Tip: This screen is best viewed in landscape mode";
      //  (Rotate your mobile device for a better view)
    }

    return specialMessage;
  }

  static isFullScreen() {
    if (!document.isFullScreen && !document.fullscreenElement && !document.webkitFullscreenElement 
        && !document.mozFullScreenElement && !document.msFullscreenElement) {
      
      return false;
    }

    return true;
  }

  static handleFullScreen(thisObj) {
    if (thisObj.state.fullScreenMode === true) {
      if (RespUtils.isFullScreen()) {
        document.exitFullscreen();
      }
      thisObj.setState({fullScreenMode: false});
    } else {
      let elementId = thisObj.props.fsElementId;
      let el = document.getElementById(elementId);
      el.addEventListener('fullscreenchange', (event) => { 
        RespUtils.isFullScreen() ? thisObj.setState({fullScreenMode: true}): thisObj.setState({fullScreenMode: false});
        });
      el.requestFullscreen();

      thisObj.setState({fullScreenMode: true});
    }
  }

  static renderFullScreenLink(thisObj) {
    if (thisObj.props.hideFullScreenLink) return "";
    
    if(thisObj.props.fsElementId != null && RespUtils.isNotTabletView()) {
      if (thisObj.state.fullScreenMode === true) {
        return (<button onClick={thisObj.handleFullScreen} className="animLinkMenu animLink"><CancelIcon /></button>);
      }
      return (<button onClick={thisObj.handleFullScreen} className="animLinkMenu animLink"><OpenWithIcon /></button>);
    }

    return "";
  }

  static getRespLabel(smallValue, largeValue) {
    // console.log({ width: "" + value, textAlign: 'left' });
    if (RespUtils.isMobileView()) {
      return smallValue;
    }

    return largeValue;
  }
}

export default RespUtils;
