import React from 'react';
// import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import {Container} from 'react-bootstrap';
// import Table from 'react-bootstrap/Table';
import ReactTooltip from "react-tooltip";

import tooltips from "../tooltips";
import ValInline from './ValInline';
import ValHeader from './ValHeader';
import ApiUtils from '../util/ApiUtils';
import SPUtilities from '../util/SPUtilities';
import Utilities from '../util/Utilities';
import MainUtils from "../main/MainUtils";
import UIUtils from "../util/UIUtils";
import "./Validator.css";
import '../styles/section.css';
import '../styles/tables.css';
import ChartTitle from '../components/ChartTitle';
import SignFmtUtils from './SignFmtUtils';
// import RespUtils from '../util/RespUtils';
import ProposalUtils from '../network/ProposalUtils';
import ValNav from './ValNav';
import currentNetwork from '../currentNetwork';
// import ValidatorUtils from '../validators/ValidatorUtils';
import BaseBarChart from '../charts/BaseBarChart';
import common from '../common';
import InterchainValPerf from './InterchainValPerf';
import MaticValidatorUtils from '../matic/MaticValidatorUtils';
import ValidatorUtils from '../validators/ValidatorUtils';
import ChainUtils from '../util/ChainUtils';
import StackedBarChart from '../charts/StackedBarChart';
import MultiLineChart from '../charts/MultiLineChart';
import RespUtils from '../util/RespUtils';
import CoinUtils from '../util/CoinUtils';
import SessionUtils from '../util/SessionUtils';
import LSUtils from '../lsm/LSUtils';
import AddressUtils from '../util/AddressUtils';

class Validator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      val: {},
      coinStat: {},
      hourlyChartData: [],
      dailyChartData: [],
      notification: {},
      width: window.innerWidth,
      size: 10,
      responsive: true,
      isLoading: true,
      showMore: "false",
      votes: [],
      frequencyHour: "8H",
      frequencyDay: "10D",
      interchainPerf: [],
      extraData: {},
      icsData: null,
      params: null,
    }
    this.updateDimensions = this.updateDimensions.bind(this);
    this.handleMore = this.handleMore.bind(this);
    this.handleChartUpdateHourlyFrequency = this.handleChartUpdateHourlyFrequency.bind(this);   
    this.handleChartUpdateDailyFrequency = this.handleChartUpdateDailyFrequency.bind(this);   
  }

  updateDimensions() {
    this.setState({width: window.innerWidth});
  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  async componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    SessionUtils.setNetwork(this);

    this.prepareData(this.state.frequencyHour, this.state.frequencyDay)
  }

  async prepareData(frequencyHour, frequencyDay) {
    let url = "listData?type=getPool" + "&frequencyHour=" + frequencyHour + "&frequencyDay=" + frequencyDay;

    if (this.props.match && this.props.match.params.address) {
      url += ValidatorUtils.getIdParam(this);
    } else if (this.props.match && this.props.match.params.identity) {
      let identity = this.props.match.params.identity;
      url += "&identity=" + identity;
    }

    // console.log("poolId is:", poolId);
    // const nodeData = await ApiUtils.get("listValidators?sortBy=stake");
    // let showMore = "false";
    // if (this.props.match && this.props.match.params.showMore) {
    //   url += "&showMore=" + this.props.match.params.showMore;
    //   showMore = this.props.match.params.showMore;
    // }
    // console.log("showMore:", this.props.match.params.showMore);
    // console.log("url:", url);

    const allData = await ApiUtils.get(url);
    // console.log("allData is:", allData);
    //"showMore": showMore,

    if (!allData) {
      this.setState({error: true, isLoading: false});
      return;
    }

    let val = allData["val"];
    let coinStat = allData["coinStat"];

    this.setState({"val": val,
      "hourlyChartData": allData["hourlyChartData"],
      "dailyChartData": allData["dailyChartData"],
      "notification": allData["notification"],
      "votes": allData["votes"],
      "statusMap": allData["statusMap"], "proposalTypeMap": allData["proposalType"],
      "coinStat": coinStat, "interchainPerf": allData["interchainPerf"],
      "events": allData["events"], isLoading: false,
      "frequencyHour": frequencyHour, "frequencyDay": frequencyDay,
      "extraData": allData["extraData"], "params": allData["params"],
      "icsData": allData["icsData"]});
  }

  render() {
    let loadMsg = UIUtils.getLoading(this);
    if (loadMsg) return loadMsg;

    var perfData = [
      {text: "Date", dataField: "title", sort: true, headerStyle: Utilities.width(35)},
      {text: "Signed", dataField: "signed", sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(19)},
      {text: "Missed", dataField: "missed", sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(19)},
      {text: "Sign %", dataField: "signedPer", formatter: MainUtils.signPerFormatterTitle, formatExtraData: this.state.val.poolId, sort: true,  sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(20)},
      {text: "Network Sign %", dataField: "networkAvgSignedPer", formatter: MainUtils.signPerFormatterTitle, formatExtraData: this.state.val.poolId, sort: true,  sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(20)},
    ];

    if (currentNetwork.details.ui.validators.supportOracle === true) {
      perfData.push({text: "Oracle Missed Votes", dataField: "oracleMissedVotes", sort: true,  sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(20)});
    } 

    return (
      <div>
        <p/>
        <ValHeader val={this.state.val} coinStat={this.state.coinStat} notification={this.state.notification} title="Summary" thisObj={this}/>
        <ReactTooltip id="main" place="top" type="dark" effect="float" multiline={true} />
        <ValInline val={this.state.val} hideNav={true} coinStat={this.state.coinStat} params={this.state.params} />
        <Container fluid className="containerLayout container-fluid chartContainer">
          <div className="chartGridContainer">
            <div className="chartBg">{this.renderAddressDetails()}</div>
            {ChainUtils.isNotMatic() && <div className="chartBg">{this.renderValidatorRunningSummary()}</div>}
            {ChainUtils.isMatic() && <div className="chartBg">{MaticValidatorUtils.renderValidatorRunningSummary(this.state.val, this.state.coinStat)}</div>}
            {currentNetwork.details.app.lsm && currentNetwork.details.app.lsmType == "LSM_FULL" && <div className="chartBg">{this.renderLsmDelegations()}</div>}
            {currentNetwork.details.app.tendermint === true && <div className="chartBg">{this.renderVotes()}</div>}
            {currentNetwork.details.app.tendermint === true && <div className="chartBg">{this.renderEvents()}</div>}
            {ChainUtils.isMatic() && <div className="chartBg">{MaticValidatorUtils.renderValidatorDetails(this.state.val)}</div>}
            {ChainUtils.isMatic() && <div className="chartBg">{MaticValidatorUtils.renderCheckpointSigns(this)}</div>}
          </div>
        </Container>

        <Container fluid className="containerLayout container-fluid chartContainer">
          <div className="chartGridContainer">
            <div className="chartBg" id="hourlySignatures">
              <StackedBarChart title="Hourly Signatures" tip={tooltips.valDetailsCharts.hourlySigns}
                  chartVisualId="hourly-signatures"
                  showVerticalLabel={true} valueAttr="signed" valueAttr2="missed" showTotalLabel={false}
                  data={this.state.hourlyChartData} fsElementId="hourlySignatures"  
                  handleMore={this.handleMore} addMoreLink={true} showMoreValue={this.state.showMore}
                  frequencies={["8H", "D", "W"]} frequency={this.state.frequencyHour} 
                  handleChart={this.handleChartUpdateHourlyFrequency} xAxis="Date/Time" 
                  dataKey="title" columns={perfData} />
            </div>
            <div className="chartBg" id="hourlySignPercent">
              <MultiLineChart title="Hourly Sign % - Validator vs Network" shortTitle="Hourly Sign % - Val vs Net" 
                  chartVisualId="hourly-sign-val-vs-network" enableSave={true}
                  xAxis="Date/Time" yAxis="Sign %"
                  showVerticalLabel={false} valueAttr={["signedPer", "networkAvgSignedPer"]} fsElementId="hourlySignPercent"
                  showTotalLabel={false} xAxisValueAttr="title" rangeAttrs={["signedPer", "networkAvgSignedPer"]} 
                  data={this.state.hourlyChartData} tip={tooltips.valDetailsCharts.hourlySigns} 
                  handleChart={this.handleChartUpdateHourlyFrequency} showLegend={true}
                  frequencies={["8H", "D", "W"]} frequency={this.state.frequencyHour}
                  dataKey="title" columns={perfData} />
            </div>
            <div className="chartBg" id="dailySignatures">
              <StackedBarChart title="Daily Signatures" tip={tooltips.valDetailsCharts.dailySigns}
                  chartVisualId="daily-sign"
                  showVerticalLabel={true} valueAttr="signed" valueAttr2="missed" showTotalLabel={false}
                  data={this.state.dailyChartData} fsElementId="dailySignatures"  
                  handleMore={this.handleMore} addMoreLink={true} showMoreValue={this.state.showMore} 
                  frequencies={["10D", "M", "3M"]} frequency={this.state.frequencyDay} 
                  handleChart={this.handleChartUpdateDailyFrequency} xAxis="Date"
                  dataKey="title" columns={perfData} />
            </div>
            <div className="chartBg" id="dailySignPercent">
              <MultiLineChart title="Daily Sign % - Validator vs Network" shortTitle="Daily Sign % - Val vs Net" 
                  chartVisualId="daily-sign-val-vs-network" enableSave={true}
                  xAxis="Date" yAxis="Sign %"
                  showVerticalLabel={false} valueAttr={["signedPer", "networkAvgSignedPer"]} fsElementId="dailySignPercent"
                  showTotalLabel={false} xAxisValueAttr="title" rangeAttrs={["signedPer", "networkAvgSignedPer"]} 
                  data={this.state.dailyChartData} tip={tooltips.valDetailsCharts.dailySigns} 
                  handleChart={this.handleChartUpdateDailyFrequency} showLegend={true}
                  frequencies={["10D", "M", "3M"]} frequency={this.state.frequencyDay} 
                  dataKey="title" columns={perfData} />
            </div>
            {ChainUtils.isMatic() && (MaticValidatorUtils.renderPoolCheckpointPerf(this))}
            {currentNetwork.details.ui.validators.supportOracle === true && (this.renderOraclePerf(perfData))}
            {ChainUtils.isMatic() && (MaticValidatorUtils.renderValidatorPerfTables(this))}
            {/* {ChainUtils.isNotMatic() && this.renderValidatorPerfTables()} */}
          </div>
          <InterchainValPerf interchainPerf={this.state.icsData} title="Interchain Security Validators Performance" 
            shortTitle="ICS Validators Performance" ics={true} />
          <InterchainValPerf interchainPerf={this.state.interchainPerf} title="Interchain Performance" ics={false} />
          {currentNetwork.details.ui.network.interchainMsg === true && (<p><br/>Interchain performance stats are displayed for only those networks where validator uses the same Keybase identity.</p>) }
        </Container>
        {/* {<div className="chartBg">{this.renderTools()}</div>} */}
        <ValNav address={this.state.val[currentNetwork.details.ui.identifierAttribute]} val={this.state.val} />

        {SPUtilities.getLastUpdatedGapSpecial(this.state.val.lastUpdatedGap, this.state.lastUpdated)}
      </div>
    );
  }
  
  renderValidatorPerfTables() {
    var columns = [
      {text: "Date", dataField: "title", sort: true, headerStyle: Utilities.width(35)},
      {text: "Signed", hidden: RespUtils.isMobileView(), dataField: "signed", sort: true,  sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(19)},
      {text: "Missed", hidden: RespUtils.isMobileView(), dataField: "missed", sort: true,  sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(19)},
      {text: "Sign %", dataField: "signedPer", formatter: MainUtils.signPerFormatterTitle, formatExtraData: this.state.val.poolId, sort: true,  sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(20)},
      {text: "Network Sign %", dataField: "networkAvgSignedPer", formatter: MainUtils.signPerFormatterTitle, formatExtraData: this.state.val.poolId, sort: true,  sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(20)},
      {text: "Missed Oracle", hidden: (currentNetwork.details.ui.validators.supportOracle != true), dataField: "oracleMissedVotes", formatExtraData: this, sort: true, headerStyle: Utilities.width(10)},
    ];

    const options = UIUtils.getPageOptionsSmall(this, 5);

    const defaultSorted = [{
      dataField: 'title',
      order: 'desc' // desc or asc
    }];
    // id="addressDetails" fsElementId="addressDetails" 
    return (
      <React.Fragment>
          <div className="chartBg">
            <ChartTitle title="Hourly Performance Stats" tip={tooltips.valDetailsCharts.hourlySigns} />
            <BootstrapTable keyField='title' data={ this.state.hourlyChartData }
              columns={ columns } options={options} defaultSorted={defaultSorted}
              condensed pagination={ paginationFactory(options) } />
          </div>
          <div className="chartBg">
            <ChartTitle title="Daily Performance Stats" tip={tooltips.valDetailsCharts.dailySigns} />
            <BootstrapTable keyField='title' data={ this.state.dailyChartData }
              columns={ columns } options={options} defaultSorted={defaultSorted}
              condensed pagination={ paginationFactory(options) } />
          </div>
      </React.Fragment>
    );
  }

  handleMore(value) {
    window.location = "/val/" + this.state.val[currentNetwork.details.ui.identifierAttribute] + "/" + value;
  }

  renderEvents() {
    var columns = [
      {text: "Event Time", dataField: "eventTime", formatter: SPUtilities.epochFormatter, sort: true, headerStyle: Utilities.width(35)},
      {text: "Description", dataField: "description", sort: true, headerStyle: Utilities.width(65)},
    ];
    const options = UIUtils.getPageOptionsSmall(this, 5);

    return (
      <React.Fragment>
        <ChartTitle title="Key Events" tip={tooltips.valDetails.events} />
        <BootstrapTable keyField='eventTime' data={ this.state.events }
                columns={ columns } options={options} 
                condensed pagination={ paginationFactory(options) } />
      </React.Fragment>
    );
  }

  renderLsmDelegations() {
    return (
      <React.Fragment>
        <ChartTitle title="LSM Delegations" tip={tooltips.lsm.delegations} />
        <table >
          <tbody>
            <tr>
              <td>Current Validator Bond: </td>
              <td> {CoinUtils.stakeFormatterRoundIfNeeded(this.state.val.valBond, this.state.val)} ${currentNetwork.details.app.token}</td>
            </tr>
            <tr>
              <td>Minimum Validator Bond: </td>
              <td> {CoinUtils.stakeFormatterRoundIfNeeded(this.state.val.minValBond, this.state.val)} ${currentNetwork.details.app.token}</td>
            </tr>
            <tr>
              <td>LSM Delegations: </td>
              <td> {CoinUtils.stakeFormatterRoundIfNeeded(this.state.val.lsmDelegations, this.state.val)} ${currentNetwork.details.app.token}</td>
            </tr>
            <tr>
              <td>Does Validator Bond Cover Delegation?: </td>
              <td> {LSUtils.formatHasSufficientValBondInd(this.state.val.hasEnoughValBond, this.state.val)}</td>
            </tr>
            <tr>
              <td>Remaining LSM Capacity: </td>
              <td> {LSUtils.formatValBondRemCapacity(this.state.val.valBondRemCapacity, this.state.val)} ${currentNetwork.details.app.token}</td>
            </tr>
            <tr>
              <td>Stride Delegation: </td>
              <td> {CoinUtils.stakeFormatterRoundIfNeeded(this.state.val.strdDelegation, this.state.val)} ${currentNetwork.details.app.token}</td>
            </tr>
            <tr>
              <td>Quicksilver Delegation: </td>
              <td> {CoinUtils.stakeFormatterRoundIfNeeded(this.state.val.qckDelegation, this.state.val)} ${currentNetwork.details.app.token}</td>
            </tr>
            <tr>
              <td>pSTAKE Delegation: </td>
              <td> {CoinUtils.stakeFormatterRoundIfNeeded(this.state.val.pstakeDelegation, this.state.val)} ${currentNetwork.details.app.token}</td>
            </tr>
            <tr>
              <td>Pryzm Delegation: </td>
              <td> {CoinUtils.stakeFormatterRoundIfNeeded(this.state.val.pryzmDelegation, this.state.val)} ${currentNetwork.details.app.token}</td>
            </tr>
            <tr>
              <td>Drop Delegation: </td>
              <td> {CoinUtils.stakeFormatterRoundIfNeeded(this.state.val.dropDelegation, this.state.val)} ${currentNetwork.details.app.token}</td>
            </tr>
          </tbody>
        </table>
      </React.Fragment>
    );
  }

  renderValidatorRunningSummary() {
    return (
      <React.Fragment>
        <ChartTitle title="Validator Performance - Running Summary" shortTitle="Validator Performance" tip={tooltips.valDetails.perfSummary} />
        <table >
          <thead>
            <tr>
              <th>Window</th>
              <th>Blocks Signed</th>
              <th>Blocks Missed</th>
              <th>Uptime %</th>
              {currentNetwork.details.ui.validators.supportOracle === true && (<th>Oracle %</th>)}
            </tr>
          </thead>

          <tbody>
            {this.state.params != null && (<tr>
              <td>Slashing Window</td>
              <td> </td>
              <td> {this.state.val.slashingWindowMissedBlocks && (<span>{this.state.val.slashingWindowMissedBlocks} (out of {CoinUtils.stakeFormatterRounded(this.state.params.signedBlocksWindow)})</span>)}</td>
              <td> {this.state.val.slashingWindowUptime && (<span>{SignFmtUtils.longPerSignedFmt(this.state.val.slashingWindowUptime)} (min needed {this.state.params.slashingMinPerUptime}%)</span>)}</td>
              {currentNetwork.details.ui.validators.supportOracle === true && (<td></td>)}
            </tr>)}
            <tr>
              <td>8 Hours</td>
              <td> {this.state.val.currentSigned}</td>
              <td> {this.state.val.currentMissed}</td>
              <td> {SignFmtUtils.longPerSignedFmt(this.state.val.currentPerSigned)}</td>
              {currentNetwork.details.ui.validators.supportOracle === true && (<td>{this.state.val.currentOraclePerSigned}</td>)}
            </tr>
            <tr>
              <td>1 Day</td>
              <td> {this.state.val.dailySigned}</td>
              <td> {this.state.val.dailyMissed}</td>
              <td> {SignFmtUtils.longPerSignedFmt(this.state.val.dailyPerSigned)}</td>
              {currentNetwork.details.ui.validators.supportOracle === true && (<td>{this.state.val.dailyOraclePerSigned}</td>)}
            </tr>
            <tr>
              <td>10 Days</td>
              <td> {this.state.val.midSigned}</td>
              <td> {this.state.val.midMissed}</td>
              <td> {SignFmtUtils.longPerSignedFmt(this.state.val.midPerSigned)}</td>
              {currentNetwork.details.ui.validators.supportOracle === true && (<td>{this.state.val.midOraclePerSigned}</td>)}
            </tr>
            <tr>
              <td>30 Days</td>
              <td> {this.state.val.longSigned}</td>
              <td> {this.state.val.longMissed}</td>
              <td> {SignFmtUtils.longPerSignedFmt(this.state.val.longPerSigned)}</td>
              {currentNetwork.details.ui.validators.supportOracle === true && (<td>{this.state.val.longOraclePerSigned}</td>)}
            </tr>
            <tr>
              <td>90 Days</td>
              <td> {this.state.val.historicalSigned}</td>
              <td> {this.state.val.historicalMissed}</td>
              <td> {SignFmtUtils.longPerSignedFmt(this.state.val.historicalPerSigned)}</td>
              {currentNetwork.details.ui.validators.supportOracle === true && (<td>{this.state.val.historicalOraclePerSigned}</td>)}
            </tr>
          </tbody>
        </table>
      </React.Fragment>
    );
  }

  renderVotes() {
    const headerSortingClasses = (column, sortOrder, isLastSorting, colIndex) => (
      sortOrder === 'asc' ? 'sorting-asc' : 'sorting-desc'
    );

    var columns = [
      {text: "#", dataField: "proposalId", sort: true, headerStyle: Utilities.respWidth(10, 7, 3), headerSortingClasses},
      {text: "Proposal", dataField: "title", formatter: ProposalUtils.renderProposalShort, sort: true, headerStyle: Utilities.respWidth(60, 50, 50), headerSortingClasses},
      // {text: "Type", hidden: RespUtils.isMobileView(), dataField: "proposalType", formatter: ProposalUtils.typeFormatterTable, formatExtraData: this, sort: true, headerStyle: Utilities.width(10), headerSortingClasses},
      {text: "Status", hidden: RespUtils.isMobileView(), dataField: "status", formatter: ProposalUtils.statusFormatterTable, formatExtraData: this, sort: true, headerStyle: Utilities.respWidth(20, 15, 10), headerSortingClasses},
      {text: "Vote", dataField: "voteOption", formatter: ProposalUtils.formatVote, formatExtraData: this, sort: true, headerStyle: Utilities.respWidth(20, 15, 15), headerSortingClasses},
    ];
    const options = UIUtils.getPageOptionsSmall(this, 5);

    return (
      <React.Fragment>
        <ChartTitle title="Votes" tip={tooltips.valDetails.votes} />
        <BootstrapTable keyField='proposalId' data={ this.state.votes }
                columns={ columns } options={options} 
                condensed pagination={paginationFactory(options)} />
      </React.Fragment>
    );
  }

  renderAddressDetails() {
    return (
      <React.Fragment>
        <ChartTitle title="Validator Addresses" tip={tooltips.valDetails.address} />
        <table>
          <tbody>
            <tr>
              <td>Validator Address: </td>
              <td> {MainUtils.getAddressLong(this.state.val.operatorAddress)}</td>
            </tr>
            {ChainUtils.isNotMatic() && <tr>
              <td>Hex Address: </td>
              <td> {MainUtils.getAddressLong(this.state.val.hexAddress)}</td>
            </tr>}
            <tr>
              <td>Consensus Node Address / Signer Address: </td>
              <td> {MainUtils.getAddressLong(this.state.val.consensusNodeAddress)}</td>
            </tr>
            <tr>
              <td>Wallet Address: </td>
              <td> {AddressUtils.addressFormatterSimpleWithLink(this.state.val.address)}</td>
            </tr>
            <tr>
              <td>Public Key: </td>
              <td> {MainUtils.getAddressLong(this.state.val.publicKey)}</td>
            </tr>
            {ValidatorUtils.isShow("identity") && <tr>
              <td>Identity: </td>
              <td> {this.state.val.identity}</td>
            </tr>}
            {/* {ChainUtils.isMatic() && MaticValidatorUtils.renderAddressDetails(this.state.val)} */}
          </tbody>
        </table>
      </React.Fragment>
    );
  }

  handleChartUpdateHourlyFrequency(frequencyHour) {
    this.prepareData(frequencyHour, this.state.frequencyDay);
  }

  handleChartUpdateDailyFrequency(frequencyDay) {
    this.prepareData(this.state.frequencyHour, frequencyDay);
  }

  renderOraclePerf(perfData) {
    return (<React.Fragment>
              <div class="chartBg">
                <BaseBarChart title="Hourly Oracle Missed Votes" tip={tooltips.valDetailsCharts.hourlyOracleMissedVotes}
                    chartVisualId="hourly-oracle-missed-votes" enableSave={true}
                    showVerticalLabel={true} xAxisValueAttr="title" valueAttr="oracleMissedVotes" showTotalLabel={false}
                    data={this.state.hourlyChartData} 
                    color={common.colors.chartColorBright} 
                    dataKey="title" columns={perfData} />
              </div>
              <div class="chartBg">
                <BaseBarChart title="Daily Oracle Missed Votes" tip={tooltips.valDetailsCharts.dailyOracleMissedVotes}
                    chartVisualId="daily-oracle-missed-votes" enableSave={true}
                    showVerticalLabel={true} xAxisValueAttr="title" valueAttr="oracleMissedVotes" showTotalLabel={false}
                    data={this.state.dailyChartData} 
                    color={common.colors.chartColorBright} 
                    dataKey="title" columns={perfData} />
              </div>
            </React.Fragment>);
  }
}

export default Validator;
