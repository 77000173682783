import React from 'react';

import currentNetwork from '../currentNetwork';
import RespUtils from '../util/RespUtils';

class StatsUtils extends React.Component {
  static getTotalStakeLabel() {
    if (currentNetwork.details.app.consumerChain === true) {
      return "Total Stake ($" + currentNetwork.details.app.stakedToken + ")"
    }
    return "Total Stake ($" + currentNetwork.details.app.token + ")";
  }

  static showDelegationStat() {
    if (currentNetwork.details.app.consumerChain === true) {
      return false;
    }

    if (currentNetwork.details.app.tendermint === true) {
      return true;
    }

    return false
  }

  static showUndelegationStat(basic) {
    if (currentNetwork.details.app.tendermint !== true || currentNetwork.details.app.consumerChain === true) {
      return false;
    }

    if (basic === true && RespUtils.isMobileView()) {
      //currentNetwork.details.app.tendermint === true && RespUtils.isNotMobileView()
      return false;
    }

    return true;
  }
}

export default StatsUtils;
