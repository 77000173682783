import React from 'react';

import LogoUtils from './LogoUtils';
import SPCalc from './SPCalc';

class ICSUtils extends React.Component {
  
    static formatICSPerf(cell, row) {
        if (!cell) {
            return "N/A";
        }

        return (
            <React.Fragment>
              <div>
                  {cell.map(function (chain) {
                      let uptime = chain.slashingWindowUptime;
                      if (chain.slashingWindowUptime) {
                        uptime = SPCalc.roundPrecision(chain.slashingWindowUptime, 2) + "% (>" + chain.slashingMinPerUptime + "%)"
                      } else if (chain.hasToValidate !== "true") {
                        return "";
                      } else {
                        uptime = "N/A";
                      }
                      // if (row.hasToValidate === "true") {
                      //     uptime += " - Low VP";
                      // }

                      return (<span>{LogoUtils.projectLogoWithCustomStyle(chain.logo, chain, "logoStyleWithName")} {uptime}&nbsp;</span>)
                  })}
              </div>
            </React.Fragment>
          );
    }

    static formatICSSignRate(cell, row) {
        // console.log(cell);
        if (!cell) {
            return "N/A";
        }

        return (
            <React.Fragment>
              <div>
                  {cell.map(function (chain) {
                      let uptime = chain.icsCurrentPerSigned;
                      if (chain.icsCurrentPerSigned || chain.icsCurrentPerSigned === 0) {
                        uptime = SPCalc.roundPrecision(chain.icsCurrentPerSigned, 2) + "%"
                      } else if (chain.hasToValidate !== "true") {
                        return "";
                      } else {
                        uptime = "N/A";
                      }
                        
                      return (<span key={chain.app}>{LogoUtils.projectLogoWithCustomStyle(chain.logo, chain, "logoStyleWithName")} {uptime}&nbsp;</span>)
                  })}
              </div>
            </React.Fragment>
          );
    }

    static sortIcsPerf(a, b, order, dataField, rowA, rowB) {
        // console.log(a);
        // console.log(b);
        // console.log(order);
        // console.log(dataField);
        // console.log(rowA);
        // console.log(rowB);
        let aMin = ICSUtils.getMinUptime(a, "slashingWindowUptime");
        let bMin = ICSUtils.getMinUptime(b, "slashingWindowUptime");
        // console.log("aMin: ", aMin, ", bMin: ", bMin);
        if (order === 'asc') {
          return bMin - aMin;
        }
        return aMin - bMin; // desc
    }

    static sortIcsPerSigned(a, b, order, dataField, rowA, rowB) {
        let aMin = ICSUtils.getMinUptime(a, "icsCurrentPerSigned");
        let bMin = ICSUtils.getMinUptime(b, "icsCurrentPerSigned");
        if (order === 'asc') {
          return bMin - aMin;
        }
        return aMin - bMin; // desc
    }

    static getMinUptime(icsPerf, dataField) {
        if (!icsPerf) return 0;

        // console.log(icsPerf);
        let minUptime;
        
        // console.log(dataField);
        for (let i=0; i < icsPerf.length; i++) {
            let chain = icsPerf[i];
            // console.log(chain);
            let value = chain[dataField];
            if (value) {
                if (!minUptime) {
                    minUptime = value;
                } else if (value < minUptime) {
                    minUptime = value;
                }
            }
        }

        if (!minUptime) {
            minUptime = 0;
        }

        return minUptime;
    }

    static formatHasToValidate(cell, row) {
      return cell;
    }
}

export default ICSUtils;
