import React from 'react';
import {Container} from 'react-bootstrap';

import ApiUtils from '../util/ApiUtils';
import currentNetwork from '../currentNetwork';
import LogoUtils from '../util/LogoUtils';
import PageHeader from '../components/PageHeader';
import SessionUtils from '../util/SessionUtils';
import TxsInline from './TxsInline';
import Utilities from '../util/Utilities';
import TxUtils from './TxUtils';
import UIUtils from '../util/UIUtils';


class NetworkTxs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      largeSize: null,
      coinStat: {},
      notification: {},
      category: "large",
      width: window.innerWidth,
      size: 10,
      isLoading: true,
    }
    this.updateDimensions = this.updateDimensions.bind(this);
    this.selectCategory = this.selectCategory.bind(this);    
  }

  updateDimensions() {
    this.setState({width: window.innerWidth});
  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions() {
    this.setState({width: window.innerWidth});
  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  async componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    SessionUtils.setNetwork(this);

    let category = this.state.category;
    if (this.props.match && this.props.match.params.category) {
      category = this.props.match.params.category;
    }

    await this.prepareData(category);
  }

  async prepareData(category) {
    var url = "listData?type=txs&size=50&category=" + category;
    // console.log(url);
    const allData = await ApiUtils.get(url);
    // console.log(allData);
    if (allData) {
      let data = Utilities.addIndex(allData["data"]);
      // console.log(allData["msgTypes"]);
      // "categorys": allData["msgTypes"],
      this.setState({"category": category, "data": data,  "largeSize": allData["largeSize"],
        "coinStat": allData["coinStat"], "notification": allData["notification"],
        isLoading: false});
    }
  }

  render() {
    SessionUtils.initNetwork(this, false);
    let loadMsg = UIUtils.getLoading(this);
    if (loadMsg) return loadMsg;

    return (
      <div>
        <PageHeader title={currentNetwork.details.app.appName + " - " + TxUtils.getTitle(this, "Recent Large Transactions")} 
          thisObj={this} customLogo={LogoUtils.getChainLogo} />

        {this.renderVestingAddressLink()}

        <Container fluid className="containerLayout container-fluid chartContainer">
          <div>
            <div class="chartBg">
              {TxUtils.renderTxFilters(this, "large")}
              <TxsInline pageSize="50" data={this.state.data} isWidget={false} showValName={true} 
                context="network" selectCategory={this.selectCategory} category={this.state.category} />
            </div>
          </div>
        </Container>
      </div>
    );
  }

  renderVestingAddressLink(){
    if (this.state.category === "vesting") {
      return (<a className="animLinkVisual" href={SessionUtils.getUrl("stats/addresses/vesting")}>Show Vesting Addresses</a>);
    }

    return "";
  }

  selectCategory(e) {
    // console.log("in network txs");
    // console.log(e);
    let category = e.target.value;
    // console.log(category);
    // this.props.selectCategory(e.target);
    window.location = SessionUtils.getUrl("/txs/" + category);
  }

}

export default NetworkTxs;
