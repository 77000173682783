import React from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import {Container} from 'react-bootstrap';
// import BootstrapTable from 'react-bootstrap-table-next';
import ReactTooltip from "react-tooltip";
// import paginationFactory from 'react-bootstrap-table2-paginator';
// import BaseTreeMap from '../charts/BaseTreeMap';

import ApiUtils from '../util/ApiUtils';
import tooltips from "../tooltips";
import MultiLineChart from '../charts/MultiLineChart';
import '../styles/section.css';
// import MainUtils from '../main/MainUtils';
// import CoinUtils from '../util/CoinUtils';
// import Utilities from '../util/Utilities';
// import ChartTitle from '../components/ChartTitle';
// import UIUtils from '../util/UIUtils';
// import BaseTreeMap from '../charts/BaseTreeMap';
// import BidirectionChangeAndCumulativeChart from '../charts/BidirectionChangeAndCumulativeChart';
// import DataUtils from '../util/DataUtils';
// import currentNetwork from '../currentNetwork';
import PageHeader from '../components/PageHeader';
import LogoUtils from '../util/LogoUtils';
import currentNetwork from '../currentNetwork';
import BaseBarChart from '../charts/BaseBarChart';
import TokenUtils from './TokenUtils';
import BidirectionalBarChart from '../charts/BidirectionalBarChart';
import SessionUtils from '../util/SessionUtils';
import TokenDistributionComparison from './TokenDistributionComparison';
import common from '../common';
import HolderDistributionRange from '../stats/HolderDistributionRange';
import ProjectUIUtils from '../projects/ProjectUIUtils';
import TokenSummaryStat from './TokenSummaryStat';
import SPUtilities from '../util/SPUtilities';

class TokenHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      minBalance: "N/A",
      networkHistory: [],
      tokenDetails: {},
      relatedTokens: [],
      error: null,
      notification: {},
      distribution: [],
      width: window.innerWidth,
      size: 10,
      responsive: true,
      isLoading: true,
      frequencyNetwork: "M",
      holderDistributionToken: [],
      holderDistributionUsd: [],
  }

    this.updateDimensions = this.updateDimensions.bind(this);
    this.handleChartUpdateFrequency = this.handleChartUpdateFrequency.bind(this);   
    this.renderTokenSwitch = this.renderTokenSwitch.bind(this);
    this.richlistLink = this.richlistLink.bind(this);
  }

  updateDimensions() {
    this.setState({width: window.innerWidth});
  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  async componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    SessionUtils.setNetwork(this);

    this.prepareData(this.state.frequencyNetwork)
  }

  async prepareData(frequencyNetwork) {
    let token = TokenUtils.getToken(this);
    // , currentNetwork.details.ui.richlist.defaultToken

    let url = "listData?type=tokenStats&useNewToken=true&frequencyNetwork=" + frequencyNetwork + "&tokenCode=" + encodeURIComponent(token);
    // console.log(url);

    const allData = await ApiUtils.get(url);
    // console.log("allData is:", allData);

    if (!allData) {
      this.setState({error: true, isLoading: false});
      return;
    }
  
    this.setState({"frequencyNetwork": frequencyNetwork, "notification": allData["notification"],
        "networkHistory": allData["networkHistory"], "tokenDetails": allData["tokenDetails"], "token": token,
        "holderDistributionToken": allData["holderDistributionToken"], 
        "holderDistributionUsd": allData["holderDistributionUsd"], "minBalance": allData["minBalance"], 
        "tokens": allData["tokens"], isLoading: false, "relatedTokens": allData["relatedTokens"]});
  }

  render() {
    if (this.state.isLoading) {
      return <div>Loading</div>;
    }

    let token = this.state.tokenDetails.token;
    // let holderDistTitle = "Holder Distributions in % (by " + token + " amount)";
    // let holderDistShortTitle = "Holder Distributions in % (by " + token + ")";

    let holderDistDollarTitle = "Holder Distributions in % (by $ value)";
    let holderDistDollarShortTitle = "Holder Distributions in % (by $)";

    var netHistoryColumns = [
      {text: "Date", dataField: "title", sort: true},
      {text: "Active Addresses (>0 balance)", dataField: "activeAccounts", sort: true},
      {text: "Net New Holders", dataField: "deltaActiveAccounts", sort: true},
    ];

    if (this.state.tokenDetails.stakingContract) {
      netHistoryColumns.push({text: "Total Stake", isHidden: (this.state.tokenDetails.stakingContract === null), dataField: "totalStake", sort: true});
      netHistoryColumns.push({text: "Unique Stakers", isHidden: (!this.state.tokenDetails.stakingContract), dataField: "uniqueDelegates", sort: true});
      netHistoryColumns.push({text: "Delta Unique Stakers", isHidden: (!this.state.tokenDetails.stakingContract), dataField: "deltaUniqueDelegates", sort: true});
    }

    return (
      <div>
        <PageHeader title={this.state.tokenDetails.name + " Stats "} thisObj={this} record={this.state.tokenDetails} additionalForTitle={this.richlistLink}
            additionalLink={this.renderTokenSwitch} disableStakeFormatter={true} logo={LogoUtils.tokenLogo}/>
        <ReactTooltip id="main" place="top" type="dark" effect="float" multiline={true} />
        <hr/>
        <TokenSummaryStat tokenDetails={this.state.tokenDetails} minBalance={this.state.minBalance} />
        <div className="compSubTitleLeft">The supply distribution may not be accurate due to missing categorization of LPs/Exchanges/SC/IBC assets. Submit <a href={common.urls.aliasUrl}>nick names</a> for such addresses for better reflection of token distribution. This chart/data is subject to manipulation. Please use other sources/mechanisms for wholesome assessment.</div>
        <Container fluid className="containerLayout container-fluid chartContainer">
          <div className="chartGridContainer">
            <TokenDistributionComparison token={currentNetwork.details.APP_KEY + "-" + this.state.tokenDetails.tokenId} tokenLabel={currentNetwork.details.APP_KEY + " - " + this.state.tokenDetails.name} />
            <HolderDistributionRange network={currentNetwork.details.APP_KEY} token={token} />
            {this.state.holderDistributionUsd != null && (<div className="chartBg" id="holderDistributionUsd">
              <BaseBarChart title={holderDistDollarTitle} shortTitle={holderDistDollarShortTitle} 
                tip={tooltips.networkCharts.stakerDistributionUsd} xAxis={"Amount in $"} yAxis="Percentage Holders" 
                showVerticalLabel={true} valueAttr="percentHolder" showTotalLabel={false} xAxisValueAttr="title"
                data={this.state.holderDistributionUsd}
                dataKey="title" columns={[
                  {text: "Amount in $", dataField: "title", sort: true},
                  {text: "Percentage Holders", dataField: "percentHolder", formatter: SPUtilities.percentFormatter, sort: true},
                ]} />
            </div>)}
            {this.state.tokenDetails.stakingContract != null && <div className="chartBg" id="totalStake">
              <MultiLineChart title="Total Stake" xAxis="Date" yAxis="Total Stake" adjustRange={true} 
                  showVerticalLabel={false} valueAttr={["totalStake"]} rangeAttr="totalStake" showTotalLabel={false} xAxisValueAttr="title"
                  data={this.state.networkHistory} tip={tooltips.networkCharts.totalStake} 
                  formatValues={true} fsElementId="totalStake"
                  dataKey="title" columns={netHistoryColumns} />
            </div>}
            <div className="chartBg" id="activeAddresses">
              <MultiLineChart title="Active Addresses (>0 balance)" xAxis="Date" yAxis="Active Addresses" adjustRange={true} 
                  showVerticalLabel={false} valueAttr={["activeAccounts"]} rangeAttr="activeAccounts" showTotalLabel={false} xAxisValueAttr="title"
                  data={this.state.networkHistory} tip={tooltips.networkCharts.activeAccounts} 
                  formatValues={true} fsElementId="activeAddresses"
                  dataKey="title" columns={netHistoryColumns} />
            </div>
            <div className="chartBg" id="newWallets">
              <BidirectionalBarChart title={"Net New Holders (>0 balance)"} tip={tooltips.networkCharts.newWallets}
                  xAxis="Date" yAxis="Addresses" hideAxisTitle={true} showVerticalLabel={false} 
                  valueAttr="deltaActiveAccounts" showTotalLabel={false} xAxisValueAttr="title"
                  formatValues={true} data={this.state.networkHistory}
                  dataKey="title" columns={netHistoryColumns} />
            </div>
            {this.state.tokenDetails.stakingContract != null && <div className="chartBg" id="uniqueDelegates">
              <MultiLineChart title="Unique Stakers" xAxis="Date" yAxis="Unique Stakers" adjustRange={true} 
                  showVerticalLabel={false} valueAttr={["uniqueDelegates"]} rangeAttr="uniqueDelegates" showTotalLabel={false} xAxisValueAttr="title"
                  data={this.state.networkHistory} tip={tooltips.networkCharts.uniqueDelegates} 
                  formatValues={true} fsElementId="uniqueDelegates" 
                  dataKey="title" columns={netHistoryColumns} />
                  {/* handleChart={this.handleChartUpdateNetwork} frequencies={["M", "Y", "All"]} frequency={this.state.frequencyNetwork}  */}
            </div>}
            {this.state.tokenDetails.stakingContract != null && <div className="chartBg" id="newDelegates">
              <BidirectionalBarChart title={"Net New Stakers (>0 balance)"} tip={tooltips.networkCharts.newDelegates}
                  xAxis="Date" yAxis="Unique Stakers" hideAxisTitle={true} showVerticalLabel={false} 
                  valueAttr="deltaUniqueDelegates" showTotalLabel={false} xAxisValueAttr="title"
                  formatValues={true} data={this.state.networkHistory}
                  dataKey="title" columns={netHistoryColumns} />
            </div>}
          </div>
        </Container>
        <p>* Supply Distribution is limited to top 5000 address per network for the selected criteria. If the chart gives error/timesout, try reducing the number of selected networks.</p>
      </div>
    );
  }
  
  handleChartUpdateFrequency(frequencyNetwork) {
    this.prepareData(frequencyNetwork);
  }

  renderTokenSwitch() {
    return TokenUtils.renderTokenSwitch(this, this.state.token);    
  }

  onTokenSelect(e) {
    // console.log(e);
    window.location = SessionUtils.getUrl("/tokens/" + encodeURIComponent(e.target.value) + "/history");
    // window.location = SessionUtils.getUrl("/tokens/" + e.token + "/history");
  }

  onOtherTokenSelect(e) {
    TokenUtils.navToOtherTokenPage(e);
  }

  richlistLink() {
    if (this.state.tokenDetails.tokenType === "ibc") {
      return "";
    }

    return (<div className="displayNetworkMedia"><button className="animLinkMenu animLink"><a 
      href={SessionUtils.getUrl("/tokens/" + encodeURIComponent(this.state.token))} title="richlist" 
      className="mediaCustom fas fa-list fa-lg"/></button>
      {ProjectUIUtils.formatNetworkMediaSmallNoIntegrations(this.state.tokenDetails.twitter, this.state.tokenDetails)}</div>);
  }
}

export default TokenHistory;
