import React, { Component } from 'react';
import InfoIcon from '@material-ui/icons/Info';
import RespUtils from '../util/RespUtils';
import SessionUtils from '../util/SessionUtils';

class Tips extends Component {
  render() {
    if (!this.props.tip) return "";

    let tip = RespUtils.isTabletView() ? this.props.tip.tipResp : this.props.tip.tip;

    return (
        <div className="tips">
            <a className="animLink" href={SessionUtils.getUrl("/guide")}><InfoIcon />&nbsp;&nbsp;<span dangerouslySetInnerHTML={{ __html: tip }} /></a>
        </div>
    );
  }
}

export default Tips;
