import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import ReactTooltip from "react-tooltip";
import ToolkitProvider from 'react-bootstrap-table2-toolkit';

import ValColumnSelector from "../validators/ValColumnSelector";

import "../validators/Validators.css";
import '../styles/section.css';
import '../styles/tables.css';
import '../styles/validator.css';
import Utilities from "../util/Utilities";
import RespUtils from "../util/RespUtils";

import MainUtils from "../main/MainUtils";
import CoinUtils from '../util/CoinUtils';
import currentNetwork from '../currentNetwork';
import SessionUtils from '../util/SessionUtils';
import ApiUtils from '../util/ApiUtils';
import Loading from '../components/Loading';
import NetworkHeader from '../network/NetworkHeader';
import LSUtils from './LSUtils';
import ValidatorUtils from '../validators/ValidatorUtils';
import ColumnUtils from '../util/ColumnUtils';

const key = "valbonds";

class ValBonds extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      coinStat: {},
      validators: [],
      notification: {"message": null},
      lastUpdated: "",
      isLoading: true,
      width: window.innerWidth,
    };
    this.updateDimensions = this.updateDimensions.bind(this);
    this.repaintData = this.repaintData.bind(this);
    this.getInitColumns = this.getInitColumns.bind(this);
  }

  updateDimensions() {
    this.setState({width: window.innerWidth});
  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  async componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    SessionUtils.initNetwork(this, false);

    let url = "listData?type=lsBondValidators";
    // console.log(url);

    const allData = await ApiUtils.get(url);
    // console.log(allData);
    if (!allData) {
      this.setState({error: true, isLoading: false});
      return;
    }

    let validators = ValidatorUtils.prepValidators(allData["data"]);
    let notification = allData["notification"];
    let coinStat = allData["coinStat"];
  
    this.setState({"validators": validators, "notification": notification, "coinStat": coinStat, "isLoading": false});
  }
  
  render() {
    if (this.state.isLoading) {
      return <Loading />;
    }

    const headerSortingClasses = (column, sortOrder, isLastSorting, colIndex) => (
      sortOrder === 'asc' ? 'sorting-asc' : 'sorting-desc'
    );
    
    let token = currentNetwork.details.app.token;

    var columns = [
      {text: "#", desc: "Index", hidden: this.isHidden("index"), dataField: "index", sort: true, align: "left", headerStyle: Utilities.respWidth(7, 5, 4), headerSortingClasses},
      {text: "VP Rank", desc: "VP Rank", hidden: this.isHidden("vpRank"), dataField: "vpRank", sort: true, align: "left", headerStyle: Utilities.respWidth(7, 7, 5), headerSortingClasses},
      {text: "", filter: textFilter({placeholder: "Name"}), desc: "Validator Name", hidden: this.isHidden("name"), dataField: "name", formatter: MainUtils.nameFormatterTableWithLogo, sort: true, headerStyle: Utilities.respWidth(50, 25, 20), headerSortingClasses},
      {text: "Status",  desc: "Validator Status", hidden: this.isHidden("status"), dataField: "status", formatter: MainUtils.statusFormatter, sort: true, headerStyle: Utilities.width(9), headerSortingClasses},
      {text: "Voting Power (VP)",  desc: ("Total " + token + " Staked with validator"), hidden: this.isHidden("votingPower"), dataField: "votingPower", formatter: CoinUtils.stakeFormatterRoundIfNeeded, sort: true, headerStyle: Utilities.width(10), headerSortingClasses},
      {text: "% Voting Power",  desc: "% of total voting power that is with the validator", hidden: this.isHidden("votingPowerPercentage"), dataField: "votingPowerPercentage", formatter: MainUtils.percentFormatter, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 12, 10), headerSortingClasses},
      {text: "Self Delegation",  desc: ("Total " + token + " Staked by the validator"), hidden: this.isHidden("selfBond"), dataField: "selfBond", formatter: CoinUtils.stakeFormatterRoundIfNeeded, sort: true, headerStyle: Utilities.width(10), headerSortingClasses},
      {text: "Self Delegation %",  desc: "% of total voting power that is self staked by the validator", hidden: this.isHidden("selfBondPercentage"), dataField: "selfBondPercentage", formatter: MainUtils.percentFormatter, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(10), headerSortingClasses},
      {text: Utilities.respLabel("Comm", "Comm", "Commission"), desc: "Current commission charged by validator", hidden: this.isHidden("commission"), dataField: "commission", formatter: MainUtils.getCommission, style: MainUtils.commissionStyleFormatter, sort: true, headerStyle: Utilities.respWidth(20, 12, 10), headerSortingClasses},
      {text: Utilities.respLabel("Val Bond", "Current Validator Bond", "Current Validator Bond"),  desc: ("Current validator bond for the validator"), hidden: this.isHidden("valBond"), dataField: "valBond", formatter: CoinUtils.stakeFormatterRoundIfNeeded, sort: true, headerStyle: Utilities.respWidth(15, 15, 10), headerSortingClasses},
      {text: "Recommended Bond",  desc: ("Recommended validator bond"), hidden: this.isHidden("recommendedValBond"), dataField: "recommendedValBond", formatter: CoinUtils.stakeFormatterRoundIfNeeded, sort: true, headerStyle: Utilities.respWidth(25, 20, 12), headerSortingClasses},
      {text: "Liquid Shares",  desc: ("Liquid Shares"), hidden: this.isHidden("liquidShares"), dataField: "liquidShares", formatter: CoinUtils.stakeFormatterRoundIfNeeded, sort: true, headerStyle: Utilities.width(10), headerSortingClasses},
      {text: "Remaining Liquid Shares Capacity",  desc: ("Remaining Liquid Shares Capacity"), hidden: this.isHidden("liquidSharesRemCapacity"), dataField: "liquidSharesRemCapacity", formatter: LSUtils.formatValBondRemCapacity, sort: true, headerStyle: Utilities.width(16), headerSortingClasses},
    ];

    return (
      <div>
        <NetworkHeader skipStats={true} coinStat={this.state.coinStat} title={"Validator Bonds ($" + currentNetwork.details.app.token + ")"} 
          thisObj={this} beta={false} />
        <p/>
        <ReactTooltip id="main" place="top" type="dark" effect="float" multiline={true} />
        <ToolkitProvider keyField="poolId" data={this.state.validators} columns={columns} columnToggle>
          {
            props => (
              <div id="mainTable">
                <div className="pageTitle">Validator Bonds</div>
                <ValColumnSelector settingKey={key} getInitColumns={this.getInitColumns}
                  { ...props.columnToggleProps } refreshParent={this.repaintData} 
                  fsElementId="mainTable" hideFullScreenLink={true} />
                <BootstrapTable { ...props.baseProps } 
                    filter={ filterFactory() }
                    condensed noDataIndication="No Data" />
              </div>
            )
          }
        </ToolkitProvider>
        <p/>
        <p>This page is updated every 1 hour. Recommended validator bond is based on validator's VP, factor of 250, and 15% bond need.
          For more information, visit <a className="animLinkVisual" href="https://docs.persistence.one/build/modules/liquid-staking-module">this website</a>
        </p>
      </div>
    );
  }

  getInitColumns() {
    let columnsConfig = {};
    if (RespUtils.isTabletView()) {
      columnsConfig = {
            index: false, vpRank: true, name: false, status: true, votingPower: true, 
            votingPowerPercentage: true, commission: true, valBond: false, 
            selfBond: true, selfBondPercentage: true, liquidShares: true, 
            liquidSharesRemCapacity: true, recommendedValBond: false};
    } else {
      columnsConfig = {
            index: false, vpRank: false, name: false, status: true, votingPower: false, 
            votingPowerPercentage: true, commission: false, valBond: false, 
            selfBond: true, selfBondPercentage: true, liquidShares: false, 
            liquidSharesRemCapacity: false, hasEnoughValBond: false, recommendedValBond: false};
    }

    // console.log("interchain val perf - getInit - columns config is: ", columnsConfig);
    return columnsConfig;
  }

  isHidden(dataField) {
    return ColumnUtils.isHidden(key, dataField, this.getInitColumns);
  }

  repaintData() {
    let validators = this.state.validators;
    let newValidators = Utilities.addIndex(validators, "poolId");
    this.setState({validators: newValidators});
  }

}

export default ValBonds;
