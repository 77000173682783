import React from 'react';
import { Container, Row } from 'react-bootstrap';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ReactTooltip from 'react-tooltip';

import CollapsibleNote from "../base/CollapsibleNote";
import Loading from '../components/Loading';
import "../styles/section.css";
import ApiUtils from '../util/ApiUtils';
import Utilities from '../util/Utilities';
import "../airdrops/Airdrops.css";
import Network from '../widget/Network';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import ValColumnSelector from '../validators/ValColumnSelector';
import BootstrapTable from 'react-bootstrap-table-next';
import UIUtils from '../util/UIUtils';
import NetworkUtils from './NetworkUtils';
import ProjectUIUtils from '../projects/ProjectUIUtils';
import CoinUtils from '../util/CoinUtils';
import Tips from '../components/Tips';
import MainUtils from '../main/MainUtils';
import StakeUtils from '../util/StakeUtils';
import Contact from '../base/Contact';
import common from '../common';
import RespUtils from '../util/RespUtils';
import GenFavUtils from '../util/GenFavUtils';
import PageHeader from '../components/PageHeader';
import MainNotes from '../main/MainNotes';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import DataUtils from '../util/DataUtils';
import UrlUtils from '../util/UrlUtils';
import viewData from '../viewData';
import ColumnUtils from '../util/ColumnUtils';


class Networks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      networks: [],
      orgNetworks: [],
      viewName: null,
      moreView: common.moreViews.hidden,
      isLoading: true,
    };
    this.renderNetworkList = this.renderNetworkList.bind(this);
    this.renderNetworkTiles = this.renderNetworkTiles.bind(this);
    this.favourite = this.favourite.bind(this);
    this.unfavourite = this.unfavourite.bind(this);
    this.repaintData = this.repaintData.bind(this);
    this.renderSwitch = this.renderSwitch.bind(this);
    this.handleFavChange = this.handleFavChange.bind(this);
    this.handleViewChange = this.handleViewChange.bind(this);
    this.filterData = this.filterData.bind(this);
    this.getInitColumns = this.getInitColumns.bind(this);
    this.toggleMoreView = this.toggleMoreView.bind(this);
  }

  async componentDidMount() {
    let url = "listData?type=networks&app=COM";
    // console.log(url);
    const allData = await ApiUtils.get(url);
    // console.log(allData);

    if (allData) {
      let notification = allData["notification"];
      let viewName = UrlUtils.getFragmentFromUrl();
      this.setState({"notification": notification, "orgNetworks": allData["networks"],
        "viewName": viewName});
      
      this.filterData(allData["networks"]);
    }

  }

  filterData(networks) {
    let newNetworks = UIUtils.addIndexAndFav(networks, "symbol");
    let finalData = GenFavUtils.filterData(this, newNetworks);

    this.setState({"networks": finalData, isLoading: false});
  }

  render() {
    if (this.state.isLoading) {
      return <Loading />;
    }

    return this.renderNetworkList();
  }

  renderNetworkList() {
    let loadMsg = UIUtils.getLoading(this);
    if (loadMsg) return loadMsg;

    const headerSortingClasses = (column, sortOrder, isLastSorting, colIndex) => (
        sortOrder === 'asc' ? 'sorting-asc' : 'sorting-desc'
    );
  
    var columns = [
        {text: "Fav", desc: "Favorite indicator", hidden: this.isHidden("fav"), dataField: "fav", sort: true, formatter: GenFavUtils.favoriteFormatter, formatExtraData: this, align: "left", headerStyle: Utilities.respWidth(4, 3, 2), headerSortingClasses},
        {text: "#", desc: "Index", hidden: this.isHidden("index"), dataField: "index", sort: true, align: "left", headerStyle: Utilities.respWidth(5, 4, 2), headerSortingClasses},
        // {text: "", desc: "logo", dataField: "logo", sort: true, formatter: LogoUtils.networkLogo, headerStyle: Utilities.respWidth(8, 6, 3)},
        {text: "", filter: textFilter({placeholder: "Network"}), desc: "Network Name", hidden: this.isHidden("name"), dataField: "name", formatter: NetworkUtils.networkFormatterWithLogo, sort: true, headerStyle: Utilities.respWidth(30, 20, 11), headerSortingClasses},
        {text: "Media", desc: "Media links for the network", hidden: this.isHidden("twitter"), dataField: "twitter", formatter: ProjectUIUtils.formatMediaSmall, sort: true, headerStyle:  Utilities.respWidth(30, 20, 10), headerSortingClasses},
        {text: "", filter: textFilter({placeholder: "Token"}), desc: "Token", hidden: this.isHidden("token"), dataField: "symbol", formatter: NetworkUtils.tokenFormatter, sort: true, headerStyle: Utilities.respWidth(15, 10, 5), headerSortingClasses},
        {text: Utilities.respLabel("MCap", "Market Cap", "Market Cap"), desc: "Market Cap of the network", hidden: this.isHidden("usdMcap"), dataField: "usdMcap", formatter: NetworkUtils.mcapFormatter, sort: true, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
        {text: "FDV", desc: "FDV", hidden: this.isHidden("fdvMcap"), dataField: "fdvMcap", formatter: NetworkUtils.mcapFormatter, sort: true, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
        {text: "Total Supply*", desc: "Total Supply", hidden: this.isHidden("totalSupply"), dataField: "totalSupply", formatter: CoinUtils.stakeFormatterRounded, sort: true, sortFunc: Utilities.sortNumeric, headerStyle:  Utilities.respWidth(20, 15, 9), headerSortingClasses},

        {text: "Total Stake*", desc: "Total Staked", hidden: this.isHidden("totalStake"), dataField: "totalStake", formatter: CoinUtils.stakeFormatterRounded, sort: true, sortFunc: Utilities.sortNumeric, headerStyle:  Utilities.respWidth(20, 15, 9), headerSortingClasses},
        {text: "Total Stake USD*", desc: "Total Staked USD", hidden: this.isHidden("totalStakeUsd"), dataField: "totalStakeUsd", formatter: NetworkUtils.mcapFormatter, sort: true, sortFunc: Utilities.sortNumeric, headerStyle:  Utilities.respWidth(20, 15, 9), headerSortingClasses},
        {text: "Percent Staked*", desc: "Percentage of circulation that is staked", hidden: this.isHidden("percentStaked"), dataField: "percentStaked", formatter: NetworkUtils.percentStakeFormatter, sort: true, sortFunc: Utilities.sortNumeric, headerStyle:  Utilities.respWidth(20, 15, 9), headerSortingClasses},
        {text: "Staking APR*", desc: "Expected Staking APR", hidden: this.isHidden("currentRewardRate"), dataField: "currentRewardRate", formatter: NetworkUtils.aprFormatter, sort: true, sortFunc: Utilities.sortNumeric, headerStyle:  Utilities.respWidth(20, 15, 9), headerSortingClasses},
        {text: "Stake % Change (7d)", desc: "Change in percentage of stake in 7d", hidden: this.isHidden("totalStakePC7d"), dataField: "totalStakePC7d", formatter: NetworkUtils.formatGrowth, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
        {text: "Stake % Change (30d)", desc: "Change in percentage of stake in 30d", hidden: this.isHidden("totalStakePC30d"), dataField: "totalStakePC30d", formatter: NetworkUtils.formatGrowth, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},

        {text: "Inactive Val Stake", desc: "Stake with inactive validators", hidden: this.isHidden("unbondedStake"), dataField: "unbondedStake", formatter: CoinUtils.stakeFormatterRounded, sort: true, sortFunc: Utilities.sortNumeric, headerStyle:  Utilities.respWidth(20, 15, 9), headerSortingClasses},
        {text: "Inactive Val Stake USD*", desc: "Stake (USD) with inactive validators", hidden: this.isHidden("unbondedStakeUsd"), dataField: "unbondedStakeUsd", formatter: NetworkUtils.mcapFormatter, sort: true, sortFunc: Utilities.sortNumeric, headerStyle:  Utilities.respWidth(20, 15, 9), headerSortingClasses},
        {text: "Inactive Val Stake %*", desc: "Percentage of stake with inactive validators", hidden: this.isHidden("unbondedStakePercent"), dataField: "unbondedStakePercent", formatter: NetworkUtils.percentStakeFormatter, sort: true, sortFunc: Utilities.sortNumeric, headerStyle:  Utilities.respWidth(20, 15, 9), headerSortingClasses},

        {text: Utilities.respLabel("Median Comm", "Median Commission", "Median Commission"), desc: "Median commission", hidden: this.isHidden("medianFee"), dataField: "medianFee", formatter: MainUtils.percentFormatter, sort: true, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
        {text: Utilities.respLabel("Weighted Median", "Weighted Median", "Weighted Median Commission"), desc: "Weighted Median commission", hidden: this.isHidden("weightedMedianCommission"), dataField: "weightedMedianCommission", formatter: MainUtils.percentFormatter, sort: true, headerStyle:  Utilities.respWidth(20, 15, 9), headerSortingClasses},
        {text: Utilities.respLabel("Addresses", "Addresses", "Addresses (>0 balance)"), desc: "Total number of addresses with >0 balance", hidden: this.isHidden("activeAccounts"), dataField: "activeAccounts", formatter: NetworkUtils.activeAccountFormatter, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
        {text: "Recent Avg Votes", desc: "Average voter count in latest 10 finished/non-spam proposals", hidden: this.isHidden("recentAvgVotes"), dataField: "recentAvgVotes", formatter: NetworkUtils.formatPropData, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
        {text: "Recent Max Votes", desc: "Max voter count among latest 10 finished/non-spam proposals", hidden: this.isHidden("recentMaxVotes"), dataField: "recentMaxVotes", formatter: NetworkUtils.formatPropData, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
        {text: "Unique Delegates", desc: "Total number of unique delegates", hidden: this.isHidden("uniqueDelegates"), dataField: "uniqueDelegates", formatter: NetworkUtils.uniqueDelegatesFormatter, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
        {text: "Unique Delegations", desc: "Total number of unique delegations", hidden: this.isHidden("uniqueDelegations"), dataField: "uniqueDelegations", sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
        {text: "Average Delegation (USD)", desc: "Average Delegation of all delegates for the validator", hidden: this.isHidden("averageDelegationUsd"), dataField: "averageDelegationUsd", formatter: StakeUtils.formatAverageDelegationUsd, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
        {text: "# of Validators", desc: "Number of Validators", hidden: this.isHidden("consensusValidators"), dataField: "consensusValidators", sort: true, sortFunc: Utilities.sortNumeric, headerStyle:  Utilities.respWidth(20, 15, 9), headerSortingClasses},
        {text: "Average Voting Power", desc: "Average Voting Power", hidden: this.isHidden("avgVotingPower"), dataField: "avgVotingPower", formatter: CoinUtils.usdFormatter, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
        {text: "Val Below Avg Voting Power", desc: "Validators below Average Voting Power", hidden: this.isHidden("valBelowAvgPower"), dataField: "valBelowAvgPower", sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
        {text: Utilities.respLabel("Nakamoto", "Nakamoto Coefficient", "Nakamoto Coefficient"), desc: "Network Coefficient", hidden: this.isHidden("nakamotoIndex"), dataField: "nakamotoIndex", formatter: NetworkUtils.formatNakamoto, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
        {text: Utilities.respLabel("Gini", "Gini Coefficient", "Gini Coefficient"), desc: "Gini Coefficient", hidden: this.isHidden("gini"), dataField: "gini", formatter: NetworkUtils.formatGini, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
        {text: Utilities.respLabel("Top 1 = Bottom N %", "Top 1 = Bottom N %", "Top 1 equals bottom N Val %"), desc: "Top 1 equals bottom N validators in percent", hidden: this.isHidden("oneEqualsNBottomValPercent"), dataField: "oneEqualsNBottomValPercent", sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
        {text: Utilities.respLabel("Top 1 = Bottom N #", "Top 1 = Bottom N #", "Top 1 equals bottom N Val #"), desc: "Top 1 equals bottom N validators in count", hidden: this.isHidden("oneEqualsNBottomVal"), dataField: "oneEqualsNBottomVal", sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
        {text: Utilities.respLabel("Top 100 Del Share", "Top 100 Del Share", "Top 100 Delegate Stake Share"), desc: "Share of total stake by top 100 delegates", hidden: this.isHidden("top100StakeShare"), dataField: "top100StakeShare", formatter: CoinUtils.percentFormatter, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
        {text: Utilities.respLabel("Pending Undelegations", "Pending Undelegations", "Pending Undelegations Count"), desc: "Pending Undelegations Count", hidden: this.isHidden("uniqueUndelegations"), dataField: "uniqueUndelegations", formatter: NetworkUtils.countFormatter, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
        {text: Utilities.respLabel("Undel Amt", "Undelegation Amount", "Pending Undelegation Amount"), desc: "Pending Undelegation Amount", hidden: this.isHidden("totalUndelegationAmount"), dataField: "totalUndelegationAmount", formatter: NetworkUtils.formatSpecialStakeFormatter, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
        {text: Utilities.respLabel("Undel Amt % of Total", "Undel Amt % of Total", "Undel Amt % of Total Supply"), desc: "Undelegation Amount % of Total Supply", hidden: this.isHidden("undelPercentOfTotalSupply"), dataField: "undelPercentOfTotalSupply", formatter: NetworkUtils.percentFormatter, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
        {text: Utilities.respLabel("Undel Amt % of Not Staked", "Undel Amt % of Not Staked", "Undel Amt % of Not Staked Supply"), desc: "Undelegation Amount % of Not Staked Supply", hidden: this.isHidden("undelPercentOfNotStakedSupply"), dataField: "undelPercentOfNotStakedSupply", formatter: NetworkUtils.percentFormatter, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
        {text: Utilities.respLabel("24h Txs", "24h Transactions", "24h Transactions"), desc: "24h Transactions Count", hidden: this.isHidden("txCount24h"), dataField: "txCount24h", formatter: NetworkUtils.format24hTxs, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
        {text: Utilities.respLabel("7d Txs", "7d Transactions", "7d Transactions"), desc: "7d Transactions Count", hidden: this.isHidden("txCount7d"), dataField: "txCount7d", formatter: NetworkUtils.format24hTxs, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
        {text: Utilities.respLabel("24h Active Addresses", "24h Active Addresses", "24h Active Addresses"), desc: "24h Active Addresses", hidden: this.isHidden("activeAddress24h"), dataField: "activeAddress24h", formatter: NetworkUtils.format24hAddresses, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},

        {text: "Net New Address (7d)", desc: "Net change in addresses in 7 days", hidden: this.isHidden("netNewWallets7d"), dataField: "netNewWallets7d", formatter: NetworkUtils.formatSpecialGrowth, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
        {text: "Net New Address (30d)", desc: "Net change in addresses in 30 days", hidden: this.isHidden("netNewWallets30d"), dataField: "netNewWallets30d", formatter: NetworkUtils.formatSpecialGrowth, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
        {text: Utilities.shortLabel("Address % Change (7d)", "Address % Change (7d)"), desc: "Change in percentage of active addresses in 7d", hidden: this.isHidden("activeAccountPC7d"), dataField: "activeAccountPC7d", formatter: NetworkUtils.formatSpecialGrowth, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
        {text: Utilities.shortLabel("Address % Change (30d)", "Address % Change (30d)"), desc: "Change in percentage of active addresses in 30d", hidden: this.isHidden("activeAccountPC30d"), dataField: "activeAccountPC30d", formatter: NetworkUtils.formatSpecialGrowth, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},

        {text: Utilities.shortLabel("Del Change (7d)", "Delegate Change (7d)"), desc: "Change in number of unique delegates in 7d", hidden: this.isHidden("netNewDelegates7d"), dataField: "netNewDelegates7d", formatter: NetworkUtils.formatGrowth, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
        {text: Utilities.shortLabel("Del Change (7d)", "Delegate Change (30d)"), desc: "Change in number of unique delegates in 30d", hidden: this.isHidden("netNewDelegates30d"), dataField: "netNewDelegates30d", formatter: NetworkUtils.formatGrowth, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
        {text: Utilities.shortLabel("Del % Change (7d)", "Delegate % Change (7d)"), desc: "Change in percentage of unique delegates in 7d", hidden: this.isHidden("uniqueDelegatePC7d"), dataField: "uniqueDelegatePC7d", formatter: NetworkUtils.formatGrowth, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
        {text: Utilities.shortLabel("Del % Change (30d)", "Delegate % Change (30d)"), desc: "Change in percentage of unique delegates in 30d", hidden: this.isHidden("uniqueDelegatePC30d"), dataField: "uniqueDelegatePC30d", formatter: NetworkUtils.formatGrowth, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},

        {text: Utilities.respLabel("Successful Txs", "Successful Txs", "Successful Transactions"), desc: "Successful Transactions in 24h", hidden: this.isHidden("successfulTxs"), dataField: "successfulTxs", formatter: NetworkUtils.format24hTxs, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
        {text: Utilities.respLabel("Failed Txs", "Failed Txs", "Failed Transactions"), desc: "Failed Transactions in 24h", hidden: this.isHidden("failedTxs"), dataField: "failedTxs", formatter: NetworkUtils.format24hTxs, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
        {text: Utilities.respLabel("Last Val Stake USD", "Last Val Stake USD", "Last Validator Stake USD"), desc: "Last Validator Stake in USD", hidden: this.isHidden("lastValStakeUsd"), dataField: "lastValStakeUsd", formatter: CoinUtils.moneyFormatterRoundDollar, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
        {text: Utilities.respLabel("Last Val Stake", "Last Val Stake", "Last Validator Stake"), desc: "Last Validator Stake", hidden: this.isHidden("lastValStake"), dataField: "lastValStake", sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
  
        {text: "Downtime Slash %", desc: "% of stake that gets slashed for downtime", hidden: this.isHidden("slashFractionDowntime"), dataField: "slashFractionDowntime", formatter: NetworkUtils.slashFormatter, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
        {text: "Slashing Window Blocks", desc: "Number of rolling blocks for slashing window", hidden: this.isHidden("signedBlocksWindow"), dataField: "signedBlocksWindow", formatter: NetworkUtils.signedBlocksFormatter, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
        {text: "Min Uptime for Slashing", desc: "Minimum Uptime needed to avoid downtime slashing", hidden: this.isHidden("slashingMinPerUptime"), dataField: "slashingMinPerUptime", formatter: NetworkUtils.slashingUptimeFormatter, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
        {text: "Unbonding Time", desc: "Number of days for unbonding to finish", hidden: this.isHidden("unbondingTime"), dataField: "unbondingTime", formatter: NetworkUtils.unbondingTimeFormatter, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},

        {text: "Analytics", desc: "Smart Stake Analytics", hidden: this.isHidden("analyticsWebsite"), dataField: "analyticsWebsite", sort: false, formatter: NetworkUtils.renderAnalytics, formatExtraData: this, headerStyle:  Utilities.respWidth(20, 15, 9)},
    ];

    columns = ColumnUtils.updateColumnsByView(this.state.viewName, viewData.networks, columns, this.getInitColumns(), this.state.moreView);

    const options = {
    };

    return (
        <div>
          <PageHeader title="Networks" thisObj={this} showFavIcon={false} disableStakeFormatter={true} hideSpecial={true}
            showRespIcon={false} additionalLink={this.renderSwitch}/>
          <p/>
          <ReactTooltip id="main" place="top" type="dark" effect="float" multiline={true} />
          <Tips tip={this.state.tip} />
          <ToolkitProvider keyField="symbol" data={this.state.networks} columns={columns} columnToggle>
            {
                props => (
                <div>
                    <ValColumnSelector settingKey={common.NETWORKS_KEY} getInitColumns={this.getInitColumns} 
                      { ...props.columnToggleProps } refreshParent={this.repaintData}
                      viewsData={viewData.networks} handleViewChange={this.handleViewChange} defaultView={this.state.viewName} 
                      supportMoreView = {ColumnUtils.isMoreViewNeeded(this.state.viewName, "networks")} moreView = {this.state.moreView} toggleMoreView={this.toggleMoreView}
                      />
                    <BootstrapTable options={options} { ...props.baseProps } 
                        filter={ filterFactory() } condensed noDataIndication="No records" />
                </div>
                )
            }
          </ToolkitProvider>
          <p/>
          <p>This page lists all the networks where Smart Stake operates as a validator on the mainnet.</p>
          <CollapsibleNote getScreenGuide={MainNotes.getNetworksNote} />
          <Contact />
        </div>
      );
  }

  renderNetworkTiles() {
    return (
        <div>
          {/* <PageHeader title="Networks" thisObj={this} /> */}
          <div className="pageTitle">Networks<span className="beta">BETA</span></div>
          <p />
  
          <ReactTooltip id="main" place="top" type="dark" effect="float" multiline={true} />
          <br/>
          <Container fluid>
            <Row>
                {this.state.networks.map(function (network) {
                    return (<Network network={network} thisObj={this} />)
                })}
            </Row>
          </Container>
        </div>
    );
  }

  getInitColumns() {
    let columnsConfig = {};
    if (RespUtils.isTabletView()) {
      columnsConfig = {
        fav: false, index:true, logoPath: false, name: false, twitter: true, token: true, fdvMcap: true,
        usdMcap: false, consensusValidators: true, percentStaked: true, currentRewardRate: true, 
        medianFee: true, weightedMedianCommission: true, uniqueDelegates: true, 
        avgVotingPower: true, valBelowAvgPower: true, gini: true, averageDelegationUsd: true, 
        analyticsWebsite: false, nakamotoIndex: true, activeAccounts: true, 
        top100StakeShare: true, undelPercentOfTotalSupply: true, undelPercentOfNotStakedSupply: true, 
        totalUndelegationAmount: true, uniqueUndelegations: true, totalSupply: true, totalStake: true, 
        oneEqualsNBottomVal: true, oneEqualsNBottomValPercent: true, txCount24h: true, activeAddress24h: true, 
        successfulTxs: true, failedTxs: true, lastValStake: true, lastValStakeUsd: true, totalStakeUsd: true,
        netNewWallets7d: true, netNewWallets30d: true, netNewDelegates7d: true, netNewDelegates30d: true, 
        uniqueDelegations: true, slashFractionDowntime: true, signedBlocksWindow: true, 
        slashingMinPerUptime: true, unbondingTime: true, 
        recentAvgVotes: true, recentMaxVotes: true, txCount7d: true, 
        unbondedStake: true, unbondedStakeUsd: true, unbondedStakePercent: true, 
        uniqueDelegatePC7d: true, uniqueDelegatePC30d: true, totalStakePC7d: true, 
        totalStakePC30d: true, activeAccountPC7d: true, activeAccountPC30d: true, 
      };
    } else {
      columnsConfig = {
        fav: false, index:false, logoPath: false, name: false, twitter: false, token: false, fdvMcap: true,
        usdMcap: false, consensusValidators: true, percentStaked: false, currentRewardRate: false, 
        medianFee: true, weightedMedianCommission: true, uniqueDelegates: true, 
        avgVotingPower: true, valBelowAvgPower: true, gini: true, averageDelegationUsd: true,
        analyticsWebsite: false, nakamotoIndex: true, activeAccounts: true, 
        top100StakeShare: true, undelPercentOfTotalSupply: true, undelPercentOfNotStakedSupply: true, 
        totalUndelegationAmount: true, uniqueUndelegations: true, totalSupply: true, totalStake: true, 
        oneEqualsNBottomVal: true, oneEqualsNBottomValPercent: true, txCount24h: false, activeAddress24h: false, 
        successfulTxs: true, failedTxs: true, lastValStake: true, lastValStakeUsd: true, totalStakeUsd: true,
        netNewWallets7d: true, netNewWallets30d: true, netNewDelegates7d: true, netNewDelegates30d: false, 
        uniqueDelegations: true, slashFractionDowntime: true, signedBlocksWindow: true, 
        slashingMinPerUptime: true, unbondingTime: true, 
        recentAvgVotes: true, recentMaxVotes: true, txCount7d: true, 
        unbondedStake: true, unbondedStakeUsd: true, unbondedStakePercent: true, 
        uniqueDelegatePC7d: true, uniqueDelegatePC30d: true, totalStakePC7d: true, 
        totalStakePC30d: true, activeAccountPC7d: true, activeAccountPC30d: true, 
      };
    }

    // console.log("initializing config in the context of view: ", this.state.viewName);
    if (this.state.viewName && this.state.viewName != "") {
      DataUtils.setAllToFalse(columnsConfig);
    }

    // console.log("columns config is: ", columnsConfig);
    return columnsConfig;
  }

  isHidden(dataField) {
    let result = ColumnUtils.isHiddenWithView(common.NETWORKS_KEY, dataField, this.getInitColumns, this.state.viewName);

    // console.log("final isHidden result for: ", dataField, " is ", result);
    return result;
  }

  repaintData() {
    let networks = this.state.networks;
    let newData = Utilities.addIndex(networks);
    this.setState({networks: newData});
  }

  getFavIdField() {
    return "symbol";
  }

  getFavStorageKey() {
    return "networks";
  }

  unfavourite(id) {
    GenFavUtils.unfavouriteById(id, this);
    // this.setState({isLoading: true});
    // this.filterData(this.state.orgNetworks);
  }

  favourite(id) {
    GenFavUtils.favouriteById(id, this);
    // this.setState({isLoading: true});
    // this.filterData(this.state.orgNetworks);
  }

  renderSwitch() {
    return GenFavUtils.showFavSwitch(this);
  }

  handleFavChange(newNetwork) {
    if (newNetwork === "all") {
      GenFavUtils.switchToAll(this);
    } else {
      GenFavUtils.switchToFav(this);
    }
    this.filterData(this.state.orgNetworks);
  }

  handleViewChange(newView) {
    if (this.state.viewName && this.state.viewName === "") {
      newView = null;
    }

    this.setState({"viewName": newView});
    UrlUtils.setUrlFragment(newView);
  }

  toggleMoreView() {
    ColumnUtils.toggleMoreView(this);
  }
}

export default Networks;
