import React from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import {Container} from 'react-bootstrap';
import ReactTooltip from "react-tooltip";

import tooltips from "../tooltips";
import MultiLineChart from '../charts/MultiLineChart';
import '../styles/section.css';
import './metrics.css';
import ApiUtils from '../util/ApiUtils';
import UrlUtils from '../util/UrlUtils';
import Dropdown from '../base/Dropdown';
import MetricsUtils from '../util/MetricsUtils';
// import SearchableDropdown from '../base/SearchableDropdown';


class CompareStats extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        frequencyNetwork: "Y",
        selectedMetric1: "gini",
        selectedMetric2: "nakamotoIndex",
        disabledMetrics: [],
        metricsData: [],
        coinStat: {},
    }

    this.updateDimensions = this.updateDimensions.bind(this);
    this.renderMetricsList = this.renderMetricsList.bind(this);   
    this.handleChartUpdateNetwork = this.handleChartUpdateNetwork.bind(this);
    this.setSelectedMetric1 = this.setSelectedMetric1.bind(this);
    this.setSelectedMetric2 = this.setSelectedMetric2.bind(this);
  }

  updateDimensions() {
    this.setState({width: window.innerWidth});
  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  async componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);

    UrlUtils.setUrlFragment("compare");
    this.setState({"isLoading": true});
    await this.updateMetricsData(this.state.frequencyNetwork);
  }

  render() {
    if (this.state.isLoading) {
      return <div>Loading</div>;
    }

    let columns = MetricsUtils.getMetricsColumns(this.state.selectedMetric1, this.state.selectedMetric2);
    return (
      <div>
        <ReactTooltip id="main" place="top" type="dark" effect="float" multiline={true} />
        <hr/>
        <Container fluid className="containerLayout container-fluid chartContainer">
          <div className="chartGridContainer">
            <div className="chartBg" id="metrics">
                <MultiLineChart title={"Metrics"} xAxis="Date" adjustRange={false} 
                    chartVisualId="metrics" enableSave={true} showLegend={true}
                    valueAttr={[this.state.selectedMetric1]} compareAttr={this.state.selectedMetric2} 
                    showVerticalLabel={false} xAxisValueAttr="title"
                    data={this.state.metricsData} tip={tooltips.networkCharts.compareMetrics} 
                    handleChart={this.handleChartUpdateNetwork} formatValues={true} fsElementId="metricsChart" 
                    frequencies={["M", "Y", "All"]} frequency={this.state.frequencyNetwork} 
                    dataKey="title" showAdditionalLink={true} renderAdditionalLink={this.renderMetricsList} 
                    columns={ columns } />
            </div>
          </div>
        </Container>
      </div>
    );
  }

  renderMetricsList() {
    // return (<MultiList selected={this.state.selectedMetrics} allItems={common.allCompareMetrics} 
    //     setSelected={this.setSelectedMetrics}/>);
    let availableMetrics = MetricsUtils.getAvailableMetrics(this.state.coinStat, this.state.disabledMetrics);
    // {/* <SearchableDropdown onSelect={this.setSelectedMetric1} values={availableMetrics} defaultValue={this.state.selectedMetric1} placeholder="1st metric" 
    //     isMulti={false} isSearchable={true} className="my-react-select-container" classNamePrefix="my-react-select"/>
    // &nbsp;&nbsp; */}
    // {/* <SearchableDropdown onSelect={this.setSelectedMetric2} values={availableMetrics} defaultValue={this.state.selectedMetric2} placeholder="2nd metric" className="metricsSelectDropdown" /> */}
  
    return (<React.Fragment>
            <Dropdown onSelect={this.setSelectedMetric1} values={availableMetrics} defaultValue={this.state.selectedMetric1} className="metricsDropdown" size="sm" />
            &nbsp;&nbsp;
            <Dropdown onSelect={this.setSelectedMetric2} values={availableMetrics} defaultValue={this.state.selectedMetric2} className="metricsDropdown" size="sm" />
        </React.Fragment>);

  }
 
  async setSelectedMetric1(e) {
    // console.log(e);
    this.setState({"selectedMetric1": e.target.value});
    // await this.updateMetricsData(e, this.state.selectedMetric2, this.state.frequencyNetwork);
  }

  async setSelectedMetric2(e) {
    // const item = data.find(item => item.value === inputValue);
    this.setState({"selectedMetric2": e.target.value});
    // await this.updateMetricsData(this.state.selectedMetric1, e, this.state.frequencyNetwork);
  }

  //   selectedMetric1, selectedMetric2, 
  async updateMetricsData(frequencyNetwork) {
    // console.log("selected networks are: ");
    // console.log(networkList);
    // let metrics = "";
    // for (let i = 0; i < inputMetrics.length; i++) {
    //   if (i !== 0) {
    //     metrics += ",";
    //   }
    //   //console.log(networkList[i]);
    //   metrics += inputMetrics[i]["value"];
    // }
    //  + "&metrics=" + metrics

    let url = "networkStats?type=compare&frequencyNetwork=" + frequencyNetwork;
    // console.log("url is: ", url);
    const allData = await ApiUtils.get(url);
    // console.log("allData is:", allData);

    let metricsData = allData["networkHistory"];
    
    // this.updateDistributionFilter(orgDistributions, this.state.selectedTopPercentSupply);
    this.setState({"metricsData": metricsData, "coinStat": allData["coinStat"], 
        "disabledMetrics": allData["disabledMetrics"], "isLoading": false, });
  }

  handleChartUpdateNetwork(frequencyNetwork) {
    // this.updateMetricsData(this.state.selectedMetrics, frequencyNetwork);
    this.setState({"frequencyNetwork": frequencyNetwork});
    this.updateMetricsData(frequencyNetwork);
  }

}

export default CompareStats;
