import React from 'react';
import {Container} from 'react-bootstrap';

import ApiUtils from '../util/ApiUtils';
import UIUtils from "../util/UIUtils";
import ChartTitle from '../components/ChartTitle';
import IntegrationUtils from '../util/IntegrationUtils';
import NetworkUtils from '../network/NetworkUtils';
import tooltips from '../tooltips';
import CoinUtils from '../util/CoinUtils';
import currentNetwork from '../currentNetwork';
import common from '../common';
import ValidatorUtils from '../validators/ValidatorUtils';
import StakeUtils from '../util/StakeUtils';
import ReactTooltip from 'react-tooltip';
import SPCalc from '../util/SPCalc';
import SessionUtils from '../util/SessionUtils';
import StatsUtils from './StatsUtils';
import UrlUtils from '../util/UrlUtils';

class NetworkStats extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      coinStat: {},
      token: null,
      params: null,
      notification: {},
      width: window.innerWidth,
      size: 10,
      isLoading: true,
    }
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  updateDimensions() {
    this.setState({width: window.innerWidth});
  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  async componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);

    let url = "networkStats?type=stats&frequencyNetwork=&frequencyBlock=";
    UrlUtils.setUrlFragment("stats");
    const allData = await ApiUtils.get(url);

    if (allData) {
      this.setState({"coinStat": allData["coinStat"], "notification": allData["notification"],
        "params": allData["params"], "token": currentNetwork.details.app.token,
        isLoading: false});
    }
  }

  render() {
    let loadMsg = UIUtils.getLoading(this);
    if (loadMsg) return loadMsg;
    // <NetworkHeader thisObj={this} coinStat={this.state.coinStat} full={true} skipHeader={true} params={this.state.params} />

    return (
      <div>
        <ReactTooltip id="main" place="top" type="dark" effect="float" multiline={true} />
        <Container fluid className="containerLayout container-fluid chartContainer">
          <div className="chartGridContainer">
            <div className="chartBg">{this.renderNetworkStats()}</div>
            <div className="chartBg">{this.renderGrowthStats()}</div>
            {StatsUtils.showDelegationStat() && <div className="chartBg">{this.renderStakingStats()}</div>}
            {/* <div className="chartBg">{this.renderDelegationStats()}</div>} */}
            <div className="chartBg">{this.renderDecentralizationStats()}</div>
            {StatsUtils.showUndelegationStat(false) && <div className="chartBg">{this.renderUndelegationStats()}</div>}
            {/* <div className="chartBg">{this.renderValidatorStats()}</div> */}
            <div className="chartBg">{this.renderUsageStats()}</div>
          </div>
        </Container>
      </div>
    );
  }

  renderNetworkStats() {
    return (
      <React.Fragment>
        <ChartTitle title="Network Stats" tip={tooltips.netstatsummary.networkStats} />
        <table>
          <tbody>
            <tr>
              <td>Total Supply</td>
              <td>{CoinUtils.stakeFormatterRoundIfNeeded(this.state.coinStat.totalSupply)} ${this.state.token}</td>
            </tr>
            <tr>
              <td>Circulating Supply</td>
              <td>{CoinUtils.stakeFormatterRoundIfNeeded(this.state.coinStat.circulatingSupply)} ${this.state.token}</td>
            </tr>
            <tr>
              <td>Block Rate</td>
              <td>{this.state.coinStat.blockRate + " seconds"}</td>
            </tr>
            {this.state.coinStat.usdPrice != null && <tr>
              <td>USD Price</td>
              <td>{IntegrationUtils.renderGecko4Label( "$" + this.state.coinStat.usdPrice, this.state.coinStat.geckoId, false)}</td>
            </tr>}
            {this.state.coinStat.usdMcap != null && <tr>
              <td>USD Mcap</td>
              <td>{NetworkUtils.mcapFormatter(this.state.coinStat.usdMcap, this.state.coinStat)}</td>
            </tr>}
            {this.state.coinStat.fdvMcap != null && <tr>
              <td>FDV</td>
              <td>{NetworkUtils.mcapFormatter(this.state.coinStat.fdvMcap, this.state.coinStat)}</td>
            </tr>}
          </tbody>
        </table>
      </React.Fragment>
    );
  }

  renderGrowthStats() {
    return (
      <React.Fragment>
        <ChartTitle title="Growth Stats" tip={tooltips.netstatsummary.growthStats} />
        <table>
          <thead>
            <tr>
              <th>Stat / Window</th>
              <th>Total</th>
              <th>24 hours</th>
              <th>7 days</th>
              <th>30 days</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Address / Change</td>
              <td>{this.state.coinStat.activeAccounts}</td>
              <td>{this.state.coinStat.deltaActiveAccounts}</td>
              <td>{this.state.coinStat.netNewWallets7d}</td>
              <td>{this.state.coinStat.netNewWallets30d}</td>
            </tr>
            <tr>
              <td>Address % Change</td>
              <td></td>
              <td>{this.state.coinStat.activeAccountPC1d}</td>
              <td>{this.state.coinStat.activeAccountPC7d}</td>
              <td>{this.state.coinStat.activeAccountPC30d}</td>
            </tr>
            {StatsUtils.showDelegationStat() && <tr>
              <td>Unique Delegates / Change</td>
              <td>{this.state.coinStat.uniqueDelegates}</td>
              <td>{this.state.coinStat.deltaUniqueDelegates}</td>
              <td>{this.state.coinStat.netNewDelegates7d}</td>
              <td>{this.state.coinStat.netNewDelegates30d}</td>
            </tr>}
            {StatsUtils.showDelegationStat() && <tr>
              <td>Unique Delegates % Change</td>
              <td></td>
              <td>{this.state.coinStat.uniqueDelegatePC1d}</td>
              <td>{this.state.coinStat.uniqueDelegatePC7d}</td>
              <td>{this.state.coinStat.uniqueDelegatePC30d}</td>
            </tr>}
            {StatsUtils.showDelegationStat() && <tr>
              <td>Total Stake % Change</td>
              <td></td>
              <td>{this.state.coinStat.totalStakePC1d}</td>
              <td>{this.state.coinStat.totalStakePC7d}</td>
              <td>{this.state.coinStat.totalStakePC30d}</td>
            </tr>}
            <tr>
              <td>Transactions</td>
              <td></td>
              <td>{this.state.coinStat.txCount24h}</td>
              <td>{this.state.coinStat.txCount7d}</td>
              <td>{this.state.coinStat.txCount30d}</td>
            </tr>
          </tbody>
        </table>
      </React.Fragment>
    );
  }

  renderStakingStats() {
    return (
      <React.Fragment>
        <ChartTitle title="Staking Stats" tip={tooltips.netstatsummary.stakingStats} />
        <table>
          <tbody>
            <tr>
              <td>{StatsUtils.getTotalStakeLabel()}</td>
              <td>{CoinUtils.stakeFormatterRoundIfNeeded(this.state.coinStat.totalStake)}</td>
            </tr>
            {this.state.coinStat.usdPrice != null && <tr>
              <td>Total Stake (USD)</td>
              <td>{"$" + CoinUtils.moneyFormatter(this.state.coinStat.totalStakeUsd)}</td>
            </tr>}
            {currentNetwork.details.ui.showApr === true && <tr>
              <td>Staking APR / APY (%)</td>
              <td>{ValidatorUtils.aprApyFormatter(this.state.coinStat.currentRewardRate, this.state.coinStat.weeklyCompoundingApy)}</td>
            </tr>}
            {currentNetwork.details.app.consumerChain != true && <tr>
              <td>Percent Staked</td>
              <td>{CoinUtils.percentFormatter(this.state.coinStat.percentStaked)}</td>
            </tr>}
            <tr>
              <td>Unique Delegates</td>
              <td><a href={SessionUtils.getUrl("/stats/delegates")} className="animLinkVisual">{this.state.coinStat.uniqueDelegates}</a></td>
            </tr>
            <tr>
              <td>Unique Delegations</td>
              <td><a href={SessionUtils.getUrl("/stats/delegations")} className="animLinkVisual">{this.state.coinStat.uniqueDelegations}</a></td>
            </tr>
            <tr>
              <td>Average Delegation</td>
              <td>{StakeUtils.formatAverageDelegation(this.state.coinStat.averageDelegation, this.state.coinStat)}</td>
            </tr>
            {this.state.coinStat.unbondedStake != null && this.state.coinStat.unbondedStake != 0 && (<tr>
              <td>Inactive Validator Stake</td>
              <td>{StakeUtils.formatInactiveValStake(this.state.coinStat.unbondedStake, this.state.coinStat)}</td>
            </tr>)}
          </tbody>
        </table>
      </React.Fragment>
    );
  }

  renderDecentralizationStats() {
    return (
      <React.Fragment>
        <ChartTitle title="Decentralization Metrics" tip={tooltips.netstatsummary.d14nStats} />
        <table>
          <tbody>
            <tr>
              <td>Gini Coefficient</td>
              <td><a href={common.urls.giniExplanation} className="animLink">{this.state.coinStat.gini}</a></td>
            </tr>
            <tr>
              <td>Nakamoto Index</td>
              <td><a href={common.urls.nakamotoIndex} className="animLink">{this.state.coinStat.nakamotoIndex}</a></td>
            </tr>
            <tr>
              <td>#1 VP Equals N Bottom Validators</td>
              <td>{this.state.coinStat.oneEqualsNBottomVal}</td>
            </tr>
            {StatsUtils.showDelegationStat() && <tr>
              <td>Top 100 delegate stake share</td>
              <td>{this.state.coinStat.top100StakeShare}%</td>
            </tr>}
            <tr>
              <td># of Consensus Validators</td>
              <td>{this.state.coinStat.consensusValidators}</td>
            </tr>
            <tr>
              <td>Validators below average Voting Power</td>
              <td>{this.state.coinStat.valBelowAvgPower}</td>
            </tr>
            <tr>
              <td>Last Active Validator Stake</td>
              <td>{this.state.coinStat.lastValStake + " $" + this.state.token + 
                    (this.state.coinStat.lastValStakeUsd != null ? ("/ $" + this.state.coinStat.lastValStakeUsd) : "")} </td>
            </tr>
            <tr>
              <td>Median/Weighted Median Commission</td>
              <td>{this.state.coinStat.medianFee + "% /" + this.state.coinStat.weightedMedianCommission + "%"}</td>
            </tr>
          </tbody>
        </table>
      </React.Fragment>
    );
  }

  renderUndelegationStats() {
    return (
      <React.Fragment>
        <ChartTitle title="Undelegation Stats" tip={tooltips.netstatsummary.undelegationStats} />
        <table>
          <tbody>
            <tr>
              <td>Unbonding Time</td>
              <td>{SPCalc.formatDaysLeft(this.state.params.unbondingTime)}</td>
            </tr>
            <tr>
              <td>Pending Undelegations (1/7 days/ all) ${this.state.token}</td>
              <td>{CoinUtils.stakeFormatterRounded(this.state.coinStat.undelAmt24Hrs) + "/" + 
                      CoinUtils.stakeFormatterRounded(this.state.coinStat.undelAmt7Days) + "/" + 
                      CoinUtils.stakeFormatterRounded(this.state.coinStat.totalUndelegationAmount)}</td>
            </tr>
            <tr>
              <td>Pending Undelegations Count</td>
              <td>{this.state.coinStat.uniqueUndelegations}</td>
            </tr>
            <tr>
              <td>Undelegation as % of Total Supply</td>
              <td>{this.state.coinStat.undelPercentOfTotalSupply}%</td>
            </tr>
            <tr>
              <td>Undelegation as % of Not Staked Supply</td>
              <td>{this.state.coinStat.undelPercentOfNotStakedSupply}%</td>
            </tr>
          </tbody>
        </table>
      </React.Fragment>
    );
  }

  renderUsageStats() {
    return (
      <React.Fragment>
        <ChartTitle title="Other Usage Stats" tip={tooltips.netstatsummary.otherUsageStats} />
        <table>
          <tbody>
            <tr>
              <td>24h Active Addresses</td>
              <td>{this.state.coinStat.activeAddress24h}</td>
            </tr>
            {this.state.coinStat.successfulTxs != null && <tr>
              <td>24h Successful Transactions</td>
              <td>{this.state.coinStat.successfulTxs}</td>
            </tr>}
            {this.state.coinStat.successfulTxs != null && <tr>
              <td>24h Failed Transactions</td>
              <td>{this.state.coinStat.failedTxs}</td>
            </tr>}
            {this.state.coinStat.totalTransactions != null && <tr>
              <td>Total Transactions</td>
              <td>{this.state.coinStat.totalTransactions}</td>
            </tr>}
          </tbody>
        </table>
      </React.Fragment>
    );
  }


}

export default NetworkStats;
