import React from 'react';
import {Container} from 'react-bootstrap';
import ReactTooltip from "react-tooltip";

import '../styles/section.css';
import '../styles/tables.css';
import '../styles/validator.css';
import tooltips from '../tooltips';
import ChartTitle from '../components/ChartTitle';
import FdpUtils from './FdpUtils';
import MainUtils from '../main/MainUtils';
import SPCalc from '../util/SPCalc';
import CoinUtils from '../util/CoinUtils';

class FdpValidatorInline extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <ReactTooltip id="main" place="top" type="dark" effect="float" multiline={true} />
        <Container fluid className="containerLayout container-fluid chartContainer">
          <div className="chartGridContainer">
            <div className="chartBg">{this.renderBasicDetails()}</div>
            <div className="chartBg">{this.renderAssessmentDetails()}</div>
          </div>
        </Container>
      </React.Fragment>
    );
  }

  renderBasicDetails() {
    return (
      <React.Fragment>
        <ChartTitle title="Validator Details" tip={tooltips.fdp.fdpval.basic} />
        <table>
          <tbody>
            <tr>
              <td>Validator Name: </td>
              <td> {MainUtils.nameFormatterWithLogoAndSize(this.props.fdpValidator.name, this.props.fdpValidator, false)}</td>
            </tr>
            <tr>
              <td>Assessment Title</td><td>{FdpUtils.fdpFormatter(this.props.fdp.periodLabel, this.props.fdp, null, this)}</td>
            </tr>
            <tr>
              <td>Voting Power</td><td>{CoinUtils.stakeFormatterRounded(this.props.fdpValidator.votingPower)}</td>
            </tr>
            <tr>
              <td>Voting Power Percentage</td><td>{MainUtils.percentFormatter(this.props.fdpValidator.votingPowerPercentage)}</td>
            </tr>
            {this.props.showDelegation && (<tr>
              <td>Foundation Delegation</td><td>{CoinUtils.stakeFormatterRounded(this.props.fdpValidator.foundationDelegation)}</td>
            </tr>)}
            <tr>
              <td>Validator Status</td><td>{this.props.fdpValidator.status}</td>
            </tr>
          </tbody>
        </table>
      </React.Fragment>
    );
  }

  renderAssessmentDetails() {
    let fdpColumns = FdpUtils.getFdpColumns(this.props.fdp, false);
    return (
      <React.Fragment>
        <ChartTitle title="Assessment Summary" tip={tooltips.fdp.fdpval.summary} />
        <table>
          <tbody>
            {FdpUtils.isToBeShown(fdpColumns, "metUptimeObj") && (<React.Fragment>
              <tr>
                <td>Met All Requirements?</td><td>{FdpUtils.objectiveFormatter(this.props.fdpValidator.metAllObj)}</td>
              </tr>
              <tr>
                <td>Minimum Uptime ({this.props.fdp.minUptime}%)</td><td>{FdpUtils.objectiveFormatter(this.props.fdpValidator.metUptimeObj)}</td>
              </tr>
            </React.Fragment>
            )}
            <tr>
              <td>Commission Range ({this.props.fdp.minCommission}% - {this.props.fdp.maxCommission}%)</td><td>{FdpUtils.objectiveFormatter(this.props.fdpValidator.metCommObj)}</td>
            </tr>
            {FdpUtils.isToBeShown(fdpColumns, "metSlashingFreqObj") && (
            <tr>
              <td>Maximum Slashing Frequency ({this.props.fdp.maxSlashingFreq})</td><td>{FdpUtils.objectiveFormatter(this.props.fdpValidator.metSlashingFreqObj)}</td>
            </tr>
            )}
            {FdpUtils.isToBeShown(fdpColumns, "metSlashingMaxDurObj") && (
            <tr>
              <td>Maximum Slashing Duration per occurrence ({SPCalc.formatTimeLeft(this.props.fdp.maxSlashingDuration)})</td><td>{FdpUtils.objectiveFormatter(this.props.fdpValidator.metSlashingMaxDurObj)}</td>
            </tr>
            )}
            {FdpUtils.isToBeShown(fdpColumns, "metUpgradeObj") && (
            // {FdpUtils.isExtraEnabled(this.props.fdp) && (
              <tr>
                <td>Upgrade Max Duration ({SPCalc.formatTimeLeft(this.props.fdp.upgradeMaxDuration)})</td><td>{FdpUtils.objectiveFormatter(this.props.fdpValidator.metUpgradeObj)}</td>
              </tr>
            )}
            {FdpUtils.isToBeShown(fdpColumns, "metExtraUptimeObj") && (
            // {FdpUtils.isExtraEnabled(this.props.fdp) && (
              <React.Fragment>
                <tr>
                  <td>Peggo Max Downtime in # of days ({this.props.fdp.extraMaxDowntime})</td><td>{FdpUtils.objectiveFormatter(this.props.fdpValidator.metExtraUptimeObj)}</td>
                </tr>
                <tr>
                  <td>Peggo Upgrade Max Duration ({SPCalc.formatTimeLeft(this.props.fdp.upgradeMaxDuration)})</td><td>{FdpUtils.objectiveFormatter(this.props.fdpValidator.metExtraUpgradeObj)}</td>
                </tr>
              </React.Fragment>
            )}
            {FdpUtils.isToBeShown(fdpColumns, "metGovernanceObj") && (
            <tr>
              <td>Minimum Governance Participation ({this.props.fdp.minGovParticipation}%)</td><td>{FdpUtils.objectiveFormatter(this.props.fdpValidator.metGovernanceObj)}</td>
            </tr>
            )}
            {FdpUtils.isToBeShown(fdpColumns, "metVPObj") && (
            // {FdpUtils.isLowVPEnabled(this.props.fdp) && (
              <tr>
                <td>Max Voting Power ({this.props.fdp.maxVP}%)</td><td>{FdpUtils.objectiveFormatter(this.props.fdpValidator.metVPObj)}</td>
              </tr>
            )}
          </tbody>
        </table>
      </React.Fragment>
    );
  }
}

export default FdpValidatorInline;
