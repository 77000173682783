import React from 'react';
import {Collapse} from 'react-collapse';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';

import CoinUtils from '../util/CoinUtils';
import common from '../common';
import StakeUtils from '../util/StakeUtils';
import numeral from 'numeral';
import MainUtils from '../main/MainUtils';
import SPCalc from '../util/SPCalc';
import UIUtils from '../util/UIUtils';
import Dropdown from '../base/Dropdown';
import RespUtils from '../util/RespUtils';
import LogoUtils from '../util/LogoUtils';
import constants from '../constants';

const specialDisable4Net = ["RUNE"];
const excludeNet4Advanced = ["ROSE", "IOTEX", "MATIC", "IOTX", "ONE"];
const excludeNet4NoSupport = ["ROSE", "IOTEX", "IOTX", "ONE"];
const excludeNet4PercentStake = ["STRD", "NTRN"];
const dataNotAvailable = "-";

class NetworkUtils extends React.Component {
  static uniqueDelegatesFormatter(cell, row) {
    if (excludeNet4NoSupport.includes(row.symbol)) {
      return dataNotAvailable;
    }

    return cell;
  }

  static formatPropData(cell, row) {
    if (!cell) {
      // if (!cell || (row.symbol === "ROSE" || row.symbol === "IOTX" || row.symbol === "MATIC")) {
      return "-";
    }

    return cell;
  }

  static activeAccountFormatter(cell, row) {
    if (excludeNet4Advanced.includes(row.symbol)) {
      return dataNotAvailable;
    }

    return cell;
  }

  static countFormatter(cell, row) {
    if (excludeNet4Advanced.includes(row.symbol) && !cell) {
      return dataNotAvailable;
    }

    return cell;
  }

  static formatGrowth(cell, row) {
    if ((excludeNet4Advanced.includes(row.symbol) || excludeNet4Advanced.includes(row.token)) && !cell) {
      return dataNotAvailable;
    }

    return StakeUtils.growthStyle(cell, false);
  }

  static percentFormatter(cell, row) {
    if (excludeNet4Advanced.includes(row.symbol) && !cell) {
      return dataNotAvailable;
    }

    if (!cell) {
      return cell;
    }

    let value = cell.toFixed(2);
    return (value + "%");
  }

  static stakeFormatterRounded(cell, row) {
    if (excludeNet4Advanced.includes(row.symbol) && !cell) {
      return dataNotAvailable;
    }

    // return SPUtilities.moneyFormatterPrecision(cell, 3);
    return numeral(cell).format('0.0a');
  }


  static networkFormatter(cell, row) {
    // return (<a href={SPUtilities.getUrl(row.website)} target="_blank" className="animLink">{cell}</a>);
    // let context = common.allNetworks[row.symbol];
    // let url = null;
    // if (context) {
    //   url = "/" + context + "/";
    // } else {
    //   url = row.analyticsWebsite;
    // }

    return (<a href={NetworkUtils.getNetworkLink(row.symbol, row.analyticsWebsite)} 
      className="animLinkVisual">{cell}</a>);
  }


  static networkFormatterWithLogo(cell, row) {
    const onClick = e => e.stopPropagation();
    return (<span onClick={ onClick }><a className="animLinkTable"
        href={NetworkUtils.getNetworkLink(row.symbol, row.analyticsWebsite)}>{
          LogoUtils.projectLogoBySize(row.logo, row, false, "Network logo for " + row.name)}&nbsp;&nbsp;{cell}</a></span>);
  }


  static getNetworkLink(symbol, website) {
    // return (<a href={SPUtilities.getUrl(row.website)} target="_blank" className="animLink">{cell}</a>);
    let context = common.allNetworks[symbol];
    let url = null;
    if (context) {
      url = NetworkUtils.getContextUrl(context) + "/";
    } else if (website) {
      url = website;
    }

    return url;
  }

  static getNetworkResourceLink(symbol, website, resource) {
    let context = common.allNetworks[symbol];
    let url = null;
    if (context) {
      url = NetworkUtils.getContextUrl(context);
    } else if (website) {
      url = website;
    }

    if (url && resource != null) {
      url += "/" + resource
    }

    return url;
  }

  static getNetworkTokensLink(symbol) {
    let context = common.allNetworks[symbol];
    let url = null;

    if (context && constants[context] && constants[context].app.tokens === true) {
      url = "/" + context + "/tokens";
    }

    return url;
  }

  static getTendermintResourceLink(symbol, website, resource) {
    let context = common.allNetworks[symbol];
    let url = null;
    if (context && constants[context] && constants[context].app.tendermint === true) {
      url = "/" + context + "/" + resource;
    // } else if (website) {
    //   url = website;
    }

    return url;
  }

  static tokenFormatter(cell, row) {
    return "$" + cell;
  }

  static renderAnalytics(analyticsWebsite, row) {
    if (!analyticsWebsite || analyticsWebsite === "") {
      return dataNotAvailable;
    }
  
    let size = RespUtils.isMobileView() ? "fa-sm" : "fa-md";
    let analyticsClassChart = "mediaCustom fas fa-chart-pie " + size;
    let validatorsClassChart = "mediaCustom fa fa-user " + size;
    let tokensClassChart = "mediaCustom fa fa-coins " + size;

    let analyticsUrl = NetworkUtils.getNetworkResourceLink(row.symbol, row.analyticsWebsite);
    let statsUrl = NetworkUtils.getNetworkResourceLink(row.symbol, row.analyticsWebsite, "stats");
    let tokensUrl = NetworkUtils.getNetworkTokensLink(row.symbol);
    let compareUrl = NetworkUtils.getTendermintResourceLink(row.symbol, row.analyticsWebsite, "stats#compare");

    return (<span>
              <button className="animLinkMedia animLinkMenu animLink"><a href={analyticsUrl} title="Validators" 
                className={validatorsClassChart} /></button>
              <button className="animLinkMedia animLinkMenu animLink"><a href={statsUrl} title="Stats" 
                className={analyticsClassChart} /></button>
              {compareUrl && <button className="animLinkMedia animLinkMenu animLink"><a href={compareUrl} title="Compare Stats" 
                ><CompareArrowsIcon className="mInfoIcon" style={{fill: "white"}} fontSize={"medium"} /></a></button>}
              {tokensUrl && <button className="animLinkMedia animLinkMenu animLink"><a href={tokensUrl} title="Tokens" 
                className={tokensClassChart} /></button>}
      </span>);
  }


  static renderButton(label, link, newWindow) {
      if (newWindow) {
        return (
            <span className="delegateContainer centerAlign">
                <div className="miniStat">
                    <div className="miniStatTitle">
                    <span><a href={link}
                        target="_blank"><button className="animLink animLink linkBorder" id={label}
                        >{label}</button></a></span>
                    </div>
                </div>
            </span>
        );
      }

      return (
            <span className="delegateContainer centerAlign">
                <div className="miniStat">
                    <div className="miniStatTitle">
                    <span><a href={link}><button className="animLink animLink linkBorder" id={label}
                        >{label}</button></a></span>
                    </div>
                </div>
            </span>
        );
  }

  static formatGini(cell, row) {
    return <a className="animLink" href={common.urls.giniExplanation}>{CoinUtils.btcValueFormatter(cell)}</a>;
  }

  static formatNakamoto(cell, row) {
    return <a className="animLink" href={common.urls.nakamotoIndex}>{cell}</a>;
  }

  // static top1EqualsNFormatter(cell, row) {
  //   let percentVal = CoinUtils.percentFormatter(cell * 100/row.consensusValidators, row);

  //   return percentVal;
  // }
  static getDisclaimer() {
    return (<Collapse isOpened={true}>
          <ul>
            <li>This is an experimental dashboard that provides a view into decentralization related metrics for chains that Smart Stake participates in as a validator and has relevant data available. </li>
            <li>It is anticipated that the metrics will evolve over time based on community feedback/discussions.</li>
            <li>The metrics presented here are not a verdict on the state of decentralization in any network.</li>
            <li>Decentralization is a long journey and the metrics covered here are a small subset.</li>
            <li>Some of the metrics provided here have direct correlation with the degree of decentralization in the network.</li>
            <li>At the same time, some other metrics are captured to visualize how general network usage metric may (or may not) correlate with decentralization metrics</li>
            <li>What's next? build more metrics, finetune the existing metrics, capture/provide visualizations into how the metrics change over time.</li>
          </ul>
        </Collapse>);
  }

  static getScreenGuide() {
    return (<Collapse isOpened={true}>
          <ul><u>Decentralization Metrics:</u>
            <li>Gini Coefficient - Gini coefficient is a metric used to describe the level of inequality of stake between all validators. <a href={common.urls.giniExplanation} target="_blank">Visit this article for more on Gini.</a></li>
            <li>Nakamoto Coefficient - Nakamoto coefficient is the number of top validators, holding together 34% of staked coins. Measures the prevalence of top validators comparing to the whole. <a href={common.urls.nakamotoIndex} target="_blank">Visit this article for more on Nakamoto</a>.</li>
            <li>Validators with majority power - The smallest number of validators that together control 67% of the voting power. Higher number is better from decentralization perspective.</li>
            <li>Validators below Average Voting Power - Numbers of validators below average voting power. Lower number (in comparison to total number of validators) is better. Higher number is better from decentralization perspective.</li>
            <li>Average Voting Power - Average of the voting power of each validator on the network</li>
            <li>Number of Validators - Total number of validators that are participating in the consensus in each chain. It does not include validators that are not considered elected/bonded.</li>
          </ul>
          <ul><u>Network Metrics</u> (not directly related to decentralization):
            <li>*Percent Staked - Percentage of the supply that is locked in staking. Some of the networks have non-circulating supply locked in staking. To keep the metric simple across all chains, the metric uses Total Supply in the calculation.</li>
            <li>Staking APR - expected returns from staking on the network. Based on chain specific methods of APR calculations. There may be some inaccuracies depending upon the specifics of a chain.</li>
            <li>Median Commission - Median value of the commission charged by all validators on the network.</li>
            <li>Weighted Median Commission - Weighted median of the commission charged by all validators.</li>
            <li>Unique Delegates - Total number of unique delegates/stakers.</li>
            <li>Unique Delegations - Total number of unique delegations from the delegates/stakers.</li>
          </ul>
        </Collapse>);
  }

  static slashFormatter(cell, row) {
    if (!cell && cell != 0) return dataNotAvailable;

    return MainUtils.percentFormatterDecimal(cell * 100, 3);
  }

  static slashingUptimeFormatter(cell, row) {
    if (!cell) return row.slashingMinUptimeDesc;

    return MainUtils.percentFormatter(cell, row);
  }

  static signedBlocksFormatter(cell, row) {
    if (!cell) return row.signedBlocksWindowDesc;

    return cell;
  }

  static unbondingTimeFormatter(cell, row) {
    if (!cell) return row.unbondingTimeDesc;

    return SPCalc.formatTimeLeft(cell);
  }

  static percentStakeFormatter(cell, row) {
    if (excludeNet4PercentStake.includes(row.symbol)) {
      return dataNotAvailable;
    }

    return CoinUtils.percentFormatter(cell);
  }

  static mcapFormatter(cell, row) {
    console.log(cell);
    if (!cell || cell === 0) return dataNotAvailable;

    return UIUtils.coingeckoLinkFromMcap(cell, row);
  }  

  static aprFormatter(cell, row) {
    if (!cell) return dataNotAvailable;

    return CoinUtils.aprFormatter(cell, row);
  }  
  
  static renderTokenSwitch(thisObj, token) {
    if (!thisObj.state.relatedTokens || thisObj.state.relatedTokens.length <= 1) return "";

    return (<Dropdown onSelect={thisObj.onOtherTokenSelect} values={thisObj.state.relatedTokens} 
        customValue={"Interchain"} size="lg" className="moreNetworks" />);
  }

  static format24hTxs(cell, row) {
    if (specialDisable4Net.includes(row.symbol)) {
      return "-";
    }
    
    return NetworkUtils.countFormatter(cell, row);
  }

  static format24hAddresses(cell, row) {
    if (specialDisable4Net.includes(row.symbol)) {
      return "-";
    }

    return NetworkUtils.countFormatter(cell, row);
  }

  static formatSpecialGrowth(cell, row) {
    if (specialDisable4Net.includes(row.symbol)) {
      return "-";
    }

    return NetworkUtils.formatGrowth(cell, row);
  }

  static formatSpecialStakeFormatter(cell, row) {
    if (specialDisable4Net.includes(row.symbol)) {
      return "-";
    }

    return NetworkUtils.stakeFormatterRounded(cell, row);
  }

  static getContextUrl(context) {
    let contextUrl = context;
    if (context && !context.startsWith("https")) {
      contextUrl = "https://analytics.smartstake.io/" + context;
    } 

    return contextUrl;
  }
}

export default NetworkUtils;
