import React from 'react';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import RespUtils from '../util/RespUtils';
import SPUtilities from '../util/SPUtilities';
import IntegrationUtils from '../util/IntegrationUtils';
import { maxMediaLinksMobile, maxIntegrationLinksMobile } from '../common';

class ProjectUIUtils extends React.Component {
    static formatMedia(cell, row) { 
        return ProjectUIUtils.formatMediabySize(cell, row, false, false, false);
    }

    static formatTokenMediaSmall(cell, row) {
        return ProjectUIUtils.formatMediabySize(cell, row, true, false, true);
    }
    
    static formatMediaSmall(cell, row) {
        return ProjectUIUtils.formatMediabySize(cell, row, true, false, true);
    }
    
    static formatNetworkMediaSmall(cell, row) {
        return ProjectUIUtils.formatMediabySize(cell, row, true, true, false);
    }
    
    static formatNetworkMediaSmallNoIntegrations(cell, row) {
        return ProjectUIUtils.formatMediabySize(cell, row, true, false, false);
    }
    
    static formatMediabySize(cell, row, smallInd, includeIntegrations, includeCoinGecko) { 
        if (typeof smallInd === 'undefined') {
            smallInd = RespUtils.isMobileView();
        }
        
        let website = false;
        let websiteLink = "";
        let twitter = false;
        let twitterLink = "";
        let telegram = false;
        let telegramLink = "";
        let discord = false;
        let discordLink = "";
        let medium = false;
        let mediumLink = "";
        let github = false;
        let githubLink = "";
        let whitepaper = false;
        let whitepaperLink = "";
        let youtube = false;
        let youtubeLink = "";
        let instagram = false;
        let instagramLink = "";
        let linkedin = false;
        let linkedinLink = "";
        let facebook = false;
        let facebookLink = "";
        let mediaLinksPresent = 0;
        let integrationLinksPresent = 0;
        let maxMediaLinks = RespUtils.isMobileView() ? maxMediaLinksMobile : 20;
        
        if (row.website && row.website !== "" && mediaLinksPresent < maxMediaLinks) {
            website = true;
            websiteLink = SPUtilities.getUrl(row.website);
            mediaLinksPresent++;
        }
        if (row.twitter && row.twitter !== "" && mediaLinksPresent < maxMediaLinks) {
            twitter = true;
            twitterLink = "https://twitter.com/" + row.twitter;
            mediaLinksPresent++;
        }
        if (row.telegram && row.telegram !== "" && mediaLinksPresent < maxMediaLinks) {
            telegram = true;
            telegramLink = "https://t.me/" + row.telegram;
            mediaLinksPresent++;
        }
        if (row.discord && row.discord !== "" && mediaLinksPresent < maxMediaLinks) {
            discord = true;
            discordLink = SPUtilities.getUrl(row.discord);
            mediaLinksPresent++;
        }
        if (row.medium && row.medium !== "" && mediaLinksPresent < maxMediaLinks) {
            medium = true;
            mediumLink = SPUtilities.getUrl(row.medium);
            mediaLinksPresent++;
        }
        if (row.github && row.github !== "" && mediaLinksPresent < maxMediaLinks) {
            github = true;
            githubLink = "https://github.com/" + row.github;
            mediaLinksPresent++;
        }
        if (row.whitepaper && row.whitepaper !== "" && mediaLinksPresent < maxMediaLinks) {
            whitepaper = true;
            whitepaperLink = SPUtilities.getUrl(row.whitepaper);
            mediaLinksPresent++;
        }
        if (row.youtube && row.youtube !== "" && mediaLinksPresent < maxMediaLinks) {
            youtube = true;
            youtubeLink = SPUtilities.getUrl(row.youtube);
            mediaLinksPresent++;
        }
        if (row.instagram && row.instagram !== "" && mediaLinksPresent < maxMediaLinks) {
            instagram = true;
            instagramLink = "https://www.instagram.com/" + row.instagram;
            mediaLinksPresent++;
        }
        if (row.linkedin && row.linkedin !== "" && mediaLinksPresent < maxMediaLinks) {
            linkedin = true;
            linkedinLink = "https://www.linkedin.com/" + row.linkedin;
            mediaLinksPresent++;
        }
        if (row.facebook && row.facebook !== "" && mediaLinksPresent < maxMediaLinks) {
            facebook  = true;
            facebookLink = "https://www.facebook.com/" + row.facebook;
            mediaLinksPresent++;
        }

        if (smallInd) {
            return (
                <span>
                    {website && <a href={websiteLink} target="_blank" title="website" className="mediaCustom fa fa-globe fa-md"/>}
                    {twitter && <a href={twitterLink} target="_blank" title="twitter" className="mediaCustom fab fa-twitter fa-md"/>}
                    {telegram && <a href={telegramLink} target="_blank" title="telegram" className="mediaCustom fab fa-telegram fa-md"/>}
                    {discord && <a href={discordLink} target="_blank" title="discord" className="mediaCustom fab fa-discord fa-md"/>}
                    {youtube && <a href={youtubeLink} target="_blank" title="youtube" className="mediaCustom fab fa-youtube fa-md"/>}
                    {medium && <a href={mediumLink} target="_blank" title="medium" className="mediaCustom fab fa-medium fa-md"/>}
                    {github && <a href={githubLink} target="_blank" title="github" className="mediaCustom fab fa-github fa-md"/>}
                    {instagram && <a href={instagramLink} target="_blank" title="instagram" className="mediaCustom fab fa-instagram fa-md"/>}
                    {linkedin && <a href={linkedinLink} target="_blank" title="linkedin" className="mediaCustom fab fa-linkedin-in fa-md"/>}
                    {facebook && <a href={facebookLink} target="_blank" title="facebook" className="mediaCustom fab fa-facebook-f fa-md"/>}
                    {whitepaper && <a href={whitepaperLink} target="_blank" title="whitepaper" className="mediaCustom fa fa-sticky-note fa-md"/>}
                    {includeIntegrations && IntegrationUtils.renderIntegrations(row)}
                    {includeCoinGecko && IntegrationUtils.renderGecko4Token(row, smallInd)}
                </span>
            );
        } else {
            return (
                <span>
                    {website && <a href={websiteLink} target="_blank" title="website" className="mediaCustom fa fa-globe fa-lg"/>}
                    {twitter && <a href={twitterLink} target="_blank" title="twitter" className="mediaCustom fab fa-twitter fa-lg"/>}
                    {telegram && <a href={telegramLink} target="_blank" title="telegram" className="mediaCustom fab fa-telegram fa-lg"/>}
                    {discord && <a href={discordLink} target="_blank" title="discord" className="mediaCustom fab fa-discord fa-lg"/>}
                    {youtube && <a href={youtubeLink} target="_blank" title="youtube" className="mediaCustom fab fa-youtube fa-lg"/>}
                    {medium && <a href={mediumLink} target="_blank" title="medium" className="mediaCustom fab fa-medium fa-lg"/>}
                    {github && <a href={githubLink} target="_blank" title="github" className="mediaCustom fab fa-github fa-lg"/>}
                    {instagram && <a href={instagramLink} target="_blank" title="instagram" className="mediaCustom fab fa-instagram fa-lg"/>}
                    {linkedin && <a href={linkedinLink} target="_blank" title="linkedin" className="mediaCustom fab fa-linkedin-in fa-lg"/>}
                    {facebook && <a href={facebookLink} target="_blank" title="facebook" className="mediaCustom fab fa-facebook-f fa-lg"/>}
                    {whitepaper && <a href={whitepaperLink} target="_blank" title="whitepaper" className="mediaCustom fa fa-sticky-note fa-lg"/>}
                    {includeIntegrations && IntegrationUtils.renderIntegrations(row)}
                    {includeCoinGecko && IntegrationUtils.renderGecko4Token(row, smallInd)}
                </span>
            );
        }
    }


    static getTwitterTimeline(twitterHandle) {
        let height = "500px";
        if (RespUtils.isMobileView()) {
          height = "300px";
        }
    
        if (twitterHandle && twitterHandle !== "") {
          return <TwitterTimelineEmbed sourceType="profile"
            screenName={twitterHandle} 
            options={{height: height}} />;
        }
        // options={{height: 400, width: 300}}
    
        return "";
    }
    
}

export default ProjectUIUtils;
