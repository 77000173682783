import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';

import ValColumnSelector from "../validators/ValColumnSelector";

import "../validators/Validators.css";
import '../styles/section.css';
import '../styles/tables.css';
import '../styles/validator.css';
import "./LSValidators.css";
import Utilities from "../util/Utilities";
import RespUtils from "../util/RespUtils";

import MainUtils from "../main/MainUtils";
import CoinUtils from '../util/CoinUtils';
import currentNetwork from '../currentNetwork';
import ValidatorUtils from '../validators/ValidatorUtils';
import ColumnUtils from '../util/ColumnUtils';

const key = "lsv_3";

class LiquidStakingValidators extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.getInitColumns = this.getInitColumns.bind(this);
    this.renderFilters = this.renderFilters.bind(this);
  }

  render() {
    const headerSortingClasses = (column, sortOrder, isLastSorting, colIndex) => (
      sortOrder === 'asc' ? 'sorting-asc' : 'sorting-desc'
    );
    
    let token = currentNetwork.details.app.token;

    var columns = [
      {text: "#", desc: "Index", hidden: this.isHidden("index"), dataField: "index", sort: true, align: "left", headerStyle: Utilities.respWidth(7, 5, 3), headerSortingClasses},
      {text: "VP Rank", desc: "VP Rank", hidden: this.isHidden("vpRank"), dataField: "vpRank", sort: true, align: "left", headerStyle: Utilities.respWidth(7, 5, 4), headerSortingClasses},
      {text: "", filter: textFilter({placeholder: "Name"}), desc: "Validator Name", hidden: this.isHidden("name"), dataField: "name", formatter: MainUtils.nameFormatterTableWithLogo, sort: true, headerStyle: Utilities.respWidth(25, 20, 15), headerSortingClasses},
    //   {text: "Badges",  desc: "Badges", hidden: this.isHidden("badge1Ind"), dataField: "badge1Ind", formatter: MainUtils.formatLSMBadges, sort: true, headerStyle: Utilities.respWidth(15, 15, 6), headerSortingClasses},
      {text: "Status",  desc: "Validator Status", hidden: this.isHidden("status"), dataField: "status", formatter: MainUtils.statusFormatter, sort: true, headerStyle: Utilities.width(7), headerSortingClasses},
      {text: "Voting Power (VP)",  desc: ("Total " + token + " Staked with validator"), hidden: this.isHidden("votingPower"), dataField: "votingPower", formatter: CoinUtils.stakeFormatterRoundIfNeeded, sort: true, headerStyle: Utilities.width(8), headerSortingClasses},
      {text: "% Voting Power",  desc: "% of total voting power that is with the validator", hidden: this.isHidden("votingPowerPercentage"), dataField: "votingPowerPercentage", formatter: MainUtils.percentFormatter, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 12, 8), headerSortingClasses},
      {text: "Self Delegation",  desc: ("Total " + token + " Staked by the validator"), hidden: this.isHidden("selfBond"), dataField: "selfBond", formatter: CoinUtils.stakeFormatterRoundIfNeeded, sort: true, headerStyle: Utilities.width(8), headerSortingClasses},
      {text: "Self Delegation %",  desc: "% of total voting power that is self staked by the validator", hidden: this.isHidden("selfBondPercentage"), dataField: "selfBondPercentage", formatter: MainUtils.percentFormatter, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(8), headerSortingClasses},
      {text: Utilities.respLabel("Comm", "Comm", "Commission"), desc: "Current commission charged by validator", hidden: this.isHidden("commission"), dataField: "commission", formatter: MainUtils.getCommission, style: MainUtils.commissionStyleFormatter, sort: true, headerStyle: Utilities.respWidth(20, 12, 7), headerSortingClasses},
      {text: "Liquid Staking Delegations",  desc: ("Liquid Staking Delegations"), hidden: this.isHidden("lsmDelegations"), dataField: "lsmDelegations", formatter: CoinUtils.stakeFormatterRoundIfNeeded, sort: true, headerStyle: Utilities.width(10), headerSortingClasses},
    ];

    if (currentNetwork.details.ui.badges.badge1Ind === true) {
      columns.push({text: (currentNetwork.details.ui.badges.badge1IndTitle + " Delegation"),  desc: (currentNetwork.details.ui.badges.badge1IndTitle + " Delegation"), hidden: (!currentNetwork.details.ui.badges.badge1Ind || this.isHidden("lsp1Delegation")), dataField: "lsp1Delegation", formatter: CoinUtils.stakeFormatterRoundIfNeeded, sort: true, headerStyle: Utilities.width(10), headerSortingClasses});
    }
    if (currentNetwork.details.ui.badges.badge2Ind === true) {
      columns.push({text: (currentNetwork.details.ui.badges.badge2IndTitle + " Delegation"),  desc: (currentNetwork.details.ui.badges.badge2IndTitle + " Delegation"), hidden: (!currentNetwork.details.ui.badges.badge2Ind || this.isHidden("lsp2Delegation")), dataField: "lsp2Delegation", formatter: CoinUtils.stakeFormatterRoundIfNeeded, sort: true, headerStyle: Utilities.width(10), headerSortingClasses});
    }
    if (currentNetwork.details.ui.badges.badge3Ind === true) {
      columns.push({text: (currentNetwork.details.ui.badges.badge3IndTitle + " Delegation"),  desc: (currentNetwork.details.ui.badges.badge3IndTitle + " Delegation"), hidden: (!currentNetwork.details.ui.badges.badge3Ind || this.isHidden("lsp3Delegation")), dataField: "lsp3Delegation", formatter: CoinUtils.stakeFormatterRoundIfNeeded, sort: true, headerStyle: Utilities.width(10), headerSortingClasses});
    }
    if (currentNetwork.details.ui.badges.badge4Ind === true) {
      columns.push({text: (currentNetwork.details.ui.badges.badge4IndTitle + " Delegation"),  desc: (currentNetwork.details.ui.badges.badge4IndTitle + " Delegation"), hidden: (!currentNetwork.details.ui.badges.badge4Ind || this.isHidden("lsp4Delegation")), dataField: "lsp4Delegation", formatter: CoinUtils.stakeFormatterRoundIfNeeded, sort: true, headerStyle: Utilities.width(10), headerSortingClasses});
    }
    if (currentNetwork.details.ui.badges.badge5Ind === true) {
      columns.push({text: (currentNetwork.details.ui.badges.badge5IndTitle + " Delegation"),  desc: (currentNetwork.details.ui.badges.badge5IndTitle + " Delegation"), hidden: (!currentNetwork.details.ui.badges.badge5Ind || this.isHidden("lsp5Delegation")), dataField: "lsp5Delegation", formatter: CoinUtils.stakeFormatterRoundIfNeeded, sort: true, headerStyle: Utilities.width(10), headerSortingClasses});
    }

    return (
        <ToolkitProvider keyField="poolId" data={this.props.validators} columns={columns} columnToggle>
          {
            props => (
              <div id="mainTable">
                <div className="pageTitle">Liquid Staking Validators</div>
                <ValColumnSelector settingKey={key} getInitColumns={this.getInitColumns}
                  { ...props.columnToggleProps } refreshParent={this.props.repaintData} 
                  fsElementId="mainTable" hideFullScreenLink={true} renderFilters={this.renderFilters} />
                <BootstrapTable { ...props.baseProps } filter={ filterFactory() } condensed noDataIndication="No Data" />
              </div>
            )
          }
        </ToolkitProvider>
    );
  }

  getInitColumns() {
    let columnsConfig = {};
    if (RespUtils.isTabletView()) {
      columnsConfig = {
            index: true, vpRank: true, name: false, badge1Ind: true, status: true, 
            votingPower: true, votingPowerPercentage: true, commission: true, 
            selfBond: true, selfBondPercentage: true, lsmDelegations: false, 
            lsp1Delegation: false, lsp2Delegation: false, lsp3Delegation: true, 
            lsp4Delegation: true, lsp5Delegation: true};
    } else {
      columnsConfig = {
            index: false, vpRank: false, name: false, badge1Ind: false, status: false, 
            votingPower: false, votingPowerPercentage: true, commission: false, 
            selfBond: false, selfBondPercentage: true, lsmDelegations: false, 
            lsp1Delegation: false, lsp2Delegation: false, lsp3Delegation: false,
            lsp4Delegation: false, lsp5Delegation: false};
    }

    // console.log("interchain val perf - getInit - columns config is: ", columnsConfig);
    return columnsConfig;
  }

  isHidden(dataField) {
    return ColumnUtils.isHidden(key, dataField, this.getInitColumns);
  }

  renderFilters() {
    return (<div><table><tbody><tr>
        {ValidatorUtils.renderBadge(currentNetwork.details.ui.badges.badge1Ind, "/lsm/" + currentNetwork.details.ui.badges.badge1Key, 
          "Click to filter validator list for this provider", currentNetwork.details.ui.badges.badge1IndImg)}
        {ValidatorUtils.renderBadge(currentNetwork.details.ui.badges.badge2Ind, "/lsm/" + currentNetwork.details.ui.badges.badge2Key, 
          "Click to filter validator list for this provider", currentNetwork.details.ui.badges.badge2IndImg)}
        {ValidatorUtils.renderBadge(currentNetwork.details.ui.badges.badge3Ind, "/lsm/" + currentNetwork.details.ui.badges.badge3Key, 
          "Click to filter validator list for this provider", currentNetwork.details.ui.badges.badge3IndImg)}
        {ValidatorUtils.renderBadge(currentNetwork.details.ui.badges.badge4Ind, "/lsm/" + currentNetwork.details.ui.badges.badge4Key, 
          "Click to filter validator list for this provider", currentNetwork.details.ui.badges.badge4IndImg)}
        {ValidatorUtils.renderBadge(currentNetwork.details.ui.badges.badge5Ind, "/lsm/" + currentNetwork.details.ui.badges.badge5Key, 
          "Click to filter validator list for this provider", currentNetwork.details.ui.badges.badge5IndImg)}
      </tr></tbody></table></div>);
  }

//   getLSLabel(seq) {
//     return ""
//   }
}

export default LiquidStakingValidators;
