import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import DetailsIcon from '@material-ui/icons/Details';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import SyncAltIcon from '@material-ui/icons/SyncAlt';

import currentNetwork from '../currentNetwork';
import SessionUtils from '../util/SessionUtils';
import IntegrationUtils from '../util/IntegrationUtils';
import ChartTitle from '../components/ChartTitle';
import RespUtils from '../util/RespUtils';

class ValNav extends React.Component {
  render() {
    if (currentNetwork.details.app.consumerChain) {
      return "";
    }

    var address = this.props.address;

    return (
        // <table className="chartBg" style={{"width": "95%"}}>
        //   <thead>
        //     <tr>
        //       <th><a className="animLinkVisual" href={SessionUtils.getUrl("/val/" + address)}>Validator Summary</a></th>
        //       <th><a className="animLinkVisual" href={SessionUtils.getUrl("/valstats/" + address)}>Stats History</a></th>
        //       <th><a className="animLinkVisual" href={SessionUtils.getUrl("/delegates/" + address)}>Delegates</a></th>
        //     </tr>
        //   </thead>
        // </table>
      <React.Fragment>
        <br/>
        <ChartTitle title="Tools" />
        <Container className="containerLayout" fluid>
          <Row className="statRowLayout">
            <Col className="statColLayout" xs={4} sm={4} md={2} lg={2}>
              <a className="mediaCustomImg animLinkVisual" href={SessionUtils.getUrl("/val/" + address)}>
                <DetailsIcon />
              </a>
              <a className="animLinkVisual" href={SessionUtils.getUrl("/val/" + address)}>
                {RespUtils.getRespLabel("Summary", "Validator Summary")}
              </a>
            </Col>
            <Col className="statColLayout" xs={4} sm={4} md={2} lg={2}>
              <a className="mediaCustomImg animLinkVisual" href={SessionUtils.getUrl("/valstats/" + address)}>
                <EqualizerIcon />
              </a>
              <a className="animLinkVisual" href={SessionUtils.getUrl("/valstats/" + address)}>
                {RespUtils.getRespLabel("Stats", "Stats History")}
              </a>
            </Col>
            <Col className="statColLayout" xs={4} sm={4} md={2} lg={2}>
              <a className="mediaCustomImg animLinkVisual" href={SessionUtils.getUrl("/delegates/" + address)}>
                <PeopleAltIcon />
              </a>
              <a className="animLinkVisual" href={SessionUtils.getUrl("/delegates/" + address)}>
                Delegates
              </a>
            </Col>
            <Col className="statColLayout" xs={4} sm={4} md={2} lg={2}>
              <a className="mediaCustomImg animLinkVisual" href={SessionUtils.getUrl("/val/" + address + "/txs")}>
                <SyncAltIcon />
              </a>
              <a className="animLinkVisual" href={SessionUtils.getUrl("/val/" + address + "/txs")}>
                {RespUtils.getRespLabel("Recent Txs", "Recent Transactions")}
              </a>
            </Col>
          </Row>
          <Row className="statRowLayout">
            {this.props.val != null && (IntegrationUtils.renderDetailedIntegrations(this.props.val[currentNetwork.details.ui.identifierAttribute]))}
          </Row>
        </Container>
        <br/>
      </React.Fragment>
    );
  }
}

export default ValNav;
