import React from 'react';
// import { set } from 'react-ga';
import { MultiSelect } from "react-multi-select-component";
// import MultiSelect from "react-multi-select-component";

import "./AppList.css";
import common from '../common';

class AppList extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
          apps: [],
          // selectedApps: [],
      };
      this.setSelected = this.setSelected.bind(this);
  }

  async componentDidMount() {
    let url = common.DASHBOARDS_URL;
  
    fetch(url, {referrerPolicy: 'no-referrer'})
      .then(this.handleErrors)
      .then(res => res.json())
      .then((data) => {
        let ssDashboards = data["ssDashboards"];
        // console.log(ssDashboards);
        let appList = [];
        for(let i=0; i < ssDashboards.length; i++) {
          let details = ssDashboards[i];
          // console.log(details);
          if (details["compareVPCurve"] === true) {
            // console.log("adding app");
            appList.push({label: details.title, value: details.app});
          }
        }
        // console.log(appList);
        if (ssDashboards && ssDashboards.length !== 0) {
            this.setState({apps: appList});
        }
      }).catch(error => console.log(error))
  }
  
  setSelected(e) {
    this.props.setApplist(e);
    // this.setState({"selectedApps": e});
    // if(e.length === 0) {
    //   this.setState({"selectedApps": []});
    // } else {
    //   console.log(e);
    //   let selectedApps = this.state.selectedApps;
    //   console.log("initial apps");
    //   console.log(selectedApps);
    //   selectedApps.push(...e);
    //   console.log("final apps");
    //   console.log(selectedApps);
  
    //   this.setState({"selectedApps": selectedApps});
    // }
  }

  render() {
    return (
      <MultiSelect options={this.state.apps} value={this.props.selectedApps}
        onChange={this.setSelected} labelledBy="Compare with"
      />
    );
  }

}

export default AppList;
