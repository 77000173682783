import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import {Container, Row, Col} from 'react-bootstrap';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import currentNetwork from "../currentNetwork";
import "./Dashboards.css";
import LogoUtils from "../util/LogoUtils";
import common from "../common";
import constants from "../constants";
import RespUtils from "../util/RespUtils";
import NetworkUtils from "../network/NetworkUtils";

class Dashboards extends Component {
  constructor(props) {
    super(props);
    this.state = {
        visible: false,
        dashboards: [],
    };
    this.switchModal = this.switchModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  async componentDidMount() {
    // let url = "https://static.smartstake.io/resources/dashboards.json";
    let url = common.DASHBOARDS_URL;

    fetch(url, { referrerPolicy: "no-referrer" })
      .then(this.handleErrors)
      .then((res) => res.json())
      .then((data) => {
        let ssDashboards = data["ssDashboards"];
        if (ssDashboards && ssDashboards.length !== 0) {
          this.setState({ dashboards: ssDashboards });
        }
      })
      .catch((error) => console.log(error));
  }

  switchModal() {
    if (this.state.visible === false) {
      this.setState({visible: true});
    } else {
      this.setState({visible: false});
    }
  }

  hideModal() {
    this.setState({visible: false});
  }

  render() {
    return (
        <React.Fragment>
            <button onClick={this.switchModal} className="animLinkMenu animLink">{this.renderDefaultApp()}
              {currentNetwork.details.app.testnet === true && (<span className="beta">Beta</span>)}<ArrowDropDownIcon className="mInfoIcon" style={{fill: "white"}} fontSize="large" />
            </button>
    
            {this.state.visible === true && 
            <Modal show={this.state.visible} onHide={this.hideModal} size="lg" centered className="ssModal">
                <Modal.Header closeButton>
                <Modal.Title>Smart Stake Analytics{constants.mainnet === false ? " - Testnets": ""}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Container className="containerLayout" fluid>
                    <Row className="statRowLayout">
                        {this.state.dashboards.map(dashboard => (
                            this.renderDashboardLink(dashboard, false)
                        ))}
                    </Row>
                  </Container>  
                  {/* <hr/>
                  <ChartTitle title="Interchain Security" />
                  <Container className="containerLayout" fluid>
                    <Row className="statRowLayout">
                        {this.state.dashboards.map(dashboard => (
                            this.renderDashboardLink(dashboard, true)
                        ))}
                    </Row>
                  </Container>   */}
                </Modal.Body>
            </Modal>}
        </React.Fragment>
    );
  }

  renderDefaultApp() {
    if (currentNetwork.details.notSet) {
      return (<span>Networks</span>);
    }

    if (RespUtils.isMobileView()) {
      return LogoUtils.defaultChainLogo();
    }

    return (<span>{LogoUtils.defaultChainLogo()} {currentNetwork.details.app.appName}</span>);
  }

  renderDashboardLink(dashboard) {
    if (dashboard.mainnet === true && constants.mainnet !== true) {
      return "";
    } else if (dashboard.mainnet === false && constants.mainnet !== false) {
      return "";
    }
    // if (ics === true && !dashboard.ics) {
    //   return "";
    // } else if (ics === false && dashboard.ics === true) {
    //   return "";
    // }
    let styleName = dashboard.app === currentNetwork.details.app.APP_KEY ? "animLinkSelected animLink": "animLink";
    let title = dashboard.title;
    if (!title) {
        title = dashboard.name;
    }

    let website = NetworkUtils.getNetworkLink(dashboard.app, dashboard.website)
    return (<Col className="statColLayout" xs={6} sm={6} md={4} lg={3}>
            <div className="dashboard" key={dashboard.app}>
                <a className={styleName} href={website}
                  >{dashboard.logoFileName && LogoUtils.chainLogo(dashboard.logoFileName)} {title}</a>
            </div>
        </Col>);
  }
}

export default Dashboards;
