import React, { Component } from 'react';
import TuneIcon from '@material-ui/icons/Tune';

import currentNetwork from '../currentNetwork';

import "../main/DelegateModal.css";
import MainUtils from '../main/MainUtils';
import RespUtils from '../util/RespUtils';
import SessionUtils from '../util/SessionUtils';
import ApiUtils from '../util/ApiUtils';
import ReactTooltip from 'react-tooltip';
import { Container } from 'react-bootstrap';
import ChartTitle from '../components/ChartTitle';
import DateUtils from '../util/DateUtils';
import IntegrationUtils from '../util/IntegrationUtils';
import UIUtils from '../util/UIUtils';
import PageHeader from '../components/PageHeader';
import Faq from '../base/Faq';
import LogoUtils from '../util/LogoUtils';

class ChooseValidator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            params: {}, 
            coinStat: {},
            notification: {},
            isLoading: true, error: false,
        }
    }

    async componentDidMount() {
        SessionUtils.setNetwork(this);

        let url = "listData?type=guide";
        // console.log(url);

        const allData = await ApiUtils.get(url);
        // console.log(allData);
        if (!allData) {
            this.setState({error: true, isLoading: false});
            return;
        }

        this.setState({isLoading: false, "params": allData["params"], "coinStat": allData["coinStat"],
            "notification": allData["notification"]});
    }
        
    render() {
        let loadMsg = UIUtils.getLoading(this);
        if (loadMsg) return loadMsg;

        return (
            <div>
                <PageHeader coinStat={this.state.coinStat} title={currentNetwork.details.app.appName + " - Staking Guide/Tips"} 
                    thisObj={this} beta={false} customLogo={LogoUtils.getChainLogo} />
                <p/>
                <ReactTooltip id="main" place="top" type="dark" effect="float" multiline={true} />
                <Container fluid className="containerLayout container-fluid chartContainer">
                    <div className="chartGridContainer">
                        <div className="chartBg">{this.renderOwnNeeds()}</div>
                        <div className="chartBg">{this.renderStrategies()}</div>
                        <div className="chartBg">{this.renderChooseValidator()}</div>
                        <div className="chartBg">{this.renderRisks()}</div>
                        <div className="chartBg">{this.renderCommission()}</div>
                        <div className="chartBg">{this.renderValidatorOfferings()}</div>
                        <div className="chartBg">{this.renderTools()}</div>
                        <div className="chartBg">{this.renderFaq()}</div>
                    </div>
                </Container>
                <br/><p>Are there any more tips that you would like to show on this page or perhaps you have feedback on the current content on this page? Feel free to provide feedback at <a href="https://t.me/SmartStake" className="fab fa-telegram fa-lg"/>.</p>
            </div>
        );
    }

    renderOwnNeeds() {
        return (
            <React.Fragment>
              <ChartTitle title="Understand your own needs first"/>
              <br/>
              <ul>
                <li>Are you staking for the short term or long term?</li>
                <li>Do you need immediate access to tokens at anytime and cannot afford to (or dont want to) wait for undelegation window ({DateUtils.formatDaysLeft(this.state.params.unbondingTime)}) to finish?</li>
                <li>Staking is a long term activity and typically undertaken by delegates that want to support the network over a long period. </li>
                <li>If you are staking for the short term or need immediate access to tokens, you may want to assess liquid staking options or explore other opportunities available in the network. Each such opportunity (including liquid staking) comes with its own risks.</li>
                <li>Monitoring: Sometimes a validator may shutdown its services or may change commission or may change their alignment/offering. Are you able to monitor your stake/rewards/validator performance once a week/month/quarter?</li>
              </ul>
            </React.Fragment>
          );
    }

    renderRisks() {
        let token = this.state.coinStat.tokenSymbol;
        let undelegationWindow = DateUtils.formatDaysLeft(this.state.params.unbondingTime);
        let downtimeSlashingPenalty = this.state.params.slashFractionDowntime * 100;
        let doubleSignSlashingPenalty = this.state.params.slashFractionDoubleSign * 100;
        let minUptime = this.state.params.slashingMinPerUptime * 100;
        let rollingBlocks = this.state.params.signedBlocksWindow;
        let slashingWindow = DateUtils.formatHoursLeft(this.state.params.signedBlocksWindow * this.state.coinStat.blockRate);
        
        // let show = {inflation: ("KUJI" !== token) }
        return (
            <React.Fragment>
              <ChartTitle title="Risks & Considerations"/>
              <br/>
              <ul>
                    <li>Token Lock - when staking ${token} tokens, tokens are locked for {undelegationWindow}. This means that after initiating undelegation, you will need to wait for {undelegationWindow} before you get hold of tokens.</li>
                    <li>Risks - there are slashing risks with delegation:
                        <ul>
                            <li>Downtime slashing - If a validator gets slashed for downtime, validator and delegates lose {downtimeSlashingPenalty}% of their staked {token}. Validator needs to sign atleast {minUptime} blocks in every {rollingBlocks} blocks/ {slashingWindow}.</li>
                            <li>Double signing / tombstoned - If a validator mis-behaves and double signs, the validator is tombstoned/jailed permanently. Validator and delegates lose {doubleSignSlashingPenalty}% of their staked ${token} if validator double signs. </li>
                        </ul>
                    </li>
                    <li>Inflation - understand token issuance and inflation aspects for ${token}.</li>
                    <li>Staking APR changes - Staking APR (in ${token} terms) is impacted by several factors. Over time, increase in total tokens staked will negatively impact staking APR. Token issuance/inflation may devalue the value of each ${token} earned in staking rewards. Governance changes or pre-planned network param changes may affect staking APR.</li>
                    <li>Price fluctuations - Crypto currencies are volatile and price fluctuations may result in the total value of your staked tokens (including staking rewards) depreciating over time. Please dyor before deciding to stake.</li>
              </ul>
            </React.Fragment>
        );
    }

    renderCommission() {
        return (
            <React.Fragment>
                <ChartTitle title="Commission"/>
                <br/>
                <p>Understand the difference between Commission, Max Commission, and Max Commission Change Rate.</p>
                <ul>
                    <li>Commission: Current commission charged by the validator. 
                        A commission of 5% means that for every 100 ${this.state.coinStat.tokenSymbol} that you earn in rewards, 
                        validator keeps 5 ${this.state.coinStat.tokenSymbol} and you/delegate gets 95 ${this.state.coinStat.tokenSymbol}.</li>
                    <li>Max Commission: This is the maximum commission a validator can ever charge. 
                        Unless you plan on monitoring the current commission on a daily/weekly basis, 
                        delegating to validators with lower max commission value is relatively better. </li>
                    <li>Max daily commission change rate: This is the maximum change in commission that a validator could do every day. 
                        A validator with 1% max daily change rate will need to increase the commission on 10 different days to increase commission by 10%.
                        A validator with 10% max daily change rate can increase the commission by 10% in 1 day.</li>
                </ul>
            </React.Fragment>
        );
    }

    renderStrategies() {
        let aprLabel = "";
        if (this.state.coinStat.currentRewardRate && this.state.coinStat.currentRewardRate > 0) {
            aprLabel = "(" + this.state.coinStat.currentRewardRate + "% APR)";
        }
        
        return (
            <React.Fragment>
              <ChartTitle title="Strategies"/>
              <br/>
              <ul>
                    <li>Assess regular staking {aprLabel} vs liquid staking</li>
                    <li>Multiple Validators - to reduce slashing risks, delegating with two or more validators is a best practice. Key consideration: reward claiming/redelegation related tx costs increase with increasing number of validators. For relatively smaller holdings, delegating with 2 to 3 validators provides a good balance between tx fee and risk reduction.</li>
                    {currentNetwork.details.ui.showApr === true && (<li>Compounding - Claiming rewards and staking rewards frequently can provide relatively higher returns. Check <a className="animLinkVisual" href={SessionUtils.getUrl("/calc")}>calculator</a>to understand more.</li>)}
                    {currentNetwork.details.ui.showApr !== true && (<li>Compounding - Claiming rewards and staking rewards frequently can provide relatively higher returns.</li>)}
                    <li>Auto compounding - Evaluate/use {IntegrationUtils.renderAutoCompoundOptions()} for automatic compounding of staking rewards for a relatively higher returns.</li>
                    {this.state.coinStat.tokenSymbol === "LUNA" && (<li>Evaluate alliance staking options for Luna at {IntegrationUtils.renderStakeStation(null, true, true)}</li>)}
                    {this.state.coinStat.tokenSymbol === "LUNA" && (<li>Liquid Staking - evaluate liquid staking options like Stride, pStake, Quicksilver, Eris, Backbone Labs, and LiquidSteaking</li>)}
                    {this.state.coinStat.tokenSymbol !== "LUNA" && (<li>Liquid Staking - evaluate liquid staking options like Stride, pStake, Quicksilver, and Milkyway (may not be available on all networks)</li>)}
              </ul>
            </React.Fragment>
        );
    }

    renderChooseValidator() {
        return (
            <React.Fragment>
              <ChartTitle title="Guide for selecting validator(s) for delegation"/>
              <br/>
              <p>There are many ways to shortlist validators for delegation. Some of the approaches are described here.</p>
              <ul>
                    <li>Decentralization - Consider supporting smaller valdators to support decentralization.</li>
                    <li>Commission - Understand about max commission, max daily commission change</li>
                    <li>Validator offering - Most validators have their own unique offerings. See what offerings align with your interests. Visit their website (visit validator home on this site) to understand their offering.</li>
                    <li>Uptime/performance - Small difference in validator uptime (e.g. 99% vs 99.5% over a long duration) matter but is not a key differentiator in selecting validators. When assessing validators by uptime, it is better to look at uptime/performance over a longer duration to gauge the stability of a validator's service.</li>
                    <li>0% validators - delegates get attracted towards validators charging 0% commission. It's always a good idea to know why a validator is on 0% and what are their long term plans for commission. Beware of 0% validators with high max daily change rate or high max commission.</li>
                    <li>Establish Contact - It's always a good idea to establish contact with the validator using commonly available means e.g. Telegram, Discord, Twitter, Email. Ask questions to get clarity on any aspect that interests you. Where available, validator contact information is also available on the home page of the validator on this site.</li>
                    <li>(optional) Use settings icon (<TuneIcon />) on Smart Stake's validators page to change screen views and compare validators on the on-chain metrics that you are interested in.</li>
              </ul>
            </React.Fragment>
        );
    }

    renderDecentralization() {
        return (
            <React.Fragment>
              <ChartTitle title="Decentralization considerations"/>
              <br/>
              <ul>
                    <li>Decentralization is a very broad topic. Many different aspects make a network more or less decentralized e.g voting power distribution, location/country/data-center concentration, development team/project team being a centralized organization and several more.</li>
                    <li>Stake concentration in top validators is one of the relatively easily manageable aspect of decentralization.</li>
                    <li>In {this.state.coinStat.name}, {this.state.coinStat.nakamatoCoeffiecient} validators control network halt power. </li>
                    <li>Staking with validators with less than {this.state.coinStat.avgVotingPower}% voting power validators helps improve decentralization. If the metric is not available, staking outside of top 10/20 validators by voting power is also generally encouraged.</li>
                    <li>Consider supporting smaller valdators to support decentralization.</li>
              </ul>
            </React.Fragment>
        );
    }

    renderValidatorOfferings() {
        return (
            <React.Fragment>
              <ChartTitle title="Validator Offerings"/>
              <br/>
              <p>All validators have their unique offerings. See what offerings/validators align with your interests. Here are some of the offerings that validator may have in addition to validating the network:</p>
              <ul>
                    <li>Provider infrastructure services for the network e.g. RPC, REST/LCD services.</li>
                    <li>Build tools for the ecosystem e.g. bots, indexer, utilities.</li>
                    <li>Participate in the core development of the network e.g. as a developer, tester etc.</li>
                    <li>Build dApp/projects on the network.</li>
                    <li>Provide explorers, analytics dashboards.</li>
                    <li>Running IBC relayers.</li>
                    <li>Support community in Telegram/Discord/Twitter/etc.</li>
                    <li>Provide educational content for community via podcasts, spaces, guides/tutorials.</li>
                    <li>Support charity or a specific cause.</li>
                    <li>Run stakedrop campaign to airdrop tokens of their project to the delegates of the validator.</li>
                    <li>A corporate investor/venture capitalist.</li>
                    <li>Run centralized exchanges - generally not recommended for staking by community to prevent further centralization.</li>
              </ul>
            </React.Fragment>
        );
    }

    renderModal(validator) {
        if (this.state.visible !== true) return "";

        let token = currentNetwork.details.app.token;
        let commissionMaxRate = parseFloat(validator.commissionMaxRate);
        let commission = parseFloat(validator.commission);
        let commissionMaxChangeRate = parseFloat(validator.commissionMaxChangeRate);

        let daysToMaxCommission;
        if (validator.commission === validator.commissionMaxRate) {
            daysToMaxCommission = 0;
        } else {
            daysToMaxCommission = (commissionMaxRate - commission) / commissionMaxChangeRate;
            if (daysToMaxCommission != parseInt(daysToMaxCommission)) {
                daysToMaxCommission = 1 + parseInt(daysToMaxCommission);
            }
        }

        return (<React.Fragment>
                <table><tbody>
                   <tr><td>Validator Name: </td><td>{MainUtils.nameFormatterLong(validator.name, validator)}</td></tr>
                   <tr><td>Current Commission: </td><td>{commission}%</td></tr>
                   <tr><td>Max Commission: </td><td>{commissionMaxRate}%</td></tr>
                   <tr><td>Max daily commission change rate: </td><td>{commissionMaxChangeRate}%</td></tr>
                </tbody></table>
                <br/>
                <p>Tips: Understand the difference between Commission, Max Commission, and Max Commission Change Rate.</p>
                <ul>
                    <li>Commission ({commission}%): Current commission charged by the validator. 
                        A commission of {commission}% means that for every 100 {token} that you earn in rewards, 
                        validator keeps {commission} {token} and you/delegate gets {100 - commission} tokens.</li>
                    <li>Max Commission ({commissionMaxRate}%): This is the maximum commission a validator can ever charge. 
                        Unless you plan on monitoring the current commission on a daily/weekly basis, 
                        delegating to validators with lower max commission value is relatively better. </li>
                    <li>Max daily commission change rate ({commissionMaxChangeRate}%): This is the maximum change in commission that a validator could do every day. 
                        A validator with 1% max daily change rate will need to increase the commission on 10 different days to increase commission by 10%.
                        A validator with 10% max daily change rate can increase the commission by 10% in 1 day.</li>
                    <li>If {validator.name} validator wanted to change commission to maximum commission, it will need to increase commission every day for at least {daysToMaxCommission} days to reach {commissionMaxRate}%.</li>
                </ul>
                <p>This is an educational tool and all examples given above are hypothetical. Not all validators intend to increase their commission to max commission. It's always a good idea to establish contact with the validators you delegate/want to delegate with and understand their offering and commission plans.</p>
            </React.Fragment>);

    }

    renderCommissionPlus(validator) {
        let value = validator.commission;
        if (this.props.commissionPlusList === true) {
            if (validator.commIncreaseTime !== null) {
                // return (<span style={{"backgroundColor": "orange", "padding": "0.2rem"}}>{validator.commIncreaseDesc}</span>);
                return validator.commIncreaseDesc;
            } else {
                if (RespUtils.isMobileView()) {
                    value = validator.commission + " /" + validator.commissionMaxRate + " /" + validator.commissionMaxChangeRate;
                } else {
                    value = validator.commission + "% / " + validator.commissionMaxRate + "% / " + validator.commissionMaxChangeRate + "%";
                }
            }
        }
    
        let desc = "Current Commission: " + validator.commission + "%. <br/>";
        desc += "Max Commission: " + validator.commissionMaxRate + "%. <br/>";
        desc += "Max daily commission change rate: " + validator.commissionMaxChangeRate + "%.<br/>"
        desc += "Click to learn more";
        // return (<span data-for="main" data-tip={desc} data-iscapture="true"><a>{value}</a></span>);
        return (<span data-for="main" data-tip={desc} data-iscapture="true"><button className="animLink" onClick={this.showModal}>{value}</button></span>);
    }

    /**
     *                     <li>Example approach 1: 
                        <ol>
                            <li>Shortlist validators by their voting power (lower is better for decentralization) - sort by Voting Power or click on 'Decentralize' filter on validators page</li>
                            <li>Pick validators with &lt;=5% max daily commission change rate.</li>
                            <li>Pick validators with &lt;=5% max daily commission change rate.</li>
                            <li>Check long term uptime</li>
                            <li></li>
                            i) , 2)  3.
                        </ol>
                    </li>

     */

    renderTools() {
        return (
            <React.Fragment>
                <ChartTitle title="Staking Tools"/>
                <br/>
                <ul>
                    {currentNetwork.details.integrations.mainStaking && (<li>{IntegrationUtils.renderMainStakingLink(null, true, true)}</li>)}
                    {currentNetwork.details.integrations.keplr && (<li>{IntegrationUtils.renderStakeKeplr(null, true, true)}</li>)}
                    {currentNetwork.details.integrations.leap && (<li>{IntegrationUtils.renderStakeLeap(null, true, true)}</li>)}
                    {currentNetwork.details.integrations.station && (<li>{IntegrationUtils.renderStakeStation(null, true, true)}</li>)}
                    {currentNetwork.details.integrations.restake && (<li>{IntegrationUtils.renderStakeRestake(null, true, true)}</li>)}
                </ul>
            </React.Fragment>
        );
    }

    renderFaq() {
        let undelegationWindow = DateUtils.formatDaysLeft(this.state.params.unbondingTime);

        return (
            <React.Fragment>
                <ChartTitle title="Frequently asked questions"/>
                <br/>
                {currentNetwork.details.ui.showApr === true && (<Faq question="What is the current/historical staking APR?"
                    answer={`The current staking APR is ${this.state.coinStat.currentRewardRate}%. Historical staking APR is available on the Network stats page.`} />)}
                {currentNetwork.details.ui.showApr !== true && (<Faq question="What is the current staking APR?"
                    answer={`The current staking APR data is not available on this dashboard. Please try using the other tools listed on this page to assess staking APR.`} />)}
                <Faq question="Can validators take control of delegates tokens?"
                    answer="Validators do not control tokens belonging to delegates. There are some slashing risks involved. Please refer to the risks section for more details." />
                <Faq question={`I can't wait for ${undelegationWindow} of undelegation time, is there any way to get the tokens right away?`}
                    answer={`Staked tokens have to undergo the mandatory ${undelegationWindow} waiting time. There is no way to shorten this window.`} />
                <Faq question={`Why are my tokens still not available even though more than ${undelegationWindow} have passed since I staked?`}
                    answer={`Staked tokens remain staked forever until the delegate initiates undelegation. The ${undelegationWindow} waiting period starts from the time of performing undelegation.`} />
            </React.Fragment>
        );
    }

                    
}

export default ChooseValidator;
