import React from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import {Container} from 'react-bootstrap';
import ReactTooltip from "react-tooltip";

import ApiUtils from '../util/ApiUtils';
import tooltips from "../tooltips";
import MultiLineChart from '../charts/MultiLineChart';
import '../styles/section.css';
import ValHeader from '../validator/ValHeader';
import ValNav from './ValNav';
import Utilities from '../util/Utilities';
import UIUtils from '../util/UIUtils';
import currentNetwork from '../currentNetwork';
import ValidatorUtils from '../validators/ValidatorUtils';
import common from '../common';
import SessionUtils from '../util/SessionUtils';
import RespUtils from '../util/RespUtils';
import CoinUtils from '../util/CoinUtils';
import SPUtilities from '../util/SPUtilities';

class ValStats extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      stats: [],
      rankStats: [],
      averageDelegationStats: [],
      val: {},
      notification: {},
      width: window.innerWidth,
      size: 10,
      responsive: true,
      isLoading: true,
      frequencyNetwork: "M",
    }

    this.updateDimensions = this.updateDimensions.bind(this);
    this.handleChartUpdateFrequency = this.handleChartUpdateFrequency.bind(this);   
  }

  updateDimensions() {
    this.setState({width: window.innerWidth});
  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  async componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    SessionUtils.setNetwork(this);

    this.prepareData(this.state.frequencyNetwork, this.state.frequencyBlock)
  }

  async prepareData(frequencyNetwork, frequencyBlock) {
    let url = "listData?type=valstats&frequencyNetwork=" + frequencyNetwork;
    url += ValidatorUtils.getIdParam(this);

    // console.log(url);
    const allData = await ApiUtils.get(url);
    // console.log("allData is:", allData);

    if (!allData) {
      this.setState({error: true, isLoading: false});
      return;
    }

    let rankStats = Utilities.removeEmptyRecords(allData["stats"], "vpRank")
    let averageDelegationStats = Utilities.removeEmptyRecords(allData["stats"], "averageDelegation");
    let lsm = Utilities.removeEmptyRecords(allData["stats"], "lsmDelegations");
    this.setState({"stats": allData["stats"], "frequencyNetwork": frequencyNetwork, 
      "notification": allData["notification"], val: allData["val"], "averageDelegationStats": averageDelegationStats,
      "rankStats": rankStats, "lsm": lsm, isLoading: false});
  }

  render() {
    let loadMsg = UIUtils.getLoading(this);
    if (loadMsg) return loadMsg;

    let vpColumns = [
        {text: "Date", dataField: "statDate", sort: true, },
        {text: "Voting Power", dataField: "votingPower", formatter: SPUtilities.formatCoins, sort: true, },
        {text: "% Voting Power", dataField: "votingPowerPercentage", sort: true, },
        {text: "VP Rank", dataField: "vpRank", sort: true, },
      ];

    return (
      <div>
        <ValHeader val={this.state.val} notification={this.state.notification} title="Validator Stats" thisObj={this}/>
        <ReactTooltip id="main" place="top" type="dark" effect="float" multiline={true} />
        <hr/>
        <Container fluid className="containerLayout container-fluid chartContainer">
          <div className="chartGridContainer">
            <div className="chartBg" id="votingPower">
              <MultiLineChart title="Voting Power" xAxis="Date" yAxis="Voting Power" adjustRange={true} 
                  chartVisualId="voting-power" enableSave={true} 
                  showVerticalLabel={false} valueAttr={["votingPower"]} rangeAttr="votingPower" showTotalLabel={false} 
                  xAxisValueAttr="statDate" data={this.state.stats} tip={tooltips.valCharts.votingPower} 
                  handleChart={this.handleChartUpdateFrequency} formatValues={true} fsElementId="votingPower"
                  frequencies={["W", "M", "Y", "All"]} frequency={this.state.frequencyNetwork} 
                  dataKey="title" columns={vpColumns} />
            </div>
            <div className="chartBg" id="votingPowerPercentage">
              <MultiLineChart title="Voting Power Percentage" shortTitle="Voting Power %" 
                  chartVisualId="voting-power-percentage" enableSave={true} 
                  xAxis="Date" yAxis="Voting Power" adjustRange={true} 
                  showVerticalLabel={false} valueAttr={["votingPowerPercentage"]} rangeAttr="votingPowerPercentage" showTotalLabel={false} 
                  xAxisValueAttr="statDate" data={this.state.stats} tip={tooltips.valCharts.votingPowerPercentage} 
                  handleChart={this.handleChartUpdateFrequency} fsElementId="votingPowerPercentage"
                  frequencies={["W", "M", "Y", "All"]} frequency={this.state.frequencyNetwork} 
                  dataKey="title" columns={vpColumns} />
            </div>
            <div className="chartBg" id="votingPowerRank">
              <MultiLineChart title="Voting Power Rank" xAxis="Date" yAxis="Voting Power Rank" adjustRange={true} 
                  chartVisualId="voting-power-rank" enableSave={true} 
                  showVerticalLabel={false} valueAttr={["vpRank"]} rangeAttr="vpRank" showTotalLabel={false} 
                  xAxisValueAttr="statDate" data={this.state.rankStats} tip={tooltips.valCharts.votingPowerRank} 
                  handleChart={this.handleChartUpdateFrequency} fsElementId="votingPowerRank"
                  frequencies={["W", "M", "Y", "All"]} frequency={this.state.frequencyNetwork} 
                  dataKey="title" columns={vpColumns} />
            </div>
            <div className="chartBg" id="uniqueDelegates">
              <MultiLineChart title="Unique Delegates" xAxis="Date" yAxis="Unique Delegates" adjustRange={true} 
                  chartVisualId="unique-delegates" enableSave={true} 
                  showVerticalLabel={false} valueAttr={["uniqueDelegates"]} rangeAttr="uniqueDelegates" showTotalLabel={false} 
                  xAxisValueAttr="statDate" data={this.state.stats} tip={tooltips.valCharts.uniqueDelegates} 
                  handleChart={this.handleChartUpdateFrequency} fsElementId="uniqueDelegates"
                  frequencies={["W", "M", "Y", "All"]} frequency={this.state.frequencyNetwork} 
                  dataKey="title" columns={[
                    {text: "Date", dataField: "statDate", sort: true, },
                    {text: "Unique Delegates", dataField: "uniqueDelegates", sort: true, },
                  ]} />
            </div>
            <div className="chartBg" id="commissionHistory">
              <MultiLineChart title="Commission History" xAxis="Date" yAxis="Commission" adjustRange={true} 
                  chartVisualId="commission-history" enableSave={true} 
                  showVerticalLabel={false} valueAttr={["commission"]} rangeAttr="commission" showTotalLabel={false} 
                  xAxisValueAttr="statDate" data={this.state.stats} tip={tooltips.valCharts.commission} 
                  handleChart={this.handleChartUpdateFrequency} formatValues={false} fsElementId="commissionHistory"
                  frequencies={["W", "M", "Y", "All"]} frequency={this.state.frequencyNetwork} 
                  dataKey="title" columns={[
                    {text: "Date", dataField: "statDate", sort: true, },
                    {text: "Commission", dataField: "commission", formatter: SPUtilities.percentFormatter, sort: true, },
                  ]} />
            </div>
            <div className="chartBg" id="averageDelegation">
              <MultiLineChart title="Average Delegation" xAxis="Date" yAxis="Average Delegation" adjustRange={true} 
                  chartVisualId="average-delegation" enableSave={true} 
                  showVerticalLabel={false} valueAttr={["averageDelegation"]} showTotalLabel={false} 
                  yAxisColor={common.colors.chartColor4} showLegend={true} compareAttr="averageDelegationUsd"
                  xAxisValueAttr="statDate" data={this.state.averageDelegationStats} tip={tooltips.valCharts.averageDelegation} 
                  handleChart={this.handleChartUpdateFrequency} formatValues={false} fsElementId="averageDelegation"
                  frequencies={["W", "M", "Y", "All"]} frequency={this.state.frequencyNetwork} 
                  dataKey="title" columns={[
                    {text: "Date", dataField: "statDate", sort: true, },
                    {text: "Average Delegation", dataField: "averageDelegation", formatter: CoinUtils.stakeFormatterRounded, sort: true, },
                    {text: "Average Delegation USD", dataField: "averageDelegationUsd", formatter: CoinUtils.moneyFormatterUsd, sort: true, },
                  ]} />
            </div>
            {currentNetwork.details.app.lsm === true && this.getLsmStats()}
          </div>
        </Container>
        <ValNav address={this.state.val[currentNetwork.details.ui.identifierAttribute]} val={this.state.val} />
      </div>
    );
  }

  getLsmStats() {
    return (<React.Fragment>
            <div className="chartBg" id="networkSignHistory">
              <MultiLineChart title="Liquid Stake History" xAxis="Date" yAxis="Liquid Stake"
                  chartVisualId="liquid-stake-history" enableSave={true} 
                  showVerticalLabel={false} valueAttr={["lsmDelegations"]} 
                  showTotalLabel={false} xAxisValueAttr="statDate" rangeAttr="lsmDelegations" 
                  data={this.state.lsm} tip={tooltips.networkCharts.liquidStake} 
                  handleChart={this.handleChartUpdateFrequency} formatValues={false} fsElementId="lsmDelegations"
                  frequencies={["W", "M", "Y", "All"]} frequency={this.state.frequencyNetwork} 
                  dataKey="title" columns={[
                    {text: "Date", dataField: "statDate", sort: true, },
                    {text: "LSM Delegations", dataField: "lsmDelegations", formatter: CoinUtils.stakeFormatterRounded, sort: true, },
                  ]} />
            </div>
            <div className="chartBg" id="networkSignHistory">
              <MultiLineChart title={RespUtils.isTabletView() ? "Protocol LS History" : "Protocol Level Liquid Staking History"}
                  chartVisualId="protocol-ls-history" enableSave={true} 
                  xAxis="Date" yAxis="Liquid Stake" showLegend={true} showVerticalLabel={false} 
                  valueAttr={["strdDelegation", "qckDelegation", "pstakeDelegation", "pryzmDelegation", "dropDelegation"]} 
                  showTotalLabel={false} xAxisValueAttr="statDate" rangeAttr="strdDelegation" 
                  data={this.state.lsm} tip={tooltips.networkCharts.protocolLiquidStake} 
                  handleChart={this.handleChartUpdateFrequency} formatValues={false} fsElementId="lsHistory"
                  frequencies={["W", "M", "Y", "All"]} frequency={this.state.frequencyNetwork} 
                  dataKey="title" columns={[
                    {text: "Date", dataField: "statDate", sort: true, headerStyle: Utilities.respWidth(30, 25, 25)},
                    {text: "Stride Delegations", dataField: "strdDelegation", formatter: CoinUtils.stakeFormatterRounded, sort: true, },
                    {text: "Quicksilver Delegations", dataField: "qckDelegation", formatter: CoinUtils.stakeFormatterRounded, sort: true, },
                    {text: "pStake Delegations", dataField: "pstakeDelegation", formatter: CoinUtils.stakeFormatterRounded, sort: true, },
                    {text: "Pryzm Delegations", dataField: "pryzmDelegation", formatter: CoinUtils.stakeFormatterRounded, sort: true, },
                    {text: "Drop Delegations", dataField: "dropDelegation", formatter: CoinUtils.stakeFormatterRounded, sort: true, },
                  ]} />
            </div>
    </React.Fragment>)
  }

  handleChartUpdateFrequency(frequencyNetwork) {
    this.prepareData(frequencyNetwork, this.state.frequencyBlock);
  }

}

export default ValStats;
