import React from 'react';

import common from '../common';

class RestUtils extends React.Component {
    static populateDashboards(thisObj, tendermintOnly) {
        let url = common.DASHBOARDS_URL;
  
        fetch(url, {referrerPolicy: 'no-referrer'})
          .then(thisObj.handleErrors)
          .then(res => res.json())
          .then((data) => {
            let ssDashboards = data["ssDashboards"];
            // console.log(ssDashboards);
            let apps = {};
            for(let i=0; i < ssDashboards.length; i++) {
              let details = ssDashboards[i];
              // console.log(details);
              apps[details.app] = details;
              //if (details["compareVPCurve"] === true || details["tendermintMainnet"] === true) {
              //  apps[details.app] = details;
              //}
            }
            // console.log(appList);
            if (ssDashboards && ssDashboards.length !== 0) {
                thisObj.setState({apps: apps});
            }
        }).catch(error => console.log(error))
    }
}

export default RestUtils;
