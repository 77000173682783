import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
// import paginationFactory from 'react-bootstrap-table2-paginator';
// import TablePagination from '@material-ui/core/TablePagination';
import Pagination from "react-pagination-js";
import "react-pagination-js/dist/styles.css"; // import css

import UITableUtils from "../util/UITableUtils";
import "./Richlist.css";
import AddressUtils from '../util/AddressUtils';

import currentNetwork from '../currentNetwork';
import SPCalc from '../util/SPCalc';
import ApiUtils from '../util/ApiUtils';
import Utilities from "../util/Utilities";
import UIUtils from "../util/UIUtils";
import SPUtilities from "../util/SPUtilities";
// import NetworkNav from '../network/NetworkNav';
import RespUtils from '../util/RespUtils';
import SessionUtils from '../util/SessionUtils';
import common from '../common';
import NetworkUtils from '../network/NetworkUtils';
import TokenUtils from '../token/TokenUtils';
import LogoUtils from '../util/LogoUtils';
import PageHeader from '../components/PageHeader';
import StatsUtils from '../stats/StatsUtils';
import UrlUtils from '../util/UrlUtils';

class RichlistByType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      width: window.innerWidth,
      size: 10,
      minBalance: "N/A",
      coinStat: {},
      relatedTokens: [],
      isLoading: true,
      currentPage: 1,
      rowsPerPage: 500,
      isAddressList: true,
      title: "- Address Richlist",
      delegationsInd: false,
    }
    this.updateDimensions = this.updateDimensions.bind(this);
    this.renderMoreNetworks = this.renderMoreNetworks.bind(this);
  }

  updateDimensions() {
    this.setState({width: window.innerWidth});
  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  changeCurrentPage = numPage => {
    // console.log("setting numPage to: ", numPage);
    this.setState({currentPage: numPage});
    this.loadData(numPage);
  }

  async componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    SessionUtils.setNetwork(this);
    
    UrlUtils.setUrlFragment(this.props.dataType);
    this.loadData(this.state.currentPage);
  }

  async loadData(currentPage) {
    let url = "richlist?pageSize=" + this.state.rowsPerPage + "&page=" + currentPage;
    let dataType = this.props.dataType;
    url += "&type=" + dataType;

    if (this.props.subType) {
      url += "&subType=" + this.props.subType; 
    }

    let allData = await ApiUtils.get(url);

    if (allData) {
      let data = allData["data"];
      let newData = Utilities.addIndexByPage(data, this.state.rowsPerPage, currentPage);

      let coinStat = allData["coinStat"];

      let totalRecords = allData["recordCount"];
      
      let isAddressList = true;
      let title = "Addresses";
      let delegationsInd = true;

      if (dataType === "delegates") {
        isAddressList = false;
        title = "Delegates";
      } else if(dataType == "delegations") {
        title = "Delegations";
        delegationsInd = true;
      } else if(this.props.subType && this.props.subType == "vesting") {
        title = "Vesting Addresses";
      }

      let totalPages = Math.ceil(totalRecords/this.state.rowsPerPage);
      this.setState({"data": newData, isLoading: false, "coinStat": coinStat, 
        minBalance: allData["minBalance"], "totalPages": totalPages, 
        "dataType": dataType, "totalRecords": totalRecords, "isAddressList": isAddressList,
        "title": title, "relatedTokens": allData["relatedTokens"], "delegationsInd": delegationsInd});
    }
  }

  render() {
    if (this.state.isLoading) {
      return <div>Loading</div>;
    }

    let isAddressList = this.props.dataType === "addresses";
    let hidePercent = (RespUtils.isMobileView() || !this.state.coinStat.totalSupply);
    let percentLabel = isAddressList ? "Percent Supply" : "Percent Staked";

    var columns = [
      {text: "#", dataField: "index", sort: true, headerStyle: Utilities.respWidth(10, 7, 5)},
      {text: "Address", dataField: "address", formatter: AddressUtils.addressFormatterByLabel, sort: true, headerStyle: Utilities.width(35)},
      {text: "Total ($" + currentNetwork.details.app.token + ")", dataField: "totalBalance", formatter: SPUtilities.stakeFormatterRounded, sort: true,  sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(15)},
      {text: percentLabel, hidden: hidePercent, dataField: "percentOfTotal", formatter: SPUtilities.percentFormatter, sort: true,  sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 12)},
      {text: "Staked Amount", hidden: !StatsUtils.showDelegationStat(), dataField: "totalStake", formatter: SPUtilities.stakeFormatterRounded, sort: true,  sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(15)},
      {text: "Not Staked", hidden: RespUtils.isMobileView(), dataField: "addressBalance", formatter: SPUtilities.stakeFormatterRounded, sort: true,  sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(10)},
      {text: "USD Value", hidden: (RespUtils.isMobileView() || !this.state.coinStat.usdPrice), dataField: "totalBalance", formatter: SPCalc.calcTotalUSD, formatExtraData: this, sort: true,  sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(10)},
    ];

    const options = UIUtils.getPageOptionsLarge(this);
    let wrapperClasses = UITableUtils.isDefaultView() ? "table":"table-responsive";

    let title = this.state.title;

    return (
      <div>
        <PageHeader title={title} thisObj={this} record={this.state.tokenDetails} hideSpecial={true}
            additionalLink={this.renderMoreNetworks} disableStakeFormatter={true} logo={LogoUtils.networkLogo}/>
        <p>Total {title}: {this.state.totalRecords} (&gt;&#61; {this.state.minBalance} ${currentNetwork.details.app.token}). Data shown may be outdated by few hours. Want alias (known exchanges/ team/ foundation) for any of the addresses? Submit <a href={common.urls.aliasUrl}>here</a></p>
        
        {this.renderVestingAddressLink()}
        <div className="richlistPagination"><Pagination currentPage={this.state.currentPage} theme="dark" totalSize={this.state.totalRecords}
          sizePerPage={this.state.rowsPerPage} changeCurrentPage={this.changeCurrentPage} numberOfPagesNextToActivePage={Utilities.respLabel(3, 5, 7)} /></div>
        <p/>
        <BootstrapTable keyField='index' data={ this.state.data }
          columns={ columns } striped expandableRow={ () => { return true; } } options={options}
          condensed noDataIndication="No data found"
          wrapperClasses={wrapperClasses}/>
        <div className="richlistPagination"><Pagination currentPage={this.state.currentPage} theme="dark" totalSize={this.state.totalRecords}
          sizePerPage={this.state.rowsPerPage} changeCurrentPage={this.changeCurrentPage} numberOfPagesNextToActivePage={Utilities.respLabel(3, 5, 7)} /></div>
      </div>
    );
  }

  renderMoreNetworks() {
    return NetworkUtils.renderTokenSwitch(this, this.state.coinStat.symbol);
  }
  
  // onTokenSelect(e) {
  //   window.location = SessionUtils.getUrl("/tokens/" + e.target.value);
  //   // window.location = SessionUtils.getUrl("/tokens/" + e.token);
  //   // TokenUtils.navToTokenPage(e);
  // }

  onOtherTokenSelect(e) {
    TokenUtils.navToOtherTokenPage(e);
    // let value = e.target.value;
    // // console.log(value);
    // let details = value.split(",");
    // // console.log(details);
    // if (details.length === 2) {
    //   let context = common.allNetworks[details[0]];
    //   if (context) {
    //     window.location = "/" + context + "/tokens/" + details[1];
    //   }
    // }
  }

  renderVestingAddressLink(){
    if (this.props.dataType === "addresses" && common.txs.vestingNetworks.includes(currentNetwork.details.app.token)) {
      if (this.props.subType) {
        return (<a className="animLinkVisual" href={SessionUtils.getUrl("stats/addresses")}>Show All Addresses</a>);
      } else {
        return (<a className="animLinkVisual" href={SessionUtils.getUrl("stats/addresses/vesting")}>Show Vesting Addresses</a>);
      }
  
    }

    return "";
  }

}

export default RichlistByType;
