import React from 'react';
import {ResponsiveContainer, LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, Label, Legend } from 'recharts';

import SPCalc from '../util/SPCalc';
import UIUtils from '../util/UIUtils';
import Utilities from '../util/Utilities';
import ChartUtils from '../util/ChartUtils';

import "../styles/charts.css";
import ChartTitle from "../components/ChartTitle";
import SavedSettings from "../util/SavedSettings";
import DownloadUtils from "../util/DownloadUtils";
import common from "../common";
import currentNetwork from '../currentNetwork';
import ChartData from './ChartData';


class MultiLineChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      responsive: true,
      lineProps: [],
      lineHover: null,
      mode: common.charts.mode.default
    }

    this.myRef = React.createRef();
    this.flipResponsive = this.flipResponsive.bind(this);
    this.switchMode = this.switchMode.bind(this);
  }

  flipResponsive() {
    ChartUtils.flipResponsive(this);
  }

  async componentDidMount() {
    let lineProps = {};
    
    for(let i=0; i < this.props.valueAttr.length; i++) {
      let value = this.props.valueAttr[i];
      lineProps[value] = true;
    }

    if (this.props.compareAttr != null) {
      lineProps[this.props.compareAttr] = true;
    }

    this.setState({lineProps: lineProps});
  }

  renderLines() {
    let textColor = common.colors.text;
    let width = UIUtils.getResponsiveWidth(this);
    let height = UIUtils.getFSChartHeight(this);
    let colors = common.colors.chartColorsArray;
    let index = -1;
    let lineColorCompare = common.colors.chartColor3;
    let yAxisColor = textColor;

    if (this.props.yAxisColor) {
      yAxisColor = this.props.yAxisColor;
    }

    const handleLegendMouseEnter = (e) => {
      this.setState({lineHover: e.dataKey});
    };
    const handleLegendMouseLeave = (e) => {
      this.setState({lineHover: null});
    };
    const selectLine = (e) => {
      let lineProps = this.state.lineProps;
      let selectedItem = e.dataKey;

      let itemVisibility = lineProps[selectedItem];

      itemVisibility = !itemVisibility;
      lineProps[selectedItem] = itemVisibility;
      this.setState({lineProps: lineProps});
    };

    const CustomTooltip = ({ active, payload, label }) => {
      if (active) {
        if (payload === null || payload.length === 0) {
          return null;
        }

        let data = payload[0].payload;
        let tooltipIndex = -1;

        return (<div style={{"margin": "15px", "padding": "15px"}}>
            <table><tbody>
              <tr>
                <th>{this.props.xAxis}:</th>
                <th>{data[this.props.xAxisValueAttr]}</th>
              </tr>
              {
                this.props.valueAttr.map((id) => {
                    tooltipIndex++;
                    return (
                      <tr key={id}>
                        <th><font color={colors[tooltipIndex]}>{id}:</font></th>
                        <th><font color={colors[tooltipIndex]}>{SPCalc.formatNumberValue(data[id])}</font></th>
                      </tr>
                    )
                })
              }
              {this.props.compareAttr && (
                <tr key={this.props.compareAttr}>
                  <th><font color={lineColorCompare}>{this.props.compareAttr}:</font></th>
                  <th><font color={lineColorCompare}>{SPCalc.formatNumberValue(data[this.props.compareAttr])}</font></th>
                </tr>
              )}
              {
                this.props.extraData4Tooltip && this.props.extraData4Tooltip.map((id) => {
                    tooltipIndex++;
                    return (
                      <tr key={id}>
                        <th><font color={colors[tooltipIndex]}>{id}:</font></th>
                        <th><font color={colors[tooltipIndex]}>{data[id]}</font></th>
                      </tr>
                    )
                })
              }
            </tbody></table>
          </div>);
      }

      return null;
    };

    // console.log(this.props.title);
    //let range = Utilities.getRangeWithZoom(true, this);
    let range;
    if (this.props.adjustRange && this.props.adjustRange === true) {
      range = Utilities.getRange(true, this.props.data, this.props.rangeAttr);
    } else if (this.props.rangeAttrs) {
      // console.log("getting getRangeWithUpperAndLowerFactorMulti for : ", this.props.rangeAttrs);
      range = Utilities.getRangeWithUpperAndLowerFactorMulti(true, this.props.data,
        this.props.rangeAttrs[0], this.props.rangeAttrs[1], [0, 1])
    } else {
      range = Utilities.getRangeWithBase0(true, this.props.data, this.props.rangeAttr);
    }

    // console.log(this.props.title + " - " + range);

    let showDots = false;
    if (this.props.dots) {
      showDots = true;
    }
    
    return (
      <div>
        <ResponsiveContainer width={width} height={height}>
          <LineChart data={this.props.data}
            margin={UIUtils.getChartMargin(this)}>
            <CartesianGrid vertical={false} horizontal={false} stroke={textColor} strokeDasharray="3 3"/>
            <XAxis dataKey={this.props.xAxisValueAttr} stroke={textColor} padding={{ top: 10 }} angle={-10} />
            {this.props.formatValues !== null ? <YAxis stroke={yAxisColor} tickFormatter={SPCalc.formatNumberValue} domain={range} /> : <YAxis stroke={textColor} domain={range} />}
            {this.props.compareAttr && (<YAxis yAxisId="right" orientation="right" stroke={lineColorCompare} tickFormatter={SPCalc.formatNumberValue} />)}
            {this.props.formatValues !== null ? <Tooltip content={<CustomTooltip labelStyle={ {color: 'black'}} />} /> : <Tooltip labelStyle={ {color: 'black'}} />}
            {
              this.props.valueAttr.map((id) => {
                // console.log(id, " : ", this.state.lineHover);
                // console.log(Number(this.state.lineHover === id || !this.state.lineHover ? 1 : 0.6))
                index++;
                return (<Line type="monotone" key={colors[index]} dataKey={id} strokeWidth={3} connectNulls={true}
                  hide={this.state.lineProps[id] === false} opacity={Number(this.state.lineHover === id || !this.state.lineHover ? 1 : 0.3)}
                  label={false} dot={showDots === true ? this.props.dots[index] : false} stroke={colors[index]} />)
              })
            }
            {this.props.showLegend && (<Legend onClick={selectLine} onMouseOver={handleLegendMouseEnter} onMouseOut={handleLegendMouseLeave} />)}
            {this.props.compareAttr && (<Line yAxisId="right" type="monotone" dataKey={this.props.compareAttr} strokeWidth={3} dot={false} stroke={lineColorCompare}
                  hide={this.state.lineProps[this.props.compareAttr] === false} opacity={Number(this.state.lineHover === this.props.compareAttr || !this.state.lineHover ? 1 : 0.3)}
              />)}
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }

  render() {
    let tableInd = this.state.mode === common.charts.mode.table;
    let chartVisualId = currentNetwork.details.networkName + "-" + (tableInd ? "" : "data") + this.props.chartVisualId;
    const downloadChart = () => {
      ChartUtils.downloadChart(document, chartVisualId, currentNetwork.details.app.title);
    };

    return (
      <div id={"div" + chartVisualId}>
        <ChartTitle title={this.props.title} tip={this.props.tip} handleChart={this.props.handleChart} 
          shortTitle={this.props.shortTitle} disableDay={this.props.disableDay} 
          statType={this.props.statType} isOverallOnly={this.props.isOverallOnly} disableWeek={this.props.disableWeek} 
          addDays={this.props.addDays} displayHelpIcon={this.props.displayHelpIcon} fsElementId={this.props.fsElementId}
          frequency={this.props.frequency} showHelp={this.showHelp} frequencies={this.props.frequencies} hideFullScreenLink={this.props.hideFullScreenLink}
          downloadData={DownloadUtils.getDownloadDataForChart(this.props.data, this.props.xAxisValueAttr, this.props.valueAttr, this.props.compareAttr)}
          saveChart={downloadChart} enableSave={this.props.enableSave} 
          mode={this.state.mode} switchMode={this.switchMode} columns={this.props.columns}
          showAdditionalLink={this.props.showAdditionalLink} renderAdditionalLink={this.props.renderAdditionalLink} 
        />
        <div align="center">
          {this.props.subtitle && <div className="compSubTitle">{this.props.subtitle}</div> }
          {this.props.showLatestLabel ? this.props.latestLabelPrefix + ": " + Utilities.getLastRecordAttributeRound(this.props.data, this.props.valueAttr[0], this.props.roundLatestValue) + "." : ""}
          {this.props.showTwoLatestLabel ? this.props.latestLabelPrefix + ": " + Utilities.getLastRecordAttributeRound(this.props.data, this.props.valueAttr[0], this.props.roundLatestValue) + "/" + Utilities.getLastRecordAttribute(this.props.data, this.props.valueAttr[1], this.props.roundLatestValue) : ""}
          {tableInd ? <ChartData data={this.props.data} columns={this.props.columns} dataKey={this.props.dataKey} sortBy={this.props.sortBy} /> : this.renderLines()}
        </div>
        {ChartUtils.getLandscapeMsg()}
      </div>
    );
  }

  closeHelp() {
    SavedSettings.setHelpNotNeeded(this, this.props.chartName);
  }

  showHelp() {
    SavedSettings.setHelpNeeded(this, this.props.chartName);
  }

  switchMode() {
    let currentMode = this.state.mode;
    if (currentMode === common.charts.mode.chart) {
      this.setState({mode: common.charts.mode.table});
    } else {
      this.setState({mode: common.charts.mode.chart});
    }
  }
}

export default MultiLineChart;
