import React from 'react';
import packageJson from '../package.json';
global.appVersion = packageJson.version;

const CACHE_REFRESH_KEY = "CACHE_REFRESH_KEY_V1";
const EXPIRY_TIME = 3600000;

// version from response - first param, local version second param
const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

class CacheBuster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isLatestVersion: false,
      refreshCacheAndReload: () => {
        console.log('Clearing cache and hard reloading...')
        if (caches) {
          // Service worker cache should be cleared with caches.delete()
          caches.keys().then(function(names) {
            for (let name of names) caches.delete(name);
          });
        }

        navigator.serviceWorker.getRegistrations()
        .then(registrations => {
          registrations.forEach(registration => {
            registration.update();
          }) 
        });

        if (CacheBuster.isReadyForCacheReload()) {
          // delete browser cache and hard reload
          console.log("performing actual cache reload");
          window.location.reload(true);
        }
      }
    };
  }

  componentDidMount() {
    fetch('/meta.json')
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version;
        const currentVersion = global.appVersion;

        const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
        if (shouldForceRefresh) {
          console.log(`We have a new version - ${latestVersion}. Current version - ${currentVersion}. Should force refresh`);
          this.setState({ loading: false, isLatestVersion: false });
        } else {
          console.log(`You already have the latest version - ${latestVersion}. Current version - ${currentVersion}. No cache refresh needed.`);
          this.setState({ loading: false, isLatestVersion: true });
        }
      });
  }

  render() {
    const { loading, isLatestVersion, refreshCacheAndReload } = this.state;
    return this.props.children({ loading, isLatestVersion, refreshCacheAndReload });
  }

  static isReadyForCacheReload() {
    // delete browser cache and hard reload
    console.log("checking isReadyForCacheReload");
    let lastRefreshTime = window.localStorage.getItem(CACHE_REFRESH_KEY);
    let currentTime = Date.now();

    let reloadInd = false;
    if (lastRefreshTime != null) {
      console.log("previous cache time: ", lastRefreshTime);
      let cacheExpiryTime = parseInt(lastRefreshTime) + EXPIRY_TIME;
      console.log("cache expiry time: ", cacheExpiryTime, ", currentTime: ", currentTime, ", gap: ", (cacheExpiryTime - currentTime));
      if (cacheExpiryTime < currentTime) {
        console.log("lot of time has passed since last refresh");
        reloadInd = true; 
      }
    } else {
      reloadInd = true; 
    }

    if (reloadInd === true) {
      console.log("setting current time as cache refreshed: ", currentTime);
      window.localStorage.setItem(CACHE_REFRESH_KEY, currentTime);
    }

    console.log("cache buster time checker done. reloadInd: ", reloadInd, ", lastRefreshTime: ", lastRefreshTime,
      ", EXPIRY_TIME: ", EXPIRY_TIME, ", current time: ", Date.now());

    return reloadInd;
  }

}

export default CacheBuster;