import React from 'react';


class DataUtils extends React.Component {

  static addIndex(data, key) {
    if (!data) {
      return data;
    }

    for (let i = 0, tableDataLen = data.length; i < tableDataLen; i++) {
        data[i]["index"] = i + 1;
    }

    return data;
  }

  static addCumulativeWeight(data, key) {
    let cumulativeWeight = 0
    for(let i=0;i < data.length; i++) {
        let val = data[i];
        cumulativeWeight += val[key];
        val.cumulativeWeight = cumulativeWeight;
    }
  }

  static moveElementToTop(data, key, value) {
    if (!data) {
      return data;
    }

    // console.log(con fig.apiGa teway.DEFAULT _POOL_ID);
    for(let i=0; i < data.length; i++) {
      // console.log(data[i]);
      // console.log(data[i].code);
      if (data[i][key] === value) {
        // console.log("match found");
        let val = data[i];
        let item = data.splice(i,1);
        // console.log("removed item: ", item);
        data.unshift(val);
        break;
      }
    }

    return data;
  }

  static addCumulativeData(data, inputAttr, cumulativeAttr) {
    let cumulativeData = 0;
    for(let i=0;i < data.length; i++) {
      let record = data[i];
      cumulativeData += parseFloat(record[inputAttr]);
                
      record[cumulativeAttr] = cumulativeData;
    }

    return data;
  }

  static getIndexArray(count) {
    let indexes = []
    for (let i = 0; i < count; i++) {
      indexes.push({"index": i+1});
    }

    // console.log("indexes are: ", indexes);
    return indexes;
  }

  static removeZeroRecords(data, key) {
    if (!data) {
      return data;
    }

    // console.log(key)
    let finalData = []
    for (let i = 0, tableDataLen = data.length; i < tableDataLen; i++) {
      // console.log(data[i][key], " - ", data[i])
      if (data[i][key] !== null && parseInt(data[i][key]) !== 0) {
        finalData.push(data[i])
      }
    }
    // console.log("initial data: ", data.length, ", final data: ", finalData.length);

    return finalData;
  }

  static limitLength(str, maxLength) {
    if (str.length > maxLength) {
        return str.substring(0, maxLength);
    } else {
        return str;
    }
  }

  static setAllToFalse(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
          obj[key] = false;
      }
    }
    return obj;
  }

}

export default DataUtils;
