import React from "react";
import {ResponsiveContainer, Line, Cell, XAxis, YAxis, CartesianGrid, Tooltip} from 'recharts';
import {Bar } from 'recharts';
import ComposedChart from "recharts/lib/chart/ComposedChart";
import Legend from "recharts/lib/component/Legend";
import Tour from "reactour";

import '../styles/section.css';
import "../styles/charts.css";
import UIUtils from '../util/UIUtils';
import Utilities from '../util/Utilities';
import ChartUtils from '../util/ChartUtils';
import ChartTitle from "../components/ChartTitle";
import SPCalc from "../util/SPCalc";
import SavedSettings from "../util/SavedSettings";
import "./BidirectionChangeAndCumulativeChart.css";
import DownloadUtils from "../util/DownloadUtils";
import currentNetwork from "../currentNetwork";
import ChartData from './ChartData';
import common from "../common";

class BidirectionChangeAndCumulativeChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      responsive: true,
      isHelpOpen: false,
      mode: common.charts.mode.default
    }

    this.flipResponsive = this.flipResponsive.bind(this);
    this.closeHelp = this.closeHelp.bind(this);
    this.showHelp = this.showHelp.bind(this);
    this.switchMode = this.switchMode.bind(this);
  }

  flipResponsive() {
    ChartUtils.flipResponsive(this);
  }

  componentDidMount() {
    // this.setState({isHelpOpen: SavedSettings.isHelpNeeded(this.props.chartName)});
  }
  
  renderBar() {
    const CustomTooltip = ({ active, payload, label }) => {
        // console.log(active, "-", payload, "-", label);
        if (active) {
          if (payload === null || payload.length === 0) {
            return null;
          }
  
          let data = payload[0].payload;
          const valueAttrColor = data[this.props.valueAttr] > 0 ? "green" : "red";

          return (<div style={{"margin": "0px", "padding": "0px"}}>
              <table><tbody>
                <tr>
                  <td>{this.props.xAxis}:</td>
                  <td>{data[this.props.xAxisValueAttr]}</td>
                </tr>
                <tr>
                    <td><font color={valueAttrColor}>{this.props.valueAttr}:</font></td>
                    <td><font color={valueAttrColor}>{SPCalc.formatNumberValue(data[this.props.valueAttr])}</font></td>
                </tr>
                <tr>
                    <td><font color={lineColor}>{this.props.cumulativeAttr}:</font></td>
                    <td><font color={lineColor}>{SPCalc.formatNumberValue(data[this.props.cumulativeAttr])}</font></td>
                </tr>
              </tbody></table>
            </div>);
        }
  
        return null;
      };
  
    let width = UIUtils.getResponsiveWidth(this);
    let height = UIUtils.getFSChartHeight(this);
    let showDataLabel = UIUtils.getShowDataLabel(this, this.props.data, 600);
    let barSize = 50;
    if (this.props.data.length > 25) {
      barSize = barSize * 25/this.props.data.length;
      if (barSize < 5) {
        barSize = 5;
      }
    }

    // {/* <Label value={this.props.xAxis} offset={-3} position="insideBottom" /> */}
    // {/* <Label value={this.props.yAxis} offset={8} position="insideBottomLeft" angle={-90} /> */}
    let textColor = "white";
    let lineColor = "#faa84b";
    return (
      <div>
        <ResponsiveContainer width={width} height={height}>
          <ComposedChart data={this.props.data} barCategoryGap={2} stackOffset="sign"
              margin={{top: 10, right: 15, left: 15, bottom: 0}}>
            <CartesianGrid vertical={false} horizontal={false} strokeDasharray="3 3"/>
            <XAxis dataKey={this.props.xAxisValueAttr} stroke={textColor} padding={{ top: 10 }} />
            <YAxis className="mainYAxis" domain={Utilities.getRangeWithBase0(true, this.props.data, this.props.valueAttr)}
              stroke={textColor} tickFormatter={SPCalc.formatNumberValue} />
            <YAxis yAxisId="right" orientation="right" stroke={lineColor} tickFormatter={SPCalc.formatNumberValue} />
            <Legend />
            <Tooltip content={<CustomTooltip />} />
            {/* {this.props.formatValues !== null ? <YAxis stroke={textColor} tickFormatter={SPCalc.formatNumberValue} domain={range} /> : <YAxis stroke={textColor} domain={range} />} */}

            {showDataLabel && <Bar maxBarSize={50} strokeWidth={2} dataKey={this.props.valueAttr} onClick={this.props.onClick} label={{ angle: -90, position: 'center' }}>
              {
                this.props.data.map((entry, index) => {
                	const color = entry[this.props.valueAttr] > 0 ? "green" : "red";
                	return <Cell fill={color} />;
                })
              }
            </Bar>}
            {!showDataLabel && <Bar maxBarSize={50} barSize={barSize} dataKey={this.props.valueAttr} onClick={this.props.onClick}>
              {
                this.props.data.map((entry, index) => {
                  const color = entry[this.props.valueAttr] > 0 ? "green" : "red";
                  return <Cell key={color} fill={color} />;
                })
              }
            </Bar>}
            <Line yAxisId="right" type="monotone" dataKey={this.props.cumulativeAttr} strokeWidth={3} dot={false} stroke={lineColor}/>
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    );
  }

  // <p className="compTitle">{this.props.title}</p>
  render () {
    let tableInd = this.state.mode === common.charts.mode.table;
    let chartVisualId = currentNetwork.details.networkName + "-" + (tableInd ? "" : "data") + this.props.chartVisualId;
    const downloadChart = () => {
      ChartUtils.downloadChart(document, chartVisualId, currentNetwork.details.app.title);
    };

    return (
      <div id={"div" + chartVisualId}>
        {this.props.tour !== null && (<Tour steps={this.props.tour} isOpen={this.state.isHelpOpen} onRequestClose={this.closeHelp} className="ssTour" />)}
        <ChartTitle title={this.props.title} tip={this.props.tip} addDays={this.props.addDays} handleChart={this.props.handleChart} 
          disableDay={this.props.disableDay} disableWeek={this.props.disableWeek} displayHelpIcon={this.props.displayHelpIcon}
          frequency={this.props.frequency} showHelp={this.showHelp} frequencies={this.props.frequencies} fsElementId={this.props.fsElementId}
          showAdditionalLink={this.props.showAdditionalLink} renderAdditionalLink={this.props.renderAdditionalLink} shortTitle={this.props.shortTitle}
          downloadData={DownloadUtils.getDownloadDataForChart(this.props.data, this.props.xAxisValueAttr, [this.props.valueAttr, this.props.cumulativeAttr])} 
          saveChart={downloadChart} enableSave={this.props.enableSave} 
          mode={this.state.mode} switchMode={this.switchMode} columns={this.props.columns}
          />
        <p/>
        <div align="center">
          {this.props.showTotalLabel ? Utilities.getTotalWithLabel(this.props.data, this.props.valueAttr, this.props.totalLabel) : ""}
          {this.props.subtitle && <div className="compSubTitle">{this.props.subtitle}</div> }
          {tableInd ? <ChartData data={this.props.data} columns={this.props.columns} dataKey={this.props.dataKey} sortBy={this.props.sortBy} /> : this.renderBar()}
        </div>
        {ChartUtils.getLandscapeMsg()}
      </div>
    );
  }

  closeHelp() {
    SavedSettings.setHelpNotNeeded(this, this.props.chartName);
  }

  showHelp() {
    SavedSettings.setHelpNeeded(this, this.props.chartName);
  }

  switchMode() {
    let currentMode = this.state.mode;
    if (currentMode === common.charts.mode.chart) {
      this.setState({mode: common.charts.mode.table});
    } else {
      this.setState({mode: common.charts.mode.chart});
    }
  }
}

export default BidirectionChangeAndCumulativeChart;
