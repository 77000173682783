import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import {Container} from 'react-bootstrap';
import filterFactory, {selectFilter, textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Table from 'react-bootstrap/Table';

import SPCalc from "../util/SPCalc";
import SPUtilities from "../util/SPUtilities";
import Utilities from "../util/Utilities";
import RespUtils from "../util/RespUtils";
import UIUtils from "../util/UIUtils";
import UITableUtils from "../util/UITableUtils";
import CoinUtils from '../util/CoinUtils';
import TxUtils from './TxUtils';
import currentNetwork from '../currentNetwork';

const eventsKey = "eventsKey_v2";

class TxsInline extends React.Component {
  constructor(props) {
    super(props);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.getInitColumns = this.getInitColumns.bind(this);
  }

  updateDimensions() {
    this.setState({width: window.innerWidth});
  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  render() {
    let initCols = this.getInitColumns();
    // console.log(initCols);
    const headerSortingClasses = (column, sortOrder, isLastSorting, colIndex) => (
      sortOrder === 'asc' ? 'sorting-asc' : 'sorting-desc'
    );
    // const selectOptions = [...new Set(this.props.data.map(item => item.group))]
    // const typeOptions = {
    //   'Delegate': 'Delegate',
    //   1: 'Bad',
    //   2: 'unknown'
    // };
    const typeOptions = UITableUtils.getSelectFilterOptions(this.props.data, "txTypeDesc");

    var columns = [
      {text: "#", desc: "Index", hidden: this.isHidden(initCols, "index"), dataField: "index", sort: true, align: "left", headerStyle: Utilities.respWidth(5, 5, 3), headerSortingClasses},
      {text: "Time Passed", hidden: this.isHidden(initCols, "elapsedTime"), dataField: "elapsedTime", formatter: TxUtils.formatElapsedTimeAndLink, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 8)},
      {text: "Block", hidden: this.isHidden(initCols, "blockNumber"), dataField: "blockNumber", sort: true, headerStyle: Utilities.respWidth(15, 15, 7)},
      {text: "Tx Type", filter: selectFilter({placeholder: "--", options: typeOptions, className: 'tableFilterStyle'}), hidden: this.isHidden(initCols, "txTypeDesc"), dataField: "txTypeDesc", formatter: TxUtils.formatMsgTypeAndLink, sort: true, headerStyle: Utilities.respWidth(25, 20, 15)},
      {text: "Amount (" + currentNetwork.details.app.token + ")",  desc: ("Token amount"), hidden: this.isHidden(initCols, "amount"), dataField: "amount", formatter: SPUtilities.stakeFormatterRounded, sort: true, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
      {text: "USD Amount", desc: ("USD amount"), hidden: this.isHidden(initCols, "usdAmount"), dataField: "usdAmount", formatter: CoinUtils.moneyFormatterRoundTwoIfNeeded, sort: true, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
      {text: "Address", filter: textFilter({placeholder: "Address", className: 'tableFilterStyle'}), desc: ("address"), hidden: this.isHidden(initCols, "fromAddress"), dataField: "fromAddress", formatter: TxUtils.formatAddress, sort: true, headerStyle: Utilities.respWidth(20, 20, 12), headerSortingClasses},
      {text: "To Address", desc: ("to address"), hidden: this.isHidden(initCols, "toAddress"), dataField: "toAddress", formatter: TxUtils.formatAddress, sort: true, headerStyle: Utilities.respWidth(20, 20, 9), headerSortingClasses},
      {text: "Validator", hidden: this.isHidden(initCols, "validatorName"), dataField: "validatorName", formatter: TxUtils.formatValidator, sort: true, headerStyle: Utilities.respWidth(25, 15, 14), headerSortingClasses},
      {text: "Previous Validator", hidden: this.isHidden(initCols, "previousValName"), dataField: "previousValName", formatter: TxUtils.formatPreviousValidator, sort: true, headerStyle: Utilities.respWidth(25, 15, 10), headerSortingClasses},
      {text: "Timestamp", hidden: this.isHidden(initCols, "epochTimestamp"), dataField: "epochTimestamp", formatter: SPUtilities.epochFormatter, sort: true, headerStyle: Utilities.respWidth(25, 20, 15)},
      // {text: "Block Number", hidden: this.isHidden(initCols, "blockNumber"), dataField: "blockNumber", sort: true, headerStyle: Utilities.respWidth(15, 15, 10)},
      {text: "Details",  desc: ("desc"), hidden: this.isHidden(initCols, "desc"), dataField: "txHash", formatter: TxUtils.formatMsgDesc, formatExtraData: this, sort: true, sortValue: TxUtils.formatMsgDescSort, headerStyle: Utilities.respWidth(65, 60, 25), headerSortingClasses},
    ];

    // console.log(columns);

    let pageSize = this.props.pageSize ? parseInt(this.props.pageSize) : 10;
    const options = UIUtils.getPageOptionsSmall(this, pageSize);

    const expandRow = {
      onlyOneExpanding: true,
      renderer: row => this.showRowDetails(row)
    };
    let wrapperClasses = UITableUtils.isDefaultView() ? "table":"table-responsive";

    return (
      <div style={{"overflow-x": "hidden"}}>
        <BootstrapTable keyField='index' data={ this.props.data }
          columns={columns} striped filter={ filterFactory() } filterPosition="top"
          options={options} wrapperClasses={wrapperClasses}
          condensed noDataIndication="No transactions found"
          expandRow={ expandRow } expandableRow={ () => { return true; } }
          pagination={paginationFactory(options)} />
      </div>
    );
  }

  showRowDetails(row) {
    return (
      <Container fluid className="containerLayout container-fluid chartContainer">
      <div class="chartGridContainer">
        <div class="chartBg">
          <Table striped bordered size="sm">
            <tbody>
              <tr>
                <th align="left">Time Passed: </th>
                <td align="left"> {SPCalc.formatElapsedTime(row.elapsedTime, row)}</td>
              </tr>
              <tr>
                <th align="left">Type: </th>
                <td align="left"> {row.txTypeDesc}</td>
              </tr>
              <tr>
                <th align="left">Block Number: </th>
                <td align="left"> {row.blockNumber}</td>
              </tr>
              <tr>
                <th align="left">Timestamp: </th>
                <td align="left"> {SPUtilities.epochFormatter(row.epochTimestamp, row)}</td>
              </tr>
              <tr>
                <th align="left">Address: </th>
                <td align="left"> {TxUtils.formatAddress(row.fromAddress, row)}</td>
              </tr>
              <tr>
                <th align="left">To Address: </th>
                <td align="left"> {TxUtils.formatAddress(row.fromAddress, row)}</td>
              </tr>
              <tr>
                <th align="left">Amount: </th>
                {(row.amount && row.amount != null) ? 
                  (<td align="left"> {SPUtilities.stakeFormatterRounded(row.amount, row)} {currentNetwork.details.app.token} 
                    / {CoinUtils.moneyFormatterRoundTwoIfNeeded(row.usdAmount, row)}</td>)
                  : (<td align="left"></td>)}
              </tr>
              <tr>
                <th align="left">Validator: </th>
                <td align="left"> {TxUtils.formatValidator(row.validatorName, row)}</td>
              </tr>
              <tr>
                <th align="left">Previous Validator: </th>
                <td align="left"> {TxUtils.formatPreviousValidator(row.previousValName, row)}</td>
              </tr>
            </tbody>
          </Table>
          <hr/>
        </div>
      </div>
    </Container>);
  }


  getInitColumns() {
    // console.log(this.props.context);
    // console.log(this.props.category);
    let columnsConfig = {};
    if (RespUtils.isTabletView()) {
      columnsConfig = {
        index: false, amount: false, usdAmount: true, 
        fromAddress: true, toAddress: true,
        txHash: true, txTypeDesc: true, blockNumber: true,
        desc: false, 
        validatorName: true, previousValName: true,
        epochTimestamp: true
      };
    } else {
      let context = this.props.context;
      let category = this.props.category;
      let hideToAddress = (category != "large" && category != "vesting") || context === "validator";
      let hideValName = (context === "validator" && (category === "delegation" || category === "undelegation"));
      let prevValName = (category != "redelegation" && category != "staking" && category != "large" && category != "vesting");

      columnsConfig = {
          index: false, amount: false, usdAmount: false, 
          fromAddress: false, toAddress: hideToAddress,
          txHash: false, txTypeDesc: false, blockNumber: true,
          desc: true, epochTimestamp: false,          
          validatorName: hideValName, previousValName: prevValName
      };
    }

    // console.log("tx - getInit - columns config is: ", columnsConfig);
    return columnsConfig;
  }

  isHidden(initCols, dataField) {
    return initCols[dataField];
  }

}

export default TxsInline;
