import React from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import ApiUtils from '../util/ApiUtils';
import tooltips from "../tooltips";
import '../styles/section.css';
import currentNetwork from '../currentNetwork';
import VPCurveChart from './VPCurveChart';
import NetworkChartUtils from './NetworkChartUtils';

class VPCurveComparison extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      width: window.innerWidth,
      selectedAppsForVPCurve: [],
      vpCurves: {},
      curveIndexes: [],
    }

    // this.showVPCurveChart = this.showVPCurveChart(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.setSelectedAppsForVPCurve = this.setSelectedAppsForVPCurve.bind(this);
    this.updateVPCurve = this.updateVPCurve.bind(this);
  }

  async setSelectedAppsForVPCurve(e) {
    this.setState({"selectedAppsForVPCurve": e});
    await this.updateVPCurve(e);
  }

  async updateVPCurve(appList) {
    // console.log(appList);
    let apps = "";
    for (let i = 0; i < appList.length; i++) {
      if (i !== 0) {
        apps += ",";
      }
      apps += appList[i]["value"];
    }
    let url = "listData?type=vpcurve&chains=" + apps;

    // console.log(url);
    const allData = await ApiUtils.get(url);
    // console.log("allData is:", allData);

    let vpCurves = allData["vpCurves"];

    this.setState({"vpCurves": vpCurves, "curveIndexes": NetworkChartUtils.getIndexes(appList, vpCurves),
      "isLoading": false});
  }

  // getIndexes(appList, vpCurves) {
  //   let valCount = 0;

  //   for (const appDetails of appList) {
  //     let app = appDetails["value"];
  //     // console.log(app);
      
  //     let curve = vpCurves[app];

  //     if (curve) {
  //       let currentCount = curve.length;
  //       if (valCount < currentCount) {
  //         valCount = currentCount;
  //       }
  //     }
  //   }

  //   return DataUtils.getIndexArray(valCount);
  // }

  updateDimensions() {
    this.setState({width: window.innerWidth});
  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  async componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);

    let selectedAppsForVPCurve = [{label: currentNetwork.details.app.appName, value: currentNetwork.details.app.token},
      {label: currentNetwork.details.others.APP_LABEL_VP_COMP, value: currentNetwork.details.others.APP_KEY_VP_COMP}]
    this.setState({selectedAppsForVPCurve: selectedAppsForVPCurve});
    this.updateVPCurve(selectedAppsForVPCurve);
  }

  render() {
    if (this.state.isLoading) {
      return <div>Loading</div>;
    }

    return (
      <div className="chartBg" id="vpCurveComparison">
        <VPCurveChart title="VP Curve Comparison" shortTitle="VP Comparison" 
          chartVisualId = {"vp-curve-comparison"}
          xAxis="Validator Count" yAxis="Total Voting Power" adjustRange={true} 
          showVerticalLabel={false} valueAttr={["cumulativeVotingPower", "cumulativeAverageVotingPower"]} 
          rangeAttr="cumulativeVotingPower" showTotalLabel={false} chains={this.state.selectedAppsForVPCurve}
          xAxisValueAttr="index" data={this.state.vpCurves} tip={tooltips.networkCharts.vpCurveComparison} 
          formatValues={false} showLegend={true} curveIndexes={this.state.curveIndexes} fsElementId="vpCurveComparison"
          selectedAppsForVPCurve={this.state.selectedAppsForVPCurve} setSelectedAppsForVPCurve={this.setSelectedAppsForVPCurve}/>
      </div>
    );
  }
  

}

export default VPCurveComparison;
