import React from "react";
import {Navbar, Nav, Modal, Button} from 'react-bootstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faSearch } from '@fortawesome/free-solid-svg-icons';
import SearchIcon from '@material-ui/icons/Search';

import Dashboards from "./components/Dashboards";
import currentNetwork from "./currentNetwork";
import RespUtils from "./util/RespUtils";
import './NavBar.css';
import SessionUtils from "./util/SessionUtils";
import constants from "./constants";
import common from "./common";
import GlobalSearchModal from './components/GlobalSearchModal';

class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      showModal: false
    }
  }

  async componentDidMount() {
    SessionUtils.activateOnLoad(this);
  }

  toggleModal = () => {
    this.setState(prevState => ({ showModal: !prevState.showModal }));
  }

  render() {
    if (!this.state.isLoading && currentNetwork.details.APP_KEY === "LUNA") {
      return this.render4Terra();
    }

    return (
      <Navbar collapseOnSelect expand="lg" variant="light" sticky="top" className="customNavBar">
        <Navbar.Brand href={SessionUtils.getUrl("/")}><img src="/images/smartstake.png" id = "mainLogo"
          className="d-inline-block align-top" alt={"Smart Stake Analytics - " + currentNetwork.details.app.title}
          /><span className="mainTitle">Smart Stake Analytics{currentNetwork.details.ui.beta === true && (<span className="beta">Beta</span>)}</span></Navbar.Brand>
          {RespUtils.isTabletView() && <Dashboards />}
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="customNavbarToggle" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="customNavBar">
              <Nav.Link eventKey={1.01} className="customNavLink" href="/">Home</Nav.Link>
              {RespUtils.isNotTabletView() && <Dashboards />}
              {!this.state.isLoading && currentNetwork.details.notSet != true && (<Nav.Link eventKey={1.02} onClick={this.toggleModal} className="customNavLink"><SearchIcon /></Nav.Link>)}
              {!this.state.isLoading && currentNetwork.details.notSet != true && (<Nav.Link eventKey={1.08} className="customNavLink" href={SessionUtils.getUrl("/")}>Validators</Nav.Link>)}
              <Nav.Link eventKey={1.30} className="customNavLink" href={SessionUtils.getUrl("/stats")}>Network</Nav.Link>
              {!this.state.isLoading && currentNetwork.details.app.burn === true && (<Nav.Link eventKey={1.15} className="customNavLink" href={SessionUtils.getUrl("/burn")}>🔥Burn</Nav.Link>)}
              {!this.state.isLoading && currentNetwork.details.ui.showApr === true && (<Nav.Link eventKey={1.20} className="customNavLink" href={SessionUtils.getUrl("/calc")}>Calculator</Nav.Link>)}
              {!this.state.isLoading && currentNetwork.details.app.tendermint === true && common.enableTxs === true && <Nav.Link eventKey={1.75} className="customNavLink" href={SessionUtils.getUrl("/txs")}>Txs</Nav.Link>}
              {!this.state.isLoading && currentNetwork.details.app.showAirdrops === true && (<Nav.Link eventKey={1.30} className="customNavLink" href={SessionUtils.getUrl("/airdrops")}>Airdrops</Nav.Link>)}
              {!this.state.isLoading && currentNetwork.details.app.tendermint === true && <Nav.Link eventKey={1.35} className="customNavLink" href={SessionUtils.getUrl("/gov")}>Gov</Nav.Link>}
              {!this.state.isLoading && currentNetwork.details.app.fdp === true && (<Nav.Link eventKey={1.40} className="customNavLink" href={SessionUtils.getUrl("/fdp")}>FDP</Nav.Link>)}
              {!this.state.isLoading && currentNetwork.details.app.lsm === true && (<Nav.Link eventKey={1.45} className="customNavLink" href={SessionUtils.getUrl("/lsm")}>LSM</Nav.Link>)}
              {!this.state.isLoading && currentNetwork.details.app.valBonds === true && (<Nav.Link eventKey={1.50} className="customNavLink" href={SessionUtils.getUrl("/valbonds")}>Val Bonds</Nav.Link>)}
              {!this.state.isLoading && currentNetwork.details.app.tokens === true && (<Nav.Link eventKey={1.60} className="customNavLink" href={SessionUtils.getUrl("/tokens")}>Tokens</Nav.Link>)}
              {!this.state.isLoading && currentNetwork.details.app.tendermint === true && <Nav.Link eventKey={1.70} className="customNavLink" href={SessionUtils.getUrl("/guide")}>Guide</Nav.Link>}
              {!this.state.isLoading && currentNetwork.details.app.tendermint === true && constants.mainnet === true && <Nav.Link eventKey={1.80} className="customNavLink" href={"https://relayers.smartstake.io/network/" + currentNetwork.details.app.token}>Relayers</Nav.Link>}
              {!this.state.isLoading && currentNetwork.details.app.decentralization === true && <Nav.Link eventKey={1.90} className="customNavLink" href={"https://d14n.smartstake.io/?ref=" + currentNetwork.details.app.d14Referral}>Decentralization</Nav.Link>}
            </Nav>
            <GlobalSearchModal show={this.state.showModal} handleClose={this.toggleModal} />
          </Navbar.Collapse>
      </Navbar>
    );
  }

  render4Terra() {
    return (
      <Navbar collapseOnSelect expand="lg" variant="light" sticky="top" className="customNavBar">
        <Navbar.Brand href="/"><img src="/images/smartstake.png" id = "mainLogo"
          className="d-inline-block align-top" alt={"Smart Stake Analytics - " + currentNetwork.details.app.title}
          /><span className="mainTitle">Smart Stake Analytics{currentNetwork.details.ui.beta === true && (<span className="beta">Beta</span>)}</span></Navbar.Brand>
          {RespUtils.isTabletView() && <Dashboards />}
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="customNavbarToggle" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="customNavBar">
              <Nav.Link eventKey={1.01} className="customNavLink" href="/">Home</Nav.Link>
              {RespUtils.isNotTabletView() && <Dashboards />}
              <Nav.Link eventKey={1.02} onClick={this.toggleModal} className="customNavLink"><SearchIcon /></Nav.Link>
              <Nav.Link eventKey={1.20} className="customNavLink" href={SessionUtils.getUrl("/validators")}>Validators</Nav.Link>
              <Nav.Link eventKey={1.30} className="customNavLink" href={SessionUtils.getUrl("/stats")}>Network</Nav.Link>
              <Nav.Link eventKey={1.50} className="customNavLink" href={SessionUtils.getUrl("/tokens")}>Tokens</Nav.Link>
              <Nav.Link eventKey={1.25} className="customNavLink" href={SessionUtils.getUrl("/calc")}>Calculator</Nav.Link>
              {common.enableTxs === true && <Nav.Link eventKey={1.75} className="customNavLink" href={SessionUtils.getUrl("/txs")}>Txs</Nav.Link>}
              {/* <Nav.Link eventKey={1.40} className="customNavLink" href={SessionUtils.getUrl("/airdrops")}>Airdrops</Nav.Link> */}
              <Nav.Link eventKey={1.60} className="customNavLink" href={SessionUtils.getUrl("/guide")}>Guide</Nav.Link>
              <Nav.Link eventKey={1.70} className="customNavLink" href={SessionUtils.getUrl("/gov")}>Gov</Nav.Link>
              <Nav.Link eventKey={1.10} className="customNavLink" href="https://terraprojects.smartstake.io/apps">Apps</Nav.Link>
              <Nav.Link eventKey={1.15} className="customNavLink" href="https://terraprojects.smartstake.io/projects">Projects</Nav.Link>
              <Nav.Link eventKey={1.80} className="customNavLink" href={"https://relayers.smartstake.io/network/" + currentNetwork.details.app.token}>Relayers</Nav.Link>
              <Nav.Link eventKey={1.90} className="customNavLink" href={"https://d14n.smartstake.io/?ref=" + currentNetwork.details.app.d14Referral}>Decentralization</Nav.Link>
            </Nav>
            <GlobalSearchModal show={this.state.showModal} handleClose={this.toggleModal} />
          </Navbar.Collapse>
      </Navbar>
    );
  }

  getSS() {
    return (<span className="ssSubtitle">by Smart Stake</span>);
  }
}

export default NavBar;
