import React from 'react';
import constants from './constants';

class currentNetwork extends React.Component {
    static details = {
        notSet: true,
        app: {
            title: "Smart Stake Analytics",
        },
        ui: {validators: {}, richlist: {defaultTokenId: null}},
        urls: {},
        proposal: {},
        others: {},
        burn: {}
    };

    static setNetwork(network) {
        if (network) {
            currentNetwork.details = constants[network];
        }
    }
}

export default currentNetwork;
