import React, { Component } from 'react';
import {Collapse} from 'react-collapse';
import Card from 'react-bootstrap/Card';

import "./CollapsibleNote.css";
import "./Faq.css";

class Faq extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showGuide: false,
    } 

    this.switchGuide = this.switchGuide.bind(this);
  }

  async componentDidMount() {
  }

  switchGuide() {
    let showGuide = !this.state.showGuide;
    this.setState({showGuide: showGuide});
  }

  render() {
    return (
      <span className="collapsibleNote">
        <a onClick={this.switchGuide} className="animLinkFaq"><li>{this.props.question}</li></a>
        <Collapse isOpened={this.state.showGuide}>
          <Card>
            <Card.Body>
              {this.props.answer}
            </Card.Body>
          </Card>
        </Collapse>
      </span>
    );
  }
}

export default Faq;
