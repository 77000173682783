import { API} from 'aws-amplify';
import React from 'react';

import currentNetwork from '../currentNetwork';
import NetworkUtils from '../util/NetworkUtils';
import constants from '../constants';

class ApiUtils extends React.Component {
  static errorMsg = "The website is not available to process your request at this time. Please contact website operator.";
  static errorMsgProc = "The website is having difficulty in processing your request at this time ('Access Denied'). Please contact website operator.";

  static async get(url) {
    window.localStorage.removeItem("DENIED");
    // console.log("window.location.href: " + window.location.href);
    let data = "";
    try {
      if(!ApiUtils.checkAccess()) {
        return;
      }

      let endpoint = NetworkUtils.getEndpoint()
      // console.log(endpoint, " - in api get data util method. calling get url: " + url);

      data =  await API.get(endpoint, ApiUtils.addKey(url));
      if(!ApiUtils.processAccess(data)) {
        return null;
      }
    } catch (e) {
      console.log(url + " - call failed. error is: " + e);
      console.log(e);
      // alert(e);
    }

    return data;
  }

  static async post(url, postDataObj, error) {
    let data = "";
    try {
      if(!ApiUtils.checkAccess()) {
        return;
      }

      // console.log("in api post data util method. calling post url: " + url);
      data = await API.post(NetworkUtils.getEndpoint(), ApiUtils.addKey(url), {
        body: postDataObj
      });

      if(!ApiUtils.processAccess(data)) {
        return null;
      }
    } catch (e) {
      console.log(url + " - call failed. error is: " + e);
      console.log(e);
      // alert(error);
    }

    return data;
  }

  static addKey(url) {
    let separator = (url.indexOf("?") !== -1) ? "&" : "?";
    const token = Math.round(new Date().getTime() / 1000);

    let finalUrl = url + separator + "key=" + constants.KEY;
    finalUrl += "&token=" + token;
    if (currentNetwork.details.APP_KEY) {
      finalUrl += "&app=" + currentNetwork.details.APP_KEY;
    }
    
    // finalUrl += "&token=" + token + "&app=" + currentNetwork.details.APP_KEY;
    return finalUrl;
  }

  static checkAccess() {
    let access = window.localStorage.getItem("DENIED");

    if (!access) {
      return true
    }

    let allowedAccess = parseInt(access) + 60 * 60 * 1000;
    if (allowedAccess > new Date().getTime()) {
      alert(ApiUtils.errorMsg);
      return false;
    }
    window.localStorage.removeItem("DENIED");
    return true;
  }

  static removeAccess() {
    window.localStorage.setItem("DENIED", new Date().getTime());
  }

  static addMinutes(dateObj, minutes) {
    return dateObj.setHours(dateObj.getMinutes() + minutes);
  }

  static processAccess(data) {
    if (data && data["errors"] && "DENIED" === data["errors"]) {
      ApiUtils.removeAccess();
      alert(ApiUtils.errorMsgProc);
      return false;
    }
    return true;
  }

  static async getSpecial(url) {
    window.localStorage.removeItem("DENIED");
    let data = "";
    try {
      if(!ApiUtils.checkAccess()) {
        return;
      }

      data =  await API.get('special', ApiUtils.addKey(url));
      if(!ApiUtils.processAccess(data)) {
        return null;
      }
    } catch (e) {
      console.log(url + " - call failed. error is: " + e);
      console.log(e);
      // alert(e);
    }

    return data;
  }

  static async getCommon(url) {
    window.localStorage.removeItem("DENIED");

    let data = "";
    try {
      if(!ApiUtils.checkAccess()) {
        return;
      }
      data =  await API.get('common', ApiUtils.addKey(url));
      if(!ApiUtils.processAccess(data)) {
        return null;
      }
    } catch (e) {
      console.log(url + " - call failed. error is: " + e);
      console.log(e);
    }

    return data;
  }
}

export default ApiUtils;
