import common from "./common";

export default {
  defaultNetworkName: "cosmos",
  baseUrl: "https://analytics.smartstake.io",
  KEY: "2mwTEDr9zXJH323M",
  title: "Smart Stake",
  mainnet: true,
  testnet: false,
  SPECIAL_URL: "https://static.smartstake.io/special/",
  PROJECT_LOGO_BASE_URL: "https://static.smartstake.io/",
  VALIDATORS_KEY: common.basic.baseKeyForValidators + "_V6",
  KEY_PROPOSALS: common.basic.baseKeyForProposals + "_V1",
  HOME_KEY: "HOME_KEY_V0",
  DEFAULT_HOME_URL: "/validators",
  adEnabled: false,
  "terra": {
    networkName: "terra",
    APP_KEY: "LUNA",
    app: {
      title: "Terra (v2)",
      token: "LUNA",
      appName: "Terra",
      addressPrefix: "terra",
      valoperPrefix: "terravaloper",
      proposalToken: "$LUNA",
      d14Referral: "luna",
      decentralization: true,
      smartStakeAddress: "terravaloper188e99yz54744uhr8xjfxmmplhnuw75xea55zfp",
      defaultValidatorViewStatus: "Bonded",
      defaultLogo: "/images/chains/terra.png",
      defaultProjectLogo: "/images/chains/terra.png",
      DEFAULT_POOL_ID: 74,
      showAirdrops: true,
      enableIsNew: true,
      REST_ENDPOINT: "https://rest.cosmos.directory/terra2/",
      validatorSetPages: 2,
      REST_PROVIDER: "Cosmos Directory",
      REST_PROVIDER_WEBSITE: "https://cosmos.directory",
      tendermint: true,
      tokens: true,
    },
    ui: {
      beta: false,
      showApr: true,
      delegateToSmartStake: true,
      identifierAttribute: "operatorAddress",
      validators: {
        supportOracle: false,
        extraData3Label: "",
        extraData3LabelDesc: "",
        columnsConfig: {
          averageDelegation: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionPlus: { "hide": false, "hideMobile": false, "addToMobile": true },
          commission: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionMaxChangeRate: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionMaxRate: { "hide": true, "hideMobile": true, "addToMobile": true },
          currentMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          currentPerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          currentSigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          dailyMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          dailyPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          dailySigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          delegate: { "hide": false, "hideMobile": true, "addToMobile": false },
          description: { "hide": true, "hideMobile": true, "addToMobile": true },
          fav: { "hide": false, "hideMobile": false, "addToMobile": true },
          ibcRelayerInd: { "hide": false, "hideMobile": true, "addToMobile": true },
          vpRank: { "hide": false, "hideMobile": true, "addToMobile": true },
          longMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          longNetApr: { "hide": false, "hideMobile": true, "addToMobile": true },
          longPerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          longSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          midMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          midPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          midSigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          name: { "hide": false, "hideMobile": false, "addToMobile": true },

          operatorAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          hexAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          publicKey: { "hide": true, "hideMobile": true, "addToMobile": true },
          consensusNodeAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          address: { "hide": true, "hideMobile": true, "addToMobile": true },
          website: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalMissed: { "hide": true, "hideMobile": true, "addToMobile": true },
          latestSlashedTime: { "hide": true, "hideMobile": true, "addToMobile": true },

          securityContact: { "hide": true, "hideMobile": true, "addToMobile": true },
          selfBond: { "hide": true, "hideMobile": true, "addToMobile": true },
          selfBondPercentage: { "hide": true, "hideMobile": true, "addToMobile": true },
          slashingWindowMissedBlocks: { "hide": true, "hideMobile": true, "addToMobile": true },
          slashingWindowUptime: { "hide": true, "hideMobile": true, "addToMobile": true },
          status: { "hide": false, "hideMobile": true, "addToMobile": true },
          timesSlashed: { "hide": true, "hideMobile": true, "addToMobile": true },
          tombstoned: { "hide": true, "hideMobile": true, "addToMobile": true },
          totalUndelegationAmount: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegates: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange1d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange30d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange7d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueUndelegations: { "hide": true, "hideMobile": true, "addToMobile": true },
          voteParticipation: { "hide": false, "hideMobile": true, "addToMobile": true },
          voteParticipationOverall: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPower: { "hide": false, "hideMobile": true, "addToMobile": true },
          votingPowerChange1d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerChange30d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerChange7d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerPercentage: { "hide": false, "hideMobile": true, "addToMobile": true },
          identity: { "hide": false, "hideMobile": true, "addToMobile": true },
        },
      },
      validator: {
        extra1Label: "",
        extra2Label: "",
      },
      network: {
        aprDisclaimer: true,
        showCirculatingSupply: true,
        showTotalSupply: true,
        vpCompareDays: 30,
      },
      delegations: {
        includeUndelInSameCall: true,
      },
      badges: {
        showBadges: true,
        relayer: true,
        restake: true,
        fdpInd: false,
        foundationNodeInd: false,
        foundationNodeIndLabel: "",
        badge1Ind: true,
        badge1IndLabel: "Liquid Staking supports/uses this validator for auto compounding. Click to use liquidstaking.app",
        badge1IndImg: "/images/specific/lunasteak.png",
        badge1IndLink: "https://liquidsteaking.app",
        badge2Ind: false,
        badge2IndLabel: null,
        badge2IndImg: null,
        badge2IndLink: null,
      }
    },
    others: {
      APP_LABEL_VP_COMP: "Cosmos",
      APP_KEY_VP_COMP: "ATOM",
    },
    proposal: {
      showDeposits: false,
    },
    integrations: {
      chainId: "phoenix-1",
      keplr: "terra",
      leap: "terra",
      mintscan: "terra",
      pingpub: "terra2",
      polkachu: "terra2",
      kjnodes: null,
      nodestake: null,
      restake: "terra2",
      mainStaking: null,
      mainStakingLabel: null,
      proposalHome: "https://dashboard.station.money/proposal/phoenix-1/",
      addressLink: "https://finder.terra.money/mainnet/address/",
      proposalHomeLabel: "Station",
      station: true,
      stakingHomeForValidator: null,
      stakingHomeLabel: null,
    },
  },
  "akash": {
    networkName: "akash",
    APP_KEY: "AKT",
    app: {
      title: "Akash",
      token: "AKT",
      appName: "Akash",
      addressPrefix: "akash",
      valoperPrefix: "akashvaloper",
      proposalToken: "$AKT",
      d14Referral: "akt",
      decentralization: true,
      smartStakeAddress: "akashvaloper1uckq4fddrwftu6pv4stsm9ffthxkgathezgznj",
      defaultValidatorViewStatus: "Bonded",
      defaultLogo: "/images/chains/akash.jpg",
      defaultProjectLogo: "/images/chains/akash.jpg",
      DEFAULT_POOL_ID: 1,
      showAirdrops: false,
      enableIsNew: true,
      validatorSetPages: 1,
      REST_ENDPOINT: "https://rest.cosmos.directory/akash/",
      REST_PROVIDER: "Cosmos Directory",
      REST_PROVIDER_WEBSITE: "https://cosmos.directory",
      tendermint: true,      
      tokens: false,
    },
    ui: {
      beta: false,
      showApr: true,
      delegateToSmartStake: true,
      identifierAttribute: "operatorAddress",
      validators: {
        supportOracle: false,
        extraData3Label: "",
        extraData3LabelDesc: "",
        columnsConfig: {
          averageDelegation: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionPlus: { "hide": false, "hideMobile": false, "addToMobile": true },
          commission: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionMaxChangeRate: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionMaxRate: { "hide": true, "hideMobile": true, "addToMobile": true },
          currentMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          currentPerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          currentSigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          dailyMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          dailyPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          dailySigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          delegate: { "hide": false, "hideMobile": true, "addToMobile": false },
          description: { "hide": true, "hideMobile": true, "addToMobile": true },
          fav: { "hide": false, "hideMobile": false, "addToMobile": true },
          ibcRelayerInd: { "hide": false, "hideMobile": true, "addToMobile": true },
          vpRank: { "hide": false, "hideMobile": true, "addToMobile": true },
          longMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          longNetApr: { "hide": false, "hideMobile": true, "addToMobile": true },
          longPerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          longSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          midMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          midPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          midSigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          name: { "hide": false, "hideMobile": false, "addToMobile": true },
          operatorAddress: { "hide": true, "hideMobile": true, "addToMobile": true },

          hexAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          publicKey: { "hide": true, "hideMobile": true, "addToMobile": true },
          consensusNodeAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          address: { "hide": true, "hideMobile": true, "addToMobile": true },
          website: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalMissed: { "hide": true, "hideMobile": true, "addToMobile": true },
          latestSlashedTime: { "hide": true, "hideMobile": true, "addToMobile": true },

          securityContact: { "hide": true, "hideMobile": true, "addToMobile": true },
          selfBond: { "hide": true, "hideMobile": true, "addToMobile": true },
          selfBondPercentage: { "hide": true, "hideMobile": true, "addToMobile": true },
          slashingWindowMissedBlocks: { "hide": true, "hideMobile": true, "addToMobile": true },
          slashingWindowUptime: { "hide": true, "hideMobile": true, "addToMobile": true },
          status: { "hide": false, "hideMobile": true, "addToMobile": true },
          timesSlashed: { "hide": true, "hideMobile": true, "addToMobile": true },
          tombstoned: { "hide": true, "hideMobile": true, "addToMobile": true },
          totalUndelegationAmount: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegates: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange1d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange30d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange7d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueUndelegations: { "hide": true, "hideMobile": true, "addToMobile": true },
          voteParticipation: { "hide": false, "hideMobile": true, "addToMobile": true },
          voteParticipationOverall: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPower: { "hide": false, "hideMobile": true, "addToMobile": true },
          votingPowerChange1d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerChange30d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerChange7d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerPercentage: { "hide": false, "hideMobile": true, "addToMobile": true },
          identity: { "hide": false, "hideMobile": true, "addToMobile": true },
        },
      },
      validator: {
        extra1Label: "",
        extra2Label: "",
      },
      network: {
        aprDisclaimer: false,
        showCirculatingSupply: true,
        showTotalSupply: true,
        vpCompareDays: 30,
      },
      delegations: {
        includeUndelInSameCall: false,
      },
      badges: {
        showBadges: true,
        relayer: true,
        restake: true,
        fdpInd: false,
        foundationNodeInd: false,
        foundationNodeIndLabel: "",
        badge1Ind: false,
        badge1IndLabel: null,
        badge1IndImg: null,
        badge1IndLink: null,
        badge2Ind: false,
        badge2IndLabel: null,
        badge2IndImg: null,
        badge2IndLink: null,
      }
    },
    others: {
      APP_LABEL_VP_COMP: "Cosmos",
      APP_KEY_VP_COMP: "ATOM",
    },
    proposal: {
      showDeposits: false,
    },
    integrations: {
      chainId: "akashnet-2",
      keplr: "akash",
      leap: "akash",
      mintscan: "akash",
      pingpub: "akash",
      polkachu: "akash",
      kjnodes: null,
      nodestake: "akash",
      restake: "akash",
      proposalHome: "keplr",
      addressLink: "mintscan",
      mainStaking: null, 
      mainStakingLabel: null,
      proposalHomeLabel: null,
      addressLinkLabel: null,
      station: true,
      stakingHomeForValidator: null,
      stakingHomeLabel: null,
    },
  },
  "cosmos": {
    networkName: "cosmos",
    APP_KEY: "ATOM",
    app: {
      title: "Cosmos",
      token: "ATOM",
      appName: "Cosmos",
      addressPrefix: "cosmos",
      valoperPrefix: "cosmosvaloper",
      proposalToken: "$ATOM",
      d14Referral: "atom",
      defaultValidatorViewStatus: "Bonded",
      decentralization: true,
      smartStakeAddress: "cosmosvaloper18extdhzzl5c8tr6453e5hzaj3exrdlea90fj3y",
      defaultLogo: "/images/chains/cosmos.jpg",
      defaultProjectLogo: "/images/chains/cosmos.jpg",
      DEFAULT_POOL_ID: 1,
      showAirdrops: false,
      enableIsNew: true,
      REST_ENDPOINT: "https://rest.cosmos.directory/cosmoshub/",
      validatorSetPages: 2,
      REST_PROVIDER: "Cosmos Directory",
      REST_PROVIDER_WEBSITE: "https://cosmos.directory",
      tendermint: true,      
      lsm: true,
      lsmType: "LSM_FULL",
      tokens: false,
    },
    ui: {
      beta: false,
      showApr: true,
      delegateToSmartStake: true,
      identifierAttribute: "operatorAddress",
      validators: {
        supportOracle: false,
        // hideLongPerSignedMobile: true,
        // ics: true,
        // icsCurrentPerSigned: true,
        // icsPerf: false,
        // slashingWindowUptime: true,
        // hideExtraData3: true,
        // hideExtraData1: true,
        extraData3Label: "",
        extraData3LabelDesc: "",
        columnsConfig: {
          averageDelegation: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionPlus: { "hide": false, "hideMobile": false, "addToMobile": true },
          commission: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionMaxChangeRate: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionMaxRate: { "hide": true, "hideMobile": true, "addToMobile": true },
          currentMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          currentPerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          currentSigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          dailyMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          dailyPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          dailySigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          delegate: { "hide": false, "hideMobile": true, "addToMobile": false },
          description: { "hide": true, "hideMobile": true, "addToMobile": true },
          fav: { "hide": false, "hideMobile": false, "addToMobile": true },
          ibcRelayerInd: { "hide": false, "hideMobile": true, "addToMobile": true },
          icsCurrentPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          icsPerf: { "hide": false, "hideMobile": true, "addToMobile": true },
          vpRank: { "hide": false, "hideMobile": true, "addToMobile": true },
          longMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          longNetApr: { "hide": false, "hideMobile": true, "addToMobile": true },
          longPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          longSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          midMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          midPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          midSigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          name: { "hide": false, "hideMobile": false, "addToMobile": true },
          operatorAddress: { "hide": true, "hideMobile": true, "addToMobile": true },

          hexAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          publicKey: { "hide": true, "hideMobile": true, "addToMobile": true },
          consensusNodeAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          address: { "hide": true, "hideMobile": true, "addToMobile": true },
          website: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalMissed: { "hide": true, "hideMobile": true, "addToMobile": true },
          latestSlashedTime: { "hide": true, "hideMobile": true, "addToMobile": true },
          securityContact: { "hide": true, "hideMobile": true, "addToMobile": true },
          selfBond: { "hide": true, "hideMobile": true, "addToMobile": true },
          selfBondPercentage: { "hide": true, "hideMobile": true, "addToMobile": true },
          slashingWindowMissedBlocks: { "hide": true, "hideMobile": true, "addToMobile": true },
          slashingWindowUptime: { "hide": true, "hideMobile": true, "addToMobile": true },
          status: { "hide": false, "hideMobile": true, "addToMobile": true },
          timesSlashed: { "hide": true, "hideMobile": true, "addToMobile": true },
          tombstoned: { "hide": true, "hideMobile": true, "addToMobile": true },
          totalUndelegationAmount: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegates: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange1d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange30d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange7d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueUndelegations: { "hide": true, "hideMobile": true, "addToMobile": true },
          voteParticipation: { "hide": false, "hideMobile": true, "addToMobile": true },
          voteParticipationOverall: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPower: { "hide": false, "hideMobile": true, "addToMobile": true },
          votingPowerChange1d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerChange30d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerChange7d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerPercentage: { "hide": false, "hideMobile": true, "addToMobile": true },
          identity: { "hide": false, "hideMobile": true, "addToMobile": true },
        },
        more: {
          currentOracleMissed: { "hide": true, "hideMobile": true, "addToMobile": true },
          currentOraclePerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          dailyOracleMissed: { "hide": true, "hideMobile": true, "addToMobile": true },
          dailyOraclePerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          icsCurrentPerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          icsPerf: { "hide": true, "hideMobile": true, "addToMobile": true },
          longOracleMissed: { "hide": true, "hideMobile": true, "addToMobile": true },
          longOraclePerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          midOracleMissed: { "hide": true, "hideMobile": true, "addToMobile": true },
          midOraclePerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          oracleMissedVotes: { "hide": true, "hideMobile": true, "addToMobile": true },
          extraData3: { "hide": false, "hideMobile": true, "addToMobile": true },
        },
        columnsConfigDesktop2: {
          averageDelegation: true, commission: false, commissionMaxChangeRate: true, commissionMaxRate: true, currentMissed: true,
          currentPerSigned: true, currentSigned: true, dailyMissed: true, dailyPerSigned: true, dailySigned: true, fav: false, ibcRelayerInd: false,
          icsCurrentPerSigned: false, icsPerf: true, vpRank: false, longMissed: true, longNetApr: false, longPerSigned: true, longSigned: true,
          midMissed: true, midPerSigned: true, midSigned: true, name: false, operatorAddress: true, securityContact: true, selfBond: true, selfBondPercentage: true,
          slashingWindowMissedBlocks: true, slashingWindowUptime: false, status: false, timesSlashed: true, tombstoned: true,
          totalUndelegationAmount: true, uniqueDelegates: false, uniqueDelegatesChange1d: true, uniqueDelegatesChange30d: true,
          uniqueDelegatesChange7d: true, uniqueUndelegations: true, voteParticipation: false, voteParticipationOverall: true,
          votingPower: false, votingPowerChange1d: true, votingPowerChange30d: true, votingPowerChange7d: true, votingPowerPercentage: false
        },
        columnsConfigMobile2: {
          averageDelegation: true, commission: false, commissionMaxChangeRate: true, commissionMaxRate: true,
          currentPerSigned: true, dailyPerSigned: true, fav: false, ibcRelayerInd: true, icsCurrentPerSigned: true, icsPerf: true, vpRank: true,
          longNetApr: true, longPerSigned: true, midPerSigned: true, name: false, operatorAddress: true, securityContact: true, selfBond: true,
          selfBondPercentage: true, slashingWindowMissedBlocks: true, slashingWindowUptime: true, status: true, timesSlashed: true, tombstoned: true,
          totalUndelegationAmount: true, uniqueDelegates: true, uniqueDelegatesChange1d: true, uniqueDelegatesChange30d: true,
          uniqueDelegatesChange7d: true, uniqueUndelegations: true, voteParticipation: true, voteParticipationOverall: true,
          votingPower: true, votingPowerChange1d: true, votingPowerChange30d: true, votingPowerChange7d: true, votingPowerPercentage: true
        },
        columnsConfigOrg: {
          averageDelegation: true, commission: false, commissionMaxChangeRate: true, commissionMaxRate: true, currentMissed: true,
          currentOracleMissed: true, currentOraclePerSigned: false, currentPerSigned: true, currentSigned: true, dailyMissed: true,
          dailyOracleMissed: true, dailyOraclePerSigned: true, dailyPerSigned: true, dailySigned: true, fav: false, ibcRelayerInd: false,
          icsCurrentPerSigned: false, icsPerf: true, vpRank: false, longMissed: true, longNetApr: false, longOracleMissed: true, longOraclePerSigned: false,
          longPerSigned: true, longSigned: true, midMissed: true, midOracleMissed: true, midOraclePerSigned: true, midPerSigned: true, midSigned: true,
          name: false, operatorAddress: true, oracleMissedVotes: true, securityContact: true, selfBond: true, selfBondPercentage: true,
          slashingWindowMissedBlocks: true, slashingWindowUptime: false, status: false, timesSlashed: true, tombstoned: true,
          totalUndelegationAmount: true, uniqueDelegates: false, uniqueDelegatesChange1d: true, uniqueDelegatesChange30d: true,
          uniqueDelegatesChange7d: true, uniqueUndelegations: true, voteParticipation: false, voteParticipationOverall: true,
          votingPower: false, votingPowerChange1d: true, votingPowerChange30d: true, votingPowerChange7d: true, votingPowerPercentage: false
        },
      },
      validator: {
        extra1Label: "",
        extra2Label: "",
      },
      network: {
        aprDisclaimer: false,
        showCirculatingSupply: true,
        showTotalSupply: true,
        vpCompareDays: 30,
      },
      delegations: {
        includeUndelInSameCall: false,
      },
      badges: {
        showBadges: true,
        relayer: true,
        restake: true,
        fdpInd: false,
        foundationNodeInd: false,
        foundationNodeIndLabel: "",
        badge1Ind: true,
        badge1IndLabel: "pSTAKE uses this validator for delegation as part of Liquid Staking. Click to use pSTAKE app.",
        badge1IndImg: "/images/specific/pstake.jpg",
        badge1IndLink: "https://cosmos.pstake.finance/",
        badge1Key: "PSTAKE",
        badge2Ind: true,
        badge2IndLabel: "Quicksilver uses this validator for delegation as part of Liquid Staking. Click to use Quicksilver app.",
        badge2IndImg: "/images/chains/qck.png",
        badge2IndLink: "https://app.quicksilver.zone/stake/delegate",
        badge2Key: "QCK",
        badge3Ind: true,
        badge3IndLabel: "Stride uses this validator for delegation as part of Liquid Staking. Click to use Stride app.",
        badge3IndImg: "/images/chains/stride.png",
        badge3IndLink: "https://app.stride.zone/",
        badge3Key: "STRD",
        badge4Ind: true,
        badge4IndLabel: "Pryzm uses this validator for delegation as part of Liquid Staking. Click to use Pryzm app.",
        badge4IndImg: "/images/chains/pryzm.jpg",
        badge4IndLink: "https://app.pryzm.zone/",
        badge4Key: "PRYZM",
        badge5Ind: true,
        badge5IndLabel: "Drop uses this validator for delegation as part of Liquid Staking. Click to use Drop app.",
        badge5IndImg: "/images/specific/drop.jpg",
        badge5IndLink: "https://www.drop.money/",
        badge5Key: "DROP",
      }
    },
    others: {
      APP_LABEL_VP_COMP: "Osmosis",
      APP_KEY_VP_COMP: "OSMO",
    },
    proposal: {
      showDeposits: false
    },
    integrations: {
      chainId: "cosmoshub-4",
      keplr: "cosmos-hub",
      leap: "cosmos",
      mintscan: "cosmos",
      pingpub: "cosmos",
      polkachu: "cosmos",
      kjnodes: "cosmos",
      nodestake: "cosmos",
      restake: "cosmoshub",
      proposalHome: "keplr",
      addressLink: "mintscan",
      mainStaking: null, 
      mainStakingLabel: null,
      proposalHomeLabel: null,
      addressLinkLabel: null,
      station: true,
      stakingHomeForValidator: null,
      stakingHomeLabel: null,
      extraLink1: "https://joinatom.zone/",
      extraLink1Label: "Join ATOM",
      extraLink1Logo: "https://abs-0.twimg.com/emoji/v2/svg/269b.svg",      
    },
    fdp: {
      extra: false,
      maxVP: false,
      accessKey: true,
      fdpAccessKey: "FDP_ACCESS_KEY_ICF_0"
    }
  },
  "band": {
    networkName: "band",
    APP_KEY: "BAND",
    app: {
      title: "Band",
      token: "BAND",
      appName: "Band",
      addressPrefix: "band",
      valoperPrefix: "bandvaloper",
      proposalToken: "$BAND",
      d14Referral: "band",
      decentralization: true, smartStakeAddress: "bandvaloper1nmlds05h9zpj0m3actleslnmf5ett8xfvmjgju",
      defaultValidatorViewStatus: "Bonded",
      defaultLogo: "/images/chains/band.jpg",
      defaultProjectLogo: "/images/chains/band.jpg",
      DEFAULT_POOL_ID: 1,
      showAirdrops: false,
      enableIsNew: true,
      REST_ENDPOINT: "https://rest.cosmos.directory/bandchain/",
      validatorSetPages: 1,
      REST_PROVIDER: "Cosmos Directory",
      REST_PROVIDER_WEBSITE: "https://cosmos.directory",
      tendermint: true,      
      tokens: false,
    },
    ui: {
      beta: false,
      showApr: true,
      delegateToSmartStake: true,
      identifierAttribute: "operatorAddress",
      validators: {
        supportOracle: false,
        extraData3Label: "Active Oracle?",
        extraData3LabelDesc: "Is validator's oracle active?",
        columnsConfig: {
          averageDelegation: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionPlus: { "hide": false, "hideMobile": false, "addToMobile": true },
          commission: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionMaxChangeRate: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionMaxRate: { "hide": true, "hideMobile": true, "addToMobile": true },
          currentMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          currentPerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          currentSigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          dailyMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          dailyPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          dailySigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          // delegate: { "hide": false, "hideMobile": true, "addToMobile": false },
          description: { "hide": true, "hideMobile": true, "addToMobile": true },
          extraData3: { "hide": false, "hideMobile": true, "addToMobile": true },
          fav: { "hide": false, "hideMobile": false, "addToMobile": true },
          ibcRelayerInd: { "hide": false, "hideMobile": true, "addToMobile": true },
          vpRank: { "hide": false, "hideMobile": true, "addToMobile": true },
          longMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          longNetApr: { "hide": false, "hideMobile": true, "addToMobile": true },
          longPerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          longSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          midMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          midPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          midSigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          name: { "hide": false, "hideMobile": false, "addToMobile": true },
          operatorAddress: { "hide": true, "hideMobile": true, "addToMobile": true },

          hexAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          publicKey: { "hide": true, "hideMobile": true, "addToMobile": true },
          consensusNodeAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          address: { "hide": true, "hideMobile": true, "addToMobile": true },
          website: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalMissed: { "hide": true, "hideMobile": true, "addToMobile": true },
          latestSlashedTime: { "hide": true, "hideMobile": true, "addToMobile": true },

          securityContact: { "hide": true, "hideMobile": true, "addToMobile": true },
          selfBond: { "hide": true, "hideMobile": true, "addToMobile": true },
          selfBondPercentage: { "hide": true, "hideMobile": true, "addToMobile": true },
          slashingWindowMissedBlocks: { "hide": true, "hideMobile": true, "addToMobile": true },
          slashingWindowUptime: { "hide": true, "hideMobile": true, "addToMobile": true },
          status: { "hide": false, "hideMobile": true, "addToMobile": true },
          timesSlashed: { "hide": true, "hideMobile": true, "addToMobile": true },
          tombstoned: { "hide": true, "hideMobile": true, "addToMobile": true },
          totalUndelegationAmount: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegates: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange1d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange30d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange7d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueUndelegations: { "hide": true, "hideMobile": true, "addToMobile": true },
          voteParticipation: { "hide": false, "hideMobile": true, "addToMobile": true },
          voteParticipationOverall: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPower: { "hide": false, "hideMobile": true, "addToMobile": true },
          votingPowerChange1d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerChange30d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerChange7d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerPercentage: { "hide": false, "hideMobile": true, "addToMobile": true },
          identity: { "hide": false, "hideMobile": true, "addToMobile": true },
        },
      },
      validator: {
        extra1Label: "Oracle Pending Request Count",
        extra2Label: "",
      },
      network: {
        aprDisclaimer: false,
        showCirculatingSupply: true,
        showTotalSupply: true,
        vpCompareDays: 30,
      },
      delegations: {
        includeUndelInSameCall: false,
      },
      badges: {
        showBadges: true,
        relayer: true,
        restake: true,
        fdpInd: false,
        foundationNodeInd: true,
        foundationNodeIndLabel: "Internal validator run by Band foundation",
        badge1Ind: false,
        badge1IndLabel: null,
        badge1IndImg: null,
        badge1IndLink: null,
        badge2Ind: false,
        badge2IndLabel: null,
        badge2IndImg: null,
        badge2IndLink: null,
      }
    },
    others: {
      APP_LABEL_VP_COMP: "Cosmos",
      APP_KEY_VP_COMP: "ATOM",
    },
    proposal: {
      showDeposits: false,
    },
    integrations: {
      chainId: "laozi-mainnet",
      keplr: null,
      leap: null,
      mintscan: "band",
      pingpub: "band",
      polkachu: "band",
      kjnodes: null,
      nodestake: null,
      restake: "bandchain",
      proposalHome: "https://cosmoscan.io/proposals?",
      addressLink: "mintscan",
      mainStaking: null, 
      mainStakingLabel: null,
      proposalHomeLabel: "Cosmoscan",
      addressLinkLabel: null,
      station: false,
      stakingHomeForValidator: "https://cosmoscan.io/validator/",
      stakingHomeLabel: "Cosmoscan",
    },
  },
  "coreum": {
    networkName: "coreum",
    APP_KEY: "CORE",
    app: {
      title: "Coreum",
      token: "CORE",
      appName: "Coreum",
      addressPrefix: "core",
      valoperPrefix: "corevaloper",
      proposalToken: "$CORE",
      d14Referral: "core",
      decentralization: true,
      smartStakeAddress: "corevaloper1zv3rz855lan7mznzlmzycf0a3tslar4lntsxk2",
      defaultValidatorViewStatus: "Bonded",
      defaultLogo: "https://static.smartstake.io/chains/coreum.jpg",
      defaultProjectLogo: "https://static.smartstake.io/chains/coreum.jpg",
      DEFAULT_POOL_ID: 1,
      showAirdrops: false,
      enableIsNew: true,
      REST_ENDPOINT: "https://rest.cosmos.directory/coreum/",
      validatorSetPages: 1,
      REST_PROVIDER: "Cosmos Directory",
      REST_PROVIDER_WEBSITE: "cosmos.directory",
      tendermint: true,
      tokens: true,
    },
    ui: {
      beta: false,
      showApr: true,
      delegateToSmartStake: true,
      identifierAttribute: "operatorAddress",
      validators: {
        supportOracle: false,
        extraData3Label: "",
        extraData3LabelDesc: "",
        columnsConfig: {
          averageDelegation: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionPlus: { "hide": false, "hideMobile": false, "addToMobile": true },
          commission: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionMaxChangeRate: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionMaxRate: { "hide": true, "hideMobile": true, "addToMobile": true },
          currentMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          currentPerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          currentSigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          dailyMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          dailyPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          dailySigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          delegate: { "hide": false, "hideMobile": true, "addToMobile": false },
          description: { "hide": true, "hideMobile": true, "addToMobile": true },
          fav: { "hide": false, "hideMobile": false, "addToMobile": true },
          vpRank: { "hide": false, "hideMobile": true, "addToMobile": true },
          longMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          longNetApr: { "hide": false, "hideMobile": true, "addToMobile": true },
          longPerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          longSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          midMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          midPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          midSigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          name: { "hide": false, "hideMobile": false, "addToMobile": true },
          operatorAddress: { "hide": true, "hideMobile": true, "addToMobile": true },

          hexAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          publicKey: { "hide": true, "hideMobile": true, "addToMobile": true },
          consensusNodeAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          address: { "hide": true, "hideMobile": true, "addToMobile": true },
          website: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalMissed: { "hide": true, "hideMobile": true, "addToMobile": true },
          latestSlashedTime: { "hide": true, "hideMobile": true, "addToMobile": true },

          securityContact: { "hide": true, "hideMobile": true, "addToMobile": true },
          selfBond: { "hide": true, "hideMobile": true, "addToMobile": true },
          selfBondPercentage: { "hide": true, "hideMobile": true, "addToMobile": true },
          slashingWindowMissedBlocks: { "hide": true, "hideMobile": true, "addToMobile": true },
          slashingWindowUptime: { "hide": true, "hideMobile": true, "addToMobile": true },
          status: { "hide": false, "hideMobile": true, "addToMobile": true },
          timesSlashed: { "hide": true, "hideMobile": true, "addToMobile": true },
          tombstoned: { "hide": true, "hideMobile": true, "addToMobile": true },
          totalUndelegationAmount: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegates: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange1d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange30d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange7d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueUndelegations: { "hide": true, "hideMobile": true, "addToMobile": true },
          voteParticipation: { "hide": false, "hideMobile": true, "addToMobile": true },
          voteParticipationOverall: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPower: { "hide": false, "hideMobile": true, "addToMobile": true },
          votingPowerChange1d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerChange30d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerChange7d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerPercentage: { "hide": false, "hideMobile": true, "addToMobile": true },
          identity: { "hide": false, "hideMobile": true, "addToMobile": true },
        },
      },
      validator: {
        extra1Label: "",
        extra2Label: "",
      },
      network: {
        aprDisclaimer: false,
        showCirculatingSupply: false,
        showTotalSupply: true,
        vpCompareDays: 30,
      },
      delegations: {
        includeUndelInSameCall: true,
      },
      badges: {
        showBadges: true,
        relayer: true,
        restake: true,
        fdpInd: false,
        foundationNodeInd: false,
        foundationNodeIndLabel: "",
        badge1Ind: false,
        badge1IndLabel: null,
        badge1IndImg: null,
        badge1IndLink: null,
        badge2Ind: false,
        badge2IndLabel: null,
        badge2IndImg: null,
        badge2IndLink: null,
      }
    },
    others: {
      APP_LABEL_VP_COMP: "Cosmos",
      APP_KEY_VP_COMP: "ATOM",
    },
    proposal: {
      showDeposits: false,
    },
    integrations: {
      chainId: "coreum-mainnet-1",
      keplr: null,
      leap: "mainCoreum",
      mintscan: "coreum",
      pingpub: null,
      polkachu: null,
      kjnodes: null,
      nodestake: null,
      restake: "coreum",
      proposalHome: "mintscan",
      addressLink: "mintscan",
      mainStaking: null, 
      mainStakingLabel: null,
      proposalHomeLabel: null,
      addressLinkLabel: null,
      station: false,
      stakingHomeForValidator: null,
      stakingHomeLabel: null,
    },
  },
  "crypto-org": {
    networkName: "crypto-org",
    APP_KEY: "CRO",
    app: {
      title: "Crypto.org",
      token: "CRO",
      appName: "Crypto.org",
      addressPrefix: "cro",
      proposalToken: "$CRO",
      d14Referral: "cro",
      decentralization: true,
      smartStakeAddress: "crocncl1s3hd63shz3q3vca8ajptewv3zt7jg6ed9kk2zr",
      defaultValidatorViewStatus: "Bonded",
      defaultLogo: "/images/chains/cryptocom.jpg",
      defaultProjectLogo: "/images/chains/cryptocom.jpg",
      DEFAULT_POOL_ID: 1,
      showAirdrops: false,
      enableIsNew: true,
      validatorSetPages: 1,
      REST_ENDPOINT: "https://rest.cosmos.directory/cryptoorgchain/",
      REST_PROVIDER: "Cosmos Directory",
      REST_PROVIDER_WEBSITE: "https://cosmos.directory",
      tendermint: true,      
      burn: true,
      tokens: false,
    },
    ui: {
      beta: false,
      showApr: true,
      delegateToSmartStake: true,
      identifierAttribute: "operatorAddress",
      validators: {
        supportOracle: false,
        extraData3Label: "",
        extraData3LabelDesc: "",
        columnsConfig: {
          averageDelegation: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionPlus: { "hide": false, "hideMobile": false, "addToMobile": true },
          commission: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionMaxChangeRate: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionMaxRate: { "hide": true, "hideMobile": true, "addToMobile": true },
          currentMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          currentPerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          currentSigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          dailyMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          dailyPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          dailySigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          delegate: { "hide": false, "hideMobile": true, "addToMobile": false },
          description: { "hide": true, "hideMobile": true, "addToMobile": true },
          fav: { "hide": false, "hideMobile": false, "addToMobile": true },
          ibcRelayerInd: { "hide": false, "hideMobile": true, "addToMobile": true },
          vpRank: { "hide": false, "hideMobile": true, "addToMobile": true },
          longMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          longNetApr: { "hide": false, "hideMobile": true, "addToMobile": true },
          longPerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          longSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          midMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          midPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          midSigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          name: { "hide": false, "hideMobile": false, "addToMobile": true },
          operatorAddress: { "hide": true, "hideMobile": true, "addToMobile": true },

          hexAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          publicKey: { "hide": true, "hideMobile": true, "addToMobile": true },
          consensusNodeAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          address: { "hide": true, "hideMobile": true, "addToMobile": true },
          website: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalMissed: { "hide": true, "hideMobile": true, "addToMobile": true },
          latestSlashedTime: { "hide": true, "hideMobile": true, "addToMobile": true },

          securityContact: { "hide": true, "hideMobile": true, "addToMobile": true },
          selfBond: { "hide": true, "hideMobile": true, "addToMobile": true },
          selfBondPercentage: { "hide": true, "hideMobile": true, "addToMobile": true },
          slashingWindowMissedBlocks: { "hide": true, "hideMobile": true, "addToMobile": true },
          slashingWindowUptime: { "hide": true, "hideMobile": true, "addToMobile": true },
          status: { "hide": false, "hideMobile": true, "addToMobile": true },
          timesSlashed: { "hide": true, "hideMobile": true, "addToMobile": true },
          tombstoned: { "hide": true, "hideMobile": true, "addToMobile": true },
          totalUndelegationAmount: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegates: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange1d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange30d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange7d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueUndelegations: { "hide": true, "hideMobile": true, "addToMobile": true },
          voteParticipation: { "hide": false, "hideMobile": true, "addToMobile": true },
          voteParticipationOverall: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPower: { "hide": false, "hideMobile": true, "addToMobile": true },
          votingPowerChange1d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerChange30d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerChange7d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerPercentage: { "hide": false, "hideMobile": true, "addToMobile": true },
          identity: { "hide": false, "hideMobile": true, "addToMobile": true },
        },
      },
      validator: {
        extra1Label: "",
        extra2Label: "",
      },
      burn: {
        burnTitle: "CRO Burn",
        burnInProgressTitle: "Pending Burn (in community pool)",
        hideEmptyData: false,
      },
      network: {
        aprDisclaimer: false,
        showCirculatingSupply: true,
        showTotalSupply: true,
        vpCompareDays: 30,
      },
      delegations: {
        includeUndelInSameCall: false,
      },
      badges: {
        showBadges: true,
        relayer: true,
        restake: true,
        fdpInd: false,
        foundationNodeInd: true,
        foundationNodeIndLabel: "Internal validator run by Crypto.org",
        badge1Ind: false,
        badge1IndLabel: null,
        badge1IndImg: null,
        badge1IndLink: null,
        badge2Ind: false,
        badge2IndLabel: null,
        badge2IndImg: null,
        badge2IndLink: null,
      }
    },
    others: {
      APP_LABEL_VP_COMP: "Cosmos",
      APP_KEY_VP_COMP: "ATOM",
    },
    proposal: {
      showDeposits: false,
    },
    integrations: {
      chainId: "crypto-org-chain-mainnet-1",
      keplr: "crypto-org",
      leap: null,
      mintscan: "crypto-org",
      pingpub: "crypto-com-chain",
      polkachu: "crypto-com-chain",
      kjnodes: null,
      nodestake: null,
      restake: "cryptoorgchain",
      proposalHome: "keplr",
      addressLink: "mintscan",
      mainStaking: null, 
      mainStakingLabel: null,
      proposalHomeLabel: null,
      addressLinkLabel: null,
      station: false,
      stakingHomeForValidator: null,
      stakingHomeLabel: null,
    },
  },
  "evmos": {
    networkName: "evmos",
    APP_KEY: "EVMOS",
    app: {
      title: "Evmos",
      token: "EVMOS",
      appName: "Evmos",
      addressPrefix: "evmos",
      valoperPrefix: "evmosvaloper",
      proposalToken: "$EVMOS",
      d14Referral: "evmos",
      decentralization: true,
      smartStakeAddress: "evmosvaloper174jlcery04j6r7wgvequeysruyjlhtcmlhnhy2",
      defaultValidatorViewStatus: "Bonded",
      defaultLogo: "/images/chains/evmos.png",
      defaultProjectLogo: "/images/chains/evmos.png",
      DEFAULT_POOL_ID: 1,
      showAirdrops: false,
      enableIsNew: true,
      REST_ENDPOINT: "https://rest.cosmos.directory/evmos/",
      validatorSetPages: 2,
      REST_PROVIDER: "Cosmos Directory",
      REST_PROVIDER_WEBSITE: "https://cosmos.directory",
      tendermint: true,      
      tokens: false,
    },
    ui: {
      beta: false,
      showApr: true,
      delegateToSmartStake: true,
      identifierAttribute: "operatorAddress",
      validators: {
        supportOracle: false,
        extraData3Label: "",
        extraData3LabelDesc: "",
        columnsConfig: {
          averageDelegation: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionPlus: { "hide": false, "hideMobile": false, "addToMobile": true },
          commission: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionMaxChangeRate: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionMaxRate: { "hide": true, "hideMobile": true, "addToMobile": true },
          currentMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          currentPerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          currentSigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          dailyMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          dailyPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          dailySigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          delegate: { "hide": false, "hideMobile": true, "addToMobile": false },
          description: { "hide": true, "hideMobile": true, "addToMobile": true },
          fav: { "hide": false, "hideMobile": false, "addToMobile": true },
          ibcRelayerInd: { "hide": false, "hideMobile": true, "addToMobile": true },
          vpRank: { "hide": false, "hideMobile": true, "addToMobile": true },
          longMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          longNetApr: { "hide": false, "hideMobile": true, "addToMobile": true },
          longPerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          longSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          midMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          midPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          midSigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          name: { "hide": false, "hideMobile": false, "addToMobile": true },
          operatorAddress: { "hide": true, "hideMobile": true, "addToMobile": true },

          hexAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          publicKey: { "hide": true, "hideMobile": true, "addToMobile": true },
          consensusNodeAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          address: { "hide": true, "hideMobile": true, "addToMobile": true },
          website: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalMissed: { "hide": true, "hideMobile": true, "addToMobile": true },
          latestSlashedTime: { "hide": true, "hideMobile": true, "addToMobile": true },

          securityContact: { "hide": true, "hideMobile": true, "addToMobile": true },
          selfBond: { "hide": true, "hideMobile": true, "addToMobile": true },
          selfBondPercentage: { "hide": true, "hideMobile": true, "addToMobile": true },
          slashingWindowMissedBlocks: { "hide": true, "hideMobile": true, "addToMobile": true },
          slashingWindowUptime: { "hide": true, "hideMobile": true, "addToMobile": true },
          status: { "hide": false, "hideMobile": true, "addToMobile": true },
          timesSlashed: { "hide": true, "hideMobile": true, "addToMobile": true },
          tombstoned: { "hide": true, "hideMobile": true, "addToMobile": true },
          totalUndelegationAmount: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegates: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange1d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange30d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange7d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueUndelegations: { "hide": true, "hideMobile": true, "addToMobile": true },
          voteParticipation: { "hide": false, "hideMobile": true, "addToMobile": true },
          voteParticipationOverall: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPower: { "hide": false, "hideMobile": true, "addToMobile": true },
          votingPowerChange1d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerChange30d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerChange7d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerPercentage: { "hide": false, "hideMobile": true, "addToMobile": true },
          identity: { "hide": false, "hideMobile": true, "addToMobile": true },
        },
      },
      validator: {
        extra1Label: "",
        extra2Label: "",
      },
      network: {
        aprDisclaimer: false,
        showCirculatingSupply: true,
        showTotalSupply: true,
        vpCompareDays: 30,
      },
      delegations: {
        includeUndelInSameCall: false,
      },
      badges: {
        showBadges: true,
        relayer: true,
        restake: true,
        fdpInd: false,
        foundationNodeInd: false,
        foundationNodeIndLabel: "",
        badge1Ind: false,
        badge1IndLabel: null,
        badge1IndImg: null,
        badge1IndLink: null,
        badge2Ind: false,
        badge2IndLabel: null,
        badge2IndImg: null,
        badge2IndLink: null,
      }
    },
    others: {
      APP_LABEL_VP_COMP: "Cosmos",
      APP_KEY_VP_COMP: "ATOM",
    },
    proposal: {
      showDeposits: false,
    },
    integrations: {
      chainId: "evmos_9001-2",
      keplr: "evmos",
      leap: "evmos",
      mintscan: "evmos",
      pingpub: "evmos",
      polkachu: "evmos",
      kjnodes: null,
      nodestake: "evmos",
      restake: "evmos",
      proposalHome: "keplr",
      addressLink: "mintscan",
      mainStaking: null, 
      mainStakingLabel: null,
      proposalHomeLabel: null,
      addressLinkLabel: null,
      station: false,
      stakingHomeForValidator: null,
      stakingHomeLabel: null,
    },
  },
  "fetch": {
    networkName: "fetch",
    APP_KEY: "FET",
    app: {
      title: "Fetch",
      token: "FET",
      appName: "Fetch",
      addressPrefix: "fetch",
      valoperPrefix: "fetchvaloper",
      proposalToken: "$FET",
      d14Referral: "fet",
      decentralization: true, 
      smartStakeAddress: "fetchvaloper1neqdrj7tl6pwv3flckhajkgtvt5tee8tu8zr68",
      defaultValidatorViewStatus: "Bonded",
      defaultLogo: "/images/chains/fet.png",
      defaultProjectLogo: "/images/chains/fet.png",
      DEFAULT_POOL_ID: 1,
      showAirdrops: false,
      enableIsNew: true,
      REST_ENDPOINT: "https://rest.cosmos.directory/fetchhub/",
      validatorSetPages: 1,
      REST_PROVIDER: "Cosmos Directory",
      REST_PROVIDER_WEBSITE: "cosmos.directory",
      tendermint: true,      
      tokens: true,
    },
    ui: {
      beta: false,
      showApr: true,
      delegateToSmartStake: true,
      identifierAttribute: "operatorAddress",
      validators: {
        supportOracle: false,
        extraData3Label: "",
        extraData3LabelDesc: "",
        columnsConfig: {
          averageDelegation: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionPlus: { "hide": false, "hideMobile": false, "addToMobile": true },
          commission: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionMaxChangeRate: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionMaxRate: { "hide": true, "hideMobile": true, "addToMobile": true },
          currentMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          currentPerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          currentSigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          dailyMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          dailyPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          dailySigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          delegate: { "hide": false, "hideMobile": true, "addToMobile": false },
          description: { "hide": true, "hideMobile": true, "addToMobile": true },
          fav: { "hide": false, "hideMobile": false, "addToMobile": true },
          ibcRelayerInd: { "hide": false, "hideMobile": true, "addToMobile": true },
          vpRank: { "hide": false, "hideMobile": true, "addToMobile": true },
          longMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          longNetApr: { "hide": false, "hideMobile": true, "addToMobile": true },
          longPerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          longSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          midMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          midPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          midSigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          name: { "hide": false, "hideMobile": false, "addToMobile": true },
          operatorAddress: { "hide": true, "hideMobile": true, "addToMobile": true },

          hexAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          publicKey: { "hide": true, "hideMobile": true, "addToMobile": true },
          consensusNodeAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          address: { "hide": true, "hideMobile": true, "addToMobile": true },
          website: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalMissed: { "hide": true, "hideMobile": true, "addToMobile": true },
          latestSlashedTime: { "hide": true, "hideMobile": true, "addToMobile": true },

          securityContact: { "hide": true, "hideMobile": true, "addToMobile": true },
          selfBond: { "hide": true, "hideMobile": true, "addToMobile": true },
          selfBondPercentage: { "hide": true, "hideMobile": true, "addToMobile": true },
          slashingWindowMissedBlocks: { "hide": true, "hideMobile": true, "addToMobile": true },
          slashingWindowUptime: { "hide": true, "hideMobile": true, "addToMobile": true },
          status: { "hide": false, "hideMobile": true, "addToMobile": true },
          timesSlashed: { "hide": true, "hideMobile": true, "addToMobile": true },
          tombstoned: { "hide": true, "hideMobile": true, "addToMobile": true },
          totalUndelegationAmount: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegates: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange1d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange30d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange7d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueUndelegations: { "hide": true, "hideMobile": true, "addToMobile": true },
          voteParticipation: { "hide": false, "hideMobile": true, "addToMobile": true },
          voteParticipationOverall: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPower: { "hide": false, "hideMobile": true, "addToMobile": true },
          votingPowerChange1d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerChange30d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerChange7d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerPercentage: { "hide": false, "hideMobile": true, "addToMobile": true },
          identity: { "hide": false, "hideMobile": true, "addToMobile": true },
        },
      },
      validator: {
        extra1Label: "",
        extra2Label: "",
      },
      network: {
        aprDisclaimer: true,
        showCirculatingSupply: true,
        showTotalSupply: true,
        vpCompareDays: 30,
      },
      delegations: {
        includeUndelInSameCall: true,
      },
      badges: {
        showBadges: true,
        relayer: true,
        restake: true,
        fdpInd: false,
        foundationNodeInd: false,
        foundationNodeIndLabel: "",
        badge1Ind: false,
        badge1IndLabel: null,
        badge1IndImg: null,
        badge1IndLink: null,
        badge2Ind: false,
        badge2IndLabel: null,
        badge2IndImg: null,
        badge2IndLink: null,
      }
    },
    others: {
      APP_LABEL_VP_COMP: "Cosmos",
      APP_KEY_VP_COMP: "ATOM",
    },
    proposal: {
      showDeposits: false,
    },
    integrations: {
      chainId: "fetchhub-4",
      keplr: null,
      leap: "fetchhub",
      mintscan: "fetchai",
      pingpub: "fetchhub",
      polkachu: "fetchhub",
      kjnodes: null,
      nodestake: "fetchhub",
      restake: "fetchhub",
      proposalHome: "mintscan",
      addressLink: "mintscan",
      mainStaking: null, 
      mainStakingLabel: null,
      proposalHomeLabel: null,
      addressLinkLabel: null,
      station: false,
      stakingHomeForValidator: null,
      stakingHomeLabel: null,
    },
  },
  "omniflix": {
    networkName: "omniflix",
    APP_KEY: "FLIX",
    app: {
      title: "OmniFlix",
      token: "FLIX",
      appName: "OmniFlix",
      addressPrefix: "omniflix",
      valoperPrefix: "omniflixvaloper",
      proposalToken: "$FLIX",
      d14Referral: "flix",
      decentralization: true, 
      smartStakeAddress: "omniflixvaloper1qsqq7yfrfea8c3h6784at9yggakrghl3tk73fu",
      defaultValidatorViewStatus: "Bonded",
      defaultLogo: "/images/chains/flix.png",
      defaultProjectLogo: "/images/chains/flix.png",
      DEFAULT_POOL_ID: 1,
      showAirdrops: false,
      enableIsNew: true,
      REST_ENDPOINT: "https://rest.cosmos.directory/omniflixhub/",
      validatorSetPages: 1,
      REST_PROVIDER: "Cosmos Directory",
      REST_PROVIDER_WEBSITE: "cosmos.directory",
      tendermint: true,      
      tokens: true,
    },
    ui: {
      beta: false,
      showApr: true,
      delegateToSmartStake: true,
      identifierAttribute: "operatorAddress",
      validators: {
        supportOracle: false,
        extraData3Label: "",
        extraData3LabelDesc: "",
        columnsConfig: {
          averageDelegation: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionPlus: { "hide": false, "hideMobile": false, "addToMobile": true },
          commission: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionMaxChangeRate: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionMaxRate: { "hide": true, "hideMobile": true, "addToMobile": true },
          currentMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          currentPerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          currentSigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          dailyMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          dailyPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          dailySigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          delegate: { "hide": false, "hideMobile": true, "addToMobile": false },
          description: { "hide": true, "hideMobile": true, "addToMobile": true },
          fav: { "hide": false, "hideMobile": false, "addToMobile": true },
          ibcRelayerInd: { "hide": false, "hideMobile": true, "addToMobile": true },
          vpRank: { "hide": false, "hideMobile": true, "addToMobile": true },
          longMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          longNetApr: { "hide": false, "hideMobile": true, "addToMobile": true },
          longPerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          longSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          midMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          midPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          midSigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          name: { "hide": false, "hideMobile": false, "addToMobile": true },
          operatorAddress: { "hide": true, "hideMobile": true, "addToMobile": true },

          hexAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          publicKey: { "hide": true, "hideMobile": true, "addToMobile": true },
          consensusNodeAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          address: { "hide": true, "hideMobile": true, "addToMobile": true },
          website: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalMissed: { "hide": true, "hideMobile": true, "addToMobile": true },
          latestSlashedTime: { "hide": true, "hideMobile": true, "addToMobile": true },

          securityContact: { "hide": true, "hideMobile": true, "addToMobile": true },
          selfBond: { "hide": true, "hideMobile": true, "addToMobile": true },
          selfBondPercentage: { "hide": true, "hideMobile": true, "addToMobile": true },
          slashingWindowMissedBlocks: { "hide": true, "hideMobile": true, "addToMobile": true },
          slashingWindowUptime: { "hide": true, "hideMobile": true, "addToMobile": true },
          status: { "hide": false, "hideMobile": true, "addToMobile": true },
          timesSlashed: { "hide": true, "hideMobile": true, "addToMobile": true },
          tombstoned: { "hide": true, "hideMobile": true, "addToMobile": true },
          totalUndelegationAmount: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegates: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange1d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange30d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange7d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueUndelegations: { "hide": true, "hideMobile": true, "addToMobile": true },
          voteParticipation: { "hide": false, "hideMobile": true, "addToMobile": true },
          voteParticipationOverall: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPower: { "hide": false, "hideMobile": true, "addToMobile": true },
          votingPowerChange1d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerChange30d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerChange7d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerPercentage: { "hide": false, "hideMobile": true, "addToMobile": true },
          identity: { "hide": false, "hideMobile": true, "addToMobile": true },
        },
      },
      validator: {
        extra1Label: "",
        extra2Label: "",
      },
      network: {
        aprDisclaimer: true,
        showCirculatingSupply: false,
        showTotalSupply: true,
        vpCompareDays: 30,
      },
      delegations: {
        includeUndelInSameCall: true,
      },
      badges: {
        showBadges: true,
        relayer: true,
        restake: true,
        fdpInd: false,
        foundationNodeInd: false,
        foundationNodeIndLabel: "",
        badge1Ind: false,
        badge1IndLabel: null,
        badge1IndImg: null,
        badge1IndLink: null,
        badge2Ind: false,
        badge2IndLabel: null,
        badge2IndImg: null,
        badge2IndLink: null,
      }
    },
    others: {
      APP_LABEL_VP_COMP: "Cosmos",
      APP_KEY_VP_COMP: "ATOM",
    },
    proposal: {
      showDeposits: false,
    },
    integrations: {
      chainId: "omniflixhub-1",
      keplr: "omniflix",
      leap: "omniflix",
      mintscan: "omniflix",
      pingpub: "omniflixhub",
      polkachu: "omniflixhub",
      kjnodes: null,
      nodestake: "omniflixhub",
      restake: "omniflixhub",
      proposalHome: "keplr",
      addressLink: "mintscan",
      mainStaking: null, 
      mainStakingLabel: null,
      proposalHomeLabel: null,
      addressLinkLabel: null,
      station: false,
      stakingHomeForValidator: null,
      stakingHomeLabel: null,
    },
  },
  "injective": {
    networkName: "injective",
    APP_KEY: "INJ",
    app: {
      title: "Injective",
      token: "INJ",
      appName: "Injective",
      addressPrefix: "inj",
      valoperPrefix: "injvaloper",
      proposalToken: "$INJ",
      d14Referral: "inj",
      decentralization: true, 
      smartStakeAddress: "injvaloper1xwsnq88kc8wcrp34qenxf3dvhl5n02yj93u755",
      defaultValidatorViewStatus: "Bonded",
      defaultLogo: "/images/chains/injective.jpg",
      defaultProjectLogo: "/images/chains/injective.jpg",
      DEFAULT_POOL_ID: 6,
      showAirdrops: false,
      enableIsNew: true,
      REST_ENDPOINT: "https://rest.cosmos.directory/injective/",
      validatorSetPages: 1,
      REST_PROVIDER: "Cosmos Directory",
      REST_PROVIDER_WEBSITE: "https://cosmos.directory",
      tendermint: true,
      fdp: true,
      burn: true,
      tokens: true,
      lsm: true,
      lsmType: "ls",
    },
    ui: {
      beta: false,
      showApr: true,
      delegateToSmartStake: true,
      identifierAttribute: "operatorAddress",
      validators: {
        supportOracle: false,
        extraData3Label: "Valset Confirms",
        extraData3LabelDesc: "Current Valset Confirms - relayer performance",
        columnsConfig: {
          averageDelegation: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionPlus: { "hide": false, "hideMobile": false, "addToMobile": true },
          commission: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionMaxChangeRate: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionMaxRate: { "hide": true, "hideMobile": true, "addToMobile": true },
          currentMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          currentPerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          currentSigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          dailyMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          dailyPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          dailySigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          delegate: { "hide": false, "hideMobile": true, "addToMobile": false },
          description: { "hide": true, "hideMobile": true, "addToMobile": true },
          extraData1: {"hide": true, "hideMobile": true, "addToMobile": true},
          // extraData2: {"hide": true, "hideMobile": true, "addToMobile": true},
          extraData3: {"hide": false, "hideMobile": true, "addToMobile": true},
          fav: { "hide": false, "hideMobile": false, "addToMobile": true },
          ibcRelayerInd: { "hide": false, "hideMobile": true, "addToMobile": true },
          vpRank: { "hide": false, "hideMobile": true, "addToMobile": true },
          longMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          longNetApr: { "hide": false, "hideMobile": true, "addToMobile": true },
          longPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          longSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          midMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          midPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          midSigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          name: { "hide": false, "hideMobile": false, "addToMobile": true },
          operatorAddress: { "hide": true, "hideMobile": true, "addToMobile": true },

          hexAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          publicKey: { "hide": true, "hideMobile": true, "addToMobile": true },
          consensusNodeAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          address: { "hide": true, "hideMobile": true, "addToMobile": true },
          website: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalMissed: { "hide": true, "hideMobile": true, "addToMobile": true },
          latestSlashedTime: { "hide": true, "hideMobile": true, "addToMobile": true },

          securityContact: { "hide": true, "hideMobile": true, "addToMobile": true },
          selfBond: { "hide": true, "hideMobile": true, "addToMobile": true },
          selfBondPercentage: { "hide": true, "hideMobile": true, "addToMobile": true },
          slashingWindowMissedBlocks: { "hide": true, "hideMobile": true, "addToMobile": true },
          slashingWindowUptime: { "hide": true, "hideMobile": true, "addToMobile": true },
          status: { "hide": false, "hideMobile": true, "addToMobile": true },
          timesSlashed: { "hide": true, "hideMobile": true, "addToMobile": true },
          tombstoned: { "hide": true, "hideMobile": true, "addToMobile": true },
          totalUndelegationAmount: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegates: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange1d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange30d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange7d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueUndelegations: { "hide": true, "hideMobile": true, "addToMobile": true },
          voteParticipation: { "hide": false, "hideMobile": true, "addToMobile": true },
          voteParticipationOverall: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPower: { "hide": false, "hideMobile": true, "addToMobile": true },
          votingPowerChange1d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerChange30d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerChange7d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerPercentage: { "hide": false, "hideMobile": true, "addToMobile": true },
          identity: { "hide": false, "hideMobile": true, "addToMobile": true },
          orchAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          orchAddressBalance: { "hide": true, "hideMobile": true, "addToMobile": true },
          ethAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          ethAddressBalance: { "hide": true, "hideMobile": true, "addToMobile": true },
        },
      },
      validator: {
        extra1Label: "Current Nonce",
        extra2Label: "",
      },
      burn: {
        burnTitle: "Burns via Auction",
        burnInProgressTitle: null,
        hideEmptyData: true,
      },
      network: {
        aprDisclaimer: false,
        showCirculatingSupply: false,
        showTotalSupply: true,
        vpCompareDays: 30,
      },
      delegations: {
        includeUndelInSameCall: true,
      },
      badges: {
        showBadges: true,
        relayer: true,
        restake: true,
        fdpInd: true,
        foundationNodeInd: false,
        foundationNodeIndLabel: "",
        badge1Ind: true,
        badge1IndLabel: "Stride uses this validator for delegation as part of Liquid Staking. Click to use Stride app.",
        badge1IndTitle: "Stride",
        badge1IndImg: "/images/chains/stride.png",
        badge1IndLink: "https://app.stride.zone/",
        badge1Key: "STRD",
        badge2Ind: true,
        badge2IndLabel: "Hydro Protocol uses this validator for delegation as part of Liquid Staking. Click to use app.",
        badge2IndTitle: "Hydro Protocol",
        badge2IndImg: "/images/others/hydro.jpg",
        badge2IndLink: "https://hydroprotocol.finance",
        badge2Key: "HDRO",
        badge3Ind: true,
        badge3IndLabel: "Black Panther uses this validator for delegation as part of Liquid Staking. Click to use app.",
        badge3IndTitle: "Black Panther",
        badge3IndImg: "/images/others/black.svg",
        badge3IndLink: "https://blackpanther.fi",
        badge3Key: "BLACK",
        badge4Ind: true,
        badge4IndLabel: "Dojo Swap uses this validator for delegation as part of Liquid Staking. Click to use app.",
        badge4IndTitle: "Dojo Swap",
        badge4IndImg: "/images/others/dojo.svg",
        badge4IndLink: "https://dojo.trading",
        badge4Key: "DOJO",
        badge5Ind: true,
        badge5IndLabel: "TruFin uses this validator for delegation as part of Liquid Staking. Click to use app.",
        badge5IndTitle: "TruFin",
        badge5IndImg: "/images/others/trufin.jpg",
        badge5IndLink: "https://app.trufin.io/",
        badge5Key: "TRUFIN",
      },
    },
    others: {
      APP_LABEL_VP_COMP: "Cosmos",
      APP_KEY_VP_COMP: "ATOM",
    },
    proposal: {
      showDeposits: false
    },
    integrations: {
      chainId: "injective-1",
      keplr: "injective",
      leap: "injective",
      mintscan: "injective",
      pingpub: "injective",
      polkachu: "injective",
      kjnodes: null,
      nodestake: "injective",
      restake: "injective",
      proposalHome: "keplr",
      addressLink: "mintscan",
      mainStaking: null, 
      mainStakingLabel: null,
      proposalHomeLabel: null,
      addressLinkLabel: null,
      station: true,
      stakingHomeForValidator: null,
      stakingHomeLabel: null,
    },
    fdp: {
      extra: true,
      maxVP: false,
    }
  },
  "juno": {
    networkName: "juno",
    APP_KEY: "JUNO",
    app: {
      title: "Juno",
      token: "JUNO",
      appName: "Juno",
      addressPrefix: "juno",
      valoperPrefix: "junovaloper",
      proposalToken: "$JUNO",
      d14Referral: "juno",
      defaultValidatorViewStatus: "Bonded",
      decentralization: true,
      smartStakeAddress: "junovaloper1wj4jhrll90knap3vt8z4qs5rrm9cla2kpc5944",
      defaultLogo: "/images/chains/juno.jpg",
      defaultProjectLogo: "/images/chains/juno.jpg",
      DEFAULT_POOL_ID: 60,
      showAirdrops: false,
      enableIsNew: true,
      REST_ENDPOINT: "https://rest.cosmos.directory/juno/",
      validatorSetPages: 2,
      REST_PROVIDER: "Cosmos Directory",
      REST_PROVIDER_WEBSITE: "https://cosmos.directory",
      tendermint: true,
      tokens: true,
    },
    ui: {
      beta: false,
      showApr: true,
      delegateToSmartStake: true,
      identifierAttribute: "operatorAddress",
      validators: {
        supportOracle: false,
        // hideLongPerSignedMobile: true,
        // hideExtraData3: true,
        // hideExtraData1: true,
        extraData3Label: "",
        extraData3LabelDesc: "",
        // hiddenColumns: [], removedColumns: ["oracleMissedVotes", "extraData3", "extraData1", "extraData2"],
        // removedColumnsResp: ["delegate", "currentSigned", "currentMissed", "dailySigned", "dailyMissed", "midSigned", "midMissed", "longSigned", "longMissed"],
        columnsConfig: {
          averageDelegation: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionPlus: { "hide": false, "hideMobile": false, "addToMobile": true },
          commission: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionMaxChangeRate: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionMaxRate: { "hide": true, "hideMobile": true, "addToMobile": true },
          currentMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          currentPerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          currentSigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          dailyMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          dailyPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          dailySigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          delegate: { "hide": false, "hideMobile": true, "addToMobile": false },
          description: { "hide": true, "hideMobile": true, "addToMobile": true },
          fav: { "hide": false, "hideMobile": false, "addToMobile": true },
          ibcRelayerInd: { "hide": false, "hideMobile": true, "addToMobile": true },
          vpRank: { "hide": false, "hideMobile": true, "addToMobile": true },
          longMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          longNetApr: { "hide": false, "hideMobile": true, "addToMobile": true },
          longPerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          longSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          midMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          midPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          midSigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          name: { "hide": false, "hideMobile": false, "addToMobile": true },
          operatorAddress: { "hide": true, "hideMobile": true, "addToMobile": true },

          hexAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          publicKey: { "hide": true, "hideMobile": true, "addToMobile": true },
          consensusNodeAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          address: { "hide": true, "hideMobile": true, "addToMobile": true },
          website: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalMissed: { "hide": true, "hideMobile": true, "addToMobile": true },
          latestSlashedTime: { "hide": true, "hideMobile": true, "addToMobile": true },

          securityContact: { "hide": true, "hideMobile": true, "addToMobile": true },
          selfBond: { "hide": true, "hideMobile": true, "addToMobile": true },
          selfBondPercentage: { "hide": true, "hideMobile": true, "addToMobile": true },
          slashingWindowMissedBlocks: { "hide": true, "hideMobile": true, "addToMobile": true },
          slashingWindowUptime: { "hide": true, "hideMobile": true, "addToMobile": true },
          status: { "hide": false, "hideMobile": true, "addToMobile": true },
          timesSlashed: { "hide": true, "hideMobile": true, "addToMobile": true },
          tombstoned: { "hide": true, "hideMobile": true, "addToMobile": true },
          totalUndelegationAmount: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegates: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange1d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange30d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange7d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueUndelegations: { "hide": true, "hideMobile": true, "addToMobile": true },
          voteParticipation: { "hide": false, "hideMobile": true, "addToMobile": true },
          voteParticipationOverall: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPower: { "hide": false, "hideMobile": true, "addToMobile": true },
          votingPowerChange1d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerChange30d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerChange7d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerPercentage: { "hide": false, "hideMobile": true, "addToMobile": true },
          identity: { "hide": false, "hideMobile": true, "addToMobile": true },
        },
      },
      validator: {
        extra1Label: "",
        extra2Label: "",
      },
      network: {
        aprDisclaimer: false,
        showCirculatingSupply: true,
        showTotalSupply: true,
        vpCompareDays: 30,
      },
      delegations: {
        includeUndelInSameCall: false,
      },
      badges: {
        showBadges: true,
        relayer: true,
        restake: true,
        fdpInd: false, 
        foundationNodeInd: false,
        foundationNodeIndLabel: "",
        badge1Ind: false,
        badge1IndLabel: null,
        badge1IndImg: null,
        badge1IndLink: null,
        badge2Ind: false,
        badge2IndLabel: null,
        badge2IndImg: null,
        badge2IndLink: null,
      }
    },
    others: {
      APP_LABEL_VP_COMP: "Cosmos",
      APP_KEY_VP_COMP: "ATOM",
    },
    proposal: {
      showDeposits: false
    },
    integrations: {
      chainId: "juno-1",
      keplr: "juno",
      leap: "juno",
      mintscan: "juno",
      pingpub: "juno",
      polkachu: "juno",
      kjnodes: null,
      nodestake: "juno",
      restake: "juno",
      proposalHome: "keplr",
      addressLink: "mintscan",
      mainStaking: null, 
      mainStakingLabel: null,
      proposalHomeLabel: null,
      addressLinkLabel: null,
      station: true,
      stakingHomeForValidator: null,
      stakingHomeLabel: null,
    },
  },
  "kujira": {
    networkName: "kujira",
    APP_KEY: "KUJI",
    app: {
      title: "Kujira",
      token: "KUJI",
      appName: "Kujira",
      addressPrefix: "kujira",
      valoperPrefix: "kujiravaloper",
      proposalToken: "$KUJI",
      d14Referral: "kuji",
      decentralization: true,
      smartStakeAddress: "kujiravaloper16c402xnqkzcc6us4m5uff8d09cnlfdkmz85st6",
      defaultValidatorViewStatus: "Bonded",
      defaultLogo: "/images/chains/kujira.jpg",
      defaultProjectLogo: "/images/chains/kujira.jpg",
      DEFAULT_POOL_ID: 60,
      showAirdrops: false,
      enableIsNew: true,
      validatorSetPages: 1,
      REST_ENDPOINT: "https://rest.cosmos.directory/kujira/",
      REST_PROVIDER: "Cosmos Directory",
      REST_PROVIDER_WEBSITE: "https://cosmos.directory",
      tendermint: true,
      tokens: true,
    },
    ui: {
      beta: false,
      showApr: false,
      delegateToSmartStake: true,
      identifierAttribute: "operatorAddress",
      validators: {
        supportOracle: true,
        extraData3Label: "",
        extraData3LabelDesc: "",
        columnsConfig: {
          averageDelegation: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionPlus: { "hide": false, "hideMobile": false, "addToMobile": true },
          commission: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionMaxChangeRate: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionMaxRate: { "hide": true, "hideMobile": true, "addToMobile": true },
          currentMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          currentPerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          currentSigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          dailyMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          dailyPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          dailySigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          // delegate: { "hide": false, "hideMobile": true, "addToMobile": false },
          description: { "hide": true, "hideMobile": true, "addToMobile": true },
          fav: { "hide": false, "hideMobile": false, "addToMobile": true },
          ibcRelayerInd: { "hide": false, "hideMobile": true, "addToMobile": true },
          vpRank: { "hide": false, "hideMobile": true, "addToMobile": true },
          longMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          longPerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          longSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          midMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          midPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          midSigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          name: { "hide": false, "hideMobile": false, "addToMobile": true },
          operatorAddress: { "hide": true, "hideMobile": true, "addToMobile": true },

          hexAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          publicKey: { "hide": true, "hideMobile": true, "addToMobile": true },
          consensusNodeAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          address: { "hide": true, "hideMobile": true, "addToMobile": true },
          website: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalMissed: { "hide": true, "hideMobile": true, "addToMobile": true },
          latestSlashedTime: { "hide": true, "hideMobile": true, "addToMobile": true },

          securityContact: { "hide": true, "hideMobile": true, "addToMobile": true },
          selfBond: { "hide": true, "hideMobile": true, "addToMobile": true },
          selfBondPercentage: { "hide": true, "hideMobile": true, "addToMobile": true },
          slashingWindowMissedBlocks: { "hide": true, "hideMobile": true, "addToMobile": true },
          slashingWindowUptime: { "hide": true, "hideMobile": true, "addToMobile": true },
          status: { "hide": false, "hideMobile": true, "addToMobile": true },
          timesSlashed: { "hide": true, "hideMobile": true, "addToMobile": true },
          tombstoned: { "hide": true, "hideMobile": true, "addToMobile": true },
          totalUndelegationAmount: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegates: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange1d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange30d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange7d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueUndelegations: { "hide": true, "hideMobile": true, "addToMobile": true },
          voteParticipation: { "hide": false, "hideMobile": true, "addToMobile": true },
          voteParticipationOverall: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPower: { "hide": false, "hideMobile": true, "addToMobile": true },
          votingPowerChange1d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerChange30d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerChange7d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerPercentage: { "hide": false, "hideMobile": true, "addToMobile": true },
          identity: { "hide": false, "hideMobile": true, "addToMobile": true },

          currentOracleMissed: { "hide": true, "hideMobile": true, "addToMobile": true },
          currentOraclePerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          dailyOracleMissed: { "hide": true, "hideMobile": true, "addToMobile": true },
          dailyOraclePerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          longOracleMissed: { "hide": true, "hideMobile": true, "addToMobile": true },
          longOraclePerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          midOracleMissed: { "hide": true, "hideMobile": true, "addToMobile": true },
          midOraclePerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          oracleMissedVotes: { "hide": true, "hideMobile": true, "addToMobile": true },

        },
      },
      validator: {
        extra1Label: "",
        extra2Label: "",
      },
      network: {
        aprDisclaimer: false,
        showCirculatingSupply: true,
        showTotalSupply: true,
        vpCompareDays: 30,
      },
      delegations: {
        includeUndelInSameCall: false,
      },
      badges: {
        showBadges: true,
        relayer: true,
        restake: true,
        fdpInd: false, 
        foundationNodeInd: false,
        foundationNodeIndLabel: "",
        badge1Ind: false,
        badge1IndLabel: null,
        badge1IndImg: null,
        badge1IndLink: null,
        badge2Ind: false,
        badge2IndLabel: null,
        badge2IndImg: null,
        badge2IndLink: null,
      }
    },
    others: {
      APP_LABEL_VP_COMP: "Cosmos",
      APP_KEY_VP_COMP: "ATOM",
    },
    proposal: {
      showDeposits: false,
    },
    integrations: {
      chainId: "kaiyo-1",
      keplr: null,
      leap: "kujira",
      mintscan: null,
      pingpub: "kujira",
      polkachu: "kujira",
      kjnodes: "kujira",
      nodestake: "kujira",
      restake: "kujira",
      proposalHome: "https://blue.kujira.app/govern/",
      addressLink: "https://finder.kujira.app/kaiyo-1/address/",
      mainStaking: "https://blue.kujira.app/stake",
      mainStakingSuffix: "/",
      mainStakingLabel: "Kujira Blue",
      viewDetailsLabel: "Kujira",
      proposalHomeLabel: "Kujira Blue",
      addressLinkLabel: null,
      station: true,
      stakingHomeForValidator: null,
      stakingHomeLabel: null,
    },
  },
  "polygon": {
    networkName: "polygon",
    APP_KEY: "MATIC",
    app: {
      title: "Polygon",
      token: "MATIC",
      appName: "Polygon",
      addressPrefix: "0x",
      proposalToken: "$MATIC",
      d14Referral: "matic",
      decentralization: true, 
      smartStakeAddress: "40",
      defaultValidatorViewStatus: "active",
      defaultLogo: "/images/chains/polygon.png",
      defaultProjectLogo: "/images/chains/polygon.png",
      DEFAULT_POOL_ID: 1,
      showAirdrops: false,
      enableIsNew: true,
      REST_ENDPOINT: null,
      validatorSetPages: 1,
      REST_PROVIDER: null,
      REST_PROVIDER_WEBSITE: null,
      tendermint: false,
      burn: true,
    },
    ui: {
      beta: false,
      showApr: true,
      delegateToSmartStake: true,
      identifierAttribute: "polygonId",
      validators: {
        supportOracle: false,
        extraData3Label: "",
        extraData3LabelDesc: "",
        columnsConfig: {
          averageDelegation: { "hide": true, "hideMobile": true, "addToMobile": true },
          commission: { "hide": false, "hideMobile": false, "addToMobile": true },
          currentCheckpointMissed: { "hide": true, "hideMobile": true, "addToMobile": true },
          currentCheckpointSign: { "hide": true, "hideMobile": true, "addToMobile": true },
          currentPerCheckpointSign: { "hide": true, "hideMobile": true, "addToMobile": true },
          currentPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          currentState: { "hide": false, "hideMobile": true, "addToMobile": true },
          dailyCheckpointMissed: { "hide": true, "hideMobile": true, "addToMobile": true },
          dailyCheckpointSign: { "hide": true, "hideMobile": true, "addToMobile": true },
          dailyPerCheckpointSign: { "hide": true, "hideMobile": true, "addToMobile": true },
          dailyPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          delegate: { "hide": true, "hideMobile": true, "addToMobile": true },
          description: { "hide": true, "hideMobile": true, "addToMobile": true },
          deltaPerformanceBenchmark: { "hide": false, "hideMobile": true, "addToMobile": true },
          ethBalance: { "hide": true, "hideMobile": true, "addToMobile": true },
          fav: { "hide": false, "hideMobile": false, "addToMobile": true },
          historicalCheckpointMissed: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalCheckpointSign: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalPerCheckpointSign: { "hide": true, "hideMobile": true, "addToMobile": true },
          isInAuction: { "hide": true, "hideMobile": true, "addToMobile": true },
          latestSignedCheckpoint: { "hide": true, "hideMobile": true, "addToMobile": true },
          longCheckpointMissed: { "hide": true, "hideMobile": true, "addToMobile": true },
          longCheckpointSign: { "hide": true, "hideMobile": true, "addToMobile": true },
          longNetApr: { "hide": false, "hideMobile": true, "addToMobile": true },
          longPerCheckpointSign: { "hide": false, "hideMobile": true, "addToMobile": true },
          longPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          midCheckpointMissed: { "hide": true, "hideMobile": true, "addToMobile": true },
          midCheckpointSign: { "hide": true, "hideMobile": true, "addToMobile": true },
          midPerCheckpointSign: { "hide": true, "hideMobile": true, "addToMobile": true },
          midPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          missedLatestCheckpointCount: { "hide": false, "hideMobile": true, "addToMobile": true },
          name: { "hide": false, "hideMobile": false, "addToMobile": true },

          operatorAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          hexAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          publicKey: { "hide": true, "hideMobile": true, "addToMobile": true },
          consensusNodeAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          website: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalMissed: { "hide": true, "hideMobile": true, "addToMobile": true },
          latestSlashedTime: { "hide": true, "hideMobile": true, "addToMobile": true },

          selfBond: { "hide": true, "hideMobile": true, "addToMobile": true },
          selfBondPercentage: { "hide": true, "hideMobile": true, "addToMobile": true },
          status: { "hide": false, "hideMobile": true, "addToMobile": true },
          uniqueDelegates: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange1d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange30d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange7d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uptimePercent: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPower: { "hide": false, "hideMobile": true, "addToMobile": true },
          votingPowerChange1d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerChange30d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerChange7d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerPercentage: { "hide": false, "hideMobile": true, "addToMobile": true },
          identity: { "hide": false, "hideMobile": true, "addToMobile": true },
          vpRank: { "hide": false, "hideMobile": true, "addToMobile": true },
        },
      },
      validator: {
        extra1Label: "",
        extra2Label: "",
      },
      burn: {
        burnTitle: "Burn (EIP-1559)",
        burnInProgressTitle: "Burn in-progress",
        hideEmptyData: true,
      },
      network: {
        aprDisclaimer: false,
        showCirculatingSupply: true,
        showTotalSupply: true,
        vpCompareDays: 30,
      },
      delegations: {
        includeUndelInSameCall: true,
      },
      badges: {
        showBadges: false,
        relayer: false,
        restake: false,
        fdpInd: false,
        foundationNodeInd: false,
        foundationNodeIndLabel: "",
        badge1Ind: false,
        badge1IndLabel: null,
        badge1IndImg: null,
        badge1IndLink: null,
        badge2Ind: false,
        badge2IndLabel: null,
        badge2IndImg: null,
        badge2IndLink: null,
      }
    },
    others: {
      APP_LABEL_VP_COMP: "Cosmos",
      APP_KEY_VP_COMP: "ATOM",
    },
    proposal: {
      showDeposits: false,
    },
    integrations: {
      chainId: null,
      keplr: null,
      leap: null,
      mintscan: null,
      pingpub: null,
      polkachu: null,
      kjnodes: null,
      nodestake: null,
      restake: null,
      proposalHome: null,
      mainStaking: "https://staking.polygon.technology",
      mainStakingSuffix: "/validators/",
      mainStakingLabel: "Polygon Staking",
      addressLink: "https://polygonscan.com/address/",
      proposalHomeLabel: null,
      addressLinkLabel: "Polygon Scan",
      station: false,
      stakingHomeForValidator: null,
      stakingHomeLabel: null,
    },
  },
  "neutron": {
    networkName: "neutron",
    APP_KEY: "NTRN",
    app: {
      title: "Neutron",
      mainnet: true,
      token: "NTRN",
      stakedToken: "ATOM",
      appName: "Neutron",
      addressPrefix: "neutron",
      valoperPrefix: "cosmosvaloper",
      proposalToken: "$NTRN",
      d14Referral: "ntrn",
      decentralization: false,
      smartStakeAddress: "cosmosvaloper1wn8s4pk34c2zfs30kk8ue5nt9jqj6exn5wl2c7",
      defaultValidatorViewStatus: "hasToValidate",
      defaultLogo: "/images/chains/neutron.jpg",
      defaultProjectLogo: "/images/chains/neutron.jpg",
      DEFAULT_POOL_ID: 1,
      showAirdrops: false,
      enableIsNew: true,
      REST_ENDPOINT: "https://rest.cosmos.directory/neutron/",
      validatorSetPages: 1,
      REST_PROVIDER: "Cosmos Directory",
      REST_PROVIDER_WEBSITE: "https://cosmos.directory",
      tendermint: true,      
      consumerChain: true,
      burn: true,
      tokens: true,
    },
    ui: {
      beta: false,
      showApr: false,
      delegateToSmartStake: false,
      identifierAttribute: "operatorAddress",
      validators: {
        supportOracle: false,
        // ics: true,
        // icsCurrentPerSigned: true,
        // icsPerf: false,
        // slashingWindowUptime: false,
        // hideExtraData3: true,
        // hideExtraData1: true,
        extraData3Label: "",
        extraData3LabelDesc: "",
        columnsConfig: {
          averageDelegation: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionPlus: { "hide": false, "hideMobile": false, "addToMobile": true },
          commission: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionMaxChangeRate: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionMaxRate: { "hide": true, "hideMobile": true, "addToMobile": true },
          currentMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          currentPerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          currentSigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          dailyMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          dailyPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          dailySigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          fav: { "hide": false, "hideMobile": false, "addToMobile": true },
          icsCurrentPerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          icsPerf: { "hide": true, "hideMobile": true, "addToMobile": true },
          vpRank: { "hide": false, "hideMobile": true, "addToMobile": true },
          longMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          longPerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          longSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          midMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          midPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          midSigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          name: { "hide": false, "hideMobile": false, "addToMobile": true },
          operatorAddress: { "hide": true, "hideMobile": true, "addToMobile": true },

          hexAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          publicKey: { "hide": true, "hideMobile": true, "addToMobile": true },
          consensusNodeAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          address: { "hide": true, "hideMobile": true, "addToMobile": true },
          website: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalMissed: { "hide": true, "hideMobile": true, "addToMobile": true },
          latestSlashedTime: { "hide": true, "hideMobile": true, "addToMobile": true },

          hasToValidate: { "hide": false, "hideMobile": true, "addToMobile": true },
          securityContact: { "hide": true, "hideMobile": true, "addToMobile": true },
          selfBond: { "hide": true, "hideMobile": true, "addToMobile": true },
          selfBondPercentage: { "hide": true, "hideMobile": true, "addToMobile": true },
          slashingWindowMissedBlocks: { "hide": true, "hideMobile": true, "addToMobile": true },
          slashingWindowUptime: { "hide": true, "hideMobile": true, "addToMobile": true },
          status: { "hide": false, "hideMobile": true, "addToMobile": true },
          timesSlashed: { "hide": true, "hideMobile": true, "addToMobile": true },
          tombstoned: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPower: { "hide": false, "hideMobile": true, "addToMobile": true },
          votingPowerPercentage: { "hide": false, "hideMobile": true, "addToMobile": true },
          identity: { "hide": false, "hideMobile": true, "addToMobile": true },
        },
      },
      validator: {
        extra1Label: "",
        extra2Label: "",
      },
      burn: {
        burnTitle: "Neutron Burn",
        burnInProgressTitle: null,
        hideEmptyData: true,
      },
      network: {
        aprDisclaimer: false,
        showCirculatingSupply: true,
        showTotalSupply: true,
        vpCompareDays: 30,
        interchainMsg: true,
      },
      delegations: {
        includeUndelInSameCall: true,
      },
      badges: {
        showBadges: false,
        relayer: false,
        restake: false,
        fdpInd: false,
        foundationNodeInd: false,
        foundationNodeIndLabel: "",
        badge1Ind: false,
        badge1IndLabel: null,
        badge1IndImg: null,
        badge1IndLink: null,
        badge2Ind: false,
        badge2IndLabel: null,
        badge2IndImg: null,
        badge2IndLink: null,
      }
    },
    others: {
      APP_LABEL_VP_COMP: "Cosmos",
      APP_KEY_VP_COMP: "ATOM",
    },
    proposal: {
      showDeposits: false,
    },
    integrations: {
      chainId: "neutron-1",
      keplr: null,
      leap: null,
      mintscan: "neutron",
      pingpub: "neutron",
      polkachu: "neutron",
      kjnodes: null,
      nodestake: "neutron",
      restake: null,
      proposalHome: null,
      addressLink: "mintscan",
      proposalHomeLabel: null,
      addressLinkLabel: null,
      station: false,
      stakingHomeForValidator: null,
      stakingHomeLabel: null,
    },
  },
  "osmosis": {
    networkName: "osmosis",
    APP_KEY: "OSMO",
    app: {
      title: "Osmosis",
      token: "OSMO",
      appName: "Osmosis",
      addressPrefix: "osmo",
      valoperPrefix: "osmovaloper",
      proposalToken: "$OSMO",
      d14Referral: "osmo",
      decentralization: true, 
      smartStakeAddress: "osmovaloper1l7hln0l79erqaw6jdfdwx0hkfmj3dp27gr77ja",
      defaultValidatorViewStatus: "Bonded",
      defaultLogo: "/images/chains/osmosis.jpg",
      defaultProjectLogo: "/images/chains/osmosis.jpg",
      DEFAULT_POOL_ID: 116,
      showAirdrops: false,
      enableIsNew: true,
      REST_ENDPOINT: "https://rest.cosmos.directory/osmosis/",
      validatorSetPages: 2,
      REST_PROVIDER: "Cosmos Directory",
      REST_PROVIDER_WEBSITE: "https://cosmos.directory",
      tendermint: true,
      burn: true,
      tokens: true,
    },
    ui: {
      beta: false,
      showApr: true,
      delegateToSmartStake: true,
      identifierAttribute: "operatorAddress",
      validators: {
        supportOracle: false,
        extraData3Label: "",
        extraData3LabelDesc: "",
        columnsConfig: {
          averageDelegation: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionPlus: { "hide": false, "hideMobile": false, "addToMobile": true },
          commission: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionMaxChangeRate: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionMaxRate: { "hide": true, "hideMobile": true, "addToMobile": true },
          currentMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          currentPerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          currentSigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          dailyMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          dailyPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          dailySigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          delegate: { "hide": false, "hideMobile": true, "addToMobile": false },
          description: { "hide": true, "hideMobile": true, "addToMobile": true },
          fav: { "hide": false, "hideMobile": false, "addToMobile": true },
          ibcRelayerInd: { "hide": false, "hideMobile": true, "addToMobile": true },
          vpRank: { "hide": false, "hideMobile": true, "addToMobile": true },
          longMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          longNetApr: { "hide": false, "hideMobile": true, "addToMobile": true },
          longPerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          longSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          midMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          midPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          midSigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          name: { "hide": false, "hideMobile": false, "addToMobile": true },
          operatorAddress: { "hide": true, "hideMobile": true, "addToMobile": true },

          hexAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          publicKey: { "hide": true, "hideMobile": true, "addToMobile": true },
          consensusNodeAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          address: { "hide": true, "hideMobile": true, "addToMobile": true },
          website: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalMissed: { "hide": true, "hideMobile": true, "addToMobile": true },
          latestSlashedTime: { "hide": true, "hideMobile": true, "addToMobile": true },

          securityContact: { "hide": true, "hideMobile": true, "addToMobile": true },
          selfBond: { "hide": true, "hideMobile": true, "addToMobile": true },
          selfBondPercentage: { "hide": true, "hideMobile": true, "addToMobile": true },
          slashingWindowMissedBlocks: { "hide": true, "hideMobile": true, "addToMobile": true },
          slashingWindowUptime: { "hide": true, "hideMobile": true, "addToMobile": true },
          status: { "hide": false, "hideMobile": true, "addToMobile": true },
          timesSlashed: { "hide": true, "hideMobile": true, "addToMobile": true },
          tombstoned: { "hide": true, "hideMobile": true, "addToMobile": true },
          totalUndelegationAmount: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegates: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange1d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange30d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange7d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueUndelegations: { "hide": true, "hideMobile": true, "addToMobile": true },
          voteParticipation: { "hide": false, "hideMobile": true, "addToMobile": true },
          voteParticipationOverall: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPower: { "hide": false, "hideMobile": true, "addToMobile": true },
          votingPowerChange1d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerChange30d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerChange7d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerPercentage: { "hide": false, "hideMobile": true, "addToMobile": true },
          identity: { "hide": false, "hideMobile": true, "addToMobile": true },
        },
      },
      validator: {
        extra1Label: "",
        extra2Label: "",
      },
      burn: {
        burnTitle: "Burn",
        burnInProgressTitle: "",
        hideEmptyData: false,
      },
      network: {
        aprDisclaimer: false,
        showCirculatingSupply: true,
        showTotalSupply: true,
        vpCompareDays: 30,
      },
      delegations: {
        includeUndelInSameCall: false,
      },
      badges: {
        showBadges: true,
        relayer: true,
        restake: true,
        fdpInd: false,
        foundationNodeInd: false,
        foundationNodeIndLabel: "",
        badge1Ind: false,
        badge1IndLabel: null,
        badge1IndImg: null,
        badge1IndLink: null,
        badge2Ind: false,
        badge2IndLabel: null,
        badge2IndImg: null,
        badge2IndLink: null,
      }
    },
    others: {
      APP_LABEL_VP_COMP: "Cosmos",
      APP_KEY_VP_COMP: "ATOM",
    },
    proposal: {
      showDeposits: false
    },
    integrations: {
      chainId: "osmosis-1",
      keplr: "osmosis",
      leap: "osmosis",
      mintscan: "osmosis",
      pingpub: "osmosis",
      polkachu: "osmosis",
      kjnodes: "osmosis",
      nodestake: "osmosis",
      restake: "osmosis",
      proposalHome: "keplr",
      addressLink: "mintscan",
      mainStaking: null, 
      mainStakingLabel: null,
      proposalHomeLabel: null,
      addressLinkLabel: null,
      station: true,
      stakingHomeForValidator: null,
      stakingHomeLabel: null,
    },
    fdp: {
      extra: false,
      maxVP: false,
      accessKey: true,
      fdpAccessKey: "FDP_ACCESS_KEY_ICF_0"
    }
  },
  "stargaze": {
    networkName: "stargaze",
    APP_KEY: "STARS",
    app: {
      title: "Stargaze",
      token: "STARS",
      appName: "Stargaze",
      addressPrefix: "stars",
      valoperPrefix: "starsvaloper",
      proposalToken: "$STARS",
      d14Referral: "stars",
      decentralization: true,
      smartStakeAddress: "starsvaloper1ux7vg9ph4p68pg7e2l5yn703xllec388y2t65a",
      defaultValidatorViewStatus: "Bonded",
      defaultLogo: "/images/chains/stargaze.jpg",
      defaultProjectLogo: "/images/chains/stargaze.jpg",
      DEFAULT_POOL_ID: 1,
      showAirdrops: false,
      enableIsNew: true,
      REST_ENDPOINT: "https://rest.cosmos.directory/stargaze/",
      validatorSetPages: 2,
      REST_PROVIDER: "Cosmos Directory",
      REST_PROVIDER_WEBSITE: "https://cosmos.directory",
      tendermint: true,      
      burn: true,
      tokens: true,
    },
    ui: {
      beta: false,
      showApr: false,
      delegateToSmartStake: true,
      identifierAttribute: "operatorAddress",
      validators: {
        supportOracle: false,
        extraData3Label: "",
        extraData3LabelDesc: "",
        columnsConfig: {
          averageDelegation: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionPlus: { "hide": false, "hideMobile": false, "addToMobile": true },
          commission: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionMaxChangeRate: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionMaxRate: { "hide": true, "hideMobile": true, "addToMobile": true },
          currentMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          currentPerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          currentSigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          dailyMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          dailyPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          dailySigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          delegate: { "hide": false, "hideMobile": true, "addToMobile": false },
          description: { "hide": true, "hideMobile": true, "addToMobile": true },
          fav: { "hide": false, "hideMobile": false, "addToMobile": true },
          ibcRelayerInd: { "hide": false, "hideMobile": true, "addToMobile": true },
          vpRank: { "hide": false, "hideMobile": true, "addToMobile": true },
          longMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          // longNetApr: { "hide": false, "hideMobile": true, "addToMobile": true },
          longPerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          longSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          midMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          midPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          midSigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          name: { "hide": false, "hideMobile": false, "addToMobile": true },
          operatorAddress: { "hide": true, "hideMobile": true, "addToMobile": true },

          hexAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          publicKey: { "hide": true, "hideMobile": true, "addToMobile": true },
          consensusNodeAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          address: { "hide": true, "hideMobile": true, "addToMobile": true },
          website: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalMissed: { "hide": true, "hideMobile": true, "addToMobile": true },
          latestSlashedTime: { "hide": true, "hideMobile": true, "addToMobile": true },

          securityContact: { "hide": true, "hideMobile": true, "addToMobile": true },
          selfBond: { "hide": true, "hideMobile": true, "addToMobile": true },
          selfBondPercentage: { "hide": true, "hideMobile": true, "addToMobile": true },
          slashingWindowMissedBlocks: { "hide": true, "hideMobile": true, "addToMobile": true },
          slashingWindowUptime: { "hide": true, "hideMobile": true, "addToMobile": true },
          status: { "hide": false, "hideMobile": true, "addToMobile": true },
          timesSlashed: { "hide": true, "hideMobile": true, "addToMobile": true },
          tombstoned: { "hide": true, "hideMobile": true, "addToMobile": true },
          totalUndelegationAmount: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegates: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange1d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange30d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange7d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueUndelegations: { "hide": true, "hideMobile": true, "addToMobile": true },
          voteParticipation: { "hide": false, "hideMobile": true, "addToMobile": true },
          voteParticipationOverall: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPower: { "hide": false, "hideMobile": true, "addToMobile": true },
          votingPowerChange1d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerChange30d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerChange7d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerPercentage: { "hide": false, "hideMobile": true, "addToMobile": true },
          identity: { "hide": false, "hideMobile": true, "addToMobile": true },
        },
      },
      validator: {
        extra1Label: "",
        extra2Label: "",
      },
      burn: {
        burnTitle: "Fair Burn",
        burnInProgressTitle: "",
        hideEmptyData: false,
      },
      network: {
        aprDisclaimer: false,
        showCirculatingSupply: true,
        showTotalSupply: true,
        vpCompareDays: 30,
      },
      delegations: {
        includeUndelInSameCall: true,
      },
      badges: {
        showBadges: true,
        relayer: true,
        restake: true,
        fdpInd: false,
        foundationNodeInd: false,
        foundationNodeIndLabel: "",
        badge1Ind: false,
        badge1IndLabel: null,
        badge1IndImg: null,
        badge1IndLink: null,
        badge2Ind: false,
        badge2IndLabel: null,
        badge2IndImg: null,
        badge2IndLink: null,
      },
    },
    others: {
      APP_LABEL_VP_COMP: "Cosmos",
      APP_KEY_VP_COMP: "ATOM",
    },
    proposal: {
      showDeposits: false,
    },
    integrations: {
      chainId: "stargaze-1",
      keplr: "stargaze",
      leap: "stargaze",
      mintscan: "stargaze",
      pingpub: "stargaze",
      polkachu: "stargaze",
      kjnodes: null,
      nodestake: "stargaze",
      restake: "stargaze",
      proposalHome: "keplr",
      addressLink: "mintscan",
      mainStaking: null, 
      mainStakingLabel: null,
      proposalHomeLabel: null,
      addressLinkLabel: null,
      station: false,
      stakingHomeForValidator: null,
      stakingHomeLabel: null,
    },
  },
  "stride": {
    networkName: "stride",
    APP_KEY: "STRD",
    app: {
      title: "Stride",
      token: "STRD",
      stakedToken: "ATOM",
      appName: "Stride",
      addressPrefix: "stride",
      valoperPrefix: "cosmosvaloper",
      proposalToken: "$STRD",
      d14Referral: "strd",
      decentralization: true,
      smartStakeAddress: "stridevaloper1v9vjgshsnsgyq2dmp0d38m7ytqp8pwarxa6g7s",
      defaultValidatorViewStatus: "hasToValidate",
      defaultLogo: "/images/chains/stride.png",
      defaultProjectLogo: "/images/chains/stride.png",
      DEFAULT_POOL_ID: 1,
      showAirdrops: false,
      enableIsNew: true,
      REST_ENDPOINT: "https://rest.cosmos.directory/stride/",
      validatorSetPages: 1,
      REST_PROVIDER: "Cosmos Directory",
      REST_PROVIDER_WEBSITE: "https://cosmos.directory",
      tendermint: true,      
      consumerChain: true,
      tokens: true,      
    },
    ui: {
      beta: false,
      showApr: false,
      delegateToSmartStake: false,
      identifierAttribute: "operatorAddress",
      validators: {
        supportOracle: false,
        extraData3Label: "",
        extraData3LabelDesc: "",
        columnsConfig: {
          averageDelegation: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionPlus: { "hide": false, "hideMobile": false, "addToMobile": true },
          commission: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionMaxChangeRate: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionMaxRate: { "hide": true, "hideMobile": true, "addToMobile": true },
          currentMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          currentPerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          currentSigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          dailyMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          dailyPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          dailySigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          description: { "hide": true, "hideMobile": true, "addToMobile": true },
          fav: { "hide": false, "hideMobile": false, "addToMobile": true },
          icsCurrentPerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          icsPerf: { "hide": true, "hideMobile": true, "addToMobile": true },
          vpRank: { "hide": false, "hideMobile": true, "addToMobile": true },
          longMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          longPerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          longSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          midMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          midPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          midSigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          name: { "hide": false, "hideMobile": false, "addToMobile": true },
          operatorAddress: { "hide": true, "hideMobile": true, "addToMobile": true },

          hexAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          publicKey: { "hide": true, "hideMobile": true, "addToMobile": true },
          consensusNodeAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          address: { "hide": true, "hideMobile": true, "addToMobile": true },
          website: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalMissed: { "hide": true, "hideMobile": true, "addToMobile": true },
          latestSlashedTime: { "hide": true, "hideMobile": true, "addToMobile": true },

          hasToValidate: { "hide": false, "hideMobile": true, "addToMobile": true },

          securityContact: { "hide": true, "hideMobile": true, "addToMobile": true },
          selfBond: { "hide": true, "hideMobile": true, "addToMobile": true },
          selfBondPercentage: { "hide": true, "hideMobile": true, "addToMobile": true },
          slashingWindowMissedBlocks: { "hide": true, "hideMobile": true, "addToMobile": true },
          slashingWindowUptime: { "hide": true, "hideMobile": true, "addToMobile": true },
          status: { "hide": false, "hideMobile": true, "addToMobile": true },
          timesSlashed: { "hide": true, "hideMobile": true, "addToMobile": true },
          tombstoned: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPower: { "hide": false, "hideMobile": true, "addToMobile": true },
          votingPowerPercentage: { "hide": false, "hideMobile": true, "addToMobile": true },
          identity: { "hide": false, "hideMobile": true, "addToMobile": true },
        },
      },
      validator: {
        extra1Label: "",
        extra2Label: "",
      },
      network: {
        aprDisclaimer: false,
        showCirculatingSupply: true,
        showTotalSupply: true,
        vpCompareDays: 30,
      },
      delegations: {
        includeUndelInSameCall: true,
      },
      badges: {
        showBadges: true,
        relayer: true,
        restake: true,
        fdpInd: false,
        foundationNodeInd: false,
        foundationNodeIndLabel: "",
        badge1Ind: false,
        badge1IndLabel: null,
        badge1IndImg: null,
        badge1IndLink: null,
        badge2Ind: false,
        badge2IndLabel: null,
        badge2IndImg: null,
        badge2IndLink: null,
      }
    },
    others: {
      APP_LABEL_VP_COMP: "Cosmos",
      APP_KEY_VP_COMP: "ATOM",
    },
    proposal: {
      showDeposits: false,
    },
    integrations: {
      chainId: "stride-1",
      keplr: "stride",
      leap: null,
      mintscan: "stride",
      pingpub: "stride",
      polkachu: "stride",
      kjnodes: "stride",
      nodestake: "stride",
      restake: null,
      proposalHome: null,
      addressLink: "mintscan",
      proposalHomeLabel: null,
      addressLinkLabel: null,
      station: false,
      stakingHomeForValidator: null,
      stakingHomeLabel: null,
    },
  },
  "migaloo": {
    networkName: "migaloo",
    APP_KEY: "WHALE",
    app: {
      title: "Migaloo",
      token: "WHALE",
      appName: "Migaloo",
      addressPrefix: "migaloo",
      valoperPrefix: "migaloovaloper",
      proposalToken: "$WHALE",
      d14Referral: "whale",
      decentralization: true,
      smartStakeAddress: "migaloovaloper1epyycuzx0u0c44w354pu99u4wujldhulec8kck",
      defaultValidatorViewStatus: "Bonded",
      defaultLogo: "/images/chains/migaloo.jpg",
      defaultProjectLogo: "/images/chains/migaloo.jpg",
      DEFAULT_POOL_ID: 1,
      showAirdrops: false,
      enableIsNew: true,
      REST_ENDPOINT: "https://rest.cosmos.directory/migaloo/",
      validatorSetPages: 1,
      REST_PROVIDER: "Cosmos Directory",
      REST_PROVIDER_WEBSITE: "https://cosmos.directory",
      tendermint: true,      
      tokens: true,      
    },
    ui: {
      beta: false,
      showApr: true,
      delegateToSmartStake: true,
      identifierAttribute: "operatorAddress",
      validators: {
        supportOracle: false,
        extraData3Label: "",
        extraData3LabelDesc: "",
        columnsConfig: {
          averageDelegation: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionPlus: { "hide": false, "hideMobile": false, "addToMobile": true },
          commission: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionMaxChangeRate: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionMaxRate: { "hide": true, "hideMobile": true, "addToMobile": true },
          currentMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          currentPerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          currentSigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          dailyMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          dailyPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          dailySigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          delegate: { "hide": false, "hideMobile": true, "addToMobile": false },
          description: { "hide": true, "hideMobile": true, "addToMobile": true },
          fav: { "hide": false, "hideMobile": false, "addToMobile": true },
          ibcRelayerInd: { "hide": false, "hideMobile": true, "addToMobile": true },
          vpRank: { "hide": false, "hideMobile": true, "addToMobile": true },
          longMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          longNetApr: { "hide": false, "hideMobile": true, "addToMobile": true },
          longPerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          longSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          midMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          midPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          midSigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          name: { "hide": false, "hideMobile": false, "addToMobile": true },
          operatorAddress: { "hide": true, "hideMobile": true, "addToMobile": true },

          hexAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          publicKey: { "hide": true, "hideMobile": true, "addToMobile": true },
          consensusNodeAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          address: { "hide": true, "hideMobile": true, "addToMobile": true },
          website: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalMissed: { "hide": true, "hideMobile": true, "addToMobile": true },
          latestSlashedTime: { "hide": true, "hideMobile": true, "addToMobile": true },

          securityContact: { "hide": true, "hideMobile": true, "addToMobile": true },
          selfBond: { "hide": true, "hideMobile": true, "addToMobile": true },
          selfBondPercentage: { "hide": true, "hideMobile": true, "addToMobile": true },
          slashingWindowMissedBlocks: { "hide": true, "hideMobile": true, "addToMobile": true },
          slashingWindowUptime: { "hide": true, "hideMobile": true, "addToMobile": true },
          status: { "hide": false, "hideMobile": true, "addToMobile": true },
          timesSlashed: { "hide": true, "hideMobile": true, "addToMobile": true },
          tombstoned: { "hide": true, "hideMobile": true, "addToMobile": true },
          totalUndelegationAmount: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegates: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange1d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange30d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange7d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueUndelegations: { "hide": true, "hideMobile": true, "addToMobile": true },
          voteParticipation: { "hide": false, "hideMobile": true, "addToMobile": true },
          voteParticipationOverall: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPower: { "hide": false, "hideMobile": true, "addToMobile": true },
          votingPowerChange1d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerChange30d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerChange7d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerPercentage: { "hide": false, "hideMobile": true, "addToMobile": true },
          identity: { "hide": false, "hideMobile": true, "addToMobile": true },
        },
      },
      validator: {
        extra1Label: "",
        extra2Label: "",
      },
      network: {
        aprDisclaimer: true,
        showCirculatingSupply: true,
        showTotalSupply: true,
        vpCompareDays: 30,
      },
      delegations: {
        includeUndelInSameCall: true,
      },
      badges: {
        showBadges: true,
        relayer: true,
        restake: true,
        fdpInd: false,
        foundationNodeInd: false,
        foundationNodeIndLabel: "",
        badge1Ind: false,
        badge1IndLabel: null,
        badge1IndImg: null,
        badge1IndLink: null,
        badge2Ind: false,
        badge2IndLabel: null,
        badge2IndImg: null,
        badge2IndLink: null,
      }
    },
    others: {
      APP_LABEL_VP_COMP: "Cosmos",
      APP_KEY_VP_COMP: "ATOM",
    },
    proposal: {
      viewDetailsLabel: "View on Ping.pub",
      showDeposits: false,
    },
    integrations: {
      chainId: "migaloo-1",
      keplr: null,
      leap: "migaloo",
      mintscan: null,
      pingpub: "Migaloo",
      polkachu: "Migaloo",
      kjnodes: "Migaloo",
      nodestake: null,
      restake: "migaloo",
      proposalHome: "pingpub",
      addressLink: "mintscan",
      mainStaking: null, 
      mainStakingLabel: null,
      proposalHomeLabel: null,
      addressLinkLabel: null,
      station: true,
      stakingHomeForValidator: null,
      stakingHomeLabel: null,
    },
  },
  "persistence": {
    networkName: "persistence",
    APP_KEY: "XPRT",
    app: {
      title: "Persistence",
      token: "XPRT",
      appName: "Persistence",
      addressPrefix: "persistence",
      valoperPrefix: "persistencevaloper",
      proposalToken: "$XPRT",
      d14Referral: "xprt",
      decentralization: true, 
      smartStakeAddress: "persistencevaloper1qtggtsmexluvzulehxs7ypsfl82yk5aznrr2zd",
      defaultValidatorViewStatus: "Bonded",
      defaultLogo: "/images/chains/persistence.jpg",
      defaultProjectLogo: "/images/chains/persistence.jpg",
      DEFAULT_POOL_ID: 1,
      showAirdrops: false,
      enableIsNew: true,
      REST_ENDPOINT: "https://rest.cosmos.directory/persistence/",
      validatorSetPages: 1,
      REST_PROVIDER: "Cosmos Directory",
      REST_PROVIDER_WEBSITE: "https://cosmos.directory",
      tendermint: true,
      valBonds: true,
      tokens: true,
    },
    ui: {
      beta: false,
      showApr: true,
      delegateToSmartStake: true,
      identifierAttribute: "operatorAddress",
      validators: {
        supportOracle: false,
        extraData3Label: "",
        extraData3LabelDesc: "",
        columnsConfig: {
          averageDelegation: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionPlus: { "hide": false, "hideMobile": false, "addToMobile": true },
          commission: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionMaxChangeRate: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionMaxRate: { "hide": true, "hideMobile": true, "addToMobile": true },
          currentMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          currentPerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          currentSigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          dailyMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          dailyPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          dailySigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          delegate: { "hide": false, "hideMobile": true, "addToMobile": false },
          description: { "hide": true, "hideMobile": true, "addToMobile": true },
          fav: { "hide": false, "hideMobile": false, "addToMobile": true },
          ibcRelayerInd: { "hide": false, "hideMobile": true, "addToMobile": true },
          vpRank: { "hide": false, "hideMobile": true, "addToMobile": true },
          longMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          longPerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          longNetApr: { "hide": false, "hideMobile": true, "addToMobile": true },
          longSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          midMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          midPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          midSigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          name: { "hide": false, "hideMobile": false, "addToMobile": true },
          operatorAddress: { "hide": true, "hideMobile": true, "addToMobile": true },

          hexAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          publicKey: { "hide": true, "hideMobile": true, "addToMobile": true },
          consensusNodeAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          address: { "hide": true, "hideMobile": true, "addToMobile": true },
          website: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalMissed: { "hide": true, "hideMobile": true, "addToMobile": true },
          latestSlashedTime: { "hide": true, "hideMobile": true, "addToMobile": true },

          securityContact: { "hide": true, "hideMobile": true, "addToMobile": true },
          selfBond: { "hide": true, "hideMobile": true, "addToMobile": true },
          selfBondPercentage: { "hide": true, "hideMobile": true, "addToMobile": true },
          slashingWindowMissedBlocks: { "hide": true, "hideMobile": true, "addToMobile": true },
          slashingWindowUptime: { "hide": true, "hideMobile": true, "addToMobile": true },
          status: { "hide": false, "hideMobile": true, "addToMobile": true },
          timesSlashed: { "hide": true, "hideMobile": true, "addToMobile": true },
          tombstoned: { "hide": true, "hideMobile": true, "addToMobile": true },
          totalUndelegationAmount: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegates: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange1d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange30d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange7d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueUndelegations: { "hide": true, "hideMobile": true, "addToMobile": true },
          voteParticipation: { "hide": false, "hideMobile": true, "addToMobile": true },
          voteParticipationOverall: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPower: { "hide": false, "hideMobile": true, "addToMobile": true },
          votingPowerChange1d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerChange30d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerChange7d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerPercentage: { "hide": false, "hideMobile": true, "addToMobile": true },
          identity: { "hide": false, "hideMobile": true, "addToMobile": true },
        },
      },
      validator: {
        extra1Label: "",
        extra2Label: "",
      },
      network: {
        aprDisclaimer: false,
        showCirculatingSupply: true,
        showTotalSupply: true,
        vpCompareDays: 30,
      },
      delegations: {
        includeUndelInSameCall: false,
      },
      badges: {
        showBadges: true,
        relayer: true,
        restake: true,
        fdpInd: false,
        foundationNodeInd: true,
        foundationNodeIndLabel: "Internal validator run by Persistence foundation",
        badge1Ind: false,
        badge1IndLabel: null,
        badge1IndImg: null,
        badge1IndLink: null,
        badge2Ind: false,
        badge2IndLabel: null,
        badge2IndImg: null,
        badge2IndLink: null,
      }
    },
    others: {
      APP_LABEL_VP_COMP: "Cosmos",
      APP_KEY_VP_COMP: "ATOM",
    },
    proposal: {
      showDeposits: false,
    },
    integrations: {
      chainId: "core-1",
      keplr: "persistence",
      leap: "persistence",
      mintscan: "persistence",
      pingpub: "persistence",
      polkachu: "persistence",
      kjnodes: null,
      nodestake: "persistence",
      restake: "persistence",
      proposalHome: "keplr",
      addressLink: "mintscan",
      proposalHomeLabel: null,
      addressLinkLabel: null,
      station: false,
      stakingHomeForValidator: null,
      stakingHomeLabel: null,
    },
  },
  "dydx": {
    networkName: "dydx",
    APP_KEY: "DYDX",
    app: {
      title: "dYdX",
      token: "DYDX",
      appName: "dYdX",
      addressPrefix: "dydx",
      valoperPrefix: "dydxvaloper",
      proposalToken: "$DYDX",
      d14Referral: "dydx",
      defaultValidatorViewStatus: "Bonded",
      decentralization: true,
      smartStakeAddress: "dydxvaloper1t8hjjca8kecjtuhs2qy83maurj78fq2z5c44z5",
      defaultLogo: "/images/chains/dydx.png",
      defaultProjectLogo: "/images/chains/dydx.png",
      DEFAULT_POOL_ID: 1,
      showAirdrops: false,
      enableIsNew: false,
      REST_ENDPOINT: "https://rest.cosmos.directory/dydx/",
      validatorSetPages: 1,
      REST_PROVIDER: "Cosmos Directory",
      REST_PROVIDER_WEBSITE: "https://cosmos.directory",
      tendermint: true,
      tokens: true,
    },
    ui: {
      beta: false,
      showApr: false,
      delegateToSmartStake: true,
      identifierAttribute: "operatorAddress",
      validators: {
        supportOracle: false,
        extraData3Label: "",
        extraData3LabelDesc: "",
        columnsConfig: {
          averageDelegation: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionPlus: { "hide": false, "hideMobile": false, "addToMobile": true },
          commission: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionMaxChangeRate: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionMaxRate: { "hide": true, "hideMobile": true, "addToMobile": true },
          currentMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          currentPerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          currentSigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          dailyMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          dailyPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          dailySigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          delegate: { "hide": false, "hideMobile": true, "addToMobile": false },
          description: { "hide": true, "hideMobile": true, "addToMobile": true },
          fav: { "hide": false, "hideMobile": false, "addToMobile": true },
          ibcRelayerInd: { "hide": false, "hideMobile": true, "addToMobile": true },
          vpRank: { "hide": false, "hideMobile": true, "addToMobile": true },
          longMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          longPerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          longSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          midMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          midPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          midSigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          name: { "hide": false, "hideMobile": false, "addToMobile": true },
          operatorAddress: { "hide": true, "hideMobile": true, "addToMobile": true },

          hexAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          publicKey: { "hide": true, "hideMobile": true, "addToMobile": true },
          consensusNodeAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          address: { "hide": true, "hideMobile": true, "addToMobile": true },
          website: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalMissed: { "hide": true, "hideMobile": true, "addToMobile": true },
          latestSlashedTime: { "hide": true, "hideMobile": true, "addToMobile": true },

          securityContact: { "hide": true, "hideMobile": true, "addToMobile": true },
          selfBond: { "hide": true, "hideMobile": true, "addToMobile": true },
          selfBondPercentage: { "hide": true, "hideMobile": true, "addToMobile": true },
          slashingWindowMissedBlocks: { "hide": true, "hideMobile": true, "addToMobile": true },
          slashingWindowUptime: { "hide": true, "hideMobile": true, "addToMobile": true },
          status: { "hide": false, "hideMobile": true, "addToMobile": true },
          timesSlashed: { "hide": true, "hideMobile": true, "addToMobile": true },
          tombstoned: { "hide": true, "hideMobile": true, "addToMobile": true },
          totalUndelegationAmount: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegates: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange1d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange30d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange7d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueUndelegations: { "hide": true, "hideMobile": true, "addToMobile": true },
          voteParticipation: { "hide": true, "hideMobile": true, "addToMobile": true },
          voteParticipationOverall: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPower: { "hide": false, "hideMobile": true, "addToMobile": true },
          votingPowerChange1d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerChange30d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerChange7d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerPercentage: { "hide": false, "hideMobile": true, "addToMobile": true },
          identity: { "hide": false, "hideMobile": true, "addToMobile": true },
        },
      },
      validator: {
        extra1Label: "",
        extra2Label: "",
      },
      network: {
        aprDisclaimer: false,
        showCirculatingSupply: true,
        showTotalSupply: true,
        vpCompareDays: 10,
      },
      delegations: {
        includeUndelInSameCall: false,
      },
      badges: {
        showBadges: true,
        relayer: true,
        restake: true,
        fdpInd: false,
        foundationNodeInd: false,
        foundationNodeIndLabel: "",
        badge1Ind: false,
        badge1IndLabel: null,
        badge1IndImg: null,
        badge1IndLink: null,
        badge2Ind: false,
        badge2IndLabel: null,
        badge2IndImg: null,
        badge2IndLink: null,
      }
    },
    others: {
      APP_LABEL_VP_COMP: "Cosmos",
      APP_KEY_VP_COMP: "ATOM",
    },
    proposal: {
      showDeposits: false
    },
    integrations: {
      chainId: "dydx-mainnet-1",
      keplr: "dydx",
      leap: "dydx",
      mintscan: "dydx",
      pingpub: null,
      polkachu: null,
      kjnodes: null,
      nodestake: null,
      restake: "dydx",
      proposalHome: "mintscan",
      addressLink: "mintscan",
      proposalHomeLabel: null,
      addressLinkLabel: null,
      station: true,
      stakingHomeForValidator: null,
      stakingHomeLabel: null,
    },
    fdp: {
      extra: false,
      maxVP: false,
    }
  },
  "celestia": {
    networkName: "celestia",
    APP_KEY: "TIA",
    app: {
      title: "Celestia",
      token: "TIA",
      appName: "Celestia",
      addressPrefix: "celestia",
      valoperPrefix: "celestiavaloper",
      proposalToken: "$TIA",
      d14Referral: "TIA",
      defaultValidatorViewStatus: "Bonded",
      decentralization: true,
      smartStakeAddress: "celestiavaloper1tzm96yvupy3egtpg6uw0s2f4c85qayw3afwetd",
      defaultLogo: "/images/chains/celestia.png",
      defaultProjectLogo: "/images/chains/celestia.png",
      DEFAULT_POOL_ID: 1,
      showAirdrops: false,
      enableIsNew: true,
      REST_ENDPOINT: "https://rest.cosmos.directory/celestia/",
      validatorSetPages: 1,
      REST_PROVIDER: "Cosmos Directory",
      REST_PROVIDER_WEBSITE: "https://cosmos.directory",
      tendermint: true,
      lsm: true,
      lsmType: "ls",
      tokens: false,
    },
    ui: {
      beta: false,
      showApr: true,
      delegateToSmartStake: true,
      identifierAttribute: "operatorAddress",
      validators: {
        supportOracle: false,
        extraData3Label: "",
        extraData3LabelDesc: "",
        columnsConfig: {
          averageDelegation: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionPlus: { "hide": false, "hideMobile": false, "addToMobile": true },
          commission: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionMaxChangeRate: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionMaxRate: { "hide": true, "hideMobile": true, "addToMobile": true },
          currentMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          currentPerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          currentSigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          dailyMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          dailyPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          dailySigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          delegate: { "hide": false, "hideMobile": true, "addToMobile": false },
          description: { "hide": true, "hideMobile": true, "addToMobile": true },
          fav: { "hide": false, "hideMobile": false, "addToMobile": true },
          ibcRelayerInd: { "hide": false, "hideMobile": true, "addToMobile": true },
          vpRank: { "hide": false, "hideMobile": true, "addToMobile": true },
          longMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          longPerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          longNetApr: { "hide": false, "hideMobile": true, "addToMobile": true },
          longSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          midMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          midPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          midSigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          name: { "hide": false, "hideMobile": false, "addToMobile": true },
          operatorAddress: { "hide": true, "hideMobile": true, "addToMobile": true },

          hexAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          publicKey: { "hide": true, "hideMobile": true, "addToMobile": true },
          consensusNodeAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          address: { "hide": true, "hideMobile": true, "addToMobile": true },
          website: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalMissed: { "hide": true, "hideMobile": true, "addToMobile": true },
          latestSlashedTime: { "hide": true, "hideMobile": true, "addToMobile": true },

          securityContact: { "hide": true, "hideMobile": true, "addToMobile": true },
          selfBond: { "hide": true, "hideMobile": true, "addToMobile": true },
          selfBondPercentage: { "hide": true, "hideMobile": true, "addToMobile": true },
          slashingWindowMissedBlocks: { "hide": true, "hideMobile": true, "addToMobile": true },
          slashingWindowUptime: { "hide": true, "hideMobile": true, "addToMobile": true },
          status: { "hide": false, "hideMobile": true, "addToMobile": true },
          timesSlashed: { "hide": true, "hideMobile": true, "addToMobile": true },
          tombstoned: { "hide": true, "hideMobile": true, "addToMobile": true },
          totalUndelegationAmount: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegates: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange1d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange30d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange7d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueUndelegations: { "hide": true, "hideMobile": true, "addToMobile": true },
          voteParticipation: { "hide": true, "hideMobile": true, "addToMobile": true },
          voteParticipationOverall: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPower: { "hide": false, "hideMobile": true, "addToMobile": true },
          votingPowerChange1d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerChange30d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerChange7d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerPercentage: { "hide": false, "hideMobile": true, "addToMobile": true },
          identity: { "hide": false, "hideMobile": true, "addToMobile": true },
        },
      },
      validator: {
        extra1Label: "",
        extra2Label: "",
      },
      network: {
        aprDisclaimer: false,
        showCirculatingSupply: true,
        showTotalSupply: true,
        vpCompareDays: 10,
      },
      delegations: {
        includeUndelInSameCall: false,
      },
      badges: {
        showBadges: true,
        relayer: true,
        restake: true,
        fdpInd: false,
        foundationNodeInd: false,
        foundationNodeIndLabel: "",
        badge1Ind: true,
        badge1IndLabel: "Stride uses this validator for delegation as part of Liquid Staking. Click to use Stride app.",
        badge1IndTitle: "Stride",
        badge1IndImg: "/images/chains/stride.png",
        badge1IndLink: "https://app.stride.zone/",
        badge1Key: "STRD",
        badge2Ind: true,
        badge2IndLabel: "Milkyway uses this validator for delegation as part of Liquid Staking. Click to use Milkyway app.",
        badge2IndTitle: "Milkyway",
        badge2IndImg: "/images/others/milkyway.jpg",
        badge2IndLink: "https://app.milkyway.zone/",
        badge2Key: "MILK",
      }
    },
    others: {
      APP_LABEL_VP_COMP: "Cosmos",
      APP_KEY_VP_COMP: "ATOM",
    },
    proposal: {
      showDeposits: false
    },
    integrations: {
      chainId: "celestia",
      keplr: "celestia",
      leap: "celestia",
      mintscan: "celestia",
      pingpub: "celestia",
      polkachu: "celestia",
      kjnodes: "celestia",
      nodestake: "celestia",
      restake: "celestia",
      proposalHome: "keplr",
      addressLink: "mintscan",
      proposalHomeLabel: null,
      addressLinkLabel: null,
      station: true,
      stakingHomeForValidator: null,
      stakingHomeLabel: null,
    },
    fdp: {
      extra: false,
      maxVP: false,
      accessKey: true,
      fdpAccessKey: "FDP_ACCESS_KEY_ICF_0"
    }
  },
  "irisnet": {
    networkName: "irisnet",
    APP_KEY: "IRIS",
    app: {
      title: "IRISnet",
      token: "IRIS",
      appName: "IRISnet",
      addressPrefix: "iaa",
      proposalToken: "$IRIS",
      d14Referral: "iris",
      defaultValidatorViewStatus: "Bonded",
      decentralization: true,
      smartStakeAddress: "",
      defaultLogo: "/images/chains/iris.png",
      defaultProjectLogo: "/images/chains/iris.png",
      DEFAULT_POOL_ID: 1,
      showAirdrops: false,
      enableIsNew: false,
      REST_ENDPOINT: "https://rest.cosmos.directory/iris/",
      validatorSetPages: 1,
      REST_PROVIDER: "Cosmos Directory",
      REST_PROVIDER_WEBSITE: "https://cosmos.directory",
      tendermint: true,
      tokens: false,
    },
    ui: {
      beta: false,
      showApr: false,
      delegateToSmartStake: false,
      identifierAttribute: "operatorAddress",
      validators: {
        supportOracle: false,
        extraData3Label: "",
        extraData3LabelDesc: "",
        columnsConfig: {
          averageDelegation: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionPlus: { "hide": false, "hideMobile": false, "addToMobile": true },
          commission: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionMaxChangeRate: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionMaxRate: { "hide": true, "hideMobile": true, "addToMobile": true },
          currentMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          currentPerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          currentSigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          dailyMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          dailyPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          dailySigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          delegate: { "hide": false, "hideMobile": true, "addToMobile": false },
          description: { "hide": true, "hideMobile": true, "addToMobile": true },
          fav: { "hide": false, "hideMobile": false, "addToMobile": true },
          ibcRelayerInd: { "hide": false, "hideMobile": true, "addToMobile": true },
          vpRank: { "hide": false, "hideMobile": true, "addToMobile": true },
          longMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          longPerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          longSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          midMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          midPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          midSigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          name: { "hide": false, "hideMobile": false, "addToMobile": true },
          operatorAddress: { "hide": true, "hideMobile": true, "addToMobile": true },

          hexAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          publicKey: { "hide": true, "hideMobile": true, "addToMobile": true },
          consensusNodeAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          address: { "hide": true, "hideMobile": true, "addToMobile": true },
          website: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalMissed: { "hide": true, "hideMobile": true, "addToMobile": true },
          latestSlashedTime: { "hide": true, "hideMobile": true, "addToMobile": true },

          securityContact: { "hide": true, "hideMobile": true, "addToMobile": true },
          selfBond: { "hide": true, "hideMobile": true, "addToMobile": true },
          selfBondPercentage: { "hide": true, "hideMobile": true, "addToMobile": true },
          slashingWindowMissedBlocks: { "hide": true, "hideMobile": true, "addToMobile": true },
          slashingWindowUptime: { "hide": true, "hideMobile": true, "addToMobile": true },
          status: { "hide": false, "hideMobile": true, "addToMobile": true },
          timesSlashed: { "hide": true, "hideMobile": true, "addToMobile": true },
          tombstoned: { "hide": true, "hideMobile": true, "addToMobile": true },
          totalUndelegationAmount: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegates: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange1d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange30d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange7d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueUndelegations: { "hide": true, "hideMobile": true, "addToMobile": true },
          voteParticipation: { "hide": true, "hideMobile": true, "addToMobile": true },
          voteParticipationOverall: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPower: { "hide": false, "hideMobile": true, "addToMobile": true },
          votingPowerChange1d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerChange30d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerChange7d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerPercentage: { "hide": false, "hideMobile": true, "addToMobile": true },
          identity: { "hide": false, "hideMobile": true, "addToMobile": true },
        },
      },
      validator: {
        extra1Label: "",
        extra2Label: "",
      },
      network: {
        aprDisclaimer: false,
        showCirculatingSupply: true,
        showTotalSupply: true,
        vpCompareDays: 10,
      },
      delegations: {
        includeUndelInSameCall: false,
      },
      badges: {
        showBadges: true,
        relayer: true,
        restake: true,
        fdpInd: false,
        foundationNodeInd: false,
        foundationNodeIndLabel: "",
        badge1Ind: false,
        badge1IndLabel: null,
        badge1IndImg: null,
        badge1IndLink: null,
        badge2Ind: false,
        badge2IndLabel: null,
        badge2IndImg: null,
        badge2IndLink: null,
      }
    },
    others: {
      APP_LABEL_VP_COMP: "Cosmos",
      APP_KEY_VP_COMP: "ATOM",
    },
    proposal: {
      showDeposits: false
    },
    integrations: {
      chainId: "irishub-1",
      keplr: "iris",
      leap: "irisnet",
      mintscan: "iris",
      pingpub: null,
      polkachu: null,
      kjnodes: null,
      nodestake: null,
      restake: "irisnet",
      proposalHome: "mintscan",
      addressLink: "mintscan",
      proposalHomeLabel: null,
      addressLinkLabel: null,
      station: false,
      stakingHomeForValidator: null,
      stakingHomeLabel: null,
    },
    fdp: {
      extra: false,
      maxVP: false,
      accessKey: true,
      fdpAccessKey: "FDP_ACCESS_KEY_ICF_0"
    }
  },
  "furya": {
    networkName: "furya",
    APP_KEY: "FURY",
    app: {
      title: "Furya",
      token: "FURY",
      appName: "Furya",
      addressPrefix: "furya",
      proposalToken: "$FURY",
      d14Referral: "fury",
      defaultValidatorViewStatus: "Bonded",
      decentralization: true,
      smartStakeAddress: "",
      defaultLogo: "/images/chains/furya.png",
      defaultProjectLogo: "/images/chains/furya.png",
      DEFAULT_POOL_ID: 1,
      showAirdrops: false,
      enableIsNew: false,
      REST_ENDPOINT: "https://rest.cosmos.directory/furya/",
      validatorSetPages: 1,
      REST_PROVIDER: "Cosmos Directory",
      REST_PROVIDER_WEBSITE: "https://cosmos.directory",
      tendermint: true,
      tokens: false,
    },
    ui: {
      beta: false,
      showApr: false,
      delegateToSmartStake: false,
      identifierAttribute: "operatorAddress",
      validators: {
        supportOracle: false,
        extraData3Label: "",
        extraData3LabelDesc: "",
        columnsConfig: {
          averageDelegation: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionPlus: { "hide": false, "hideMobile": false, "addToMobile": true },
          commission: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionMaxChangeRate: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionMaxRate: { "hide": true, "hideMobile": true, "addToMobile": true },
          currentMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          currentPerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          currentSigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          dailyMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          dailyPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          dailySigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          // delegate: { "hide": false, "hideMobile": true, "addToMobile": false },
          description: { "hide": true, "hideMobile": true, "addToMobile": true },
          fav: { "hide": false, "hideMobile": false, "addToMobile": true },
          // ibcRelayerInd: { "hide": false, "hideMobile": true, "addToMobile": true },
          vpRank: { "hide": false, "hideMobile": true, "addToMobile": true },
          longMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          longPerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          longSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          midMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          midPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          midSigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          name: { "hide": false, "hideMobile": false, "addToMobile": true },
          operatorAddress: { "hide": true, "hideMobile": true, "addToMobile": true },

          hexAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          publicKey: { "hide": true, "hideMobile": true, "addToMobile": true },
          consensusNodeAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          address: { "hide": true, "hideMobile": true, "addToMobile": true },
          website: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalMissed: { "hide": true, "hideMobile": true, "addToMobile": true },
          latestSlashedTime: { "hide": true, "hideMobile": true, "addToMobile": true },

          securityContact: { "hide": true, "hideMobile": true, "addToMobile": true },
          selfBond: { "hide": true, "hideMobile": true, "addToMobile": true },
          selfBondPercentage: { "hide": true, "hideMobile": true, "addToMobile": true },
          slashingWindowMissedBlocks: { "hide": true, "hideMobile": true, "addToMobile": true },
          slashingWindowUptime: { "hide": true, "hideMobile": true, "addToMobile": true },
          status: { "hide": false, "hideMobile": true, "addToMobile": true },
          timesSlashed: { "hide": true, "hideMobile": true, "addToMobile": true },
          tombstoned: { "hide": true, "hideMobile": true, "addToMobile": true },
          totalUndelegationAmount: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegates: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange1d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange30d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange7d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueUndelegations: { "hide": true, "hideMobile": true, "addToMobile": true },
          voteParticipation: { "hide": true, "hideMobile": true, "addToMobile": true },
          voteParticipationOverall: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPower: { "hide": false, "hideMobile": true, "addToMobile": true },
          votingPowerChange1d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerChange30d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerChange7d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerPercentage: { "hide": false, "hideMobile": true, "addToMobile": true },
          identity: { "hide": false, "hideMobile": true, "addToMobile": true },
        },
      },
      validator: {
        extra1Label: "",
        extra2Label: "",
      },
      network: {
        aprDisclaimer: false,
        showCirculatingSupply: true,
        showTotalSupply: true,
        vpCompareDays: 10,
      },
      delegations: {
        includeUndelInSameCall: false,
      },
      badges: {
        showBadges: false,
        relayer: false,
        restake: false,
        fdpInd: false,
        foundationNodeInd: false,
        foundationNodeIndLabel: "",
        badge1Ind: false,
        badge1IndLabel: null,
        badge1IndImg: null,
        badge1IndLink: null,
        badge2Ind: false,
        badge2IndLabel: null,
        badge2IndImg: null,
        badge2IndLink: null,
      }
    },
    others: {
      APP_LABEL_VP_COMP: "Cosmos",
      APP_KEY_VP_COMP: "ATOM",
    },
    proposal: {
      showDeposits: false
    },
    integrations: {
      chainId: "furya-1",
      keplr: null,
      leap: null,
      mintscan: null,
      pingpub: null,
      polkachu: null,
      kjnodes: null,
      nodestake: null,
      restake: "furya",
      proposalHome: null,
      addressLink: null,
      proposalHomeLabel: null,
      addressLinkLabel: null,
      station: false,
      stakingHomeForValidator: "https://explorer.furya.wtf/furya/staking/",
      stakingHomeLabel: "Furya",
    },
    fdp: {
      extra: false,
      maxVP: false,
    }
  },
  "andromeda": {
    networkName: "andromeda",
    APP_KEY: "ANDR",
    app: {
      title: "Andromeda",
      token: "ANDR",
      appName: "Andromeda",
      addressPrefix: "andr",
      proposalToken: "$ANDR",
      d14Referral: "andr",
      defaultValidatorViewStatus: "Bonded",
      decentralization: true,
      smartStakeAddress: "",
      defaultLogo: "/images/chains/andromeda.jpg",
      defaultProjectLogo: "/images/chains/andromeda.jpg",
      DEFAULT_POOL_ID: 1,
      showAirdrops: false,
      enableIsNew: false,
      REST_ENDPOINT: null,
      validatorSetPages: 1,
      REST_PROVIDER: "Cosmos Directory",
      REST_PROVIDER_WEBSITE: "https://cosmos.directory",
      tendermint: true,
      tokens: true,
    },
    ui: {
      beta: false,
      showApr: false,
      delegateToSmartStake: false,
      identifierAttribute: "operatorAddress",
      validators: {
        supportOracle: false,
        extraData3Label: "",
        extraData3LabelDesc: "",
        columnsConfig: {
          averageDelegation: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionPlus: { "hide": false, "hideMobile": false, "addToMobile": true },
          commission: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionMaxChangeRate: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionMaxRate: { "hide": true, "hideMobile": true, "addToMobile": true },
          currentMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          currentPerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          currentSigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          dailyMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          dailyPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          dailySigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          description: { "hide": true, "hideMobile": true, "addToMobile": true },
          fav: { "hide": false, "hideMobile": false, "addToMobile": true },
          // ibcRelayerInd: { "hide": false, "hideMobile": true, "addToMobile": true },
          vpRank: { "hide": false, "hideMobile": true, "addToMobile": true },
          longMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          longPerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          longSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          midMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          midPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          midSigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          name: { "hide": false, "hideMobile": false, "addToMobile": true },
          operatorAddress: { "hide": true, "hideMobile": true, "addToMobile": true },

          hexAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          publicKey: { "hide": true, "hideMobile": true, "addToMobile": true },
          consensusNodeAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          address: { "hide": true, "hideMobile": true, "addToMobile": true },
          website: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalMissed: { "hide": true, "hideMobile": true, "addToMobile": true },
          latestSlashedTime: { "hide": true, "hideMobile": true, "addToMobile": true },

          securityContact: { "hide": true, "hideMobile": true, "addToMobile": true },
          selfBond: { "hide": true, "hideMobile": true, "addToMobile": true },
          selfBondPercentage: { "hide": true, "hideMobile": true, "addToMobile": true },
          slashingWindowMissedBlocks: { "hide": true, "hideMobile": true, "addToMobile": true },
          slashingWindowUptime: { "hide": true, "hideMobile": true, "addToMobile": true },
          status: { "hide": false, "hideMobile": true, "addToMobile": true },
          timesSlashed: { "hide": true, "hideMobile": true, "addToMobile": true },
          tombstoned: { "hide": true, "hideMobile": true, "addToMobile": true },
          totalUndelegationAmount: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegates: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange1d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange30d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange7d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueUndelegations: { "hide": true, "hideMobile": true, "addToMobile": true },
          voteParticipation: { "hide": true, "hideMobile": true, "addToMobile": true },
          voteParticipationOverall: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPower: { "hide": false, "hideMobile": true, "addToMobile": true },
          votingPowerChange1d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerChange30d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerChange7d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerPercentage: { "hide": false, "hideMobile": true, "addToMobile": true },
          identity: { "hide": false, "hideMobile": true, "addToMobile": true },
        },
      },
      validator: {
        extra1Label: "",
        extra2Label: "",
      },
      network: {
        aprDisclaimer: false,
        showCirculatingSupply: true,
        showTotalSupply: true,
        vpCompareDays: 10,
      },
      delegations: {
        includeUndelInSameCall: false,
      },
      badges: {
        showBadges: false,
        relayer: false,
        restake: false,
        fdpInd: false,
        foundationNodeInd: false,
        foundationNodeIndLabel: "",
        badge1Ind: false,
        badge1IndLabel: null,
        badge1IndImg: null,
        badge1IndLink: null,
        badge2Ind: false,
        badge2IndLabel: null,
        badge2IndImg: null,
        badge2IndLink: null,
      }
    },
    others: {
      APP_LABEL_VP_COMP: "Cosmos",
      APP_KEY_VP_COMP: "ATOM",
    },
    proposal: {
      showDeposits: false
    },
    integrations: {
      chainId: "andromeda-1",
      keplr: null,
      leap: null,
      mintscan: null,
      pingpub: "andromeda",
      polkachu: "andromeda",
      kjnodes: "andromeda",
      nodestake: "andromeda",
      restake: "andromeda",
      proposalHome: "pingpub",
      addressLink: "pingpub",
      proposalHomeLabel: null,
      addressLinkLabel: null,
      station: true,
      stakingHomeForValidator: null,
      stakingHomeLabel: null,
      extraLink: "https://ping.wildsage.io/andromeda"
    },
    fdp: {
      extra: false,
      maxVP: false,
    }
  },
  "dymension": {
    networkName: "dymension",
    APP_KEY: "DYM",
    app: {
      title: "Dymension",
      token: "DYM",
      appName: "Dymension",
      addressPrefix: "dym",
      valoperPrefix: "dymvaloper",
      proposalToken: "$DYM",
      d14Referral: "dym",
      decentralization: true, 
      smartStakeAddress: "dymvaloper1fy8uv0aqr7t5uvhdus9qhg5myepagvrk7sct9x",
      defaultValidatorViewStatus: "Bonded",
      defaultLogo: "/images/chains/dymension.png",
      defaultProjectLogo: "/images/chains/dymension.png",
      DEFAULT_POOL_ID: 1,
      showAirdrops: false,
      enableIsNew: true,
      REST_ENDPOINT: "https://rest.cosmos.directory/dymension/",
      validatorSetPages: 1,
      REST_PROVIDER: "Cosmos Directory",
      REST_PROVIDER_WEBSITE: "https://cosmos.directory",
      fdp: false,
      tendermint: true,
      tokens: true,
    },
    ui: {
      beta: false,
      showApr: true,
      delegateToSmartStake: true,
      identifierAttribute: "operatorAddress",
      validators: {
        supportOracle: false,
        extraData3Label: "",
        extraData3LabelDesc: "",
        columnsConfig: {
          averageDelegation: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionPlus: { "hide": false, "hideMobile": false, "addToMobile": true },
          commission: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionMaxChangeRate: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionMaxRate: { "hide": true, "hideMobile": true, "addToMobile": true },
          currentMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          currentPerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          currentSigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          dailyMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          dailyPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          dailySigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          delegate: { "hide": false, "hideMobile": true, "addToMobile": false },
          description: { "hide": true, "hideMobile": true, "addToMobile": true },
          fav: { "hide": false, "hideMobile": false, "addToMobile": true },
          ibcRelayerInd: { "hide": false, "hideMobile": true, "addToMobile": true },
          vpRank: { "hide": false, "hideMobile": true, "addToMobile": true },
          longMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          longNetApr: { "hide": false, "hideMobile": true, "addToMobile": true },
          longPerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          longSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          midMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          midPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          midSigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          name: { "hide": false, "hideMobile": false, "addToMobile": true },
          operatorAddress: { "hide": true, "hideMobile": true, "addToMobile": true },

          hexAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          publicKey: { "hide": true, "hideMobile": true, "addToMobile": true },
          consensusNodeAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          address: { "hide": true, "hideMobile": true, "addToMobile": true },
          website: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalMissed: { "hide": true, "hideMobile": true, "addToMobile": true },
          latestSlashedTime: { "hide": true, "hideMobile": true, "addToMobile": true },

          securityContact: { "hide": true, "hideMobile": true, "addToMobile": true },
          selfBond: { "hide": true, "hideMobile": true, "addToMobile": true },
          selfBondPercentage: { "hide": true, "hideMobile": true, "addToMobile": true },
          slashingWindowMissedBlocks: { "hide": true, "hideMobile": true, "addToMobile": true },
          slashingWindowUptime: { "hide": true, "hideMobile": true, "addToMobile": true },
          status: { "hide": false, "hideMobile": true, "addToMobile": true },
          timesSlashed: { "hide": true, "hideMobile": true, "addToMobile": true },
          tombstoned: { "hide": true, "hideMobile": true, "addToMobile": true },
          totalUndelegationAmount: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegates: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange1d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange30d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange7d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueUndelegations: { "hide": true, "hideMobile": true, "addToMobile": true },
          voteParticipation: { "hide": false, "hideMobile": true, "addToMobile": true },
          voteParticipationOverall: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPower: { "hide": false, "hideMobile": true, "addToMobile": true },
          votingPowerChange1d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerChange30d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerChange7d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerPercentage: { "hide": false, "hideMobile": true, "addToMobile": true },
          identity: { "hide": false, "hideMobile": true, "addToMobile": true },
        },
      },
      validator: {
        extra1Label: "",
        extra2Label: "",
      },
      network: {
        aprDisclaimer: false,
        showCirculatingSupply: true,
        showTotalSupply: true,
        vpCompareDays: 30,
      },
      delegations: {
        includeUndelInSameCall: false,
      },
      badges: {
        showBadges: true,
        relayer: true,
        restake: true,
        fdpInd: false,
        foundationNodeInd: false,
        foundationNodeIndLabel: "",
        badge1Ind: false,
        badge1IndLabel: null,
        badge1IndImg: null,
        badge1IndLink: null,
        badge2Ind: false,
        badge2IndLabel: null,
        badge2IndImg: null,
        badge2IndLink: null,
      }
    },
    others: {
      APP_LABEL_VP_COMP: "Cosmos",
      APP_KEY_VP_COMP: "ATOM",
    },
    proposal: {
      showDeposits: false
    },
    integrations: {
      chainId: "dymension_1100-1",
      keplr: "dymension",
      leap: "dymension",
      mintscan: "dymension",
      pingpub: "dymension",
      polkachu: "dymension",
      kjnodes: "dymension",
      nodestake: "dymension",
      restake: "dymension",
      proposalHome: "keplr",
      addressLink: "mintscan",
      mainStaking: "https://portal.dymension.xyz/dymension/staking",
      mainStakingSuffix: "ignoreAddress",
      mainStakingLabel: "Dymension",
      proposalHomeLabel: null,
      addressLinkLabel: null,
      station: false,
      stakingHomeForValidator: null,
      stakingHomeLabel: null,
    },
    fdp: {
      extra: false,
      maxVP: false,
      accessKey: false,
      fdpAccessKey: ""
    }
  },
  "removed_saga": {
    networkName: "removed_saga",
    APP_KEY: "SAGA",
    app: {
      title: "Saga",
      token: "SAGA",
      appName: "Saga",
      addressPrefix: "saga",
      valoperPrefix: "sagavaloper",
      proposalToken: "$SAGA",
      d14Referral: "saga",
      decentralization: true, 
      smartStakeAddress: "sagavaloper1xq7yfdkzkgqnymes720p5pnnrwj28naz6fc3yk",
      defaultValidatorViewStatus: "Bonded",
      defaultLogo: "/images/chains/saga.png",
      defaultProjectLogo: "/images/chains/saga.png",
      DEFAULT_POOL_ID: 1,
      showAirdrops: false,
      enableIsNew: true,
      REST_ENDPOINT: "https://rest.cosmos.directory/saga/",
      validatorSetPages: 1,
      REST_PROVIDER: "Cosmos Directory",
      REST_PROVIDER_WEBSITE: "https://cosmos.directory",
      fdp: false,
      tendermint: true,
      lsm: true,
      lsmType: "ls",
      tokens: true,
    },
    ui: {
      beta: false,
      showApr: true,
      delegateToSmartStake: true,
      identifierAttribute: "operatorAddress",
      validators: {
        supportOracle: false,
        extraData3Label: "",
        extraData3LabelDesc: "",
        columnsConfig: {
          averageDelegation: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionPlus: { "hide": false, "hideMobile": false, "addToMobile": true },
          commission: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionMaxChangeRate: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionMaxRate: { "hide": true, "hideMobile": true, "addToMobile": true },
          currentMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          currentPerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          currentSigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          dailyMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          dailyPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          dailySigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          delegate: { "hide": false, "hideMobile": true, "addToMobile": false },
          description: { "hide": true, "hideMobile": true, "addToMobile": true },
          fav: { "hide": false, "hideMobile": false, "addToMobile": true },
          ibcRelayerInd: { "hide": false, "hideMobile": true, "addToMobile": true },
          vpRank: { "hide": false, "hideMobile": true, "addToMobile": true },
          longMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          longNetApr: { "hide": false, "hideMobile": true, "addToMobile": true },
          longPerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          longSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          midMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          midPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          midSigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          name: { "hide": false, "hideMobile": false, "addToMobile": true },
          operatorAddress: { "hide": true, "hideMobile": true, "addToMobile": true },

          hexAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          publicKey: { "hide": true, "hideMobile": true, "addToMobile": true },
          consensusNodeAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          address: { "hide": true, "hideMobile": true, "addToMobile": true },
          website: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalMissed: { "hide": true, "hideMobile": true, "addToMobile": true },
          latestSlashedTime: { "hide": true, "hideMobile": true, "addToMobile": true },

          securityContact: { "hide": true, "hideMobile": true, "addToMobile": true },
          selfBond: { "hide": true, "hideMobile": true, "addToMobile": true },
          selfBondPercentage: { "hide": true, "hideMobile": true, "addToMobile": true },
          slashingWindowMissedBlocks: { "hide": true, "hideMobile": true, "addToMobile": true },
          slashingWindowUptime: { "hide": true, "hideMobile": true, "addToMobile": true },
          status: { "hide": false, "hideMobile": true, "addToMobile": true },
          timesSlashed: { "hide": true, "hideMobile": true, "addToMobile": true },
          tombstoned: { "hide": true, "hideMobile": true, "addToMobile": true },
          totalUndelegationAmount: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegates: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange1d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange30d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange7d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueUndelegations: { "hide": true, "hideMobile": true, "addToMobile": true },
          voteParticipation: { "hide": false, "hideMobile": true, "addToMobile": true },
          voteParticipationOverall: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPower: { "hide": false, "hideMobile": true, "addToMobile": true },
          votingPowerChange1d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerChange30d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerChange7d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerPercentage: { "hide": false, "hideMobile": true, "addToMobile": true },
          identity: { "hide": false, "hideMobile": true, "addToMobile": true },
        },
      },
      validator: {
        extra1Label: "",
        extra2Label: "",
      },
      network: {
        aprDisclaimer: true,
        showCirculatingSupply: true,
        showTotalSupply: true,
        vpCompareDays: 30,
      },
      delegations: {
        includeUndelInSameCall: false,
      },
      badges: {
        showBadges: true,
        relayer: true,
        restake: true,
        fdpInd: false,
        foundationNodeInd: false,
        foundationNodeIndLabel: "",
        badge1Ind: true,
        badge1IndLabel: "Stride uses this validator for delegation as part of Liquid Staking. Click to use Stride app.",
        badge1IndTitle: "Stride",
        badge1IndImg: "/images/chains/stride.png",
        badge1IndLink: "https://app.stride.zone/",
        badge1Key: "STRD",
        badge2Ind: true,
        badge2IndLabel: "Quicksilver uses this validator for delegation as part of Liquid Staking. Click to use Quicksilver app.",
        badge2IndTitle: "Quicksilver",
        badge2IndImg: "/images/chains/qck.png",
        badge2IndLink: "https://app.quicksilver.zone/stake/delegate",
        badge2Key: "QCK",
      }
    },
    others: {
      APP_LABEL_VP_COMP: "Cosmos",
      APP_KEY_VP_COMP: "ATOM",
    },
    proposal: {
      showDeposits: false
    },
    integrations: {
      chainId: "ssc-1",
      keplr: "saga",
      leap: "saga",
      mintscan: "saga",
      pingpub: "saga",
      polkachu: "saga",
      kjnodes: "saga",
      nodestake: "saga",
      restake: "saga",
      proposalHome: "keplr",
      addressLink: "mintscan",
      mainStaking: null,
      mainStakingSuffix: null,
      mainStakingLabel: null,
      proposalHomeLabel: null,
      addressLinkLabel: null,
      station: false,
      stakingHomeForValidator: null,
      stakingHomeLabel: null,
    },
    fdp: {
      extra: false,
      maxVP: false,
      accessKey: false,
      fdpAccessKey: ""
    }
  },
  "pryzm": {
    networkName: "pryzm",
    APP_KEY: "PRYZM",
    app: {
      title: "Pryzm",
      token: "PRYZM",
      appName: "Pryzm",
      addressPrefix: "pryzm",
      valoperPrefix: "pryzmvaloper",
      proposalToken: "$PRYZM",
      d14Referral: "pryzm",
      decentralization: true, 
      smartStakeAddress: "pryzmvaloper1rd3lxtk5zd9fm0enh6zqs97954tcl8yup4kccj",
      defaultValidatorViewStatus: "Bonded",
      defaultLogo: "/images/chains/pryzm.jpg",
      defaultProjectLogo: "/images/chains/pryzm.jpg",
      DEFAULT_POOL_ID: 1,
      showAirdrops: false,
      enableIsNew: true,
      REST_ENDPOINT: "https://rest.cosmos.directory/pryzm/",
      validatorSetPages: 1,
      REST_PROVIDER: "Cosmos Directory",
      REST_PROVIDER_WEBSITE: "https://cosmos.directory",
      fdp: false,
      tendermint: true,
      tokens: false,
    },
    ui: {
      beta: false,
      showApr: false,
      delegateToSmartStake: true,
      identifierAttribute: "operatorAddress",
      validators: {
        supportOracle: true,
        extraData3Label: "",
        extraData3LabelDesc: "",
        columnsConfig: {
          averageDelegation: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionPlus: { "hide": false, "hideMobile": false, "addToMobile": true },
          commission: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionMaxChangeRate: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionMaxRate: { "hide": true, "hideMobile": true, "addToMobile": true },
          currentMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          currentPerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          currentSigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          dailyMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          dailyPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          dailySigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          // delegate: { "hide": false, "hideMobile": true, "addToMobile": false },
          description: { "hide": true, "hideMobile": true, "addToMobile": true },
          fav: { "hide": false, "hideMobile": false, "addToMobile": true },
          // ibcRelayerInd: { "hide": false, "hideMobile": true, "addToMobile": true },
          vpRank: { "hide": false, "hideMobile": true, "addToMobile": true },
          longMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          longPerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          longSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          midMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          midPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          midSigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          name: { "hide": false, "hideMobile": false, "addToMobile": true },
          operatorAddress: { "hide": true, "hideMobile": true, "addToMobile": true },

          hexAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          publicKey: { "hide": true, "hideMobile": true, "addToMobile": true },
          consensusNodeAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          address: { "hide": true, "hideMobile": true, "addToMobile": true },
          website: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalMissed: { "hide": true, "hideMobile": true, "addToMobile": true },
          latestSlashedTime: { "hide": true, "hideMobile": true, "addToMobile": true },

          securityContact: { "hide": true, "hideMobile": true, "addToMobile": true },
          selfBond: { "hide": true, "hideMobile": true, "addToMobile": true },
          selfBondPercentage: { "hide": true, "hideMobile": true, "addToMobile": true },
          slashingWindowMissedBlocks: { "hide": true, "hideMobile": true, "addToMobile": true },
          slashingWindowUptime: { "hide": true, "hideMobile": true, "addToMobile": true },
          status: { "hide": false, "hideMobile": true, "addToMobile": true },
          timesSlashed: { "hide": true, "hideMobile": true, "addToMobile": true },
          tombstoned: { "hide": true, "hideMobile": true, "addToMobile": true },
          totalUndelegationAmount: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegates: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange1d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange30d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange7d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueUndelegations: { "hide": true, "hideMobile": true, "addToMobile": true },
          voteParticipation: { "hide": false, "hideMobile": true, "addToMobile": true },
          voteParticipationOverall: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPower: { "hide": false, "hideMobile": true, "addToMobile": true },
          votingPowerChange1d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerChange30d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerChange7d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerPercentage: { "hide": false, "hideMobile": true, "addToMobile": true },
          identity: { "hide": false, "hideMobile": true, "addToMobile": true },

          // currentOracleMissed: { "hide": true, "hideMobile": true, "addToMobile": true },
          // currentOraclePerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          // dailyOracleMissed: { "hide": true, "hideMobile": true, "addToMobile": true },
          // dailyOraclePerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          // longOracleMissed: { "hide": true, "hideMobile": true, "addToMobile": true },
          // longOraclePerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          // midOracleMissed: { "hide": true, "hideMobile": true, "addToMobile": true },
          // midOraclePerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          // oracleMissedVotes: { "hide": true, "hideMobile": true, "addToMobile": true },
        },
      },
      validator: {
        extra1Label: "",
        extra2Label: "",
      },
      network: {
        aprDisclaimer: false,
        showCirculatingSupply: true,
        showTotalSupply: true,
        vpCompareDays: 30,
      },
      delegations: {
        includeUndelInSameCall: false,
      },
      badges: {
        showBadges: false,
        relayer: true,
        restake: true,
        fdpInd: false,
        foundationNodeInd: false,
        foundationNodeIndLabel: "",
        badge1Ind: false,
        badge1IndLabel: null,
        badge1IndImg: null,
        badge1IndLink: null,
        badge2Ind: false,
        badge2IndLabel: null,
        badge2IndImg: null,
        badge2IndLink: null,
      }
    },
    others: {
      APP_LABEL_VP_COMP: "Cosmos",
      APP_KEY_VP_COMP: "ATOM",
    },
    proposal: {
      showDeposits: false
    },
    integrations: {
      chainId: "pryzm-1",
      keplr: null,
      leap: null,
      mintscan: null,
      pingpub: null,
      polkachu: null,
      kjnodes: null,
      nodestake: "pryzm",
      restake: "pryzm",
      proposalHome: null,
      addressLink: null,
      mainStaking: null,
      mainStakingSuffix: null,
      mainStakingLabel: null,
      proposalHomeLabel: null,
      addressLinkLabel: null,
      station: false,
      stakingHomeForValidator: null,
      stakingHomeLabel: null,
    },
    fdp: {
      extra: false,
      maxVP: false,
      accessKey: false,
      fdpAccessKey: ""
    }
  },
  "xion": {
    networkName: "xion",
    APP_KEY: "XION",
    app: {
      title: "Xion",
      token: "XION",
      appName: "Xion",
      addressPrefix: "xion",
      valoperPrefix: "xionvaloper",
      proposalToken: "$XION",
      d14Referral: "xion",
      decentralization: true, 
      smartStakeAddress: "xionvaloper1ak58a04fzdftza8vnnpv9fm4s3jeg8swd3vpgy",
      defaultValidatorViewStatus: "Bonded",
      defaultLogo: "/images/chains/xion.jpg",
      defaultProjectLogo: "/images/chains/xion.jpg",
      DEFAULT_POOL_ID: 1,
      showAirdrops: false,
      enableIsNew: true,
      REST_ENDPOINT: "https://rest.cosmos.directory/burnt/",
      validatorSetPages: 1,
      REST_PROVIDER: "Cosmos Directory",
      REST_PROVIDER_WEBSITE: "https://cosmos.directory",
      fdp: false,
      tendermint: true,
      tokens: false,
    },
    ui: {
      beta: false,
      showApr: false,
      delegateToSmartStake: true,
      identifierAttribute: "operatorAddress",
      validators: {
        supportOracle: false,
        extraData3Label: "",
        extraData3LabelDesc: "",
        columnsConfig: {
          averageDelegation: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionPlus: { "hide": false, "hideMobile": false, "addToMobile": true },
          commission: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionMaxChangeRate: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionMaxRate: { "hide": true, "hideMobile": true, "addToMobile": true },
          currentMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          currentPerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          currentSigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          dailyMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          dailyPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          dailySigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          delegate: { "hide": false, "hideMobile": true, "addToMobile": false },
          description: { "hide": true, "hideMobile": true, "addToMobile": true },
          fav: { "hide": false, "hideMobile": false, "addToMobile": true },
          ibcRelayerInd: { "hide": false, "hideMobile": true, "addToMobile": true },
          vpRank: { "hide": false, "hideMobile": true, "addToMobile": true },
          longMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          longPerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          longSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          midMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          midPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          midSigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          name: { "hide": false, "hideMobile": false, "addToMobile": true },
          operatorAddress: { "hide": true, "hideMobile": true, "addToMobile": true },

          hexAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          publicKey: { "hide": true, "hideMobile": true, "addToMobile": true },
          consensusNodeAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          address: { "hide": true, "hideMobile": true, "addToMobile": true },
          website: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalMissed: { "hide": true, "hideMobile": true, "addToMobile": true },
          latestSlashedTime: { "hide": true, "hideMobile": true, "addToMobile": true },

          securityContact: { "hide": true, "hideMobile": true, "addToMobile": true },
          selfBond: { "hide": true, "hideMobile": true, "addToMobile": true },
          selfBondPercentage: { "hide": true, "hideMobile": true, "addToMobile": true },
          slashingWindowMissedBlocks: { "hide": true, "hideMobile": true, "addToMobile": true },
          slashingWindowUptime: { "hide": true, "hideMobile": true, "addToMobile": true },
          status: { "hide": false, "hideMobile": true, "addToMobile": true },
          timesSlashed: { "hide": true, "hideMobile": true, "addToMobile": true },
          tombstoned: { "hide": true, "hideMobile": true, "addToMobile": true },
          totalUndelegationAmount: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegates: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange1d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange30d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueDelegatesChange7d: { "hide": true, "hideMobile": true, "addToMobile": true },
          uniqueUndelegations: { "hide": true, "hideMobile": true, "addToMobile": true },
          voteParticipation: { "hide": false, "hideMobile": true, "addToMobile": true },
          voteParticipationOverall: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPower: { "hide": false, "hideMobile": true, "addToMobile": true },
          votingPowerChange1d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerChange30d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerChange7d: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPowerPercentage: { "hide": false, "hideMobile": true, "addToMobile": true },
          identity: { "hide": false, "hideMobile": true, "addToMobile": true },
        },
      },
      validator: {
        extra1Label: "",
        extra2Label: "",
      },
      network: {
        aprDisclaimer: false,
        showCirculatingSupply: true,
        showTotalSupply: true,
        vpCompareDays: 30,
      },
      delegations: {
        includeUndelInSameCall: false,
      },
      badges: {
        showBadges: true,
        relayer: true,
        restake: true,
        fdpInd: false,
        foundationNodeInd: false,
        foundationNodeIndLabel: "",
        badge1Ind: false,
        badge1IndLabel: null,
        badge1IndImg: null,
        badge1IndLink: null,
        badge2Ind: false,
        badge2IndLabel: null,
        badge2IndImg: null,
        badge2IndLink: null,
      }
    },
    others: {
      APP_LABEL_VP_COMP: "Cosmos",
      APP_KEY_VP_COMP: "ATOM",
    },
    proposal: {
      showDeposits: false
    },
    integrations: {
      chainId: "xion-mainnet-1",
      keplr: "xion",
      leap: null,
      mintscan: "xion",
      pingpub: null,
      polkachu: "xion",
      kjnodes: null,
      nodestake: null,
      restake: "xion",
      proposalHome: "keplr",
      addressLink: "mintscan",
      mainStaking: null,
      mainStakingSuffix: null,
      mainStakingLabel: null,
      proposalHomeLabel: null,
      addressLinkLabel: null,
      station: false,
      stakingHomeForValidator: null,
      stakingHomeLabel: null,
    },
    fdp: {
      extra: false,
      maxVP: false,
      accessKey: false,
      fdpAccessKey: ""
    }
  },
  "elys": {
    networkName: "elys",
    APP_KEY: "ELYS",
    app: {
      title: "Elys",
      mainnet: true,
      token: "ELYS",
      stakedToken: "ATOM",
      appName: "Elys",
      addressPrefix: "elys",
      valoperPrefix: "cosmosvaloper",
      proposalToken: "$ELYS",
      d14Referral: "elys",
      decentralization: false,
      smartStakeAddress: "cosmosvaloper1wn8s4pk34c2zfs30kk8ue5nt9jqj6exn5wl2c7",
      defaultValidatorViewStatus: "hasToValidate",
      defaultLogo: "/images/chains/elys.jpg",
      defaultProjectLogo: "/images/chains/elys.jpg",
      DEFAULT_POOL_ID: 1,
      showAirdrops: false,
      enableIsNew: true,
      REST_ENDPOINT: "https://rest.cosmos.directory/elys/",
      validatorSetPages: 1,
      REST_PROVIDER: "Cosmos Directory",
      REST_PROVIDER_WEBSITE: "https://cosmos.directory",
      tendermint: true,      
      consumerChain: true,
      burn: false,
      tokens: false,
    },
    ui: {
      beta: false,
      showApr: false,
      delegateToSmartStake: false,
      identifierAttribute: "operatorAddress",
      validators: {
        supportOracle: false,
        // ics: true,
        // icsCurrentPerSigned: true,
        // icsPerf: false,
        // slashingWindowUptime: false,
        // hideExtraData3: true,
        // hideExtraData1: true,
        extraData3Label: "",
        extraData3LabelDesc: "",
        columnsConfig: {
          averageDelegation: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionPlus: { "hide": false, "hideMobile": false, "addToMobile": true },
          commission: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionMaxChangeRate: { "hide": true, "hideMobile": true, "addToMobile": true },
          commissionMaxRate: { "hide": true, "hideMobile": true, "addToMobile": true },
          currentMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          currentPerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          currentSigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          dailyMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          dailyPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          dailySigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          fav: { "hide": false, "hideMobile": false, "addToMobile": true },
          icsCurrentPerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          icsPerf: { "hide": true, "hideMobile": true, "addToMobile": true },
          vpRank: { "hide": false, "hideMobile": true, "addToMobile": true },
          longMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          longPerSigned: { "hide": false, "hideMobile": true, "addToMobile": true },
          longSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          midMissed: { "hide": true, "hideMobile": true, "addToMobile": false },
          midPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          midSigned: { "hide": true, "hideMobile": true, "addToMobile": false },
          name: { "hide": false, "hideMobile": false, "addToMobile": true },
          operatorAddress: { "hide": true, "hideMobile": true, "addToMobile": true },

          hexAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          publicKey: { "hide": true, "hideMobile": true, "addToMobile": true },
          consensusNodeAddress: { "hide": true, "hideMobile": true, "addToMobile": true },
          address: { "hide": true, "hideMobile": true, "addToMobile": true },
          website: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalPerSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalSigned: { "hide": true, "hideMobile": true, "addToMobile": true },
          historicalMissed: { "hide": true, "hideMobile": true, "addToMobile": true },
          latestSlashedTime: { "hide": true, "hideMobile": true, "addToMobile": true },

          hasToValidate: { "hide": false, "hideMobile": true, "addToMobile": true },

          securityContact: { "hide": true, "hideMobile": true, "addToMobile": true },
          selfBond: { "hide": true, "hideMobile": true, "addToMobile": true },
          selfBondPercentage: { "hide": true, "hideMobile": true, "addToMobile": true },
          slashingWindowMissedBlocks: { "hide": true, "hideMobile": true, "addToMobile": true },
          slashingWindowUptime: { "hide": true, "hideMobile": true, "addToMobile": true },
          status: { "hide": false, "hideMobile": true, "addToMobile": true },
          timesSlashed: { "hide": true, "hideMobile": true, "addToMobile": true },
          tombstoned: { "hide": true, "hideMobile": true, "addToMobile": true },
          votingPower: { "hide": false, "hideMobile": true, "addToMobile": true },
          votingPowerPercentage: { "hide": false, "hideMobile": true, "addToMobile": true },
          identity: { "hide": false, "hideMobile": true, "addToMobile": true },
        },
      },
      validator: {
        extra1Label: "",
        extra2Label: "",
      },
      network: {
        aprDisclaimer: false,
        showCirculatingSupply: true,
        showTotalSupply: true,
        vpCompareDays: 30,
        interchainMsg: true,
      },
      delegations: {
        includeUndelInSameCall: true,
      },
      badges: {
        showBadges: false,
        relayer: false,
        restake: false,
        fdpInd: false,
        foundationNodeInd: false,
        foundationNodeIndLabel: "",
        badge1Ind: false,
        badge1IndLabel: null,
        badge1IndImg: null,
        badge1IndLink: null,
        badge2Ind: false,
        badge2IndLabel: null,
        badge2IndImg: null,
        badge2IndLink: null,
      }
    },
    others: {
      APP_LABEL_VP_COMP: "Cosmos",
      APP_KEY_VP_COMP: "ATOM",
    },
    proposal: {
      showDeposits: false,
    },
    integrations: {
      chainId: "elys-1",
      keplr: null,
      leap: null,
      mintscan: null,
      pingpub: null,
      polkachu: null,
      kjnodes: null,
      nodestake: null,
      restake: null,
      proposalHome: null,
      addressLink: null,
      proposalHomeLabel: null,
      addressLinkLabel: null,
      station: false,
      stakingHomeForValidator: null,
      stakingHomeLabel: null,
    },
  },
};
