import React from 'react';
import { CSVLink } from 'react-csv';


class DownloadUtils extends React.Component {
  static getDownloadDataForChart(data, xAxisValueAttr, valueAttr, extraAttr) {
    let allAttributes = [xAxisValueAttr].concat(valueAttr);
    if (extraAttr) {
      allAttributes = allAttributes.concat(extraAttr);
    }

    return DownloadUtils.getDownloadData(data, allAttributes);
  }

  static getDownloadData(data, attributes) {
    let downloadData = [];

    for (let i = 0; i < data.length; i++) {
      let record = data[i];
      let newRecord = {};
      for (let j = 0; j < attributes.length; j++) {
        let attribute = attributes[j];
        newRecord[attribute] = record[attribute];
      }

      downloadData.push(newRecord);
    }

    return downloadData;
  }

  static getDownloadLink(downloadData, filename) {
    return (
      <CSVLink data={downloadData()} filename={filename + ".csv"}>
        <u>Download data used in this screen (CSV format)</u>
      </CSVLink>
    )
  }
  

  static getDownloadIconWithData(downloadData, filename) {
    return (
      <CSVLink data={downloadData} filename={filename + ".csv"} className="showItemAtTop animLink">
        <i className="mediaCustom fas fa-file-download fa-lg" />
      </CSVLink>
    )
  }


  static getSaveChart(saveChartFn) {
    return (
      <button onClick={saveChartFn} className="showItemAtTop animLink">
        <i className="mediaCustom fas fa-save fa-lg" />
      </button>
    )
  }

  static getDownloadLinkWithLabel(downloadData, filename, label) {
    return (
      <CSVLink data={downloadData()} filename={filename + ".csv"}>
        <u>{label}</u>
      </CSVLink>
    )
  }

  static getDownloadIconWithTableStyle(downloadData, filename) {
    return (
      <button className="tourColumnSettings animLinkStatusAction animLink" aria-label="download data">
        <CSVLink data={downloadData} filename={filename + ".csv"}>
          <i className="mediaCustom fas fa-file-download fa-lg" />
        </CSVLink>
      </button>
    )
  }

  static getTableDownloadData(data, columns) {
    let attributes = [];
    let ignoreFields = ["extraData1", "extraData2", "extraData3", "delegate"]
    for(let i=0; i < columns.length; i++) {
      let field = columns[i]["dataField"];
      if (!ignoreFields.includes(field)) {
        attributes.push(field);
      }
    }

    return DownloadUtils.getDownloadData(data, attributes)
  }
}

export default DownloadUtils;
