import React from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import ApiUtils from '../util/ApiUtils';
import tooltips from "../tooltips";
import '../styles/section.css';
import currentNetwork from '../currentNetwork';
import VPCurveChart from './VPCurveChart';
import SPCalc from '../util/SPCalc';
import { MultiSelect } from 'react-multi-select-component';
import NetworkChartUtils from './NetworkChartUtils';

class VPCurveTimeline extends React.Component {
  constructor(props) {
    super(props);

    let previousDate = SPCalc.getNthDateInPast(currentNetwork.details.ui.network.vpCompareDays);
    this.state = {
      isLoading: true,
      width: window.innerWidth,
      selectedRecords: [{label: previousDate, value: previousDate}],
      showPreviousVPCurve: false,
      vpDates: [],
    //   vpCurve: [],
      vpCurveOverTime: {},
    }

    // this.showVPCurveChart = this.showVPCurveChart(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.setSelectedRecords = this.setSelectedRecords.bind(this);
    this.updateVPCurve = this.updateVPCurve.bind(this);
    // this.showVPCurveDates = this.showVPCurveDates(this);
  }

  async setSelectedRecords(e) {
    this.setState({"selectedRecords": e});
    await this.updateVPCurve(e);
  }

  async updateVPCurve(dateList) {
    // console.log(appList);
    let dates = "";
    for (let i = 0; i < dateList.length; i++) {
      if (i !== 0) {
        dates += ",";
      }
      dates += dateList[i]["value"];
    }
    let url = "listData?type=vpCurveOverTime&dates=" + dates;

    // console.log(url);
    const allData = await ApiUtils.get(url);
    // console.log(allData);
    // console.log("allData is:", allData);

    // let vpCurve = Utilities.addIndex(allData["vpCurve"]);
    // let showPreviousVPCurve = false;

    let vpCurves = allData["vpCurves"];
    // if (vpCurveOverTime != null) {
    //   showPreviousVPCurve = true;
    // }
    let currentDate = allData["currentDate"];
    let selectedRecords = this.state.selectedRecords;
    let recordFound = false;
    for(const record of selectedRecords) {
        if (record["label"] === currentDate) {
            recordFound = true;
        }
    }

    if (recordFound != true) {
        let currentRecord = {label: currentDate, value: currentDate};
        selectedRecords.unshift(currentRecord);
    }

    let vpDates = [];
    let datesData = allData["vpDates"];
    for(let i=0; datesData != null && i < datesData.length; i++) {
      vpDates.push({value: datesData[i].statDate, label: datesData[i].statDate});
    }

    // "showPreviousVPCurve": showPreviousVPCurve, 
    this.setState({"vpCurves": vpCurves, "curveIndexes": NetworkChartUtils.getIndexes(dateList, vpCurves),
        "vpDates": vpDates, "isLoading": false, "selectedRecords": selectedRecords});
  }

  updateDimensions() {
    this.setState({width: window.innerWidth});
  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  async componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);

    this.updateVPCurve(this.state.selectedRecords);
  }

  render() {
    if (this.state.isLoading) {
      return <div>Loading</div>;
    }
    // console.log("in vp curve comparison");
    let indexes = NetworkChartUtils.getIndexes(this.state.selectedRecords, this.state.vpCurves);
    // console.log(indexes);

    return (
      <div className="chartBg" id="vpCurveChanges">
        <VPCurveChart title="VP Curve Changes" shortTitle="VP Changes"
          chartVisualId = {currentNetwork.details.networkName + "-vp-curve-timeline"}
          xAxis="Validator Count" yAxis="Total Voting Power" adjustRange={true} 
          showVerticalLabel={false} valueAttr={["cumulativeVotingPower", "cumulativeAverageVotingPower"]} 
          rangeAttr="cumulativeVotingPower" showTotalLabel={false} chains={this.state.selectedRecords}
          xAxisValueAttr="index" data={this.state.vpCurves} tip={tooltips.networkCharts.vpCurveTimeline} 
          formatValues={false} showLegend={true} fsElementId="vpCurveChanges"
          selectedAppsForVPCurve={this.state.selectedRecords} setSelectedAppsForVPCurve={this.setSelectedRecords}
          curveIndexes={indexes} additionalLink={() => {
              return (<MultiSelect options={this.state.vpDates} value={this.state.selectedRecords}
                          onChange={this.setSelectedRecords} labelledBy="Compare with"/>);
          }} />
      </div>
    );
  }
  
//   showVPCurveChart() {
//     if (this.state.showPreviousVPCurve === true) {
//       let chains = [{label: "CurrentVPCurve", value: "CurrentVPCurve"}, {label: "30DaysOldVPCurve", value: "30DaysOldVPCurve"}];
//       let indexes = this.getIndexes(chains, this.state.vpCurveOverTime);
//       // value={this.state.vpCompareDate}

//       return (<VPCurveChart title="VP Curve Changes" xAxis="Validator Count" yAxis="Total Voting Power" adjustRange={true} 
//                 showVerticalLabel={false} valueAttr={["cumulativeVotingPower", "cumulativeAverageVotingPower"]} 
//                 rangeAttr="cumulativeVotingPower" showTotalLabel={false} chains={chains} hideApps={true}
//                 xAxisValueAttr="index" data={this.state.vpCurveOverTime} tip={tooltips.networkCharts.vpCurveTimeline} 
//                 formatValues={false} showLegend={true} curveIndexes={indexes} additionalLink={() => {
//                     return (<MultiSelect options={this.state.vpDates} 
//                         labelledBy="Compare with"/>);
//                 }} />);
//     }

//     return (<MultiLineChart title="Voting Power (VP) Curve" xAxis="Validator Count" yAxis="Total Voting Power" adjustRange={true} 
//               showVerticalLabel={false} valueAttr={["cumulativeVotingPower", "cumulativeAverageVotingPower"]} 
//               rangeAttr="cumulativeVotingPower" showTotalLabel={false} 
//               xAxisValueAttr="index" data={this.state.vpCurve} tip={tooltips.networkCharts.vpCurve} showLegend={true} 
//               handleChart={this.handleChartUpdateNetwork} formatValues={false} dots={[true, false]} />);
//   }


}

export default VPCurveTimeline;
