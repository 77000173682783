import React from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ReactTooltip from "react-tooltip";
import {Container} from 'react-bootstrap';

import ApiUtils from '../util/ApiUtils';
import tooltips from "../tooltips";
import '../styles/section.css';
import Utilities from '../util/Utilities';
import UIUtils from '../util/UIUtils';
import currentNetwork from '../currentNetwork';
import PageHeader from '../components/PageHeader';
import SessionUtils from '../util/SessionUtils';
import FdpUtils from './FdpUtils';
import FdpValidatorInline from './FdpValidatorInline';
import SPUtilities from '../util/SPUtilities';
import ProposalUtils from '../network/ProposalUtils';
import SPCalc from '../util/SPCalc';
import MainUtils from '../main/MainUtils';
import RespUtils from '../util/RespUtils';

class FdpValidator extends React.Component {
  FDP_VALIDATORS_KEY = "FDP_VALIDATORS_KEY";

  constructor(props) {
    super(props);

    this.state = {
      summary: {},
      fdp: {},
      fdpValidator: {},
      showDelegation: false,
      commission: [],
      uptime: [],
      proposals: [],
      extraUptime: [],
      upgrades: [],
      width: window.innerWidth,
      responsive: true,
      isLoading: true,
    }
    // this.showVPCurveChart = this.showVPCurveChart(this);
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  updateDimensions() {
    this.setState({width: window.innerWidth});
  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  async componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    SessionUtils.setNetwork(this);

    let url = "listData?type=fdpval";
    if (this.props.match && this.props.match.params.fdpNumber) {
        let fdpNumber = this.props.match.params.fdpNumber;
        url += "&fdpNumber=" + fdpNumber;
    }
    if (this.props.match && this.props.match.params.operatorAddress) {
        let operatorAddress = this.props.match.params.operatorAddress;
        url += "&operatorAddress=" + operatorAddress;
    }
    url += FdpUtils.setAccessKeyAndGetParam(this);
  
    const allData = await ApiUtils.get(url);
    if (allData) {
      this.setState({"fdp": allData["fdp"], "fdpValidator": allData["fdpValidator"], "summary": allData["summary"],
        "commission": Utilities.addIndex(allData["commission"]), "proposals": Utilities.addIndex(allData["proposals"]), 
        "uptime": Utilities.addIndex(allData["uptime"]), "extraUptime": Utilities.addIndex(allData["extraUptime"]), 
        "upgrades": Utilities.addIndex(allData["upgrades"]), "events": Utilities.addIndex(allData["events"]), 
        "showDelegation": allData["showDelegation"], isLoading: false});
    }
  }

  render() {
    if (this.state.isLoading) {
      return <div>Loading</div>;
    }

    let fdpColumns = FdpUtils.getFdpColumns(this.state.fdp, this.state.showDelegation);
    return (
      <div>
        {FdpUtils.getFdpBreadCrumb(false, this.state.fdp)}
        <PageHeader title={currentNetwork.details.app.appName + " FDP - " + this.state.fdp.periodLabel + " - " + this.state.fdpValidator.name} thisObj={this}/>
        <ReactTooltip id="main" place="top" type="dark" effect="float" multiline={true} />
        <FdpValidatorInline fdp={this.state.fdp} fdpValidator={this.state.fdpValidator} showDelegation={this.state.showDelegation} />
        <Container fluid className="containerLayout container-fluid chartContainer">
          <div className="chartGridContainer">
            {FdpUtils.isToBeShown(fdpColumns, "metUptimeObj") && (<div className="chartBg">{this.renderUptime()}</div>)}
            {currentNetwork.details.APP_KEY === "INJ" && (<div className="chartBg">{this.renderInjExtraUptime()}</div>)}
            
            {FdpUtils.isToBeShown(fdpColumns, "metGovernanceObj") && (<div className="chartBg">{this.renderProposals()}</div>)}
            {FdpUtils.isToBeShown(fdpColumns, "metUpgradeObj") && (<div className="chartBg">{this.renderUpgrades()}</div>)}
            <div className="chartBg">{this.renderCommission()}</div>
            {FdpUtils.isToBeShown(fdpColumns, "metSlashingFreqObj") && (<div className="chartBg">{this.renderSlashingEvents()}</div>)}
          </div>
        </Container>
      </div>
    );
  }
  
  renderCommission() {
    var columns = [
      {text: "#", dataField: "index", sort: true, headerStyle: Utilities.respWidth(7, 5, 4)},
      {text: "Date/Time", dataField: "eventRecordedTime", formatter: SPUtilities.epochFormatter, sort: true},
      {text: "Description", dataField: "description", sort: true,  sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(50, 40, 30)},
    ];

    var objectiveValueMin = this.state.fdp.minCommission;
    var objectiveValueMax = this.state.fdp.maxCommission;
    let objective = `Commission must remain within ${objectiveValueMin}% and ${objectiveValueMax}%.`;
    let objectivesResult = [{"name": "Met Requirement?", "result": this.state.fdpValidator.metCommObj}]

    return FdpUtils.renderTable(objective, "Commission Changes", this.state.commission, columns, tooltips.fdp.fdpval.commission, objectivesResult);
  }
  
  // hidden: RespUtils.isMobileView(), 
  renderUptime() {
    var columns = [
      {text: "#", dataField: "index", sort: true, headerStyle: Utilities.respWidth(7, 5, 4)},
      {text: "Date", dataField: "title", formatter: SPUtilities.formatDate, sort: true},
      {text: "Signed", hidden: RespUtils.isMobileView(), dataField: "signed", sort: true, sortFunc: Utilities.sortNumeric},
      {text: "Missed", hidden: RespUtils.isMobileView(), dataField: "missed", sort: true, sortFunc: Utilities.sortNumeric},
      {text: "Signed %", dataField: "signedPer", sort: true, sortFunc: Utilities.sortNumeric},
    ];
    
    var objectiveValue = MainUtils.percentFormatter(this.state.fdp.minUptime);
    var actualValue = MainUtils.percentFormatter(this.state.summary.uptime);
    let objective = `Average block sign uptime needs to be atleast ${objectiveValue}. Actual uptime is ${actualValue}.`;
    let objectivesResult = [{"name": "Met Uptime Requirement?", "result": this.state.fdpValidator.metUptimeObj}]

    return FdpUtils.renderTable(objective, "Uptime", this.state.uptime, columns, tooltips.fdp.fdpval.uptime, objectivesResult);
  }

  renderInjExtraUptime() {
    var columns = [
      {text: "#", dataField: "index", sort: true, headerStyle: Utilities.respWidth(7, 5, 4)},
      {text: "Date", dataField: "title", formatter: SPUtilities.formatDate, sort: true},
      {text: "Peggo in sync at any time?", hidden: RespUtils.isMobileView(), dataField: "oracleMissedVotes", formatter: FdpUtils.peggoFormatter, sort: true, sortFunc: Utilities.sortNumeric},
      {text: "Met Requirement", dataField: "metObjective", formatter: FdpUtils.objectiveFormatterBoolean, sort: true, },
    ];

    var objectiveValue = this.state.fdp.extraMaxDowntime;
    var actualValue = this.state.summary.downtimeDaysForExtraUptime;
    let objective = `Peggo cannot be completely down for more than ${objectiveValue} days in assessment window. Actual - ${actualValue} down days for Peggo.`;
    let objectivesResult = [{"name": "Met Peggo Requirement?", "result": this.state.fdpValidator.metExtraUptimeObj}]
    
    return FdpUtils.renderTable(objective, "Peggo Uptime", this.state.extraUptime, columns, tooltips.fdp.fdpval.peggoUptime, objectivesResult);
  }
 
  // "proposals", proposals, "events", events)

  renderUpgrades() {
    var columns = [
      {text: "#", dataField: "index", sort: true, headerStyle: Utilities.respWidth(7, 5, 4)},
      // {text: "Proposal Id", dataField: "proposalId", sort: true},
      {text: "Title", dataField: "title", formatter: ProposalUtils.renderProposalShort, sort: true},
      {text: "Upgrade Time", dataField: "upgradeTime", formatter: SPUtilities.epochFormatter, sort: true, sortFunc: Utilities.sortNumeric},
      {text: "Stabilization Time", hidden: RespUtils.isMobileView(), dataField: "postUpgradeBlockTime", formatter: SPUtilities.epochFormatter, sort: true, sortFunc: Utilities.sortNumeric},
      {text: "First Signed Time", hidden: RespUtils.isMobileView(), dataField: "firstSignedBlockTime", formatter: SPUtilities.epochFormatter, sort: true, sortFunc: Utilities.sortNumeric},
      {text: "Duration", dataField: "durationForFirstBlockSign", formatter: SPCalc.formatTimeLeft, sort: true, sortFunc: Utilities.sortNumeric},
      {text: "Peggo First Signed Time", hidden: RespUtils.isMobileView(), hidden: (currentNetwork.details.APP_KEY != "INJ"), dataField: "extraPerfTime", formatter: SPUtilities.epochFormatter, sort: true, sortFunc: Utilities.sortNumeric},
      {text: "Peggo Duration", hidden: (RespUtils.isMobileView() || currentNetwork.details.APP_KEY != "INJ"), dataField: "durationForExtraPerf", formatter: SPCalc.formatTimeLeft, sort: true, sortFunc: Utilities.sortNumeric},
    ];
    
    var objectiveValue = SPCalc.formatTimeLeft(this.state.fdp.upgradeMaxDuration);
    let objective = `Planned upgrades need to be performed within ${objectiveValue} after network upgrade has stabilized (100 blocks after upgrade).`;
    let objectivesResult = [{"name": "Met Requirement?", "result": this.state.fdpValidator.metUpgradeObj}]
    return FdpUtils.renderTable(objective, "Upgrades", this.state.upgrades, columns, tooltips.fdp.fdpval.upgrades, objectivesResult);
  }

  renderProposals() {
    var columns = [
      // {text: "#", dataField: "index", sort: true, headerStyle: Utilities.respWidth(7, 5, 4)},
      {text: "Id", dataField: "proposalId", sort: true, headerStyle: Utilities.respWidth(10, 7, 5)},
      {text: "Title", dataField: "title", formatter: ProposalUtils.renderProposalShort, sort: true, headerStyle: Utilities.respWidth(50, 40, 30)},
      {text: "Proposal End Time", hidden: RespUtils.isMobileView(), dataField: "epochVotingEndTime", formatter: SPUtilities.epochFormatter, sort: true, headerStyle: Utilities.respWidth(30, 25, 20)},
      {text: "Vote", dataField: "voteOption", formatter: ProposalUtils.formatVoteByProp, sort: true, headerStyle: Utilities.respWidth(20, 15, 10)},
    ];
    
    var objectiveValue = this.state.fdp.minGovParticipation;
    // console.log(this.state.summary);
    var actualValue = MainUtils.percentValue(this.state.summary.govPartRate);
    
    let objective = `Minimum of ${objectiveValue}% governance participation is needed. Actual participation rate is ${actualValue}.`;
    let objectivesResult = [{"name": "Met Requirement?", "result": this.state.fdpValidator.metGovernanceObj}]
    return FdpUtils.renderTable(objective, "Proposals", this.state.proposals, columns, tooltips.fdp.fdpval.proposals, objectivesResult);
  }

  renderSlashingEvents() {
    var columns = [
      {text: "Jailed Event Time", dataField: "eventRecordedTime", formatter: SPUtilities.epochFormatter, sort: true, headerStyle: Utilities.width(25)},
      {text: "Description", dataField: "description", sort: true, headerStyle: Utilities.width(50)},
      {text: "Unjail Time", dataField: "eventReversalTime", formatter: SPUtilities.epochFormatter, sort: true, headerStyle: Utilities.width(25)},
    ];
    const options = UIUtils.getPageOptionsSmall(this, 5);

    var objectiveValue = this.state.fdp.maxSlashingFreq;
    var slashingDuration = SPCalc.formatTimeLeft(this.state.fdp.maxSlashingDuration);

    let objective = `Maximum of ${objectiveValue} slashes and max slashing duration of ${slashingDuration} in each slashing event.`;
    let objectivesResult = [{"name": "Met Freq Requirement?", "result": this.state.fdpValidator.metSlashingFreqObj}, 
                            {"name": "Met Duration Requirement?", "result": this.state.fdpValidator.metSlashingMaxDurObj}]
    return FdpUtils.renderTable(objective, "Slashing Events", this.state.events, columns, tooltips.fdp.fdpval.slashing, objectivesResult);
  }

}

export default FdpValidator;
