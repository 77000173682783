import React from 'react';

import currentNetwork from '../currentNetwork';
import SSUtils from '../util/SSUtils';
// import Bookmark from 'react-bookmark';

import "./Footer.css";
// import currentNetwork from '../currentNetwork';

class Footer extends React.Component {
  render() {
    if ( window.location !== window.parent.location ) {
      return <p>Powered by SmartStake.io</p>;
    }

    return (
      <div>
        {SSUtils.getSupportUs()}
        <div className="notification">Disclaimer: The data provided on this site is for educational purposes only. Most of the data is obtained by scraping/calculating delta's. There is no assurance that all the numbers provided on this site are 100% accurate. Please use other resources to verify.</div>
        <div className="compFooter">
          <div className="socialMedias">
            <a href="https://twitter.com/SmartStake" className="fab fa-twitter fa-lg" aria-label="Twitter group for Smart Stake"/>
            &nbsp;&nbsp;&nbsp;
            <a href="https://t.me/SmartStake" className="fab fa-telegram fa-lg" aria-label="Telegram group for Smart Stake"/>
            &nbsp;&nbsp;&nbsp;
            <a href="mailto:info@smartstake.io" title="email" className="fas fa-envelope fa-lg" aria-label="Send email to Smart Stake"/>
            &nbsp;&nbsp;&nbsp;
            <a href="https://smartstake.io/feedback" title="Feedback form" className="fas fa-comment fa-lg" aria-label="Contact Smart Stake"/>
          </div>
          <div className="poweredBySmartStake">
            Powered by &nbsp;
            <a href="https://www.SmartStake.io">
              <img width="16px" height="16px" src="/images/smartstake.png" alt="Smart Stake logo"/>
            </a>
            &nbsp;
            <a href="https://www.SmartStake.io" aria-label="Smart Stake website">Smart Stake</a>
          </div>

          <span className="versionInfo">Version: {currentNetwork.details.APP_KEY} {global.appVersion}</span>
        </div>
      </div>
    );
  }

}

export default Footer;
