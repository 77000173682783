import React from "react";
import {ResponsiveContainer, PieChart, Pie, Cell, Legend, Tooltip } from 'recharts';

import Utilities from '../util/Utilities';
import ChartUtils from '../util/ChartUtils';

import "../styles/charts.css";
import UIUtils from "../util/UIUtils";
// import currentNetwork from "../currentNetwork";
import common from "../common";


class TinyPieChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      responsive: true,
    }

    this.flipResponsive = this.flipResponsive.bind(this);
    this.renderLabel = this.renderLabel.bind(this);
  }

  flipResponsive() {
    ChartUtils.flipResponsive(this);
  }

  renderPie() {
    const CustomTooltip = ({ active, payload, label }) => {
      if (active) {
        if (payload === null || payload.length === 0) {
          return null;
        }
        let data = payload[0].payload;
        return this.props.customTooltip(data);
      }

      return null;
    };

    let endAngle = 360;
    let startAngle = 0;
    let width = UIUtils.getResponsiveWidth(this);
    let height = 300;
    let cx = "50%";
    let cy = "50%";
    let outerRadius = height / 2.5;

    if (this.props.tiny === true) {
        startAngle = 180;
        endAngle = 0;
        width = 100;
        height = 40;
        cx = "50";
        cy = "35";
        outerRadius = 30;
    }

    const COLORS = ["#00C49F", "#FF8042", "#FFBB28", "#0088FE"];
    // const COLORS = ['#0088FE', '#FFBB28', '#00C49F', '#FF8042', "#ffffff"];
    let range = this.props.range;
    if (this.props.range === null) {
      range = Utilities.getRangeWithBase0(true, this.props.data, this.props.valueAttr);
    }

    let data = Utilities.calcDataForPie(this.props.data, this.props.countAttr, this.props.labelAttr);

    if (this.props.tiny === true) {
        return (
            <ResponsiveContainer width={width} height={height}>
              <PieChart width={width} height={height}>
                <Pie data={data} startAngle={startAngle} endAngle={endAngle} outerRadius={outerRadius} cx={cx} cy={cy}
                  fill="#8884d8" dataKey="y">
                    {
                    this.props.data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                    }
                </Pie>
                {this.props.customTooltip !== null ? <Tooltip content={<CustomTooltip />} /> : <Tooltip/>} 
              </PieChart>
            </ResponsiveContainer>
        );
    } else {
        return (
            <ResponsiveContainer width={width} height={height}>
              <PieChart width={width} height={height}>
                <Pie data={data} startAngle={startAngle} endAngle={endAngle} outerRadius={outerRadius} cx={cx} cy={cy}
                  fill="#8884d8" dataKey="y" label={this.renderLabel}>
                    {
                    this.props.data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                    }
                </Pie>
                {this.props.customTooltip !== null ? <Tooltip content={<CustomTooltip />} /> : <Tooltip/>} 
                <Legend payload={data.map((item, index) => ({id: item.name, type: "square", value: `${item.label} (${item.y}%)`, color: COLORS[index % COLORS.length]}))}/>
              </PieChart>
            </ResponsiveContainer>
        );
    }
  }


  render () {
    return (
        <div align="center">
            {this.renderPie()}
        </div>
    );
  }

  renderLabel(data) {
    if (this.props.tiny === true) {
        return false;
    }

    const{cx, cy, midAngle, innerRadius, outerRadius, value, index} = data;
    const RADIAN = Math.PI / 180;
    const radius = 25 + innerRadius + (outerRadius - innerRadius);
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill={common.colors["--color-text"]} textAnchor={x > cx ? "start" : "end"} dominantBaseline="central" >
            {value}%
        </text>
    );
  }

}

export default TinyPieChart;
