import React, { Component } from 'react';

import UIUtils from "../util/UIUtils";
import "../styles/header.css";
import "./PageHeader.css";
import DelegateWithSmartStake from './DelegateWithSmartStake';
import RespUtils from '../util/RespUtils';
import Special from './Special';
// import HomeIcon from '@material-ui/icons/Home';
import constants from '../constants';

class PageHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      "updatedHome": false
    };
    this.setHome = this.setHome.bind(this);
  }

  render() {
    return (
        <div className="subHeader">
            {this.props.hideSpecial != true && (<Special />)}
            <div className="subHeader__group">
              {this.getTitle()}
              {this.getAdditionalLink()}
              {(!this.props.disableStakeFormatter || this.props.disableStakeFormatter === false)  && this.getStakeFormatter()}
            </div>
            {this.props.notification !== false && UIUtils.getNotification(this.props.thisObj.state.notification)}
            {this.state.updatedHome === true && (<div className="notification">Successfully updated home page</div>)}
        </div>
    );
  }

  getTitle() {
    let title = this.props.title;
    if (this.props.fullTitle) {
      title = this.props.fullTitle;
    } else if (RespUtils.isMobileView() && this.props.shortTitle) {
      title = this.props.shortTitle;
    } 


    if (this.props.logo !== null && this.props.record) {
      return <div className="pageTitle">{this.props.logo(this.props.record.logo, this.props.record)} {title} {
        this.props.beta && this.props.beta === true && <span className="beta">BETA</span>}{this.renderAdditionalForTitle()}</div>;
    } else if(this.props.customLogo != null) {
      return <div className="pageTitle">{this.props.customLogo()} {title} {
        this.props.beta && this.props.beta === true && <span className="beta">BETA</span>}{this.renderAdditionalForTitle()}</div>;
    }
    // if (this.props.record) {
    //   return <div className="pageTitle">{LogoUtils.projectLogo(this.props.record.logo, this.props.record)} {title} {
    //     this.props.beta && this.props.beta === true && <span className="beta">BETA</span>}{this.renderAdditionalForTitle()}</div>;
    // }

    return <div className="pageTitle">{title} {this.props.beta && this.props.beta === true && 
      <span className="beta">BETA</span>}{this.renderAdditionalForTitle()}</div>;
  }

  getAdditionalLink() {
    if (!this.props.additionalLink) {
      return "";
      // return <button onClick={this.setHome} className="animLink animLink">
      //   <HomeIcon className="mInfoIcon" style={{fill: "white"}} fontSize="large"/>
      // </button>
    }

    return <React.Fragment>
      {this.props.additionalLink()}
      {/* <button onClick={this.setHome} className="animLink animLink">
        <HomeIcon className="mInfoIcon" style={{fill: "white"}} fontSize="large"/>
      </button> */}
    </React.Fragment>
  }

  renderAdditionalForTitle() {
    if (!this.props.additionalForTitle) {
      return "";
    }

    return <React.Fragment>
      {this.props.additionalForTitle()}
    </React.Fragment>
  }

  setHome() {
    // alert(window.location.href);
    // console.log(window.location.href);
    window.localStorage.setItem(constants.HOME_KEY, window.location.pathname);
    this.setState({"updatedHome": true});
    // console.log(window.location.pathname);
  }

  getStakeFormatter() {
    if (RespUtils.isTabletView()) {
      return "";
    }

    return (
      <div className="headerActions">
        <DelegateWithSmartStake />
      </div>
    )
  }
}
// {UIUtils.renderPageActions(this, true, true)}

export default PageHeader;
