import React from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import {Container} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ReactTooltip from "react-tooltip";
import paginationFactory from 'react-bootstrap-table2-paginator';

import tooltips from "../tooltips";
import MultiLineChart from '../charts/MultiLineChart';
import '../styles/section.css';
import MainUtils from '../main/MainUtils';
import Utilities from '../util/Utilities';
import ChartTitle from '../components/ChartTitle';
import UIUtils from '../util/UIUtils';
import BidirectionChangeAndCumulativeChart from '../charts/BidirectionChangeAndCumulativeChart';
import DataUtils from '../util/DataUtils';
import currentNetwork from '../currentNetwork';
import SPUtilities from '../util/SPUtilities';
import RespUtils from '../util/RespUtils';
import ApiUtils from '../util/ApiUtils';
import common from '../common';
import BaseBarChart from '../charts/BaseBarChart';
import StakerDistributionRange from './StakerDistributionRange';
import UrlUtils from '../util/UrlUtils';
import CoinUtils from '../util/CoinUtils';

class Staking extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      networkHistory: [],
      undelegationSummary: [],
      notification: {},
      width: window.innerWidth,
      size: 10,
      responsive: true,
      isLoading: true,
      frequencyNetwork: "M",
      frequencyBlock: "H",
      undelChartMode: "pending",
      topDelegates: [],
      stakeShare: null,
      averageDelegationStats: [],
      // delegationDistributionToken: [],
      delegationDistributionUsd: [],
    }

    this.updateDimensions = this.updateDimensions.bind(this);
    this.handleChartUpdateNetwork = this.handleChartUpdateNetwork.bind(this);   
    this.handleChartUpdateBlock = this.handleChartUpdateBlock.bind(this);
    this.renderMoreUndelHistory = this.renderMoreUndelHistory.bind(this);
    this.showUndelBreakdown = this.showUndelBreakdown.bind(this);
  }

  updateDimensions() {
    this.setState({width: window.innerWidth});
  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  async componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);

    UrlUtils.setUrlFragment("staking");
    await this.prepareData(this.state.frequencyNetwork, this.state.frequencyBlock);
  }

  async prepareData(frequencyNetwork, frequencyBlock) {
    let url = "networkStats?type=staking&frequencyNetwork=" + frequencyNetwork + "&frequencyBlock=" + frequencyBlock;

    const allData = await ApiUtils.get(url);

    let undelegationSummary = DataUtils.addCumulativeData(allData["undelegationSummary"], 
        "undelegationAmount", "cumulativeUndelegationAmount");

    let averageDelegationStats = Utilities.removeEmptyRecords(allData["networkHistory"], "averageDelegation");
    this.setState({"frequencyNetwork": frequencyNetwork, "frequencyBlock": frequencyBlock,
        "notification": allData["notification"],
        "networkHistory": allData["networkHistory"], "undelegationSummary": undelegationSummary, 
        "undelegations": Utilities.addIndex(allData["undelegations"]), 
        "topDelegates": Utilities.addIndex(allData["topDelegates"]), 
        "stakeShare": allData["stakeShare"], "averageDelegationStats": averageDelegationStats,
        // "delegationDistributionToken": allData["delegationDistributionToken"], 
        "delegationDistributionUsd": allData["delegationDistributionUsd"], 
        isLoading: false});
  }

  render() {
    if (this.state.isLoading) {
      return <div>Loading</div>;
    }

    let undelChartTitle = "Pending Undelegations ($" + currentNetwork.details.app.token + ")";
    let undelChartShortTitle = "Pending Undelegations";
    if(this.state.undelChartMode == "history") {
      undelChartTitle = "Historical Undelegations ($" + currentNetwork.details.app.token + ")"
      undelChartShortTitle = "Historical Undelegations";
    }

    // let delDistTitle = "Staker Distributions in % (by " + currentNetwork.details.app.token + " amount)";
    // let delDistShortTitle = "Staker Distributions in % (by " + currentNetwork.details.app.token + ")";

    let delDistDollarTitle = "Staker Distributions in % (by $ value)";
    let delDistDollarShortTitle = "Staker Distributions in % (by $)";

    let stakingColumns = [
      {text: "Date", dataField: "title", sort: true, },
      {text: "Total Stake", dataField: "totalStake", formatter: CoinUtils.stakeFormatterRounded, sort: true, },
      {text: "Percent Staked", dataField: "percentStaked", formatter: MainUtils.percentFormatter, sort: true, },
      {text: "Unique Delegates", dataField: "uniqueDelegates", sort: true, },
    ];

    if (currentNetwork.details.ui.showApr === true) {
      stakingColumns.push({text: "Staking APR", dataField: "apr", formatter: MainUtils.percentFormatter, sort: true, });
    }

    return (
      <div>
        <ReactTooltip id="main" place="top" type="dark" effect="float" multiline={true} />
        <hr/>
        <Container fluid className="containerLayout container-fluid chartContainer">
          <div className="chartGridContainer">
            {currentNetwork.details.ui.showApr === true && <div className="chartBg" id="stakingApr">
              <MultiLineChart title="Staking APR" xAxis="Date" yAxis="Staking APR" adjustRange={true} 
                  chartVisualId="staking-apr"
                  showVerticalLabel={false} valueAttr={["apr"]} rangeAttr="apr" showTotalLabel={false} xAxisValueAttr="title"
                  data={this.state.networkHistory} tip={tooltips.networkCharts.aprHistory} 
                  handleChart={this.handleChartUpdateNetwork} formatValues={true} fsElementId="stakingApr" 
                  frequencies={["M", "Y", "All"]} frequency={this.state.frequencyNetwork} enableSave={true} 
                  dataKey="title" columns={stakingColumns} />
            </div>}
            <div className="chartBg" id="totalStake">
              <MultiLineChart title="Total Stake" xAxis="Date" yAxis="Total Stake" adjustRange={true} 
                  chartVisualId="total-stake"
                  showVerticalLabel={false} valueAttr={["totalStake"]} rangeAttr="totalStake" showTotalLabel={false} xAxisValueAttr="title"
                  data={this.state.networkHistory} tip={tooltips.networkCharts.totalStake} 
                  handleChart={this.handleChartUpdateNetwork} formatValues={true} fsElementId="totalStake" 
                  frequencies={["M", "Y", "All"]} frequency={this.state.frequencyNetwork} enableSave={true} 
                  dataKey="title" columns={stakingColumns} />
            </div>
            <div className="chartBg" id="uniqueDelegates">
              <MultiLineChart title="Unique Delegates" xAxis="Date" yAxis="Unique Delegates" adjustRange={true} 
                  chartVisualId="unique-delegates"
                  showVerticalLabel={false} valueAttr={["uniqueDelegates"]} rangeAttr="uniqueDelegates" showTotalLabel={false} xAxisValueAttr="title"
                  data={this.state.networkHistory} tip={tooltips.networkCharts.uniqueDelegates} 
                  handleChart={this.handleChartUpdateNetwork} formatValues={true} fsElementId="uniqueDelegates" 
                  frequencies={["M", "Y", "All"]} frequency={this.state.frequencyNetwork} enableSave={true} 
                  dataKey="title" columns={stakingColumns} />
            </div>
            <div className="chartBg">
              {this.renderDelegates()}
            </div>
            {currentNetwork.details.app.tendermint === true && <div className="chartBg" id="pendingUndelegationBreakdown">
              <BidirectionChangeAndCumulativeChart title={undelChartTitle} shortTitle={undelChartShortTitle}
                  chartVisualId="pending-undel-breakdown"
                  xAxis="Date" yAxis="Pending Undelegation Breakdowns" xAxisValueAttr="title" cumulativeAttr="cumulativeUndelegationAmount"
                  valueAttr="undelegationAmount" showVerticalLabel={false} 
                  showLegend={true} adjustRange={true} fsElementId="pendingUndelegationBreakdown"
                  showAdditionalLink={true} renderAdditionalLink={this.renderMoreUndelHistory}                  
                  range={[0,3]} data={this.state.undelegationSummary} tip={tooltips.networkCharts.undelegationSummary} 
                  enableSave={true} 
                  dataKey="title" columns={[
                    {text: "Date", dataField: "title", sort: true, },
                    {text: "Undelegation Amount", dataField: "undelegationAmount", formatter: CoinUtils.stakeFormatterRounded, sort: true, },
                    {text: "Cumulative Undelegation Amount", dataField: "cumulativeUndelegationAmount", formatter: CoinUtils.stakeFormatterRounded, sort: true, },
                  ]} />
            </div>}
            {currentNetwork.details.app.tendermint === true && <div className="chartBg">
              {this.renderUndelegations()}
            </div>}
            <div className="chartBg" id="percentStaked">
              <MultiLineChart title="Percent Staked" xAxis="Date" yAxis="Percent Staked" adjustRange={true} 
                  chartVisualId="percent-staked"
                  showVerticalLabel={false} valueAttr={["percentStaked"]} rangeAttr="percentStaked" showTotalLabel={false} xAxisValueAttr="title"
                  data={this.state.networkHistory} tip={tooltips.networkCharts.percentStaked} 
                  handleChart={this.handleChartUpdateNetwork} formatValues={true} fsElementId="percentStaked" 
                  frequencies={["M", "Y", "All"]} frequency={this.state.frequencyNetwork} enableSave={true} 
                  dataKey="title" columns={stakingColumns} />
            </div>
            <div className="chartBg" id="averageDelegation">
              <MultiLineChart title="Average Delegation" xAxis="Date" yAxis="Average Delegation" adjustRange={true} 
                  chartVisualId="average-delegation" enableSave={true} 
                  showVerticalLabel={false} valueAttr={["averageDelegation"]}  showTotalLabel={false} xAxisValueAttr="title" 
                  yAxisColor={common.colors.chartColor4} showLegend={true} compareAttr="averageDelegationUsd"
                  data={this.state.averageDelegationStats} tip={tooltips.networkCharts.averageDelegation} 
                  handleChart={this.handleChartUpdateNetwork} formatValues={true} fsElementId="averageDelegation" 
                  frequencies={["M", "Y", "All"]} frequency={this.state.frequencyNetwork} 
                  dataKey="title" columns={[
                    {text: "Date", dataField: "title", sort: true, },
                    {text: "Average Delegation", dataField: "averageDelegation", formatter: CoinUtils.stakeFormatterRounded, sort: true, },
                    {text: "Average Delegation USD", dataField: "averageDelegationUsd", formatter: CoinUtils.moneyFormatterUsd, sort: true, },
                  ]} />
            </div>
            <StakerDistributionRange network={currentNetwork.details.APP_KEY} token={null} enableSave={true} />
            {this.state.delegationDistributionUsd != null && (<div className="chartBg" id="delegationDistributionUsd">
              <BaseBarChart title={delDistDollarTitle} shortTitle={delDistDollarShortTitle} 
                chartVisualId="staker-dist" enableSave={true} 
                tip={tooltips.networkCharts.stakerDistributionUsd} xAxis={"Amount in $"} yAxis="Percentage Stakers" 
                showVerticalLabel={true} valueAttr="percentStakers" showTotalLabel={false} xAxisValueAttr="title"
                data={this.state.delegationDistributionUsd} 
                dataKey="title" columns={[
                  {text: "Amount in $", dataField: "title", sort: true, },
                  {text: "% of Stakers", dataField: "percentStakers", sort: true, },
                ]} />
            </div>)}
          </div>
        </Container>
      </div>
    );
  }
  
  handleChartUpdateNetwork(frequencyNetwork) {
    this.prepareData(frequencyNetwork, this.state.frequencyBlock);
  }

  handleChartUpdateBlock(frequencyBlock) {
    this.prepareData(this.state.frequencyNetwork, frequencyBlock);
  }

  renderUndelegations() {
    var columns = [
      {text: "#", hidden: RespUtils.isMobileView(), dataField: "index", sort: true, headerStyle: Utilities.respWidth(5, 4, 3)},
      {text: "Address", dataField: "address", formatter: MainUtils.addressFormatterUndel, sort: true, headerStyle: Utilities.respWidth(40, 25, 25)},
      {text: "Validator", hidden: RespUtils.isMobileView(), dataField: "name", formatter: MainUtils.nameFormatterTableWithLogo, sort: true, headerStyle: Utilities.respWidth(15, 25, 25)},
      {text: "Amount ($" + currentNetwork.details.app.token + ")", dataField: "amount", formatter: SPUtilities.formatCoins, sort: true,  sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(25, 20, 15)},
      {text: "Release Time", dataField: "releaseTimeEpoch", formatter: SPUtilities.epochFormatter, sort: true,  sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(45, 25, 25)},
    ];

    const options = UIUtils.getPageOptionsSmall(this, 5);

    return (
      <React.Fragment>
        <ChartTitle title="Top Undelegations" tip={tooltips.networkCharts.allUndelegations} />
        <BootstrapTable keyField='index' data={ this.state.undelegations }
          columns={ columns } striped options={options}
          condensed noDataIndication="No data" pagination={ paginationFactory(options) }/>
      </React.Fragment>
    )
  }

  renderDelegates() {
    var columns = [
      {text: "#", dataField: "index", sort: true, headerStyle: Utilities.respWidth(5, 4, 3)},
      {text: "Address", dataField: "address", formatter: MainUtils.addressFormatterDel, sort: true, headerStyle: Utilities.respWidth(35, 25, 25)},
      {text: "Stake ($" + currentNetwork.details.app.token + ")", dataField: "totalStake", formatter: SPUtilities.formatCoins, sort: true,  sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 15)},
    ];

    const options = UIUtils.getPageOptionsSmall(this, 5);

    return (
      <React.Fragment>
        <ChartTitle title="Top 100 Delegates" tip={tooltips.networkCharts.topDelegates} />
        <div className="sectionSummary">Total stake share by top {this.state.topDelegates.length} delegates is: <b>{this.state.stakeShare}%</b></div>
        <BootstrapTable keyField='index' data={ this.state.topDelegates }
          columns={ columns } striped options={options}
          condensed noDataIndication="No data" pagination={ paginationFactory(options) }/>
      </React.Fragment>
    )
  }

  renderMoreUndelHistory() {
    if (this.state.undelChartMode == "history") {
      return (<button key="showPending" onClick={this.showUndelBreakdown} value="pending"
        className="animLink">Pending</button>);
    }

    return (<button key="showHistory" onClick={this.showUndelBreakdown} value="history"
      className="animLink">History</button>);
  }

  async showUndelBreakdown(e) {
    let undelChartMode = e.target.value;
    let url = "listData?type=undelBreakdown&breakdownType=" + undelChartMode;
    const allData = await ApiUtils.get(url);

    if (allData) {
      let undelegationSummary = DataUtils.addCumulativeData(allData["undelegationSummary"], 
        "undelegationAmount", "cumulativeUndelegationAmount");

      this.setState({"undelegationSummary": undelegationSummary, "undelChartMode": undelChartMode});
    }
  }

}

export default Staking;
