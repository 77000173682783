import React from 'react';
import {ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Label, Legend } from 'recharts';
import * as htmlToImage from 'html-to-image';
import download from 'downloadjs';

import '../styles/section.css';

import Utilities from './Utilities';
import UIUtils from './UIUtils';
import RespUtils from './RespUtils';
import ChartTitle from '../components/ChartTitle';
import numeral from 'numeral';
import SPUtilities from './SPUtilities';

class ChartUtils extends React.Component {

  static flipResponsive(thisObj) {
    if(thisObj.state.responsive) {
      // console.log("setting chart to not responsive")
      thisObj.setState({responsive: false});
    } else {
      // console.log("setting chart to responsive")
      thisObj.setState({responsive: true});
    }
  }

  static getLandscapeMsg() {
    let specialMessage = RespUtils.getSpecialMessage();
  }

  static truncDate(val, chartType, filter) {
    if (chartType === "hour" && filter !== null) {
      return val.substring(11, 13) + ":00";
    }

    if (filter === null && val.length > 10) {
      return val.substring(0, 10);
    }

    return val;
  }

  static renderLines(thisObj, title, data, yAxisLabel, valueAttr1, valueAttr2, valueAttr3, subtitle) {
    let width = UIUtils.getResponsiveWidth(thisObj);
    let margin = UIUtils.getChartMargin(thisObj);
    // <YAxis domain={Utilities.getRange(true, data, rangeAttr)}>

    return (
      <div>
        <p className="compTitle">{title}</p>
        <p className="compSubTitle">{subtitle ? subtitle: ""}</p>
        <ResponsiveContainer width={width} height={250}>
          <LineChart data={data} margin={margin}>
            <XAxis dataKey="title" angle={-10} stroke="black">
              <Label value="Date" offset={-3} position="insideBottom" />
            </XAxis>
            <YAxis stroke="black">
              <Label value={yAxisLabel} offset={8} position="insideBottomLeft" angle={-90} />
            </YAxis>
            <Tooltip/>
            <Legend />
            <CartesianGrid vertical={false} horizontal={false} stroke="#eee" strokeDasharray="5 5"/>
            <Line type="monotone" dataKey={valueAttr1} connectNulls={true}
              label={false} strokeWidth={2} dot={false} stroke="red" />
            <Line type="monotone" dataKey={valueAttr2} connectNulls={true}
              label={false} strokeWidth={2} dot={false} stroke="green" />
            <Line type="monotone" dataKey={valueAttr3} connectNulls={true}
              label={false} strokeWidth={2} dot={false} stroke="blue" />
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }

  static render2Lines(thisObj, title, data, xAxisLabel, yAxisLabel, valueAttr1, valueAttr2, range, subtitle) {
    let width = UIUtils.getResponsiveWidth(thisObj);
    let margin = UIUtils.getChartMargin(thisObj);
    // console.log("width: ", width);
    // console.log("margin: ", margin);
    //Utilities.getRange(true, data, rangeAttr)
    return (
      <div>
        <p className="compTitle">{title}</p>
        <p className="compSubTitle">{subtitle ? subtitle: ""}</p>
        <ResponsiveContainer width={width} height={250}>
          <LineChart data={data} margin={margin}>
            <XAxis dataKey="title" angle={-10} stroke="black">
              <Label value={xAxisLabel} offset={-3} position="insideBottom" />
            </XAxis>
            <YAxis domain={range} stroke="black">
              <Label value={yAxisLabel} offset={8} position="insideBottomLeft" angle={-90} />
            </YAxis>
            <Tooltip/>
            <Legend />
            <CartesianGrid vertical={false} horizontal={false} stroke="#eee" strokeDasharray="5 5"/>
            <Line type="monotone" strokeWidth={2} dataKey={valueAttr1} connectNulls={true}
              label={false} dot={false} stroke="red" />
            <Line type="monotone" strokeWidth={2} dataKey={valueAttr2} connectNulls={true}
              label={false} dot={false} stroke="green" />
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }

/*  static renderMultiLines(thisObj, title, data, xAxisLabel, yAxisLabel, yAxisData,
    rangeAttr, subtitle, tooltip) {

    let width = UIUtils.getResponsiveWidth(thisObj);
    let height = UIUtils.getResponsiveHeight(thisObj);
    let margin = UIUtils.getChartMargin(thisObj);
    let colors = ["purple", "green", "blue", "red"];
    let index = -1;

    // <ReactTooltip id="main" place="top" type="dark" effect="float" multiline={true} />
    // <p>
    //   <b>
    //     <span align="left">{title}</span>
    //     <span className="buttonWithText"><span data-for="main" data-tip={tooltip} data-iscapture="true"><InfoIcon color="action"/></span></span>
    //   </b>
    // </p>
    return (
      <div>
        <p className="compTitle">{title}</p>
        <p className="compSubTitle">{subtitle ? subtitle: ""}</p>
        <ResponsiveContainer width={width} height={height}>
          <LineChart data={data} margin={margin}>
            <XAxis dataKey="title" angle={-10} stroke="black">
              <Label value={xAxisLabel} offset={-3} position="insideBottom" />
            </XAxis>
            <YAxis domain={Utilities.getRange(false, data, rangeAttr)} dx={6} stroke="black">
              <Label value={yAxisLabel} offset={8} position="insideBottomLeft" angle={-90} />
            </YAxis>
            <Tooltip/>
            <Legend />
            <CartesianGrid vertical={false} horizontal={false} stroke="#eee" strokeDasharray="5 5"/>
            {
              yAxisData.map((id) => {
               index++;
               return (<Line dataKey={id} strokeWidth={2} connectNulls={true}
                   label={false} dot={false} stroke={colors[index]} />)
              })
            }

          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }*/

 
  static renderMultiLines(thisObj, title, data, xAxisAttr, yAxisData, rangeAttr, 
      subtitle, tooltip, adjustRange) {

    let textColor = "white";
    let width = UIUtils.getResponsiveWidth(thisObj);
    let height = UIUtils.getChartHeight(this);
    // let height = UIUtils.getResponsiveHeight(thisObj);
    let margin = UIUtils.getChartMargin(thisObj);
    let colors = ["#faa84b", "lightblue", "lightgreen", "lightgray", "yellow", "purple", "green", "blue", "red"];
    let index = -1;

    let range;
    if (adjustRange && adjustRange === true) {
      // console.log("using original range");
      range = Utilities.getRange(false, data, rangeAttr);
    } else {
      // console.log("using new range: ", adjustRange);
      range = Utilities.getRangeWithBase0(true, data, rangeAttr);
    }

    // <p>
    //   <b>
    //     <span align="left">{title}</span>
    //     <span className="buttonWithText"><span data-for="main" data-tip={tooltip} data-iscapture="true"><InfoIcon color="action"/></span></span>
    //   </b> padding={{ top: 0, bottom: 0, }} margin={{top: 0, right: 0, left: 0, bottom: 0}}
    // </p>
    return (
      <div>
        <ChartTitle title={title} tip={tooltip} />
        <p className="compSubTitle">{subtitle ? subtitle: ""}</p>
        <ResponsiveContainer width={width} height={height}>
          <LineChart data={data}
            margin={{top: 10, right: 0, left: 5, bottom: 0}}>
            <XAxis dataKey={xAxisAttr} stroke={textColor}>
            {/* <XAxis dataKey={xAxisAttr} angle={-10} stroke={textColor}> */}
              {/* <Label value={xAxisLabel} offset={-3} position="insideBottom" style={{fill: textColor }} /> */}
            </XAxis>
            {/* dx={6}  */}
            <YAxis domain={range} stroke={textColor}>
              {/* <Label value={yAxisLabel} offset={8} position="insideBottomLeft" angle={-90} style={{fill: textColor }} /> */}
            </YAxis>
            <Tooltip labelStyle={ {color: 'black'}} />
            <CartesianGrid vertical={false} horizontal={false} stroke={textColor} strokeDasharray="5 5"/>
            {
              yAxisData.map((id) => {
               index++;
               return (<Line type="monotone" key={colors[index]} dataKey={id} strokeWidth={3} connectNulls={true}
                   label={false} dot={false} stroke={colors[index]} />)
              })
            }

          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }  

  static normalizeFloatTitleLength(data) {
    if (!data) {
      return data;
    }

    for (let i = 0, tableDataLen = data.length; i < tableDataLen; i++) {
      let value = data[i]["title"];
      if (value && !isNaN(value)) {
        if (!Number.isInteger(value)) {
          let decimalPlaces = value.toString().split('.')[1].length;
          if (decimalPlaces > 3) {
            value = numeral(parseFloat(value)).format('0.00a');
          }
        }
      }
      data[i]["title"] = value;
    }

    return data;
  }

  static downloadChart(document, elementId, title) {
    let domElement = document.getElementById("div" + elementId);
    const titleDiv = document.createElement('div');
    titleDiv.className = 'watermarkTitle'; // Apply the CSS class
    titleDiv.innerText = title;
    domElement.style.position = 'relative';
    domElement.appendChild(titleDiv);

    const watermarkDiv = document.createElement('div');
    watermarkDiv.className = 'watermarkLink'; // Apply the CSS class
    watermarkDiv.innerText = 'analytics.smartstake.io';
    domElement.style.position = 'relative';
    domElement.appendChild(watermarkDiv);

    let imageFileName = elementId + "-" + SPUtilities.getTodayAsText() + ".jpeg";
    htmlToImage.toJpeg(domElement, { cacheBust: true, })
      .then(function (dataUrl) {
        // console.log(dataUrl);
        download(dataUrl, imageFileName);
        titleDiv.innerText = "";
        watermarkDiv.innerText = "";
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });

  }
}

export default ChartUtils;
