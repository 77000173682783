import React from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import ReactTooltip from "react-tooltip";
import paginationFactory from 'react-bootstrap-table2-paginator';

import ApiUtils from '../util/ApiUtils';
import tooltips from "../tooltips";
import '../styles/section.css';
import Utilities from '../util/Utilities';
import ChartTitle from '../components/ChartTitle';
import currentNetwork from '../currentNetwork';
import PageHeader from '../components/PageHeader';
import SessionUtils from '../util/SessionUtils';
import FdpInline from './FdpInline';
import FdpUtils from './FdpUtils';
import RespUtils from '../util/RespUtils';
import SPUtilities from '../util/SPUtilities';

class FdpList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      accessKey: null,
      coinStat: {},
      notification: null,
      fdps: [],      
      width: window.innerWidth,
      responsive: true,
      isLoading: true,
    }

    // this.showVPCurveChart = this.showVPCurveChart(this);
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  updateDimensions() {
    this.setState({width: window.innerWidth});
  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  async componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    SessionUtils.setNetwork(this);

    let url = "listData?type=fdps" + FdpUtils.setAccessKeyAndGetParam(this);

    const allData = await ApiUtils.get(url);
    if (allData) {
      this.setState({"fdps": allData["fdps"], "notification": allData["notification"], isLoading: false});
    }
  }

  render() {
    if (this.state.isLoading) {
      return <div>Loading</div>;
    }

    return (
      <div>
        <PageHeader title={currentNetwork.details.app.appName + " - Foundation Delegation Program Analytics"} 
          shortTitle={currentNetwork.details.app.appName + " - FDP Analytics"} thisObj={this} notification={this.state.notification}/>
        <ReactTooltip id="main" place="top" type="dark" effect="float" multiline={true} />
        <hr/>
        {this.renderFdps()}
      </div>
    );
  }
  
  renderFdps() {
    const options = {onlyOneExpanding: true};

    const expandRow = {
      renderer: row => (
        <FdpInline fdp={row} />
      ), showExpandColumn: false
    };
    
    var columns = [
      {text: "#", dataField: "fdpNumber", sort: true, formatter: FdpUtils.fdpFormatter, formatExtraData: this, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(7, 6, 5)},
      {text: "FDP Source", hidden: RespUtils.isMobileView(), dataField: "source", sort: true, headerStyle: Utilities.respWidth(20, 15, 8)},
      {text: "Label", dataField: "periodLabel", sort: true, formatter: FdpUtils.fdpFormatter, formatExtraData: this, headerStyle: Utilities.respWidth(30, 15, 10)},
      {text: "Start Date", dataField: "periodStart", formatter: SPUtilities.formatDate, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 10)},
      {text: "End Date", hidden: RespUtils.isMobileView(), dataField: "periodEnd", formatter: SPUtilities.formatDate, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 10)},
      {text: "Validators Meeting Requirements", hidden: RespUtils.isMobileView(), dataField: "valMetObjective", sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(25, 20, 15)},
      {text: Utilities.respLabel("Vals Not Meeting Reqmt", "Vals Not Meeting Reqmt", "Validators Not Meeting Requirements"), dataField: "valFailedObjective", sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(25, 20, 15)},
      {text: "Status", hidden: RespUtils.isMobileView(), dataField: "status", sort: true, headerStyle: Utilities.respWidth(20, 15, 10)},
    ];

    const defaultSorted = [{
      dataField: 'title',
      order: 'desc'
    }];

    return (
      <React.Fragment>
        <ChartTitle title="Assessment Windows" tip={tooltips.fdp.assessmentWindows} />
        <p/>
        <ReactTooltip id="main" place="top" type="dark" effect="float" multiline={true} />
        <BootstrapTable keyField="fdpId" data={ this.state.fdps }
          columns={columns} striped options={options} defaultSorted={defaultSorted}
          expandRow={ expandRow } expandableRow={ () => { return true; } } 
          condensed noDataIndication="No data" pagination={ paginationFactory(options) } />
      </React.Fragment>
    );
  }
}

export default FdpList;
