import React from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import ReactTooltip from "react-tooltip";
import paginationFactory from 'react-bootstrap-table2-paginator';

import ApiUtils from '../util/ApiUtils';
import '../styles/section.css';
import Utilities from '../util/Utilities';
import UIUtils from '../util/UIUtils';
import PageHeader from '../components/PageHeader';
import RespUtils from '../util/RespUtils';
import currentNetwork from '../currentNetwork';
import ValColumnSelector from '../validators/ValColumnSelector';
import FavUtils from '../util/FavUtils';
import ProposalUtils from './ProposalUtils';
import CoinUtils from '../util/CoinUtils';
import SPUtilities from '../util/SPUtilities';
import SessionUtils from '../util/SessionUtils';
import constants from '../constants';
import LogoUtils from '../util/LogoUtils';
import ColumnUtils from '../util/ColumnUtils';
// import NetworkNav from './NetworkNav';

class Proposals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        proposals: [],
        statusMap: {},
        proposalTypeMap: {},
        notification: {},
        width: window.innerWidth,
        size: 10,
        responsive: true,
        isLoading: true,
    }
    this.updateDimensions = this.updateDimensions.bind(this);
    this.repaintData = this.repaintData.bind(this);
  }

  updateDimensions() {
    this.setState({width: window.innerWidth});
  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  async componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    SessionUtils.setNetwork(this);

    let url = "proposals"
    // console.log(url);

    const allData = await ApiUtils.get(url);
    // console.log(allData);
    if (!allData) {
      this.setState({error: true, isLoading: false});
      return;
    }

    let proposals = allData["proposals"];
    if (this.props.match && this.props.match.params.showDeposit !== 'true') {
      let finalData = [];
      for(let i=0; i < proposals.length; i++) {
        let proposal = proposals[i]
        if (proposal["status"] !== "PROPOSAL_STATUS_DEPOSIT_PERIOD") {
          finalData.push(proposal);
        }
      }

      proposals = finalData;
    }

    this.setState({isLoading: false, "proposals": proposals, "statusMap": allData["statusMap"],
      "notification": allData["notification"], "proposalTypeMap": allData["proposalType"]});
  }

  repaintData() {
    let proposals = this.state.proposals;
    let newProposals = UIUtils.addIndexAndFav(proposals, "proposalId");
    let finalData = FavUtils.filterData(this, newProposals);
    this.setState({proposals: finalData});
  }

  render() {
    let loadMsg = UIUtils.getLoading(this);
    if (loadMsg) return loadMsg;

    const headerSortingClasses = (column, sortOrder, isLastSorting, colIndex) => (
      sortOrder === 'asc' ? 'sorting-asc' : 'sorting-desc'
    );
    
    var columns = [
        {text: "#", desc: "Proposal ID", hidden: this.isHidden("proposalId"), dataField: "proposalId", formatter: ProposalUtils.renderIndex, sort: true, headerStyle: Utilities.respWidth(10, 7, 3), headerSortingClasses},
        {text: "Title", desc: "Proposal Title", hidden: this.isHidden("title"), dataField: "title", formatter: ProposalUtils.renderProposal, sort: true, headerStyle: Utilities.respWidth(50, 40, 30), headerSortingClasses},
        {text: "Type", desc: "Proposal Type", hidden: this.isHidden("proposalType"), dataField: "proposalType", formatter: ProposalUtils.typeFormatterTable, formatExtraData: this, sort: true, headerStyle: Utilities.respWidth(20, 15, 13), headerSortingClasses},
        {text: "Status", desc: "Proposal Status", hidden: this.isHidden("status"), dataField: "status", formatter: ProposalUtils.statusFormatterTable, formatExtraData: this, sort: true, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
        {text: "Vote Count", desc: "Total Vote Count", hidden: this.isHidden("totalVoteCount"), dataField: "totalVoteCount", sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(9), headerSortingClasses},
        {text: "Validators Voted", desc: "# of validators that voted", hidden: this.isHidden("validatorsVoted"), dataField: "validatorsVoted", sort: true, headerStyle: Utilities.width(9), headerSortingClasses},
        {text: "Vote %", desc: "Proposal Vote Percentage", hidden: this.isHidden("percentVotes"), dataField: "percentVotes", formatter: ProposalUtils.percentVotesFormatter, sort: true, headerStyle: Utilities.width(9), headerSortingClasses},
        {text: "Yes %", desc: "Yes Vote Percent", hidden: this.isHidden("percentTallyYes"), dataField: "tallyYes", formatter: ProposalUtils.tallyFormatter, sort: true, headerStyle: Utilities.width(10), headerSortingClasses},
        {text: "Yes Votes", desc: "Yes Votes", hidden: this.isHidden("tallyYes"), dataField: "tallyYes", formatter: SPUtilities.formatCoins, sort: true, headerStyle: Utilities.width(10), headerSortingClasses},
        {text: "Abstain %", desc: "Abstain vote percent", hidden: this.isHidden("percentTallyAbstain"), dataField: "tallyAbstain", formatter: ProposalUtils.tallyFormatter, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(10), headerSortingClasses},
        {text: "Abstain Votes", desc: "Abstain vote", hidden: this.isHidden("tallyAbstain"), dataField: "tallyAbstain", formatter: SPUtilities.formatCoins, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(10), headerSortingClasses},
        {text: "No %", desc: "No vote percent", hidden: this.isHidden("percentTallyNo"), dataField: "tallyNo", formatter: ProposalUtils.tallyFormatter, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(10), headerSortingClasses},
        {text: "No Votes", desc: "No vote", hidden: this.isHidden("tallyNo"), dataField: "tallyNo", formatter: SPUtilities.formatCoins, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(10), headerSortingClasses},
        {text: "No With Veto %", desc: "No with veto vote percent", hidden: this.isHidden("percentTallyNoWithVeto"), dataField: "tallyNoWithVeto", formatter: ProposalUtils.tallyFormatter, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(10), headerSortingClasses},
        {text: "No With Veto Votes", desc: "No with veto vote", hidden: this.isHidden("tallyNoWithVeto"), dataField: "tallyNoWithVeto", formatter: SPUtilities.formatCoins, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(10), headerSortingClasses},
        {text: "Vote Distribution", desc: "Vote Distribution", hidden: this.isHidden("voteDistribution"), dataField: "voteDistribution", formatter: ProposalUtils.formatVoteProgress, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(10), headerSortingClasses},
        {text: "Submit Time", desc: "Time of proposal submission", hidden: this.isHidden("submitTime"), dataField: "submitTime", sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(10), headerSortingClasses},
        {text: "Deposit End Time", desc: "deadline for deposit", hidden: this.isHidden("depositEndTime"), dataField: "depositEndTime", sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(10), headerSortingClasses},
        {text: "Voting Start Time", desc: "Time when voting started", hidden: this.isHidden("votingStartTime"), dataField: "votingStartTime", sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(10), headerSortingClasses},
        {text: "Voting End Time", desc: "Time when voting ends/ended", hidden: this.isHidden("votingEndTime"), dataField: "votingEndTime", sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(10), headerSortingClasses},
        {text: "Time Left", desc: "Time Left before voting ends", hidden: this.isHidden("votingEndTimeLeft"), dataField: "votingEndTimeLeft", formatter: ProposalUtils.timeLeft, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(10), headerSortingClasses},
        {text: "Total Deposit Amount", desc: "Total Deposit Amount", hidden: this.isHidden("totalDeposit"), dataField: "totalDeposit", sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(10), headerSortingClasses},
        {text: "Total Staked Amount", desc: "Total Staked Amount", hidden: this.isHidden("totalStaked"), dataField: "totalStaked", sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(10), headerSortingClasses},
        {text: "Total Votes Received", desc: "Total Votes Received", hidden: this.isHidden("totalVotes"), dataField: "totalVotes", formatter: CoinUtils.stakeFormatterRounded, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(10), headerSortingClasses},
    ];

    const options = UIUtils.getPageOptionsSmall(this, 10);
    const defaultSorted = [{
      dataField: 'proposalId',
      order: 'desc' // desc or asc
    }];

    return (
      <div style={{"width": "99%"}}>
        <PageHeader title={currentNetwork.details.app.appName + " Proposals"} thisObj={this} showFavIcon={false} beta={false} customLogo={LogoUtils.getChainLogo} />
        <ReactTooltip id="main" place="top" type="dark" effect="float" multiline={true} />
        <p/>
        {currentNetwork.details.proposal.showDeposits === true && this.renderShowDeposit()}
        <ToolkitProvider keyField="proposalId" data={this.state.proposals} columns={columns} columnToggle>
          {
            props => (
              <div>
                <ValColumnSelector settingKey={constants.KEY_PROPOSALS} getInitColumns={this.getInitColumns} showHelp={this.showHelp}
                  { ...props.columnToggleProps } refreshParent={this.repaintData} />
                <BootstrapTable options={options} { ...props.baseProps }
                  columns={columns} striped defaultSorted={defaultSorted}
                  condensed noDataIndication="No proposals" pagination={ paginationFactory(options) } />
              </div>
            )
          }
        </ToolkitProvider>
      </div>
    );
  }

  renderShowDeposit() {
    if (this.props.match && this.props.match.params.showDeposit === 'true') {
      return (<a href={SessionUtils.getUrl("/proposals/false")}>Hide Proposals in Deposit</a>);
    }

    return (<a href={SessionUtils.getUrl("/proposals/true")}>Show Proposals In Deposit</a>);
  }

  // statusFormatter(cell, row) {
  //   return this.state.statusMap[cell];
  // }

  // typeFormatter(cell, row) {
  //   return this.state.proposalType[cell];
  // }

  // percentVotesFormatter(cell, row) {
  //   if (!cell) {
  //     return "";
  //   }

  //   return CoinUtils.percentFormatter(cell);
  // }

  // timeLeft(cell, row) {
  //   if (!cell) {
  //     return "";
  //   }
    
  //   if (row["status"] !== "PROPOSAL_STATUS_VOTING_PERIOD") {
  //     return "";
  //   }

  //   return SPCalc.formatTimeLeft(cell, row);
  // }

  // tallyFormatter(cell, row) {
  //   // console.log(cell, " - total votes: ", row.totalVotes);
  //   if (!cell || !row.totalVotes) {
  //     return "";
  //   }

  //   return CoinUtils.percentFormatter(cell * 100/row.totalVotes);
  // }

  getInitColumns() {
    let columnsConfig = {};
    if (RespUtils.isTabletView()) {
      columnsConfig = {
        proposalId: false, title: false, proposalType: true, 
        status: false, percentVotes: true, percentTallyYes: true, 
        percentTallyAbstain: true, percentTallyNo: true, percentTallyNoWithVeto: true, 
        tallyYes: true, tallyAbstain: true, tallyNo: true, tallyNoWithVeto: true, 
        validatorsVoted: true, submitTime: true, depositEndTime: true, 
        votingStartTime: true, votingEndTime: true, votingEndTimeLeft: true, 
        totalDeposit: true, totalStaked: true, totalVotes: true, 
        voteDistribution: true, totalVoteCount: true,
      };
    } else {
      columnsConfig = {
        proposalId: false, title: false, proposalType: false, 
        status: false, percentVotes: false, percentTallyYes: false, 
        percentTallyAbstain: true, percentTallyNo: true, percentTallyNoWithVeto: true, 
        tallyYes: true, tallyAbstain: true, tallyNo: true, tallyNoWithVeto: true, 
        validatorsVoted: false, submitTime: true, depositEndTime: true, 
        votingStartTime: true, votingEndTime: true, votingEndTimeLeft: false, 
        totalDeposit: true, totalStaked: true, totalVotes: true, 
        voteDistribution: false, totalVoteCount: false,
      };
    }

    return columnsConfig;
  }

  isHidden(dataField) {
    return ColumnUtils.isHidden(constants.KEY_PROPOSALS, dataField, this.getInitColumns);
  }
}

export default Proposals;
