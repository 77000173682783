import React from 'react';
import TuneIcon from '@material-ui/icons/Tune';
import HelpIcon from '@material-ui/icons/Help';
import SearchIcon from '@material-ui/icons/Search';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';

import '../styles/section.css';
import '../styles/tables.css';
import RespUtils from '../util/RespUtils';
import currentNetwork from '../currentNetwork';
import SessionUtils from '../util/SessionUtils';
import LogoUtils from '../util/LogoUtils';
import MainUtils from '../main/MainUtils';
import ValidatorUtils from './ValidatorUtils';
import DownloadUtils from '../util/DownloadUtils';
import Views from '../base/views/Views';

import SearchModal from './SearchModal';

class StatusTagList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullScreenMode: false,
      showModal: false,
    };
    this.handleFullScreen = this.handleFullScreen.bind(this);
    this.handleViewChange = this.handleViewChange.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  // toggleSearchModal() {
  //   this.setState(prevState => ({ isSearchModalOpen: !prevState.isSearchModalOpen }));
  // }

  toggleModal() {
    this.setState(prevState => ({
      showModal: !prevState.showModal
    }));
  }

  refreshData = () => {
  };

  render() {
    return (
      <div className="tablesBg">
        <div className="sectionHeaderMini">
          <div className="sectionTitleMini">
            <span>{this.renderStatusTags()} </span>
          </div>

          {this.renderFullScreenLink()}
        </div>
        {/* <SearchModal isOpen={this.state.showModal} onClose={this.toggleModal} /> */}
      </div>
    );
  }

  renderStatusTags() {
    if (this.props.renderFilters) {
      return this.props.renderFilters();
    }
    
    if (!this.props.statusSummary || currentNetwork.details.app.tendermint != true) {
      return "";
    }

    // console.log(this.props.thisObj.props.match.params.status);
    // console.log("in renderStatusTags");
    if (RespUtils.isTabletView()) {
      return (<div><table><tbody><tr>
          <td className="view-tag"><a className="animLink" href={SessionUtils.getUrlForAudit("/validators/All")}>{this.formatView("All ", (this.props.statusSummary.Total), "All")}</a> </td>
          <td className="view-tag"><a className="animLink" href={SessionUtils.getUrl("/validators/Bonded")}>{this.formatView("Bonded ", this.props.statusSummary.Bonded, "Bonded")}</a> </td>
        </tr></tbody></table></div>);
    } else {
      return (<div><table><tbody><tr>
          <td className="view-tag"><a className="animLink" href={SessionUtils.getUrlForAudit("/validators/All")}>{this.formatView("All ", (this.props.statusSummary.Total), "All")}</a> </td>
          <td className="view-tag"><a className="animLink" href={SessionUtils.getUrl("/validators/Bonded")}>{this.formatView("Bonded ", this.props.statusSummary.Bonded, "Bonded")}</a> </td>
          {currentNetwork.details.app.consumerChain === true && 
            <td className="view-tag"><a className="animLink" href={SessionUtils.getUrl("/validators/hasToValidate")}>{this.formatView("Has To Validate ", this.props.statusSummary.hasToValidate, "hasToValidate")}</a> </td>
          }
          <td className="view-tag"><a className="animLink" href={SessionUtils.getUrl("/validators/Dec")}>{this.formatViewWithLabel("Decentralize ", "Dec")}</a> </td>
          <td className="view-tag"><a className="animLink" href={SessionUtils.getUrl("/validators/Perf")}>{this.formatViewWithLabel("> Avg Sign ", "Perf")}</a> </td>
          {currentNetwork.details.app.tendermint && (<td className="view-tag"><a className="animLink" href={SessionUtils.getUrl("/validators/LowMaxRate")}>{this.formatView("Low Comm Params ", this.props.statusSummary.LowMaxRate, "LowMaxRate")}</a> </td>)}
          {/* <td className="view-tag"><a className="animLink" href={SessionUtils.getUrl("/validators/CommIncrease")}>{this.formatView("Comm Increased ", this.props.statusSummary.CommIncrease, "CommIncrease")}</a> </td>
          {currentNetwork.details.app.enableIsNew === true && (<td className="view-tag"><a className="animLink" href={SessionUtils.getUrl("/validators/New")}>{this.formatView("New ", this.props.statusSummary.New, "New")}</a> </td>)}
          <td className="view-tag"><a className="animLink" href={SessionUtils.getUrl("/validators/Jailed")}>{this.formatView("Jailed ", this.props.statusSummary.Jailed, "Jailed")}</a> </td> */}
          {currentNetwork.details.ui.badges.showBadges === true && currentNetwork.details.ui.badges.relayer === true && <td 
            className="view-tag"><a className="animLink" href={SessionUtils.getUrl("/validators/ibc")} title="Validators that run IBC relayers">
              <CompareArrowsIcon className="mInfoIcon" style={{fill: "white"}} fontSize="large"/>
            </a></td>}
          {currentNetwork.details.ui.badges.showBadges === true && currentNetwork.details.ui.badges.restake === true && 
            <td className="view-tag"><a className="animLink" href={SessionUtils.getUrl("/validators/restake")} title="Validators that run Restake App">
              {MainUtils.formatViewIcon("/images/restake.png", "valBadgeBorder")}
            </a></td>}
          {ValidatorUtils.renderBadge(currentNetwork.details.ui.badges.badge1Ind, "/validators/" + currentNetwork.details.ui.badges.badge1Key, 
            "Click to filter validator list for this provider", currentNetwork.details.ui.badges.badge1IndImg)}
          {ValidatorUtils.renderBadge(currentNetwork.details.ui.badges.badge2Ind, "/validators/" + currentNetwork.details.ui.badges.badge2Key, 
            "Click to filter validator list for this provider", currentNetwork.details.ui.badges.badge2IndImg)}
          {ValidatorUtils.renderBadge(currentNetwork.details.ui.badges.badge3Ind, "/validators/" + currentNetwork.details.ui.badges.badge3Key, 
            "Click to filter validator list for this provider", currentNetwork.details.ui.badges.badge3IndImg)}
          {ValidatorUtils.renderBadge(currentNetwork.details.ui.badges.badge4Ind, "/validators/" + currentNetwork.details.ui.badges.badge4Key, 
            "Click to filter validator list for this provider", currentNetwork.details.ui.badges.badge4IndImg)}
          {ValidatorUtils.renderBadge(currentNetwork.details.ui.badges.badge5Ind, "/validators/" + currentNetwork.details.ui.badges.badge5Key, 
            "Click to filter validator list for this provider", currentNetwork.details.ui.badges.badge5IndImg)}
          {currentNetwork.details.ui.badges.fdpInd === true && 
            <td className="view-tag"><a className="animLink" href={SessionUtils.getUrl("/validators/fdp")} 
              title="Validators receiving foundation delegation"> {LogoUtils.defaultChainLogo()}
            </a></td>}
        </tr></tbody></table></div>);
    }
  }

  formatView(label, count, status) {
    if (count !== null) {
      label = label + count;
    } else {
      label = label + "0";
    }

    return this.formatViewWithLabel(label, status)
  }

  formatViewWithLabel(label, status) {
    if (this.props.status === status) {
      // console.log("status matched: ", this.props.match.params.status, status);
      return (<span className="view-tag-selected">{label}</span>);
      // return <b><u>{label}</u></b>
    }

    return label;
    // console.log(label, "-", status);
    // if (this.props.status) {
    //   // console.log("checking params: ", this.props.match.params.status, ", expected: ", status);
    //   if (this.props.status === status) {
    //     // console.log("status matched: ", this.props.match.params.status, status);
    //     return (<span className="view-tag-selected">{label}</span>);
    //     // return <b><u>{label}</u></b>
    //   } else {
    //     return label;
    //   }
    // // } else if (status === currentNetwork.details.app.defaultValidatorViewStatus) {
    // //   console.log("returning from default status");
    // //   return (<span className="view-tag-selected">{label}</span>);
    // //   // return <b><u>{label}</u></b>
    // } else if (status !== null) {
    //   console.log("returning from status not null");
    //   return label;
    // }

    // console.log("returning from end");
    // // return <b><u>{label}</u></b>
    // return {label};
  }

  handleFullScreen() {
    RespUtils.handleFullScreen(this);
  }

  renderFullScreenLink() {
    if (!this.state.fullScreenMode) {
      return <React.Fragment>
        {this.props.views != null && <Views values={this.props.views} handleViewChange={this.handleViewChange} defaultView={this.props.defaultView} />}
        {this.props.supportMoreView === true && this.renderMoreView()}
        {this.props.handleFilter && <button onClick={this.toggleModal} className="animLinkStatusAction animLink" aria-label="filter validators"><SearchIcon /></button>}
        <button onClick={this.props.showColumnSettings} className="tourColumnSettings animLinkStatusAction animLink" aria-label="change view"><TuneIcon /></button>
        {RespUtils.isNotTabletView() && this.props.downloadData && DownloadUtils.getDownloadIconWithTableStyle(this.props.downloadData, this.props.title)} 
        {RespUtils.isNotTabletView() && this.props.showHelp && <button onClick={this.props.showHelp} className="tourHelp animLinkStatusAction animLink" aria-label="show help"><HelpIcon /></button>}
        {RespUtils.renderFullScreenLink(this)}
        <SearchModal
            isOpen={this.state.showModal}
            onClose={this.toggleModal}
            onFilter={this.props.handleFilter}
            refreshParent={this.refreshData}
          />
      </React.Fragment>
    }
    return RespUtils.renderFullScreenLink(this);
  }

  handleViewChange(newView) {
    // console.log("in st list: new view: ");
    // console.log(newView);
    this.props.handleViewChange(newView);
  }

  renderMoreView() {
    let currentMoreView = this.props.moreView;
    return (<button type="button" key="moreColView" className="animLinkStatusAction animLink" 
                data-toggle="button" onClick={this.props.toggleMoreView}>{ 
                  currentMoreView === "full" ? <VisibilityIcon /> : 
                  currentMoreView === "partial" ? <span className="mediaCustom fas fa-low-vision fa-md" /> : <VisibilityOffIcon /> }
      </button>);
  }
}

export default StatusTagList;
