import React from 'react';
import {Collapse} from 'react-collapse';
import currentNetwork from '../currentNetwork';
import MaticValidatorUtils from '../matic/MaticValidatorUtils';
import ChainUtils from '../util/ChainUtils';
import common from '../common';

class MainNotes extends React.Component {

  static getBlsNote() {
    return (<Collapse isOpened={true}>
        <ul>
          <li></li>
        </ul>
      </Collapse>);
  }

  static getPoolNote() {
    if (ChainUtils.isMatic()) return MaticValidatorUtils.getPoolNote();

    return (<Collapse isOpened={true}>
        <ul>
          <li>&gt; Avg Sign filter - The filter shows all bonded/active validators with higher than average block signing rate in last 30 days.</li>
          <li>Jailed* - "number of times jailed" data is not available from beginning of chain and is available based on whatever data could be captured. Like rest of the data, this data is captured by scraping and there is always a possibility of some bugs. Please verify from other sources as appropriate.</li>
          <li>The current signing data is based on last 8 hours of blocks produced in network.</li>
          <li>Number of blocks signed per hour may have ups/downs because a) the sync up job runs every 5 minutes & captures all blocks since last sync up b) block times can vary.</li>
          <li>Block signing color codes: Red - Validator is signing less than 90% blocks in last 8 hours. Orange - Validator is signing between 90 and 98% blocks. Green - validator is signing 98%+ blocks in last 8 hours.</li>
          {currentNetwork.details.ui.badges.showBadges === true && <li>IBC Relayer - If you know of a validator that runs an IBC relayer but is not reflected as such, please check <a href="https://relayers.smartstake.io/">Relayers Analytics</a> for submission details.</li>}
        </ul>
      </Collapse>);
  }

  static getNetworksNote() {
    return (<Collapse isOpened={true}>
        <ul><u>Network Metrics</u> (not directly related to decentralization):
          <li>Staking APR - is a just-in-time stat and does not consider future change in staking returns based on higher/lower total stake, or change in network parameters, or reflect token price changes. It is based on inflation, block rate, 0% commission, and does not reflect transaction fees. There may be some inaccuracies depending upon the specifics of a chain.</li>
          <li>Net new addresses is a delta change in total addresses that hold &gt; 0 tokens</li>
          <li>Percent Staked - Percentage of the supply that is locked in staking. Some of the networks have non-circulating supply locked in staking. To keep the metric simple across all chains, the metric uses Total Supply in the calculation.</li>
          <li>Median Commission - Median value of the commission charged by all validators on the network.</li>
          <li>Weighted Median Commission - Weighted median of the commission charged by all validators.</li>
          <li>Unique Delegates - Total number of unique delegates/stakers.</li>
        </ul>
        <ul><u>Decentralization Metrics:</u>
          <li>Gini Coefficient - Gini coefficient is a metric used to describe the level of inequality of stake between all validators. <a href={common.urls.giniExplanation} target="_blank">Visit this article for more on Gini</a>.</li>
          <li>Nakamoto Coefficient - Nakamoto coefficient is the number of top validators, holding together 34% of staked coins. Measures the prevalence of top validators comparing to the whole. <a href={common.urls.nakamotoIndex} target="_blank">Visit this article for more on Nakamoto</a>.</li>
          <li>Validators with majority power - The smallest number of validators that together control 67% of the voting power. Higher number is better from decentralization perspective.</li>
          <li>Validators below Average Voting Power - Numbers of validators below average voting power. Lower number (in comparison to total number of validators) is better. Higher number is better from decentralization perspective.</li>
          <li>Average Voting Power - Average of the voting power of each validator on the network</li>
          <li>Number of Validators - Total number of validators that are participating in the consensus in each chain. It does not include validators that are not considered elected/bonded.</li>
        </ul>
      </Collapse>);
  }

}
export default MainNotes;
