import React from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import RespUtils from './RespUtils';
import SPUtilities from './SPUtilities';
import SessionUtils from './SessionUtils';
import IntegrationUtils from './IntegrationUtils';
import DataUtils from './DataUtils';

class AddressUtils extends React.Component {
  static addressFormatterSimpleByLabel(cell, row) {
    return AddressUtils.addressFormatterLabelShortInd(cell, row, false, true);
  }

  static addressFormatterByLabel(cell, row) {
    return AddressUtils.addressFormatterLabelShortInd(cell, row, false, false);
  }

  static addressFormatterByLabelShort(cell, row) {
    return AddressUtils.addressFormatterLabelShortInd(cell, row, true, false);
  }

  static addressFormatterLabelShortInd(cell, row, short, noLink) {
    if (!cell) {
      return "";
    }

    let shortInd = short;
    if (!shortInd) {
      shortInd = (RespUtils.isNotTabletView() ? false : true);
    }

    let label = row.alias;
    if (!row.alias) {
      label = SPUtilities.addressLabelShortFormatter(cell, null, shortInd);
    }

    if (noLink === true) {
      return (<span>{label}{AddressUtils.copyLink(row.address)}</span>);

    }

    let link = SessionUtils.getUrl("/address/");

    return (<span><a className="animLink" href={link + row.address}>{label}</a>{AddressUtils.copyLink(row.address)}</span>);
  }

  static addressLabelShortFormatter(value, row, short) {
    if (!value) {
      return value;
    }

    let label = value;
    if (short || (RespUtils.isOldPCView() && value.length > 10)) {
      if (RespUtils.isMobileView()) {
        label = value.substring(0,6) + "..";
      } else {
        label = value.substring(0,10) + "..";
      }
    }

    return label;
  }

  static addressFormatterLong(cell, row, index, formatExtraData) {
    return AddressUtils.addressFormatterByType(cell, row.alias, false, true);
  }

  static addressFormatter(cell, row, index, formatExtraData) {
    return AddressUtils.addressFormatterByType(cell, row.alias, true, true);
  }

  static addressFormatterDel(cell, row, index, formatExtraData) {
    return AddressUtils.addressFormatterSimpleWithLink(cell);
    // addressFormatterByType(cell, row.alias, false, false)
  }

  static addressFormatterByType(address, alias, short, validator) {
    if (!address) {
      return "";
    }

    let label = SPUtilities.addressLabelShortFormatter(address, null, short);

    return (<span>{label}{AddressUtils.copyLink(address)}</span>);
  }

  static copyLink(address) {
    // let widthNHeight = 16;
    // if (window.innerWidth < 600) {
    //   widthNHeight = 16;
    // }

    let imgSrc = "/images/copy.png";
    let title = "Copy address to clipboard"

    return (<CopyToClipboard text={address}
        onCopy={() => {
          // formatExtraData.setState({copied: row.address});
          // console.log(row.address);
          // return false;
        }}>
        <a href="javascript:return false;" className="mediaCustom far fa-copy fa-lg"/>
      </CopyToClipboard>)
  }

  static addressFormatterSimple(address) {
    if (!address) {
      return "";
    }

    let label = address;

    if (RespUtils.isTabletView()) {
      label = address.substring(0,10) + "..";
    }

    return (<span>{label}{AddressUtils.copyLink(address)}</span>);
  }

  static addressFormatterSimpleWithLink(address) {
    if (!address) {
      return "";
    }

    let label = address;

    if (RespUtils.isTabletView()) {
      label = address.substring(0,10) + "..";
    }

    return (<span><a href={SessionUtils.getUrl("/address/" + address)} class="animLink">{label}</a>&nbsp;{AddressUtils.copyLink(address)}</span>);
  }

  static addressFormatterSimpleNoCopy(address) {
    if (!address) {
      return "";
    }

    let label = address;

    if (RespUtils.isTabletView()) {
      label = address.substring(0,10) + "..";
    }

    return label;
  }

  static getAliasOrAddress(address) {
    // console.log("input is:", address);
    if (!address) {
        return "";
    }

    if(address.alias) {
      return address.alias;
    }

    return AddressUtils.addressFormatterSimple(address.address);
  }

  static addressFormatterLabelLinkToExplorer(cell, row) {
    if (!cell) {
      return "";
    }

    let shortInd = (RespUtils.isNotTabletView() ? false : true);

    let label = row.alias;
    if (!row.alias) {
      label = SPUtilities.addressLabelShortFormatter(cell, null, shortInd);
    }

    let link = IntegrationUtils.getAddressLink();

    return (<span><a className="animLink"
      href={link + row.address}>{label}</a>{AddressUtils.copyLink(row.address)}</span>);
  }

  static addressFormatterLabelLinkToExplorerWithHistory(cell, row, index, token) {
    if (!cell) {
      return "";
    }

    let shortInd = (RespUtils.isNotTabletView() ? false : true);

    let label = row.alias;
    if (!row.alias) {
      label = SPUtilities.addressLabelShortFormatter(cell, null, shortInd);
    } else if (label.indexOf(" ") == -1) {
      label = DataUtils.limitLength(label, 15);
    }

    let link = IntegrationUtils.getAddressLink();

    if (link) {
      return (<span><a className="animLink"
        href={link + cell}>{label}</a>{AddressUtils.copyLink(cell)} {AddressUtils.getTokenHistoryLink(row, token)}</span>);
    }
    return (<span>{label}{AddressUtils.copyLink(cell)} {AddressUtils.getTokenHistoryLink(row, token)}</span>);

  }

  static getTokenHistoryLink(row, token) {
    if (token && row.trackHistory === "True") {
      return (<button className="animLinkMenu animLink"><a href={SessionUtils.getUrl("/tokens/" + token + "/" + row.address + "/history")} 
        title="history" className="mediaCustom fas fa-history fa-lg" /></button>);
    }

    return "";
  }

  static ethAddressFormatter(cell, row, index, shortInd) {
    if (!cell) {
      return "";
    }

    let label = cell;
    let copyLink = true;
    if (shortInd && shortInd === true) {
      label = SPUtilities.addressLabelShortFormatter(cell, null, shortInd);
      copyLink = false;
    }

    return (<span><a href={"https://etherscan.io/address/" + row.ethAddress} class="animLink"
      >{label}</a>&nbsp;{copyLink && AddressUtils.copyLink(row.ethAddress)}</span>);
  }

  static ethAddressBalanceFormatter(cell, row) {
    if (!cell && cell != 0) {
      return "";
    }

    let label = cell;

    return (<span><a href={"https://etherscan.io/address/" + row.ethAddress} class="animLink"
      >{label}</a></span>);
  }

  static orchAddressBalanceFormatter(cell, row) {
    if (!cell && cell != 0) {
      return "";
    }

    let label = cell;

    return (<span><a href={SessionUtils.getUrl("/address/" + row.orchAddress)} class="animLink"
      >{label}</a></span>);
  }

  static addressFormatterLinkNoCopy(cell, row, index, shortInd) {
    if (!cell) {
      return "";
    }

    let label = cell;
    let copyLink = true;
    if (shortInd && shortInd === true) {
      label = SPUtilities.addressLabelShortFormatter(cell, null, shortInd);
      copyLink = false;
    }

    return (<span><a className="animLink"
      href={SessionUtils.getUrl("/address/") + cell}>{label}</a>
      &nbsp;{copyLink && AddressUtils.copyLink(cell)}</span>);
  }

  static addressWithCopy(cell, row) {
    if (!cell) {
      return "";
    }

    let label = cell;
    if (RespUtils.isMobileView()) {
      label = SPUtilities.addressLabelShortFormatter(cell, null, true);
    }

    return (<span>{label}{AddressUtils.copyLink(cell)}</span>);
  }

  static addressFormatterShortWithCopy(address) {
    if (!address) {
      return "";
    }

    let label = SPUtilities.addressLabelShortFormatter(address, null, true);
    return (<span>{label}{AddressUtils.copyLink(address)}</span>);
  }

  static convertAddressesForDropdown(addresses) {
    return addresses.map(address => {
        const description = address.substring(0, 5) + "..." + address.substring(address.length - 5);
        return {
            code: address,
            description: description
        };
    });
}

}

export default AddressUtils;
