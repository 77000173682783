import React from 'react';

import SPUtilities from '../util/SPUtilities';
import RespUtils from '../util/RespUtils';
import KeybaseLogo from '../base/KeybaseLogo';
import currentNetwork from '../currentNetwork';
import common from '../common';
import constants from '../constants';

class LogoUtils extends React.Component {
  static getImageSize(large) {
    let size = 16;
    if (large && RespUtils.isMobileView()) {
      size = 32;
    } else if (large) {
      size = 32;
    } else if (RespUtils.isNotMobileView()) {
      size = 20;
    }

    return size;
  }

  static defaultLogoFormatter() {
    let size = LogoUtils.getImageSize(true);

    return (<img className="logoStyle" width={size} height={size} src={currentNetwork.details.app.defaultLogo} />);
  }

  static logoFormatter(address, logoUrl, large) {
    if (!address) return "";

    return LogoUtils.logoByUrl(logoUrl, large);
  }

  static logoByUrl(logoUrl, large, styleName) {
    let size = LogoUtils.getImageSize(large);
    let imagePath = logoUrl;

    if (!styleName) styleName = "logoStyle"

    return (<img onError={this.addDefaultSrc} className={styleName}
      width={size} height={size} src={imagePath}/>);
  }

  static addDefaultSrc(ev){
    ev.target.src = currentNetwork.details.app.defaultLogo;
  }

  static formatLogo(cell, row) {
    return LogoUtils.logoFormatter(cell, row.website, false);
  }

  static formatLogoFlag(cell, row) {
    if (!cell) return "";

    if (row.hasLogo) {
      return LogoUtils.logoFormatter(cell, row.website, false);
    }


    let size = LogoUtils.getImageSize(false);
    return <img onError={LogoUtils.addDefaultSrc} className="logoStyle"
      width={size} height={size} src={currentNetwork.details.app.defaultLogo}/>;
  }

  static formatLogoByIdentity(identity, row) {
    // console.log(identity);
    if (!identity) {
      return LogoUtils.defaultLogoFormatter();
    }

    return <KeybaseLogo identity={identity} />

  }

  static getChainLogo() {
    return LogoUtils.formatChainLogoBySizeAndStyle("logoStyle", 24);
  }


  static formatLogoByPath(logoPath, row) {
    // console.log(logoPath);
    if (!logoPath) {
      return LogoUtils.defaultLogoFormatter();
    }

    let size = LogoUtils.getImageSize(true);
    return <img onError={LogoUtils.addDefaultSrc} className="logoStyle"
      width={size} height={size} src={logoPath} />;
  }

  static formatLogoByPathAndSize(logoPath, row) {
    //console.log(logoPath);
    if (!logoPath) {
      return (<img className="logoStyle" width="24" height="24" src={currentNetwork.details.app.defaultLogo} />);
    }

    let size = 24;
    return <img onError={LogoUtils.addDefaultSrc} className="logoStyle"
      width={size} height={size} src={logoPath} />;
  }

  static formatLogoByPathAndSizeAndStyle(logoPath, row, styleName) {
    //console.log(logoPath);
    if (!logoPath) {
      return (<img className={styleName} width="24" height="24" src={currentNetwork.details.app.defaultLogo} />);
    }

    let size = 24;
    return <img onError={LogoUtils.addDefaultSrc} className={styleName}
      width={size} height={size} src={logoPath} />;
  }

  static formatChainLogoBySizeAndStyle(styleName, size) {
    return <img onError={LogoUtils.addDefaultSrc} className={styleName}
      width={size} height={size} src={currentNetwork.details.app.defaultLogo} />;
  }

  static networkLogo(logo, row) {
    // console.log(logo);
    // console.log(row);
    return LogoUtils.projectLogoBySize(logo, row, true, "Network logo for: " + row.name);
  }

  static tokenLogo(logo, row) {
    // console.log(logo);
    // console.log(row);
    return LogoUtils.projectLogoBySize(logo, row, true, "Token logo for " + row.token);
  }

  static projectLogo(logo, row) {
    // console.log(logo);
    // console.log(row);
    return LogoUtils.projectLogoBySize(logo, row, true);
  }

  static getProjectLogoPath(logoPath) {
    let imagePath = currentNetwork.details.app.defaultProjectLogo;

    if (logoPath && logoPath !== "") {
      if (logoPath.toLowerCase().startsWith("http")) {
        imagePath = logoPath;
      } else {
        imagePath = constants.PROJECT_LOGO_BASE_URL + logoPath;
      }
    }

    return imagePath;
  }

  static projectLogoBySize(logo, row, largeSize, altText) {
      // if (!logo) return "";
    let size = LogoUtils.getImageSize(largeSize);
    let imagePath = LogoUtils.getProjectLogoPath(row.logo);
    
    // console.log("project: ", row.name, ", imagePath: ", row.logo);

    // console.log(imagePath);
    // return (<object type="image/png" width={size} height={size} data={imagePath}>
    //   <img className="img-valign" width={size} height={size} src="images/logo/default.png" />
    // </object>);
    let alt = altText != null ? altText : "Project/network logo";
    return (<img onError={this.addDefaultSrc} className="logoStyle" 
      width={size} height={size} src={imagePath} alt={alt} />);
  }

  static projectLogoWithCustomStyle(logo, row, customLogoStyle) {
      // if (!logo) return "";
    let size = LogoUtils.getImageSize(false);
    let imagePath = LogoUtils.getProjectLogoPath(row.logo);

    // console.log("project: ", row.name, ", imagePath: ", row.logo);

    // console.log(imagePath);
    // return (<object type="image/png" width={size} height={size} data={imagePath}>
    //   <img className="img-valign" width={size} height={size} src="images/logo/default.png" />
    // </object>);

    return (<img onError={this.addDefaultSrc} className={customLogoStyle} key={imagePath}
      width={size} height={size} src={imagePath}/>);
  }

  static chainLogo(logoFileName, customLogoStyle) {
      // if (!logo) return "";
    let size = LogoUtils.getImageSize(false);

    let imagePath = common.networkLogoBaseUrl + logoFileName;

    // let imagePath = "/images/chains/" + logoFileName;
    let logoStyle = "logoStyle";
    if (customLogoStyle) {
      logoStyle = customLogoStyle;
    }

    return (<img className={logoStyle} 
      width={size} height={size} src={imagePath}/>);
  }

  static defaultChainLogo() {
    let size = LogoUtils.getImageSize(true);

    let imagePath = currentNetwork.details.app.defaultProjectLogo;
    // let imagePath = common.networkLogoBaseUrl + currentNetwork.details.app.defaultProjectLogo;
    // console.log("in defaultChainLogo: ", imagePath);

    return (<img className="logoStyle" 
      width={size} height={size} src={imagePath}/>);
  }

  static tokenLogoWithCustomStyle(logo, row, customLogoStyle) {
    let size = RespUtils.isMobileView() ? 24 : 32;
    let imagePath = LogoUtils.getProjectLogoPath(row.logo);

    return (<img onError={this.addDefaultSrc} className={customLogoStyle} key={imagePath}
      width={size} height={size} src={imagePath}/>);
  }

}

export default LogoUtils;
