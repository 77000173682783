import React from 'react';

import SPUtilities from './SPUtilities';
import FavUtils from './FavUtils';
import UITableUtils from './UITableUtils';
import RespUtils from './RespUtils';
import SessionUtils from './SessionUtils';
import numeral from 'numeral';
import CoinUtils from './CoinUtils';

class UIUtils extends React.Component {

  static getResponsiveWidth(thisObj) {
    let width = "99%";
    if (!thisObj.state.responsive) {
      width =  window.innerWidth * 1.5;
    }

    return width;
  }

  static getResponsiveWidthScreenSize() {
    let width = "100%";

    return width;
  }

  static getResponsiveHeightScreenSize() {
    let height = "100%";

    return height;
  }

  static getResponsiveHeight(thisObj) {
    let height = 300;
    if (window.innerHeight < 600) {
      height = 250;
    } else if (window.innerHeight < 1000) {
      height = 300;
    }

    return height
  }

  static getChartMargin(thisObj) {
    let margin = {top: 10, right: 5, left: 15, bottom: 15};
    if (thisObj.props.bigMargin) {
      margin = {top: 10, right: 25, left: 25, bottom: 15};
    }

    return margin;
  }

  static getChartHeight(thisObj, big) {
    let height = 300;
    if (big && big === true) {
      height = 400
    }

    if (thisObj.props) {
      if (window.innerHeight < 600) {
        height = 250;
      } else if (window.innerHeight < 1000) {
        height = 350;
      }
    }

    return height;
  }

  static getFSChartHeight(thisObj, big) {
    let height = UIUtils.getChartHeight(thisObj, big);
    if (RespUtils.isFullScreen() && window.innerHeight > 700) {
      height = 600;
    }

    return height;
  }

  static getShowDataLabelDefaultWidth(thisObj, data) {
    return UIUtils.getShowDataLabel(thisObj, data, 600);
  }

  static getShowDataLabel(thisObj, data, minWidth) {
    let showDataLabel = false;
    // console.log(this.state.data);
    // console.log(this.state.data.length);
    if (thisObj.props.showVerticalLabel && window.innerWidth > minWidth && data !== undefined && data.length < 35) {
      showDataLabel = true;
    } else if (thisObj.props.showVerticalLabel && window.innerWidth < minWidth && data !== undefined && data.length < 7) {
      showDataLabel = true;
    }

    return showDataLabel;
  }

  static getPageOptions(thisObj) {
    const options = {
      onlyOneExpanding: true,
      sizePerPageList: [
        {text: '50', value: 50},
        {text: '100', value: 100},
        {text: '200', value: 200},
        {text: '500', value: 500}
      ]
    };

    return options;
  }

  static getPageOptionsLarge(thisObj) {
    const options = {
      onlyOneExpanding: true,
      sizePerPageList: [
        {text: '200', value: 200},
        {text: '500', value: 500},
        {text: '1000', value: 1000},
        {text: '2000', value: 2000}
      ]
    };

    return options;
  }

  static getPageOptionsSmall(thisObj, minSize) {
    let options;
    if (minSize && minSize !== null && minSize !== 10) {
      options = {
        onlyOneExpanding: true,
        sizePerPageList: [
          {text: minSize, value: minSize},
          {text: '10', value: 10},
          {text: '25', value: 25},
          {text: '50', value: 50},
          {text: '100', value: 100},
          {text: '200', value: 200},
          {text: '500', value: 500}],
      };
    } else {
      options = {
        onlyOneExpanding: true,
        sizePerPageList: [
          {text: '10', value: 10},
          {text: '25', value: 25},
          {text: '50', value: 50},
          {text: '100', value: 100},
          {text: '200', value: 200},
          {text: '500', value: 500}],
      };
    }

    return options;
  }

  static getPoolId(thisObj) {
    var poolId = thisObj.props.match.params.poolId;
    if (typeof poolId === 'undefined') {
      poolId = 1;
    }

    // console.log("poolId final is: ", poolId);
    return poolId;
  }

  static getHideFooter(thisObj) {
    // console.log(thisObj.props.match);
    if (typeof thisObj.props.match === 'undefined') {
      // console.log("returning because no params found");
      return false;
    }

    var poolId = this.props.match.params.poolId;
    // console.log("poolId: ", poolId);

    var hideFooter = thisObj.props.match.params.hideFooter;
    // console.log("hideFooter param is: ", hideFooter);
    if (typeof hideFooter === 'undefined') {
      // console.log("hideFooter param is: ", hideFooter);
      hideFooter = false;
    }

    // console.log("hideFooter final is: ", hideFooter);
    return hideFooter;
  }

  static getNotification(notification) {
    if (!notification || notification.message === null) {
      return "";
    }
    
    // return (<div className="notification">{notification.message}</div>);
    // style={{"backgroundColor": "orange"}}
    return (<div className="notification" dangerouslySetInnerHTML={{ __html: notification.message }}>
      </div>);
  }

  static getLoading(thisObj) {
    if (thisObj.state.isLoading) {
      return <div>Loading</div>;
    } else if (thisObj.state.error) {
      return (<div><p/>
        <p>An error occurred while loading the screen. Please contact the website operator if issue persists.</p>
        <p/>
        <p/><p align="center"><a href="javascript:window.location.reload(true);">Try again</a></p>
        </div>);
    }
  }

  static getNetworkError() {
      return (<div><p/>
        <p>Network "${SessionUtils.getNetwork()}" is not supported. <br/>
          Please contact Smart Stake support if you think this is a bug or if you want this network supported.</p>
        <p/>
        <p/><p align="center"><a href="javascript:window.location='/'">Home</a></p>
        </div>);
  }

  static renderReload(thisObj) {
    return UIUtils.renderReloadSize(thisObj, 32);
  }

  static renderReloadSize(thisObj, size) {
    return (<img src="/images/reload.svg" onClick={SPUtilities.reload}
      title="Reload Screen" className="imgicon" width={size} height={size} />);
  }

  static renderPageActions(thisObj, fav, respSwitch) {
    return (<span className="buttonWithText">{fav ? FavUtils.showFavSwitch() : ""}&nbsp;
      {UIUtils.renderReload(thisObj)}{respSwitch ? UITableUtils.renderSwitchView(thisObj) : ""}</span>);
  }

  static getUniqueValues(data, attribute) {
    let values = new Set();

    for (var i=0; i < data.length; i++) {
      // let val = { text: data[i][attribute], value: data[i][attribute] };
      // console.log("value is: ", data[i][attribute]);
      values.add(data[i][attribute]);
    }

    // console.log(attribute, " - ", values);

    let options = {};
    for (var i=0; i < values.length; i++) {
      // let val = { text: data[i][attribute], value: data[i][attribute] };
      // console.log(i + " - " + values[i]);
      options[i] = values[i];
    }

    // console.log("options are: " + attribute, " - ", options);
    return values;
  }

  static getTip(inputText) {
    const chunks = inputText.match(/.{1,35}(\s|$)/g);
    let tip = chunks.join('<br/>');

    return tip;
  }

  static projectFormatter(cell, row) {
    return (
      <React.Fragment>
        <a href={SessionUtils.getUrl("/project/" + row.projectId)} className="animLinkVisual">{cell}</a>
      </React.Fragment>
    );
  }
  
  static addIndexAndFav(data, favAttr) {
    if (!data) {
        return data;
    }

    for (let i = 0, tableDataLen = data.length; i < tableDataLen; i++) {
        data[i]["index"] = i + 1;
        data[i]["fav"] = data[i][favAttr];
    }

    return data;
  }

  static websiteLinkWithStyle(label, link, styleName) {
    if (!label) {
      return label;
    }

    if (!link) {
      return label;
    }

    return (<a className={styleName} target="_blank"
      href={SPUtilities.getUrl(link)}>{label}</a>);
  }

  static websiteLinkNoStyle(label, link) {
    if (!link) {
      return "";
    }

    return UIUtils.websiteLinkWithStyle(label, link, "defaultLink");
  }

  static websiteLink(label, link) {
    return UIUtils.websiteLinkWithStyle(label, link, "animLink");
  }

  static coingeckoLinkFromMcap(mcap, row) {
    if (!mcap || mcap <= 0) {
      return "N/A";
    }

    if (!row.geckoId) {
      return CoinUtils.stakeFormatterRounded(mcap, row);
    }

    let coingecko = "https://www.coingecko.com/en/coins/" + row.geckoId;
    let value = numeral(mcap).format('0.0a');
    return UIUtils.websiteLinkWithStyle("$" + value, coingecko, "animLink");
  }
}

export default UIUtils;
