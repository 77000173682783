import React from "react";
import {ResponsiveContainer, XAxis, YAxis, CartesianGrid, Tooltip, Label, LabelList } from 'recharts';
import {BarChart, Bar } from 'recharts';

import UIUtils from '../util/UIUtils';
import Utilities from '../util/Utilities';
import ChartUtils from '../util/ChartUtils';
// import currentNetwork from "../currentNetwork";
import ChartTitle from "../components/ChartTitle";
import common from "../common";
import DownloadUtils from "../util/DownloadUtils";
import SPCalc from "../util/SPCalc";
import currentNetwork from "../currentNetwork";
import ChartData from './ChartData';

class BaseBarChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      responsive: true,
      mode: common.charts.mode.default
    }

    this.flipResponsive = this.flipResponsive.bind(this);
    this.switchMode = this.switchMode.bind(this);
    this.truncDate = this.truncDate.bind(this);
  }

  flipResponsive() {
    ChartUtils.flipResponsive(this);
  }

  renderBar() {
    let textColor = common.colors.text;//"white";
    let lineColor = "green"; //common.colors.chartColor1
	  
    if (this.props.color && this.props.color != null) {
      lineColor = this.props.color;
    }

    let width = UIUtils.getResponsiveWidth(this);
    // <Label value={this.props.xAxis} offset={-8} position="insideBottom"/>
    let height = UIUtils.getChartHeight(this);
    let showDataLabel = UIUtils.getShowDataLabel(this, this.props.data, 600);

    const CustomTooltip = ({ active, payload, label }) => {
      if (active) {
        if (payload === null || payload.length === 0) {
          return null;
        }

        let data = payload[0].payload;

        return (<div style={{"margin": "0px", "padding": "0px"}}>
            <table><tbody>
              <tr>
                <td>{this.props.xAxis}:</td>
                <td>{data[this.props.xAxisValueAttr]}</td>
              </tr>
              <tr>
                  <td><font color={lineColor}>{this.props.valueAttr}:</font></td>
                  <td><font color={lineColor}>{data[this.props.valueAttr]}</font></td>
              </tr>
            </tbody></table>
          </div>);
      }

      return null;
    };
    // margin={{top: 10, right: 0, left: 5, bottom: 20}}>

    return (
      <div>
        <ResponsiveContainer width={width} height={height}>
          <BarChart data={this.props.data} barCategoryGap={2}
              margin={{top: 10, right: 5, left: 15, bottom: 15}}>
            <CartesianGrid vertical={false} horizontal={false} strokeDasharray="3 3"/>
            <XAxis dataKey={this.props.xAxisValueAttr} stroke={textColor} angle={-10}>
              {this.props.hideAxisTitle === true ? "" : <Label value={this.props.xAxis} offset={-3} position="insideBottom" />}
            </XAxis>
            {this.props.formatValues !== null ? 
              (
                <YAxis tickFormatter={SPCalc.formatNumberValue} stroke={textColor} domain={Utilities.getRangeWithBase0(true, this.props.data, this.props.valueAttr)}>
                  {this.props.hideAxisTitle === true ? "" : <Label value={this.props.yAxis} offset={10} position="insideBottomLeft" angle={-90} />}
                </YAxis>
              ) : 
              (
                <YAxis stroke={textColor} domain={Utilities.getRangeWithBase0(true, this.props.data, this.props.valueAttr)}>
                  {this.props.hideAxisTitle === true ? "" : <Label value={this.props.yAxis} offset={10} position="insideBottomLeft" angle={-90} />}
                </YAxis>
              )
            }
            <Tooltip content={<CustomTooltip />} />
            <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
            {showDataLabel && <Bar maxBarSize={50} dataKey={this.props.valueAttr} stroke={lineColor} fill={lineColor} onClick={this.barOnClick}>
              <LabelList dataKey={this.props.valueAttr} position="top" /> 
            </Bar>}
            {!showDataLabel && <Bar maxBarSize={50} dataKey={this.props.valueAttr} stroke={lineColor} fill={lineColor} onClick={this.barOnClick} /> }
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }

  render () {
    let tableInd = this.state.mode === common.charts.mode.table;
    let chartVisualId = currentNetwork.details.networkName + "-" + (tableInd ? "" : "data") + this.props.chartVisualId;
    const downloadChart = () => {
      ChartUtils.downloadChart(document, chartVisualId, currentNetwork.details.app.title);
    };
    return (
      <div id={"div" + chartVisualId}>
        {this.props.hideTitle != true && (<ChartTitle title={this.props.title} tip={this.props.tip} handleChart={this.props.handleChart} disableDay={this.props.disableDay} 
          statType={this.props.statType} isOverallOnly={this.props.isOverallOnly} disableWeek={this.props.disableWeek} 
          addDays={this.props.addDays} displayHelpIcon={this.props.displayHelpIcon} fsElementId={this.props.fsElementId}
          frequency={this.props.frequency} showHelp={this.showHelp} frequencies={this.props.frequencies} shortTitle={this.props.shortTitle}
          downloadData={DownloadUtils.getDownloadDataForChart(this.props.data, this.props.xAxisValueAttr, this.props.valueAttr)} 
          saveChart={downloadChart} enableSave={this.props.enableSave} 
          mode={this.state.mode} switchMode={this.switchMode} columns={this.props.columns}
          />)}
        <div align="center">
          {this.props.showTotalLabel ? Utilities.getTotalWithLabel(this.props.data, this.props.valueAttr, this.props.totalLabel) : ""}
          {tableInd ? <ChartData data={this.props.data} columns={this.props.columns} dataKey={this.props.dataKey} sortBy={this.props.sortBy}/> : this.renderBar()}
        </div>
        {ChartUtils.getLandscapeMsg()}
      </div>
    );
  }
  // <p>Data for the latest window (hour or date) is partial. All times are in GMT.</p>

  truncDate(val) {
    return ChartUtils.truncDate(val, this.props.chartType, this.props.filter)
  }

  switchMode() {
    let currentMode = this.state.mode;
    if (currentMode === common.charts.mode.chart) {
      this.setState({mode: common.charts.mode.table});
    } else {
      this.setState({mode: common.charts.mode.chart});
    }
  }
}

export default BaseBarChart;
