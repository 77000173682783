import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import "../airdrops/Airdrop.css";
import LogoUtils from '../util/LogoUtils';
import ProjectUIUtils from '../projects/ProjectUIUtils';
import PageHeader from '../components/PageHeader';

class Network extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.renderNetworkLinks = this.renderNetworkLinks.bind(this);
    }

    render() {
        return (<div>
            <PageHeader title={this.props.network.name} thisObj={this.props.thisObj} 
                additionalForTitle={this.renderNetworkLinks} hideSpecial={true} 
                disableStakeFormatter={true} notification={false} />
        </div>);
    }

    renderNetworkLinks() {
        return (<div className="displayNetworkMedia">{ProjectUIUtils.formatMediabySize(this.props.network.twitter, this.props.network, true, false)}</div>);
    }
    
    /*renderStats() {
        return (
            <Col className="blockBg" xs={12} sm={12} md={6} lg={4} xl={4}>
                <div className="airdrop">
                    <div className="airdropTitle">
                        <div className="airdropLabel">
                            <span className="airdropLogo">{LogoUtils.tokenLogo(this.props.network.logo, this.props.network)}</span>
                            <span className="textLabel">{Special.projectFormatter(this.props.network.name, this.props.network)} {AirdropUtils.renderStatus(this.props.network.airdropStatus)}</span>
                            <div className="airdropMedia">{ProjectUIUtils.formatMediaSmall(this.props.network.twitter, this.props.network)}</div>
                        </div>
                    </div>
                    <p className="airdropNav">
                        <a className="animLink airdropLink linkBorder" href={this.getDetailsLink()} target="_blank">Details</a>
                        {this.props.network.eligibilityCheck !== null && <a className="animLink airdropLink linkBorder" href={this.props.network.eligibilityCheck} target="_blank">Eligibility Check</a> }
                        {this.props.network.airdropStatus === 'Active' && <a className="animLink airdropLink linkBorder" href={this.props.network.airdropWebsite} target="_blank">Claim</a> }
                    </p>
                </div>
            </Col>
        );
    }*/
}

export default Network;
