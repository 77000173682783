import React, { Component } from "react";
import { useState } from "react";
// import Routes from './Routes-auth';
import {BrowserRouter as Router} from 'react-router-dom';
// import { Route } from 'react-router-dom';
// import Fullscreen from "react-full-screen";
// import ReactGA from 'react-ga';
// import { createBrowserHistory } from 'history';

import "./App.css";
import DefaultRoutes from './DefaultRoutes';
import NavBar from './NavBar';
import Footer from './base/Footer';
// import NetworkUtils from './util/NetworkUtils';
import config from './config';
import currentNetwork from "./currentNetwork";
import CacheBuster from "./CacheBuster";
// import meta from "./meta";

class App extends Component {
  constructor(props) {
		super(props);
    // description: meta.desc.main,
    // title: meta.title.main

    this.state = {
    };
	}

  componentDidCatch(error, errorInfo) {
    // Catch errors in any child components and re-renders with an error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
  }

	async componentDidMount() {
	}

  getNonProdWarning() {
    if (config.apiGateway.ENV === "prod") {
      return "";
    }

    return (<div className="headerWarning"><p/><p/>This is a test website. Contact the website operator to report the issue.</div>);
  }

  render() {
    if (this.state.error) {
      // Fallback UI if an error occurs
      console.log(this.state.error);
      return (
       <div>
         <h3>{"Oh-no! Something went wrong"}</h3>
         <p className="red">Please <a href="javascript:location.reload(true);">reload</a> the screen and try again. </p>
         <p align="center"><a href="javascript:window.location='/'">Home</a>&nbsp;&nbsp;&nbsp;<a href="javascript:window.location.reload(true);">Reload</a></p>
       </div>
      );
    }

    return this.renderDefaultContainer();
  }

  renderDefaultContainer() {
    document.title = currentNetwork.details.app.title;

    const childProps = {
    };

    return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }

        return (
          <Router>
            <div className="App">
              <div id="outer-container" className="divWithText">
                <NavBar {...childProps} handleLogout={this.handleLogout} onFullScreen={this.goFull}/>
                {/* <Helmet>
                  <title>{this.state.title}</title>
                  <meta name="description" content={this.state.description} />
                </Helmet> */}
                <main id="page-wrap">
                  {this.getNonProdWarning()}
                  <DefaultRoutes childProps={childProps} />
                </main>
                <Footer/>
              </div>
            </div>
          </Router>
        );
      }}
    </CacheBuster>
    );
  }
/*        return (
          <Router>
            <Fullscreen enabled={this.state.isFull} onChange={isFull => this.setState({isFull})} >
              <div className="App">
                <div id="outer-container" className="divWithText">
                  <NavBar {...childProps} handleLogout={this.handleLogout} onFullScreen={this.goFull}/>
                  <main id="page-wrap">
                    {this.getNonProdWarning()}
                    <DefaultRoutes childProps={childProps} />
                  </main>
                  <Footer/>
                </div>
              </div>
            </Fullscreen>
          </Router>
        );
 */
}

export default App;
