import React from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import {Container} from 'react-bootstrap';
import ReactTooltip from "react-tooltip";

import tooltips from "../tooltips";
import MultiLineChart from '../charts/MultiLineChart';
import '../styles/section.css';
import currentNetwork from '../currentNetwork';
import ApiUtils from '../util/ApiUtils';
import NetworkDistributionComparison from './NetworkDistributionComparison';
import BaseBarChart from '../charts/BaseBarChart';
import common from '../common';
import HolderDistributionRange from './HolderDistributionRange';
import UrlUtils from '../util/UrlUtils';

class Decentralization extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      networkHistory: [],
      notification: {},
      width: window.innerWidth,
      size: 10,
      responsive: true,
      isLoading: true,
      frequencyNetwork: "M",
      frequencyBlock: "H",
      topDelegates: [],
      stakeShare: null,
      deltaActiveAccounts: [],
      holderDistributionUsd: [],
    }
    // holderDistributionToken: [],

    this.updateDimensions = this.updateDimensions.bind(this);
    this.handleChartUpdateNetwork = this.handleChartUpdateNetwork.bind(this);   
    this.handleChartUpdateBlock = this.handleChartUpdateBlock.bind(this);
  }


  updateDimensions() {
    this.setState({width: window.innerWidth});
  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  async componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);

    UrlUtils.setUrlFragment("decentralization");
    await this.prepareData(this.state.frequencyNetwork, this.state.frequencyBlock);
  }

  async prepareData(frequencyNetwork, frequencyBlock) {
    let url = "networkStats?type=decentralization&frequencyNetwork=" + frequencyNetwork + "&frequencyBlock=" + frequencyBlock;
    const allData = await ApiUtils.get(url);
    // "holderDistributionToken": allData["holderDistributionToken"], 

    this.setState({"frequencyNetwork": frequencyNetwork, "frequencyBlock": frequencyBlock,
        "notification": allData["notification"], "networkHistory": allData["networkHistory"], 
        "holderDistributionUsd": allData["holderDistributionUsd"], 
        isLoading: false});
  }

  render() {
    if (this.state.isLoading) {
      return <div>Loading</div>;
    }

    // let holderDistTitle = "Holder Distributions in % (by " + currentNetwork.details.app.token + " amount)";
    // let holderDistShortTitle = "Holder Distributions in % (by " + currentNetwork.details.app.token + ")";

    let holderDistDollarTitle = "Holder Distributions in % (by $ value)";
    let holderDistDollarShortTitle = "Holder Distributions in % (by $)";

    let decColumns = [
      {text: "Date", dataField: "title", sort: true, },
      {text: "Gini Coeffiecient", dataField: "gini", sort: true, },
      {text: "Nakamoto Coefficient", dataField: "nakamotoIndex", sort: true, },
      {text: "Weighted Median Commission", dataField: "weightedMedianCommission", sort: true, },
    ];

    return (
      <div>
        <ReactTooltip id="main" place="top" type="dark" effect="float" multiline={true} />
        <hr/>
        <Container fluid className="containerLayout container-fluid chartContainer">
          <div className="chartGridContainer">
            <NetworkDistributionComparison network={currentNetwork.details.APP_KEY} label={currentNetwork.details.app.title} />
            <HolderDistributionRange network={currentNetwork.details.APP_KEY} token={null} />
            {this.state.holderDistributionUsd != null && (<div className="chartBg" id="holderDistributionUsd">
              <BaseBarChart title={holderDistDollarTitle} shortTitle={holderDistDollarShortTitle} 
                chartVisualId="holder-dist-usd" enableSave={true} 
                tip={tooltips.networkCharts.stakerDistributionUsd} xAxis={"Amount in $"} yAxis="Percentage Holders" 
                showVerticalLabel={true} valueAttr="percentHolder" showTotalLabel={false} xAxisValueAttr="title"
                data={this.state.holderDistributionUsd} 
                dataKey="title" columns={[
                  {text: "Amount in $USD", dataField: "title", sort: true, },
                  {text: "% of Stakers", dataField: "percentHolder", sort: true, },
                ]} />
            </div>)}
            <div className="chartBg" id="giniCoefficient">
                <MultiLineChart title="Gini Coefficient" xAxis="Date" yAxis="Gini Coefficient" adjustRange={true} 
                  chartVisualId="gini-coefficient" enableSave={true} 
                  showVerticalLabel={false} valueAttr={["gini"]} rangeAttr="gini" showTotalLabel={false} xAxisValueAttr="title"
                  data={this.state.networkHistory} tip={tooltips.networkCharts.gini} 
                  handleChart={this.handleChartUpdateNetwork} formatValues={true} fsElementId="giniCoefficient" 
                  frequencies={["M", "Y", "All"]} frequency={this.state.frequencyNetwork} 
                  dataKey="title" columns={decColumns} />
            </div>
            <div className="chartBg" id="nakamotoIndex">
                <MultiLineChart title="Nakamoto Index" xAxis="Date" yAxis="Nakamoto Index" adjustRange={true} 
                  chartVisualId="nakamoto-index" enableSave={true} 
                  showVerticalLabel={false} valueAttr={["nakamotoIndex"]} rangeAttr="nakamotoIndex" showTotalLabel={false} xAxisValueAttr="title"
                  data={this.state.networkHistory} tip={tooltips.networkCharts.nakamotoIndex} 
                  handleChart={this.handleChartUpdateNetwork} formatValues={true} fsElementId="nakamotoIndex" 
                  frequencies={["M", "Y", "All"]} frequency={this.state.frequencyNetwork} 
                  dataKey="title" columns={decColumns} />
            </div>
            <div className="chartBg" id="weightedMedianCommission">
                <MultiLineChart title="Weighted Median Commission" shortTitle="Weighted Med Commission" 
                  chartVisualId="weighted-median-commission" enableSave={true} 
                  xAxis="Date" yAxis="Weighted Median Commission" adjustRange={true} 
                  showVerticalLabel={false} valueAttr={["weightedMedianCommission"]} rangeAttr="weightedMedianCommission" showTotalLabel={false} xAxisValueAttr="title"
                  data={this.state.networkHistory} tip={tooltips.networkCharts.weightedMedianCommission} 
                  handleChart={this.handleChartUpdateNetwork} formatValues={true} fsElementId="weightedMedianCommission" 
                  frequencies={["M", "Y", "All"]} frequency={this.state.frequencyNetwork}
                  dataKey="title" columns={decColumns} />
            </div>
          </div>
        </Container>
        <p>* Supply Distribution is limited to top 5000 address per network for the selected criteria. If the chart gives error/timesout, try reducing the number of selected networks.</p>
        <p>** Submit known addresses <a href={common.urls.aliasUrl}>here</a>.</p>
      </div>
    );
  }
  
  handleChartUpdateNetwork(frequencyNetwork) {
    this.prepareData(frequencyNetwork, this.state.frequencyBlock);
  }

  handleChartUpdateBlock(frequencyBlock) {
    this.prepareData(this.state.frequencyNetwork, frequencyBlock);
  }

}

export default Decentralization;
