import React from 'react';

import Dropdown from '../Dropdown';
import currentNetwork from '../../currentNetwork';

class Views extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
    this.handleViewChange = this.handleViewChange.bind(this);
  }

  render() {
    let values = this.props.values;
    if (!currentNetwork.details.notSet && currentNetwork.details.app.consumerChain === true) {
      // ignore views that dont support consumer chains
      values = values.filter(item => !item.disable4ConsumerChain || item.disable4ConsumerChain !== true);
    } else if (!currentNetwork.details.notSet && currentNetwork.details.app.tendermint !== true) {
      // ignore views that dont support consumer chains
      values = values.filter(item => !item.disable4NonTendermint || item.disable4NonTendermint !== true);
    }

    return (<Dropdown onSelect={this.handleViewChange} values={values} defaultValue={this.props.defaultView} 
      addEmpty={false} size="lg" className="customViews" />)
  }

  handleViewChange(e) {
    this.props.handleViewChange(e.target.value);
  }
}

export default Views;
