import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
// import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ReactTooltip from "react-tooltip";
import {Container} from 'react-bootstrap';

import ApiUtils from '../util/ApiUtils';

import Utilities from "../util/Utilities";
import UIUtils from "../util/UIUtils";
import SPUtilities from "../util/SPUtilities";
import MainUtils from '../main/MainUtils';
import ChartTitle from '../components/ChartTitle';
import PageHeader from '../components/PageHeader';
import tooltips from '../tooltips';
import currentNetwork from '../currentNetwork';
import MultiLineChart from '../charts/MultiLineChart';
import SessionUtils from '../util/SessionUtils';
import IntegrationUtils from '../util/IntegrationUtils';

class Address extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "",
      addressDetails: {},
      delegations: [],
      undelegations: [],
      addressHistory: [],
      width: window.innerWidth,
      size: 10,
      isLoading: true,
    }
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  updateDimensions() {
    this.setState({width: window.innerWidth});
  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  async componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    SessionUtils.setNetwork(this);

    var address = SPUtilities.getAddress(this);
    // console.log(address);
    // console.log("componentDidMount - after address: ", address);

    let url = "listData?type=address&address=" + address;
    // console.log(url);
    const allData = await ApiUtils.get(url);
    // console.log("allData is:", allData);

    if (allData) {
      let addressDetails = allData["address"];
      let delegations = Utilities.addIndex(allData["delegations"]);
      let undelegations = Utilities.addIndex(allData["undelegations"]);

      this.setState({"addressDetails": addressDetails, "delegations": delegations, 
        "undelegations": undelegations, "address": address, "addressHistory": allData["addressHistory"],
        isLoading: false});
    }
  }

  render() {
    if (this.state.isLoading) {
      return <div>Loading</div>;
    }

    return (
      <div>
        <PageHeader title={"Address - " + this.state.address} thisObj={this} />
        <ReactTooltip id="main" place="top" type="dark" effect="float" multiline={true} />
        <p/>
        <Container fluid className="containerLayout container-fluid chartContainer">
          <div className="chartGridContainer">
            <div className="chartBg">
                {this.renderAddressSummary()}
            </div>
            <div className="chartBg" id="totalBalance">
              <MultiLineChart title="Address Balance" xAxis="Date" yAxis="Address Balance" adjustRange={true} 
                  showVerticalLabel={false} valueAttr={["totalBalance"]} rangeAttr="totalBalance" showTotalLabel={false} 
                  xAxisValueAttr="statDate" data={this.state.addressHistory} 
                  formatValues={true} fsElementId="totalBalance" 
                  dataKey="title" columns={[
                    {text: "Date", dataField: "statDate", sort: true, },
                    {text: "Balance", dataField: "totalBalance", formatter: SPUtilities.formatCoins, sort: true, },
                  ]} />
            </div>
            <div className="chartBg">{this.renderDelegations()}</div>
            <div className="chartBg">{this.renderUndelegations()}</div>
          </div>
        </Container>
      </div>
    );
  }

  renderDelegations() {
    var columns = [
      {text: "#", dataField: "index", sort: true, headerStyle: Utilities.respWidth(5, 4, 3)},
      {text: "Validator", dataField: "name", formatter: MainUtils.nameFormatterTableWithLogo, sort: true, headerStyle: Utilities.respWidth(60, 70, 70)},
      {text: ("Stake ($" + currentNetwork.details.app.token + ")"), dataField: "stake", formatter: SPUtilities.formatCoins, sort: true,  sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(40, 30, 30)},
    ];

    const options = UIUtils.getPageOptionsSmall(this, 50);
    // console.log("delegates is:", this.state.delegates);

    return (
      <React.Fragment>
        <ChartTitle title="Delegations" tip={tooltips.address.undelegations} />
        <br/>
        <BootstrapTable keyField='index' data={ this.state.delegations }
          columns={ columns } striped options={options}
          condensed noDataIndication="No data" pagination={ paginationFactory(options) }/>
      </React.Fragment>
    )
  }

  renderUndelegations() {
    var columns = [
      {text: "#", dataField: "index", sort: true, headerStyle: Utilities.width(5)},
      {text: "Validator", dataField: "name", formatter: MainUtils.nameFormatterTableWithLogo, sort: true, headerStyle: Utilities.respWidth(50, 50, 50)},
      {text: "Amount", dataField: "amount", formatter: SPUtilities.formatCoins, sort: true,  sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(15)},
      {text: "Release Time", dataField: "releaseTimeEpoch", formatter: SPUtilities.epochFormatter, sort: true,  sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(30)},
    ];

    const options = UIUtils.getPageOptionsSmall(this, 5);

    return (
      <React.Fragment>
        <ChartTitle title="Pending Undelegations" tip={tooltips.address.undelegations} />
        <br/>
        <BootstrapTable keyField='index' data={ this.state.undelegations }
          columns={ columns } striped options={options}
          condensed noDataIndication="No data" pagination={ paginationFactory(options) }/>
      </React.Fragment>
    )
  }

  renderAddressSummary() {
    return (
        <React.Fragment>
          <ChartTitle title="Address Summary" />
          <table>
            <tbody>
                <tr>
                    <td>Alias: </td>
                    <td> {this.state.addressDetails.alias}</td>
                </tr>
                <tr>
                    <td>Total Balance (${currentNetwork.details.app.token}): </td>
                    <td> {SPUtilities.formatCoins(this.state.addressDetails.totalBalance)}</td>
                </tr>
                <tr>
                    <td>Liquid Balance (${currentNetwork.details.app.token}): </td>
                    <td> {SPUtilities.formatCoins(this.state.addressDetails.addressBalance)}</td>
                </tr>
                <tr>
                    <td>Total Delegated (${currentNetwork.details.app.token}): </td>
                    <td> {SPUtilities.formatCoins(this.state.addressDetails.totalStake)}</td>
                </tr>
                <tr>
                    <td>Total Undelegation (${currentNetwork.details.app.token}): </td>
                    <td> {SPUtilities.formatCoins(this.state.addressDetails.totalUndelegation)}</td>
                </tr>
            </tbody>
            </table>
            <br/>
            {IntegrationUtils.renderDetailedIntegrations4Address(this.state.address)}
        </React.Fragment>
      );
  }

}

export default Address;
