import React from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import RespUtils from './RespUtils';
import SessionUtils from './SessionUtils';

class BreadcrumbUtils extends React.Component {
  static getBreadCrumb() {
    if (RespUtils.isTabletView()) {
      return (
        <Breadcrumb>
          <Breadcrumb.Item href={SessionUtils.getUrl("/")}>Home</Breadcrumb.Item>
        </Breadcrumb>
      );
    } else {
      return "";
    }
  }

  static getProposalBreadCrumb() {
    return BreadcrumbUtils.getBreadCrumbLink("Proposals", "/proposals")
  }

  static getBreadCrumbLink(label, linkUrl) {
    return (
        <Breadcrumb>
            <Breadcrumb.Item href={SessionUtils.getUrl(linkUrl)}>{label}</Breadcrumb.Item>
        </Breadcrumb>
    );
  }
}

export default BreadcrumbUtils;
