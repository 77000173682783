import React from 'react';
import {ResponsiveContainer, LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, Label, Legend, ReferenceLine } from 'recharts';
// import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
// import PersonAddIcon from '@material-ui/icons/PersonAdd';

import ApiUtils from '../util/ApiUtils';
import UIUtils from '../util/UIUtils';
import Loading from "../components/Loading";

import ChartTitle from "../components/ChartTitle";
import common from "../common";
import NetworkChartUtils from '../network/NetworkChartUtils';
import MultiList from '../base/MultiList';
import tooltips from '../tooltips';
import Dropdown from '../base/Dropdown';
import "./TokenDistributionComparison.css";
import "../base/AppList.css";

const SUPPLIES = [{"description": "Top 70%", "code": "70"}, {"description": "Top 80%", "code": "80"}, 
  {"description": "Top 90%", "code": "90"}, {"description": "Top 95%", "code": "95"}, ];
const ADDRESS_TYPES = [{"description": "All", "code": "all"}, {"description": "Not named", "code": "excludeAlias"}, 
  {"description": "Not SC/named", "code": "excludeAliasAndSC"}, ];

class TokenDistributionComparison extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      width: window.innerWidth,
      selectedTokens: [],
      vpCurves: {},
      curveIndexes: [],
      selectedTopPercentSupply: 80,
      selectedAddressType: "all",
      lineProps: [],
      lineHover: null,
      responsive: true,
    }

    this.updateDimensions = this.updateDimensions.bind(this);
    this.setSelectedTokens = this.setSelectedTokens.bind(this);
    this.updateDistributionFilter = this.updateDistributionFilter.bind(this);
    this.updateDistribution = this.updateDistribution.bind(this);
    this.renderAdditionalLink = this.renderAdditionalLink.bind(this);
    this.updateSupplyFilter = this.updateSupplyFilter.bind(this);
    this.updateAddressTypeFilter = this.updateAddressTypeFilter.bind(this);
    // this.hideNamedAccounts = this.hideNamedAccounts.bind(this);
    // this.showNamedAccounts = this.showNamedAccounts.bind(this);
  }

  async setSelectedTokens(e) {
    this.setState({"selectedTokens": e});
    await this.updateDistribution(e, this.state.selectedAddressType);
  }

  async updateDistribution(tokenList, selectedAddressType) {
    // console.log("selected tokens are: ");
    // console.log(tokenList);
    let tokens = "";
    for (let i = 0; i < tokenList.length; i++) {
      if (i !== 0) {
        tokens += ",";
      }
      //console.log(tokenList[i]);
      tokens += tokenList[i]["value"];
    }

    let url = "listData?type=tokenDistribution&useNewToken=true&tokens=" + tokens + "&addressType=" + selectedAddressType;
    // console.log("url is: ", url);
    const allData = await ApiUtils.get(url);
    // console.log("allData is:", allData);

    let orgDistributions = allData["distributions"];
    
    this.updateDistributionFilter(orgDistributions, this.state.selectedTopPercentSupply);
    this.setState({"allTokens": allData["allTokens"], "orgDistributions": orgDistributions, "isLoading": false, });
  }

  updateDimensions() {
    this.setState({width: window.innerWidth});
  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  async componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);

    let selectedTokens = [{label: this.props.tokenLabel, value: this.props.token}];

    let lineProps = {};
    let numberOfLines = 0;
    for(let i=0; i < selectedTokens.length; i++) {
      let value = selectedTokens[i]["value"];
      lineProps[value] = true;
      numberOfLines++;
    }

    this.setState({lineProps: lineProps, numberOfLines: numberOfLines});
    this.setState({selectedTokens: selectedTokens});
    this.updateDistribution(selectedTokens, this.state.selectedAddressType);
  }

  render() {
    if (this.state.isLoading) {
      return <Loading />;
    }

    return (
      <div className="chartBg" id="tokenDistributionComparison">
        <ChartTitle title="% Supply Distribution*" fsElementId="tokenDistributionComparison" tip={tooltips.networkCharts.supplyDistribution} 
          showAdditionalLink={true} renderAdditionalLink={this.renderAdditionalLink} shortTitle="% Supply*" />
        <div align="center">
          {this.renderLines()}
        </div>
      </div>
    );
  }

  renderLines() {
    let textColor = common.colors.text;
    let width = UIUtils.getResponsiveWidth(this);
    let height = UIUtils.getFSChartHeight(this);
    // let width = 500;
    // let height = 300;
    let colors = common.colors.chartColorsArray2;
    let index = -1;

    const handleLegendMouseEnter = (e) => {
      this.setState({lineHover: e.dataKey});
    };
    const handleLegendMouseLeave = (e) => {
      this.setState({lineHover: null});
    };
    const selectLine = (e) => {
      let lineProps = this.state.lineProps;
      //   console.log(lineProps);
      let selectedItem = e.dataKey;
      //   console.log(selectedItem);

      let itemVisibility = lineProps[selectedItem];
      //   console.log(itemVisibility);

      if (typeof itemVisibility === 'undefined') {
        lineProps[selectedItem] = false;
      } else {
        itemVisibility = !itemVisibility;
        lineProps[selectedItem] = itemVisibility;
      }

      this.setState({lineProps: lineProps});
    };

    if (!this.state.distributions) {
        return "";
    }

    // console.log("attempting to render");
    // console.log(this.state.lineProps);
    // console.log(this.state.curveIndexes);
    // console.log(this.state.selectedTokens);
    return (
      <div>
        <ResponsiveContainer width={width} height={height}>
          <LineChart data={this.state.curveIndexes} 
            margin={UIUtils.getChartMargin(this)}>

            <XAxis xAxisId="0" dataKey={"index"} stroke={textColor} padding={{ top: 10 }} angle={-10}/>
            <YAxis stroke={textColor} />
            <Tooltip labelStyle={ {color: 'black'}} />
            {/* <ReferenceLine y={33.34} stroke="lightgray" label="33.34%" />
            <ReferenceLine y={66.67} stroke="lightgray" label="66.67%" /> */}
            {
              this.state.selectedTokens.map((id) => {
                index++;
                let key = id["value"];
                //console.log(key);
                let data = this.state.distributions[key];
                // console.log(index + " - " + key);
                // console.log(data);
                // console.log(colors[index]);
                return (
                        <Line data={data} type="monotone" key={colors[index]} dataKey={key} strokeWidth={3} connectNulls={false} 
                            hide={this.state.lineProps[key] === false} opacity={Number(this.state.lineHover === id || !this.state.lineHover ? 1 : 0.3)}
                            label={false} dot={false} stroke={colors[index]} />
                )
              })
            }
            (<Legend onClick={selectLine} onMouseOver={handleLegendMouseEnter} onMouseOut={handleLegendMouseLeave} />)
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }

  renderAdditionalLink() {
    // console.log("in renderAdditionalLink");
    return this.doRenderAdditionalLink(this.updateSupplyFilter);
  }

  doRenderAdditionalLink() {
    // console.log("in doRenderAdditionalLink: ", updateSupplyFilter);
    // let supplies = [90, 95, 100];
    // console.log(supplies2);
    // console.log(this.state.allTokens);
    let selectedTopPercentSupply = this.state.selectedTopPercentSupply;
    let selectedAddressType = this.state.selectedAddressType;
    // console.log(selectedTopPercentSupply);
    return <React.Fragment>
                {/* {supplies.map(function (topSupply) {
                let className = "frequencyLink animLink";
                if (topSupply === selectedTopPercentSupply) {
                    className="frequencyLink animLinkSelected animLink";
                }
                return (<button key={"topSupply" + topSupply} onClick={updateSupplyFilter} value={topSupply} 
                    className={className}>{topSupply}%</button>);
                })} */}
                {/* {this.state.includeNamedAccounts === true ? 
                    (<button onClick={this.hideNamedAccounts} className="animLinkMenu animLink" title='Hide named addresses'><PersonAddDisabledIcon /></button>)
                    : (<button onClick={this.showNamedAccounts} className="animLinkMenu animLink" title='Show named addresses'><PersonAddIcon /></button>)} */}
                <Dropdown onSelect={this.updateSupplyFilter} values={SUPPLIES} className="supplyDropdown" defaultValue={selectedTopPercentSupply} size="sm" />
                <Dropdown onSelect={this.updateAddressTypeFilter} values={ADDRESS_TYPES} className="supplyDropdown" defaultValue={selectedAddressType} size="sm" />
                &nbsp;
                <MultiList selected={this.state.selectedTokens} allItems={this.state.allTokens} 
                    setSelected={this.setSelectedTokens}/>
            </React.Fragment>
  }

  // showNamedAccounts() {
  //   let includeNamedAccounts = true;
  //   this.setState({"includeNamedAccounts": includeNamedAccounts, "isLoading": true});
  //   this.updateDistribution(this.state.selectedTokens, includeNamedAccounts);
  // }

  // hideNamedAccounts() {
  //   let includeNamedAccounts = false;
  //   this.setState({"includeNamedAccounts": includeNamedAccounts, "isLoading": true});
  //   this.updateDistribution(this.state.selectedTokens, includeNamedAccounts);
  // }

  updateAddressTypeFilter(e) {
    let selectedAddressType = e.target.value;
    this.setState({"selectedAddressType": selectedAddressType, "isLoading": true});
    this.updateDistribution(this.state.selectedTokens, selectedAddressType);
  }

  updateSupplyFilter(e) {
    let selectedTopPercentSupply = e.target.value;
    this.setState({"selectedTopPercentSupply": parseInt(selectedTopPercentSupply)});
    this.updateDistributionFilter(this.state.orgDistributions, selectedTopPercentSupply);
  }

  updateDistributionFilter(orgDistributions, selectedTopPercentSupply) {
    // console.log("capping at : ", selectedTopPercentSupply);
    // console.log(orgDistributions);
    let newDistributions = {};
    for (var key in orgDistributions) { 
        // console.log(key);
        if (orgDistributions.hasOwnProperty(key)) {           
            let value = orgDistributions[key];
            // console.log(value);
            let distributions = []
            for (let i = 0, tableDataLen = value.length; i < tableDataLen; i++) {
                let record = value[i];
                if (record[key] <= selectedTopPercentSupply) {
                    distributions.push(record);
                }
                // distribution.push({name: record.index, votingPower: record.balance, uniqueDelegates: record.cumulativePercentSupply});
            }

            newDistributions[key] = distributions;
        }
    }

    // console.log(newDistributions);
    this.setState({"distributions": newDistributions, 
        "curveIndexes": NetworkChartUtils.getIndexes(this.state.selectedTokens, newDistributions)}); 
  }
}

export default TokenDistributionComparison;
