import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import ApiUtils from '../util/ApiUtils';
import SessionUtils from '../util/SessionUtils';
import ChartTitle from './ChartTitle';

import './GlobalSearchModal.css';
import Loading from './Loading';

const urlConfig = {
    validator: "/val/{id}",
    token: "/tokens/{id}",
    proposal: "/proposal/{id}",
    address: "/address/{id}"
};

function getUrlByDataType(dataType, idValue) {
    return SessionUtils.getUrl(urlConfig[dataType].replace("{id}", idValue));
}

function GlobalSearchModal({ show, handleClose }) {
    const [searchValue, setSearchValue] = useState('');
    const [error, setError] = useState('');
    const [results, setResults] = useState([]);
    const [searching, setSearching] = useState(false);
    const searchFocus = useRef(null);

    useEffect(() => {
        if (show) {
            searchFocus.current.focus();
        }
    }, [show]);

    const handleSearch = async (event) => {
        event.preventDefault();
        // console.log("Search button clicked");
        if (searchValue.length > 0 && searchValue.length < 128) {
            // const addressPrefix = currentNetwork.details.app.addressPrefix;
            // const valoperPrefix = currentNetwork.details.app.valoperPrefix;
            setError(null);
            await callGlobalSearchAPI(searchValue);
            // console.log(`addressPrefix: ${addressPrefix}, valoperPrefix: ${valoperPrefix}, searchValue: ${searchValue}`);
            // if (searchValue.startsWith(valoperPrefix)) {
            //     window.location.href = SessionUtils.getUrl(`/val/${searchValue}`);
            //     setError('');
            // } else if (searchValue.startsWith(addressPrefix)) {
            //     window.location.href = SessionUtils.getUrl(`/address/${searchValue}`);
            //     setError('');
            // } else {
            //     await callGlobalSearchAPI(searchValue);
            // }
        } else {
            setError("Invalid input. Please enter valid search criteria.");
        }
    };

    const callGlobalSearchAPI = async (text) => {
        const escapedText = encodeURIComponent(text);
        try {
            let url = `listData?type=globalSearch&dataType=globalSearch&searchText=${escapedText}`;
            setSearching(true);
            const response = await ApiUtils.get(url);
            // console.log(response);
            const searchResults = response.results;
            if (searchResults.length === 0) {
                setError("No results found for the entered search criteria.");
            } else if (searchResults.length === 1) {
                const singleResult = searchResults[0];
                window.location.href = getUrlByDataType(singleResult.dataType, singleResult.id);
            } else {
                setResults(searchResults);
            }
        } catch (error) {
            console.error('Error fetching search data:', error);
            setError("Failed to perform search. Please try again.");
        }
        setSearching(false);
    };


    return (
        <Modal show={show} onHide={handleClose} className="delegateWithUs">
            <Modal.Header closeButton>
                <Modal.Title>Search</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSearch}>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Control type="text" placeholder="" onChange={e => setSearchValue(e.target.value)} ref={searchFocus} />
                        {error && <Form.Text className='notification'>{error}</Form.Text>}
                        <Form.Text className="text-muted">
                            Enter Validator Name, Valoper Address, Wallet Address, Token, Denom, or Proposal #
                        </Form.Text>
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        Search
                    </Button>
                </Form>
                {searching === true && <Loading />}
                {results.length > 0 && (
                    <React.Fragment>
                        <br/>
                        <ChartTitle title="Search Results" />
                        <div className="tableContainer">
                            <table striped bordered hover id="searchResultsTable">
                                <thead>
                                    <tr>
                                        <th>Result</th>
                                        <th>Result Type</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {results.map((result, index) => (
                                        <tr key={index}>
                                            <td><a className="animLinkVisual" href={getUrlByDataType(result.dataType, result.id)}>{result.description}</a></td>
                                            <td>{result.dataType}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </React.Fragment>
                )}
            </Modal.Body>
        </Modal>
    );
}

export default GlobalSearchModal;
