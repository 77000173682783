import React from "react";
import {ResponsiveContainer, XAxis, YAxis, CartesianGrid, Tooltip, Label, LabelList } from 'recharts';
import {BarChart, Bar } from 'recharts';

import UIUtils from '../util/UIUtils';
import Utilities from '../util/Utilities';
import ChartUtils from '../util/ChartUtils';
// import currentNetwork from "../currentNetwork";
import ChartTitle from "../components/ChartTitle";
import common from "../common";
import DownloadUtils from "../util/DownloadUtils";
import SPCalc from "../util/SPCalc";
import currentNetwork from "../currentNetwork";
import ChartData from './ChartData';

class BaseMultiBarChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      responsive: true,
      mode: common.charts.mode.default
    }

    this.flipResponsive = this.flipResponsive.bind(this);
    this.switchMode = this.switchMode.bind(this);
    this.truncDate = this.truncDate.bind(this);
  }

  flipResponsive() {
    ChartUtils.flipResponsive(this);
  }

  renderBar() {
    let textColor = common.colors.text;//"white";
    let colors = common.colors.chartColorsArray;
    let lineColor = colors[0];
    let lineColor2 = colors[1];
    let lineColor3 = colors[2];

    // let lineColor =  common.colors.chartColorsArray[1]; //common.colors.chartColor1
    // let lineColor2 = common.colors.chartColorsArray[0]; //common.colors.chartColor1
    // let lineColor3 = common.colors.chartColorsArray[2]; //common.colors.chartColor1
    // let lineColor = "#00bcd4"; // common.colors.chartColorsArray[1];
    // let lineColor2 = "#ffeb3b"; //common.colors.chartColorsArray[0];
    // let lineColor3 = "#e91e63"; //common.colors.chartColorsArray[2];
	  
    let width = UIUtils.getResponsiveWidth(this);
    // <Label value={this.props.xAxis} offset={-8} position="insideBottom"/>
    let height = UIUtils.getChartHeight(this);
    let showDataLabel = UIUtils.getShowDataLabel(this, this.props.data, 600);

    const CustomTooltip = ({ active, payload, label }) => {
      if (active) {
        if (payload === null || payload.length === 0) {
          return null;
        }

        let data = payload[0].payload;
        let multiLines = this.props.value3Attr != null ? 3 : 2;

        return (<div style={{"margin": "0px", "padding": "0px"}}>
            <table><tbody>
              <tr>
                <td>{this.props.xAxis}:</td>
                <td>{data["title"]}</td>
              </tr>
              <tr>
                  <td><font color={lineColor}>{this.props.value1Attr}:</font></td>
                  <td><font color={lineColor}>{data[this.props.value1Attr]}</font></td>
              </tr>
              <tr>
                  <td><font color={lineColor2}>{this.props.value2Attr}:</font></td>
                  <td><font color={lineColor2}>{data[this.props.value2Attr]}</font></td>
              </tr>
              {multiLines && <tr>
                  <td><font color={lineColor3}>{this.props.value3Attr}:</font></td>
                  <td><font color={lineColor3}>{data[this.props.value3Attr]}</font></td>
              </tr>}
            </tbody></table>
          </div>);
      }

      return null;
    };
    // margin={{top: 10, right: 0, left: 5, bottom: 20}}>

    let domainRange = Utilities.getCombinedRange(true, this.props.data, this.props.value1Attr, this.props.value2Attr, 100);
    let multiLines = this.props.value3Attr != null ? 3 : 2;

    return (
      <div>
        <ResponsiveContainer width={width} height={height}>
          <BarChart data={this.props.data} barCategoryGap={10}
              margin={{top: 20, right: 5, left: 15, bottom: 15}}>
            <CartesianGrid vertical={false} horizontal={false} strokeDasharray="3 3"/>
            <XAxis dataKey={this.props.xAxisValueAttr} stroke={textColor} angle={-10}>
              {this.props.hideAxisTitle === true ? "" : <Label value={this.props.xAxis} offset={-3} position="insideBottom" />}
            </XAxis>
            <YAxis tickFormatter={SPCalc.formatNumberValue} stroke={textColor} domain={domainRange}>
              {this.props.hideAxisTitle === true ? "" : <Label value={this.props.yAxis} offset={10} position="insideBottomLeft" angle={-90} />}
            </YAxis>
            <YAxis tickFormatter={SPCalc.formatNumberValue} stroke={textColor} domain={domainRange}>
              {this.props.hideAxisTitle === true ? "" : <Label value={this.props.yAxis} offset={10} position="insideBottomLeft" angle={-90} />}
            </YAxis>
            <Tooltip content={<CustomTooltip />} />
            <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
            {showDataLabel && <Bar maxBarSize={70} dataKey={this.props.value1Attr} stroke={lineColor} fill={lineColor} onClick={this.barOnClick}>
              <LabelList dataKey={this.props.valueAttr} position="top" /> 
            </Bar>}
            {!showDataLabel && <Bar maxBarSize={70} dataKey={this.props.value1Attr} stroke={lineColor} fill={lineColor} onClick={this.barOnClick} /> }
            
            {showDataLabel && <Bar maxBarSize={70} dataKey={this.props.value2Attr} stroke={lineColor2} fill={lineColor2} onClick={this.barOnClick}>
              <LabelList dataKey={this.props.value2Attr} position="top" /> 
            </Bar>}
            {!showDataLabel && <Bar maxBarSize={70} dataKey={this.props.value2Attr} stroke={lineColor2} fill={lineColor2} onClick={this.barOnClick} /> }

            {multiLines && showDataLabel && <Bar maxBarSize={70} dataKey={this.props.value3Attr} stroke={lineColor3} fill={lineColor3} onClick={this.barOnClick}>
              <LabelList dataKey={this.props.value3Attr} position="top" /> 
            </Bar>}
            {multiLines && !showDataLabel && <Bar maxBarSize={70} dataKey={this.props.value3Attr} stroke={lineColor3} fill={lineColor3} onClick={this.barOnClick} />}
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }

  render () {
    let tableInd = this.state.mode === common.charts.mode.table;
    let chartVisualId = currentNetwork.details.networkName + "-" + (tableInd ? "" : "data") + this.props.chartVisualId;
    const downloadChart = () => {
      ChartUtils.downloadChart(document, chartVisualId, currentNetwork.details.app.title);
    };

    return (
      <div id={"div" + chartVisualId}>
        <ChartTitle title={this.props.title} tip={this.props.tip} handleChart={this.props.handleChart} disableDay={this.props.disableDay} 
          statType={this.props.statType} isOverallOnly={this.props.isOverallOnly} disableWeek={this.props.disableWeek} 
          addDays={this.props.addDays} displayHelpIcon={this.props.displayHelpIcon} fsElementId={this.props.fsElementId}
          frequency={this.props.frequency} showHelp={this.showHelp} frequencies={this.props.frequencies} shortTitle={this.props.shortTitle}
          downloadData={DownloadUtils.getDownloadDataForChart(this.props.data, this.props.xAxisValueAttr, this.props.valueAttr)}
          saveChart={downloadChart} enableSave={this.props.enableSave}
          mode={this.state.mode} switchMode={this.switchMode} columns={this.props.columns}
        />
        <div align="center">
          {this.props.showTotalLabel ? Utilities.getTotalWithLabel(this.props.data, this.props.valueAttr, this.props.totalLabel) : ""}
          {tableInd ? <ChartData data={this.props.data} columns={this.props.columns} dataKey={this.props.dataKey} sortBy={this.props.sortBy}/> : this.renderBar()}
        </div>
        {ChartUtils.getLandscapeMsg()}
      </div>
    );
  }
  // <p>Data for the latest window (hour or date) is partial. All times are in GMT.</p>

  truncDate(val) {
    return ChartUtils.truncDate(val, this.props.chartType, this.props.filter)
  }

  switchMode() {
    let currentMode = this.state.mode;
    if (currentMode === common.charts.mode.chart) {
      this.setState({mode: common.charts.mode.table});
    } else {
      this.setState({mode: common.charts.mode.chart});
    }
  }
}

export default BaseMultiBarChart;
