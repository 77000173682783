import React from 'react';
import {Col} from 'react-bootstrap';

import Stat from '../components/Stat';
import tooltips from '../tooltips';
import SPUtilities from '../util/SPUtilities';

class MaticNetworkHeader extends React.Component {
  render() {
    return (
      <React.Fragment>
            <Col className="statColLayout" xs={6} sm={4} md={3} lg={3}>
                <Stat label={"Running Benchmark/Median Performance"} value={this.props.coinStat.extraData4
                    + "/" + this.props.coinStat.extraData3} 
                    link={"https://wiki.polygon.technology/docs/maintain/validate/validator-performance/"}
                    info={tooltips.matic.maticBenchmarkPerfMedian.replace("BUFFER", this.props.coinStat.extraData6)} />
            </Col>
            <Col className="statColLayout" xs={6} sm={4} md={3} lg={3}>
                <Stat label={"# of Validators below Benchmark"} value={this.props.coinStat.extraData5} 
                    info={tooltips.matic.validatorsBelowBenchmark} />
            </Col>
            <Col className="statColLayout" xs={6} sm={4} md={3} lg={3}>
                <Stat label={"Last Checkpoint #/Time"} value={this.props.coinStat.extraData1 + " / " + 
                  SPUtilities.getTimeInPastMinutes(this.props.coinStat.lastCheckpointTimeSec)} 
                  info={tooltips.matic.lastCheckpointNumberAndTime} />
            </Col>
      </React.Fragment>
    );
  }
/*
  extraData
  	1 - checkpointNum
    2 - checkpointTime, 
    3 - medianPerf,
		4 - benchmarkPerf
    5 - belowBenchmarkCount
    6 - 95 - MATIC_PERF_BENCHMARK_BUFFER
 */
}

export default MaticNetworkHeader;
