import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import ReactTooltip from "react-tooltip";
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import Tour from 'reactour';

import ValColumnSelector from "./ValColumnSelector";
import Contact from "../base/Contact";
import UITableUtils from "../util/UITableUtils";
import CollapsibleNote from "../base/CollapsibleNote";

import NetworkHeader from "../network/NetworkHeader";
import UIUtils from "../util/UIUtils";
import ApiUtils from '../util/ApiUtils';
import "./Validators.css";
import '../styles/section.css';
import '../styles/tables.css';
import '../styles/validator.css';
import Utilities from "../util/Utilities";
import FavUtils from "../util/FavUtils";

import MainUtils from "../main/MainUtils";
import LastUpdated from '../components/LastUpdated';
import CoinUtils from '../util/CoinUtils';
import SignFmtUtils from '../validator/SignFmtUtils';
import currentNetwork from '../currentNetwork';
import SavedSettings from '../util/SavedSettings';
import ValidatorUtils from './ValidatorUtils';
import StakeUtils from '../util/StakeUtils';
import MainNotes from '../main/MainNotes';
import common from '../common';
import MaticValidatorUtils from '../matic/MaticValidatorUtils';
import ChainUtils from '../util/ChainUtils';
import ICSUtils from '../util/ICSUtils';
import SessionUtils from '../util/SessionUtils';
import constants from '../constants';
import IntegrationUtils from '../util/IntegrationUtils';
import Tips from '../components/Tips';
import AddressUtils from '../util/AddressUtils';
import DownloadUtils from '../util/DownloadUtils';
import UrlUtils from '../util/UrlUtils';
import DataUtils from '../util/DataUtils';
import viewData from '../viewData';
import MediaUtils from '../util/MediaUtils';
import ColumnUtils from '../util/ColumnUtils';
import RespUtils from '../util/RespUtils';
import SPUtilities from '../util/SPUtilities';

class Validators extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      network: null,
      error: null,
      validators: [],
      orgValidators: [],
      coinStat: {},
      statusSummary: {},
      params: {},
      notification: {"message": null},
      viewName: null,
      moreView: common.moreViews.hidden,
      tip: null,
      width: window.innerWidth,
      size: 10,
      lastUpdated: "",
      isLoading: true,
      isHelpOpen: false,
      status: null,
    }
    // networkError: false,

    this.updateDimensions = this.updateDimensions.bind(this);
    this.reload = this.reload.bind(this);
    this.favourite = this.favourite.bind(this);
    this.unfavourite = this.unfavourite.bind(this);
    this.getInitColumns = this.getInitColumns.bind(this);
    this.repaintData = this.repaintData.bind(this);
    this.doRepaintData = this.doRepaintData.bind(this);
    this.closeHelp = this.closeHelp.bind(this);
    this.showHelp = this.showHelp.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.handleViewChange = this.handleViewChange.bind(this);
    this.toggleMoreView = this.toggleMoreView.bind(this);
  }

  updateDimensions() {
    this.setState({width: window.innerWidth});
  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  async componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    console.log("validators: calling session utils set network from validators page");
    SessionUtils.initNetwork(this, true);

    if (!currentNetwork.details) {
      console.log(currentNetwork.details);
      this.setState({networkError: true});
    }

    let url = "listData?type=listPools";
    let status = null;
    if (this.props.match && this.props.match.params.status) {
      url += "&status=" + this.props.match.params.status;
      status = this.props.match.params.status;
    } else if (currentNetwork.details.app.consumerChain === true) {
      url += "&status=hasToValidate";
      status = "hasToValidate";
    } else if (currentNetwork.details.app.tendermint === true) {
      url += "&status=" + currentNetwork.details.app.defaultValidatorViewStatus;
      status = currentNetwork.details.app.defaultValidatorViewStatus;
    } else if (currentNetwork.details.app.token === 'MATIC') {
      url += "&status=" + currentNetwork.details.app.defaultValidatorViewStatus;
      status = currentNetwork.details.app.defaultValidatorViewStatus;
    } 
    // console.log(url);

    const allData = await ApiUtils.get(url);
    // console.log(allData);
    if (!allData) {
      this.setState({error: true, isLoading: false});
      return;
    }

    let validators = allData["data"];
    let lastUpdated = allData["lastUpdated"];
    if (validators) {

      let notification = allData["notification"];
      let statusSummary = allData["statusSummary"];
      let coinStat = allData["coinStat"];
      let viewName = UrlUtils.getFragmentFromUrl();
      this.doRepaintData(validators);
      this.setState({lastUpdated: lastUpdated, notification: notification,
        statusSummary: statusSummary, orgValidators: validators, coinStat: coinStat,
        params: allData["params"], tip: allData["tip"], "viewName": viewName});
    }

    this.setState({isLoading: false, status: status, isHelpOpen: SavedSettings.isValHelpNeeded(this)});
  }
  
  handleFilter({ criteria, searchText }) {
    const { orgValidators } = this.state;
    let filteredValidators = null;
    if (!searchText || searchText === "") {
      // console.log(`searchText: ${searchText}`);
      filteredValidators = orgValidators;
    } else {
      filteredValidators = orgValidators.filter(validator => {
        switch (criteria) {
            case common.ui.validatorCriteria.COMMISSION_LOWER_THAN:
                // console.log(`${validator.name} - parseFloat(validator.commission): ${validator.commission} < parseFloat(searchText): ${searchText}`)
                return parseFloat(validator.commission) < parseFloat(searchText);
            case common.ui.validatorCriteria.MAX_COMMISSION_LOWER_THAN:
                return parseFloat(validator.commissionMaxRate) < parseFloat(searchText);
            case common.ui.validatorCriteria.MAX_COMMISSION_CHANGE_RATE_LOWER_THAN:
                return parseFloat(validator.commissionMaxChangeRate) < parseFloat(searchText);
            case common.ui.validatorCriteria.VOTING_POWER_PERCENTAGE_LESS_THAN:
                return parseFloat(validator.votingPowerPercentage) < parseFloat(searchText);
            case common.ui.validatorCriteria.VALIDATOR_NAME:
                return validator.name.toLowerCase().includes(searchText.toLowerCase());
            case common.ui.validatorCriteria.VALIDATOR_OPERATOR_ADDRESS:
                return validator.operatorAddress.toLowerCase().includes(searchText.toLowerCase());
            case common.ui.validatorCriteria.SEARCH_ALL:
              // Filter data based on the search term
              // const filtered = data.filter(item => {
              return Object.values(validator).some(val =>
                  val != null && val.toString().toLowerCase().includes(searchText.toLowerCase())
              );
            default:
                return true;
        }
      });
    }

    this.setState({
      validators: filteredValidators,
      currentFilter: criteria,
      filterValue: searchText
    });
  }

  repaintData() {
    this.doRepaintData(this.state.orgValidators);
  }

  doRepaintData(validators) {
    // console.log("in repaintData");
    // let validators = this.state.orgValidators;
    // let newValidators = UIUtils.addIndexAndFav(validators, "poolId");
    // let finalData = FavUtils.filterData(this, newValidators);
    let newValidators = JSON.parse(JSON.stringify(validators));

    newValidators = UIUtils.addIndexAndFav(newValidators, "poolId");
    // DataUtils.addCumulativeWeight(newValidators, "stakeWeight");

    let cumulativeWeight = 0
    for(let i=0;i < newValidators.length; i++) {
      let val = newValidators[i];
      cumulativeWeight += val.votingPowerPercentage;
      val.cumulativeWeight = cumulativeWeight;
      val.commissionPlus = val.commission;
    }

    let finalData = FavUtils.filterData(this, newValidators);

    this.setState({"validators": finalData});
  }

  unfavourite(id) {
    FavUtils.unfavouriteById(id, this);
  }

  favourite(id) {
    FavUtils.favouriteById(id, this);
  }

  render() {
    SessionUtils.initNetwork(this, false);

    let loadMsg = UIUtils.getLoading(this);
    if (loadMsg) return loadMsg;

    const headerSortingClasses = (column, sortOrder, isLastSorting, colIndex) => (
      sortOrder === 'asc' ? 'sorting-asc' : 'sorting-desc'
    );
    
    let token = currentNetwork.details.app.token;
    var columns;

    if (ChainUtils.isMatic()) {
      columns = MaticValidatorUtils.getColsForValidators(this, headerSortingClasses);
    } else {
      columns = [
        {text: "Fav", desc: "Favorite indicator", hidden: this.isHidden("fav"), dataField: "fav", sort: true, formatter: FavUtils.favoriteFormatter, align: "left", formatExtraData: this, headerStyle: Utilities.respWidth(5, 5, 2), headerSortingClasses},
        {text: "#", desc: "VP Rank", hidden: this.isHidden("vpRank"), dataField: "vpRank", sort: true, align: "left", headerStyle: Utilities.respWidth(5, 5, 2), headerSortingClasses},
        {text: "", filter: textFilter({placeholder: "Name"}), desc: "Validator Name", hidden: this.isHidden("name"), dataField: "name", formatter: MainUtils.nameFormatterTableWithLogo, sort: true, headerStyle: Utilities.respWidth(45, 25, 15), headerSortingClasses},
        {text: "Badges",  desc: "Badges", hidden: this.isHidden("ibcRelayerInd"), dataField: "ibcRelayerInd", formatter: MainUtils.formatBadges, sort: true, headerStyle: Utilities.respWidth(15, 15, 6), headerSortingClasses},
        {text: "Status",  desc: "Validator Status", hidden: this.isHidden("status"), dataField: "status", formatter: MainUtils.statusFormatter, sort: true, headerStyle: Utilities.width(6), headerSortingClasses},
        {text: "Self Delegation",  desc: ("Total " + token + " Staked by the validator"), hidden: this.isHidden("selfBond"), dataField: "selfBond", formatter: CoinUtils.stakeFormatterRounded, sort: true, headerStyle: Utilities.width(8), headerSortingClasses},
        {text: "Self Delegation %",  desc: "% of total voting power that is self staked by the validator", hidden: this.isHidden("selfBondPercentage"), dataField: "selfBondPercentage", formatter: MainUtils.percentFormatter, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(8), headerSortingClasses},
        {text: "Voting Power (VP)",  desc: ("Total " + token + " Staked with validator"), hidden: this.isHidden("votingPower"), dataField: "votingPower", formatter: CoinUtils.stakeFormatterRounded, sort: true, headerStyle: Utilities.width(7), headerSortingClasses},
        {text: "% Voting Power",  desc: "% of total voting power that is with the validator", hidden: this.isHidden("votingPowerPercentage"), dataField: "votingPowerPercentage", formatter: UITableUtils.vpProgressFormatter, formatExtraData: this, style: UITableUtils.vpProgressStyle, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(10), headerSortingClasses},
        {text: Utilities.respLabel("Comm/ Max/ Change", "Comm/ Max/ Change", "Comm/ Max Comm/ Max Change"), desc: "Current commission/Max Commission/Max Daily Commission Change Rate", hidden: this.isHidden("commissionPlus"), dataField: "commissionPlus", formatter: MainUtils.getCommissionPlus, formatExtraData: true, style: MainUtils.commissionStyleFormatter, sort: true, headerStyle: Utilities.respWidth(45, 25, 12), headerSortingClasses},
        {text: Utilities.respLabel("Comm", "Comm", "Commission"), desc: "Current commission charged by validator", hidden: this.isHidden("commission"), dataField: "commission", formatter: MainUtils.getCommission, style: MainUtils.commissionStyleFormatter, sort: true, headerStyle: Utilities.respWidth(20, 12, 7), headerSortingClasses},
        {text: "Max Comm.",  desc: "Maximum commission ever charged by validator", hidden: this.isHidden("commissionMaxRate"), dataField: "commissionMaxRate", formatter: MainUtils.percentFormatter, sort: true, headerStyle: Utilities.width(8), headerSortingClasses},
        {text: "Comm. Max Change Rate",  desc: "Daily max change rate for commission", hidden: this.isHidden("commissionMaxChangeRate"), dataField: "commissionMaxChangeRate", formatter: MainUtils.percentFormatter, sort: true, headerStyle: Utilities.width(8), headerSortingClasses},
        {text: "Unique Delegates",  desc: "Total number of unique delegates", hidden: this.isHidden("uniqueDelegates"), dataField: "uniqueDelegates", formatter: StakeUtils.formatDelegateLinkForCount, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(6), headerSortingClasses},
        {text: "Average Delegation",  desc: "Average Delegation of all delegates for the validator", hidden: this.isHidden("averageDelegation"), dataField: "averageDelegation", formatter: StakeUtils.formatAverageDelegation, formatExtraData: true, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(12), headerSortingClasses},
        {text: "VP Change (1d)",  desc: "Change in voting power in 1 day", hidden: this.isHidden("votingPowerChange1d"), dataField: "votingPowerChange1d", formatter: StakeUtils.formatVPChangeLink, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(9), headerSortingClasses},
        {text: "VP Change (7d)",  desc: "Change in voting power in 7 days", hidden: this.isHidden("votingPowerChange7d"), dataField: "votingPowerChange7d", formatter: StakeUtils.formatVPChangeLink, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(9), headerSortingClasses},
        {text: "VP Change (30d)",  desc: "Change in voting power in 30 days", hidden: this.isHidden("votingPowerChange30d"), dataField: "votingPowerChange30d", formatter: StakeUtils.formatVPChangeLink, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(9), headerSortingClasses},
        {text: "Delegate Change (1d)",  desc: "Change in number of unique delegates in 1d", hidden: this.isHidden("uniqueDelegatesChange1d"), dataField: "uniqueDelegatesChange1d", formatter: StakeUtils.formatDelegateChangeLink, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(9), headerSortingClasses},
        {text: "Delegate Change (7d)",  desc: "Change in number of unique delegates in 7d", hidden: this.isHidden("uniqueDelegatesChange7d"), dataField: "uniqueDelegatesChange7d", formatter: StakeUtils.formatDelegateChangeLink, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(9), headerSortingClasses},
        {text: "Delegate Change (30d)",  desc: "Change in number of unique delegates in 30d", hidden: this.isHidden("uniqueDelegatesChange30d"), dataField: "uniqueDelegatesChange30d", formatter: StakeUtils.formatDelegateChangeLink, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(9), headerSortingClasses},
        {text: Utilities.respLabel("Recent Voting", "Recent Voting", "Recent Voting"), desc: "Recent governance participation (voting)", hidden: this.isHidden("voteParticipation"), dataField: "voteParticipation", formatter: ValidatorUtils.formatVoting, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(6), headerSortingClasses},
        {text: Utilities.respLabel("Overall Voting", "Overall Voting", "Governance Participation Overall"), desc: "Governance participation in all tracked votes", hidden: this.isHidden("voteParticipationOverall"), dataField: "voteParticipationOverall", formatter: ValidatorUtils.formatVotingOverall, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(7), headerSortingClasses},
        {text: ("Pending Undelegation (" + token + ")"),  desc: "Total amount of pending undelegations", hidden: this.isHidden("totalUndelegationAmount"), dataField: "totalUndelegationAmount", formatter: StakeUtils.formatDelegateLinkForAmount, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(11), headerSortingClasses},
        {text: "Pending Undelegation Count",  desc: "Total number of pending undelegations", hidden: this.isHidden("uniqueUndelegations"), dataField: "uniqueUndelegations", formatter: StakeUtils.formatDelegateLinkForCount, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(11), headerSortingClasses},
        {text: "Jailed*",  desc: "Number of times validator got jailed (since data capture started)", hidden: this.isHidden("timesSlashed"), dataField: "timesSlashed", sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(8), headerSortingClasses},
        {text: "Latest Jailed Time",  desc: "Latest Jailed Time", hidden: this.isHidden("latestSlashedTime"), dataField: "latestSlashedTime", formatter: SPUtilities.epochFormatter, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(8), headerSortingClasses},
        
        {text: "Current Sign %",  desc: "Block signature % in last 8 hours", hidden: this.isHidden("currentPerSigned"), dataField: "currentPerSigned", formatter: SignFmtUtils.currentPerSignedFmt, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(15, 15, 9), headerSortingClasses},
        {text: "Current Signed",  desc: "Blocks signed in last 8 hours", hidden: this.isHidden("currentSigned"), dataField: "currentSigned", formatter: SignFmtUtils.currentSignedFmt, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(15, 15, 9), headerSortingClasses},
        {text: "Current Missed",  desc: "Blocks missed in last 8 hours", hidden: this.isHidden("currentMissed"), dataField: "currentMissed", formatter: MainUtils.currentMissedFmt, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(15, 15, 9), headerSortingClasses},
        {text: "Current Oracle Commit %",  desc: "Oracle Commit % in last 17 hours", hidden: this.isHidden("currentOraclePerSigned"), dataField: "currentOraclePerSigned", formatter: SignFmtUtils.currentPerSignedFmt, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(7), headerSortingClasses},
        {text: "Current Oracle Missed",  desc: "Oracle Commit missed in last 17 hours", hidden: this.isHidden("currentOracleMissed"), dataField: "currentOracleMissed", formatter: MainUtils.currentMissedFmt, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(10), headerSortingClasses},

        {text: "Daily Sign %",  desc: "Block signature % in last 24 hours", hidden: this.isHidden("dailyPerSigned"), dataField: "dailyPerSigned", formatter: SignFmtUtils.currentPerSignedFmt, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(9), headerSortingClasses},
        {text: "Daily Signed",  desc: "Blocks signed in last 24 hours", hidden: this.isHidden("dailySigned"), dataField: "dailySigned", formatter: SignFmtUtils.currentSignedFmt, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(9), headerSortingClasses},
        {text: "Daily Sign Missed",  desc: "Blocks missed in last 24 hours", hidden: this.isHidden("dailyMissed"), dataField: "dailyMissed", formatter: MainUtils.currentMissedFmt, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(9), headerSortingClasses},
        {text: "Daily Oracle Commit %",  desc: "Oracle commits % in last 24 hours", hidden: this.isHidden("dailyOraclePerSigned"), dataField: "dailyOraclePerSigned", formatter: SignFmtUtils.currentPerSignedFmt, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(9), headerSortingClasses},
        {text: "Daily Oracle Missed",  desc: "Oracle commits missed in last 24 hours", hidden: this.isHidden("dailyOracleMissed"), dataField: "dailyOracleMissed", formatter: MainUtils.currentMissedFmt, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(9), headerSortingClasses},

        {text: "10D Sign %",  desc: "Block signature % in last 10 days", hidden: this.isHidden("midPerSigned"), dataField: "midPerSigned", formatter: SignFmtUtils.currentPerSignedFmt, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(9), headerSortingClasses},
        {text: "10D Signed",  desc: "Blocks signed in last 10 days", hidden: this.isHidden("midSigned"), dataField: "midSigned", formatter: SignFmtUtils.currentSignedFmt, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(9), headerSortingClasses},
        {text: "10D Sign Missed",  desc: "Blocks missed in last 10 days", hidden: this.isHidden("midMissed"), dataField: "midMissed", formatter: MainUtils.currentMissedFmt, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(9), headerSortingClasses},
        {text: "10D Oracle Commit %",  desc: "Oracle commits % in last 10 days", hidden: this.isHidden("midOraclePerSigned"), dataField: "midOraclePerSigned", formatter: SignFmtUtils.currentPerSignedFmt, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(9), headerSortingClasses},
        {text: "10D Oracle Missed",  desc: "Oracle commits missed in last 10 days", hidden: this.isHidden("midOracleMissed"), dataField: "midOracleMissed", formatter: MainUtils.currentMissedFmt, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(9), headerSortingClasses},

        {text: this.getSlashingMissedTitle(),  desc: "Slashing Window Missed Blocks", hidden: this.isHidden("slashingWindowMissedBlocks"), dataField: "slashingWindowMissedBlocks", sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(25, 15, 9), headerSortingClasses},
        {text: this.getSlashingUptimeTitle(),  desc: "Slashing Window Uptime in %age", hidden: this.isHidden("slashingWindowUptime"), dataField: "slashingWindowUptime", formatter: SignFmtUtils.slashingWindowSignPerFmt, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(25, 15, 8), headerSortingClasses},

        {text: "Avg Sign %",  desc: "Block signature % in last 30 days", hidden: this.isHidden("longPerSigned"), dataField: "longPerSigned", formatter: SignFmtUtils.longPerSignedFmt, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 7), headerSortingClasses},
        {text: "Missed Oracle Votes",  desc: "Missed Oracle Votes", hidden: this.isHidden("oracleMissedVotes"), dataField: "oracleMissedVotes", sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(15, 15, 9), headerSortingClasses},
        {text: currentNetwork.details.ui.validator.extra1Label,  desc: currentNetwork.details.ui.validator.extra1Label, hidden: this.isHidden("extraData1"), dataField: "extraData1", sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(15, 15, 9), headerSortingClasses},
        {text: currentNetwork.details.ui.validators.extraData3Label,  desc: currentNetwork.details.ui.validators.extraData3LabelDesc, hidden: this.isHidden("extraData3"), dataField: "extraData3", sort: true, headerStyle: Utilities.respWidth(15, 15, 7), headerSortingClasses},

        {text: "Avg Signed",  desc: "Blocks signed in last 30 days", hidden: this.isHidden("longSigned"), dataField: "longSigned", formatter: SignFmtUtils.longSignedFmt, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(11), headerSortingClasses},
        {text: "Avg Sign Missed",  desc: "Blocks missed in last 30 days", hidden: this.isHidden("longMissed"), dataField: "longMissed", formatter: MainUtils.currentMissedFmt, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(9), headerSortingClasses},

        {text: "Avg Oracle %",  desc: "Oracle commit % in last 30 days", hidden: this.isHidden("longOraclePerSigned"), dataField: "longOraclePerSigned", formatter: SignFmtUtils.longPerSignedFmt, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
        {text: "Avg Oracle Missed",  desc: "Oracle Commit missed in last 30 days", hidden: this.isHidden("longOracleMissed"), dataField: "longOracleMissed", formatter: MainUtils.currentMissedFmt, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(9), headerSortingClasses},

        {text: "Net APR / APY (%)",  desc: "Net APR over last 30 days", hidden: this.isHidden("longNetApr"), dataField: "longNetApr", formatter: ValidatorUtils.aprFormatter, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(25, 15, 7), headerSortingClasses},

        {text: "Has To Validate?",  desc: "Has To Validate?", hidden: this.isHidden("hasToValidate"), dataField: "hasToValidate", formatter: ICSUtils.formatHasToValidate, sort: true, headerStyle: Utilities.respWidth(20, 12, 8), headerSortingClasses},
        {text: "ICS Slashing Window Uptime",  desc: "Validator Uptime in related ICS/Replicated Security Chains", hidden: this.isHidden("icsPerf"), dataField: "icsPerf", formatter: ICSUtils.formatICSPerf, sort: true, sortFunc: ICSUtils.sortIcsPerf, headerStyle: Utilities.respWidth(25, 15, 12), headerSortingClasses},
        {text: "ICS Current Per Signed",  desc: "Validator sign rate in last 8 hours", hidden: this.isHidden("icsCurrentPerSigned"), dataField: "icsCurrentPerSigned", formatter: ICSUtils.formatICSSignRate, sort: true, sortFunc: ICSUtils.sortIcsPerSigned, headerStyle: Utilities.respWidth(25, 15, 12), headerSortingClasses},

        {text: "Historical Sign %", desc: "Block signature % in the last 90 days", hidden: this.isHidden("historicalPerSigned"), dataField: "historicalPerSigned", formatter: SignFmtUtils.longPerSignedFmt, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
        {text: "Historical Signed", desc: "Blocks signed in the last 90 days", hidden: this.isHidden("historicalSigned"), dataField: "historicalSigned", formatter: SignFmtUtils.longSignedFmt, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
        {text: "Historical Missed", desc: "Blocks missed in the last 90 days", hidden: this.isHidden("historicalMissed"), dataField: "historicalMissed", formatter: SignFmtUtils.longSignedFmt, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},

        {text: "Orchestrator Address",  desc: "Orchestrator Address", hidden: this.isHidden("orchAddress"), dataField: "orchAddress", formatter: AddressUtils.addressFormatterLinkNoCopy, formatExtraData: true, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
        {text: "Orchestrator Balance",  desc: "Orchestrator balance", hidden: this.isHidden("orchAddressBalance"), dataField: "orchAddressBalance", formatter: AddressUtils.orchAddressBalanceFormatter, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
        {text: "ETH Address",  desc: "ETH Address", hidden: this.isHidden("ethAddress"), dataField: "ethAddress", formatter: AddressUtils.ethAddressFormatter, formatExtraData: true, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
        {text: "ETH Balance",  desc: "ETH balance", hidden: this.isHidden("ethAddressBalance"), dataField: "ethAddressBalance", formatter: AddressUtils.ethAddressBalanceFormatter, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},

        {text: "Operator Address", desc: "Operator Address", hidden: this.isHidden("operatorAddress"), dataField: "operatorAddress", formatter: ValidatorUtils.opAddressFormatter, sort: true, headerStyle: Utilities.respWidth(25, 15, 10), headerSortingClasses},
        {text: "Consensus Node Address", desc: "Consensus Node Address", hidden: this.isHidden("consensusNodeAddress"), dataField: "consensusNodeAddress", formatter: AddressUtils.addressFormatterShortWithCopy, sort: true, headerStyle: Utilities.respWidth(25, 15, 10), headerSortingClasses},
        {text: "Hex Address", desc: "Hex Address", hidden: this.isHidden("hexAddress"), dataField: "hexAddress", formatter: AddressUtils.addressFormatterShortWithCopy, sort: true, headerStyle: Utilities.respWidth(25, 15, 10), headerSortingClasses},
        {text: "Public Key", desc: "Public Key", hidden: this.isHidden("publicKey"), dataField: "publicKey", formatter: AddressUtils.addressFormatterShortWithCopy, sort: true, headerStyle: Utilities.respWidth(25, 15, 10), headerSortingClasses},
        {text: "Address", desc: "Address", hidden: this.isHidden("address"), dataField: "address", formatter: AddressUtils.addressFormatterByLabelShort, sort: true, headerStyle: Utilities.respWidth(25, 15, 10), headerSortingClasses},
        {text: "Media", desc: "Contact Info for validator", hidden: this.isHidden("website"), dataField: "website", formatter: MediaUtils.valMediaFormatter, sort: true, headerStyle: Utilities.respWidth(10, 8, 5), headerSortingClasses},
        {text: "Description", desc: "Description", hidden: this.isHidden("description"), dataField: "description", sort: true, headerStyle: Utilities.respWidth(45, 45, 25), headerSortingClasses},
        {text: "Security Contact", desc: "Security Contact", hidden: this.isHidden("securityContact"), dataField: "securityContact", sort: true, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},

        {text: "Stake",  desc: "Staking tools available", hidden: this.isHidden("delegate"), dataField: "delegate", sort: false, formatter: IntegrationUtils.renderStakeIntegrations, formatExtraData: this, headerStyle: Utilities.respWidth(25, 15, 10)},
      ];
    }

    // console.log(`in render, after processing is hidden, 1st column is: ${columns[0].hidden} - ${columns[0].dataField} `);
    columns = ValidatorUtils.removeListColumns(columns);
    // console.log("in render, after processing remove list columns, columns are: ", columns);
    // console.log("in render, before processing updateColumnsByView, more view is: ", this.state.moreView);
    columns = ColumnUtils.updateColumnsByView(this.state.viewName, viewData.validators, columns, this.getInitColumns(), this.state.moreView);
    // console.log("in render, after update columns by view, columns are: ", columns);

    const options = {
    };

    const { currentFilter, filterValue } = this.state;
    let filterMessage = "";
    if (currentFilter && filterValue) {
        filterMessage = `Filter: showing validators with ${currentFilter.replace(/_/g, ' ').toLowerCase()} ${filterValue}.`;
    }
    // console.log(`currentFilter - ${currentFilter} && filterValue - ${filterValue}. filterMessage - ${filterMessage}`);
    return (
      <div>
        <NetworkHeader coinStat={this.state.coinStat} title="Validators" thisObj={this} beta={false} />
        <p/>
        <ReactTooltip id="main" place="top" type="dark" effect="float" multiline={true} />
        {RespUtils.isNotTabletView() && <Tips tip={this.state.tip} />}
        <Tour steps={common.screenTour.validators} isOpen={this.state.isHelpOpen} onRequestClose={this.closeHelp} className="ssTour" />
        {filterMessage && <div className="filter-message">{filterMessage}</div>}
        <ToolkitProvider keyField="poolId" data={this.state.validators} columns={columns} columnToggle >
          {
            props => (
              <div id="mainTable">
                <ValColumnSelector settingKey={constants.VALIDATORS_KEY} getInitColumns={this.getInitColumns} showHelp={this.showHelp}
                  statusSummary={this.state.statusSummary} { ...props.columnToggleProps } refreshParent={this.repaintData} 
                  fsElementId="mainTable" hideFullScreenLink={true} status={this.state.status} handleFilter={this.handleFilter}
                  viewsData={viewData.validators} handleViewChange={this.handleViewChange} defaultView={this.state.viewName}
                  supportMoreView = {ColumnUtils.isMoreViewNeeded(this.state.viewName, "validators")} moreView = {this.state.moreView} toggleMoreView={this.toggleMoreView}
                  title="Validators" downloadData={DownloadUtils.getTableDownloadData(this.state.validators, columns)} />
                <BootstrapTable options={options} { ...props.baseProps } 
                    filter={ filterFactory() } expandableRow={ () => { return false; } }
                    condensed noDataIndication="No Data" />
              </div>
            )
          }
        </ToolkitProvider>
        <p/>
        <LastUpdated thisObj={this} recentBlock={true} />
        <CollapsibleNote getScreenGuide={MainNotes.getPoolNote} />
        {currentNetwork.details.ui.network.aprDisclaimer === true && (<p>** Staking APR is a just-in-time stat and does not consider future change in staking returns based on higher/lower total stake, or change in network parameters, or reflect token price changes. It is based on inflation, block rate, 0% commission, and does not reflect transaction fees. APY assumes weekly auto compounding without considering tx fees (manual/automated).</p>) }
        {currentNetwork.details.ui.network.aprDisclaimer !== true && currentNetwork.details.ui.showApr === true && (<p>** Staking APR is a just-in-time stat and does not consider future change in staking returns based on higher/lower total stake, or change in network parameters, or reflect token price changes. APY assumes weekly auto compounding without considering tx fees (manual/automated).</p>) }
        <Contact />
      </div>
    );
  }

  reload() {
    window.location = "/";
  }

  getInitColumns() {
    // console.log(`in start of getInitColumns`);
    let columnsConfig = ValidatorUtils.getCustomConfig();
    
    if (columnsConfig) {
      if (this.state.viewName && this.state.viewName != "") {
        // console.log(`in getInitColumns: before setting everything to false for view: `, this.state.viewName);
        // console.log(columnsConfig);
        DataUtils.setAllToFalse(columnsConfig);
        // console.log(`in getInitColumns: setting everything to false for view: `, this.state.viewName);
      }
    }

    // console.log(`in getInitColumns: `, columnsConfig);
    return columnsConfig;
  }

  isHidden(dataField) {
    // return UITableUtils.isHidden(constants.VALIDATORS_KEY, dataField, this.getInitColumns);
    let result = ColumnUtils.isHiddenWithView(constants.VALIDATORS_KEY, dataField, this.getInitColumns, this.state.viewName);
    // console.log(`in validators.js is hidden: ${dataField} - ${result}`);
    return result;
  }

  closeHelp() {
    SavedSettings.setValHelpNotNeeded(this);
  }

  showHelp() {
    SavedSettings.setValHelpNeeded(this);
  }

  static jailedFormatter(cell, row, index, extra) {
    if (!cell) return "";

    if (row.jailed === "True") {
      return {"background-image": "linear-gradient(to right, rgb(245, 0, 0), #ffffff)"};
    }

    return "";
  }

  getSlashingUptimeTitle() {
    let title = "Slashing Window Uptime";
    if (this.state.params != null) {
      title += " (>" + this.state.params.slashingMinPerUptime + "%)";
    }

    return title;
  }

  getSlashingMissedTitle() {
    let title = "Slashing Window Missed Blocks";
    if (this.state.params != null) {
      title += " (out of " + CoinUtils.stakeFormatterRounded(this.state.params.signedBlocksWindow) + " blocks)";
    }

    return title;
  }

  handleViewChange(newView) {
    if (this.state.viewName && this.state.viewName === "") {
      newView = null;
    }

    this.setState({"viewName": newView});
    UrlUtils.setUrlFragment(newView);
  }

  toggleMoreView() {
    ColumnUtils.toggleMoreView(this);
  }
}

export default Validators;
