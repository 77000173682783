import React from "react";
import {Container, Row, Col} from 'react-bootstrap';
import ClearRoundedIcon from '@material-ui/icons//ClearRounded';
import CheckRoundedIcon from '@material-ui/icons//CheckRounded';

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ReactTooltip from "react-tooltip";
import PageHeader from "../components/PageHeader";
import Stat from "../components/Stat";
import currentNetwork from "../currentNetwork";
import MainUtils from "../main/MainUtils";
import tooltips from "../tooltips";
import ApiUtils from "../util/ApiUtils";
import './BlockSign.css';
import UIUtils from "../util/UIUtils";
import SessionUtils from "../util/SessionUtils";

class BlockSign extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      validatorSet: [],
      validatorHexMap: [],
      validatorConsensusMap: [],
      validatorSignMap: [],
      validatorPKMap: [],
      blockDetails: {},
      validatorSetPages: 1,
      changeStyle: "highlight-default",
      isLoading: true,
    };
    this.processValidatorSet = this.processValidatorSet.bind(this);
    this.processSign = this.processSign.bind(this);
    this.mergeData = this.mergeData.bind(this);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  async componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    SessionUtils.setNetwork(this);

    let url = "listData?type=listPoolSummary&status=All";
    let block=100000;
    if (this.props.match && this.props.match.params.block) {
        block = this.props.match.params.block;
        url += "&block=" + this.props.match.params.block;
    }
    // console.log(url);

    // console.log("making pool list call");
    const allData = await ApiUtils.get(url);
    // console.log("pool list call successful");
    // console.log(allData);
    if (!allData) {
      this.setState({error: true, isLoading: false});
      return;
    }

    let validators = allData["data"];
    let validatorHexMap = MainUtils.convertListToMap(validators, "hexAddress");
    // console.log("validatorHexMap: ", validatorHexMap)
    let validatorConsensusMap = MainUtils.convertListToMap(validators, "consensusNodeAddress");
    // console.log("validatorConsensusMap: ", validatorConsensusMap)
    let validatorPKMap = MainUtils.convertListToMap(validators, "publicKey");

    this.setState({"validatorHexMap": validatorHexMap, "validatorConsensusMap": validatorConsensusMap,
        "validatorSetPages": currentNetwork.details.app.validatorSetPages,
        "validatorPKMap": validatorPKMap});

    // console.log("currentNetwork.details.app.validatorSetPages : " + currentNetwork.details.app.validatorSetPages);
    for (let i = 0; i < currentNetwork.details.app.validatorSetPages; i++) {
        this.processValidatorSet(block, 1 + i);
    }

    this.processSign(block);
    this.setState({"isLoading": false});
  }

  processValidatorSet(block, pageNum) {
    // console.log("making validator set call");
    // fetch(currentNetwork.details.app.REST_ENDPOINT + "validatorsets/" + block + "?page=" + pageNum)
    fetch(currentNetwork.details.app.REST_ENDPOINT + "cosmos/base/tendermint/v1beta1/validatorsets/" + block 
        + "?pagination.offset=" + ((pageNum -1) * 100) + "&pagination.limit=100")
      .then((response) => response.json())
      .then((data) => {
        // console.log("validator set call successful");
        // console.log(data);
        let validatorSet = this.state.validatorSet;
        // console.log(validatorSet);
        validatorSet = validatorSet.concat(data["validators"]);
        // console.log("val set for : " + pageNum);
        // console.log(data["validators"]);
        // console.log(validatorSet);
        this.setState({"validatorSet": validatorSet});
      }).catch(console.log);
      ;
  }

  processSign(block) {
    // console.log("making block details call");
    // TODO use this or blocks/{} or parameterize the API 
    fetch(currentNetwork.details.app.REST_ENDPOINT + "cosmos/base/tendermint/v1beta1/blocks/" + block)
      .then((response) => response.json())
      .then((data) => {
        // console.log("block details call successful");
        // console.log(data)
        let blockDetails = {"block": data.block.header.height, 
            "blockTime": data.block.header.time, 
            "proposer": data.block.header.proposer_address, 
        }
        // console.log("sign data: ", data);
        let signatures = data.block.last_commit.signatures;
        // console.log("signatures: ", signatures);
        let validatorSignMap = MainUtils.convertListToMap(signatures, "validator_address");
        // console.log("validatorSignMap: ", validatorSignMap);

        this.setState({"validatorSignMap": validatorSignMap, "blockDetails": blockDetails});
      });
  }

  render() {
    let loadMsg = UIUtils.getLoading(this);
    if (loadMsg) return loadMsg;

    // console.log("merging data");
    let validatorData = this.mergeData();
    if (!validatorData) {
        // console.log("data not available yet");
        return <div>Loading</div>;
    }
    // console.log("validatorData: ");
    // console.log(validatorData);

    return (
      <div>
        <PageHeader title={"Block Signs for " + this.state.blockDetails.block} thisObj={this} />
        <ReactTooltip id="main" place="top" type="dark" effect="float" multiline={true} />
        {this.getBlockSummary()}
        {currentNetwork.details.app.REST_PROVIDER && <p>Thanks to <a href={currentNetwork.details.app.REST_PROVIDER_WEBSITE} className="animLinkVisual">{currentNetwork.details.app.REST_PROVIDER}</a> for the public LCD used in this page.</p>}
        <Container className="containerLayout" fluid>
            <Row className="statRowLayout">
                {validatorData.map(function (validator) {
                    if (validator !== "") {
                        let styleName = "signStat";
                        if (validator.signed !== true) {
                            styleName = "signMissedStat";
                        }
                    
                        return <Col className="statColLayout" xs={6} sm={4} md={3} lg={2}>
                                    <div className={styleName}>
                                        {MainUtils.nameFormatterTableWithLogo(validator.name, validator)} &nbsp; {BlockSign.formatSign(validator.signed)}
                                    </div>
                                </Col>
                    }
                })}

            </Row>
        </Container>  
        
      </div>
    );
  }

    //   SPUtilities.formatDateTimeFromStr(this.state.blockDetails.blockTime)

  getBlockSummary() {
    return (
        <Container className="containerLayout" fluid>
            <Row className="statRowLayout">
                <Col className="statColLayout" xs={6} sm={6} md={6} lg={3}>
                    <Stat label="Block Height" value={this.state.blockDetails.block}
                        info={tooltips.blocksign.height} />
                </Col>
                <Col className="statColLayout" xs={6} sm={6} md={6} lg={3}>
                    <Stat label="Block Time" value={BlockSign.formatBlockTime(this.state.blockDetails.blockTime)}
                            info={tooltips.blocksign.blockTime} />
                </Col>
                <Col className="statColLayout" xs={6} sm={6} md={6} lg={3}>
                    <Stat label="Proposer" value={this.getValidatorFromMap(this.state.blockDetails.proposer, this.state.validatorPKMap)}
                            info={tooltips.blocksign.proposer} />
                    {/* <Stat label="Proposer" value={this.getValidatorFromHex(this.state.blockDetails.proposer, this.state.validatorHexMap)}
                            info={tooltips.blocksign.proposer} /> */}
                </Col>
            </Row>
        </Container>  
    );
  }

  getValidatorFromMap(proposer, validatorsMap) {
    // console.log("getting validator for : ", proposer);
    let validator = validatorsMap[proposer];

    if (!validator) return "Unknown";

    return MainUtils.nameFormatterTableWithLogo(validator.name, validator);
  }

  mergeData() {
    if (this.state.validatorSignMap.length === 0) {
        // console.log("not everything is loaded yet. signs: ", this.state.signatures.length);
        return null;
    }
    if (this.state.validatorConsensusMap.size === 0) {
        // console.log("not everything is loaded yet. val consensus: ", this.state.validatorConsensusMap.size);
        return null;
    }
    if (this.state.validatorSet.size === 0) {
        // console.log("not everything is loaded yet. val set: ", this.state.validatorSet.size);
        return null;
    }

    if (this.state.validatorPKMap.size === 0) {
      // console.log("not everything is loaded yet. val set: ", this.state.validatorSet.size);
      return null;
    }

    console.log("start");
    console.log(this.state.validatorSignMap);
    console.log(this.state.validatorConsensusMap);
    console.log(this.state.validatorSet);
    console.log(this.state.validatorPKMap);
    console.log("end");

    // console.log("everything is loaded now");
    let validators = []
    for (let i=0; i < this.state.validatorSet.length; i++) {
        let valFromSet = this.state.validatorSet[i];
        // console.log(valFromSet);
        let consensusAddress = valFromSet["address"];
        let validator = this.state.validatorConsensusMap[consensusAddress];
        if (!validator["name"].startsWith("Smart")) {
          continue;
        }
        console.log(validator);
        console.log(this.state.validatorSignMap);
        // let sign = this.state.validatorSignMap[validator.hexAddress];
        let sign = this.state.validatorSignMap[validator.publicKey];
        console.log(sign);
        if (!sign) {
            console.log("missed by: ", validator);
            validator.signed = false;
        } else {
            validator.signed = true;
        }

        validators.push(validator);
    }

    return validators;
  }

  static formatSign(signed) {
    let tip = "Validator did not commit block";
    // let imgSrc = "/images/red-16.png";
    if (signed === true) {
        tip = "Validator committed block";
        // imgSrc = "/images/green-16.png"
        return (<span data-for="main" data-tip={tip} data-iscapture="true"><CheckRoundedIcon style={{color: "green"}} fontSize="large" size="large" /></span>);
    }

    return (<span data-for="main" data-tip={tip} data-iscapture="true">
                <ClearRoundedIcon style={{color: "red"}} fontSize="large" size="large" />
            </span>);
  }

  static formatBlockTime(blockTime) {
    if (!blockTime) return "";

    let milliPartIndex = blockTime.indexOf(".");
    if (milliPartIndex !== -1) {
        return blockTime.substring(0, milliPartIndex).replace("T", " ");
    }

    return blockTime.replace("T", " ");
  }
}

export default BlockSign;
