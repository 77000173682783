import React from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import BootstrapTable from 'react-bootstrap-table-next';
import ReactTooltip from "react-tooltip";
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';

import RespUtils from '../util/RespUtils';
import ValColumnSelector from '../validators/ValColumnSelector';
import CoinUtils from '../util/CoinUtils';

import ApiUtils from '../util/ApiUtils';
import tooltips from "../tooltips";
import '../styles/section.css';
import Utilities from '../util/Utilities';
import ChartTitle from '../components/ChartTitle';
import UIUtils from '../util/UIUtils';
import currentNetwork from '../currentNetwork';
import PageHeader from '../components/PageHeader';
import SessionUtils from '../util/SessionUtils';
import FdpInline from './FdpInline';
import FdpUtils from './FdpUtils';
import FdpValidatorInline from './FdpValidatorInline';
import MainUtils from '../main/MainUtils';
import ColumnUtils from '../util/ColumnUtils';
import Dropdown from '../base/Dropdown';
import AddressUtils from '../util/AddressUtils';

class Fdp extends React.Component {
  FDP_VALIDATORS_KEY = "FDP_VALIDATORS_KEY_3";

  constructor(props) {
    super(props);

    this.state = {
      fdpNumber: null,
      showDelegation: false,
      fdp: {},
      validators: [],
      filterSummary: {},
      width: window.innerWidth,
      responsive: true,
      isLoading: true,
      filterStatus: null,
      fdpAddresses: null,
      selectedAddress: null,
    }

    this.updateDimensions = this.updateDimensions.bind(this);
    this.repaintData = this.repaintData.bind(this);
    this.getInitColumns = this.getInitColumns.bind(this);
    this.renderFilters = this.renderFilters.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
  }

  updateDimensions() {
    this.setState({width: window.innerWidth});
  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  async componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    SessionUtils.setNetwork(this);

    let fdpNumber = null;
    let filter = null;
    let filterStatus = null;
    if (this.props.match && this.props.match.params.fdpNumber) {
      fdpNumber = this.props.match.params.fdpNumber;
    }
    if (this.props.match && this.props.match.params.filterStatus) {
      filter = this.props.match.params.filterStatus;
      filterStatus = this.props.match.params.filterStatus;
    }

    // console.log(filter);
    // console.log(filterStatus);
    
    let url = "listData?type=fdp&fdpNumber=" + fdpNumber + (filter ? "&filter=" + filter : "");
    url += FdpUtils.setAccessKeyAndGetParam(this);

    const allData = await ApiUtils.get(url);
    if (allData) {
      let selectedAddress = null;
      if (filterStatus && (filterStatus != "met" || filterStatus != "notmet")) {
        selectedAddress = filterStatus;
      }

      // console.log(allData["showDelegation"]);
      // console.log(allData["fdp"]);
      let validators = Utilities.addIndex(allData["validators"]);
      this.setState({"fdp": allData["fdp"], "validators": validators, "filterSummary": allData["filterSummary"],
        "fdpNumber": fdpNumber, "showDelegation": allData["showDelegation"], "filterStatus": filterStatus, 
        "fdpAddresses": allData["fdpAddresses"], "selectedAddress": selectedAddress,
        isLoading: false});
    }
  }

  render() {
    if (this.state.isLoading) {
      return <div>Loading</div>;
    }

    return (
      <div>
        {FdpUtils.getFdpBreadCrumb(true, this.state.fdp)}
        <PageHeader title={currentNetwork.details.app.appName + " FDP - " + this.state.fdp.periodLabel} thisObj={this}/>
        <ReactTooltip id="main" place="top" type="dark" effect="float" multiline={true} />
        {this.state.fdp.status === "pending" && UIUtils.getNotification({"message": "This FDP assessment window is not yet active"})}
        <FdpInline fdp={this.state.fdp} />
        {this.state.fdp.status !== "pending" && this.renderValidators()}
      </div>
    );
  }
  
  renderValidators() {
    const headerSortingClasses = (column, sortOrder, isLastSorting, colIndex) => (
      sortOrder === 'asc' ? 'sorting-asc' : 'sorting-desc'
    );
    
    // let foundationDelegationColumnIndex = 4;
    var columns = [
      {text: "#", desc: "Index", hidden: this.isHidden("index"), dataField: "index", sort: true, align: "left", headerStyle: Utilities.respWidth(7, 5, 3), headerSortingClasses},
      {text: "", filter: textFilter({placeholder: "Name"}), desc: "Validator Name", hidden: this.isHidden("name"), dataField: "name", formatter: FdpUtils.nameFormatterTableWithLogo, formatExtraData: this, sort: true, headerStyle: Utilities.respWidth(45, 25, 15), headerSortingClasses},
      {text: "Voting Power (VP)",  desc: ("Total Staked with validator"), hidden: this.isHidden("votingPower"), dataField: "votingPower", formatter: CoinUtils.stakeFormatterRounded, sort: true, headerStyle: Utilities.respWidth(20, 12, 9), headerSortingClasses},
      {text: "VP %",  desc: "% of total voting power that is with the validator", hidden: this.isHidden("votingPowerPercentage"), dataField: "votingPowerPercentage", formatter: MainUtils.percentFormatter, formatExtraData: this, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 12, 7), headerSortingClasses},
      {text: "Foundation Delegation",  desc: ("Foundation delegation"), hidden: this.isHidden("foundationDelegation"), dataField: "foundationDelegation", formatter: CoinUtils.stakeFormatterRounded, sort: true, headerStyle: Utilities.respWidth(20, 12, 9), headerSortingClasses},
      {text: "Status",  desc: "Status", hidden: this.isHidden("status"), dataField: "status", sort: true, headerStyle: Utilities.respWidth(20, 12, 8), headerSortingClasses},
      {text: "Met All Reqmts",  desc: "Met All Requirements", hidden: this.isHidden("metAllObj"), dataField: "metAllObj", formatter: FdpUtils.objectiveFormatter, sort: true, headerStyle: Utilities.respWidth(20, 12, 8), headerSortingClasses},
      {text: "Commission",  desc: "Met Comm Requirements", hidden: this.isHidden("metCommObj"), dataField: "metCommObj", formatter: FdpUtils.objectiveFormatter, sort: true, headerStyle: Utilities.respWidth(20, 12, 8), headerSortingClasses},
      {text: "Uptime",  desc: "Met Uptime Requirements", hidden: this.isHidden("metUptimeObj"), dataField: "metUptimeObj", formatter: FdpUtils.objectiveFormatter, sort: true, headerStyle: Utilities.respWidth(20, 12, 8), headerSortingClasses},
      {text: "Peggo Uptime",  desc: "Met Extra Uptime Requirements", hidden: this.isHidden("metExtraUptimeObj"), dataField: "metExtraUptimeObj", formatter: FdpUtils.objectiveFormatter, sort: true, headerStyle: Utilities.respWidth(20, 12, 8), headerSortingClasses},
      {text: "Upgrade",  desc: "Met Upgrade Requirements", hidden: this.isHidden("metUpgradeObj"), dataField: "metUpgradeObj", formatter: FdpUtils.objectiveFormatter, sort: true, headerStyle: Utilities.respWidth(20, 12, 8), headerSortingClasses},
      {text: "Extra Upgrade",  desc: "Met Extra Upgrade Requirements", hidden: this.isHidden("metExtraUpgradeObj"), dataField: "metExtraUpgradeObj", formatter: FdpUtils.objectiveFormatter, sort: true, headerStyle: Utilities.respWidth(20, 12, 8), headerSortingClasses},
      {text: "Slashing Freq",  desc: "Met Slashing Freq Requirements", hidden: this.isHidden("metSlashingFreqObj"), dataField: "metSlashingFreqObj", formatter: FdpUtils.objectiveFormatter, sort: true, headerStyle: Utilities.respWidth(20, 12, 8), headerSortingClasses},
      {text: "Slashing Max Duration",  desc: "Met Slashing Max Duration Requirements", hidden: this.isHidden("metSlashingMaxDurObj"), dataField: "metSlashingMaxDurObj", formatter: FdpUtils.objectiveFormatter, sort: true, headerStyle: Utilities.respWidth(20, 12, 8), headerSortingClasses},
      {text: "Governance",  desc: "Met Governance Requirements", hidden: this.isHidden("metGovernanceObj"), dataField: "metGovernanceObj", formatter: FdpUtils.objectiveFormatter, sort: true, headerStyle: Utilities.respWidth(20, 12, 8), headerSortingClasses},
      {text: (this.state.fdp.maxVP != null ? ("<" + this.state.fdp.maxVP + "% VP"): "Low VP"), desc: "Is validator total VP within limits?", hidden: this.isHidden("metVPObj"), dataField: "metVPObj", formatter: FdpUtils.objectiveFormatter, sort: true, headerStyle: Utilities.respWidth(20, 12, 8), headerSortingClasses},
      {text: "Notes",  desc: "Notes", hidden: this.isHidden("notes"), dataField: "notes", formatter: FdpUtils.objectiveFormatter, sort: true, headerStyle: Utilities.respWidth(20, 12, 10), headerSortingClasses},
    ];

    // if (FdpUtils.isToBeShown(this.state.fdp, "minUptime")) {
    //   columns.push();
    // }
    // if (FdpUtils.isToBeShown(this.state.fdp, "minUptime")) {
    //   columns.push();
    // }
    columns = FdpUtils.removeUnsupportedColumns(columns, this.state.fdp, this.state.showDelegation);

    // if (!this.state.showDelegation) {
    //   columns.splice(foundationDelegationColumnIndex, 1);
    // }

    // FIXME remove the columns for values that are disabled

    const options = UIUtils.getPageOptionsSmall(this, 5);
    const defaultSorted = [{
      dataField: 'title',
      order: 'desc' // desc or asc
    }];

    const expandRow = {
      renderer: row => (
        <FdpValidatorInline fdp={this.state.fdp} fdpValidator={row} />
      ), showExpandColumn: false
    };

    return (
      <React.Fragment>
        <ChartTitle title="FDP Recipients" tip={tooltips.fdp.recipientValidators} />
        <p/>
        <ReactTooltip id="main" place="top" type="dark" effect="float" multiline={true} />
        <ToolkitProvider keyField="poolId" data={this.state.validators} columns={columns} columnToggle>
          {
            props => (
              <div id="mainTable">
                <ValColumnSelector settingKey={Fdp.FDP_VALIDATORS_KEY} getInitColumns={this.getInitColumns} showHelp={this.showHelp}
                  statusSummary={this.state.filterSummary} { ...props.columnToggleProps } refreshParent={this.repaintData} 
                  fsElementId="mainTable" hideFullScreenLink={true} renderFilters={this.renderFilters} 
                  status={this.state.filterStatus}/>
                <BootstrapTable options={options} { ...props.baseProps } 
                    expandRow={ expandRow } expandableRow={ () => { return true; } } filter={ filterFactory() }
                    condensed noDataIndication="No Data" />
              </div>
            )
          }
        </ToolkitProvider>
      </React.Fragment>
    );
  }

  getInitColumns() {
    let columnsConfig = {};
    // let fdpExtraDisabled = FdpUtils.isExtraDisabled(this.state.fdp);
    let fdpExtraDisabled = !currentNetwork.details.fdp || !currentNetwork.details.fdp.extra;
    let fdpLowVPDisabled = !currentNetwork.details.fdp || !currentNetwork.details.fdp.maxVP;

    if (RespUtils.isTabletView()) {
      columnsConfig = {
        index: false, name: false, votingPower: true, foundationDelegation: true, 
        metAllObj: false, metCommObj: true, metUptimeObj: true,
        metExtraUptimeObj: true, metUpgradeObj: true, metExtraUpgradeObj: true,
        metSlashingFreqObj: true, metSlashingMaxDurObj: true, metGovernanceObj: true,
        notes: true, status: true, metVPObj: true, votingPowerPercentage: true,
      };
    } else {
      columnsConfig = {
        index: false, name: false, votingPower: false, foundationDelegation: false, 
        metAllObj: false, metCommObj: false, metUptimeObj: false,
        metExtraUptimeObj: fdpExtraDisabled, metUpgradeObj: false, metExtraUpgradeObj: fdpExtraDisabled,
        metSlashingFreqObj: false, metSlashingMaxDurObj: false, metGovernanceObj: false,
        notes: false, status: false, metVPObj: fdpLowVPDisabled, votingPowerPercentage: false, 
      };
    }

    return columnsConfig;
  }

  isHidden(dataField) {
    return ColumnUtils.isHidden(Fdp.FDP_VALIDATORS_KEY, dataField, this.getInitColumns);
  }

  repaintData() {
    let newValidators = Utilities.addIndex(this.state.validators)
    this.setState({validators: newValidators});
  }

  renderFilters() {
    // console.log("in render: ", this.state.fdpAddresses);
    return (<div><table><tbody><tr>
        <td className="view-tag"><a className="animLink" href={SessionUtils.getUrl("/fdp/" + this.state.fdp.fdpNumber)}
          >All - {this.state.filterSummary.Total}</a> </td>
        <td className="view-tag"><a className="animLink" href={SessionUtils.getUrl("/fdp/" + this.state.fdp.fdpNumber + "/met")}
          ><CheckCircleRoundedIcon style={{color: "#419541",}} /> Met All Reqmts - {this.state.filterSummary.met}</a> </td>
        <td className="view-tag"><a className="animLink" href={SessionUtils.getUrl("/fdp/" + this.state.fdp.fdpNumber + "/notmet")}
          ><CancelRoundedIcon style={{color: "#ce4536",}} /> Not Meeting All Reqmts - {this.state.filterSummary.notmet}</a> </td>
        {this.state.fdpAddresses != null && (<td className="view-tag">{this.renderAddresses()}</td>)}
      </tr></tbody></table></div>);
  }

  renderAddresses() {
    // console.log("in renderAddresses");
    let values = AddressUtils.convertAddressesForDropdown(this.state.fdpAddresses);
    return <Dropdown onSelect={this.handleAddressChange} values={values} defaultValue={this.state.selectedAddress} 
    customValue="Addresses" size="lg" className="customViews" />
  }

  handleAddressChange(e) {
    // console.log(e);
    // console.log(e.target.value);
    let address = e.target.value;
    if (address != "") {
      window.location = SessionUtils.getUrl("/fdp/" + this.state.fdp.fdpNumber + "/" + address);
    } else {
      window.location = SessionUtils.getUrl("/fdp/" + this.state.fdp.fdpNumber);
    }
  }

}

export default Fdp;
