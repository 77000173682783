import React from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import {Container} from 'react-bootstrap';
import ReactTooltip from "react-tooltip";

import MultiLineChart from '../charts/MultiLineChart';
import ApiUtils from '../util/ApiUtils';
import tooltips from "../tooltips";
import '../styles/section.css';
import MainUtils from '../main/MainUtils';
import CoinUtils from '../util/CoinUtils';
import Utilities from '../util/Utilities';
import BaseTreeMap from '../charts/BaseTreeMap';
import currentNetwork from '../currentNetwork';
import './VotingPower.css';
import VPCurveComparison from '../network/VPCurveComparison';
import VPCurveTimeline from '../network/VPCurveTimeline';
import RespUtils from '../util/RespUtils';
import BaseBarChart from '../charts/BaseBarChart';
import UrlUtils from '../util/UrlUtils';

class VotingPower extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      coinStat: {},
      notification: {},
      width: window.innerWidth,
      responsive: true,
      isLoading: true,
      frequencyNetwork: "M",
      frequencyBlock: "H",
      lastValStakes: [],
      validators: [],
    }

    // this.showVPCurveChart = this.showVPCurveChart(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.handleChartUpdateNetwork = this.handleChartUpdateNetwork.bind(this);   
    this.handleChartUpdateBlock = this.handleChartUpdateBlock.bind(this);
  }

  updateDimensions() {
    this.setState({width: window.innerWidth});
  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  async componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);

    await this.prepareData(this.state.frequencyNetwork, this.state.frequencyBlock);
    UrlUtils.setUrlFragment("vp");
  }

  async prepareData(frequencyNetwork, frequencyBlock) {
    let url = "networkStats?type=votingPower&frequencyNetwork=" + frequencyNetwork + "&frequencyBlock=" + frequencyBlock;
    const allData = await ApiUtils.get(url);

    if (allData) {
      let lastValStakes = Utilities.removeEmptyRecords(allData["networkHistory"], "lastValStake");
      this.setState({"frequencyNetwork": frequencyNetwork, "frequencyBlock": frequencyBlock,
        "coinStat": allData["coinStat"], "notification": allData["notification"],
        isLoading: false, "validators": allData["validators"], "lastValStakes": lastValStakes});
    }
  }

  render() {
    if (this.state.isLoading) {
      return <div>Loading</div>;
    }

    var vpColumns = [
      {text: "Validator", dataField: "name", formatter: MainUtils.nameFormatterTableWithLogo, sort: true, headerStyle: Utilities.respWidth(45, 35, 25)},
      {text: Utilities.respLabel("Voting Power (VP)", "Voting Power (VP)", "Voting Power"), hidden: RespUtils.isMobileView(), dataField: "votingPower", formatter: CoinUtils.stakeFormatterRounded, sort: true, headerStyle: Utilities.respWidth(20, 20, 20)},
      {text: Utilities.respLabel("% VP", "% VP", "Voting Power Percentage"), dataField: "votingPowerPercentage", formatter: CoinUtils.percentFormatter, sort: true, headerStyle: Utilities.respWidth(35, 25, 25)},
      {text: "Unique Delegates", dataField: "uniqueDelegates", sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(35, 20, 20)},
    ];

    return (
      <div>
        <ReactTooltip id="main" place="top" type="dark" effect="float" multiline={true} />
        <hr/>
        <Container fluid className="containerLayout container-fluid chartContainer">
          <div className="chartGridContainer">
            <VPCurveTimeline />
            <VPCurveComparison />
            <div className="chartBg" id="stakeDistribution">
              <BaseTreeMap title="Stake Heat Map" data={this.state.validators} 
                chartVisualId="stake-heat-map" enableSave={true} 
                tip={tooltips.networkCharts.stakeDistribution} fsElementId="stakeDistribution"
                dataKey="name" sortBy="votingPower" columns={vpColumns} />
            </div>
            <div className="chartBg" id="vpDistribution">
              <BaseBarChart title="Validator Stake Distribution" tip={tooltips.networkCharts.valStakeDistribution} 
                chartVisualId="val-stake-dist" enableSave={true} 
                xAxis={"Validator"} yAxis={"Stake in " + currentNetwork.details.app.token}
                showVerticalLabel={true} valueAttr="votingPower" showTotalLabel={false} xAxisValueAttr="name"
                data={this.state.validators}
                dataKey="name" sortBy="votingPower" columns={vpColumns} />
            </div>
            {/* <div className="chartBg">
              {this.getStakeDistribution()}
            </div> */}
            {this.state.lastValStakes.length > 0 && (<div className="chartBg" id="lastValStake">
              <MultiLineChart title="Last Active Validator VP" shortTitle="Last Validator VP" 
                  chartVisualId="last-active-val-vp" enableSave={true} 
                  xAxis="Date" yAxis="Count" adjustRange={true} 
                  showVerticalLabel={false} valueAttr={["lastValStake", "lastValStakeUsd"]} 
                  rangeAttrs={["lastValStake", "lastValStakeUsd"]} showTotalLabel={false} xAxisValueAttr="title"
                  data={this.state.lastValStakes} tip={tooltips.networkCharts.lastValStakes} 
                  handleChart={this.handleChartUpdateNetwork} formatValues={true} fsElementId="lastValStake" 
                  frequencies={["M", "Y", "All"]} frequency={this.state.frequencyNetwork} 
                  dataKey="title" columns={[
                    {text: "Date", dataField: "title", sort: true, },
                    {text: "Last Validator Stake", dataField: "lastValStake", formatter: CoinUtils.stakeFormatterRounded, sort: true, },
                    {text: "Last Validator Stake USD", dataField: "lastValStakeUsd", formatter: CoinUtils.moneyFormatterUsd, sort: true, },
                  ]} />
            </div>)}
          </div>
        </Container>
      </div>
    );
  }
  
  handleChartUpdateNetwork(frequencyNetwork) {
    this.prepareData(frequencyNetwork, this.state.frequencyBlock);
  }

  handleChartUpdateBlock(frequencyBlock) {
    this.prepareData(this.state.frequencyNetwork, frequencyBlock);
  }

  // getStakeDistribution() {
  //   const headerSortingClasses = (column, sortOrder, isLastSorting, colIndex) => (
  //     sortOrder === 'asc' ? 'sorting-asc' : 'sorting-desc'
  //   );
    
  //   var columns = [
  //     {text: "Validator", dataField: "name", formatter: MainUtils.nameFormatterTableWithLogo, sort: true, headerStyle: Utilities.respWidth(45, 35, 35), headerSortingClasses},
  //     {text: Utilities.respLabel("Voting Power (VP)", "Voting Power (VP)", "Voting Power"), hidden: RespUtils.isMobileView(), dataField: "votingPower", formatter: CoinUtils.stakeFormatterRounded, sort: true, headerStyle: Utilities.respWidth(20, 20, 20), headerSortingClasses},
  //     {text: Utilities.respLabel("% VP", "% VP", "Voting Power Percentage"), dataField: "votingPowerPercentage", formatter: CoinUtils.percentFormatter, sort: true, headerStyle: Utilities.respWidth(25, 20, 15), headerSortingClasses},
  //     {text: "Unique Delegates", dataField: "uniqueDelegates", sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(35, 20, 20), headerSortingClasses},
  //   ];

  //   const options = UIUtils.getPageOptionsSmall(this, 5);
  //   const defaultSorted = [{
  //     dataField: 'votingPower',
  //     order: 'desc' // desc or asc
  //   }];

  //   return (
  //     <div>
  //       <ChartTitle title="Validator Stake & Delegate Distribution" shortTitle="Stake & Delegate Distribution" 
  //         tip={tooltips.networkCharts.stakeAndDelegateDistribution} />
  //       <p/>
  //       <ReactTooltip id="main" place="top" type="dark" effect="float" multiline={true} />
  //       <BootstrapTable keyField={currentNetwork.details.ui.identifierAttribute} data={ this.state.validators }
  //         columns={columns} striped options={options} defaultSorted={defaultSorted}
  //         condensed noDataIndication="No data" pagination={ paginationFactory(options) } />
  //     </div>
  //   );
  // }
}

export default VotingPower;
