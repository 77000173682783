import { Route, Switch } from 'react-router-dom';
import React from 'react';

import UnauthRoute from "./util/UnauthRoute";

import Validators from './validators/Validators';
import Calculator from './main/Calculator';
import Validator from './validator/Validator';
import Proposals from './network/Proposals';
import Proposal from './network/Proposal';
import Airdrops from './airdrops/Airdrops';
import Delegations from './validator/Delegations';
import ValStats from './validator/ValStats';
import Address from './address/Address';
import BlockSign from './network/BlockSign';
import Richlist from './address/Richlist';
import NetworkOverall from './stats/NetworkOverall';
import Burn from './network/Burn';
import TokenRichlist from './token/TokenRichlist';
import TokenHistory from './token/TokenHistory';
import TokenAddressHistory from './token/TokenAddressHistory';
import FdpList from './fdp/FdpList';
import Fdp from './fdp/Fdp';
import FdpValidator from './fdp/FdpValidator';
import ChooseValidator from './guides/ChooseValidator';
import LSM from './lsm/LSM';
import ValBonds from './lsm/ValBonds';
import Performance from './stats/Performance';
import NetworkTxs from './events/NetworkTxs';
import ValTxs from './events/ValTxs';
import Networks from './network/Networks';
import Tokens from './token/Tokens';


export default ({ childProps }) => (
	<Switch>
		<UnauthRoute exact={true} path='/networks' component={Networks} props={childProps} />

		<UnauthRoute exact={true} path='/:network/calc' component={Calculator} props={childProps} />
		<UnauthRoute exact={true} path='/:network/airdrops' component={Airdrops} props={childProps} />

		<UnauthRoute exact={true} path='/:network/validators/:status' component={Validators} props={childProps} />
		<UnauthRoute exact={true} path='/:network/validators' component={Validators} props={childProps} />

		<UnauthRoute path='/:network/stats/:statType/:extraData' component={NetworkOverall} props={childProps} />
		<UnauthRoute path='/:network/stats/:statType' component={NetworkOverall} props={childProps} />
		<UnauthRoute path='/:network/stats' component={NetworkOverall} props={childProps} />
		{/* <UnauthRoute path='/stats' component={NetworkCharts} props={childProps} /> */}
		
		<UnauthRoute path='/:network/proposals/:showDeposit' component={Proposals} props={childProps} />
		<UnauthRoute path='/:network/proposals' component={Proposals} props={childProps} />
		<UnauthRoute path='/:network/proposal/:proposalId/:minStake' component={Proposal} props={childProps} />
		<UnauthRoute path='/:network/proposal/:proposalId' component={Proposal} props={childProps} />

		<UnauthRoute path='/:network/gov/:showDeposit' component={Proposals} props={childProps} />
		<UnauthRoute path='/:network/gov' component={Proposals} props={childProps} />

		<UnauthRoute path='/:network/block/:block' component={BlockSign} props={childProps} />

		<UnauthRoute path='/:network/val/:address/txs/:category' component={ValTxs} props={childProps} />
		<UnauthRoute path='/:network/val/:address/txs' component={ValTxs} props={childProps} />

		<UnauthRoute path='/:network/valstats/:address' component={ValStats} props={childProps} />
		<UnauthRoute path='/:network/val/:address/:showMore' component={Validator} props={childProps} />
		<UnauthRoute path='/:network/val/:address' component={Validator} props={childProps} />
		<UnauthRoute path='/:network/val' component={Validator} props={childProps} />

		<UnauthRoute path='/:network/delegates/:address' component={Delegations} props={childProps} />
		<UnauthRoute path='/:network/delegations/:address' component={Delegations} props={childProps} />
		<UnauthRoute path='/:network/address/:address' component={Address} props={childProps} />

		<UnauthRoute path='/:network/richlist/:dataType' component={Richlist} props={childProps} />
		<UnauthRoute path='/:network/richlist' component={Richlist} props={childProps} />

		<UnauthRoute path='/:network/tokens/:token/history' component={TokenHistory} props={childProps} />
		<UnauthRoute path='/:network/tokens/:token/:address' component={TokenAddressHistory} props={childProps} />
		<UnauthRoute path='/:network/tokens/:token' component={TokenRichlist} props={childProps} />
		<UnauthRoute path='/:network/tokens' component={Tokens} props={childProps} />

		<UnauthRoute exact={true} path='/:network/vals/:skipTour' component={Validators} props={childProps} />

		<UnauthRoute path='/:network/valByIdentity/:identity/:showMore' component={Validator} props={childProps} />
		<UnauthRoute path='/:network/valByIdentity/:identity' component={Validator} props={childProps} />

		<UnauthRoute path='/:network/burn' component={Burn} props={childProps} />

		<UnauthRoute path='/:network/txs/:category' component={NetworkTxs} props={childProps} />
		<UnauthRoute path='/:network/txs' component={NetworkTxs} props={childProps} />

		<UnauthRoute path='/:network/perf' component={Performance} props={childProps} />

		<UnauthRoute path='/:network/fdp/:fdpNumber/val/:operatorAddress' component={FdpValidator} props={childProps} />
		<UnauthRoute path='/:network/fdp/:fdpNumber/:filterStatus' component={Fdp} props={childProps} />
		<UnauthRoute path='/:network/fdp/:fdpNumber' component={Fdp} props={childProps} />
		<UnauthRoute exact={true} path='/:network/fdp/' component={FdpList} props={childProps} />

		<UnauthRoute exact={true} path='/:network/guide/' component={ChooseValidator} props={childProps} />

		<UnauthRoute path='/:network/valbonds' component={ValBonds} props={childProps} />

		<UnauthRoute path='/:network/lsm/:lsp' component={LSM} props={childProps} />
		{/* <UnauthRoute path='/:network/lsm/:showAll/:lsp' component={LSM} props={childProps} />
		<UnauthRoute path='/:network/lsm/:showAll' component={LSM} props={childProps} /> */}
		<UnauthRoute exact={true} path='/:network/lsm/' component={LSM} props={childProps} />

		<UnauthRoute exact={true} path='/:network' component={Validators} props={childProps} />
		{/* Finally, catch all unmatched routes */}
		{/* <UnauthRoute exact={true} path='/' component={RouteToHome} props={childProps} /> */}
		{/* <Route component={Validators} /> */}

		{/* <Route component={RouteToHome} /> */}
		<Route component={Networks} />
	</Switch>
);
