import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import common from '../common';

import "../main/DelegateModal.css";
import './SearchModal.css';


function SearchModal({ isOpen, onClose, onFilter }) {
  const [criteria, setCriteria] = useState(common.ui.validatorCriteria.SEARCH_ALL);
  const [searchText, setSearchText] = useState('');
  const [error, setError] = useState('');
  
  const handleCriteriaChange = (e) => {
    setCriteria(e.target.value);
    setSearchText('');
    setError('');
  };

  const handleSearchTextChange = (e) => {
    const value = e.target.value;
    const isLimitedCriteria = [
      common.ui.validatorCriteria.COMMISSION_LOWER_THAN,
      common.ui.validatorCriteria.MAX_COMMISSION_LOWER_THAN,
      common.ui.validatorCriteria.MAX_COMMISSION_CHANGE_RATE_LOWER_THAN,
      common.ui.validatorCriteria.VOTING_POWER_PERCENTAGE_LESS_THAN
    ].includes(criteria);
    
    // console.log(`isLimitedCriteria: ${isLimitedCriteria}. criteria: ${criteria}`)
    if (isLimitedCriteria) {
      const num = Number(value);
      if (value === '' || (num >= 0 && num <= 100)) {
        setSearchText(value);
        setError('');
      } else {
        setError('Please enter a number between 0 and 100.');
      }
    } else {
      setSearchText(value);
      setError('');
    }
  };

  const handleFilterClick = () => {
    // if (error) {
    //   alert(error);
    //   return;
    // }
    const sanitizedText = searchText.trim();
    onFilter({ criteria, searchText: sanitizedText });
    onClose();
  };

  const handleReset = () => {
    setSearchText("");
    setCriteria("");
    onFilter({ criteria: null, searchText: null });
    onClose();
  };
  
  return (
    <Modal show={isOpen} onHide={onClose} size="lg" centered className="delegateWithUs">
      <Modal.Header closeButton>
        <Modal.Title>Filter Validators</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <div className="notification">{error}</div>}
        <div className="field">
          <label className="label">Search Criteria</label>
          <div className="control">
            <div className="select">
              <select value={criteria} onChange={handleCriteriaChange}>
                <option value={common.ui.validatorCriteria.SEARCH_ALL}>{common.ui.validatorCriteria.SEARCH_ALL}</option>
                <option value={common.ui.validatorCriteria.COMMISSION_LOWER_THAN}>{common.ui.validatorCriteria.COMMISSION_LOWER_THAN}</option>
                <option value={common.ui.validatorCriteria.MAX_COMMISSION_LOWER_THAN}>{common.ui.validatorCriteria.MAX_COMMISSION_LOWER_THAN}</option>
                <option value={common.ui.validatorCriteria.MAX_COMMISSION_CHANGE_RATE_LOWER_THAN}>{common.ui.validatorCriteria.MAX_COMMISSION_CHANGE_RATE_LOWER_THAN}</option>
                <option value={common.ui.validatorCriteria.VOTING_POWER_PERCENTAGE_LESS_THAN}>{common.ui.validatorCriteria.VOTING_POWER_PERCENTAGE_LESS_THAN}</option>
                <option value={common.ui.validatorCriteria.VALIDATOR_NAME}>{common.ui.validatorCriteria.VALIDATOR_NAME}</option>
                <option value={common.ui.validatorCriteria.VALIDATOR_OPERATOR_ADDRESS}>{common.ui.validatorCriteria.VALIDATOR_OPERATOR_ADDRESS}</option>
              </select>
            </div>
          </div>
        </div>
        <p/>
        <div className="field">
          <label className="label">Enter Value</label>
          <div className="control">
            <input
              type="text"
              placeholder=" "
              autoFocus
              value={searchText}
              onChange={handleSearchTextChange}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleFilterClick();
                }
              }}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleFilterClick}>Filter</Button>
        <Button variant="secondary" onClick={handleReset}>Reset</Button>
        <Button variant="secondary" onClick={onClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default SearchModal;
