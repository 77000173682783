import React from 'react';
import SPUtilities from '../util/SPUtilities';

class AirdropUtils extends React.Component {
  static resetAirdropCount(key) {
    // console.log("in resetAirdropCount");
    window.localStorage.removeItem("AIRDROP_COUNT_" + key);
    window.localStorage.removeItem("AIRDROP_PROC_" + key);
    window.localStorage.removeItem("AIRDROP_AMOUNT_" + key);
  }

  static getAirdropAmount(key) {
    let airdropAmount = window.localStorage.getItem("AIRDROP_AMOUNT_" + key);

    if (!airdropAmount) {
        // console.log("getAirdropCount: ", airdropCount);
        return 0;
    }

    // console.log("getAirdropCount: ", airdropCount);
    return airdropAmount;
  }

  static getAirdropsProcessed(key) {
    let airdropCount = window.localStorage.getItem("AIRDROP_PROC_" + key);

    if (!airdropCount) {
        // console.log("getAirdropsProcessed: ", airdropCount);
        return 0;
    }

    // console.log("getAirdropsProcessed: ", airdropCount);
    return airdropCount;
  }

  static getAirdropTime(nextSnapshotTime, row) {
    var timeObject = new Date();
    var seconds = parseInt(nextSnapshotTime);
    // console.log(seconds);
    timeObject.setSeconds(timeObject.getSeconds() + seconds);
    // console.log(timeObject);
    let localTimeForNextDrop = SPUtilities.formatDateTime(timeObject);
    
    return localTimeForNextDrop;
  }


  static isSampleAddress() {
    // console.log("timer.isSampleAddress - checking if address is sample")
    let isSampleAddress = AirdropUtils.isCurrentAddressSample();

    if (isSampleAddress === true) {
      return (<span className="sample">Demo</span>);
    }
    return "";
  }

  static renderStatus(airdropStatus) {
    let tip = "Airdrop has/may have finished.";
    let imgSrc = "/images/red-16.png";
    if (airdropStatus === 'Active') {
        tip = "Airdrop is available for claim";
        imgSrc = "/images/green-16.png"
    } else if (airdropStatus === 'Pending') {
      tip = "Airdrop is not yet released";
      imgSrc = "/images/orange-16.png"
    }

    return (<span data-for="main" data-tip={tip} data-iscapture="true"><img src={imgSrc} /></span>);
  }

}

export default AirdropUtils;
