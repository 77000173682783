import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import PageHeader from '../components/PageHeader';
import currentNetwork from '../currentNetwork';
import VotingPower from './VotingPower';
import Staking from './Staking';
import NetworkStats from './NetworkStats';
import Decentralization from './Decentralization';
import RichlistByType from '../address/RichlistByType';
import Performance from './Performance';
import './NetworkOverall.css';
import Utilities from '../util/Utilities';
import Usage from './Usage';
import SessionUtils from '../util/SessionUtils';
import UIUtils from '../util/UIUtils';
import LogoUtils from '../util/LogoUtils';
import MainUtils from '../main/MainUtils';
import Misc from './Misc';
import Delegations from '../address/Delegations';
import UrlUtils from '../util/UrlUtils';
import CompareStats from './CompareStats';
// import NetworkChartsInline from '../network/NetworkChartsInline';

class NetworkOverall extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    }

    this.updateDimensions = this.updateDimensions.bind(this);
  }


  updateDimensions() {
    this.setState({width: window.innerWidth});
  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  async componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    SessionUtils.setNetwork(this);
    this.setState({isLoading: false});
  }

  render() {
    let loadMsg = UIUtils.getLoading(this);
    if (loadMsg) return loadMsg;

    let statType = "stats";
    let extraData = null;
    if (this.props.match && this.props.match.params.statType) {
      statType = this.props.match.params.statType.toLowerCase();
    } else {
      let fragment = UrlUtils.getFragmentFromUrl();
      if (fragment) {
        statType = fragment;
      }
    }

    if (this.props.match && this.props.match.params.extraData) {
      extraData = this.props.match.params.extraData;
    }

    return (
      <div>
        <PageHeader title={currentNetwork.details.app.appName + " Network Stats"} thisObj={this} 
          customLogo={LogoUtils.getChainLogo} />
        <Tabs defaultActiveKey={statType} id="uncontrolled-tab-example" className="mb-3" unmountOnExit={true}>
            <Tab eventKey="stats" title="Stats">
              {MainUtils.shareLink("/stats")}
              <NetworkStats />
            </Tab>
            {!currentNetwork.details.app.consumerChain && (<Tab eventKey="vp" title={Utilities.respLabel("VP", "VP", "Voting Power")}>
              {MainUtils.shareLink("/stats/vp")}
              <VotingPower />
            </Tab>)}
            {!currentNetwork.details.app.consumerChain && (<Tab eventKey="staking" title="Staking">
              {MainUtils.shareLink("/stats/staking")}
              <Staking />
            </Tab>)}
            {!currentNetwork.details.app.consumerChain && (<Tab eventKey="decentralization" title={Utilities.respLabel("D14n", "Decentralization", "Decentralization")}>
              {MainUtils.shareLink("/stats/decentralization")}
              <Decentralization />
            </Tab>)}
            {currentNetwork.details.app.tendermint === true && <Tab eventKey="usage" title={Utilities.respLabel("Usage", "Usage", "Usage")}>
              {MainUtils.shareLink("/stats/usage")}
              <Usage />
            </Tab>}
            <Tab eventKey="performance" title={Utilities.respLabel("Perf", "Perf", "Performance")}>
              {MainUtils.shareLink("/stats/performance")}
              <Performance mode="inline" />
            </Tab>
            <Tab eventKey="misc" title="Misc">
              {MainUtils.shareLink("/stats/misc")}
              <Misc mode="inline" />
            </Tab>
            <Tab eventKey="compare" title="Compare">
              {MainUtils.shareLink("/stats/compare")}
              <CompareStats mode="inline" />
            </Tab>
            {currentNetwork.details.app.tendermint && (<Tab eventKey="addresses" title={Utilities.respLabel("Addresses", "Top Addr", "Addresses")}>
              {MainUtils.shareLink("/stats/addresses")}
              <RichlistByType dataType="addresses" subType={extraData} />
            </Tab>)}
            {!currentNetwork.details.app.consumerChain && (<Tab eventKey="delegates" title={Utilities.respLabel("Delegates", "Delegates", "Delegates")}>
              {MainUtils.shareLink("/stats/delegates")}
              <RichlistByType dataType="delegates" />
            </Tab>)}
            {!currentNetwork.details.app.consumerChain && (<Tab eventKey="delegations" title="Delegations">
              {MainUtils.shareLink("/stats/delegations")}
              <Delegations />
            </Tab>)}
            {/* <Tab eventKey="all" title={Utilities.respLabel("All", "All", "All")}>
              <NetworkChartsInline skipHeader={true}/>
            </Tab> */}
        </Tabs>
      </div>
    );
  }
  
}

export default NetworkOverall;
