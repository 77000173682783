import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import currentNetwork from '../currentNetwork';

import "../main/DelegateModal.css";
import MainUtils from '../main/MainUtils';
import RespUtils from '../util/RespUtils';

class CommissionTip extends Component {
    constructor(props) {
        super(props);
        this.state = {
          visible: false,
        }
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.renderModal = this.renderModal.bind(this);
        this.renderCommissionPlus = this.renderCommissionPlus.bind(this);
    }
    
    showModal(e) {
        if (this.state.visible === false) {
          this.setState({visible: true});
        } else {
          this.setState({visible: false});
        }
        e.stopPropagation();
    }
    
    hideModal(e) {
        this.setState({visible: false});
    }
      
    render() {
        return (
            <React.Fragment>
                {this.renderCommissionPlus(this.props.validator)}
                {this.renderModal(this.props.validator)}
            </React.Fragment>
        );
    }

    renderModal(validator) {
        if (this.state.visible !== true) return "";

        let token = currentNetwork.details.app.token;
        let commissionMaxRate = parseFloat(validator.commissionMaxRate);
        let commission = parseFloat(validator.commission);
        let commissionMaxChangeRate = parseFloat(validator.commissionMaxChangeRate);

        let daysToMaxCommission;
        if (validator.commission === validator.commissionMaxRate) {
            daysToMaxCommission = 0;
        } else {
            daysToMaxCommission = (commissionMaxRate - commission) / commissionMaxChangeRate;
            if (daysToMaxCommission != parseInt(daysToMaxCommission)) {
                daysToMaxCommission = 1 + parseInt(daysToMaxCommission);
            }
        }

        return (<Modal show={this.showModal} onHide={this.hideModal} size="xl" centered className="showModal">
            <Modal.Header closeButton>
                <Modal.Title>How to assess validators on Commission aspects?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <table><tbody>
                   <tr><td>Validator Name: </td><td>{MainUtils.nameFormatterLong(validator.name, validator)}</td></tr>
                   <tr><td>Current Commission: </td><td>{commission}%</td></tr>
                   <tr><td>Max Commission: </td><td>{commissionMaxRate}%</td></tr>
                   <tr><td>Max daily commission change rate: </td><td>{commissionMaxChangeRate}%</td></tr>
                </tbody></table>
                <br/>
                <p>Tips: Understand the difference between Commission, Max Commission, and Max Commission Change Rate.</p>
                <ul>
                    <li>Commission ({commission}%): Current commission charged by the validator. 
                        A commission of {commission}% means that for every 100 {token} that you earn in rewards, 
                        validator keeps {commission} {token} and you/delegate gets {100 - commission} tokens.</li>
                    <li>Max Commission ({commissionMaxRate}%): This is the maximum commission a validator can ever charge. 
                        Unless you plan on monitoring the current commission on a daily/weekly basis, 
                        delegating to validators with lower max commission value is relatively better. </li>
                    <li>Max daily commission change rate ({commissionMaxChangeRate}%): This is the maximum change in commission that a validator could do every day. 
                        A validator with 1% max daily change rate will need to increase the commission on 10 different days to increase commission by 10%.
                        A validator with 10% max daily change rate can increase the commission by 10% in 1 day.</li>
                    <li>If {validator.name} validator wanted to change commission to maximum commission, it will need to increase commission every day for at least {daysToMaxCommission} days to reach {commissionMaxRate}%.</li>
                </ul>
                <p>This is an educational tool and all examples given above are hypothetical. Not all validators intend to increase their commission to max commission. It's always a good idea to establish contact with the validators you delegate/want to delegate with and understand their offering and commission plans.</p>
            </Modal.Body>

            <Modal.Footer>
                <button className="animLink" onClick={this.hideModal}>Close</button>
            </Modal.Footer>
        </Modal>);

    }

    renderCommissionPlus(validator) {
        let value = validator.commission;
        if (this.props.commissionPlusList === true) {
            if (validator.commIncreaseTime !== null) {
                // return (<span style={{"backgroundColor": "orange", "padding": "0.2rem"}}>{validator.commIncreaseDesc}</span>);
                value = validator.commIncreaseDesc;
            } else {
                if (RespUtils.isMobileView()) {
                    value = validator.commission + " /" + validator.commissionMaxRate + " /" + validator.commissionMaxChangeRate;
                } else {
                    value = validator.commission + "% / " + validator.commissionMaxRate + "% / " + validator.commissionMaxChangeRate + "%";
                }
            }
        }
    
        let desc = "Current Commission: " + validator.commission + "%. <br/>";
        desc += "Max Commission: " + validator.commissionMaxRate + "%. <br/>";
        desc += "Max daily commission change rate: " + validator.commissionMaxChangeRate + "%.<br/>"
        desc += "Click to learn more";
        // return (<span data-for="main" data-tip={desc} data-iscapture="true"><a>{value}</a></span>);
        return (<span data-for="main" data-tip={desc} data-iscapture="true"><button className="animLinkVisual" onClick={this.showModal}>{value}</button></span>);
    }

}

export default CommissionTip;
