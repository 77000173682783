import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import ReactTooltip from "react-tooltip";
import ToolkitProvider from 'react-bootstrap-table2-toolkit';

import NetworkHeader from "../network/NetworkHeader";
import UIUtils from "../util/UIUtils";
import ApiUtils from '../util/ApiUtils';
import "../validators/Validators.css";
import '../styles/section.css';
import '../styles/tables.css';
import '../styles/validator.css';
import Utilities from "../util/Utilities";

import SessionUtils from '../util/SessionUtils';
import DownloadUtils from '../util/DownloadUtils';
import ValColumnSelector from '../validators/ValColumnSelector';
import GenFavUtils from '../util/GenFavUtils';
import AddressUtils from '../util/AddressUtils';
import CoinUtils from '../util/CoinUtils';
import RespUtils from '../util/RespUtils';
import common from '../common';
import TokenUtils from './TokenUtils';
import NetworkUtils from '../network/NetworkUtils';
import ColumnUtils from '../util/ColumnUtils';


const All = "All";
const HighHolders = "HighHolders";
const LowHolders = "LowHolders";
const IbcTokens = "IbcTokens";

class Tokens extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      tokens: [],
      orgTokens: [],
      coinStat: {},
      statusSummary: {},
      notification: {"message": null},
      tip: null,
      width: window.innerWidth,
      size: 10,
      lastUpdated: "",
      isLoading: true,
      mode: HighHolders,
    }

    this.updateDimensions = this.updateDimensions.bind(this);
    this.reload = this.reload.bind(this);
    this.favourite = this.favourite.bind(this);
    this.unfavourite = this.unfavourite.bind(this);
    this.getInitColumns = this.getInitColumns.bind(this);
    this.repaintData = this.repaintData.bind(this);
    this.handleFavChange = this.handleFavChange.bind(this);
    this.renderFilters = this.renderFilters.bind(this);
    this.showAllTokens = this.showAllTokens.bind(this);
    this.showHighTokens = this.showHighTokens.bind(this);
    this.showLowTokens = this.showLowTokens.bind(this);
    this.formatView = this.formatView.bind(this);
    this.renderSwitch = this.renderSwitch.bind(this);
    this.showIbcTokens = this.showIbcTokens.bind(this);
  }

  updateDimensions() {
    this.setState({width: window.innerWidth});
  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  async componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    SessionUtils.initNetwork(this, false);

    let url = "listData?type=tokenList&includeLowHolderCount=true"
    // if (this.props.match && this.props.match.params.includeLowHolderCount) {
    //   url += "&includeLowHolderCount=" + this.props.match.params.includeLowHolderCount;
    // }
    // console.log(url);

    const allData = await ApiUtils.get(url);
    // console.log(allData);
    if (!allData) {
      this.setState({error: true, isLoading: false});
      return;
    }

    let tokens = allData["data"];
    if (tokens) {
      let notification = allData["notification"];
      let statusSummary = allData["statusSummary"];
      let coinStat = allData["coinStat"];
      this.setState({notification: notification, statusSummary: statusSummary, 
        orgTokens: allData["data"], coinStat: coinStat, lowHolderCountLimit: allData["lowHolderCountLimit"]});
      
      this.filterData(tokens, allData["lowHolderCountLimit"], All)
    }
  }

  filterData(tokens, lowHolderCountLimit, mode) {
    // console.log("mode: ", mode);
    // console.log(tokens.length);

    let selectTokens = [];
    for (const token of tokens) {
      if (mode === HighHolders) {
        // console.log("adding high holder only");
        if (token.activeAccounts >= lowHolderCountLimit) {
          selectTokens.push(token);
        }
      } else if (mode === LowHolders) {
        // console.log("adding low holder only");
        if (token.activeAccounts < lowHolderCountLimit) {
          selectTokens.push(token);
        }
      } else {
        // console.log("adding all tokens");
        selectTokens.push(token);
      }
    }

    let newTokens = UIUtils.addIndexAndFav(selectTokens, "tokenId");
    let finalData = GenFavUtils.filterData(this, newTokens);

    this.setState({"tokens": finalData, isLoading: false});
  }

  render() {
    SessionUtils.initNetwork(this, false);

    let loadMsg = UIUtils.getLoading(this);
    if (loadMsg) return loadMsg;

    const headerSortingClasses = (column, sortOrder, isLastSorting, colIndex) => (
      sortOrder === 'asc' ? 'sorting-asc' : 'sorting-desc'
    );

    var columns = [
      {text: "Fav", desc: "Favorite indicator", hidden: this.isHidden("fav"), dataField: "fav", sort: true, formatter: GenFavUtils.favoriteFormatter, align: "left", formatExtraData: this, headerStyle: Utilities.respWidth(4, 3, 2), headerSortingClasses},
      {text: "#", desc: "Index", hidden: this.isHidden("index"), dataField: "index", sort: true, align: "left", headerStyle: Utilities.respWidth(5, 5, 2), headerSortingClasses},
      {text: "", filter: textFilter({placeholder: "Name"}), desc: "Name", hidden: this.isHidden("name"), dataField: "name", formatter: TokenUtils.tokenNameFormatter, sort: true, headerStyle: Utilities.respWidth(30, 25, 15), headerSortingClasses},
      {text: "", filter: textFilter({placeholder: "Token"}), desc: "Token", hidden: this.isHidden("token"), dataField: "token", formatter: TokenUtils.tokenFormatter, sort: true, headerStyle: Utilities.respWidth(15, 15, 8), headerSortingClasses},
      {text: "Denom",  desc: "Denom", hidden: this.isHidden("denom"), dataField: "denom", formatter: AddressUtils.addressWithCopy, sort: true, headerStyle: Utilities.respWidth(35, 25, 20), headerSortingClasses},
      {text: "Total Supply",  desc: ("Total Supply"), hidden: this.isHidden("totalSupply"), dataField: "totalSupply", formatter: CoinUtils.supplyFormatterRounded, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(25, 15, 10), headerSortingClasses},
      {text: Utilities.respLabel("MCap", "Market Cap", "Market Cap"),  desc: "Market Cap of the network", hidden: this.isHidden("usdMcap"), dataField: "usdMcap", formatter: NetworkUtils.mcapFormatter, sort: true, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
      {text: "FDV",  desc: "FDV", hidden: this.isHidden("fdvMcap"), dataField: "fdvMcap", formatter: NetworkUtils.mcapFormatter, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 9), headerSortingClasses},
      {text: "Holders",  desc: "Total number of holders", hidden: this.isHidden("activeAccounts"), dataField: "activeAccounts", sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(15, 15, 10), headerSortingClasses},
      {text: "Change in Holders (1d)",  desc: "Change in number of holders in 1d", hidden: this.isHidden("deltaActiveAccounts"), dataField: "deltaActiveAccounts", formatter: NetworkUtils.formatGrowth, sort: true, sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(25, 15, 12), headerSortingClasses},
      {text: "Analytics",  desc: "Token Analytics", hidden: this.isHidden("analytics"), dataField: "analytics", sort: false, formatter: this.renderAnalytics, headerStyle:  Utilities.respWidth(30, 15, 10)},
    ];

    return (
      <div>
        <NetworkHeader coinStat={this.state.coinStat} title="Tokens" thisObj={this} beta={false} titleOnly={true} 
          additionalLink={this.renderSwitch} disableStakeFormatter={true}/>
        <p/>
        <ReactTooltip id="main" place="top" type="dark" effect="float" multiline={true} />
        <ToolkitProvider keyField="tokenId" data={this.state.tokens} columns={columns} columnToggle>
          {
            props => (
              <div id="mainTable">
                <ValColumnSelector settingKey={common.TOKENS_KEY} getInitColumns={this.getInitColumns} renderFilters={this.renderFilters} 
                    statusSummary={this.state.statusSummary} { ...props.columnToggleProps } refreshParent={this.repaintData}
                    fsElementId="mainTable" hideFullScreenLink={true} status={this.state.mode}
                    title="Tokens" downloadData={DownloadUtils.getTableDownloadData(this.state.tokens, columns)} />
                  <BootstrapTable { ...props.baseProps } filter={ filterFactory() } condensed noDataIndication="No records" />
              </div>
            )
          }
        </ToolkitProvider>
        <p/>
      </div>
    );
  }

  reload() {
    window.location = "/";
  }

  getInitColumns() {
    let columnsConfig = {};
    if (RespUtils.isTabletView()) {
      columnsConfig = {
        index: true, fav: false, name: false, denom: true, token: true, 
        activeAccounts: false, deltaActiveAccounts: true, totalSupply: true,
        usdMcap: true, fdvMcap: true, priceChangeUsd: true, 
        txCount24h: true, activeAddress24h: true, analytics: false, 
      };
    } else {
      columnsConfig = {
        index: false, fav: false, name: false, denom: true, token: false, 
        activeAccounts: false, deltaActiveAccounts: false, totalSupply: false,
        usdMcap: false, fdvMcap: false, priceChangeUsd: false, 
        txCount24h: true, activeAddress24h: true, analytics: false
      };
    }
    
    return columnsConfig;
  }

  isHidden(dataField) {
    return ColumnUtils.isHidden(common.TOKENS_KEY, dataField, this.getInitColumns);
  }

  repaintData() {
    let tokens = this.state.tokens;
    let newData = Utilities.addIndex(tokens);
    this.setState({networks: newData});
  }

  getFavIdField() {
    return "tokenId";
  }

  getFavStorageKey() {
    return SessionUtils.getNetwork() + "-tokens";
  }

  unfavourite(id) {
    GenFavUtils.unfavouriteById(id, this);
  }

  favourite(id) {
    GenFavUtils.favouriteById(id, this);
  }

  renderSwitch() {
    return GenFavUtils.showFavSwitch(this);
  }

  handleFavChange(newToken) {
    // console.log(newNetwork);
    // this.prepareData(newNetwork, this.state.mode, this.state.frequency);
    // this.prepareData(newNetwork, this.state.status);
    if (newToken === "all") {
      GenFavUtils.switchToAll(this);
    } else {
      GenFavUtils.switchToFav(this);
    }
    this.filterData(this.state.orgTokens);
  }
 
  renderFilters() {
    return (<div><table><tbody><tr>
        {this.formatView("All Tokens", "All", this.state.statusSummary.All, All, this.showAllTokens)}
        {this.formatView(">100 Holders", ">100", this.state.statusSummary.HighHolders, HighHolders, this.showHighTokens)}
        {this.formatView("<100 Holders", "<100", this.state.statusSummary.LowHolders, LowHolders, this.showLowTokens)}
        {this.formatView("IBC Tokens", "IBC", this.state.statusSummary.ibcTokens, IbcTokens, this.showIbcTokens)}
      </tr></tbody></table></div>);
  }

  formatView(label, shortLabel, count, mode, onClickFn) {
    let size = RespUtils.isMobileView() ? "fa-sm" : "fa-lg";
    let displayLabel = size === "fa-sm" ? shortLabel : label;
    displayLabel += ": " + count;
    if (this.state.mode === mode) {
      // return <b><u>{displayLabel}</u></b>
      return <td className="view-tag-selected"><a className="animLinkVisual" onClick={onClickFn}>{displayLabel}</a></td>

    }

    return <td className="view-tag"><a className="animLink" onClick={onClickFn}>{displayLabel}</a></td>;
  }


  showAllTokens() {
    this.setState({mode: All});
    this.filterData(this.state.orgTokens, this.state.lowHolderCountLimit, All);
  }

  showHighTokens() {
    this.setState({mode: HighHolders});
    this.filterData(this.state.orgTokens, this.state.lowHolderCountLimit, HighHolders);
  }

  showLowTokens() {
    this.setState({mode: LowHolders});
    this.filterData(this.state.orgTokens, this.state.lowHolderCountLimit, LowHolders);
  }

  showIbcTokens() {
    this.setState({mode: IbcTokens});
    let selectTokens = [];
    for (const token of this.state.orgTokens) {
      if (token.ibcInd == "true") {
        selectTokens.push(token);
      }
    }

    let newTokens = UIUtils.addIndexAndFav(selectTokens, "tokenId");
    let finalData = GenFavUtils.filterData(this, newTokens);

    this.setState({"tokens": finalData, isLoading: false});
  }

  renderAnalytics(cell, row) {
    let size = RespUtils.isMobileView() ? "fa-sm" : "fa-lg";
    let analyticsClassChart = "mediaCustom fas fa-chart-pie " + size;
    let analyticsClassList = "mediaCustom fas fa-list " + size;

    return (<span>
              <button className="animLinkMedia animLinkMenu animLink"><a 
                href={SessionUtils.getUrl("/tokens/" + row.token + "/history")} title="history" 
                className={analyticsClassChart} /></button>
              <button className="animLinkMedia animLinkMenu animLink"><a 
                href={SessionUtils.getUrl("/tokens/" + row.token)} title="richlist" 
                className={analyticsClassList} /></button>
      </span>);
  }
}

export default Tokens;
