import React from 'react';
import DataUtils from '../util/DataUtils';


class NetworkChartUtils extends React.Component {
  static getIndexes(keyItems, vpCurves) {
      let count = 0;
  
      for (const keyDetails of keyItems) {
        let key = keyDetails["value"];
        // console.log(key);
        
        let curve = vpCurves[key];
        // console.log(curve);
        if (curve) {
          let currentCount = curve.length;
          // console.log(currentCount);
          if (count < currentCount) {
            count = currentCount;
          }
        }
      }
  
      return DataUtils.getIndexArray(count);
  }
}

export default NetworkChartUtils;
