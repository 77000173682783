export default {
  DASHBOARDS_URL2: "https://static.smartstake.io/resources/localdashboards.json",
  DASHBOARDS_URL: "https://static.smartstake.io/resources/dashboardsnew.json",
  networkLogoBaseUrl: "https://static.smartstake.io/chains/",
  assetsUrl: "https://static.smartstake.io/",
  HOME_NETWORK_KEY: "HOME_NETWORK_KEY_1",
  NETWORKS_KEY: "NETWORKS_V6",
  TOKENS_KEY: "TOKENS_V1",
  allNetworks: {"LUNA": "terra", "AKT": "akash", "ATOM": "cosmos", "BAND": "band", 
    "CORE": "coreum", "CRO": "crypto-org", "EVMOS": "evmos", 
    "FET": "fetch", "FLIX": "omniflix", "INJ": "injective", "JUNO": "juno", "KUJI": "kujira", 
    "MATIC": "polygon", "OSMO": "osmosis", "QCK": "quicksilver", 
    "STARS": "stargaze", "STRD": "stride", "WHALE": "migaloo", "XPRT": "persistence", 
    "NTRN": "neutron", "DYDX": "dydx", "XION": "xion", "ELYS": "elys",
    "TIA": "celestia", "IRIS": "irisnet", "FURY": "furya", "ANDR": "andromeda", 
    "DYM": "dymension", "PRYZM": "pryzm", 
    "RUNE": "https://thorchain.smartstake.io"
  },
  optInChains: ["ELYS"],
  deleted: {"SEI": "sei", "QSR": "quasar", "KYVE": "kyve", "PASG": "passage", 
    "UMEE": "ux", "SAGA": "saga", "CRE": "crescent", "MARS": "mars", 
    "TARCH": "archway", "BLD": "agoric", "ARCH": "archway", 
  },
  basic: {
    baseKeyForValidators: "VALIDATORS_LIST_KEY_14_",
    baseKeyForProposals: "PROPOSALS_LIST_KEY_2_",
    baseKeyForTokens: "TOKENS_LIST_KEY_1_",
  },
  urls: {
    giniExplanation: "https://medium.com/@terra.validator.watchtower/terra-decentralization-july-2021-e9029d7a221b",
    nakamotoIndex: "https://crosstower.com/resources/education/nakamoto-coefficient/",
    aliasUrl: "https://www.github.com/smartstake/richlists",
  },
  integrations: {
    leap: "https://cosmos.leapwallet.io/transact/stake/plain?chain=",
    keplr: "https://wallet.keplr.app/chains/",
    mintscan: "https://mintscan.io/",
    pingpub: "https://ping.pub/",
    polkachu: "https://polkachu.com/networks/",
    nodestake: "https://explorer.nodestake.org/",
    restake: "https://restake.app/",
    station: "https://dashboard.station.money/",
    kjnodes: "https://explorer.kjnodes.com/",
    nodestake: "https://explorer.nodestake.org/",
    nodesguru: "https://$context.explorers.guru/",
    cosmosdirectory: "https://cosmos.directory/",
    logo: {
      keplr: "https://wallet.keplr.app/keplr-brand-assets/keplr-logo.svg",
      leap: "https://static.smartstake.io/integrations/leap.jpg",
      mintscan: "https://www.mintscan.io/assets/icons/cosmostation.png",
      pingpub: "https://ping.pub/assets/logo-fea46f13.svg",
      polkachu: "https://s3.amazonaws.com/keybase_processed_uploads/d56ce0bdda17f73d4aa895d1626e2505_360_360.jpg",
      restake: "https://static.smartstake.io/integrations/restake.png",
      station: "https://static.smartstake.io/integrations/station.svg",
      coingecko: "https://static.smartstake.io/integrations/coingecko.jpg",
      kjnodes: "https://services.kjnodes.com/assets/images/logo.png",
      nodestake: "https://static.smartstake.io/integrations/nodestake.svg",
      nodesguru: "https://static.smartstake.io/integrations/nodesguru.svg",
      cosmosdirectory: "https://static.smartstake.io/integrations/cosmosdirectory.webp",
    }
  },
  enableTxs: true,
  removeOracleColumns: ["currentOraclePerSigned", "currentOracleMissed", "dailyOraclePerSigned", "dailyOracleMissed", "midOraclePerSigned", "midOracleMissed", "longOraclePerSigned", "longOracleMissed"],
  haltPower: 33.34,
  majorityPower: 66.67,
  commission: {
    maxChangeRate: 5,
    maxCommission: 20,
  },
  colors: {
    text: "white",
    chartColor1: "darkgray",
    chartColor2: "lightblue",
    chartColor3: "lightgreen",
    chartColor4: "#faa84b",
    chartColor5: "yellow",
    chartColor6: "purple",
    chartColor7: "green",
    chartColor8: "blue",
    chartColor9: "lightgray",
    chartColorBright: "red",

    "--color-text": "white",
    "--color-text-gray": "#6d738c",
    "--color-background": "#212024",
    "--color-smart-primary": "#faa84c",
    "--color-smart-primary-bright": "#ffc107",
    "--color-smart-main-surface": "#0f1011",
    "--color-smart-main-surface--accent": "#1b191b",
    "--color-custom-surface": "#17141a",
    chartColorsArray: ["#faa84b", "green", "red", "lightblue", "lightgreen", "lightgray", "purple", "blue", "yellow"],
    chartColorsArray2: ["#faa84b", "green", "red", "purple", "blue", "#F3B6F1", "#B46C8C", "#44C4CA", 
      "#15534C", "#6C4623", "#E2E062", "#3B1B1C","#8D4E60","#64333B","#319C9D","#523219",
      "lightgray", "#498F60", "#5BECFB", ],
  },
  screenTour: {
    validators: [
          {selector: '.view-tag', content: 'Filter validators by status'},
          {selector: '.tourColumnSettings',content: 'Change comparison data for assessing validators (one-time setting)'},
          {selector: '.tourView',content: 'Switch to different views based on assessment needs'},
          {selector: '.tourFavorite', content: 'Select/unselect favorites (one-time setting)'},
          {selector: '.tourValName', content: 'Click validator name to open validator home page'},
          {selector: '.tourHelp',content: 'Show this help again. Click X or anywhere in the screen to close.'},
        ]
  },
  menuStyle: {
    borderRadius: '3px',  boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
    background: 'rgba(255, 255, 255, 1)', padding: '2px 0',
    fontSize: '90%', position: 'fixed',
    overflow: 'auto', maxHeight: '50%',  
    zIndex: '998'
  },
  proposal: {
    vote: {"VOTE_OPTION_YES": "Yes",
      "VOTE_OPTION_NO": "No",
      "VOTE_OPTION_ABSTAIN": "Abstain",
      "VOTE_OPTION_NO_WITH_VETO": "Veto",
    },
    finalStatuses: ["PROPOSAL_STATUS_REJECTED", "PROPOSAL_STATUS_PASSED"],
    votingStatus: "PROPOSAL_STATUS_VOTING_PERIOD",
  },
  txs: {
    categories: [{category: "large", label: "Large", respLabel: "Large"}, 
      {category: "delegation", label: "Delegations", respLabel: "Del"}, 
      {category: "undelegation", label: "Undelegations", respLabel: "Undel"}, 
      {category: "redelegation", label: "Redelegations", respLabel: "Redel"},
      {category: "staking", label: "Staking", respLabel: "Staking"}, 
      {category: "vesting", label: "Vesting", respLabel: "Vesting", networks: ["TIA", "DYM", "SAGA", "ANDR"]},
    ],
    vestingNetworks: ["TIA", "DYM", "SAGA", "ANDR"],
    categories2: [{category: "valupdate", label: "Val Updates", respLabel: "Val Updates"}],
    categoryTitles: {"large": "Recent Large Transactions", "staking": "Recent Staking Transactions", 
      "valupdate": "Recent Validator Updates", "delegation": "Recent Delegation Transactions", 
      "undelegation": "Recent Undelegation Transactions", "redelegation": "Recent Redelegation Transactions",
      "vesting": "Recent Vesting Wallet Transactions"}
  },
  ui: {
    validatorCriteria: {
      SEARCH_ALL: "Search All",
      COMMISSION_LOWER_THAN: 'Commission Lower Than',
      MAX_COMMISSION_LOWER_THAN: 'Max Commission Lower Than',
      MAX_COMMISSION_CHANGE_RATE_LOWER_THAN: 'Max Commission Change Rate Lower Than',
      VOTING_POWER_PERCENTAGE_LESS_THAN: 'Voting Power Percentage Less Than',
      VALIDATOR_NAME: 'Validator Name',
      VALIDATOR_OPERATOR_ADDRESS: "Validator's Operator Address"
    }
  },
  moreViews: {
    hidden: "hidden",
    partial: "partial",
    full: "full",
    factor: {hidden: 1, partial: 2, full: 10}
  },
  charts: {
    mode: {
      chart: "chart",
      table: "table",
      default: "chart"
    }
  },
  allCompareMetrics: [
    {description: "24h Active Addresses", code: "activeAddresses", coinStatAttr: "activeAddress24h"}, 
    {description: "Addresses (>0)", code: "activeAccounts", coinStatAttr: "activeAccounts"}, 
    {description: "Average Delegation", code: "averageDelegation", coinStatAttr: "averageDelegation"}, 
    {description: "Average Delegation USD", code: "averageDelegationUsd", coinStatAttr: "averageDelegationUsd"}, 
    {description: "Avg Voting Power", code: "avgVotingPower", coinStatAttr: "avgVotingPower"}, 
    {description: "Block Rate", code: "blockRate", coinStatAttr: "blockRate"}, 
    {description: "Block Sign Rate", code: "signRate", coinStatAttr: "signRate"}, 
    {description: "Circulating Supply", code: "circulatingSupply", coinStatAttr: "circulatingSupply"}, 
    {description: "Commission", code: "medianCommission", coinStatAttr: "medianFee"}, 
    {description: "Consensus Validators", code: "consensusValidators", coinStatAttr: "consensusValidators"}, 
    {description: "Delegates", code: "uniqueDelegates", coinStatAttr: "uniqueDelegates"}, 
    {description: "Delta Active Addresses", code: "deltaActiveAccounts", coinStatAttr: "deltaActiveAccounts"}, 
    {description: "Failed Txs", code: "failedTxs", coinStatAttr: "failedTxs"}, 
    {description: "Fdv Mcap", code: "fdvMcap", coinStatAttr: "fdvMcap"}, 
    {description: "Gini Coefficient", code: "gini", coinStatAttr: "gini"}, 
    {description: "Last Validator Stake", code: "lastValStake", coinStatAttr: "lastValStake"},
    {description: "Last Validator Stake USD", code: "lastValStakeUsd", coinStatAttr: "lastValStakeUsd"}, 
    {description: "Market Cap USD", code: "usdMcap", coinStatAttr: "usdMcap"}, 
    {description: "Nakamoto Coefficient", code: "nakamotoIndex", coinStatAttr: "nakamotoIndex"}, 
    {description: "Price USD", code: "usdPrice", coinStatAttr: "usdPrice"}, 
    {description: "Recent Avg Gov Votes", code: "recentAvgVotes", coinStatAttr: "recentAvgVotes"}, 
    {description: "Recent Max Gov Votes", code: "recentMaxVotes", coinStatAttr: "recentMaxVotes"}, 
    {description: "Staking Apr", code: "apr", coinStatAttr: "currentRewardRate"}, 
    {description: "Successful Txs", code: "successfulTxs", coinStatAttr: "successfulTxs"}, 
    {description: "Top 100 Stake Share", code: "top100StakeShare", coinStatAttr: "top100StakeShare"}, 
    {description: "Top Val Equals N Bottom By %", code: "oneEqualsNBottomValPercent", coinStatAttr: "oneEqualsNBottomValPercent"}, 
    {description: "Total Stake", code: "totalStake", coinStatAttr: "totalStake"}, 
    {description: "Total Stake USD", code: "totalStakeUsd", coinStatAttr: "totalStakeUsd"}, 
    {description: "Total Supply", code: "totalSupply", coinStatAttr: "totalSupply"}, 
    {description: "Tx Count", code: "txCount", coinStatAttr: "txCount24h"}, 
    {description: "Unbonded Stake %", code: "unbondedStakePercent", coinStatAttr: "unbondedStakePercent"}, 
    {description: "Unbonded Stake USD", code: "unbondedStakeUsd", coinStatAttr: "unbondedStakeUsd"}, 
    {description: "Undel % Of Not Staked Supply", code: "undelPercentOfNotStakedSupply", coinStatAttr: "undelPercentOfNotStakedSupply"}, 
    {description: "Undel % Of Total Supply", code: "undelPercentOfTotalSupply", coinStatAttr: "undelPercentOfTotalSupply"}, 
    {description: "Undelegation Amount", code: "undelegationAmount", coinStatAttr: "totalUndelegationAmount"}, 
    {description: "Unique Delegations", code: "uniqueDelegations", coinStatAttr: "uniqueDelegations"}, 
    {description: "Unique Undelegations", code: "uniqueUndelegations", coinStatAttr: "uniqueUndelegations"}, 
    {description: "Val Below Avg VP", code: "valBelowAvgPower", coinStatAttr: "valBelowAvgPower"}, 
  ]
};

export const maxMediaLinksMobile = 3;
export const maxIntegrationLinksMobile = 3;
