import React from 'react';
// import numeral from 'numeral';
// import Breadcrumb from 'react-bootstrap/Breadcrumb';

// import SPUtilities from '../util/SPUtilities';
// import RespUtils from '../util/RespUtils';

// import SPCalc from '../util/SPCalc';
// import {CopyToClipboard} from 'react-copy-to-clipboard';
// import currentNetwork from "../currentNetwork";
import MainUtils from '../main/MainUtils';

class SignFmtUtils extends React.Component {

  static currentSignedFmt(cell, row) {
    return SignFmtUtils.signedFmt(cell, "8 hours");
  }

  static dailySignedFmt(cell, row) {
    return SignFmtUtils.signedFmt(cell, "1 day");
  }

  static midSignedFmt(cell, row) {
    return SignFmtUtils.signedFmt(cell, "10 days");
  }

  static longSignedFmt(cell, row) {
    return SignFmtUtils.signedFmt(cell, "30 days");
  }

  static signedFmt(cell, freq) {
    let value = cell
    if (!value) {
      value = "0";
    }

    let desc = "# of blocks signed in last " + freq + ": " + value + ".<br/>";
    return SignFmtUtils.fmtWithTip(value, desc);
  }

  static currentMissedFmt(cell, row) {
    return SignFmtUtils.missedFmt(cell, "8 hours");
  }

  static dailyMissedFmt(cell, row) {
    return SignFmtUtils.missedFmt(cell, "1 day");
  }

  static midMissedFmt(cell, row) {
    return SignFmtUtils.missedFmt(cell, "10 days");
  }

  static longMissedFmt(cell, row) {
    return SignFmtUtils.missedFmt(cell, "30 days");
  }

  static missedFmt(cell, freq) {
    let value = cell
    if (!value) {
      value = "0";
    }

    let desc = "# of blocks missed in last " + freq + ": " + value + ".<br/>";
    return SignFmtUtils.fmtWithTip(value, desc);
  }

  static currentPerSignedFmt(cell, row) {
    return SignFmtUtils.perSignedFmt(cell, "8 hours");
  }

  static dailyPerSignedFmt(cell, row) {
    return SignFmtUtils.perSignedFmt(cell, "1 day");
  }

  static midPerSignedFmt(cell, row) {
    return SignFmtUtils.perSignedFmt(cell, "10 days");
  }

  static longPerSignedFmt(cell, row) {
    return SignFmtUtils.perSignedFmt(cell, "30 days");
  }

  static slashingWindowSignPerFmt(cell, row) {
    if (row.status != "Bonded") return "";

    let value = cell
    if (!value) {
      value = 0;
    }

    let color = "orange";

    value = value.toFixed(1);

    if (!value || value < 90) {
      color = "red";
    } else if (cell > 98) {
      color = "green";
    }

    value = parseFloat(value) + "%";
    let desc = "% of blocks signed in slashing window.<br/>";
    return (<span data-for="main" data-tip={desc} data-iscapture="true">{MainUtils.colorFormatter(value, color)}</span>);
  }

  static perSignedFmt(cell, freq) {
    let value = cell
    if (!value) {
      value = 0;
    }

    let color = "orange";

    value = value.toFixed(1);

    if (!value || value < 90) {
      color = "red";
    } else if (cell > 98) {
      color = "green";
    }

    value = parseFloat(value) + "%";
    let desc = "% of blocks signed in last " + freq + ": " + value + ".<br/>";
    return (<span data-for="main" data-tip={desc} data-iscapture="true">{MainUtils.colorFormatter(value, color)}</span>);
  }

  static fmtWithTip(value, desc) {
    if (!value) {
      // console.log((typeof cell), " - in percentFormatter: ", cell);
      value = "";
    }

    return (<span data-for="main" data-tip={desc} data-iscapture="true">{value}</span>);
  }

}

export default SignFmtUtils;
