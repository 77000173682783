import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ReactTooltip from 'react-tooltip';

import Loading from '../components/Loading';
import PageHeader from '../components/PageHeader';
import currentNetwork from '../currentNetwork';
import "../styles/section.css";
import ApiUtils from '../util/ApiUtils';
import SPUtilities from '../util/SPUtilities';
import Utilities from '../util/Utilities';
import Airdrop from './Airdrop';
import "./Airdrops.css";
import SessionUtils from '../util/SessionUtils';
import LogoUtils from '../util/LogoUtils';


class Airdrops extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      airdrops: [],
      coinStat: {},
      isLoading: true,
    };
    this.renderAirdrops = this.renderAirdrops.bind(this);
  }

  async componentDidMount() {
    SessionUtils.setNetwork(this);
    let url = "listData?type=chainAirdrops";
    // console.log(url);
    const allData = await ApiUtils.get(url);
    // console.log(allData);

    if (allData) {
      let notification = allData["notification"];
      let airdrops = Utilities.addIndex(allData["airdrops"]);

      this.setState({
        "notification": notification, "airdrops": airdrops, 
        "coinStat": allData["coinStat"], isLoading: false
      });
    }
  }

  render() {
    if (this.state.isLoading) {
      return <Loading />;
    }
    // console.log("this.state.coinStat.currentRewardRate: ", this.state.coinStat.currentRewardRate);

    return (
      <div>
        <PageHeader title={"Airdrops for $" + currentNetwork.details.app.token + " Stakers"} thisObj={this} 
          customLogo={LogoUtils.getChainLogo} />
        <p />

        <ReactTooltip id="main" place="top" type="dark" effect="float" multiline={true} />
        {this.renderAirdrops()}
        <p>
          Want your/your favorite project listed here? Reach out on 
          <span className="socialMedias">
            <a href="https://twitter.com/SmartStake" className="fab fa-twitter fa-lg imageMargin" />
          </span>
        </p>
      </div>
    );
  }

  renderAirdrops() {
    return (
      <React.Fragment>
        <br/>
        <Container fluid>
          <Row>
            {this.state.airdrops.map(function (airdrop) {
              if (airdrop !== "") {
                return (<Airdrop airdrop={airdrop} />)
              }
            })}
          </Row>
        </Container>
      </React.Fragment>
    );
  }


  claimFormatter(cell, row, index, extra) {
    const onClick = e => e.stopPropagation();
    return (<span onClick={onClick}><a href={SPUtilities.getUrl(row.airdropWebsite)}
      target="_blank"><button className="animLink linkBorder" id={"Claim" + row.projectId}
      >Claim</button></a></span>);
  }


}

export default Airdrops;
