import React, { Component } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import ApiUtils from '../util/ApiUtils';
import LogoUtils from '../util/LogoUtils';
import RespUtils from '../util/RespUtils';
import SPUtilities from '../util/SPUtilities';
import "./Special.css";
import constants from '../constants';


class Special extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      responsive: true,
      isLoading: true,
      enabled: constants.adEnabled,
    }
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  updateDimensions() {
    this.setState({width: window.innerWidth});
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  async componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);

    if (this.state.enabled === false) {
      return;
    }
    
    let url = "special";

    // console.log(url);
    const allData = await ApiUtils.getSpecial(url);
    // console.log("allData is:", allData);

    if (allData) {
      this.setState({"data": allData});
    }
  }


  render() {
    if (this.state.enabled === false) {
      return "";
    }

    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 1000,
      autoplaySpeed: 5000,
      pauseOnHover: true,
      swipeToSlide: true,
      draggable: true,
    };
    return (
      <div className="specialSlider">
        <Slider {...settings}>
          {this.state.data.map(function(project){
            // console.log(project);
            if (project.adType === 'REG') {
              return Special.renderImage(project);
            } else if (project.adType === 'MSG') {
              return Special.renderMessage(project);
            // } else if (project.adType === 'PROJECT') {
            //   return Special.renderProject(project);
            }
          })}
        </Slider>
      </div>
    );
  }
//   <div className="specialComponent">
//   {this.("")}
// </div>
// {this.renderProject()}


  static renderProject(project) {
    return (<div className="specialProject" key={project.adId}>
      </div>);
  }

  static renderMessage(project) {
    return (<div className="specialProjectMessage" key={project.adId}>
          <div className="projectLabel">
            <span className="projectLogo">{LogoUtils.projectLogo(project.logo, project)}</span>
            <span className="projectTitle"><a href={SPUtilities.getUrl(project.adLink)} className="adLink" target="_blank">
              {Special.projectFormatter(project.name, project)} - {project.adTitle}</a></span>
          </div>
          <a href={SPUtilities.getUrl(project.adLink)} className="adLink" target="_blank">
            <p className="projectMsg">
                {project.message}
            </p>
          </a>
      </div>);
  }
//   <a href={SPUtilities.getUrl(project.adLink)} target="_blank" className="regularA">
//   <b>{Special.projectLogo(project)} {project.name} - {project.adTitle}</b>
//   <p>{project.message}</p>
// </a>

  static renderImage(project) {
    let basePath = constants.SPECIAL_URL;
    let imagePath = basePath + project.bannerUri;

    if (RespUtils.isMobileView()) {
      imagePath = basePath + project.bannerUriSm;
    } else if (RespUtils.isTabletView()) {
      imagePath = basePath + project.bannerUriMd;
    }

    // console.log("project: ", row.name, ", logo: ", logo);

    // console.log(imagePath);
    // return (<object type="image/png" width={size} height={size} data={imagePath}>
    //   <img className="img-valign" width={size} height={size} src="images/logo/default.png" />
    // </object>); width={size} height={size} 


    return (<div className="specialComponent" key={project.adId}>
        <a href={SPUtilities.getUrl(project.adLink)} target="_blank"><img onError={Special.addDefaultSrc} className="specialImage"
          src={imagePath}/></a> 
      </div>);
  }

  static addDefaultSrc(ev){
    let imagePath = "/images/smartstake/ad.jpg";
    if (RespUtils.isMobileView()) {
      imagePath = "/images/smartstake/ad-sm.jpg";;
    } else if (RespUtils.isTabletView()) {
      imagePath = "/images/smartstake/ad-md.jpg";;
    }

    ev.target.src = imagePath;
  }

  static projectLogo(row) {
    let size = LogoUtils.getImageSize(true);

    let imagePath = "/images/terradefault.png"

    if (row.logo && row.logo !== "") {
      imagePath = constants.PROJECT_LOGO_BASE_URL + row.logo;
    }

    return (<img style={{"display": "inline"}} onError={this.addDefaultSrc} className="logoStyle" 
      width={size} height={size} src={imagePath}/>);
  }

  static projectFormatter(cell, row) {
    // console.log(row);
    return (<a href={SPUtilities.getUrl(row.website)} className="animLinkVisual">{cell}</a>);
  }

}

export default Special;
