import React from 'react';

class SavedSettings extends React.Component {
  static isValHelpNeeded(thisObj) {
    //   console.log("isValHelpNeeded: ", !SavedSettings.isEnabled("VAL_HELP_NEEDED"));
    // console.log(thisObj.props.match);
    if (thisObj.props.match && thisObj.props.match.params.skipTour === 'true') {
      return false;
    }

    return SavedSettings.isEnabled("VAL_HELP_NEEDED", true);
  }

  static setValHelpNotNeeded(thisObj) {
    // console.log("closing help");
    thisObj.setState({isHelpOpen: false});
    SavedSettings.setValue("VAL_HELP_NEEDED", "false");
    // console.log("setValHelpNotNeeded: ", !SavedSettings.isEnabled("VAL_HELP_NEEDED"));
  }

  static setValHelpNeeded(thisObj) {
    // console.log("show help");
    thisObj.setState({isHelpOpen: true});
    SavedSettings.setValue("VAL_HELP_NEEDED", "true");
    // console.log("setValHelpNeeded: ", !SavedSettings.isEnabled("VAL_HELP_NEEDED"));
  }

  static isEnabled(key, defaultValue) {
    let value = window.localStorage.getItem(key);
    // console.log("isEnabled: value: ", value);
    if (value !== null) {
        return value === "true";
    }

    return defaultValue;
  }

  static setValue(key, value) {
    window.localStorage.setItem(key, value);
  }
}

export default SavedSettings;
