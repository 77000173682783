import React from 'react';
import { Modal } from 'react-bootstrap';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';

import "../main/DelegateModal.css";
import '../styles/section.css';
import '../styles/tables.css';
import './ValColumnSelector.css';
import StatusTagList from './StatusTagList';
import RespUtils from '../util/RespUtils';
import FavUtils from '../util/FavUtils';



class ValColumnSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSettings: false,
      // isSearchModalOpen: false
    }

    this.showColumnSettings = this.showColumnSettings.bind(this);
    this.hideColumnSettings = this.hideColumnSettings.bind(this);
    this.resetColumnSettings = this.resetColumnSettings.bind(this);
    this.handleViewChange = this.handleViewChange.bind(this);
    // this.toggleSearchModal = this.toggleSearchModal.bind(this);
  }

  // toggleSearchModal() {
  //   this.setState(prevState => ({ isSearchModalOpen: !prevState.isSearchModalOpen }));
  // }

  async componentDidMount() {
    // window.localStorage.removeItem(this.props.settingKey);
    this.getColumnConfig();
  }

  getKey() {
    // console.log("in getKey of val selector: " + this.props.defaultView);

    if (this.props.defaultView) {
      return FavUtils.getKey(this.props.settingKey + "-" + this.props.defaultView);
    }
    return FavUtils.getKey(this.props.settingKey);
  }

  updateSetting(dataField) {
    // console.log("in updateSetting");
    let columnsConfig = this.getColumnConfig();
    // console.log(columnsConfig);

    // console.log("in updateSetting for: ", dataField);
    // console.log("current setting for: ", dataField, " is: ", columnsConfig[dataField]);
    columnsConfig[dataField] = !columnsConfig[dataField];
    // console.log("new setting for: ", dataField, " is: ", columnsConfig[dataField]);

    window.localStorage.setItem(this.getKey(), JSON.stringify(columnsConfig));
    
    // console.log(this.getColumnConfig());
    // console.log("end of updateSetting");
  }

  getColumnConfig() {
    let columnsConfig = window.localStorage.getItem(this.getKey());
    if (!columnsConfig) {
      let initConfig = this.initConfig();
      // console.log("returning default config");
      return initConfig;
    }

    // make sure atleast one column is selected
    let finalConfig = JSON.parse(columnsConfig);
    let visibleColumns = 0;
    for (var key of Object.keys(finalConfig)) {
      if(finalConfig[key] === false) {
        visibleColumns++;
      }
    }
    // console.log(visibleColumns);

    if (visibleColumns === 0) {
      finalConfig["symbol"] = false;
      window.localStorage.setItem(this.getKey(), JSON.stringify(finalConfig));
    }

    return finalConfig;
  }

  initConfig() {
    // console.log("current config: ", JSON.parse(window.localStorage.getItem(this.props.settingKey)));
    
    let columnsConfig = this.props.getInitColumns();
    // console.log("default column config: ", columnsConfig);
    window.localStorage.setItem(this.getKey(), JSON.stringify(columnsConfig));
    // console.log("final column config: ", columnsConfig);
    // console.log("new config: ", JSON.parse(window.localStorage.getItem(this.props.settingKey)));
    return columnsConfig;
  }

  showColumnSettings() {
    // console.log("in showColumnSettings");
    if (this.state.showSettings === false) {
      this.setState({showSettings: true});
    } else {
      this.setState({showSettings: false});
    }
  }

  hideColumnSettings() {
    // console.log("in hideColumnSettings");
    this.setState({showSettings: false});
  }

  resetColumnSettings() {
    // console.log("in reset settings");
    this.initConfig();
    this.setState({showSettings: false});
    this.props.refreshParent();
    // console.log("end of reset settings");
  }

  isHidden(dataField) {
    return this.getColumnConfig()[dataField];
  }

  render() {
    // const { ToggleList } = ColumnToggle;
    return (
        <React.Fragment>
          <StatusTagList showColumnSettings={this.showColumnSettings} showHelp={this.props.showHelp} renderFilters={this.props.renderFilters} 
              statusSummary={this.props.statusSummary} fsElementId={this.props.fsElementId} hideFullScreenLink={this.props.hideFullScreenLink} 
              downloadData={this.props.downloadData} title={this.props.title} status={this.props.status} 
              views={this.props.viewsData ? this.props.viewsData.views : null} handleViewChange={this.handleViewChange} defaultView={this.props.defaultView}
              supportMoreView = {this.props.supportMoreView} moreView = {this.props.moreView} toggleMoreView={this.props.toggleMoreView}
              handleFilter={this.props.handleFilter} />
          {this.state.showSettings === true && 
          <Modal show={this.state.showSettings} onHide={this.hideColumnSettings} size="lg" 
              centered className="delegateWithUs">
              <Modal.Header closeButton>
                  <Modal.Title>{RespUtils.isMobileView() ? "Change Visibility" : "Change visibility of columns"}</Modal.Title>
                  <div className="resetButtonStyle">
                    <button className="animLinkMenu animLink" onClick={this.resetColumnSettings}>Reset</button>
                    <button className="animLinkMenu animLink" onClick={this.hideColumnSettings}>Close</button>
                  </div>
              </Modal.Header>
              <Modal.Body>
              <div className="customSettingsGroup btn-group btn-group-toggle btn-group-vertical" data-toggle="buttons">
                  {
                  this.props.columns
                      .map(column => ({
                      ...column,
                      toggle: this.props.toggles[column.dataField]
                      }))
                      .map(column => (
                      <button type="button" key={ column.dataField } className={ `customSettingsButton btn btn-dark ${column.toggle ? 'active' : ''}` }
                          data-toggle="button" aria-pressed={ column.toggle ? 'true' : 'false' }
                          onClick={ () => {this.props.onColumnToggle(column.dataField); this.updateSetting(column.dataField); }}>
                          { column.toggle ? <VisibilityIcon /> : <VisibilityOffIcon /> } { column.text } - {column.desc}
                      </button>
                      ))
                  }
              </div>
              </Modal.Body>
              <Modal.Footer>
                <button className="animLinkMenu animLink" onClick={this.resetColumnSettings}>Reset</button>
                <button className="animLinkMenu animLink" onClick={this.hideColumnSettings}>Close</button>
              </Modal.Footer>
            </Modal>
            }
        </React.Fragment>
    );
  }

  handleViewChange(newView) {
    // console.log("in val col selector: new view: ");
    // console.log(newView);
    this.props.handleViewChange(newView);
  }
}

export default ValColumnSelector;
