import React from 'react';
// import {Form} from 'react-bootstrap';
// import LogoUtils from '../util/LogoUtils';
import Select from 'react-select';


class SearchableDropdown extends React.Component {
  render () {
    let values = this.props.values;
    // let optionItems = values.map((value) =>
    //     <option key={value.code} value={value.code}>{value.description}</option>
    // );

    for (let i = 0; i < values.length; i++) {
        values[i]["label"] = values[i]["description"];
    }

    const colourStyles = {
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
          // const color = chroma(data.color);
          // console.log({ data, isDisabled, isFocused, isSelected });
          return {
            ...styles,
            backgroundColor: isFocused ? "#1967D2": "#0f1011",
            color: "white",
            fontWeight: 300,
            fontFamily: 'Roboto'
          };
        }
    };

    let size = this.props.size ? this.props.size : "sm"
    // style={{"marginLeft": "0.5rem"}} 
    return (
        <div style={{width: '50rem'}}>
            <Select options={values} onChange={this.props.onSelect} 
                value={this.props.defaultValue} placeholder={this.props.placeholder}
                menuPlacement="auto" menuPosition="fixed" styles={colourStyles} 
                // styles={{
                //     control: (provided, state) => ({
                //       ...provided,
                //       backgroundColor: state.isSelected ? "#1967D2": "#0f1011", color: "white",
                //       width:"100%"
                //     }),
                //     option: (provided, state) => ({
                //       ...provided,
                //       backgroundColor: state.isSelected ? "#1967D2": "#0f1011", color: "white",
                //     })
                //   }}
            />
        </div>
    );
  }

  // getLogo(value) {
  //   if (value.logo) {
  //     return LogoUtils.logoByUrl(value.logo, false);
  //   }

  //   return "";
  // }

}

export default SearchableDropdown;
