import React from 'react';

class SSUtils extends React.Component {

  static getSupportUs() {
    return "";
    // return (<div className="notification">Like Smart Stake tools? Support us by delegating with Smart Stake.</div>);
  }

}

export default SSUtils;
