import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
// import paginationFactory from 'react-bootstrap-table2-paginator';
// import TablePagination from '@material-ui/core/TablePagination';
import Pagination from "react-pagination-js";
import "react-pagination-js/dist/styles.css"; // import css
import UITableUtils from "../util/UITableUtils";
import "../address/Richlist.css";
import AddressUtils from '../util/AddressUtils';

import currentNetwork from '../currentNetwork';
import SPCalc from '../util/SPCalc';
import ApiUtils from '../util/ApiUtils';
import Utilities from "../util/Utilities";
import UIUtils from "../util/UIUtils";
import SPUtilities from "../util/SPUtilities";
// import NetworkNav from '../network/NetworkNav';
import './TokenRichlist.css';
import RespUtils from '../util/RespUtils';
import PageHeader from '../components/PageHeader';
import LogoUtils from '../util/LogoUtils';
import ReactTooltip from 'react-tooltip';
import TokenUtils from './TokenUtils';
import SessionUtils from '../util/SessionUtils';
import common from '../common';
import ProjectUIUtils from '../projects/ProjectUIUtils';
import TokenSummaryStat from './TokenSummaryStat';

class TokenRichlist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      tokens: [],
      tokenDetails: {},
      minBalance: "N/A",
      coinStat: {},
      relatedTokens: [],
      currentPage: 1,
      rowsPerPage: 100,
      token: null,
      width: window.innerWidth,
      size: 10,
      isLoading: true,
    }
    this.updateDimensions = this.updateDimensions.bind(this);
    this.renderTokenSwitch = this.renderTokenSwitch.bind(this);
    this.historyLink = this.historyLink.bind(this);
    this.loadData = this.loadData.bind(this);
    // this.renderTokenSwitchResp = this.renderTokenSwitchResp.bind(this);
  }

  updateDimensions() {
    this.setState({width: window.innerWidth});
  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  changeCurrentPage = numPage => {
    // console.log("setting numPage to: ", numPage);
    this.setState({currentPage: numPage});
    this.loadData(numPage);
  }

  async componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    SessionUtils.setNetwork(this);
    
    setTimeout(this.loadData, 20);
  }

  async loadData(currentPage) {
    if (!currentPage) {
      currentPage = this.state.currentPage;
    }
    let dataType = this.state.dataType;
    let token = TokenUtils.getToken(this);
    // , currentNetwork.details.ui.richlist.defaultToken

    let url = "listData?type=tokens&useNewToken=true&pageSize=" + this.state.rowsPerPage + "&page=" + currentPage + "&tokenCode=" + encodeURIComponent(token);
    // console.log("url: ", url);

    let allData = await ApiUtils.get(url);
    // console.log("allData is:", allData["tokenDetails"]);
    // console.log("allData is:", allData);

    if (allData) {
      let data = allData["data"];
      let newData = Utilities.addIndexByPage(data, this.state.rowsPerPage, currentPage);

      let coinStat = allData["coinStat"];

      let totalRecords = allData["recordCount"];
      // let totalRecords = coinStat.activeAccounts;
      
      let totalPages = Math.ceil(totalRecords/this.state.rowsPerPage);
      // console.log("totalRecords: ", totalRecords);
      // console.log("totalPages: ", totalPages);
      this.setState({"data": newData, isLoading: false, "coinStat": coinStat, "tokenDetails": allData["tokenDetails"],
        "minBalance": allData["minBalance"], "totalPages": totalPages, "tokens": allData["tokens"],
        "dataType": dataType, "totalRecords": totalRecords, "token": token, "relatedTokens": allData["relatedTokens"]});
    }
  }

  render() {
    if (this.state.isLoading) {
      return <div>Loading</div>;
    }

    let hideTotal = (this.state.tokenDetails.stakingContract  != null && this.state.tokenDetails.denom != null) ? false : true;
    let hideBalance = (this.state.tokenDetails.denom == null);
    let hideStakedBalance = (RespUtils.isMobileView() || this.state.tokenDetails.stakingContract == null);
    let hideUsdValue = (RespUtils.isMobileView() || !this.state.coinStat.usdPrice);
    let hidePercentSupply = (RespUtils.isMobileView() || !this.state.coinStat.totalSupply);

    var columns = [
      {text: "#", dataField: "index", sort: true, headerStyle: Utilities.respWidth(5, 4, 3)},
      {text: "Address", dataField: "address", formatter: AddressUtils.addressFormatterLabelLinkToExplorerWithHistory, formatExtraData: this.state.token, sort: true, headerStyle: Utilities.respWidth(50, 25, 30)},
      {text: "Total ($" + this.state.token + ")", hidden: hideTotal, dataField: "totalBalance", formatter: SPUtilities.stakeFormatterRounded, sort: true,  sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(35, 20, 15)},
      {text: "Staked Amount", hidden: hideStakedBalance, dataField: "totalStake", formatter: SPUtilities.stakeFormatterRounded, sort: true,  sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(15)},
      {text: (RespUtils.isMobileView() ? "Balance" : "Address Balance ($" + this.state.token + ")"), hidden: hideBalance, dataField: "addressBalance", formatter: SPUtilities.stakeFormatterRounded, sort: true,  sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(15)},
      {text: "Percent Supply", hidden: hidePercentSupply, dataField: "percentSupply", formatter: SPUtilities.percentFormatter, sort: true,  sortFunc: Utilities.sortNumeric, headerStyle: Utilities.respWidth(20, 15, 12)},
      {text: "Total USD Value", hidden: hideUsdValue, dataField: "totalBalance", formatter: SPCalc.calcTotalUSD, formatExtraData: this, sort: true,  sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(15)},
    ];

    const options = UIUtils.getPageOptionsLarge(this);
    let wrapperClasses = UITableUtils.isDefaultView() ? "table":"table-responsive";

    return (
      <div>
        <PageHeader title={this.state.tokenDetails.name + " Addresses "} thisObj={this} record={this.state.tokenDetails} additionalForTitle={this.historyLink}
            additionalLink={this.renderTokenSwitch} disableStakeFormatter={true} logo={LogoUtils.tokenLogo}/>

        <ReactTooltip id="main" place="top" type="dark" effect="float" multiline={true} />
        {/* {this.getStats()} */}
        <TokenSummaryStat tokenDetails={this.state.tokenDetails} minBalance={this.state.minBalance} />
        <p>1) This is primarily a permissionless listing of tokens. 2) Listing of tokens on this page is not an endorsement of each token. Please dyor. 2) Some of the addresses might be LPs/SCs/IBC/Treasury/Exchange. 3) Want alias for any of the addresses? Submit <a href={common.urls.aliasUrl}>here</a></p>
        <div className="richlistPagination"><Pagination currentPage={this.state.currentPage} theme="dark" totalSize={this.state.totalRecords}
          sizePerPage={this.state.rowsPerPage} changeCurrentPage={this.changeCurrentPage} numberOfPagesNextToActivePage={Utilities.respLabel(3, 5, 7)} /></div>
        <p/>
        <BootstrapTable keyField='address' data={ this.state.data }
          columns={ columns } striped expandableRow={ () => { return true; } } options={options}
          condensed noDataIndication="No data"
          wrapperClasses={wrapperClasses}/>
        <div className="richlistPagination"><Pagination currentPage={this.state.currentPage} theme="dark" totalSize={this.state.totalRecords}
          sizePerPage={this.state.rowsPerPage} changeCurrentPage={this.changeCurrentPage} numberOfPagesNextToActivePage={Utilities.respLabel(3, 5, 7)} /></div>

        <p><br/>Data is synced up every 4 hours or so and may be outdated. Want alias for any address or need another token listed? Submit <a href={common.urls.aliasUrl}>here</a></p>
      </div>
    );
  }

  // getStats() {
  //   return (
  //     <Container className="containerLayout" fluid>
  //       <Row className="statRowLayout">
  //         <Col className="statColLayout" xs={6} sm={4} md={3} lg={3}>
  //           <Stat label={"Active Addresses (>" + this.state.minBalance + " " + this.state.token + ")"} labelShort="Active Addresses" 
  //             value={this.state.tokenDetails.activeAccounts} info={tooltips.netstats.activeAddresses} />
  //         </Col>
  //         {this.state.tokenDetails.stakingContract != null && <Col className="statColLayout" xs={6} sm={4} md={3} lg={3}>
  //           <Stat label="Unique Stakers" value={this.state.tokenDetails.stakerCount} 
  //                 info={tooltips.netstats.uniqueDelegates} />
  //         </Col>}
  //         {this.state.tokenDetails.totalSupply != null && this.state.tokenDetails.totalSupply > 0 && 
  //           (<Col className="statColLayout" xs={6} sm={4} md={3} lg={3}>
  //             <Stat label={"Total Supply"} value={CoinUtils.stakeFormatterRoundIfNeeded(this.state.tokenDetails.totalSupply)} 
  //               info={tooltips.netstats.totalSupply} />
  //           </Col>)}
  //       </Row>
  //     </Container>  
  //   );
  // }
  
  renderTokenSwitch() {
    return TokenUtils.renderTokenSwitch(this, this.state.token);    
  }
  
  onTokenSelect(e) {
    window.location = SessionUtils.getUrl("/tokens/" + encodeURIComponent(e.target.value));
    // window.location = SessionUtils.getUrl("/tokens/" + e.token);
  }

  onOtherTokenSelect(e) {
    TokenUtils.navToOtherTokenPage(e);
    // let value = e.target.value;
    // // console.log(value);
    // let details = value.split(",");
    // // console.log(details);
    // if (details.length === 2) {
    //   let context = common.allNetworks[details[0]];
    //   if (context) {
    //     window.location = "/" + context + "/tokens/" + details[1];
    //   }
    // }
  }

  historyLink() {
    if (this.state.tokenDetails.tokenType === "ibc") {
      return "";
    }

    return (<div className="displayNetworkMedia"><button className="animLinkMenu animLink"><a 
      href={SessionUtils.getUrl("/tokens/" + encodeURIComponent(this.state.token) + "/history")} title="history" 
      className="mediaCustom fas fa-chart-pie fa-lg" /></button>
      {ProjectUIUtils.formatNetworkMediaSmallNoIntegrations(this.state.tokenDetails.twitter, this.state.tokenDetails)}</div>);
  }
}

export default TokenRichlist;
