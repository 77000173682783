import React, { Component } from 'react';
import {Button} from '@material-ui/core';
import {Collapse} from 'react-collapse';
import Card from 'react-bootstrap/Card';

import "./CollapsibleNote.css";

class CollpasibleNote extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showGuide: false,
    } 

    this.switchGuide = this.switchGuide.bind(this);
  }

  async componentDidMount() {
  }

  switchGuide() {
    let showGuide = !this.state.showGuide;
    this.setState({showGuide: showGuide});
  }

  render() {
    let label = "Screen Guide";

    if (this.props.label) {
      label = this.props.label;
    }

    return (
      <div className="collapsibleNote">
        <p><Button onClick={this.switchGuide} variant="contained" id="helpComp" size="small"
          >{this.state.showGuide ? ("Hide " + label) : ("Show " + label)}</Button></p>
        <Collapse isOpened={this.state.showGuide}>
          <Card>
            <Card.Header>{label}</Card.Header>
            <Card.Body>
              {this.props.body && (<span>{this.props.body}</span>)}
              {!this.props.body && this.props.getScreenGuide()}
            </Card.Body>
          </Card>
        </Collapse>
      </div>
    );
  }
}

export default CollpasibleNote;
