import React from 'react';
import currentNetwork from '../currentNetwork';

import "./StakeFormatter.css";
import SessionUtils from '../util/SessionUtils';
import IntegrationUtils from '../util/IntegrationUtils';

class StakeFormatter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDelegate: false,
      isLoading: true,
    }
    this.delegateWithUs = this.delegateWithUs.bind(this);
    this.hideDelegateWithUs = this.hideDelegateWithUs.bind(this);
  }

  async componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    SessionUtils.activateOnLoad(this);
  }

  delegateWithUs(e) {
    if (e) {
      e.stopPropagation();
    }

    if (this.state.showDelegate === false) {
      this.setState({showDelegate: true});
    } else {
      this.setState({showDelegate: false});
    }
  }

  hideDelegateWithUs(e) {
    if (e) {
      e.stopPropagation();
    }

    this.setState({showDelegate: false});
  }

  render() {
    if (this.state.isLoading) return "";

    const onClick = e => e.stopPropagation();
    return (<span onClick={ onClick }><a href={IntegrationUtils.getStakingHome(this.props.address)}
      target="_blank"><button className="animLink animLink linkBorder" id={"Stake" + this.props.address}
      >{this.props.label}</button></a></span>);

  }

}

export default StakeFormatter;
