import React from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import {Container} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ReactTooltip from "react-tooltip";
import paginationFactory from 'react-bootstrap-table2-paginator';

import MultiLineChart from '../charts/MultiLineChart';
import ApiUtils from '../util/ApiUtils';
import tooltips from "../tooltips";
import '../styles/section.css';
import './Burn.css';
import CoinUtils from '../util/CoinUtils';
import Utilities from '../util/Utilities';
import ChartTitle from '../components/ChartTitle';
import UIUtils from '../util/UIUtils';
import currentNetwork from '../currentNetwork';
import LogoUtils from '../util/LogoUtils';
import SPUtilities from '../util/SPUtilities';
import DataUtils from '../util/DataUtils';
import BidirectionChangeAndCumulativeChart from '../charts/BidirectionChangeAndCumulativeChart';
import PageHeader from '../components/PageHeader';
import SessionUtils from '../util/SessionUtils';
import ChartUtils from '../util/ChartUtils';

class Burn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      coinStat: {},
      notification: {},
      width: window.innerWidth,
      responsive: true,
      isLoading: true,
      frequencyNetwork: "M",
      frequencyBlock: "H",
      networkHistory: [],
      networkHistoryTable: [],
    }

    // this.showVPCurveChart = this.showVPCurveChart(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.handleChartUpdateNetwork = this.handleChartUpdateNetwork.bind(this);   
    this.handleChartUpdateBlock = this.handleChartUpdateBlock.bind(this);
  }


  updateDimensions() {
    this.setState({width: window.innerWidth});
  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  async componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    SessionUtils.setNetwork(this);

    await this.prepareData(this.state.frequencyNetwork, this.state.frequencyBlock);
  }

  async prepareData(frequencyNetwork, frequencyBlock) {
    let url = "networkStats?type=burn&frequencyNetwork=" + frequencyNetwork + "&frequencyBlock=" + frequencyBlock;
    const allData = await ApiUtils.get(url);
    if (allData) {
      let networkHistory = Utilities.removeEmptyRecords(allData["networkHistory"], "totalBurn");
      networkHistory = DataUtils.addCumulativeData(networkHistory, "deltaBurn", "cumulativeBurn");

      let networkHistoryTable = networkHistory;
      if (currentNetwork.details.ui.burn.hideEmptyData) {
        networkHistoryTable = DataUtils.removeZeroRecords(allData["networkHistory"], "deltaBurn");
      }
      
      this.setState({"frequencyNetwork": frequencyNetwork, "frequencyBlock": frequencyBlock,
        "coinStat": allData["coinStat"], "notification": allData["notification"],
        isLoading: false, "networkHistory": networkHistory, "networkHistoryTable": networkHistoryTable});
    }
  }

  render() {
    if (this.state.isLoading) {
      return <div>Loading</div>;
    }

    let chartVisualId = currentNetwork.details.networkName + "-burnStat";
    const downloadChart = () => {
      ChartUtils.downloadChart(document, chartVisualId, currentNetwork.details.app.title);
    };

    return (
      <div>
        <PageHeader title={currentNetwork.details.app.appName + " " + currentNetwork.details.ui.burn.burnTitle} 
          thisObj={this} customLogo={LogoUtils.getChainLogo} />
        <ReactTooltip id="main" place="top" type="dark" effect="float" multiline={true} />
        <hr/>
        <Container fluid className="containerLayout container-fluid chartContainer">
          <div className="chartGridContainer">
            <div className="chartBg burnStyle" id={"div" + chartVisualId}>
                <ChartTitle title={"Total Burn ($" + currentNetwork.details.app.token + ")"} 
                  saveChart={downloadChart} enableSave={true} /> 
                {this.getBurnStats()}
            </div>
            <div className="chartBg" id="cumulativeBurn">
              <BidirectionChangeAndCumulativeChart title={"Daily Burn ($" + currentNetwork.details.app.token + ")"}
                  xAxis="Date" yAxis="Daily Burns" xAxisValueAttr="title" cumulativeAttr="cumulativeBurn"
                  chartVisualId="dailyBurns" enableSave={true} 
                  valueAttr="deltaBurn" showVerticalLabel={false} handleChart={this.handleChartUpdateNetwork} 
                  showLegend={true} adjustRange={true} fsElementId="cumulativeBurn"
                  data={this.state.networkHistory} tip={tooltips.networkCharts.burnHistory} 
                  frequencies={["M", "Y", "All"]} frequency={this.state.frequencyNetwork} />
            </div>
            {currentNetwork.details.ui.burn.burnInProgressTitle && this.state.coinStat.inProgressBurn != null && (<div className="chartBg" id="inProgressBurn">
              <MultiLineChart title={currentNetwork.details.ui.burn.burnInProgressTitle} xAxis="Date" 
                  yAxis={currentNetwork.details.ui.burn.burnInProgressTitle} adjustRange={true} 
                  showVerticalLabel={false} valueAttr={["inProgressBurn"]} rangeAttr="inProgressBurn" showTotalLabel={false} 
                  xAxisValueAttr="title" data={this.state.networkHistory} tip={tooltips.networkCharts.inProgressBurn} 
                  handleChart={this.handleChartUpdateNetwork} formatValues={true} 
                  frequencies={["M", "Y", "All"]} frequency={this.state.frequencyNetwork} 
                  fsElementId="inProgressBurn" />
            </div>)}
            <div className="chartBg" id="burnHistory">
                {this.getBurnHistory()}
            </div>
          </div>
        </Container>
      </div>
    );
  }
  
  handleChartUpdateNetwork(frequencyNetwork) {
    this.prepareData(frequencyNetwork, this.state.frequencyBlock);
  }

  handleChartUpdateBlock(frequencyBlock) {
    this.prepareData(this.state.frequencyNetwork, frequencyBlock);
  }

  getBurnStats() {
    return (
      <React.Fragment>
        <div className="burnStat">
            {CoinUtils.formatCoins(this.state.coinStat.totalBurn)} 
            {LogoUtils.formatChainLogoBySizeAndStyle("logoStyleWithNameAndMargin", 40)}
        </div>
        {this.state.coinStat.inProgressBurn != null && (
          <div className="burnStatMinor">
              {currentNetwork.details.ui.burn.burnInProgressTitle && 
                <span>
                  {currentNetwork.details.ui.burn.burnInProgressTitle}: {CoinUtils.formatCoins(this.state.coinStat.inProgressBurn)}
                  {LogoUtils.formatChainLogoBySizeAndStyle("logoStyleWithNameAndMargin", 30)}
                </span>} 
          </div>
        )}
      </React.Fragment>
    );
  }


  getBurnHistory() {
    const headerSortingClasses = (column, sortOrder, isLastSorting, colIndex) => (
      sortOrder === 'asc' ? 'sorting-asc' : 'sorting-desc'
    );
    
    // console.log(this.state.networkHistoryTable);

    var columns = [
      {text: "Date", dataField: "title", sort: true, headerStyle: Utilities.width(20)},
      {text: ("$" + currentNetwork.details.app.token + " burned"), dataField: "deltaBurn", formatter: SPUtilities.formatCoins, sort: true,  sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(20)},
      {text: "Total Burn till date", dataField: "totalBurn", formatter: SPUtilities.formatCoins, sort: true,  sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(20)},
      {text: (currentNetwork.details.ui.burn.burnInProgressTitle), hidden: (!currentNetwork.details.ui.burn.burnInProgressTitle), dataField: "inProgressBurn", formatter: SPUtilities.formatCoins, sort: true,  sortFunc: Utilities.sortNumeric, headerStyle: Utilities.width(20)},
    ];

    const options = UIUtils.getPageOptionsSmall(this, 5);
    const defaultSorted = [{
      dataField: 'title',
      order: 'desc' // desc or asc
    }];

    return (
      <React.Fragment>
        <ChartTitle title="Burn History" tip={tooltips.networkCharts.burnHistory} />
        <p/>
        <ReactTooltip id="main" place="top" type="dark" effect="float" multiline={true} />
        <BootstrapTable keyField="title" data={ this.state.networkHistoryTable }
          columns={columns} striped options={options} defaultSorted={defaultSorted}
          condensed noDataIndication="No data" pagination={ paginationFactory(options) } />
      </React.Fragment>
    );
  }
}

export default Burn;
