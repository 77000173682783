import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import {Form, FormGroup} from 'react-bootstrap';
import ReactTooltip from "react-tooltip";

import NetworkUtils from '../util/NetworkUtils';
import Dropdown from '../base/Dropdown';
import ApiUtils from '../util/ApiUtils';
import Utilities from '../util/Utilities';
import MessageBox from "../components/MessageBox";
import ValidationUtils from "../util/ValidationUtils";
import DataUtils from '../util/DataUtils';
// import NetworkNav from '../network/NetworkNav';

import "./Calculator.css";
import "../styles/tsection.css";
import PageHeader from '../components/PageHeader';
import currentNetwork from '../currentNetwork';
import CoinUtils from '../util/CoinUtils';
import SessionUtils from '../util/SessionUtils';
import LogoUtils from '../util/LogoUtils';

class Calculator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: {type: undefined, body: undefined},
      calc: {},
      coinStat: {},
      calcStats: {},
      validator: "",
      validators: [],
      rewardRate: 0,
      isLoading: true,
      token: currentNetwork.details.app.token
    }
    this.handleCalculate = this.handleCalculate.bind(this);
    this.calculateStakingRewards = this.calculateStakingRewards.bind(this);
    this.handleCalculateByVal = this.handleCalculateByVal.bind(this);
    this.handleStakedAmtChange = this.handleStakedAmtChange.bind(this);
    this.handleRewardRateChange = this.handleRewardRateChange.bind(this);
    this.handleFeeChange = this.handleFeeChange.bind(this);

  }

  resetForm() {
    window.location.reload();
  }

  handleFeeChange(event) {
    let value = event.target.value;
    if (value !== '') {
      value = value.replace("%","");
    }

		// console.log("in handleFeeChange: ", value);
    this.setState({commission: value});
    // this.calculateStakingRewards();
  }

  handleRewardRateChange(event) {
    let value = event.target.value;
    if (value !== '') {
      value = value.replace("%","");
    }

    // let input = this.state.input;
    // input.rewardRate = rewardRate.value;
		// console.log("in handleRewardRateChange: ", value);
    this.setState({rewardRate: value});
    // this.calculateStakingRewards();
  }

  handleStakedAmtChange(event) {
    // if (amount.value !== '') {
    //   amount = amount.value.trim();
    // }

    // let input = this.state.input;
    // input.stakedAmount = amount.value;
		// console.log("in handleStakedAmtChange: ", event.target.value);
    this.setState({stakedAmount: event.target.value});
    // this.calculateStakingRewards();
  }

  async componentDidMount() {
    SessionUtils.setNetwork(this);
    const allData = await ApiUtils.get("listData?type=listApr");

    // console.log("allData is:");
    // console.log(allData);
    if (!allData) {
      return;
    }
    let data = DataUtils.moveElementToTop(allData["data"], "code", NetworkUtils.getDefaultPool());
    let coinStat = allData["coinStat"];

    let rewardRate = coinStat.currentRewardRate;
    let commission = coinStat.smartStakeFee;
    let stakedAmount = "100";

    this.setState({validators: data, coinStat: coinStat, rewardRate: rewardRate,
      commission: commission, stakedAmount: stakedAmount, isLoading: false});
    this.calculateStakingRewards();
  }

  onValSelect = (e) => {
    // alert(e.target.value)
    // console.log(e.target.value);
    let val = Utilities.getElementByAttribute(this.state.validators, "code", e.target.value);
    // console.log(val);
    let rewardRate = val.avgNetApr;
    let commission = val.commission;

		// console.log("in onValSelect: ", commission);
    this.setState({rewardRate: rewardRate, commission: commission});
  }

  calculateStakingRewards() {
    // console.log("in calculateStakingRewards: ", this.state);
    // console.log(this.state.stakedAmount);
    let stakedAmount = this.state.stakedAmount;
    if (stakedAmount) {
      stakedAmount = stakedAmount.split(",").join("");
    }
    let rewardRate = this.state.rewardRate;
    let commission = this.state.commission;

    let message = "";
    let status = "success";
    let token = currentNetwork.details.app.token;

    if (isNaN(stakedAmount) || stakedAmount < 1 || stakedAmount > 1000000000) {
  		alert("'$" + token + " delegated by you' must be a number between 1 and 1 billion");
      message = "'$" + token + " delegated by you' must be a number between 1 and 1 billion";
      status = "error";
  		return;
  	} else if (isNaN(rewardRate) || rewardRate < 0 || rewardRate > 10000) {
  		alert("Reward rate must be between 0 and 10000");
      message = "Reward rate must be between 0 and 10000";
      status = "error";
  		return;
    } else if (isNaN(commission) || commission < 0 || commission > 100) {
  		alert("Commission must be between 0 and 100 (" + commission + ")");
      message = "Commission must be between 0 and 100";
      status = "error";
  		return;
    }
    
  	let stakedAmountInt = parseFloat(stakedAmount);
  	let rewardRateInt = parseFloat(rewardRate);
  	let feeInt = parseFloat(commission);

  	let reward = stakedAmountInt * ((100 - feeInt)/100) * (rewardRateInt/100);

    let feePaid = stakedAmountInt * ((feeInt)/100) * (rewardRateInt/100);
    let feePaidSmartStake = stakedAmountInt * ((this.state.coinStat.smartStakeFee)/100) * (rewardRateInt/100);

    let dailyReward = reward / this.getDaysInYear();

    let weeklyReward = reward * 7/ this.getDaysInYear();

    let monthlyReward = reward / 12;

    let calcStats = {
      feePaid: feePaid.toFixed(2),
      feePaidSmartStake: feePaidSmartStake.toFixed(2),
      annualReward: reward.toFixed(2),
      monthlyAIReward: this.getCompoundReward(stakedAmountInt, feeInt, rewardRateInt, 12).toFixed(2),
      weeklyAIReward: this.getCompoundReward(stakedAmountInt, feeInt, rewardRateInt, 52).toFixed(2),
      dailyAIReward: this.getCompoundReward(stakedAmountInt, feeInt, rewardRateInt, this.getDaysInYear()).toFixed(2),
      dailyReward: dailyReward.toFixed(2),
      weeklyReward: weeklyReward.toFixed(2),
      monthlyReward: monthlyReward.toFixed(2),
      monthlyRewardInt: monthlyReward,
      stakedAmount: stakedAmountInt, feeInt: feeInt, rewardRateInt: rewardRateInt
    };

    // let rewardData = this.getRewardData(calcStats);
    // this.setState({calcStats: calcStats, rewardData: rewardData});

    this.setState({calcStats: calcStats});
	}

  getRewardData(calcStats) {
    let rewardData = [];

    let prevRecord = {"month": 0, "stake": calcStats.stakedAmount,
      "stakeWeeklyCompound": calcStats.stakedAmount,
      "stakeMonthlyCompound": calcStats.stakedAmount,
      "stakeAnnualCompound": calcStats.stakedAmount,
      "stakeAnnualPrevValid": calcStats.stakedAmount};
    // console.log(prevRecord)
    rewardData.push(prevRecord);
    for (let month=1; month <= 60; month++) {
      let stake = prevRecord.stake + calcStats.monthlyRewardInt
      let stakeMonthlyCompoundRewards = this.getRewardAmountByFactor(prevRecord.stakeMonthlyCompound,
        calcStats.feeInt, calcStats.rewardRateInt, 1, 12);
      let stakeWeeklyCompoundRewards = this.getRewardAmountByFactor(prevRecord.stakeWeeklyCompound,
        calcStats.feeInt, calcStats.rewardRateInt, 4, 52);
      stakeWeeklyCompoundRewards += stakeWeeklyCompoundRewards * (52/12 - 4);
      let stakeWeeklyCompound = prevRecord.stake + stakeWeeklyCompoundRewards;
      let stakeMonthlyCompound = prevRecord.stake + stakeMonthlyCompoundRewards;
      let stakeAnnualCompound = null;
      let stakeAnnualPrevValid = null;
      if (month % 12 === 0) {
        stakeAnnualCompound = this.getRewardAmountByFactor(prevRecord.stakeAnnualCompound,
          calcStats.feeInt, calcStats.rewardRateInt, month/12, 1);
        stakeAnnualPrevValid = prevRecord.stakeAnnualPrevValid;
      }

      let record = {"month": month, "stake": stake,
        "stakeWeeklyCompound": stakeWeeklyCompound,
        "stakeMonthlyCompound": stakeMonthlyCompound,
        "stakeAnnualPrevValid": stakeAnnualPrevValid,
        "stakeAnnualCompound": stakeAnnualCompound};
      // console.log(record);
      rewardData.push(record);
      prevRecord = record;
    }

    // console.log(rewardData);
    return rewardData;
  }

  getDaysInYear() {
    let today = new Date();
    let year = today.getFullYear();
    return this.isLeapYear(year) ? 366 : 365;
  }

  isLeapYear(year) {
    return year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0);
  }

  handleCalculate(e) {
    e.preventDefault();
    this.calculateStakingRewards();
  }

  handleCalculateByVal(e) {
    e.preventDefault();
    this.calculateStakingRewards();
  }

  getCompoundReward(stakedAmount, commission, rewardRate, totalReinvestments) {
  	var amount = stakedAmount;
  	for (var i = 0; i < totalReinvestments; i++) {
  		amount += amount * ((100 - commission)/100) * (rewardRate/100) / totalReinvestments;
  		// console.log(i + " - amount is: " + amount);
  	}

  	amount = amount - stakedAmount;

  	return amount;
  }

  getRewardAmountByFactor(stakedAmount, commission, rewardRate, totalReinvestments, annualFactor) {
  	var amount = stakedAmount;
  	for (var i = 0; i < totalReinvestments; i++) {
  		amount += amount * ((100 - commission)/100) * (rewardRate/100) / annualFactor;
  		// console.log(i + " - amount is: " + amount);
  	}

    amount = amount - stakedAmount;
  	return amount;
  }


  render() {
    if (this.state.isLoading) {
      return <div>Loading Calculator</div>;
    }

    let token = currentNetwork.details.app.token;

		// console.log(this.state.rewardRate);
    return (
      <div>
        <PageHeader title={currentNetwork.details.app.appName + " Staking Rewards Calculator"} 
          thisObj={this} showFavIcon={false} showRespIcon={false} beta={false} customLogo={LogoUtils.getChainLogo} />
        <ReactTooltip id="main" place="top" type="dark" effect="float" multiline={true} />
        <p>Smart Stake charges {this.state.coinStat.smartStakeFee}% commission.</p>
        <MessageBox type={this.state.message.type} message={this.state.message.body}/>
        <Container fluid className="containerLayout container-fluid chartContainer">
          <div class="chartGridContainer">
            <div class="chartBg">
							<p className="compTitle">Overall Rewards Calculator</p>
							<Form>
								<Form.Group as={Row} controlId="stakedAmount">
									<Form.Label column sm={6}>${token} delegated by you: </Form.Label>
									<Col sm={6}><Form.Control type="text" placeholder="Enter staked amount"
											onChange={this.handleStakedAmtChange} defaultValue={this.state.stakedAmount}/></Col>
								</Form.Group>
								<Form.Group as={Row} controlId="rewardRate">
									<Form.Label column sm={6}>Reward Rate (%): </Form.Label>
									<Col sm={6}><Form.Control type="text" placeholder="Annual reward rate in %"
											onChange={this.handleRewardRateChange} defaultValue={this.state.rewardRate}/></Col>
								</Form.Group>
								<Form.Group as={Row} controlId="commission">
									<Form.Label column sm={6}>Validator Commission: </Form.Label>
									<Col sm={6}><Form.Control type="text" placeholder="Commission paid to Validator in %"
											onChange={this.handleFeeChange} defaultValue={this.state.coinStat.smartStakeFee}/></Col>
								</Form.Group>
								<FormGroup as={Row}>
									<Col>
										<button variant="contained" id="calcButton"  className="animLink linkBorder" type="submit" onClick={this.handleCalculate}>Calculate</button>
										<button variant="contained" id="resetButton1"  className="animLink linkBorder" type="button" onClick={this.resetForm}>Reset</button>
									</Col>
								</FormGroup>
							</Form>
            </div>
            <div class="chartBg">
							<p className="compTitle">Rewards Calculator by Validator</p>
							<Form>
								<Form.Group as={Row} controlId="stakedAmount">
									<Form.Label column sm={6}>${token} delegated by you: </Form.Label>
									<Col sm={6}><Form.Control type="text" placeholder="Enter staked amount"
										onChange={this.handleStakedAmtChange} defaultValue={this.state.stakedAmount}/></Col>
								</Form.Group>
								<FormGroup as={Row} controlId="validatorControlId">
									<Form.Label column sm={6}>Validator Name & Reward Rate (%): </Form.Label>
									<Col sm={6}><Dropdown onSelect={this.onValSelect} values={this.state.validators} addAll={false} addBlank={true} /></Col>
								</FormGroup>
								<FormGroup as={Row} controlId="validatorControlId">
									<Form.Label column sm={6}>Reward Rate (%): </Form.Label>
									<Col sm={6}><Form.Control plaintext readOnly value={this.state.rewardRate} /></Col>
								</FormGroup>
								<FormGroup as={Row}>
									<Col>
										<button variant="contained" id="calcButton2" className="animLink linkBorder" type="submit" onClick={this.handleCalculateByVal}>Calculate</button>
										<button variant="contained" id="resetButton2" className="animLink linkBorder" type="button" onClick={this.resetForm}>Reset</button>
									</Col>
								</FormGroup>
							</Form>
						</div>
            <div class="chartBg">
							<p className="compTitle">${token} Rewards</p>
							<table >
								<thead>
									<tr>
										<th>Frequency</th>
										<th>${token} Rewards</th>
									</tr>
								</thead>

								<tbody>
									<tr>
										<td>Daily</td>
										<td>{CoinUtils.coinCountCellFormatter(this.state.calcStats.dailyReward)}</td>
									</tr>
									<tr>
										<td>Weekly</td>
										<td>{CoinUtils.coinCountCellFormatter(this.state.calcStats.weeklyReward)}</td>
									</tr>
									<tr>
										<td>Monthly</td>
										<td>{CoinUtils.coinCountCellFormatter(this.state.calcStats.monthlyReward)}</td>
									</tr>
									<tr>
										<td>Annual</td>
										<td>{CoinUtils.coinCountCellFormatter(this.state.calcStats.annualReward)}</td>
									</tr>
								</tbody>
							</table>
						</div>
            <div class="chartBg">
							<p className="compTitle">${token} Compound Annual Rewards *</p>
							<table >
								<thead>
									<tr>
										<th>Frequency</th>
										<th>${token} Rewards</th>
									</tr>
								</thead>

								<tbody>
									<tr>
										<td>Daily</td>
										<td>{CoinUtils.coinCountCellFormatter(this.state.calcStats.dailyAIReward)}</td>
									</tr>
									<tr>
										<td>Weekly</td>
										<td>{CoinUtils.coinCountCellFormatter(this.state.calcStats.weeklyAIReward)}</td>
									</tr>
									<tr>
										<td>Monthly</td>
										<td>{CoinUtils.coinCountCellFormatter(this.state.calcStats.monthlyAIReward)}</td>
									</tr>
									<tr>
										<td>Annual</td>
										<td>{CoinUtils.coinCountCellFormatter(this.state.calcStats.annualReward)}</td>
									</tr>
								</tbody>
							</table>
						</div>
        	</div>
				</Container>
				<p>Notes:</p>
				<ul>
					<li>Annual commission for inputs used - {CoinUtils.coinCountCellFormatter(this.state.calcStats.feePaid)}</li>
					<li>Annual commission with Smart Stake - {CoinUtils.coinCountCellFormatter(this.state.calcStats.feePaidSmartStake)}</li>
					<li>Compound Annual Rewards - "Annual" means rewards earned are redelegated once a year. "Monthly" means rewards are redelegated once every month. "Weekly" means rewards are redelegated once every week.</li>
          <li>Staking APR is a just-in-time stat and does not consider future change in staking returns based on higher/lower total stake, or change in network parameters, or reflect token price changes. It is based on inflation, block rate, 0% commission, and does not reflect transaction fees. APY assumes weekly auto compounding without considering tx fees (manual/automated).</li>
				</ul>
      </div>
    );
  }

  validate(key, value, callerObj, msg) {
    let valid = ValidationUtils.isNotNull(value);

    if (!valid || !ValidationUtils.isValidAmount(value)) {
      callerObj.setState({[key]: msg});
      valid = false;
    } else {
      callerObj.setState({[key]: ""});
    }

    return valid;
  }

}

export default Calculator;
