import React from 'react';


class UrlUtils extends React.Component {
    static getFragmentFromUrl() {
        let url = window.location.href;
        // Split the URL at the '#' character
        const parts = url.split('#');
        
        // Check if there is a fragment part
        if (parts.length > 1) {
            return parts[1];
        } else {
            // If there is no fragment, return an empty string or a suitable default value
            return null;
        }
    }

    static setUrlFragment(fragment) {
        if (fragment != null && fragment != "") {
            window.location = "#" + fragment;
        }
    }
    
}

export default UrlUtils;
