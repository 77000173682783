import React from 'react';

import RespUtils from './RespUtils';
import FavUtils from './FavUtils';
import common from '../common';
import viewData from '../viewData';
import TextUtils from './TextUtils';

class ColumnUtils extends React.Component {
  static getViewDataByHandlingNAColumne(viewName, viewsDataOrg, initColumns) {
    let viewsData = JSON.parse(JSON.stringify(viewsDataOrg));

    const minCommonColumns = viewsData.minCommonColumns.filter(item => item in initColumns);
    viewsData.minCommonColumns = minCommonColumns;

    const extraCommonColumns = viewsData.extraCommonColumns.filter(item => item in initColumns);
    viewsData.extraCommonColumns = extraCommonColumns;

    let viewColumnsInit = viewsData.viewColumns[viewName];
    const viewColumns = viewColumnsInit.filter(item => item in initColumns);
    viewsData.viewColumns[viewName] = viewColumns;

    return viewsData;
  }
  
  static updateColumnsByView(newView, viewsDataOrg, columns, initColumns, moreView) {
    // console.log("in updateColumnsByView, columns are: ", columns);
    // console.log(`in updateColumnsByView, 1st column is: ${columns[0].hidden} - ${columns[0].dataField} `);
    if (!newView || newView === "") {
    //   console.log("default view is all columns");
    //   console.log(columns);
      return columns;
    }
    
    let viewsData = ColumnUtils.getViewDataByHandlingNAColumne(newView, viewsDataOrg, initColumns);
    // console.log(`in updateColumnsByView: new view: ${newView}`);
    let viewColumns = [];
    viewColumns = viewColumns.concat(viewsData.minCommonColumns);

    if (RespUtils.isTabletView()) {
        viewColumns = viewColumns.concat(viewsData.viewColumns[newView]);
        viewColumns = viewColumns.concat(viewsData.extraCommonColumns);
    } else {
        viewColumns = viewColumns.concat(viewsData.extraCommonColumns);
        viewColumns = viewColumns.concat(viewsData.viewColumns[newView]);
    }
    // console.log("in updateColumnsByView: view specific configured columns are: ", viewColumns);
    // console.log("in updateColumnsByView: init columns are: ", initColumns);

    // console.log(`in updateColumnsByView: before finding selected columns, 1st column is: ${columns[0].hidden} - ${columns[0].dataField} `);

    let selectedColumns = [];
    for(let i=0; i < columns.length; i++) {
      let column = columns[i];
      // console.log(`checking ${column.dataField} in ${viewColumns}`);
      //   initColumns.has(column.dataField)
    //   console.log(initColumns[column.dataField])
      if (viewColumns.includes(column.dataField)) {
        // console.log(`adding column ${column.dataField}`);
        column.hidden = false;
        selectedColumns.push(column);
      }
    }

    // console.log(`in updateColumnsByView: after finding selected columns, 1st column is: ${columns[0].hidden} - ${columns[0].dataField} `);

    // console.log("in updateColumnsByView: selectedColumns before visibility update are: ", selectedColumns);
    ColumnUtils.updateVisibilityByDevice(selectedColumns, newView, viewsData, moreView);
    // console.log("in updateColumnsByView: selectedColumns after visibility update are: ", selectedColumns);

    // console.log("in updateColumnsByView: final list of columns for new view: ", newView);
    // console.log(selectedColumns);
    return selectedColumns;
  }

  static updateVisibilityByDevice(selectedColumns, viewName, viewsData, moreView) {
    // console.log("in updateVisibilityByDevice: selectedColumns: ", selectedColumns);
    let viewSpecificColumns = viewsData.viewColumns[viewName];
    
    let moreViewFactor = common.moreViews.factor[moreView];
    // console.log(`moreViewFactor for {moreView}: {moreViewFactor}`);
    let maxSpecificColumns = RespUtils.isTabletView() ? viewsData.maxColumnsShownResp * moreViewFactor : viewsData.maxColumnsShown * moreViewFactor;
    let hideExtraColumns = RespUtils.isTabletView();
    if (RespUtils.isTabletView() && moreViewFactor > 1) {
      viewSpecificColumns = viewSpecificColumns.concat(viewsData.extraCommonColumns);
      hideExtraColumns = false;
    }

    // console.log("maxSpecificColumns: ", maxSpecificColumns);
    // console.log("viewSpecificColumns: ", viewSpecificColumns);
    if (maxSpecificColumns < viewSpecificColumns.length) {
      viewSpecificColumns = viewSpecificColumns.slice(0, maxSpecificColumns);
    }
    // console.log("viewSpecificColumns: ", viewSpecificColumns);
    
    let extraCommonColumnsSet = new Set(viewsData.extraCommonColumns);
    let minCommonColumnsSet = new Set(viewsData.minCommonColumns);
    let viewSpecificColumnsSet = new Set(viewSpecificColumns);

    selectedColumns.forEach(item => {
        if (extraCommonColumnsSet.has(item.dataField)) {
          if (hideExtraColumns === true) {
            item.hidden = true;
          }
        } else if (!minCommonColumnsSet.has(item.dataField) && !viewSpecificColumnsSet.has(item.dataField)) {
          item.hidden = true;
        }
    });
  }

  static isHiddenWithView(key, dataField, initConfigFn, viewName) {
    // console.log("start of isHiddenWithView - ", dataField);
    let storageKey = FavUtils.getKey(key);
    if (viewName) {
      storageKey = storageKey + "-" + viewName;
    }

    let columnsConfig = window.localStorage.getItem(storageKey);
    // console.log("isHiddenWithView - ", storageKey, " - columnsConfig from storage : ", columnsConfig);
    let columnsConfigJson;

    if (!columnsConfig || columnsConfig === null) {
      // console.log("isHiddenWithView - initializing config");
      columnsConfigJson = initConfigFn();
      // console.log("isHiddenWithView - after init: columnsConfig: ", columnsConfigJson);
    } else {
      // console.log("isHiddenWithView - column config is in storage");
      columnsConfigJson = JSON.parse(columnsConfig)
    }

    // console.log("isHiddenWithView - columnsConfigJson: ", columnsConfigJson);
    // console.log("isHiddenWithView - ", dataField, " - result: ", columnsConfigJson[dataField]);

    let value = columnsConfigJson[dataField];
    if (value === undefined) {
      // console.log("this might be a new element. hiding by default: ", dataField);
      return true;
    }
    
    // console.log("end of isHiddenWithView - ", dataField, " - result: ", value);
    return value;
  }

  static isHidden(key, dataField, initConfigFn) {
    // console.log("in isHidden(key: ", key, ", dataField: ", dataField);
    let columnsConfig = window.localStorage.getItem(FavUtils.getKey(key));

    // console.log("columnsConfig: ", columnsConfig);
    let columnsConfigJson;
    if (!columnsConfig || columnsConfig === null) {
      // console.log("initializing config");
      columnsConfigJson = initConfigFn();
      // console.log("after init: columnsConfig: ", columnsConfig);
    } else {
      columnsConfigJson = JSON.parse(columnsConfig)
    }

    // console.log("columnsConfigJson: ", columnsConfigJson);
    // console.log(columnsConfigJson[dataField]);
    let value = columnsConfigJson[dataField];
    if (value === undefined) {
      // console.log("this might be a new element. hiding by default: ", dataField);
      return true;
    }
    
    return value;
  }

  static toggleMoreView(thisObj) {
    let currentView = thisObj.state.moreView;
    let nextView = null;
    if (currentView == common.moreViews.full) {
      nextView = common.moreViews.hidden;
    } else if (currentView == common.moreViews.partial) {
      nextView = common.moreViews.full;
    } else {
      nextView = common.moreViews.partial;
    }

    // console.log("in toggleMoreView: setting next view to: ", nextView);
    thisObj.setState({"moreView": nextView});
  }

  static isMoreViewNeeded(viewName, viewType) {
    if (TextUtils.isEmpty(viewName)) {
      return false;
    }

    let viewSettings = viewData[viewType];
    let viewColumns = viewSettings.viewColumns[viewName];

    let columnsShown;
    if (RespUtils.isTabletView()) {
      columnsShown = viewSettings.maxColumnsShownResp + viewSettings.extraCommonColumns.length;
    } else {
      columnsShown = viewSettings.maxColumnsShown;      
    }

    // console.log(`columnsShown: ${columnsShown} < viewColumns: ${viewColumns}`);
    if (columnsShown < viewColumns.length) {
      return true;
    }
    return false;
  }
}

export default ColumnUtils;
